import React, { Component } from "react";
import { Button, BButton } from "../../../common/component/Button";
import Message from "semantic-ui-react/dist/commonjs/collections/Message";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";
import captureException from "sen-obj";
import { FireHelper } from "../../../../core/util/FireHelper";
import { isElectron, isMobile, isBrowser } from "react-device-detect";

var MacIAP;

function AddPaddle() {
  if (!isElectron && window.Paddle === undefined) {
    var s = document.createElement("script");
    s.src = "https://cdn.paddle.com/paddle/paddle.js";
    s.onload = function() {
      console.log(`We added Paddele`, window.isDev);
      if (window.isDev) {
        window.Paddle.Environment.set("sandbox");
        window.Paddle.Setup({ vendor: 1791 });
      } else {
        window.Paddle.Setup({ vendor: 129564 });
      }
    };
    document.body.appendChild(s);
  }
}

function StripeInvoice(props) {
  return (
    <Message>
      <Message.Header>Create invoice</Message.Header>
      <div>
        <div>Company / induvial name on invoice</div>
        <div>
          <input
            className="form-control"
            disabled={props.buttonDisabled}
            value={props.nameToInvoice}
            onChange={e =>
              props.setState({
                nameToInvoice: e.target.value
              })
            }
          ></input>
        </div>
      </div>

      <div
        style={{
          marginBottom: 10
        }}
      >
        <div>Credits to buy</div>
        <div>
          <input
            className="form-control"
            disabled={props.buttonDisabled}
            value={props.creditsToInvoice}
            onChange={e =>
              props.setState({
                creditsToInvoice: e.target.value
              })
            }
            type="number"
          ></input>
        </div>
      </div>
      <BButton
        className="form-control"
        loading={props.buttonDisabled}
        disabled={props.buttonDisabled}
        onClick={() => {
          props.setState({
            buttonDisabled: true
          });
          FireHelper.createInvoice(
            props.creditsToInvoice,
            props.nameToInvoice,
            props.currentUser
          );
          setTimeout(() => {
            try {
              props.setState({
                buttonDisabled: false,
                creditsToInvoice: 0
              });
            } catch (error) {}
          }, 3000);
        }}
        content={"Create invoice"}
      ></BButton>

      <div
        style={{
          padding: 5
        }}
      >
        Current price is 2 dollar per credits (tax will be add for EU)
        <br />
      </div>
    </Message>
  );
}

class Wallet extends Component {
  
  // static contextTypes = { store: PropTypes.object.isRequired, }
  constructor(props) {
    super(props);
  }

  state = {
    creditValue: "5credit",
    buttonDisabled: false,
    creditsToInvoice: 5,
    nameToInvoice: ""
  };

  onStoreUpdate = payload => {
    console.log(">>onStoreUpdate", payload.detail);

    switch (payload.detail) {
      case "waiting_for_validation":
        //  console.log(`waiting_for_validation`)
        toastr.info(
          window._getText("wallet.waiting_for_validation"),
          window._getText("wallet.pleasewait")
        );
        break;
      case "success":
        // console.log(`success`)
        this.setState({ buttonDisabled: false });
        toastr.success(
          window._getText("wallet.success"),
          window._getText("wallet.success1")
        );
        break;
      case "faild":
        //console.log(`faild`)
        this.setState({ buttonDisabled: false });
        toastr.error(
          window._getText("wallet.faild", window._getText("wallet.faild1"), {
            timeOut: 10000
          })
        );
        break;
      case "purchasing":
        //console.log(`Purchasing`)
        toastr.info("Purchasing...", window._getText("wallet.pleasewait"), {
          timeOut: 6000
        });
        break;
      case "purchased":
        //console.log(` purchased.`)
        toastr.success("Purchased.", window._getText("wallet.pleasewait"));

        break;
      case "failed":
        this.setState({ buttonDisabled: false });
        // console.log(`Failed to purchase .`)
        toastr.error(
          window._getText("wallet.failed"),
          window._getText("wallet.failed1"),
          { timeOut: 10000 }
        );

        break;
      case "restored":
        this.setState({ buttonDisabled: false });
        //console.log(`The purchase of has been restored.`)
        toastr.success(window._getText("wallet.restored"), "");
        break;
      case "deferred":
        //console.log(`The purchase of   has been deferred.`)
        toastr.success(window._getText("wallet.deferred"), "");

        break;
      default:
        console.log("--Default--");
        this.setState({ buttonDisabled: false });
        break;
    }
  };
  componentWillUnmount = () => {
    window.removeEventListener("onStoreUpdate", this.onStoreUpdate);
  };
  componentDidMount = () => {
    AddPaddle();
    window.addEventListener("onStoreUpdate", this.onStoreUpdate);

    if (isElectron && this.state.creditValue === "5credit") {
      this.setState({ creditValue: "mac5credit" });
    }

    if (isBrowser && !isElectron && !isMobile) {
      // Make sure to call `loadStripe` outside of a component’s render to avoid
      // recreating the `Stripe` object on every render.
      //  stripePromise = loadStripe('pk_test_JJ1eMdKN0Hp4UFJ6kWXWO4ix00jtXzq5XG');
    }

    if (isElectron === true) {
      if (window.isStoreInit !== true) {
        //init Electron IAP
        console.log("<<INIT STORE electron>>");
        MacIAP = require("../../../common/component/MacIAP");
      }
    }
  };
  onAddMoreCredits = () => {
    console.log(`this.state.creditValue`, this.state.creditValue)
    window.store.order(this.state.creditValue);
  };

  onAddMoreCreditsMac = e => {
    console.log("this.state.buttonDisabled", this.state.buttonDisabled);
    if (this.state.buttonDisabled === true) {
      alert("Please wait a few sec before trying again."); //TODO TRANSLATE
      toastr.info("Purchasing...", "please wait");
      return;
    }

    this.setState({ buttonDisabled: true }, () => {
      // setTimeout(() => {
      //   this.setState({ buttonDisabled: false })
      // }, 10000);

      try {
        var PRODUCT_IDS = ["mac5credit"];
        console.log('PRODUCT_IDS', PRODUCT_IDS);
        MacIAP.default.getProducts(PRODUCT_IDS).then(products => {
          // Check the parameters.
          if (!Array.isArray(products) || products.length <= 0) {
            console.log("Unable to retrieve the product informations.");
            return;
          }

          // Display the name and price of each product.
          products.forEach(product => {
            console.log(
              `The price of ${product.localizedTitle} is ${product.formattedPrice}.`
            );
          });

          // // Ask the user which product he/she wants to purchase.
          // const selectedProduct = products[0]
          // const selectedQuantity = 1
        });
      } catch (error) {
        console.log('error', error);
       }

      try {
        console.log("before buy", this.state.creditValue);
        MacIAP.default
          .purchaseProduct(this.state.creditValue, 1, isProductValid => {
            console.log(
              "Wallet -> onAddMoreCreditsMac -> isProductValid",
              isProductValid
            );

            if (!isProductValid) {
              alert("The product is not valid.", 1);
              return;
            }
            console.log("The payment has been added to the payment queue.");
          })
          .then(isProductValid => {
            console.log("isProductValid", isProductValid);
            if (!isProductValid) {
              console.log("The product is not valid.", 2);
              this.setState({ buttonDisabled: false });
              return;
            }

            console.log("The payment has been added to the payment queue.");
          });
        console.log("After");
      } catch (error) {
        captureException(error);
        console.log("#ERR wallet.onAddMoreCredits ", error);
      }
    });
  };

  // onRefresh = () => {
  //   console.log("onRefresh");
  //   //console.log('onRefresh!88')
  //   window.store.refresh();
  //   //console.log('window.store.restore', window.store.restore)
  //   try {
  //     if (window.store && window.store.restore) {
  //       //  console.log('store restore');
  //       window.store.restore();
  //     } else {
  //       //  console.log('store restore DONT EXITS ');
  //     }
  //   } catch (error) {
  //     captureException(error);
  //     console.log("#err", error);
  //   }
  // };
  handleChange = event => {
    console.log(this.state.creditValue);
    this.setState({ creditValue: event.target.value });
  };

  paddleSuccessCallback = response => {
    alert("Thank you. your credits will be in your account in a feew minutes");
  };

  doPayment = () => {
    window.Paddle.Checkout.open({
      passthrough: this.props.projectKey,
      product: window.isDev === true ? 10675 : 651098,
      email: this.props.currentUser.email || "paddle-purchased@timeto.work",
      successCallback: this.paddleSuccessCallback
    });
  };

  render() {
    // ****************** STRIPE ****************
    // const headInvoice = ["Status", "Url", "credits"];
    // const widthsInvice = [100, "100%", 80];
    // const dataInvoice = [];
    // const invoceArray = [...(this.props.invoices || [])].sort((a, b) => {
    //   if (a.createDate > b.createDate) {
    //     return -1;
    //   }
    //   if (a.createDate < b.createDate) {
    //     return 1;
    //   }
    //   return 0;
    // });

    // for (let index = 0; index < invoceArray.length; index++) {
    //   const invoice = invoceArray[index];
    //   if (invoice)
    //     dataInvoice.push([
    //       invoice.stripeInvoiceStatus,
    //       <a href={invoice.stripeInvoiceUrl} target="_blank">
    //         {invoice.stripeInvoiceStatus !== "paid" ? "pay invoice" : "Receipt"}
    //       </a>,
    //       invoice.credits,
    //     ]);
    // }
    // <StripeInvoice buttonDisabled={this.state.buttonDisabled} nameToInvoice={this.state.nameToInvoice} setState={this.setState} creditsToInvoice={this.state.creditsToInvoice} currentUser={this.props.currentUser} headInvoice={headInvoice} widthsInvice={widthsInvice} dataInvoice={dataInvoice}></StripeInvoice>
    // ****************** STRIPE END ****************

    return (
      <div style={{ width: "100%", paddingRight: 10 }}>
        {isBrowser && !isElectron && (
          <>
            <button
              className="ui compact icon button"
              style={{ width: "100%", margin: "0px", marginBottom:"6px" }}
              onClick={this.doPayment}
            >
              {window._getText("AddMoreCredits")}
              <i aria-hidden="true" className="cart icon"></i>
            </button>
            To buy more than 10 use the amount drop down in the top left corner
            in the checkout
          </>
        )}
        {isElectron && (
          <div>
            <h5>{window._getText("wallet.title1")}</h5>
            {/* <span>Current balance: {project.credits || 0}</span> */}
            <div style={{}}>
              <select
                value={this.state.creditValue}
                onChange={this.handleChange}
                style={{ width: "100%" }}
              >
                {/* <option value="mac1credit">
                  1 {window._getText("userCredits")}{" "}
                </option> */}

                <option value="mac5credit">
                  5 {window._getText("userCredits")}{" "}
                </option>
                <option value="mac10credit">
                  10 {window._getText("userCredits")}{" "}
                </option>
                <option value="mac20credit">
                  20 {window._getText("userCredits")}
                </option>
                {/* <option value='30credit'>30 user credits </option> */}
                <option value="mac40credit">
                  40 {window._getText("userCredits")}{" "}
                </option>
                {/* <option value='50credit'>50 user credits </option>
             <option value='60credit'>60 user credits </option>
             <option value='70credit'>70 user credits </option> */}
                <option value="mac80credit">
                  80 {window._getText("userCredits")}{" "}
                </option>
                {/* <option value='90credit'>90 user credits </option> */}
                <option value="mac100credit">
                  100 {window._getText("userCredits")}{" "}
                </option>
                <option value="mac150credit">
                  150 {window._getText("userCredits")}{" "}
                </option>
                {/* <option value='mac1creditSub'>1 SUB s{window._getText('userCredits')} </option>
                <option value='mac5creditSub'>5 SUB {window._getText('userCredits')} </option> */}

                {/* <option value='200credit'>200 user credits </option>
             <option value='250credit'>250 user credits </option> */}
              </select>
            </div>
            <div>({window._getText("currentPrice")})</div>
            <div style={{ paddingBottom: "10px", paddingTop: "10px" }}>
              <Button
                color="green"
                compact
                style={{ width: "100%" }}
                disabled={this.state.buttonDisabled}
                onClick={this.onAddMoreCreditsMac}
                content={
                  !this.state.buttonDisabled
                    ? window._getText("AddMoreCredits")
                    : window._getText("wallet.pleasewait")
                }
              ></Button>
            </div>
            {/* <div style={{ border: 'solid 0px red' }}>
              <Button compact style={{ width: '100%' }} onClick={this.onRefresh} >Restore previous purchase</Button>
            </div> */}
          </div>
        )}
      </div>
    );
  }
}

export default connect(state => ({
  invoices: state.firestore.ordered.invoices,
  billing: state.firestore.data.billing,
  currentUser: state.firebase.profile,
  project: state.firestore.data.project,
  projectKey: state.firebase.profile.projectKey
}))(Wallet);
