import React, { Component } from "react";
import Label from "semantic-ui-react/dist/commonjs/elements/Label";
import Menu from "semantic-ui-react/dist/commonjs/collections/Menu";
import Image from "semantic-ui-react/dist/commonjs/elements/Image";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import { Message } from "../../common/component/Button";
import { withRouter } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClock as icoToday,
  faClipboardListCheck as icoTasks,
  faCommentsAlt as icoChat,
  faInbox as icoInbox,
  faUserFriends as icoUsers,
  faChartBar as icoList,
  faQuestion as icoHelp,
  faCircle as icoScheduleSelected,
  faCircle as icoSchedule,
  faCalendarAlt as icoScheduleUser,
  faPlus as icoAddSchedule,
  faHome as icoHome,
} from "@fortawesome/pro-duotone-svg-icons";
import { isMobile } from "react-device-detect";
import moize from "moize";
import { createSelector } from "reselect";

import { connect } from "react-redux";
import noUserImage from "../../../img/noimg.png";
import { getUserImage } from "../../../core/util/helpers";
import PropTypes from "prop-types";

import { isElectron } from "react-device-detect";
let ipcRenderer;

if (isElectron) {
  ipcRenderer = window.require("electron").ipcRenderer;
}

let isMacOs = process.env.REACT_APP_MACOS === "1";

class MainMenu extends Component {
  state = {
    isLandscape: true,
    msgCount: 0,
    inOnline: true,
    leftMenuOpen: true,
  };
  static contextTypes = { router: PropTypes.object };
  styles = {};

  componentDidMount = () => {
    window.addEventListener("online", this.onlineStatus);
    window.addEventListener("offline", this.onlineStatus);
  };
  componentWillUnmount = () => {
    window.removeEventListener("online", this.onlineStatus);
    window.removeEventListener("offline", this.onlineStatus);
  };

  onlineStatus = () => {
    console.log("navigator.onLine", navigator.onLine);
    this.setState({ inOnline: navigator.onLine });
  };

  msgCounterTimer = null;
  msgCounter = 0;
  requestCount = 0;
  msgCount = moize(
    (alerts) => {
      var msgCount = 0;
      if (alerts && alerts.m)
        Object.keys(alerts.m).forEach((o) => {
          var obj = alerts.m[o];
          msgCount += Number(obj.m || 0);
        });

      // console.log('----')
      // console.log('-- this.msgCounter', this.msgCounter)
      // console.log('-- msgCount', msgCount)
      // console.log('----')
      if (this.msgCounterTimer) clearTimeout(this.msgCounterTimer);

      if (this.msgCounter !== msgCount) {
        if (window.FirebasePlugin || isElectron) {
          this.msgCounterTimer = setTimeout(() => {
            this.setOsBadge();
          }, 1000);
        }
      }

      this.msgCounter = msgCount;
      return msgCount;
    },
    { maxSize: 2 }
  );

  trigger = (
    <span>
      <Icon size="large" name="user circle" />
    </span>
  );

  timerGetMsgCount = null;
  getMsgCount = () => {
    if (this.timerGetMsgCount) clearTimeout(this.timerGetMsgCount);

    this.timerGetMsgCount = setTimeout(() => {
      //console.log('>>> timerGetMsgCount')
      const alerts = this.props.currentUser.alerts || {};
      let msgCount = 0;
      if (alerts.m)
        Object.keys(alerts.m).forEach((o) => {
          var obj = alerts.m[o];
          msgCount += Number(obj.m || 0);
        });
      if (this.state.msgCount !== msgCount) {
        //console.log('>>> RE SET MSGCOUNT')
        this.setState({ msgCount });
      }
    }, 1000);
  };

  setOsBadge = () => {
    // if (window.FirebasePlugin)
    //   window.FirebasePlugin.setBadgeNumber(Number(this.msgCounter + this.requestCount))

    if (isElectron) {
      ipcRenderer.send("setBadge", Number(this.msgCounter + this.requestCount));
    }
  };

  innerMenu = (isLandscape) => {
    console.log(">>>innerMenu 1");
    const { currentUser, requestData } = this.props;
    const role = currentUser.role || 0;
    const isAdmin = role > 199;
    const isManager = role > 149 && role < 199;
    const isUser = !isManager && !isAdmin;
    const alerts = currentUser.alerts || {};
    const requestCount = requestData ? requestData.length : 0;

    //this.getMsgCount()
    //let msgCount = this.state.msgCount

    let msgCount = this.msgCount(alerts);

    if (msgCount + requestCount !== this.msgCount + this.requestCount) {
      this.requestCount = requestCount;
      this.setOsBadge();
    }
    //console.log('>>this.msgCount>', msgCount)
    // if (alerts.m)
    //   Object.keys(alerts.m).forEach(o => {
    //     var obj = alerts.m[o]
    //     msgCount += Number(obj.m || 0)
    //   })

    //   console.log('currentUser', currentUser)

    return (
      <React.Fragment>
        {/* {isLandscape &&
          <Menu.Item name='Work' >
            <Image src={imgLogo} size='mini' alt='TimeTo.Work' style={this.styles.s1} />
          </Menu.Item>
        } */}
        {!isAdmin && !isManager && (
          <React.Fragment>
            <Menu.Item as={NavLink} to="/home" name="Home">
              <Icon name="home" size="large" className="menuAnimation" />
              {false && "Home"}
            </Menu.Item>
            <Menu.Item as={NavLink} to="/schedules" name="Schedule">
              <Icon
                name="calendar alternate"
                size="large"
                className="menuAnimation"
              />
              {false && "Schedule"}
            </Menu.Item>
            <Menu.Item as={NavLink} to="/chat1">
              <Icon name="comments" size="large" className="menuAnimation" />
              {false && "Messages"}
              {msgCount > 0 && (
                <Label
                  color="purple"
                  className="lblMsgCountAni"
                  style={this.styles.s2}
                >
                  {msgCount}
                </Label>
              )}
            </Menu.Item>
            <Menu.Item as={NavLink} to="/requests1">
              <Icon name="inbox" size="large" className="menuAnimation" />
              {false && "Messages"}
              {requestCount > 0 && (
                <Label
                  color="teal"
                  className="lblMsgCountAni"
                  style={this.styles.s3}
                >
                  {requestCount}
                </Label>
              )}
            </Menu.Item>
          </React.Fragment>
        )}
        {(isAdmin || isManager) && (
          <React.Fragment>
            <Menu.Item as={NavLink} to="/schedule/schedule">
              <Icon
                name="calendar alternate"
                size="large"
                className="menuAnimation"
              />
              {false && "Schedule"}
            </Menu.Item>
            <Menu.Item as={NavLink} to="/schedule/users">
              <Icon name="users" size="large" className="menuAnimation" />
              {false && "Staff"}
            </Menu.Item>
            <Menu.Item as={NavLink} to="/reports">
              <Icon name="clipboard" size="large" className="menuAnimation" />
              {false && "Reports"}
            </Menu.Item>

            <Menu.Item as={NavLink} to="/chat">
              <Icon name="mail" size="large" className="menuAnimation" />
              {msgCount > 0 && (
                <Label
                  color="purple"
                  className="lblMsgCountAni"
                  style={this.styles.s4}
                >
                  {msgCount}
                </Label>
              )}
              {requestCount > 0 && (
                <Label
                  color="teal"
                  className="lblMsgCountAni"
                  style={this.styles.s5}
                >
                  {requestCount}
                </Label>
              )}
              {false && "Messages"}
            </Menu.Item>
            {/* <Menu.Item as={NavLink} to="/schedule/settings" >
              <Icon name="setting" size='large' className='menuAnimation' />
              {false && 'Settings'}
            </Menu.Item> */}
          </React.Fragment>
        )}
        {/* {isMobile ? 'MOBILE' : 'Vanlig'}<br />
        {isLandscape ? 'isLandscape' : 'portrait'} */}

        <Menu.Item style={this.styles.s6} as={NavLink} to="/appmenu">
          {currentUser.extraData && (
            <Image
              style={this.styles.s7}
              size="mini"
              floated="left"
              src={
                currentUser.extraData.image
                  ? getUserImage(
                    currentUser.projectKey,
                    currentUser.inSysUserKey,
                    currentUser.extraData.image
                  )
                  : noUserImage
              }
              alt=""
            />
          )}
          {!currentUser.extraData && (
            <Icon name="user" size="large" className="menuAnimation" />
          )}
          {false && "Account"}
        </Menu.Item>

        {/* <Menu.Item name='Settings'>
          <Dropdown
            className='menuAnimation'
            inline
            trigger={this.trigger}
            pointing="top left"
            icon={null}
          >
            <Dropdown.Menu pointing="top left" icon={null}>
              <Dropdown.Item text="Account" as={NavLink} to="/user/account" />
              <Dropdown.Item text="Settings" as={NavLink} to="/user/settings" />
              <Dropdown.Item text="Sign Out" as={NavLink} to="/signout" />
            </Dropdown.Menu>
          </Dropdown>
          {!isLandscape && 'Me'}
        </Menu.Item> */}
      </React.Fragment>
    );
  };

  goTo = (url) => () => {
    this.props.history.push(url);
    if (ipcRenderer) {
      if (url.startsWith("/schedule/schedule/")) {
        ipcRenderer.send("setMenu", "/schedule");
      } else {
        ipcRenderer.send("setMenu", url);
      }
    }
  };

  getBg = (key, index) => {
    if (
      window.location.pathname === "/schedule/schedule/default" &&
      this.props.defaultScheduleKey === "default" &&
      index === 0
    ) {
      return "var(--color--selected-bgcolor)";
    } else if (window.location.pathname === "/schedule/schedule/default") {
      return this.props.defaultScheduleKey === key
        ? "var(--color--selected-bgcolor)"
        : "";
    } else {
      return window.location.pathname === "/schedule/schedule/" + key
        ? "var(--color--selected-bgcolor)"
        : "";
    }
  };
  toggleLeftMenu = () => {
    this.props.onToggleLeftMenuOpen();
    this.setState({ leftMenuOpen: !this.state.leftMenuOpen });
  };

  onNewschedule = () => {
    console.log(
      "window.location.pathname",
      window.location.pathname.indexOf("schedule")
    );
    if (window.location.pathname.indexOf("schedule") === -1) {
      this.goTo("/schedule/schedule/default")();
      setTimeout(() => {
        window.dispatchEvent(new CustomEvent("newschedule", { detail: {} }));
      }, 500);
    } else window.dispatchEvent(new CustomEvent("newschedule", { detail: {} }));
  };

  editSchedule = (schedule) => () => {
    window.dispatchEvent(
      new CustomEvent("newschedule", { detail: { schedule } })
    );
  };
  newSchedule = () => {
    window.dispatchEvent(new CustomEvent("newschedule", { detail: {} }));
  };
  selectedMenu = (index, leftMenuOpen, colors) => {
    return { fontSize: leftMenuOpen ? "18px" : "26px", color: colors[index] };
  };
  getCss = (leftMenuOpen, selected) => {
    return {
      minHeight: "28px",
      display: "flex",
      fontSize: "14px",
      paddingLeft: "15px",
      paddingRight: "15px",
      paddingBottom: leftMenuOpen ? "4px" : "4px",
      paddingTop: leftMenuOpen ? "7px" : "4px",
      background: selected,
      borderRadius: "6px",
      margin: "0px 8px 4px",
    };
  };
  render() {
    const colors = [
      "#2185d0",
      "#6435c9",
      "#a333c8",
      "#fbbd08",
      "#b5cc18",
      "#21ba45",
      "#02c39a",
      "#2185d0",
      "#6435c9",
      "#a333c8",
      "#fbbd08",
      "#b5cc18",
      "#21ba45",
      "#02c39a",
      "#2185d0",
      "#6435c9",
      "#a333c8",
      "#fbbd08",
      "#b5cc18",
      "#21ba45",
      "#02c39a",
      "#2185d0",
      "#6435c9",
      "#a333c8",
      "#a333c8",
      "#fbbd08",
      "#b5cc18",
      "#21ba45",
      "#02c39a",
      "#2185d0",
      "#6435c9",
      "#a333c8",
    ];
    const {
      currentUser,
      requestData,
      isLandscape,
      isKeyboardOpen,
      project,
      leftMenuOpen,
    } = this.props;

    // console.log('>>render MenuSchema')
    // const role = currentUser.role || 0
    //const isAdmin = role > 101
    const alerts = currentUser.alerts || {};
    //const requestCount = requestData ? requestData.length : 0

    //this.getMsgCount()
    //let msgCount = this.state.msgCount
    const role = currentUser.role || 0;

    const isAdmin = role > 199;
    const isManager = role > 149 && role < 199;
    const isUser = !isManager && !isAdmin;
    let msgCount = this.msgCount(alerts);

    this.styles = _styles(
      leftMenuOpen,
      colors,
      isAdmin,
      isManager,
      isKeyboardOpen
    );

    if (isMacOs === true) {
      //var credits = '-'
      var userCount = "-";
      var requestCount = "-";

      if (project) {
        //credits = project.credits
        var count = 0;
        if (project.cache)
          Object.keys(project.cache).forEach(
            (key) => (count += project.cache[key].a === true ? 1 : 0)
          );
        userCount = count;
      }

      if (requestData) {
        requestCount = requestData ? requestData.length : 0;
      }

      if (msgCount + requestCount !== this.msgCount + this.requestCount) {
        this.requestCount = requestCount;
        this.setOsBadge();
      }

      var schedualArrayKeys = Object.keys(this.props.schedules || {});
      var schedualArray = [];
      schedualArrayKeys.forEach((key) => {
        schedualArray.push({ ...this.props.schedules[key], key });
      });

      // console.log('schedualArray>>>', schedualArray)
      schedualArray = schedualArray.sort((item1, item2) => {
        if (!item1 || !item2) return 0;

        if (!item1.createdAt || !item2.createdAt) return 0;
        // console.log(item1.createdAt)

        if (item1.createdAt.seconds < item2.createdAt.seconds) return -1;
        if (item1.createdAt.seconds > item2.createdAt.seconds) return 1;
        return 0;
      });

      // console.log('schedualArray>>>', schedualArray)

      // var doRedirect = false
      // schedualArray.forEach(schedual => {
      //   if (schedual === null)
      //     doRedirect = true
      // })

      // if (doRedirect === true) {
      //   this.goTo('/schedule/schedule/default')
      //   return (null)
      // }

      const goToPreferences = () => {
        window.dispatchEvent(new CustomEvent("preferences"));
      };

      const isAnonymous =
        (currentUser.email || "").indexOf("anonymous_") !== -1;


      const isDemo = (currentUser.email || '').toLowerCase() === 'demo@timeto.work'

      return (
        <React.Fragment>
          <div className="noPrint onboardStep_mainMenu" style={this.styles.s8}>
            <div style={this.styles.s9}>
              {(isAdmin || isManager) && (
                <div style={this.styles.s10}>
                  {/* <i aria-hidden="true" onClick={this.toggleLeftMenu} className="calendar alternate meduim icon" style={this.styles.s11} ></i> */}
                  {leftMenuOpen && (
                    <React.Fragment>
                      <div style={this.styles.s12}>
                        {" "}
                        {window._getText("SCHEDULES")}
                        <span
                          style={this.styles.s123}
                          onClick={this.onNewschedule}
                        >
                          <Icon name="plus" />
                        </span>
                      </div>
                      {/* tooltip={window._getText('schedual.m.addNewSchedule')} */}
                    </React.Fragment>
                  )}
                </div>
              )}

              {(isAdmin || isManager) &&
                !this.props.schedules &&
                schedualArray.length === 0 && (
                  <div style={this.styles.s13} onClick={this.onNewschedule}>
                    <i
                      aria-hidden="true"
                      className=" plus meduim icon"
                      style={this.styles.s14}
                    ></i>
                    {window._getText("schedual.m.addNewSchedule")}
                  </div>
                )}

              <div style={this.styles.s15}>
                {(isAdmin || isManager) &&
                  this.props.schedules &&
                  schedualArray.map((schedule, index) => {
                    if (!schedule) return null;
                    var scheduleKey = schedule.key;
                    const selected = this.getBg(scheduleKey, index);

                    return (
                      <div
                        className="tour_schedule_1"
                        key={scheduleKey}
                        style={this.getCss(leftMenuOpen, selected)}
                        onClick={this.goTo("/schedule/schedule/" + scheduleKey)}
                      >
                        {selected ? (
                          <FontAwesomeIcon
                            icon={icoScheduleSelected}
                            style={this.selectedMenu(
                              index,
                              leftMenuOpen,
                              colors
                            )}
                          ></FontAwesomeIcon>
                        ) : (
                            <FontAwesomeIcon
                              swapOpacity
                              icon={icoSchedule}
                              style={this.selectedMenu(
                                index,
                                leftMenuOpen,
                                colors
                              )}
                            ></FontAwesomeIcon>
                          )}
                        {/* <i aria-hidden="true" className={selected ? "circle  meduim icon" : "circle outline meduim icon"} style={{}} ></i> */}
                        {leftMenuOpen && (
                          <React.Fragment>
                            <div
                              className="onboardStep_schedule"
                              style={this.styles.s17}
                            >
                              {schedule.name || "#unknown"}
                            </div>
                            {selected && isAdmin && (
                              // <div tooltip={window._getText("schedual.m.edit")}>
                              <span
                                className="ico ico-cog"
                                style={this.styles.s18}
                                onClick={this.editSchedule(schedule)}
                              ></span>
                            )
                              // </div>
                            }
                          </React.Fragment>
                        )}
                      </div>
                    );
                  })}
              </div>

              {isAnonymous && (
                <div onClick={goToPreferences}>
                  <Message style={{ margin: 6, fontSize: 12 }} color="red">
                    <b> You have a anonymous account! </b>
                    <p style={{ color: 'red' }}>
                      Add your email to protect your schedule.
                      <br />
                      Click here
                    </p>
                  </Message>
                </div>
              )}
              {isDemo &&

                <div onClick={this.goTo('/signout')} >
                  <Message style={{ margin: 6, fontSize: 12 }} color="red">
                    <b>
                      {window._getText('DemoAccount')}
                    </b>
                  </Message></div>
              }

              <div style={this.styles.s19}>
                {isUser && (
                  <React.Fragment>
                    <div style={this.styles.s20} onClick={this.goTo("/home")}>
                      {/* <i aria-hidden="true" className="home meduim icon" style={this.styles.s21}></i> */}
                      <FontAwesomeIcon
                        icon={icoHome}
                        style={this.styles.s22}
                      ></FontAwesomeIcon>

                      {leftMenuOpen && (
                        <React.Fragment>
                          <div style={this.styles.s23}>
                            {window._getText("schedual.m.Home")}
                          </div>
                          <span style={this.styles.s24}></span>
                        </React.Fragment>
                      )}
                    </div>

                    <div
                      style={this.styles.s25}
                      onClick={this.goTo("/schedules")}
                    >
                      <FontAwesomeIcon
                        icon={icoScheduleUser}
                        style={this.styles.s26}
                      ></FontAwesomeIcon>

                      {leftMenuOpen && (
                        <React.Fragment>
                          <div style={this.styles.s27}>
                            {window._getText("schedual.m.Schedule")}
                          </div>
                          <span style={this.styles.s28}></span>
                        </React.Fragment>
                      )}
                    </div>

                    <div style={this.styles.s29} onClick={this.goTo("/chat1")}>
                      <FontAwesomeIcon
                        swapOpacity
                        icon={icoChat}
                        style={this.styles.s30}
                      ></FontAwesomeIcon>

                      {leftMenuOpen && (
                        <React.Fragment>
                          <div style={this.styles.s31}>
                            {window._getText("schedual.m.Chat")}
                          </div>
                          <span style={this.styles.s32}>
                            {msgCount > 0 && (
                              <Label color="purple" style={this.styles.s33}>
                                {msgCount}
                              </Label>
                            )}
                            {msgCount < 1 && (
                              <Label style={this.styles.s34}>{msgCount}</Label>
                            )}
                          </span>
                        </React.Fragment>
                      )}
                    </div>

                    <div
                      style={this.styles.s35}
                      onClick={this.goTo("/requests1")}
                    >
                      <FontAwesomeIcon
                        icon={icoInbox}
                        style={this.styles.s36}
                      ></FontAwesomeIcon>
                      {leftMenuOpen && (
                        <React.Fragment>
                          <div style={this.styles.s37}>
                            {window._getText("schedual.m.Request")}
                          </div>
                          <span style={this.styles.s38}>
                            {requestCount > 0 && (
                              <Label color="teal" style={this.styles.s39}>
                                {" "}
                                {requestCount}
                              </Label>
                            )}
                            {requestCount < 1 && (
                              <Label style={this.styles.s40}>
                                {" "}
                                {requestCount}
                              </Label>
                            )}
                          </span>
                        </React.Fragment>
                      )}
                    </div>
                  </React.Fragment>
                )}

                {(isAdmin || isManager) && (
                  <React.Fragment>
                    {/* <div style={this.styles.s41} onClick={this.goTo('/dev')}>
                      <i aria-hidden="true" className="help icon" style={this.styles.s42}></i>

                      {leftMenuOpen &&
                        <React.Fragment>
                          <div style={this.styles.s43}>Chat</div>
                          <span style={this.styles.s44}>
                            {msgCount > 0 && <Label color='purple' style={this.styles.s45}>{msgCount}</Label>}
                            {msgCount < 1 && <Label   style={this.styles.s46}>{msgCount}</Label>}

                          </span>
                        </React.Fragment>}
                    </div> */}

                    <div style={this.styles.s47} onClick={this.goTo("/today")}>
                      <FontAwesomeIcon
                        icon={icoToday}
                        style={this.styles.s48}
                      ></FontAwesomeIcon>

                      {leftMenuOpen && (
                        <React.Fragment>
                          <div style={this.styles.s49}>
                            {window._getText("schedual.m.Today")}
                          </div>
                          <span style={this.styles.s50}></span>
                        </React.Fragment>
                      )}
                    </div>

                    <div style={this.styles.s51} onClick={this.goTo("/tasks")}>
                      <FontAwesomeIcon
                        icon={icoTasks}
                        style={this.styles.s52}
                      ></FontAwesomeIcon>

                      {leftMenuOpen && (
                        <React.Fragment>
                          <div style={this.styles.s53}>
                            {window._getText("schedual.m.Tasks")}
                          </div>
                          <span style={this.styles.s54}></span>
                        </React.Fragment>
                      )}
                    </div>

                    <div style={this.styles.s55} onClick={this.goTo("/chat")}>
                      <FontAwesomeIcon
                        swapOpacity
                        icon={icoChat}
                        style={this.styles.s56}
                      ></FontAwesomeIcon>

                      {leftMenuOpen && (
                        <React.Fragment>
                          <div style={this.styles.s57}>
                            {window._getText("schedual.m.Chat")}
                          </div>
                          <span style={this.styles.s58}>
                            {msgCount > 0 && (
                              <Label color="purple" style={this.styles.s59}>
                                {msgCount}
                              </Label>
                            )}
                            {msgCount < 1 && (
                              <Label style={this.styles.s60}>{msgCount}</Label>
                            )}
                          </span>
                        </React.Fragment>
                      )}
                    </div>

                    <div
                      style={this.styles.s61}
                      onClick={this.goTo("/requests")}
                    >
                      <FontAwesomeIcon
                        icon={icoInbox}
                        style={this.styles.s62}
                      ></FontAwesomeIcon>

                      {leftMenuOpen && (
                        <React.Fragment>
                          <div style={this.styles.s63}>
                            {window._getText("schedual.m.Request")}
                          </div>
                          <span style={this.styles.s64}>
                            {requestCount > 0 && (
                              <Label color="teal" style={this.styles.s65}>
                                {" "}
                                {requestCount}
                              </Label>
                            )}
                            {requestCount < 1 && (
                              <Label style={this.styles.s66}>
                                {" "}
                                {requestCount}
                              </Label>
                            )}
                          </span>
                        </React.Fragment>
                      )}
                    </div>

                    {isAdmin && (
                      <React.Fragment>
                        <div
                          style={this.styles.s67}
                          onClick={this.goTo("/schedule/users")}
                        >
                          <FontAwesomeIcon
                            icon={icoUsers}
                            style={this.styles.s68}
                          ></FontAwesomeIcon>

                          {leftMenuOpen && (
                            <React.Fragment>
                              <div
                                className="onboardStep_staff"
                                style={this.styles.s69}
                              >
                                {window._getText("schedual.m.Staff")}
                              </div>
                              <span style={this.styles.s70}>{userCount}</span>
                            </React.Fragment>
                          )}
                        </div>
                        <div
                          style={this.styles.s71}
                          onClick={this.goTo("/reports")}
                        >
                          <FontAwesomeIcon
                            swapOpacity
                            icon={icoList}
                            style={this.styles.s72}
                          ></FontAwesomeIcon>
                          {leftMenuOpen && (
                            <React.Fragment>
                              <div style={this.styles.s73}>
                                {window._getText("schedual.m.Reports")}
                              </div>
                            </React.Fragment>
                          )}
                        </div>

                        <div
                          style={this.styles.s74}
                          onClick={this.goTo("/tour")}
                        >
                          <FontAwesomeIcon
                            icon={icoHelp}
                            style={this.styles.s75}
                          ></FontAwesomeIcon>
                          {leftMenuOpen && (
                            <React.Fragment>
                              <div style={this.styles.s76}>
                                {window._getText("schedual.m.Tour")}
                              </div>
                            </React.Fragment>
                          )}
                        </div>
                      </React.Fragment>
                    )}
                  </React.Fragment>
                )}
                {/* <div style={this.styles.s77} onClick={this.goTo('/credits')}>
                  <i aria-hidden="true" className="money meduim icon" style={this.styles.s78}></i>
                  {leftMenuOpen &&
                    <React.Fragment>
                      <div style={this.styles.s79}>Credits</div>
                      <span style={this.styles.s80}>{credits}</span>
                    </React.Fragment>}
                </div> */}
              </div>
              <div style={this.styles.s81}> </div>
              <div style={this.styles.s82}>
                <div style={this.styles.s83}>
                  {(isAdmin || isManager) && false && (
                    <div style={this.styles.s84} onClick={this.newSchedule}>
                      <div
                        tooltip_t={window._getText("schedual.m.addNewSchedule")}
                        style={this.styles.s85}
                      >
                        <span
                          className="ico ico-plus"
                          style={this.styles.s86}
                        ></span>
                      </div>
                    </div>
                  )}
                </div>
                <div style={this.styles.s87}>
                  <div style={this.styles.s88} onClick={this.toggleLeftMenu}>
                    {leftMenuOpen && (
                      <span
                        className="ico ico-left-open-mini"
                        style={this.styles.s89}
                      ></span>
                    )}
                    {!leftMenuOpen && (
                      <span
                        className="ico ico-right-open-mini"
                        style={this.styles.s90}
                      ></span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {
            this.state.inOnline === false && (
              <div style={this.styles.s91}>
                {/* <span><Icon name='exclamation' color='red' />
              <div style={this.styles.s92}>Offline</div></span>
               */}
                {/* TODO FIX THIS */}
              Offline
              </div>
            )
          }
        </React.Fragment >
      );
    }

    //console.log('>>>isMacOs end!', isMacOs)

    if (!isLandscape) {
      return (
        <React.Fragment>
          <div style={this.styles.s93}>
            <div style={this.styles.s94} className="menuPortrait">
              <Menu icon="labeled" style={this.styles.s95}>
                {this.innerMenu(isLandscape)}
              </Menu>
            </div>
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <div className="menuLandscape">
            <Menu pointing icon attached="top" vertical style={this.styles.s96}>
              {this.innerMenu(isLandscape)}
            </Menu>
            {this.state.inOnline === false && (
              <div style={this.styles.s97}>
                <span>
                  <Icon name="exclamation circle" color="red" />
                  <div style={this.styles.s98}>Offline</div>
                </span>
              </div>
            )}
          </div>
        </React.Fragment>
      );
    }
  }
}

const getRequestData = createSelector(
  (state) => state.firestore.ordered["requestAllListner"],
  (state) => state.firestore.ordered["requestMyListner"],
  (state) => state.firestore.ordered["requestAdminListner"],
  (requestAllListner, requestMyListner, requestAdminListner) => {
    return [
      ...(requestAllListner || []),
      ...(requestMyListner || []),
      ...(requestAdminListner || []),
    ];
  }
);

const mapStateToProps = (state) => {
  let schedules = null;
  if (state.firestore.data.schedules) {
    schedules = state.firestore.data.schedules;
  }

  var requestData = getRequestData(state);
  return {
    currentUser: state.firebase.profile,
    requestData,
    isKeyboardOpen: state.viewstate.isKeyboardOpen,
    isLandscape: state.viewstate.isLandscape,
    project: state.firestore.data.project,
    schedules,
  };
};
const mapActions = {};
export default withRouter(connect(mapStateToProps, mapActions)(MainMenu));
//export default MenuSchema;

const _styles = (leftMenuOpen, colors, isAdmin, isManager, isKeyboardOpen) => {
  return {
    s1: { padding: "0px", nargin: "0px" },
    s2: { position: "absolute", top: "8px", left: "0px", fontSize: "9px" },
    s3: { position: "absolute", top: "8px", left: "0px", fontSize: "9px" },
    s4: { position: "absolute", top: "8px", right: "8px", fontSize: "9px" },
    s5: { position: "absolute", top: "8px", left: "-2px", fontSize: "9px" },
    s6: { height: "50px" },
    s7: {
      margin: "0",
      marginLeft: "6px",
      borderRadius: "25px",
      border: "solid 1px #ccc",
      width: "29px",
      height: "29px",
    },
    s8: {
      transition: "all .1s ease",
      width: leftMenuOpen ? "200px" : "70px",
      minWidth: leftMenuOpen ? "200px" : "70px",
      position: "relative",
    },
    s9: {
      background: "rgba(1,1,1,0.05)",
      borderRight: "dotted 1px rgba(1,1,1,0.07)",
      transition: "all .1s ease",
      display: "flex",
      height: isElectron ? "100%" : "calc(100% - 36px)",
      flexDirection: "column",
      position: "fixed",
      overflow: "hidden",
      width: leftMenuOpen ? "200px" : "70px",
      minWidth: leftMenuOpen ? "200px" : "70px",
      paddingTop: isElectron ? "20px" : "4px",
      paddingBottom: "0px",
    },
    s10: {
      color: "var(--color--text)",
      fontSize: "14px",
      padding: "0px",
      display: "flex",
      paddingLeft: leftMenuOpen ? "20px" : "20px",
      marginTop: "9px",
    },
    s11: { fontSize: leftMenuOpen ? "18px" : "26px" },
    s12: { flexGrow: "1", color: "gray", fontSize: "9px", marginLeft: "5px" },
    s123: { position: "absolute", right: 8 },
    s13: {
      marginLeft: "5px",
      color: "var(--color--text)",
      fontSize: "12px",
      minHeight: "28px",
      minWidth: "180px",
      display: "flex",
      padding: "8px",
    },
    s14: { fontSize: leftMenuOpen ? "18px" : "26px" },
    s15: { overflowY: "auto", overflowX: "hidden" },
    //s16: { color: colors[index], fontSize: leftMenuOpen ? '18px' : '26px' },
    s17: {
      marginLeft: "5px",
      flexGrow: "1",
      fontWeight: "bold",
      color: "var(--color--text)",
      fontSize: "12px",
    },
    s18: {
      marginRight: "0px",
      color: "var(--color--text)",
      position: "absolute",
      right: 15,
    },
    s19: {
      paddingBottom: "6px",
      marginTop: isAdmin || isManager ? "auto" : "",
      paddingTop: "6px",
      color: "var(--color--text)",
      fontWeight: "bold",
      display: "flex",
      flexDirection: "column",
      alignSelf: "flex-end",
      width: "100%",
      xminHeight: "130px",
    },
    s20: {
      position: "relative",
      paddingLeft: leftMenuOpen ? "20px" : "20px",
      paddingRight: "20px",
      color: "var(--color--text)",
      fontWeight: "bold",
      paddingBottom: leftMenuOpen ? "4px" : "6px",
      paddingTop: leftMenuOpen ? "4px" : "10px",
      display: "flex",
      fontSize: "14px",
      background:
        window.location.pathname === "/home"
          ? "var(--color--selected-bgcolor)"
          : "",
      borderRadius: "6px",
      margin: "0px 8px 4px 8px",
    },
    s21: { fontSize: leftMenuOpen ? "18px" : "26px" },
    s22: { fontSize: leftMenuOpen ? "18px" : "26px", color: "#DA3391" },
    s23: {
      marginLeft: "10px",
      flexGrow: "1",
      color: "var(--color--text)",
      fontSize: "14px",
    },
    s24: {
      xbackgroundColor: "#f1f1f1",
      width: "25px",
      textAlign: "center",
      borderRadius: "20px",
      fontSize: "9px",
      color: "var(--color--text)",
      fontWeight: "bold",
    },
    s25: {
      position: "relative",
      paddingLeft: leftMenuOpen ? "20px" : "20px",
      paddingRight: "20px",
      color: "var(--color--text)",
      fontWeight: "bold",
      paddingBottom: leftMenuOpen ? "4px" : "6px",
      paddingTop: leftMenuOpen ? "4px" : "10px",
      display: "flex",
      fontSize: "14px",
      background:
        window.location.pathname === "/schedules"
          ? "var(--color--selected-bgcolor)"
          : "",
      borderRadius: "6px",
      margin: "0px 8px 4px 8px",
    },
    s26: { fontSize: leftMenuOpen ? "18px" : "26px", color: "#DEC013" },
    s27: {
      marginLeft: "10px",
      flexGrow: "1",
      color: "var(--color--text)",
      fontSize: "14px",
    },
    s28: {
      xbackgroundColor: "#f1f1f1",
      width: "25px",
      textAlign: "center",
      borderRadius: "20px",
      fontSize: "9px",
      color: "var(--color--text)",
      fontWeight: "bold",
    },
    s29: {
      position: "relative",
      paddingLeft: leftMenuOpen ? "20px" : "20px",
      paddingRight: "20px",
      color: "var(--color--text)",
      fontWeight: "bold",
      paddingBottom: leftMenuOpen ? "4px" : "6px",
      paddingTop: leftMenuOpen ? "4px" : "10px",
      display: "flex",
      fontSize: "14px",
      background:
        window.location.pathname === "/chat1"
          ? "var(--color--selected-bgcolor)"
          : "",
      borderRadius: "6px",
      margin: "0px 8px 4px 8px",
    },
    s30: { fontSize: leftMenuOpen ? "18px" : "26px", color: "#13959A" },
    s31: {
      marginLeft: "10px",
      flexGrow: "1",
      color: "var(--color--text)",
      fontSize: "14px",
    },
    s32: {
      xbackgroundColor: "#f1f1f1",
      width: "25px",
      textAlign: "center",
      borderRadius: "20px",
      fontSize: "9px",
      color: "var(--color--text)",
      fontWeight: "bold",
    },
    s33: { fontSize: "9px" },
    s34: { fontSize: "9px" },
    s35: {
      paddingLeft: leftMenuOpen ? "20px" : "20px",
      paddingRight: "20px",
      color: "var(--color--text)",
      fontWeight: "bold",
      paddingBottom: leftMenuOpen ? "4px" : "6px",
      paddingTop: leftMenuOpen ? "4px" : "10px",
      display: "flex",
      fontSize: "14px",
      background:
        window.location.pathname === "/requests1"
          ? "var(--color--selected-bgcolor)"
          : "",
      borderRadius: "6px",
      margin: "0px 8px 4px 8px",
    },
    s36: { fontSize: leftMenuOpen ? "18px" : "26px", color: "#82D23A" },
    s37: {
      marginLeft: "10px",
      flexGrow: "1",
      color: "var(--color--text)",
      fontSize: "14px",
    },
    s38: {
      xbackgroundColor: "#f1f1f1",
      width: "25px",
      textAlign: "center",
      borderRadius: "20px",
      fontSize: "9px",
      color: "var(--color--text)",
      fontWeight: "bold",
    },
    s39: { fontSize: "9px" },
    s40: { fontSize: "9px" },
    s41: {
      position: "relative",
      paddingLeft: leftMenuOpen ? "20px" : "20px",
      paddingRight: "20px",
      color: "var(--color--text)",
      paddingBottom: leftMenuOpen ? "4px" : "6px",
      paddingTop: leftMenuOpen ? "4px" : "10px",
      display: "flex",
      fontSize: "14px",
      background:
        window.location.pathname === "/dev"
          ? "var(--color--selected-bgcolor)"
          : "",
    },
    s42: { fontSize: leftMenuOpen ? "18px" : "26px" },
    s43: {
      marginLeft: "10px",
      flexGrow: "1",
      color: "var(--color--text)",
      fontSize: "14px",
    },
    s44: {
      xbackgroundColor: "#f1f1f1",
      width: "25px",
      textAlign: "center",
      borderRadius: "20px",
      fontSize: "9px",
      color: "var(--color--text)",
    },
    s45: { fontSize: "9px" },
    s46: { fontSize: "9px" },
    s47: {
      position: "relative",
      paddingLeft: "15px",
      paddingRight: "15px",
      paddingBottom: leftMenuOpen ? "4px" : "6px",
      paddingTop: leftMenuOpen ? "4px" : "10px",
      color: "var(--color--text)",
      fontWeight: "bold",
      display: "flex",
      fontSize: "14px",
      background:
        window.location.pathname === "/today"
          ? "var(--color--selected-bgcolor)"
          : "",
      borderRadius: "6px",
      margin: "0px 8px 4px 8px",
    },
    s48: { fontSize: leftMenuOpen ? "18px" : "26px", color: "#DA3391" },
    s49: {
      marginLeft: "10px",
      flexGrow: "1",
      color: "var(--color--text)",
      fontSize: "14px",
    },
    s50: {
      xbackgroundColor: "#f1f1f1",
      width: "25px",
      textAlign: "center",
      borderRadius: "20px",
      fontSize: "9px",
      color: "var(--color--text)",
      fontWeight: "bold",
    },
    s51: {
      position: "relative",
      paddingLeft: "15px",
      paddingRight: "15px",
      paddingBottom: leftMenuOpen ? "4px" : "6px",
      paddingTop: leftMenuOpen ? "4px" : "10px",
      color: "var(--color--text)",
      fontWeight: "bold",
      display: "flex",
      fontSize: "14px",
      background:
        window.location.pathname === "/tasks"
          ? "var(--color--selected-bgcolor)"
          : "",
      borderRadius: "6px",
      margin: "0px 8px 4px 8px",
    },
    s52: { fontSize: leftMenuOpen ? "18px" : "26px", color: "#A334C8" },
    s53: {
      marginLeft: "10px",
      flexGrow: "1",
      color: "var(--color--text)",
      fontSize: "14px",
    },
    s54: {
      xbackgroundColor: "#f1f1f1",
      width: "25px",
      textAlign: "center",
      borderRadius: "20px",
      fontSize: "9px",
      color: "var(--color--text)",
      fontWeight: "bold",
    },
    s55: {
      position: "relative",
      paddingLeft: "15px",
      paddingRight: "15px",
      paddingBottom: leftMenuOpen ? "4px" : "6px",
      paddingTop: leftMenuOpen ? "4px" : "10px",
      color: "var(--color--text)",
      fontWeight: "bold",
      display: "flex",
      fontSize: "14px",
      background:
        window.location.pathname === "/chat"
          ? "var(--color--selected-bgcolor)"
          : "",
      borderRadius: "6px",
      margin: "0px 8px 4px 8px",
    },
    s56: { fontSize: leftMenuOpen ? "18px" : "26px", color: "#13959A" },
    s57: {
      marginLeft: "10px",
      flexGrow: "1",
      color: "var(--color--text)",
      fontSize: "14px",
    },
    s58: {
      xbackgroundColor: "#f1f1f1",
      width: "25px",
      textAlign: "center",
      borderRadius: "20px",
      fontSize: "9px",
      color: "var(--color--text)",
      fontWeight: "bold",
    },
    s59: { fontSize: "9px" },
    s60: { fontSize: "9px" },
    s61: {
      paddingLeft: "15px",
      paddingRight: "15px",
      paddingBottom: leftMenuOpen ? "4px" : "6px",
      paddingTop: leftMenuOpen ? "4px" : "10px",
      color: "var(--color--text)",
      fontWeight: "bold",
      display: "flex",
      fontSize: "14px",
      background:
        window.location.pathname === "/requests"
          ? "var(--color--selected-bgcolor)"
          : "",
      borderRadius: "6px",
      margin: "0px 8px 4px 8px",
    },
    s62: { fontSize: leftMenuOpen ? "18px" : "26px", color: "#82D23A" },
    s63: {
      marginLeft: "10px",
      flexGrow: "1",
      color: "var(--color--text)",
      fontSize: "14px",
    },
    s64: {
      xbackgroundColor: "#f1f1f1",
      width: "25px",
      textAlign: "center",
      borderRadius: "20px",
      fontSize: "9px",
      color: "var(--color--text)",
      fontWeight: "bold",
    },
    s65: { fontSize: "9px" },
    s66: { fontSize: "9px" },
    s67: {
      paddingLeft: "15px",
      paddingRight: "15px",
      paddingBottom: leftMenuOpen ? "4px" : "6px",
      paddingTop: leftMenuOpen ? "4px" : "10px",
      color: "var(--color--text)",
      fontWeight: "bold",
      display: "flex",
      fontSize: "14px",
      background:
        window.location.pathname === "/schedule/users"
          ? "var(--color--selected-bgcolor)"
          : "",
      borderRadius: "6px",
      margin: "0px 8px 4px 8px",
    },
    s68: { fontSize: leftMenuOpen ? "18px" : "26px", color: "#DEC013" },
    s69: {
      marginLeft: "10px",
      flexGrow: "1",
      color: "var(--color--text)",
      fontSize: "14px",
    },
    s70: {
      xbackgroundColor: "#f1f1f1",
      width: "25px",
      textAlign: "center",
      borderRadius: "20px",
      fontSize: "9px",
      color: "var(--color--text)",
      fontWeight: "bold",
    },
    s71: {
      paddingLeft: "15px",
      paddingRight: "15px",
      paddingBottom: leftMenuOpen ? "4px" : "6px",
      paddingTop: leftMenuOpen ? "4px" : "10px",
      color: "var(--color--text)",
      fontWeight: "bold",
      paddingTop: leftMenuOpen ? "4px" : "10px",
      display: "flex",
      fontSize: "14px",
      background:
        window.location.pathname === "/reports"
          ? "var(--color--selected-bgcolor)"
          : "",
      borderRadius: "6px",
      margin: "0px 8px 4px 8px",
    },
    s72: { fontSize: leftMenuOpen ? "18px" : "26px", color: "#6960EF" },
    s73: {
      marginLeft: "10px",
      flexGrow: "1",
      color: "var(--color--text)",
      fontWeight: "bold",
      fontSize: "14px",
    },
    s74: {
      paddingLeft: "15px",
      paddingRight: "15px",
      paddingBottom: leftMenuOpen ? "4px" : "6px",
      paddingTop: leftMenuOpen ? "4px" : "10px",
      color: "var(--color--text)",
      fontWeight: "bold",
      paddingTop: leftMenuOpen ? "4px" : "10px",
      display: "flex",
      fontSize: "14px",
      background:
        window.location.pathname === "/tour"
          ? "var(--color--selected-bgcolor)"
          : "",
      borderRadius: "6px",
      margin: "0px 8px 4px 8px",
    },
    s75: { fontSize: leftMenuOpen ? "18px" : "26px", color: "#E4494E" },
    s76: {
      marginLeft: "10px",
      flexGrow: "1",
      color: "var(--color--text)",
      fontWeight: "bold",
      fontSize: "14px",
    },
    s77: {
      paddingLeft: leftMenuOpen ? "20px" : "20px",
      paddingRight: "20px",
      color: "var(--color--text)",
      paddingBottom: leftMenuOpen ? "4px" : "6px",
      paddingTop: leftMenuOpen ? "4px" : "10px",
      display: "flex",
      fontSize: "14px",
      background:
        window.location.pathname === "/credits"
          ? "var(--color--selected-bgcolor)"
          : "",
    },
    s78: { fontSize: leftMenuOpen ? "18px" : "26px" },
    s79: {
      marginLeft: "10px",
      flexGrow: "1",
      color: "var(--color--text)",
      fontSize: "14px",
    },
    s80: {
      xbackgroundColor: "#f1f1f1",
      width: "25px",
      textAlign: "center",
      borderRadius: "20px",
      fontSize: "9px",
      color: "var(--color--text)",
    },
    s81: { marginTop: isAdmin || isManager ? "0px" : "auto" },
    s82: { display: "flex", paddingTop: "5px" },
    s83: { flexGrow: "1" },
    s84: { paddingLeft: "8px" },
    s85: { display: "inline" },
    s86: { marginRight: "8px", color: "#ccc", fontSize: "20px" },
    s87: {},
    s88: { paddingRight: "8px", paddingLeft: "8px" },
    s89: { color: "var(--color--text)", fontSize: "20px" },
    s90: { color: "var(--color--text)", fontSize: "20px" },
    s91: {
      position: "fixed",
      top: "1px",
      left: "70px",
      fontSize: "10px",
      color: "solver",
    },
    s92: { fontSize: "10px", display: "inline" },
    s93: {
      backgroundColor: "var(--color-bg2)",
      textAlign: "center",
      width: "100%",
      borderTop: "solid 0px rgba(34, 36, 38, 0.15)",
      position: isKeyboardOpen ? "absolute" : "fixed",
      bottom: "0px",
      left: isKeyboardOpen && isMobile ? "-4000px" : "0px",
      zIndex: "100",
    },
    s94: { display: "inline-block" },
    s95: { borderRight: "solid 0px silver" },
    s96: {
      borderTop: "none",
      borderBottom: "none",
      borderRight: "solid 1px var(--color-bg1)",
    },
    s97: { position: "fixed", bottom: "0px", padding: "3px" },
    s98: { fontSize: "11px", display: "inline" },
  };
};
