import React, { Component } from 'react'
import Joyride, { STATUS } from 'react-joyride';




export class Joyrider extends Component {


    pageStartSteps =
        [
            {
                content: (
                    <div style={{ fontSize: '18px' }}>
                        <b style={{ fontSize: '18px' }}>{window._getText('joy.welcome')}</b><br />

                        {window._getText('joy.startbyaddingusers')}
                        <br />{window._getText('joy.clickonstaff')}
                    </div>
                ),

                disableBeacon: true,
                disableOverlayClose: true,
                hideCloseButton: true,
                hideFooter: true,
                placement: 'bottom',
                spotlightClicks: true,
                styles: {
                    options: {
                        zIndex: 10000,
                        primaryColor: 'red',
                        textColor: 'green'
                    },
                },
                target: '.onboardStep_staff',
                //   title: (<div style={{ fontSize: '20px' }}>Welcome!</div>),
            }
        ]

    pageUsersSteps = [
        {
            content: (<div style={{ fontSize: '18px' }}>{window._getText('joy.clickHerToAddUsers')}</div>),
            disableBeacon: true,
            disableOverlayClose: true,
            hideCloseButton: true,
            hideFooter: false,
            placement: 'top',
            spotlightClicks: true,
            styles: {
                options: {
                    zIndex: 10000,
                },
            },
            target: '.onboardStep2',
            //title: 'Add a user or two',
        },
        {
            content: (<div style={{ fontSize: '18px' }}>{window._getText('joy.clickHereToAddMultipleUsers')}</div>),
            disableBeacon: true,
            disableOverlayClose: true,
            hideCloseButton: true,
            hideFooter: false,
            placement: 'top',
            spotlightClicks: true,
            styles: {
                options: {
                    zIndex: 10000,
                },
            },
            target: '.onboardStep3',
            // title: 'Bulk import',
        },
        {
            content: (<div style={{ fontSize: '18px' }}>{window._getText('joy.whenYourDone')}<br />{window._getText('joy.goToSchedule')} </div>),
            disableBeacon: true,
            disableOverlayClose: true,
            hideCloseButton: true,
            hideFooter: false,
            placement: 'right',
            spotlightClicks: true,
            styles: {
                options: {
                    zIndex: 10000,
                },
            },
            target: '.onboardStep_schedule',
            // title: '',
        },





    ]


    pageScheduleSteps = [
        {
            content: (<div style={{ fontSize: '18px' }}>{window._getText('joy.clickHereToAddAShift')},
                <br />{window._getText('joy.orClickOnTheSchedule')}</div>),
            disableBeacon: true,
            disableOverlayClose: true,
            hideCloseButton: true,
            hideFooter: false,
            placement: 'right',
            spotlightClicks: true,
            styles: {
                options: {
                    zIndex: 10000,
                },
            },
            target: '.onboardStep_topmenu_addshift',
            //title: 'Add shift',
        },
        // {
        //     content: (<div style={{ fontSize: '18px' }}>
        //         Bild på moudl här....


        //     </div>),
        //     placement: 'right',
        //     disableBeacon: true,
        //     disableOverlayClose: true,
        //     hideCloseButton: true,
        //     hideFooter: false,
        //     spotlightClicks: true,

        //     styles: {
        //         options: {
        //             zIndex: 10000,
        //         },
        //     },
        //     target: '.onboardStep_topmenu_save',
        //     // title: 'Save and publish',
        // },

        {
            content: (<div style={{ fontSize: '18px' }}>{window._getText('joy.addMultipleRows')}</div>),
            disableBeacon: true,
            disableOverlayClose: true,
            hideCloseButton: true,
            hideFooter: false,
            placement: 'right',
            spotlightClicks: true,

            styles: {
                options: {
                    zIndex: 10000,
                },
            },
            target: '.onboardStep_topmenu_addusers',
            // title: 'Add multiple users',
        },
        {
            content: (<div style={{ fontSize: '18px' }}>{window._getText('joy.viewWeeks')}</div>),
            disableBeacon: true,
            disableOverlayClose: true,
            hideCloseButton: true,
            hideFooter: false,
            placement: 'bottom',
            spotlightClicks: true,

            styles: {
                options: {
                    zIndex: 10000,
                },
            },
            target: '.onboardStep_topmenu_viewweeks',
            // title: 'Add multiple users',
        },
        {
            content: (<div style={{ fontSize: '18px' }}>{window._getText('joy.dontForgetToSave')}</div>),
            placement: 'right',
            disableBeacon: true,
            disableOverlayClose: true,
            hideCloseButton: true,
            hideFooter: false,
            spotlightClicks: true,

            styles: {
                options: {
                    zIndex: 10000,
                },
            },
            target: '.onboardStep_topmenu_save',
            // title: 'Save and publish',
        },





    ]
    state = { run: false, startIsFinished: false }

    componentDidMount = () => {
        var run = {
            pageUsersSteps: !localStorage.getItem('joyrun_pageUsersSteps') ? true : false,
            pageStartSteps: !localStorage.getItem('joyrun_pageStartSteps') ? true : false,
            pageScheduleSteps: !localStorage.getItem('joyrun_pageScheduleSteps') ? true : false,
            nada: false
        }

        //DEBUG
        // if (1 == 1) {
        //     run.pageUsersSteps = true
        //     run.pageStartSteps = true
        //     run.pageScheduleSteps = true
        // }


        this.setState({ run })
    };


    getPageSteps = () => {


        // console.log('this.props.page', this.props.page)
        // console.log('this.state', this.state)

        if (this.state.startIsFinished === false && this.props.page !== '/schedule/users') {
            return { stepsData: this.pageStartSteps, stepKey: 'pageStartSteps' } //boot up
        }
        else {
            if (this.state.startIsFinished === false)
                this.setState({ startIsFinished: true })
        }


        if (this.props.page === '/schedule/users') {
            return { stepsData: this.pageUsersSteps, stepKey: 'pageUsersSteps' }
        }
        else if (this.props.page === '/schedule/schedule/default') {
            return { stepsData: this.pageStartSteps, stepKey: 'pageStartSteps' } //boot up
        }
        else if ((this.props.page).toString().startsWith('/schedule/schedule/')) {
            return { stepsData: this.pageScheduleSteps, stepKey: 'pageScheduleSteps' }
        }
        else {
            return { stepsData: [], stepKey: 'nada' }
        }

    }



    handleJoyrideCallback = (data) => {
        // eslint-disable-next-line no-unused-vars
        const { action, index, type, status, lifecycle } = data;
        const { stepKey } = this.getPageSteps()
        // console.log('status', status)
        // console.log('action', action)
        // console.log('index', index)
        // console.log('type', type)
        //console.log('stepKey', stepKey)
        // console.log('data', data)
        // console.log('----------------------------')


        if (stepKey === 'pageUsersSteps' && type === 'tour:start')
            localStorage.setItem('joyrun_pageStartSteps', true)//remove start step




        if (([STATUS.FINISHED, STATUS.SKIPPED]).includes(status)) {


            localStorage.setItem(`joyrun_${stepKey}`, true)
            var run = this.state.run
            run[stepKey] = false
            this.setState({ run })
        }

    }



    render() {
        const { run } = this.state
        const { stepsData, stepKey } = this.getPageSteps()
        return (
            <Joyride
                locale={{ back: window._getText('joy.Back'), close: window._getText('joy.Close'), last: window._getText('joy.Last'), next: window._getText('joy.Next'), skip: window._getText('joy.Skip') }}
                key={stepKey}
                steps={stepsData}
                continuous={true}
                run={run[stepKey]}
                styles={{
                    options: {
                        primaryColor: '#000',
                        textColor: '#000',
                        zIndex: 1000,
                    }
                }}
                hideBackButton={true}
                disableScrolling={true}
                scrollToFirstStep={false}
                showProgress={true}
                showSkipButton={false}
                callback={this.handleJoyrideCallback}

            />
        )
    }
}

export default Joyrider
