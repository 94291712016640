import React, { Component } from 'react'
import { Button, Icon } from "../../common/component/Button";
import { closeModal } from "../../../core/redux/action/modalAction";
import { connect } from "react-redux";

import CtrlAskForChangeAdmin from '../userhome/modalActions/ctrlAskForChangeAdmin'

import ModalFrame from './ModalFrame'


import { isElectron } from 'react-device-detect';


const modalKey = 'shiftadminrequest'
const INITIAL_STATE = { subIndex: -1, activeIndex: 2, isLoading: false, isNew: true, error: false, title: '', doSave: false }
export class ShiftUserInput extends Component {

    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE, title: window._getText('shiftuserinput.title0') };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        //on open
        if (prevProps.isOpen === false && this.props.isOpen === true) {
            this.setState({ isLoading: false, isNew: (this.props.sKey === null), error: false })
        }
    }

    onCloseModal = () => {
        this.props.onCloseModal(modalKey)
    }

    onClickDelete = () => {
        this.setState({ isLoading: true })
        // doShit.then(() => {
        //     this.setState({ isLoading: false })
        //     this.onCloseModal()
        // })

    }

    onChangeText = (e) => {
        this.setState({ valueName: e.target.value, error: (e.target.value.length < 1) });
    }
    onClickSave = () => {

        this.setState({ isLoading: true })
        // doShit.then(() => {
        //     this.setState({ isLoading: false })
        //     this.onCloseModal()
        // })
    }

    handleClick = (e, titleProps) => {
        console.log('this.props', this.props)
        const { index } = titleProps
        const { activeIndex } = this.state
        const newIndex = activeIndex === index ? -1 : index
        let title = window._getText('shiftuserinput.title0')
        switch (newIndex) {
            case -1:
                title = window._getText('shiftuserinput.title0')
                break;
            case 1:
                title = window._getText('shiftuserinput.title1')
                break;
            case 2:
                title = window._getText('shiftuserinput.title2')
                break;
            case 3:
                title = window._getText('shiftuserinput.title3')//'Add acomment'
                break;
            case 4:
                title = window._getText('shiftuserinput.title4')//'Mark as sick!'
                break;
            default:
        }

        this.setState({ activeIndex: newIndex, title, subIndex: -1 })
    }

    defaultViwe = () => {

        return (
            <React.Fragment>
                <Button compact className='btnMobile' index={1} onClick={this.handleClick} color='blue' icon='tag' content={window._getText('shiftuserinput.btn1')} style={{ width: '100%', margin: '5px' }}></Button>
                <Button compact className='btnMobile' index={2} onClick={this.handleClick} color='blue' icon='comments' content={window._getText('shiftuserinput.btn2')} style={{ width: '100%', margin: '5px' }}></Button>
                <Button compact className='btnMobile' index={3} onClick={this.handleClick} color='blue' icon='comment' content={window._getText('shiftuserinput.btn3')} style={{ width: '100%', margin: '5px' }}></Button>
                <Button compact className='btnMobile' index={4} onClick={this.handleClick} color='blue' icon='bed' content={window._getText('shiftuserinput.btn4')} style={{ width: '100%', margin: '5px' }}></Button>
            </React.Fragment>
        )
    }
    setSubIndex = (index) => {
        this.setState({ subIndex: index })
    }
    getCtrl = () => {
        return <CtrlAskForChangeAdmin modalKey={modalKey} setSubIndex={3} onClose={this.onClose} doSave={this.state.doSave} key={this.props.data} resetSave={this.resetSave} />

    }
    onClose = () => {
        this.setState({ activeIndex: -1, subIndex: -1 })
        this.onCloseModal()
    }
    onBack = () => {

        if (isElectron === true) {
            this.setState({ activeIndex: -1, subIndex: -1, title: window._getText('shiftuserinput.title0') })
            this.onCloseModal()
            return
        }

        switch (this.state.activeIndex) {
            case -1:
                this.onCloseModal()
                break;
            case 1:
                this.setState({ activeIndex: -1, subIndex: -1, title: window._getText('shiftuserinput.title0') })
                break;
            case 2:
                this.setState({ activeIndex: -1, subIndex: -1, title: window._getText('shiftuserinput.title0') })
                break;
            case 3:
                this.setState({ activeIndex: -1, subIndex: -1, title: window._getText('shiftuserinput.title0') })
                break;
            case 4:
                this.setState({ activeIndex: -1, subIndex: -1, title: window._getText('shiftuserinput.title0') })
                break;
            default:

        }


    }
    resetSave = () => {
        this.setState({ doSave: false })
    }
    onSave = () => {
        this.setState({ doSave: true })
    }
    render() {
        // console.log('>>>render Shiftrequest')
        const { data } = this.props
        if (!data)
            return null

        const shift = data.shift || {}

        //TODO FIX THIS SHIT! mobile and modalFrame
        return (
            <ModalFrame name='shiftadminrequest' className='modalAniSlideUp' dimmer='inverted' size='large' open={this.props.isOpen} onClickClose={this.onBack}  >
                <ModalFrame.Content >
                    <div style={{ marginTop: '18px', height: 'calc(100% - 60px)' }}>
                        <div key={shift.k} style={{ width: 'calc(100% - 10px)', minHeight: '50px', marginLeft: '4px', marginRight: '4px', marginBottom: '20px' }} className={"ui label large dragMe x" + shift.c}  >
                            <div>{shift.l}
                                {data.meta && data.meta['deviation'] && <span> <Icon name='tag' size='small' /></span>}
                                {data.meta && data.meta['comment'] && <span> <Icon name='comment' size='small' /></span>}
                                {data.meta && data.meta['change'] && <span> <Icon name='comments' size='small' /></span>}
                                {data.meta && data.meta['sick'] && <span> <Icon name='bed' size='small' /></span>}
                            </div>
                            <span>{shift.co && shift.co}</span>
                        </div>
                        {this.getCtrl()}
                    </div>
                </ModalFrame.Content>
                <ModalFrame.Actions>

                    <div style={{ display: 'flex' }}>
                        <Button onClick={this.onSave} content={window._getText('send')} icon='send' style={{ marginLeft: 'auto' }}></Button>
                    </div>

                </ModalFrame.Actions>
            </ModalFrame >)




    }
}


const mapActions = {
    onCloseModal: closeModal,
};

const mapStateToProps = state => {
    //   console.log('state.modal.modals[' + modalKey + '].data', state.modal.modals[modalKey].data)


    return {
        isOpen: state.modal.modals[modalKey].open || false,
        data: state.modal.modals[modalKey].data,
    };
};
export default connect(
    mapStateToProps,
    mapActions
)(ShiftUserInput);



