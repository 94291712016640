import { GET_SCHEDULE, SET_SCHEDULE, SET_SCHEDULE_ISLOADING, SET_SCHEDULE_RENDERDATE, COPY_SCHEDULE_SHIFT, RESET_SCHEDULE, COPY_SCHEDULE_ROW } from "../action/currentScheduelDataAction";
import { createReducer } from "../../util/reducerUtil";
import { identity } from "lodash";
const moment = require('moment');

const initState = { selectedKey: null, selectedDateKey: null, refresh: false, isLoading: false, renderDayDate: null, copyShiftObject: null, copyRowObject: null }
const initScheduleObject = {
  key: null,
  dateKey: '-',
  selectedSchedule: null,
  isNew: false,
  scheduleEventsList: {}, //events belongs to schedule
  scheduleUserList: [], //users belongs to schedule
  selectedUsers: [], // new users add from the full userslist
  sortedUserList: [], //users keys sorted
  dividerList: {}, // list of dividers 
  cacheKey: ''
};

//FIX THIS NAME WRONG! it returns the state and not a schedule
export const getSchedule = (state, payload) => {
  //console.log('***getSchedule')
  //var key = payload? payload:state.selectedKey  
  //var schdeule = state[key] 

  return { ...state };
};

export const resetSchedule = (state, payload) => {
  //console.log('***ok resetSchedule')
  //var key = payload? payload:state.selectedKey  
  //var schdeule = state[key] 
  //console.log('RESET', { ...initState })
  return { ...initState };
};


export const setIsLoadingSchedule = (state, payload) => {
  //console.log('***setIsLoadingSchedule')
  return { ...state, isLoading: payload }
}
export const setRenderDayDateSchedule = (state, payload) => {
  //console.log('***setRenderDayDateSchedule')
  return { ...state, renderDayDate: payload }
}


export const setSchedule = (state, payload) => {
  //console.time('-->setSchedule')
  //console.log('***setSchedule')
  // console.log('state', state)
  // console.log(' ')
  var newState = { ...state }
  var key = payload.key ? payload.key : state.selectedKey // important to set selected key , before doinign any other updates 

  var datekey = payload.dateKey ? payload.dateKey : state.selectedDateKey
  newState.selectedKey = key;
  newState.selectedDateKey = datekey;


  newState[key + '_' + datekey] = { ...initScheduleObject, ...newState[key + '_' + datekey], ...payload, cacheKey: +new Date() };


  const userSortedEvents = {}
  const userSortedEvents2 = {}
  const scheduleEventsList = newState[key + '_' + datekey].scheduleEventsList || {}
  Object.keys(scheduleEventsList).forEach(shiftKey => {
    const shift = { ...scheduleEventsList[shiftKey] }
    const userKey = shift.uid

    // if (userKey) {
    //   if (!userSortedEvents[userKey])
    //     userSortedEvents[userKey] = []

    //   userSortedEvents[userKey].push(shift)

    //   const d = new Date(shift.date);
    //   shift.renderDayKey = `${d.getMonth()}-${d.getDate()}`

    // }




    if (userKey) {
      const d = new Date(shift.date); // WE NEED UTC DATE ?  FUCK THISSSSS

      shift.renderDayKey = `${d.getMonth()}-${d.getDate()}`

      // console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>')
      // // if (userKey === 'zxO2zZY7P') {
      // console.log('>>shift.date', moment(shift.date).format('MM-DD'))
      // console.log('day of week', moment(shift.startDate).utc().isoWeekday())
      // console.log('>> 1 shift.startDate', moment(shift.startDate).format('MM-DD'))
      // console.log('>> 2 shift.stopDate', moment(shift.stopDate).format('MM-DD'))
      // console.log('>>shift key ', `${d.getMonth()}-${d.getDate()}`)


      // console.log('>>shift.renderDayKey', shift.renderDayKey)
      // console.log('>>shift ')

      //  }







      if (!userSortedEvents2[userKey])
        userSortedEvents2[userKey] = {}

      //userSortedEvents[userKey].push(shift)
      if (!userSortedEvents2[userKey][shift.renderDayKey])
        userSortedEvents2[userKey][shift.renderDayKey] = []

      userSortedEvents2[userKey][shift.renderDayKey].push(shift)
    }
  })



  // remove this and sort on low level IF we need to 
  // Object.keys(userSortedEvents2).forEach(key => {
  //   var userData = userSortedEvents2[key]
  //   if (userData)
  //     Object.keys(userData).forEach(key2 => {
  //       userSortedEvents2[key][key2] = [...userSortedEvents2[key][key2].sort((a, b) => a.timeStart - b.timeStart)]

  //     })
  // })


  newState[key + '_' + datekey].userSortedEvents2 = userSortedEvents2
  newState[key + '_' + datekey].userSortedEvents = userSortedEvents
  //console.log('***', newState)
  //console.timeEnd('setSchedule')
  return newState;
};
export const copyScheduleShift = (state, payload) => {
  //console.log('***copyScheduleShift', payload)
  const newState = { ...state, copyShiftObject: payload }
  return newState;
}
export const copyScheduleRow = (state, payload) => {
  console.log('>>>>copyScheduleRow', payload)
  const newState = { ...state, copyRowObject: payload }
  console.log('newState', newState)
  return newState;
}


export default createReducer(initState, {
  [GET_SCHEDULE]: getSchedule,
  [SET_SCHEDULE]: setSchedule,
  [SET_SCHEDULE_ISLOADING]: setIsLoadingSchedule,
  [SET_SCHEDULE_RENDERDATE]: setRenderDayDateSchedule,
  [COPY_SCHEDULE_SHIFT]: copyScheduleShift,
  [COPY_SCHEDULE_ROW]: copyScheduleRow,
  [RESET_SCHEDULE]: resetSchedule,

});
