import React from "react";
import ReactDOM from "react-dom";
import moize from "memoize-one";

import { isElectron } from "react-device-detect";
let ipcRenderer;
// const shortid = require('shortid');

if (isElectron) {
  ipcRenderer = window.require("electron").ipcRenderer;
  //   ipcRenderer.on('window-manager', (event, result) => {
  //     console.log('window-manager CLOSED')
  //   })
}

if (!window._modals) window._modals = {};

let modal = window._modals;

const copyStyles = moize((sourceDoc, targetDoc) => {
  //console.log('*****copyStyles******')
  Array.from(sourceDoc.styleSheets).forEach((styleSheet) => {
    if (styleSheet.cssRules) {
      // true for inline styles
      const newStyleEl = targetDoc.createElement("style");

      Array.from(styleSheet.cssRules).forEach((cssRule) => {
        newStyleEl.appendChild(targetDoc.createTextNode(cssRule.cssText));
      });

      targetDoc.head.appendChild(newStyleEl);
    } else if (styleSheet.href) {
      // true for stylesheets loaded from a URL
      const newLinkEl = targetDoc.createElement("link");

      newLinkEl.rel = "stylesheet";
      newLinkEl.href = styleSheet.href;
      targetDoc.head.appendChild(newLinkEl);
    }
  });
});

export class ElectronWindowPortal extends React.PureComponent {
  modalOnunload = () => {
    //console.log('>>>modalOnunload')
    if (this.props.modalOnunload) this.props.modalOnunload();
  };
  modalBeforeOnunload = () => {
    // console.log('>>>modalBeforeOnunload')
    // if (this.props.modalOnunload)
    //   this.props.modalOnunload()
  };

  constructor(props) {
    super(props);
    this.setWindow();
  }

  setWindow = () => {
    var name = this.props.name || "modal";
    //var options = props.options || {width:300}
    // STEP 1: create a container <div>
    this.containerEl = document.createElement("div");

    modal[name] = null;
    modal[name] = window.open("", name);
    //console.log('modal[name]', modal[name])
    //modal[name].document.write("<base href='http://" + document.location.host + "' />");

    if (modal[name].document.head.innerHTML === "") {
      modal[name].document.head.innerHTML =
        modal[name].document.head.innerHTML +
        "<base href='" +
        document.location.href +
        "' />";

      copyStyles(document, modal[name].document);
      modal[name].onunload = this.modalOnunload;
      modal[name].onbeforeunload = this.modalBeforeOnunload;

      var innerscript = document.createElement("script");
      innerscript.innerHTML =
        "window.require('electron').ipcRenderer.on('setColor', function (event,color) { document.documentElement.setAttribute('data-theme', color) })";
      modal[name].document.head.append(innerscript);
    }
    modal[name].document.body.innerHTML = "";
    //  if (name === 'eventModal' || name === 'timepicker') {
    modal[name].document.body.style.backgroundColor = "transparent";
    // }
    // else {
    //   modal[name].document.body.style.backgroundColor = '#fff'
    // }
    modal[name].document.body.appendChild(this.containerEl);
  };

  render() {
    // if (!modal[this.props.name || 'modal'].closed)
    //   this.setWindow()

    if (!this.containerEl) {
      console.log("null render");
      return null;
    }

    //console.log(' modal[name].closed', modal[this.props.name || 'modal'].closed)
    if (this.props.name === "eventModal")
      ipcRenderer.send("renderWindow", this.props.name || "modal");

    return ReactDOM.createPortal(this.props.children, this.containerEl);
  }

  componentDidMount() {
    // STEP 3: open a new browser window and store a reference to it
    // this.externalWindow = window.open('', '', 'width=400,height=300,left=200,top=200,status=no,titlebar=no,toolbar=no');

    // const BrowserWindow = window.require('electron').remote.BrowserWindow;
    // let parent = BrowserWindow.getFocusedWindow();
    // var win = new BrowserWindow({ parent: parent, modal: true, frame: true, width: 500, height: 500 });
    // win.setMenu(null);
    // win.loadURL('http://localhost:3000/jens');
    //var name = this.props.name || 'modal'

    // var meta = document.createElement('meta');
    // meta.httpEquiv = "Content-Security-Policy";
    // meta.content = "default-src * gap://ready https://ssl.gstatic.com data:; img-src *  'self' data: blob:;font-src * 'unsafe-inline' 'self' data: blob:; style-src * 'unsafe-inline' data:; script-src * 'self' 'unsafe-inline' 'unsafe-eval';";
    // modal[name].document.getElementsByTagName('head')[0].appendChild(meta);

    if (this.props.doRerender) this.props.doRerender();

    //ipcRenderer.send('open-window', 'ping')

    // STEP 4: append the container <div> (that has props.children appended to it) to the body of the new window
    //this.externalWindow.document.body.appendChild(this.containerEl);
  }

  componentWillUnmount() {
    var name = this.props.name || "modal";

    // STEP 5: This will fire when this.state.showWindowPortal in the parent component becomes false
    // So we tidy up by closing the window

    if (modal[name]) {
      if (this.props.name === "eventModal") ipcRenderer.send("hideEventModal");

      modal[name].close();
    }
  }
}
