import React from "react";
import ReactDOM from "react-dom";
//ok hej e
//import messaging from 'firebase/messaging'
//import messaging from 'firebase/messaging';
//import firebase from './app/config/firebase';


import { BrowserRouter } from "react-router-dom";
import ReduxToastr from "react-redux-toastr";
//import "semantic-ui-css/semantic.min.css";
import "react-redux-toastr/lib/css/react-redux-toastr.min.css";
import { isMobile, isBrowser, isElectron } from 'react-device-detect'; //isIOS
//import { initializePush } from './initializePush';
import './css/semantic.css'
import "./css/index.css"
import "./css/schedual1.css"
import './css/macos.css'
import './css/semanticoveride.css'
import './css/colors.css'
import './css/schedual.css'
import './css/print.css'
import './css/login.css'
import './css/slider.css'
import './css/mobile.css'
import './css/toastr.css'
import './css/noprint.css'
import './css/Raleway/font.css'
import './css/webMenu.css'
import './css/contextMenu.css'
import './css/table.css'

// import './css/stripe.css'


import Feedback from './app/common/component/Feedback'


import App from "./App";
import ReactGA from 'react-ga';
//
//import registerServiceWorker from "./registerServiceWorker";
import * as serviceWorker from './serviceWorker';

import ScrollToTop from "./core/util/ScrollToTop";

import { Provider } from "react-redux";
import { store } from "./core/redux/store/configStore";
import * as lang from './core/util/translation'
import GlobalState from './globalState'




// import initReactFastclick from 'react-fastclick';
// initReactFastclick();


// class PleaseUseApp extends React.Component {

//   render() {
//     const image = require('./img/ios.png')
//     return <div style={{ padding: '40px', textAlign: 'center' }}>

//       For Ipads and Iphones <br />
//       please use the app from the app store<br /><br />
//       <img alt='Download app' src={image} />

//     </div>;
//   }
// }


window._baseUrl = process.env.PUBLIC_URL || '.'


// const myWorker = new Worker('/worker.js');
// myWorker.onmessage = function (e) {
//   //result.textContent = e.data;
//   console.log('Message received from worker', e);
// }
// myWorker.postMessage('store')


if (window.location.hostname.toLocaleLowerCase() === 'app.timeto.work') {
  //  if (isBrowser && !isElectron)
  //  alert(`This is a preview of the web version. it's still in development. Any bugs please report them to support@timeto.work. Thank you`)

  if (isMobile)
    alert(`We don't support mobiles (yet). Continue on your own risk :)`)
}





//init values
let gs = {
  showTour: false
}
const setgState = (val) => {
  gs = Object.assign(gs, val)
  return gs
}

let render = () => {
  if (isMobile)
    document.getElementsByTagName('body')[0].classList.add('mobile')


  // if (isMobile && isIOS && isElectron === false) {
  //   ReactDOM.render(<PleaseUseApp />, document.getElementById("root"))
  // }
  // else {

  ReactDOM.render(

    <GlobalState.Provider value={{ state: gs, setState: setgState }}>
      <Provider store={store}>
        <BrowserRouter>
          <ScrollToTop>
            <ReduxToastr
              timeOut={2000}
              preventDuplicates
              position="bottom-center"
              closeOnToastrClick={true}

            />
            <App />


            <Feedback />
          </ScrollToTop>
        </BrowserRouter>
      </Provider>
    </GlobalState.Provider>
    ,
    document.getElementById("root")
  );
}
//};





const startApp = () => {
  lang.init();
  var electronInputLanguage = (global.location.search || '').substring(1) // get from electron remove "?" in the begining
  var language = (electronInputLanguage || navigator.language.substring(0, 2)) || 'en' // we get this from electron , preload.js
  window._getText = lang.text

  //language = 'de' //OVERIDE
  var systemLanguage = localStorage.getItem('sysLang') || language
  window._getTextLanguage = systemLanguage
  lang.setLang(systemLanguage)

  window._currency = localStorage.getItem('currency') || "USD"

  render();

  if (isElectron === false) {
    if (process.env.REACT_APP_ENV === 'production')
      ReactGA.initialize('UA-127758857-3');
    else
      ReactGA.initialize('G-VGKLG6YTS6');

  }
  else {

    if (process.env.REACT_APP_ENV === 'production')
      ReactGA.initialize('UA-127758857-1')
    else
      ReactGA.initialize('G-VGKLG6YTS6');


  }


  setTimeout(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, 100);
  //if (isElectron === false)
  // serviceWorker.register({
  //   onUpdate: registration => {
  //     console.log('onUpdate', registration)
  //     const waitingServiceWorker = registration.waiting

  //     if (waitingServiceWorker) {
  //       waitingServiceWorker.addEventListener("statechange", event => {
  //         console.log('event.target.state', event.target.state)
  //         if (event.target.state === "activated") {
  //           console.log('111', 111)
  //           window.location.reload()
  //         }
  //       });
  //       console.log('okje...', waitingServiceWorker)
  //       try {
  //         waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });

  //       } catch (error) {
  //         alert(error)
  //       }
  //       window.location.reload(true);

  //     }
  //   }
  // });



  //if (window.location.hostname.toLocaleLowerCase() === 'app.timeto.work')

  serviceWorker.register({
    onUpdate: async registration => {
      // We want to run this code only if we detect a new service worker is
      // waiting to be activated.
      // Details about it: https://developers.google.com/web/fundamentals/primers/service-workers/lifecycle
      if (registration && registration.waiting) {
        await registration.unregister();
        // Makes Workbox call skipWaiting()
        registration.waiting.postMessage({ type: 'SKIP_WAITING' });
        // Once the service worker is unregistered, we can reload the page to let
        // the browser download a fresh copy of our app (invalidating the cache)
        window.location.reload();
      }
    },
  });
  //registerServiceWorker();
}




startApp();



// if ('serviceWorker' in navigator) {
//   //
// console.log(`${process.env.PUBLIC_URL || '.'}/firebase-messaging-sw.js`)
//   navigator.serviceWorker.register(`${process.env.PUBLIC_URL || '.'}/firebase-messaging-sw.js`)
//     .then(function (registration) {
//       // console.log('firebase', firebase.messaging)
//       // console.log('messaging', messaging)

//       try {
//         firebase.messaging().useServiceWorker(registration);
//       } catch (error) {
//         console.log(error)
//       }


//       initializePush()

//       console.log("!!!!!!This push service worker is registered!!!!!!!!")
//     }).catch(function (err) {
//       console.log('Service worker registration failed, error:', err);
//     });
// }


// console.log('-------------')
// Notification.requestPermission(function (status) {
//   console.log('Notification permission status:', status);
// });
// console.log('-------------')

// const displayNotification = () => {
//   console.log('Notification.permission', Notification.permission)
//   if (Notification.permission == 'granted') {
//     // navigator.serviceWorker.getRegistration().then(function (reg) {
//     //   reg.showNotification('Hello world!');
//     // });
//   }
// }
// displayNotification()