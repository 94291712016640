module.exports = {
    'newTaskInfo': `Wenn Sie einen schnellen Rundgang benötigen, klicken Sie auf die Registerkarte 'Hilfe' im Menü links unten. Klicken Sie dann auf 'Machen Sie eine Tour' und kehren Sie zu dieser Seite zurück.`,
    'Publish': `Veröffentlichen`,
    'schedual.btnPasteSchedule': `Arbeitsplan einfügen`,
    'schedual.btnCopySchedule': `Arbeitsplan kopieren`,
    'NewFeatuer': `Dies ist eine neue Funktion.`,
    'LinkCopied': `Link kopiert`,
    'GoToWorkView': `Dies ist der veröffentlichte Arbeitsplan
    Gehen Sie zur Arbeitsansicht, um Änderungen vorzunehmen`,
    'QuickIntro': `Brauchen Sie eine kurze Einführung in die Funktionsweise dieser App?`,
    'Workview': `Arbeitsansicht`,
    'AddRow': `Zeile hinzufügen`,
    'Published': `Veröffentlicht`,
    'thisIsWatStaffSee': `Das sehen Ihre Mitarbeiter so`,
    'Unpublish': `Unveröffentlichen`,
    'TaskStartInfo': `Klicken Sie auf eine Aufgabe oder erstellen Sie eine neue Aufgabe, indem Sie auf die Plus-Schaltfläche in der oberen rechten Ecke klicken`,
    'ClickHereToStart': `Klicken Sie hier, um zu beginnen`,
    'joy.viewWeeks': `Wochenanzahl anzeigen`,
    'tour.onboardStep_mainMenu': `Beginnen Sie mit einem Klick auf die Registerkarte, die Sie besichtigen möchten.`,
    'comefirmUnlinkUser': `Wollen Sie die Verlinkung aufheben?`,
    'draft': `Entwurf, nicht veröffentlicht`,
    'Unpublishing': `Unveröffentlichung ...`,
    'AreYouSurePublish': `Sind Sie sicher, dass Sie den Arbeitsplan für alle Mitarbeiter veröffentlichen wollen?`,
    'tour.tour_report_1': `Aktueller Monat. Benutzen Sie die Pfeile, um den Monat zu ändern.`,
    'tour.tour_chats_3': `Wählen Sie einen Chat-Raum aus und schreiben Sie hier Ihre Nachricht.`,
    'AdminCanAddScheduls': `Nur der Administrator kann neue Zeitpläne hinzufügen.`,
    'PrintNoAbuseMsg': `Nur zahlende Kunden können die Druckfunktion nutzen.
    (Damit soll ein Missbrauch des Systems vermieden werden).`,
    'tour.tour_schedule_9': `Dies ist Ihre Arbeitsansicht, die Mitarbeiter können diese Version des Plans nicht sehen.`,
    'CopyShift': `Schicht kopieren`,
    'tour.tour_requests_2': `Die ursprüngliche Schicht aus Ihrem veröffentlichten Arbeitsplan.`,
    'ClearSchedule': `Wollen Sie diesen Arbeitsplan löschen? `,
    'tour.tour_schedule_6': `Klicken Sie auf eine Schicht, um sie zu bearbeiten, oder ziehen Sie sie, um sie zu verschieben.
    Wenn Sie beim Verschieben die Option gedrückt halten, wird die Schicht geklont.`,
    'tour.tour_staff_3': `Klicken Sie hier, um Einladungen an alle ungebetenen Benutzer auf einmal zu versenden.`,
    'PleaseAddCreditsBeforePrinting': `Bitte fügen Sie vor dem Drucken Credits hinzu`,
    'tour.tour_staff_1': `Klicken Sie hier, um einen neuen Mitarbeiter hinzuzufügen.`,
    'AddDivider': `Trennzeichen hinzufügen`,
    'WantToWork': `Möchte arbeiten`,
    'tour.tour_requests_5': `Die Änderungen wurden abgelehnt, und es werden keine Änderungen vorgenommen.`,
    'tour.tour_report_3': `Dies ist der Stundenzettel
    Hier können Sie die geleisteten Arbeitsstunden einsehen.
    Alle Daten stammen aus dem veröffentlichten Stundenplan.
    Bewegen Sie den Mauszeiger über eine Zeile und klicken Sie dann auf den Stift, um die Schicht zu bearbeiten.`,
    'CreateShiftRequest': `Schichtantrag erstellen`,
    'tour.tour_chats_2': `Als Administrator können Sie einen neuen Chatraum hinzufügen.`,
    'tour.tour_schedule_10': `Dies sollen Ihre Benutzer in der mobilen App sehen.
    Dies sind auch die Daten in Berichten.`,
    'tour.tour_schedule_6_1': `Klicken Sie auf den Benutzer, um eine Zeile zu kopieren oder den Benutzer anzuzeigen.`,
    'tour.tour_schedule_5': `Sie können auch auf einen Tag doppelklicken, um eine Schicht hinzuzufügen.
    (Bitte stellen Sie sicher, dass Sie mindestens eine Schicht im Arbeitsplan haben, bevor Sie zum nächsten Schritt übergehen)`,
    'TakeTour': `Machen Sie eine Produkttour`,
    'tour.tour_report_2': `Wählen Sie einen Benutzer und klicken Sie dann auf Weiter.
    Wenn die Benutzerstunden 0:00 Uhr sind, müssen Sie Ihren Arbeitsplan veröffentlichen.
    Alle Daten hier stammen aus dem veröffentlichten Arbeitsplan.`,
    'Mo': `Mo`,
    'Fr': `Fr`,
    'tour.tour_staff_2': `Klicken Sie hier, um mehrere Mitglieder auf einmal hinzuzufügen`,
    'login.demo': `Demo`,
    'NewVersion': `Eine neue Version ist verfügbar, möchten Sie jetzt aktualisieren?`,
    'Th': `Do`,
    'Askforthisshift': `Bitten Sie um diese Schicht`,
    'tour.tour_schedule_8': `Wenn Sie den veröffentlichten Arbeitsplan entfernen möchten, können Sie dies tun, indem Sie hier klicken.`,
    'tour.tour_requests_3': `Die neue Schicht, in die der Mitarbeiter wechseln möchte ...`,
    'tour.tour_schedule_3': `Oder verwenden Sie diese Wochen-Picker, um zu einer bestimmten Woche zu gehen.`,
    'tour.tour_today_2': `Klicken Sie hier, wenn Sie überlappende Schichten haben.`,
    'tour.tour_requests_empty': `Bitte stellen Sie sicher, dass Sie mindestens einen Wunsch haben, bevor Sie an der Führung teilnehmen. 
    
    Klicken Sie zum Beispiel auf eine Schicht im Arbeitsplan und dann mit der rechten Maustaste auf "Schichtwunsch erstellen". `,
    'ThereIsNoPubSchedule': `In diesem Monat gibt es keinen veröffentlichten Arbeitsplan.`,
    'tour.tour_staff_4': `Klicken Sie hier, um einen Mitarbeiter anzuzeigen oder zu bearbeiten`,
    'PasteShift': `Schicht einfügen`,
    'TaskRestarted': `Die Aufgabe wird neu gestartet und auf die Registerkarte Aktiv verschoben.`,
    'NewShift': `Neue Schicht`,
    'TaskIsRemoved': `Aufgabe wird entfernt.`,
    'DeleteShift': `Schicht löschen`,
    'shiftIsCloned': `Schicht wird geklont`,
    'TaskIsPublished': `Die Aufgabe ist veröffentlicht.`,
    'tour.tour_schedule_2': `Aktuelle Woche, verwenden Sie die Pfeile, um zur nächsten/vorherigen Woche zu gelangen.`,
    'schedual.m.Tour': `Hilfe`,
    'MovetToArchive': `Die Aufgabe wird ins Archiv verschoben.`,
    'tour.tour_schedule_4': `Klicken Sie hier, um einen Benutzer oder eine Schicht hinzuzufügen
    (Falls Sie noch keine Benutzer in Ihrem Terminkalender haben, fügen Sie bitte jetzt einen hinzu)`,
    'tour.tour_today_1': `So sieht der heutige Tag aus.
    Die Daten stammen aus dem veröffentlichten Arbeitsplan.`,
    'PrivateNotes': `Privatnotizen`,
    'minutes': `Minuten`,
    'Available': `Verfügbar`,
    'DemoAccount': `Demo-Konto, klicken Sie hier, um sich abzumelden und Ihr eigenes Konto zu eröffnen.`,
    'AddASchedule': `Fügen Sie einen Arbeitsplan hinzu, um anzufangen.`,
    'AreYouSureDeleteTask': `Sind Sie sicher, dass Sie diese Aufgabe löschen möchten?`,
    'AddStaff': `Mitarbeiter hinzufügen`,
    'tour.tour_schedule_7': `Wenn Sie mit Ihrem Arbeitsplan zufrieden sind und ihn mit Ihren Mitarbeitern teilen möchten.
    Klicken Sie hier zum Veröffentlichen.`,
    'tour.tour_requests_1': `Dies ist der Eingangs- und Ausgangskorb für Ihre Anfragen.`,
    'Vacation': `Urlaub`,
    'Sa': `Sa`,
    'RequestIsSent': `Anfrage gesendet`,
    'DataComesFromPubSche': `(Die hier angezeigten Daten stammen aus den veröffentlichten Arbeitsplänen)`,
    'MovetToActive': `Die Aufgabe wird in die Registerkarte Aktiv verschoben.`,
    'addMoreBeforPrint': `Bitte fügen Sie vor dem Drucken weitere Credits hinzu`,
    'StartByAddSchedule': `Beginnen Sie, indem Sie den Namen Ihres HauptArbeitsplans hinzufügen.`,
    'We': `Mi`,
    'tour.tour_staff_5': `Klicken Sie hier, um den Benutzer per E-Mail oder SMS einzuladen.`,
    'Tu': `Di`,
    'schedual.m.Tasks': `Aufgaben`,
    'YouCanOnlyPrint4week': `Sie können nur vier Wochen im Voraus drucken.`,
    'tour.tour_requests_4': `Genehmigen Sie die Benutzeranfrage 
    Diese Schicht wird in Ihrem veröffentlichten Arbeitsplan aktualisiert.`,
    'tour.tour_chats_1': `Dies ist Ihre Chatliste. 
    All ist eine Nicht-Entfernungs-Chatgruppe für alle Mitarbeiter. 
    Log ist eine Log-Liste Ihrer Anfragen/Antworten.`,
    'ScheduleIsCopied': `Arbeitsplan wird kopiert`,
    'PleaseRestart': `Bitte starten Sie Ihre Anwendung neu, damit die Einstellungen übernommen werden.`,
    'tour.tour_schedule_1': `Der Arbeitsplan.
    Klicken Sie auf das Zahnrad, um einen Namen zu ändern oder den Arbeitsplan zu löschen.
    (Bitte stellen Sie sicher, dass Sie mindestens einen Benutzer des Arbeitsplans im Arbeitsplan haben, bevor Sie fortfahren)`,
    'Su': `So`,
    'TaskIsUnPublished': `Aufgabe ist unveröffentlicht.`,
    'tour.tour_report_4': `Klicken Sie hier, um einen xlsx-Bericht zu exportieren.
    Wenn Sie alle Berichte in einem Blatt herunterladen möchten, verwenden Sie das obere Menü.
    Datei -> Exportieren -> Als xlsx exportieren.`,
    'CanNotWork': `Kann nicht arbeiten`,
    'login.err.2': `Das Passwort ist ungültig.`,
    'createNewAccount.info': `Verwenden Sie die Telefonnummer, wenn Sie per SMS oder E-Mail eingeladen wurden, wenn Sie eine Einladung per Post erhalten haben.`,
    'createNewAccount.smscodeTitle': `Code aus der Textnachricht`,
    'login.err.1': `Es gibt keinen Benutzer, der dieser E-Mail-Adresse entspricht. Der Benutzer kann gelöscht worden sein.`,
    'login.err.4': `Das Konto wurde von einem Administrator deaktiviert.`,
    'createNewAccount.newaccount': `Neues Mitarbeiterkonto`,
    'login.btnLogin': `Anmeldung`,
    'login.err.3': `Die E-Mail-Adresse ist schlecht formatiert.`,
    'login.err.6': `Dies ist keine gültige Telefonnummer. Sie müssen mit Ihrer Landesvorwahl beginnen.`,
    'createNewAccount.newSchedule': `Neuer Arbeitsplan`,
    'login.err.5': `Das Passwort muss mindestens 6 Zeichen lang sein.`,
    'createNewAccount.teleOrEmail': `Telefonnummer oder E-Mail`,
    'createNewAccount.setPassword': `Neues Passwort festlegen`,
    'createNewAccount.smscode': `Code aus der Textnachricht`,
    'createNewAccount.login': `Anmeldung`,
    'or': `oder`,
    'set.settings.btn.dev': `Abweichungen`,
    'createNewAccount.dontHavePassword': `Sie haben kein Passwort?`,
    'set.settings.time': `Zeit`,
    'set.settings.timeWarning': `Bei Änderungen müssen Sie Ihre Termindaten erneut speichern, um alte zwischengespeicherte Zeitangaben zu erneuern.`,
    'set.settings.btn.com': `Anmerkungen`,
    'set.settings.publickey': `Firmenschlüssel`,
    'set.settings.auto': `Automatische Genehmigung von Benutzer-Schichtänderungen`,
    'set.settings.btn.sMov': `Schichten verschieben`,
    'set.settings.publickeytext': `Dies ist der Schlüssel, den Ihre Mitarbeiter benötigen, wenn sie ein neues Konto erstellen.`,
    'set.settings.btn.sCan': `Schichten entfernen`,
    'createNewAccount.comName': `Name des Unternehmens`,
    'SCHEDULES': `ARBEITSPLÄNE`,
    'on': `Ein`,
    'createNewAccount.weekstart': `Woche beginnt mit`,
    'createNewAccount.secretKey': `Firmenschlüssel`,
    'import': `importieren`,
    'signout': `Abmeldung`,
    'createNewAccount.comDisplayName': `Anzeigename des Unternehmens`,
    'createNewAccount.adminAccount': `Administrator-Konto`,
    'January': `Januar`,
    'June': `Juni`,
    'March': `März`,
    'time': `Zeit`,
    'uploading_image': `Bild hochladen...`,
    'December': `Dezember`,
    'shifts': `schichten`,
    'inactive': `Inaktiv`,
    'week': `Woche`,
    'February': `Februar`,
    'schedule': `Arbeitsplan`,
    'phonenumber': `Telefonnummer`,
    'h': `Std`,
    'message.out': `Aus`,
    'oops': `Ups!`,
    'update': `Aktualisierung`,
    'save': `Speichern`,
    'total': `Gesamt`,
    'Apr': `Apr`,
    'users.infoText': `Diese werden verwendet, um Ihre Mitarbeiter mit einem Benutzer im Arbeitsplan zu verbinden, Sie müssen nur einen von ihnen einstellen.`,
    'schedual.m.addNewUser': `Neuen Mitarbeiter hinzufügen`,
    'schedual.lbl-unassigned': `Nicht zugewiesen`,
    'logginin': `Einloggen...`,
    'back': `zurück`,
    'upload': `Hochladen`,
    'August': `August`,
    'tooltip.AddANewChat': `Einen neuen Chat hinzufügen`,
    'active': `Aktiv`,
    'saturday': `Samstag`,
    'average': `Durchschnitt`,
    'September': `September`,
    'break': `Pause`,
    'billing.nextInvoice': `Nächste Rechnung`,
    'billing.msgBody': `Bitte fügen Sie mehr Credits hinzu, um das System weiter zu nutzen.`,
    'set.settings.btn.sik': `Krankheitsurlaub`,
    'AreYouSureYouWantToQuitYes': `Ja`,
    'terms': `Nutzungsbedingungen`,
    'setup.creatingCompany': `Geschäftskonto anlegen ...`,
    'setup.notValidKey': `Dies ist kein gültiger Firmenschlüssel`,
    'user.active': `Aktiv`,
    'users.linked': `Verlinkt`,
    'users.uniquephone': `Telefon muss eindeutig sein`,
    'schedual.addS': `Neuen Arbeitsplan hinzufügen`,
    'schedual.m.addDiv': `Benutzer-Teiler hinzufügen`,
    'locked.txt2': `Keine Sorge, es ist eine einfache Lösung, fügen Sie mehr Credits hinzu und in wenigen Minuten wird es wieder aktiviert.`,
    'setup.comKey': `Firmenschlüssel`,
    'setup.createNewAccount': `Neues Benutzerkonto erstellen`,
    'setup.updateKey': `Update-Schlüssel`,
    'setup.retry': `Wiederholen Sie`,
    'createNewAccount.youneedCompanyName': `Sie benötigen einen Firmennamen`,
    'createNewAccount.theKey': `Der Schlüssel, den Sie von Ihrem Manager erhalten haben`,
    'users.infoText1': `Dieser Benutzer ist mit einem Mitarbeiter verknüpft, klicken Sie auf das Schloss, um die Verknüpfung aufzuheben.`,
    'Oct': `Okt`,
    'search': `Suchen...`,
    'new': `Neu`,
    'tuesday': `Dienstag`,
    'date': `Datum`,
    'users': `Benutzer`,
    'October': `Oktober`,
    'hours': `Stunden`,
    'rotateimage': `Bild drehen`,
    'login.lostpass': `Haben Sie Ihr Passwort vergessen?`,
    'tooltip.Deny': `ablehnen`,
    'phone': `Telefon`,
    'add': `hinzufügen`,
    'monday': `Montag`,
    'sick': `Krank`,
    'name': `Name`,
    'message.empty': `Sie haben keine Wünsche.`,
    'cellphone': `Handy`,
    'create': `erstellen`,
    'picture': `Bild`,
    'Dec': `Dez`,
    'delete': `löschen`,
    'message.out.empty': `Sie haben keine ausstehenden Anträge.`,
    'send': `Senden`,
    'wednesday': `Mittwoch`,
    'Sep': `Sep`,
    'Jul': `Jul`,
    'Jun': `Jun`,
    'login.newUser': `Neues Mitarbeiterkonto`,
    'message.chat': `Chat`,
    'logginout': `Ausloggen...`,
    'sunday': `Sonntag`,
    'wShort': `w.`,
    'Aug': `Aug`,
    'Mar': `Mrz`,
    'yes': `Ja`,
    'now': `Jetzt`,
    'login.forEmp': `Für den Arbeitnehmer`,
    'login.newSchedule': `Neues Terminkonto`,
    'login.titel': `Anmeldung`,
    'tooltip.Cancel': `Abbrechen`,
    'password': `Passwort`,
    'type': `Eingeben`,
    'Feb': `Feb`,
    'off': `Aus`,
    'login.titleNewAccount': `Neues Konto`,
    'login.sendpass': `Neues Passwort zusenden`,
    'loading': `Laden...`,
    'cancel': `Abbrechen`,
    'thursday': `Donnerstag`,
    'May': `Mai`,
    'November': `November`,
    'April': `April`,
    'billing.payDetails': `Einzelheiten zur Zahlung`,
    'report.h_time': `Zeit`,
    'request.titleChange': `Zeit ändern`,
    'billing.msgHead2': `oder Ihr Konto wird automatisch gesperrt.`,
    'shiftuserinput.title4': `Als krank markieren`,
    'billing.infoTxt2': `Danach wird Ihr Zugang deaktiviert, bis Sie mehr Credits hinzufügen.`,
    'appdiv.pushmessages': `Push-Nachrichten`,
    'wallet.txtWeb2': `auch wenn Sie TimeTo.Work nur im Web benutzen, müssen Sie die Zahlungen durch einen der Läden machen. Bitte laden Sie die Anwendung von einem der Geschäfte herunter und Sie werden eine Schaltfläche "Buy More Credits" innerhalb der Anwendung hier sehen`,
    'request.titleComment': `Kommentar`,
    'set.settings.btn.openshifts': `Schicht buchen`,
    'request.adminRequest': `Anfrage`,
    'userhome.youarefree': `Sie können diese Woche tun, was immer Sie wollen `,
    'report.h_workedhours': `Geleistete Arbeitsstunden`,
    'editdivider.title': `Gruppen-Teiler`,
    'request.titleSwap': `Schicht tauschen`,
    'shiftuserinput.title5': `Schicht beantragen`,
    'wallet.purchasing': `undefined`,
    'shiftuserinput.btn2': `Bitten Sie um eine Änderung`,
    'report.h_date': `Datum`,
    'shiftuserinput.btn3': `Einen Kommentar hinzufügen`,
    'report.downloadall': `Alle herunterladen`,
    'selectuser.title': `Benutzerliste`,
    'billing.infoTxt3': `Wenn Sie Ihren Arbeitsplan nicht weiter verwenden möchten, können Sie das Hinzufügen neuer Credits einfach überspringen. Nach 7 Tagen wird Ihr Konto gesperrt und nach 360 Tagen gelöscht.`,
    'usernotmapped_txt1': `Sie sind keinem Benutzer in der Liste zugeordnet.`,
    'schedual.adminrequest': `Verwalter`,
    'wallet.waiting_for_validation': `Warten auf Validierung.`,
    'usernotmapped_title': `Wichtig!`,
    'report.downloadExcel': `Excel herunterladen`,
    'appdiv.changepass': `Passwort ändern`,
    'shiftuserinput.btn6': `Zeit einstellen`,
    'appmenu.user': `Benutzer`,
    'wallet.txtWeb1': `Im Moment unterstützen wir nur den Kauf neuer Credits über Google Play oder iOS App Store.`,
    'billing.msgHead1': `Bitte fügen Sie mehr Credits hinzu, bevor Sie`,
    'appmenu.changepass': `Passwort ändern`,
    'wallet.deferred': `Der Kauf von wurde aufgeschoben.`,
    'request.titleUserrequestshift': `Schichtantrag`,
    'set.settings.btn.sSwp': `Schichten tauschen`,
    'wallet.success': `Erfolg.`,
    'wallet.failed': `Der Kauf ist fehlgeschlagen.`,
    'schedual.btnAddUser': `Mitarbeiter hinzufügen`,
    'shiftuserinput.swapTitle1': `Benutzer zum Tausch auswählen`,
    'shiftuserinput.usercomment': `Benutzer-Kommentar`,
    'shiftuserinput.btn7': `Schicht einem anderen Benutzer zuweisen`,
    'report.h_break': `Pause`,
    'billing.infoTxt1': `Alle 30 Tage überprüfen wir die verbrauchten Credits im Vergleich zu Ihren bestehenden Credits. Wenn Ihre Credits niedrig sind, haben Sie 7 Tage Zeit, weitere Credits hinzuzufügen.`,
    'billing.msgBodyDays': `Tage`,
    'wallet.faild': `kontaktieren Sie den Support, wenn Sie Fragen haben`,
    'shiftuserinput.title2': `Bitten Sie um eine Änderung`,
    'report.h_sik': `Krank`,
    'report.h_hours': `Stunden`,
    'report.noscheduled': `Keine geplanten Stunden in diesem Monat`,
    'schedual.btnAddShift': `Schicht hinzufügen`,
    'wallet.failed1': `Kontaktieren Sie den Support, wenn Sie Fragen haben`,
    'wallet.pleasewait': `bitte warten`,
    'shiftuserinput.addCommentToUser': `Kommentar zum Benutzer hinzufügen`,
    'shiftuserinput.btn5': `Schicht abbrechen`,
    'shiftuserinput.title0': `Schicht aktualisieren`,
    'appmenu.pushmessages': `Push-Benachrichtigungen`,
    'wallet.title1': `Mehr Credits kaufen`,
    'WeCanNotMatchYou': `Wir können Ihre Mail nicht einem Benutzer zuordnen. Bitten Sie Ihren Vorgesetzten, Ihre Mail einem Benutzer in der Liste hinzuzufügen.`,
    'settings.title': `Präferenzen`,
    'and': `und`,
    'setup.yesImAManger': `Ja, ich bin ein Manager`,
    'settings.account': `Konto`,
    'Help': `Hilfe`,
    'dontShowThis': `Zeigen Sie dies nicht beim Start`,
    'IncomingRequests': `Eingehende Anfragen`,
    'Preferences...': `Präferenzen...`,
    'billing.msgLink': `Gehen Sie zu TimeTo.work -> Präferenzen -> Rechnungsstellung. Im oberen Menü`,
    'policy': `Datenschutzerklärung`,
    'joy.welcome': `Herzlich willkommen!`,
    'joy.Last': `Letzte`,
    'log': `Einloggen `,
    'EditChat': `Chat bearbeiten`,
    'joy.startbyaddingusers': `Beginnen Sie mit dem Hinzufügen einiger Mitarbeiter`,
    'Toggle menu bar icon': `Menüleistensymbol umschalten`,
    'AddMoreCredits': `Weitere Credits hinzufügen`,
    'Paste schedule': `Arbeitsplan einfügen`,
    'Staff': `Mitarbeiter`,
    'Signout': `Ausloggen `,
    'settings.billing': `Rechnungsstellung`,
    'Hide TimeTo.Work': `TimeTo.work ausblenden`,
    'Quit': `Beenden`,
    'Copy': `kopieren`,
    'joy.clickHereToAddMultipleUsers': `Hier können Sie mehrere Mitarbeiter auf einmal hinzufügen`,
    'Reports as XLSX': `Berichte als XLSX`,
    'Clear all data': `Alle Daten löschen`,
    'Edit': `bearbeiten`,
    'OrAreYouAManger': `Oder sind Sie ein Manager und möchten einen neuen Personalplan erstellen?`,
    'companyNameIsNotValid': `Firmenname ist nicht gültig`,
    'a minute ago': `vor einer Minute`,
    'About TimeTo.Work': `Über TimeTo.work`,
    'OutgoingRequests': `Ausgehende Anfragen`,
    'joy.clickHereToAddAShift': `Klicken Sie hier, um eine Schicht hinzuzufügen`,
    'settings.settings': `Einstellungen`,
    'AreYouSureYouWantToQuitTitle': `Beenden bestätigen`,
    'joy.Back': `Zurück`,
    'window': `TimeTo.Work`,
    'How do i ...': `Wie kann ich...`,
    'Divider': `Teiler`,
    'byclicking': `Durch Klicken auf 'Erstellen' stimmen Sie der`,
    'all': `Alle`,
    'joy.goToSchedule': `gehen Sie zum Arbeitsplan, um mit der Terminierung zu beginnen`,
    'Saved': `Gespeichert`,
    'an hour ago': `vor einer Stunde`,
    'joy.whenYourDone': `Wenn Sie mit dem Hinzufügen von Mitarbeitern fertig sind,`,
    'Shift': `Schicht`,
    'TotalBreakTime': `Gesamte Pausenzeit`,
    'shortIntro': `Dies ist eine kurze Einführung, um Sie auf den neuesten Stand zu bringen, wie TimeTo.work funktioniert`,
    'a day ago': `vor einem Tag`,
    'minutes ago': `vor Minuten`,
    'Window': `Fenster`,
    'Clear schedule': `Klarer Arbeitsplan`,
    'Copy schedule': `Arbeitsplan kopieren`,
    'New schedule': `Neuer Arbeitsplan`,
    'Hide Others': `Andere ausblenden`,
    'Cut': `ausschneiden`,
    'RestorePreviousPurchase': `Vorherigen Kauf wiederherstellen`,
    'currentPrice': `1 Benutzer für 30 Tage = 1 Kredit`,
    'AreYouSureYouWantToQuitNo': `Nein`,
    'WelcomeTo': `Willkommen bei TimeTo.work`,
    'joy.addMultipleRows': `Hier können Sie mehrere Zeilen auf einmal hinzufügen`,
    'joy.Next': `Nächste`,
    'Prev': `Vorherige `,
    'Next': `Nächste`,
    'Export': `Exportieren`,
    'New staff': `Neue Mitarbeiter`,
    'AreYouSureYouWantToQuit': `Sind Sie sicher, dass Sie aufhören wollen?
Sie werden alle unveröffentlichten Daten verlieren.`,
    'zoom': `vergrößern`,
    'joy.dontForgetToSave': `Wenn Sie fertig sind, vergessen Sie nicht, Ihren Arbeitsplan zu speichern und zu veröffentlichen`,
    'users.invalidphone2': `Sie müssen die Landesvorwahl vor der Nummer hinzufügen`,
    'users.notemail': `Dies ist keine gültige E-Mail`,
    'users.staffmembers': `Mitarbeiter`,
    'schedual.addSName': `Name des Arbeitsplans`,
    'schedual.editS': `Zeitpläne bearbeiten`,
    'schedual.m.tools': `Werkzeuge`,
    'schedual.m.Reports': `Berichte`,
    'schedual.m.Request': `Anfrage`,
    'schedual.m.Today': `Heute`,
    'schedual.emptyTxt1': `Benutzen Sie den sekundären Klick, um Personal oder Schichten hinzuzufügen.`,
    'schedual.emptyTxt2': ` `,
    'createNewAccount.ampmOr24h': `Zeitformat`,
    'createNewAccount.termsApprove': `Sie müssen die Bedingungen und Konditionen genehmigen`,
    'createNewAccount.terms': `Ich stimme den Allgemeinen Geschäftsbedingungen zu`,
    'createNewAccount.useSameEmail': `Verwenden Sie dieselbe E-Mail, die Sie in der Einladung erhalten haben. So können wir Sie einem Benutzer im Arbeitsplan zuordnen.`,
    'set.billing': `Rechnungsstellung`,
    'user.allLinked': `Alle Benutzer ist verlinkt`,
    'user.unlockEmail': `E-Mail freischalten`,
    'users.uniqueemail': `E-Mail muss eindeutig sein`,
    'schedual.m.pasteS': `Arbeitsplan einfügen`,
    'schedual.m.addNewSchedule': `Neuen Arbeitsplan hinzufügen`,
    'schedual.m.Staff': `Mitarbeiter`,
    'schedual.m.Schedule': `Arbeitsplan`,
    'schedual.last': `Zuletzt aktualisiert`,
    'locked.txt1': `Ihr Konto ist vorübergehend gesperrt, da keine Credits vorhanden sind.`,
    'locked.title': `Ihr Konto ist gesperrt!`,
    'setup.needSetup': `Wir haben Ihr Konto erfolgreich eingerichtet, aber wir müssen es mit einem Unternehmen verbinden.`,
    'createNewAccount.youneedkey': `Sie benötigen einen Firmenschlüssel`,
    'createNewAccount.emailAndPass': `E-Mail und Passwort`,
    'set.settings': `Einstellungen`,
    'user.bulkTitle': `Einfügen einer durch Komma getrennten Liste mit Benutzern`,
    'users.delete': `Wenn Sie den Benutzer löschen, wird er endgültig gelöscht !
    Und alle historischen Daten werden mit 'Unbekannter Benutzer' markiert.
Manchmal ist es vielleicht besser, den Benutzer einfach als inaktiv zu markieren.`,
    'users.dname': `Name anzeigen`,
    'users.newuser': `Neu`,
    'schedual.deleteBtn2': `Dauerhaftes Löschen bestätigen`,
    'schedual.deleteWarning': `Wenn Sie den Arbeitsplan löschen, wird er dauerhaft gelöscht und alle historischen Daten dazu`,
    'schedual.deleteBtn1': `Arbeitsplan löschen`,
    'schedual.m.addOpenShifts': `'offene Schichten' hinzufügen`,
    'schedual.m.print': `Drucken`,
    'schedual.m.Chat': `Chat`,
    'schedual.openshifts': `Offene Schichten`,
    'locked.link': `Klicken Sie hier, um weitere Credits zu erhalten`,
    'schedual.dropdelete': `Lassen Sie Schicht/Benutzer hier fallen, um sie zu entfernen!`,
    'setup.mappingUser': `Benutzer einem Arbeitsplan zuordnen ...`,
    'user.inviteUnMaped': `Nicht verknüpfte Benutzer einladen`,
    'set.settings.title': `System-Einstellungen`,
    'user.bulkTxt': `Ein Benutzer pro Zeile. Name und Nummer/Mail mit einem Komma trennen`,
    'users.deleteBtn1': `Benutzer löschen`,
    'users.uniqueemail2': `Diese Mail kann nicht verwendet werden`,
    'schedual.m.undo': `Alle Änderungen rückgängig machen`,
    'schedual.m.copyS': `Arbeitsplan kopieren`,
    'schedual.m.Home': `Startseite`,
    'schedual.emptyTitle': `Ihr Terminkalender ist leer !`,
    'setup.wrongKey': `Firmenschlüssel fehlt oder ist falsch`,
    'user.bulkimport': `Bulk-Import`,
    'users.deleteBtn2': `Dauerhaftes Löschen bestätigen`,
    'schedual.m.clear': `Alle Schichten löschen`,
    'users.invalidphone': `Dies ist keine gültige Handynummer`,
    'user.bulkTxt2': `Name, E-Mail oder Telefon anzeigen
John Doe, +1123456789 oder Jane Doe, jane@timeto.work.`,
    'schedual.lbl-openshifts': `Offene Schichten`,
    'schedual.addSFirstTitle': `Neuen Arbeitsplan hinzufügen`,
    'login.sentResetMail': `Wir haben einen Reset-Link an Ihre E-Mail geschickt.`,
    'message.in': `Ein`,
    'email': `E-Mail`,
    'login.forCom': `Für das Unternehmen`,
    'message.in.empty': `Sie haben keine Wünsche.`,
    'credit': `Kredit`,
    'Jan': `Jan`,
    'invites': `einladen`,
    'July': `Juli`,
    'Nov': `Nov `,
    'validphone': `+ Landesvorwahl und Handynummer`,
    'friday': `Freitag`,
    'tooltip.Approve': `Genehmigen`,
    'no': `Nein`,
    'calendar.empty': `Diese Woche gibt es keinen Arbeitsplan`,
    'schedual.btnHelp': `Hilfe`,
    'schedual.btnAddDiv': `Trennzeichen hinzufügen`,
    'schedual.btnPublish': `Speichern und veröffentlichen`,
    'wallet.restored': `Der Kauf von ist wiederhergestellt worden.`,
    'wallet.success1': `die Gutschriften werden in Kürze auf Ihr Konto eingehen`,
    'request.titleSick': `Krank`,
    'request.titleTime': `Zeitabweichung`,
    'request.titleMove': `Schicht verschieben`,
    'request.titleCancle': `Schicht abbrechen`,
    'shiftuserinput.cancelation': `Ursache der Annullierung`,
    'shiftuserinput.swapTitle3': `Schicht auswählen`,
    'shiftuserinput.swapTitle2': `Woche auswählen`,
    'shiftuserinput.comment': `Kommentar`,
    'shiftuserinput.btn8': `Schicht mit einem anderen Benutzer tauschen`,
    'shiftuserinput.btn4': `Als krank markieren`,
    'shiftuserinput.btn1': `Berichtszeit-Abweichung`,
    'shiftuserinput.title3': `Einen Kommentar hinzufügen`,
    'shiftuserinput.title1': `Tatsächlich gearbeitete Zeit`,
    'joy.Close': `schließen`,
    'front': `Alle an die Front bringen`,
    'minimize': `minimieren`,
    'Quick introduction': `Schnelle Einführung`,
    'Schedule as PDF': `Arbeitsplan als PDF`,
    'New chat': `Neuer Chat`,
    'Select All': `Alle auswählen`,
    'services': `Dienstleistungen`,
    'years ago': `vor Jahren`,
    'months ago': `vor Monaten`,
    'days ago': `vor Tagen`,
    'hours ago': `vor Stunden`,
    'a few seconds ago': `vor ein paar Sekunden`,
    'joy.clickonstaff': `Klicken Sie auf 'Mitarbeiter'.`,
    'a year ago': `vor einem Jahr`,
    'a month ago': `vor einem Monat`,
    'seconds ago': `vor Sekunden`,
    'joy.orClickOnTheSchedule': `Oder Sie können auf den Arbeitsplan doppelklicken`,
    'joy.clickHerToAddUsers': `Klicken Sie hier, um einen oder zwei Mitarbeiter hinzuzufügen`,
    'joy.Skip': `Tutorial überspringen`,
    'Undo all changes': `Alle Änderungen rückgängig machen`,
    'Add to schedule': `Zum Arbeitsplan hinzufügen`,
    'File': `Datei`,
    'selectuser.warning': `Wählen Sie mindestens einen Benutzer zum Hinzufügen zum Arbeitsplan`,
    'usernotmapped_txt2': `Bitten Sie Ihren Schema-Administrator, Ihre E-Mail-Adresse hinzuzufügen`,
    'report.h_schedal': `Arbeitsplan`,
    'report.h_presence': `Präsenz`,
    'billing.bHistory': `Geschichte der Rechnungsstellung`,
    'billing.msgHead': `Ihr Konto verfügt nicht über genügend Credits.`,
    'set.settings.btn.sCha': `Zeit ändern`,
    'Show All': `Alle anzeigen`,
    'Paste': `Einfügen`,
    'userCredits': `Benutzercredits`,
    'ShiftLabel': `Schicht Kennzeichen `,

}