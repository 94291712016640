import React, { Component } from "react";
import { Icon, Image } from "../../../common/component/Button";
import { shallowEqual } from 'shouldcomponentupdate-children';
import { BButton } from '../../../common/component/Button'

import noUserImage from '../../../../img/noimg.png'
import { getUserImage } from '../../../../core/util/helpers'

class UserCard extends Component {
  state = { isLoading: false }

  shouldComponentUpdate(nextProps, nextState) {
    // console.log(this.props)
    //const r = shallowEqual(this.props, nextProps, this.state, nextState);

    // let r = false
    // if (r === true) {
    // Object.keys(this.props).forEach(key => {
    //   try {

    //     //   r = (this.props[key] !== nextProps[key])
    //     // if (r === true)
    //     //   return true

    //     if (this.props[key] !== nextProps[key]) {
    //       console.log('===key', key)
    //       console.log('===props', this.props[key] === nextProps[key])
    //     }
    //   } catch (error) {
    //     console.log('err', error)
    //   }

    // });
    // }
    // // console.log('props', this.props === nextProps)
    // // console.log('state', this.state === nextState)

    return shallowEqual(this.props, nextProps, this.state, nextState);
    // console.log('====shouldComponentUpdate>', r)
    // return r
  }


  onOpenModal = key => () => {
    this.props.openModal('user', key);
  };

  cancelClick = (e) => {
    e.stopPropagation()
  }

  sendSms = (phone) => (e) => {
    e.stopPropagation()
    this.setState({ isLoading: true })
    this.props.sendSms(phone);
    setTimeout(() => {
      this.setState({ isLoading: false })
    }, 1000);
  }

  sendMail = (mail) => (e) => {
    e.stopPropagation()
    this.setState({ isLoading: true })
    this.props.sendMail(mail);
    setTimeout(() => {
      this.setState({ isLoading: false })
    }, 1000);
  }
  style = [
    {},
    { height: '40px', },

  ]
  getRole = (role) => {
    if (role === 200)
      return (<Icon name='chess king' size='small'></Icon>)

    if (role === 150)
      return (<Icon name='chess rook' size='small'></Icon>)

    if (role === 50)
      return (<Icon name='chess pawn' size='small'></Icon>)

    return (<Icon name='chess pawn' size='small'></Icon>)
  }
  render() {
    const { user, projectKey } = this.props;
    //var color = user.isActive ? '' : '#f1f1f1';

    const border = 'solid 5px ' + (user.role === 200 ? 'var(--color-btn-green-a)' : user.role === 150 ? 'var(--color-btn-blue)' : '#fff')
    return (
      <div className='userCard' style={{ display: 'flex' }} onClick={this.onOpenModal(user.key)} >
        <div style={this.style[0]} >
          <div style={{ fontWeight: 'bold', whiteSpace: 'nowrap', maxWidth: '220px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {this.getRole(user.role)}

            {user.name}
          </div>

          <Image className={'tour_staff_4'} floated="right" size='tiny' circular style={{ border: border, width: '80px', height: '80px' }}
            src={user.image ?
              getUserImage(projectKey, user.key, user.image)
              : noUserImage}

            onError={(e) => { e.target.onerror = null; e.target.src = noUserImage }}
          />

          <div style={this.style[1]} >
            {user.email && (
              <div style={{ whiteSpace: 'nowrap', maxWidth: '150px', overflow: 'hidden', textOverflow: 'ellipsis', color: 'var(--color-font)' }}>

                <Icon name="mail" />
                {user.email}

              </div>
            )}

            <React.Fragment>
              <span >

                {user.phone && (<div style={{ whiteSpace: 'nowrap', maxWidth: '150px', overflow: 'hidden', textOverflow: 'ellipsis', color: 'var(--color-font)' }}><Icon name="phone" />{user.phone}</div>)}
                {/* {user.phone2 && (<div style={{ whiteSpace: 'nowrap', maxWidth: '150px', overflow: 'hidden', textOverflow: 'ellipsis' }}><a style={{ color: 'var(--color-font)' }} onClick={this.cancelClick} title={user.phone2} target='_blank' rel="noopener noreferrer" href={'tel:' + user.phone2}><Icon name="phone" />{user.phone2}</a></div>)} */}

              </span>
            </React.Fragment>


          </div>
        </div>
        <div style={this.style[0]}>
          {user.sysUserKey && <span floated="left"  >
            {window._getText('users.linked') + ' '}
            <Icon name='check circle' color='green'></Icon></span>}
          {(!user.sysUserKey && user.email) && <BButton className={' tour_staff_5'} compact loading={this.state.isLoading} disabled={this.state.isLoading} floated="left" color="blue" onClick={this.sendMail(user)} icon='mail' labelPosition='left' content={window._getText('invites')}  ></BButton>}
          {(!user.sysUserKey && user.phone) && <BButton className={' tour_staff_5'} compact loading={this.state.isLoading} disabled={this.state.isLoading} floated="right" color="blue" onClick={this.sendSms(user.phone)} icon='phone' labelPosition='left' content={window._getText('invites')}  ></BButton>}


          {/* {(!user.sysUserKey && user.email) &&
            <button style={{}} className="btn btn-default" disabled={this.state.isLoading} onClick={this.sendMail(user)}>
              <div tooltip={window._getText("invites")}>
                <span className="ico ico-mail"></span> send invite
              </div>
            </button>
          } */}
          {/* <Button color="blue" onClick={this.onOpenModal(user.key)} floated="right" compact >View</Button> */}
        </div>
      </div>
    );
  }
}

export default UserCard;
