import React, { PureComponent } from "react";
import Modal from "semantic-ui-react/dist/commonjs/modules/Modal";
import { Button } from "../../common/component/Button";
import { isMobile } from "react-device-detect";
import { ElectronWindowPortal } from "./ElectronWindowPortal";

import { isElectron } from "react-device-detect";
let ipcRenderer;

export const Header = (data) => {
  //// console.log('Header>', Array.isArray(data.children))

  if (Array.isArray(data.children)) {
    return data.children.map((child) => {
      return child;
    });
  }

  if (data.children) {
    if (typeof data.children === "string" || data.children instanceof String)
      return data.children;

    return { ...data.children };
  }

  return null;
};
Header.displayName = "Header";

export const Content = (data) => {
  //// console.log('Content')
  const { children } = data;
  if (Array.isArray(children)) {
    return (
      // <Modal.Content {...rest}>

      children.map((child) => {
        return child;
      })

      // </Modal.Content>
    );
  }

  if (data.children) {
    if (typeof data.children === "string" || data.children instanceof String)
      return data.children;

    return { ...data.children };
  }

  return null;
};
Content.displayName = "Content";

export const Actions = (data) => {
  //// console.log('Actions')
  if (Array.isArray(data.children)) {
    return data.children.map((child) => {
      return child;
    });
  }

  if (data.children) {
    if (typeof data.children === "string" || data.children instanceof String)
      return data.children;

    return { ...data.children };
  }

  return null;
};
Actions.displayName = "Actions";

const MobileModal = (props) => (
  <div
    style={{
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: "#fff",
      zIndex: 100000,
    }}
  >
    {/* <div {...rest} className='MobileModal'  > */}
    {/* <div style={{ backgroundColor: '#fff', height: '40px' }}> */}
    {props.hideCloseButton !== true && (
      <Button
        compact
        className="closeButton"
        size="huge"
        onClick={props.onClose}
        floated="right"
        icon="close"
        style={{
          zIndex: 100,
          position: "absolute",
          top: "12px",
          right: "16px",
        }}
      />
    )}
    {/* <div style={{ paddingLeft: '15px', paddingTop: '10px', fontWeight: '700', fontSize: '20px' }}>{this.getContent('Header')}
               </div>
            </div> */}
    {/* <div className='modalBodyScroll' id={'modalBodyScroll_' + this.props.name || ''} > */}
    <div
      style={{
        padding: "20px",
      }}
    >
      {props.content}
    </div>
    {/* </div> */}
    <div
      style={{
        // border: "solid 1px blue",
        position: "absolute",
        bottom: 0,
        top: "calc(100vh - 50px)",
        width: "100%",
        // background: 'red',
        zIndex: "3",
      }}
    >
      {props.actions}
    </div>
    {/* </div> */}
  </div>
);

const WebModal = (props) => (
  <Modal {...props.rest} open={true} size="tiny" className="modalBgColor">
    {/* <Modal.Header>
               <div style={{ marginLeft: 'calc(100% - 30px)', marginTop: 5, background: '#fff' }}>
                   {this.props.hideCloseButton !== true &&
                       <Button compact className='closeButton' size='huge' onClick={this.onClose} icon='close' />
                   }
               </div>
            </Modal.Header> */}
    <Modal.Content>
      <div
        style={{
          border: "solid 0px red",
        }}
      >
        {props.hideCloseButton !== true && (
          <Button
            compact
            className="closeButton"
            size="huge"
            onClick={props.onClose}
            icon="close"
            style={{
              border: "none",
              zIndex: 11,
              backgroundColor: "transparent",
              position: "absolute",
              right: 7,
              top: 5,
            }}
          />
        )}
        <div
          style={{
            top: 10,
            position: "relative",
            maxHeight: "calc(100vh - 200px)",
            overflow: "hidden",
            minHeight: 200,
          }}
        >
          {props.header}
          {props.content}

          {/* {actions &&
                           <div style={{ marginTop: '8px', border: 'solid 0px green', position: 'fixed', bottom: '8px', left: '8px', right: '8px' }}>
                               {actions}
                           </div>
                        } */}
        </div>
      </div>
    </Modal.Content>
    <Modal.Actions>
      {props.actions}
      <div
        style={{
          clear: "both",
          height: "10px",
        }}
      ></div>
    </Modal.Actions>
  </Modal>
);

const ElectronModal = (props) => (
  <ElectronWindowPortal
    name={props.name}
    modalOnunload={props.modalOnunload}
    doRerender={() => props.setState({})}
  >
    {props.css && (
      <div className={props.open === true ? "xfadeIn2" : "xfadeOut2"}>
        {/*  */}
        <div
          className={props.getCss()}
          style={
            {
              // marginLeft: tipPos.x === 'right' && tipPos.showArrow !== false ? '10px' : '',
              // marginRight: tipPos.x === 'left' && tipPos.showArrow !== false ? '10px' : ''
            }
          }
        >
          {/* {this.props.name === 'eventModal' && tipPos.x !== 'left' && tipPos.showArrow !== false && <div className='pointerDivLeft'></div>}
                    {this.props.name === 'eventModal' && tipPos.x === 'left' && tipPos.showArrow !== false && <div className='pointerDivRight'></div>} */}

          <div
            style={{
              xbackgroundColor: "var(--color-bg1)",
            }}
            className="dragWindow"
          >
            {props.header && (
              <div
                style={{
                  paddingLeft: "20px",
                  paddingTop: "10px",
                }}
              >
                <h2>{props.header}</h2>
              </div>
            )}
          </div>

          <div className="modalBodyScrollElectron">
            <div
              style={{
                paddingTop: "0px",
                marginBottom: props.headerspace ? props.headerspace : "0px",
              }}
            >
              {props.content}

              {props.actions && (
                <div
                  style={{
                    marginTop: "8px",
                    border: "solid 0px green",
                    position: "fixed",
                    bottom: "8px",
                    left: "8px",
                    right: "8px",
                  }}
                >
                  {props.actions}
                </div>
              )}
            </div>
          </div>
        </div>
        <button
          onClick={() => props.setState({})}
          style={{
            display: "none",
          }}
        >
          {" "}
        </button>
        {/* HACK to make open.window and portals work */}
      </div>
    )}
  </ElectronWindowPortal>
);

export default class ModalFrame extends PureComponent {
  static Header = Header;
  static Content = Content;
  static Actions = Actions;
  state = {
    reRender: 0,
    tipPos: { x: "left", y: "top", showArrow: false },
    css: true,
  };

  tipPos = (event, arg) => {
    //// console.log('--->REPLY', arg)
    this.setState({ tipPos: arg });
  };
  onCloseBlur = () => {
    // console.log('------------------>>>>>>>>blut close')
    this.setState({ css: false }, () => {
      setTimeout(() => {
        this.onClose();
      }, 50);
    });
  };
  componentDidMount() {
    if (isElectron && this.props.name === "eventModal") {
      if (!ipcRenderer) ipcRenderer = window.require("electron").ipcRenderer;

      ipcRenderer.on("renderWindow-reply", this.tipPos);
      ipcRenderer.on("onCloseBlur", this.onCloseBlur);
    }
  }

  componentWillUnmount() {
    if (isElectron && this.props.name === "eventModal") {
      if (!ipcRenderer) ipcRenderer = window.require("electron").ipcRenderer;

      ipcRenderer.removeListener("renderWindow-reply", this.tipPos);
      ipcRenderer.removeListener("onCloseBlur", this.onCloseBlur);
    }
    //  clearAllBodyScrollLocks();
  }
  componentDidUpdate = async (prevProps, prevState, snapshot) => {
    if (prevProps) {
      if (prevProps.open === true && this.props.open === false) {
        this.setState({ css: false });
      } else if (prevProps.open === false && this.props.open === true) {
        this.setState({ css: true });
      }
    }
  };
  getContent = (key) => {
    var child = this.props.children.filter(function(obj) {
      if (!obj.type) return false;

      return obj.type.displayName === key;
    })[0];

    if (child) return React.cloneElement(child, child.children);

    return null;
  };
  onClose = () => {
    if (this.props.onClickClose) this.props.onClickClose();
    // enableBodyScroll()
  };
  modalOnunload = () => {
    try {
      this.props.onClickClose();
    } catch (error) {
      // console.log('#Err ModalFrame ', error)
    }
  };
  getCss = () => {
    // // console.log('getCss')
    const { name } = this.props;
    //// console.log('>>>>>>>>>>>>>>>>>name.>>', name)
    switch (name) {
      case "eventModal":
        return "electronModalEvent";
      case "appSettings":
        return "electronModalSettings";
      case "selectusersModal":
        return "electronModalSelectuser";
      case "selectuser_schedual":
        return "electronModalSelectuser";
      case "sendinvites":
        return "electronModalSendinvites";

      default:
        return "electronModal";
    }
  };
  setstate = (obj) => {
    this.setState(obj);
  };
  render() {
    const { children, onClickClose, hideCloseButton, ...rest } = this.props;
    const { tipPos } = this.state;

    if (this.props.open !== true) {
      return null;
    }

    const content = this.getContent("Content");
    const actions = this.getContent("Actions");
    const header = this.getContent("Header");

    if (isElectron) {
      return (
        <ElectronModal
          name={this.props.name}
          modalOnunload={this.modalOnunload}
          setState={this.setstate}
          css={this.state.css}
          open={this.props.open}
          getCss={this.getCss}
          headerspace={this.props.headerspace}
          content={content}
          actions={actions}
          header={header}
        ></ElectronModal>
      );
    } else if (isMobile === true) {
      return (
        <MobileModal
          hideCloseButton={this.props.hideCloseButton}
          onClose={this.onClose}
          content={content}
          actions={actions}
        ></MobileModal>
      );
    } else {
      return (
        <WebModal
          hideCloseButton={this.props.hideCloseButton}
          onClose={this.onClose}
          rest={rest}
          content={content}
          actions={actions}
          header={header}
        ></WebModal>
      );
    }
  }
}
