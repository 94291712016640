import React, { PureComponent } from "react";
import { connect } from "react-redux";
import Message from "semantic-ui-react/dist/commonjs/collections/Message";
import Loader from "semantic-ui-react/dist/commonjs/elements/Loader";
 import moment from "moment";
import Wallet from "./Wallet";
import FirestoreListnerHelper from "../../../../core/util/FirestoreListnerHelper";
import Table from "../../../common/component/Tabel";

class Billing extends PureComponent {
  //thisMonth = moment.utc().format('YYYYMM');
  //static contextTypes = { store: PropTypes.object.isRequired }
  componentDidMount() {
    FirestoreListnerHelper.setBillingListner(this.context);
    // FirestoreListnerHelper.setInvoicesListner(this.context);
  }

  componentWillUnmount = () => {
    // FirestoreListnerHelper.clearBillingListners(this.context);
  };
  componentDidUpdate = (prevProps, prevState, snapshot) => {};

  renderInfoTest = () => {
    return (
      <div style={{ paddingTop: "10px", maxWidth: "900px" }}>
        <h5>{window._getText("billing.payDetails")} </h5>
        <p style={{ color: "sliver" }}>
          {window._getText("billing.infoTxt1")}
          <br />
          {window._getText("billing.infoTxt2")} <br />
          {window._getText("billing.infoTxt3")}
          <br />
        </p>
        <br />
      </div>
    );
  };

  render() {
    var { billing, billingTransactions, project } = this.props;
    var billingArray = [];
    var billingTransactionsArray = [];
    var dayDiff = 0;
    const diablseAccountAfterDays = 7;
    if (project && project.disableDate) {
      var disableDate = moment(project.disableDate)
        .utc()
        .add(diablseAccountAfterDays, "days");
      var now = moment().utc();
      dayDiff = disableDate.diff(now, "days");
      // console.log("-----------");
      // console.log("disableDate", disableDate.format("YYYY-MM-DD HH:mm"));
      // console.log("dayDiff", dayDiff);
      // console.log("project.enabled", project.enabled);
      // console.log("project.disabled", project.disabled);
      // console.log("-----------");
    }

    if (billing && billing[0]) {
      billingArray = Object.keys(billing[0]).map(key => {
        if (key === "id") return null;
        return { ...billing[0][key], key };
      });
      billingArray = billingArray.filter(Boolean);

      billingArray.sort((a, b) => {
        if (a.key > b.key) return -1;
        if (a.key < b.key) return 1;

        return 0;
      });
    }

    if (billingTransactions) {
      billingTransactionsArray = Object.keys(billingTransactions).map(key => {
        return { ...billingTransactions[key] };
      });
      billingTransactionsArray.sort((a, b) => {
        if (a.date > b.date) return -1;
        if (a.date < b.date) return 1;

        return 0;
      });
    }

    //console.log('billingArray', billingArray)

    if (!billingArray || !project)
      return (
        <div style={{ textAlign: "center", marginTop: "50px" }}>
          <Loader inline active>
            {window._getText("loading")}
          </Loader>
        </div>
      );

    var sum = 0; // eslint-disable-line no-unused-vars
    var activeUsers = 0;
    var rows = 0;

    var rowCount = Object.keys(billingArray || {}).length;

    const billingTableData = [];
    for (let index = 0; index < billingArray.length; index++) {
      const day = billingArray[index];
      // const daySum = Number(day.true) * 0.02
      activeUsers += Number(day.true || 0);
      billingTableData.push([
        index,
        moment(day.key).format("L"),
        day.true && day.true,
        day.false && day.false
      ]);
    }

    const billingTransactionsArrayTableData = [];
    for (let index = 0; index < billingTransactionsArray.length; index++) {
      const item = billingTransactionsArray[index];
      billingTransactionsArrayTableData.push([
        moment.unix(item.date / 1000).format("L HH:mm"),
        item.bType,
        item.value
      ]);
    }

    return (
      <div
        style={{
          paddingLeft: "10px",
          overflow: "auto",
          paddingBottom: "80px",
          maxHeight: 500
        }}
      >
        {project.disabled && (
          <Message color="red">
            <Message.Header>
              {window._getText("billing.msgHead")}
            </Message.Header>
            {window._getText("billing.msgBody")}
          </Message>
        )}

        <div>
          <div
            style={{
              position: "fixed",
              top: "10px",
              right: "30px",
              fontWeight: "bold",
              color: project.credits < 0 ? "#DB2828" : "#198f35",
              fontSize: "25px"
            }}
          >
            {window._getText("credit")}:{project.credits || 0}
          </div>

          <div style={{ paddingTop: "10px" }}>
            <Wallet></Wallet>
          </div>

          <div style={{ paddingTop: 10, paddingRight: 10 }}>
            <h5>{window._getText("billing.nextInvoice")}</h5>
            <Table
              head={[
                "#",
                window._getText("date"),
                window._getText("active"),
                window._getText("inactive")
              ]}
              rows={billingTableData}
              maxHeight={100}
              widths={[30, "100%", 100, 100]}
            ></Table>
          </div>

          <div style={{ paddingTop: 10, paddingRight: 10 }}>
            <h5>{window._getText("billing.bHistory")}</h5>
            <Table
              head={[
                window._getText("date"),
                window._getText("type"),
                window._getText("credit")
              ]}
              rows={billingTransactionsArrayTableData}
              maxHeight={100}
              widths={[130, "100%", 50]}
            ></Table>
          </div>

          {/* {rowCount > 0 && this.renderInfoTest()} */}
          {this.renderInfoTest()}
        </div>
      </div>
    );
  }
}

export default connect(state => ({
  billing: state.firestore.ordered.billing,
  billingTransactions: state.firestore.ordered.billingTransactions,
  project: state.firestore.data.project,
  projectKey: state.firebase.profile.projectKey
}))(Billing);
