import { toastr } from 'react-redux-toastr'
import shortid from 'shortid'
import { setIsLoadingSchedule } from './currentScheduelDataAction'
import object from "lodash/object";
import captureException from 'sen-obj';
import { Factory } from '../../util/Factory'
import { getDateKey } from '../../util/helpers'
const moment = require('moment')


var pickBy = object.pickBy

export const addevent = event => async (dispatch, getState, { getFirebase, getFirestore }) => {
    try {
        const firestore = getFirestore();
        const projectKey = getState().firebase.profile.projectKey;
        const newEvent = { ...event, key: shortid.generate(), updatedAt: firestore.FieldValue.serverTimestamp() }
        await firestore.set(`projects/${projectKey}/events/${newEvent.key}`, newEvent)
        toastr.success('Yes!', `new Event '${newEvent.label}' was successfully created`);
    } catch (error) {
        console.log('addevent', error)
        captureException(error);
        toastr.error('Error', error);
    }

}

export const addTemplateShift = shift => async (dispatch, getState, { getFirebase, getFirestore }) => {
    try {
        // console.log('addTemplateShift to DB')
        const firestore = getFirestore();
        const state = getState()
        const projectKey = state.firebase.profile.projectKey;
        const scheduleKey = state.currentScheduelData.selectedKey;
        const newShift = { ...shift, updatedAt: firestore.FieldValue.serverTimestamp() }
        await firestore.doc(`projects/${projectKey}/schedules/${scheduleKey}`).set({ templates: { [shift.key]: newShift } }, { merge: true })
        toastr.success(`Template is saved!`);
    } catch (error) {
        console.log('addTemplateShift', error)
        captureException(error);
        toastr.error('Error', error);
    }

}

export const removeSchedule = key => async (dispatch, getState, { getFirebase, getFirestore }) => {
    try {
        // console.log('removeSchedule')
        const firestore = getFirestore();
        const projectKey = getState().firebase.profile.projectKey;
        // console.log('kill', `projects${projectKey}schedules${key}`)
        await firestore.delete({
            storeA: 'delete_' + key,
            collection: 'projects',
            doc: projectKey,
            subcollections: [{
                collection: "schedules",
                doc: key
            }],

        })

        //await firestore.delete(`projects/${projectKey}/schedules/${key}`)
        toastr.success('Okej!', `we removed the schedule successfully`);
    } catch (error) {
        console.log('removeSchedule', error)
        captureException(error);
        toastr.error('Error', error);
    }
}

export const addSchedule = schedule => async (dispatch, getState, { getFirebase, getFirestore }) => {
    try {
        const firestore = getFirestore();
        const projectKey = getState().firebase.profile.projectKey;
        const newSchedule = { ...schedule, updatedAt: firestore.FieldValue.serverTimestamp(), createdAt: firestore.FieldValue.serverTimestamp() }
        await firestore.set(`projects/${projectKey}/schedules/${newSchedule.key}`, newSchedule)
        toastr.success('Saved successfully');
    } catch (error) {
        console.log('addSchedule', error)
        captureException(error);
        toastr.error('Error', error);
    }

}

export const unPublish = (schedule, dateKey) => async (dispatch, getState, { getFirebase, getFirestore }) => {
    try {
        // console.log('inpublish schedule', schedule.key)
        const firestore = getFirestore();
        const projectKey = getState().firebase.profile.projectKey;
        firestore.collection(`projects/${projectKey}/schedules/${schedule.key}/events`).doc(dateKey).set({ unPublish: true, publish: false }, { merge: true })
    } catch (error) {

        console.log('unPublish', error)

        toastr.error('Error', error);
        captureException(error);
    }

}
export const updateEventBatchMultiWeeks = (sList, schedule, dateKey, sortedUserList, dividerList, publish, unPublish, weeksToShow, renderDayDate) => async (dispatch, getState, { getFirebase, getFirestore }) => {
    let r = false
    try {

        // console.log('*************************************')
        // console.log('save data', { sList, schedule, dateKey, sortedUserList, dividerList, publish, unPublish })
        // console.log('*************************************')
        // console.log('>>updateEventBatchMultiWeeks', 'problem with multi update, the all have the same dividerlist', dividerList)
        // console.log('>>', { sList, schedule, dateKey, sortedUserList, dividerList, publish, unPublish })
        let shiftList = { ...sList }

        // console.log('..>', shiftList)


        let weekShiftList = {}
        let deleteShiftList = {}
        //if (shiftList) {



        let shiftListArray = Object.keys(shiftList).forEach(key => {
            var shift = shiftList[key]
            if (shift.date) {
                let week = getDateKey(moment(shift.date).utc().startOf('week'))
                // console.log('week', week)
                if (!weekShiftList[week])
                    weekShiftList[week] = {}

                weekShiftList[week][key] = shiftList[key]


            }



            //    if (shiftList[key].removed)
            //      deleteShiftList[key] = { ...shiftList[key], week }

        })



        for (let index = 0; index < weeksToShow; index++) {
            let weekKey = getDateKey(moment(renderDayDate).utc().add(index * 7, 'days').startOf('week'))
            // console.log('save>>> weekKey >>', weekKey)
            let week = moment(renderDayDate).isoWeek()
            let _sList = weekShiftList[weekKey]
            dispatch(updateEventBatch2(_sList, schedule, weekKey, sortedUserList, dividerList, publish, unPublish, week))
        }

        // Object.keys(weekShiftList).forEach(key => {
        //     let _sList = weekShiftList[key]
        //     debugger
        //     dispatch(updateEventBatch2(_sList, schedule, key, sortedUserList, dividerList, publish, unPublish))

        //     // console.log('save ' + key, _sList)
        // });
        // }
        return



    } catch (error) {
        console.log('updateEventBatchMultiWeeks', error)
        toastr.error('Error', error);
        captureException(error);
    }




}

export const updateEventBatch2 = (sList, schedule, dateKey, sortedUserList, dividerList, publish, unPublish, week) => async (dispatch, getState, { getFirebase, getFirestore }) => {
    let r = false

    try {

        // console.log('*************************************')
        // console.log('sList', sList)
        let shiftList = { ...sList }

        //moment(shift.date)

        for (const key in shiftList) {
            if (shiftList.hasOwnProperty(key)) {
                var shift = shiftList[key];
                //console.log('---<shift', moment(shift.date).format('dd'))
            }
        }


        // console.log('shiftList', Object.keys(shiftList).length)
        // console.log('shiftList', shiftList)

        // console.log()
        // console.log('schedule')
        // console.table(schedule)
        // console.log()
        // console.log('dateKey')
        // console.table(dateKey)
        // console.log()
        // console.log('sortedUserList')
        // console.table(sortedUserList)
        // console.log()
        // console.log('dividerList')
        // console.table(dividerList)
        // console.log('*************************************')



        dispatch(setIsLoadingSchedule(true));
        const firestore = getFirestore();
        const projectKey = getState().firebase.profile.projectKey;

        var batch = firestore.batch();


        if (schedule) {

            const newSchedule = { ...schedule, updatedAt: firestore.FieldValue.serverTimestamp() }



            const scheduleRef = firestore.collection("projects").doc(projectKey).collection("schedules").doc(newSchedule.key);
            batch.set(scheduleRef, newSchedule, { merge: true })//

            if (!shiftList)
                shiftList = []



            if (shiftList) {
                // console.log("shiftList", shiftList)
                const scheduleEventsRef = firestore.collection("projects").doc(projectKey).collection("schedules").doc(newSchedule.key).collection('events').doc(dateKey);

                var newEventList = {}
                // console.log('shiftList', shiftList)
                let i = 0
                var updatedAt = firestore.FieldValue.serverTimestamp()
                Object.keys(shiftList).forEach(key => {

                    // console.log('key', key)
                    // let shift = {...shiftList[key]} 
                    if (shiftList[key] && shiftList[key].removed === undefined) {
                        if (Object.keys(shiftList[key]).length > 0) {

                            var shift = { ...Factory.validateAndClean(shiftList[key]) }
                            shift.updatedAt = updatedAt // firestore.FieldValue.serverTimestamp()
                            //console.log('shift.updatedAt', shift.updatedAt)
                            shiftList[shift.key] = shift
                            newEventList[shift.key] = shift
                        }
                    } else if (shiftList[key] && shiftList[key].removed !== undefined) {
                        // console.log('key', key)


                        // console.log('dateKey', dateKey)
                        newEventList[key] = {} // this is deleted 
                        delete shiftList[key]
                        //  newEventList[key] = firestore.FieldValue.delete()
                    }
                })


                // console.log('newEventList', newEventList)



                var dividerListSave = pickBy(dividerList, obj => {
                    if (obj.removed) return false;
                    return true;
                });


                var saveObj = { events: newEventList }
                saveObj.publish = publish !== undefined ? publish : true

                if (sortedUserList) { saveObj.sortedUserList = sortedUserList.filter((el) => (el != null && el !== undefined)) }
                if (dividerListSave) { saveObj.dividerList = dividerListSave }

                if (newSchedule.name)
                    saveObj.scheduleName = newSchedule.name // wee need this to week views 

                saveObj.unPublish = unPublish ? unPublish : false

                //console.log('saveObj', saveObj) 
                saveObj.updatedAt = firestore.FieldValue.serverTimestamp()
                saveObj.cacheKey = +new Date()

                batch.set(scheduleEventsRef, saveObj, { merge: false }) // used for saving do docs
                // console.log('set batch 2', saveObj)
                // console.log('i', i)
                i++;
                if (i > 400) {  // write batch only allows maximum 500 writes per batch
                    i = 0;
                    console.log('Intermediate committing of batch operation');
                    await batch.commit();
                    batch = firestore.batch();
                }


            }
        }

        batch.commit().then(function () {
            dispatch(setIsLoadingSchedule(false));

        })

    } catch (error) {
        console.log('updateEventBatch2', error)
        toastr.error('Error', error);
        captureException(error);
    }

}

export const updateEventBatch_OLD = (events, schedule) => async (dispatch, getState, { getFirebase, getFirestore }) => {
    try {
        // console.log('setIsLoadingSchedule', true)
        dispatch(setIsLoadingSchedule(true));
        const firestore = getFirestore();
        const projectKey = getState().firebase.profile.projectKey;

        var batch = firestore.batch();
        // console.log('@schedule', schedule)
        // console.log('@schedule', events)
        if (schedule) {
            const newSchedule = { ...schedule, updatedAt: firestore.FieldValue.serverTimestamp() }
            // console.log('##newSchedule', newSchedule)
            const scheduleRef = firestore.collection("projects").doc(projectKey).collection("schedules").doc(newSchedule.key);
            batch.set(scheduleRef, newSchedule)


        }
        if (events) {
            for (let index = 0; index < events.length; index++) {
                const event = events[index];

                const newEvent = { ...event, updatedAt: firestore.FieldValue.serverTimestamp() }
                const eventRef = firestore.collection("projects").doc(projectKey).collection("events").doc(newEvent.key);



                if (newEvent.isNew) {
                    delete newEvent.isNew
                    batch.set(eventRef, newEvent)
                }
                else if (newEvent.removed)
                    batch.delete(eventRef)
                else
                    batch.update(eventRef, newEvent)

            }
        }

        batch.commit().then(function () {


            if (events) { // clean all flags in the current events
                for (let index = 0; index < events.length; index++) {
                    delete events.isNew
                    delete events.removed
                }
            }
            if (events)
                toastr.success('Saved!', `Update successfully`)
            else
                toastr.success('Saved!', `Created schedule ${schedule.name} successfully`)

            dispatch(setIsLoadingSchedule(false));
        });



    } catch (error) {
        console.log('updateEventBatch_OLD', error)
        captureException(error);
        toastr.error('Error', error);
    }

}
export const updateEvent = event => async (dispatch, getState, { getFirebase, getFirestore }) => {
    try {
        const firestore = getFirestore();
        const projectKey = getState().firebase.profile.projectKey;
        const newEvent = {
            ...event,

            updatedAt: firestore.FieldValue.serverTimestamp()
        }

        if (newEvent.isNew) {
            delete newEvent.isNew
            await firestore.set(`projects/${projectKey}/events/${newEvent.key}`, newEvent)
            toastr.success('Yes!', `new Event '${newEvent.label}' was successfully created`);
        }
        else if (newEvent.removed) {
            await firestore.delete(`projects/${projectKey}/events/${newEvent.key}`, newEvent)
            toastr.success('Okej!', `We removed event '${newEvent.label}'`)
        }
        else {
            await firestore.update(`projects/${projectKey}/events/${newEvent.key}`, newEvent)
            toastr.success('Yes!', `update event '${newEvent.label}' was successfully`)
        }



    } catch (error) {
        console.log('updateEvent', error)
        captureException(error);
        toastr.error('Error', error);
    }

}
