import React, { Component } from 'react'
import { Button, Icon, Image } from "../../common/component/Button";
import collection from "lodash/collection";
import { closeModal } from "../../../core/redux/action/modalAction";
import { connect } from "react-redux";
import { FireHelper } from '../../../core/util/FireHelper'
import noUserImage from '../../../img/noimg.png'
//import { height } from 'window-size';
import ModalFrame from './ModalFrame'
import { getUserImage } from '../../../core/util/helpers'
import { toastr } from 'react-redux-toastr'



const modalKey = 'userSendinvite'
export class UserSendInvitesModal extends Component {


    componentDidUpdate(prevProps, prevState, snapshot) {

        //on open
        if (prevProps.isOpen === false && this.props.isOpen === true) {
            const { userList } = this.props
            const userListFilterd = collection.filter(userList, u => (u && !u.sysUserKey && u.email && u.name))

            this.setState({ userListFilterd, isLoading: false, valueName: this.props.sName, isNew: (this.props.sKey === null), error: (this.props.sName < 1) })
        }
    }

    constructor(props) {
        super(props);

        this.state = {
            userListFilterd: [],
            isLoading: false,
            isNew: true,
            valueName: this.props.sName,
            error: true
        };
    }
    onCloseModal = () => {
        this.props.onCloseModal(modalKey)
    }

    onClickDelete = () => {
        this.setState({ isLoading: true })
        // doShit.then(() => {
        //     this.setState({ isLoading: false })
        //     this.onCloseModal()
        // })

    }

    onChangeText = (e) => {
        this.setState({ valueName: e.target.value, error: (e.target.value.length < 1) });
    }
    onClickSave = () => {

        if (this.props.currentUser && this.props.currentUser.email === 'demo@timeto.work') {
            toastr.success('Demo account', `No saves are made`);
            console.log('Demo account = exit')
            this.onCloseModal()
            return
        }

        this.setState({ isLoading: true })
        var uList = this.state.userListFilterd.filter(x => !x.dontSend)
        //  console.log('uList', uList)
        FireHelper.setInviteMailBulk(this.props.project.key, uList, this.props.project.publicKey, this.props.project.name).then(() => {
            this.setState({ isLoading: false })
        })

        this.props.onCloseModal(modalKey)
    }

    toggle = (index) => () => {
        var userListFilterd = this.state.userListFilterd
        var user = { ...userListFilterd[index] }

        if (user.dontSend !== true)
            user.dontSend = true
        else
            delete user.dontSend


        userListFilterd[index] = user
        this.setState({ userListFilterd })
    }

    listUser = (userObj, index) => {

        if (!userObj)
            return (null)

        var user = userObj

        return (
            <div key={user.key} onClick={this.toggle(index)} style={{ cursor: 'pointer', display: 'flex', paddingTop: '4px', borderBottom: 'dotted 1px var(--color-border)' }} className='noSelect'>
                <Image floated="right" size="mini" style={{ width: '35px', height: '35px', marginRight: '10px', }} src={user.image ?
                    getUserImage(this.props.projectKey, user.key, user.image)
                    : noUserImage} circular
                    onError={(e) => { e.target.onerror = null; e.target.src = noUserImage }}
                />


                <div style={{ flexGrow: '1' }}>{user.name}<br />{user.email}</div>
                <div  >
                    {user.dontSend !== true && <Icon name='check'></Icon>}
                </div>
            </div>
        )
    }
    render() {


        return (


            <ModalFrame name='sendinvites' className='modalAniSlideUp' dimmer='inverted' size='mini' open={this.props.isOpen} onClickClose={this.onCloseModal}>

                <ModalFrame.Content>
                    <div style={{ paddingTop: '14px', fontWeight: 'bold' }}>{window._getText('user.inviteUnMaped')}</div>
                    <div style={{ height: 'calc(100% - 80px)', overflow: 'auto', padding: '8px' }}>
                        {(!this.state.userListFilterd || this.state.userListFilterd.length === 0) && <div >{window._getText('user.allLinked')}</div>}
                        {this.state.userListFilterd.map((u, index) => this.listUser(u, index))}
                    </div>

                </ModalFrame.Content>
                <ModalFrame.Actions>

                    <div style={{ display: 'flex' }}>

                        <Button icon='send' style={{ marginLeft: 'auto', }} content={window._getText('send')} onClick={this.onClickSave} loading={this.state.isLoading} disabled={this.state.isLoading || this.state.error} />
                    </div>
                </ModalFrame.Actions>
            </ModalFrame>)

    }
}


const mapActions = {
    onCloseModal: closeModal,
};

const mapStateToProps = state => {
    var userList = {};

    if (state.firestore.data && state.firestore.data.users) {
        userList = state.firestore.data.users.userlist;
    }
    return {
        projectKey: state.firebase.profile.projectKey,
        project: state.firestore.data.project,
        userList,
        isOpen: state.modal.modals[modalKey].open || false,
        data: state.modal.modals[modalKey].data || {},
        currentUser: state.firebase.profile,

    };
};
export default connect(
    mapStateToProps,
    mapActions
)(UserSendInvitesModal);



