import React, { useState, useEffect } from 'react'
import { isMobile, isElectron, isBrowser } from 'react-device-detect';
import ReactDOM from 'react-dom'

let electronMenu
let ipcRenderer
let electronRemote
let shell




if (isElectron) {
    ipcRenderer = window.require('electron').ipcRenderer
    shell = window.require('electron').shell;
    electronRemote = window.require('electron').remote;
    electronMenu = electronRemote.Menu;

}

export const OpenContextMenu = (config) => {
    if (isElectron && config.length > 0) {
        electronMenu.buildFromTemplate(config).popup(electronRemote.getCurrentWindow());
    }

}

const doClick = (clickEvent, closeMenu) => (e) => {
    if (clickEvent)
        clickEvent()

    closeMenu()

}


const doWebMenu = (config, doClick, pos = { x: -1000, y: -1000 }, closeMenu) => {

    return (
        (
            <div id='context-menu' style={{ left: pos.x, top: pos.y }}   >
                {config.map((item, index) => {
                    if (item.type === 'separator')
                        return (<hr key={'key_' + index} style={{ borderTop: ' 1px solid rgba(255,255,255,0.8)' }} />)

                    return (
                        <div className='contextMenu' key={'key_' + index} onClick={doClick(item.click, closeMenu)}
                            style={{ color: !item.enabled ? 'silver' : '#000', paddingLeft: !item.click ? 0 : '20px', paddingRight: !item.click ? 0 : '20px', borderBottom: 'solid 0px silver', fontWeight: 500, fontSize: 16 }}
                        >
                            <span className='contextMenu' style={{ whiteSpace: 'nowrap', paddingLeft: item.click ? 0 : '20px', paddingRight: item.click ? 0 : '20px', fontFamily: 'sans-serif' }}>{item.label}</span>
                        </div>
                    )
                })}
            </div>
        )
    )
}



export default function WebContextMenu({ config, open, onClose }) {


    const [pos, setPos] = useState({ x: -1000, y: -1000 });
    const closeMenu = (e) => {

        if (e) {
            var target = e.target
            if (target === undefined) return
            if (target.className === undefined) return
            if (target.className.indexOf === undefined) return


            if (target.tagName === 'path')
                do {
                    target = target.parentNode

                } while (target.tagName.toLowerCase() === 'path' || target.tagName.toLowerCase() === 'g' || target.tagName.toLowerCase() === 'svg');

            if (target.className.indexOf('contextMenu') > -1) {
                return
            }
        }
        if (e) {
            e.stopImmediatePropagation();
            e.stopPropagation()
        }

        setPos({ x: -1000, y: -1000 })

        if (onClose)
            onClose()

        return true
    }
    const setMousePos = (e) => {
        setPos({ x: e.clientX, y: e.clientY })
        document.body.removeEventListener('mouseup', setMousePos);
    }

    useEffect(() => {
        if (open && !isElectron) {
            document.body.addEventListener("click", closeMenu)
            document.body.addEventListener('mouseup', setMousePos);
            return () => {
                document.body.removeEventListener("click", closeMenu)
                document.body.removeEventListener('mouseup', setMousePos);
            }
        }
    }, [open])




    if (isBrowser && !isElectron && open)
        return ReactDOM.createPortal(doWebMenu(config, doClick, pos, closeMenu), document.body)



    if (isElectron)
        OpenContextMenu(config)

    return null
}


