import React, { Component } from 'react'

import PropTypes from 'prop-types'


class Locked extends Component {
  static contextTypes = { router: PropTypes.object }
  onClickBilling = () => {
    window.dispatchEvent(new CustomEvent('preferences', { detail: { path: 'billing' } }));

  }
  render() {
    return (
      <div style={{ padding: '20px' }} >
        <h2>{window._getText('locked.title')}</h2>
        <p>
          {window._getText('locked.txt1')}<br />
          {window._getText('locked.txt2')}<br />
          {window._getText('billing.msgLink')}
          {/* <b style={{ cursor: 'pointer' }} onClick={() => { this.props.history.push('/schedule/settings') }}>{window._getText('locked.link')}</b> */}
        </p>
        <h2 onClick={this.onClickBilling}>Click here to goto billing.</h2>

      </div>
    )
  }
}


export default (Locked)