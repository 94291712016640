import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Form from 'semantic-ui-react/dist/commonjs/collections/Form'
import { Button } from '../../../common/component/Button'
import Dropdown from 'semantic-ui-react/dist/commonjs/modules/Dropdown'
import { toastr } from 'react-redux-toastr'

import { Field, reduxForm, change } from "redux-form";
import FirestoreListnerHelper from '../../../../core/util/FirestoreListnerHelper'
import { FireHelper } from '../../../../core/util/FireHelper'
import TimePicker from '../../../common/form/TimePicker'
import { getUserImage, getDateKey, getWeekObject } from '../../../../core/util/helpers'
import noUserImage from '../../../../img/noimg.png'

import moment from 'moment';



const modalKey = 'shiftadminrequest'

const dType = 'adminrequest'
export class ctrlAskForChangeAdmin extends Component {
    //static contextTypes = { store: PropTypes.object.isRequired }
    state = { comment: '', activeIndex: 3 }
    timerTime = null
    swapShifts = []
    componentDidMount = () => {

    }
    componentWillUnmount = () => {

    }
    componentDidUpdate = (prevProps, prevState, snapshot) => {
        if (prevProps.doSave === false && this.props.doSave === true) {
            this.props.handleSubmit(this.onSubmit)()
            this.props.resetSave()
        }
    }


    saveData = (values) => {

    }
    onSubmit = values => {


        //console.log('onSubmit values = ', values)

        //console.log('xx')
        //console.log('this.props.renderDateKey', this.props.renderDateKey)
        //console.log('xx')
        var isForAdmin = true;
        const { projectKey, data } = this.props
        const uKey = this.props.currentUser.inSysUserKey

        var dataIn = { t1: values.start, t2: values.stop, sh: { ...data.shift }, ct: this.state.activeIndex }

        if (dataIn.sh.k)
            delete dataIn.sh.k

        if (values.comment)
            dataIn.com = values.comment





        dataIn.sh.newU = this.state.ddlSelectedUser;
        isForAdmin = false;// this is request for user to users 


        if (dataIn.sh.newU)
            FireHelper.setShiftMetaData(projectKey, uKey, data.sKey, this.props.renderDateKey, data.shift.k, dType, dataIn, isForAdmin).then(r => {
                if (r && r.success === true)
                    toastr.success(window._getText('RequestIsSent'), '');

            })




        this.setState({ ddlSelectedUser: '', ddlSelectedShift: '' })
        this.props.onClose()

    }


    scrolling = (e, c) => {
        e.scrollIntoView();
        if (c < 5) setTimeout(this.scrolling, 300, e, c + 1);
    }
    ensureVisible = (e) => {
        // setTimeout(this.scrolling, 300, e, 0);
    };

    handleClick = (e, titleProps) => {
        const { index } = titleProps
        const { activeIndex } = this.state
        const newIndex = activeIndex === index ? -1 : index
        let title = this.state.title
        switch (newIndex) {
            case -1:
                title = ''
                break;
            case 1:
                title = 'Actual worked time'
                break;
            case 2:
                title = 'Ask for a change'
                break;
            case 3:
                title = 'Add acomment'
                break;
            case 4:
                title = 'Mark as sick!'
                break;
            default:
        }

        this.props.setSubIndex(newIndex)
        this.setState({ activeIndex: newIndex, title })
    }
    handleDropdownChange = (e, { name, value }) => {
        console.log('name', name)
        console.log('value', value)

        this.setState({ [name]: value })
        console.log('this.state', this.state)
        console.log('',)
    }
    handleDropdownWeekChange = (e, { name, value }) => {
        // get this week!
        //console.log([name], value)

        FirestoreListnerHelper.setWeekViewListner(this.context, value)


        this.setState({ [name]: value })
    }

    getUserList = () => {
        //console.log('>>>getUserList>>')
        const cache = this.props.project.cache || {}
        const projectKey = this.props.projectKey
        var userList = Object.keys(cache).map(objKey => {
            const obj = cache[objKey]
            if (obj && obj.sk && obj.n && obj.a === true) //this is a user 
                return ({
                    key:objKey,
                    text: obj.n,
                    value: objKey,
                    image: obj.i ? getUserImage(projectKey, objKey, obj.i) : noUserImage
                })


            return null
        })

        userList = userList.filter(x => x)
        userList = userList.sort((a, b) => {
            if (!a.text || !b.text)
                return 0

            const aName = (a.text || '').toLowerCase()
            const bName = (b.text || '').toLowerCase()

            if (aName < bName) return -1
            if (aName > bName) return 1
            return 0
        })
        //console.log('userList', userList)
        return userList
    }
    imgError = (e) => { e.target.onerror = null; e.target.src = noUserImage }

    addRemoveUser = (value) => (e) => {


        var arr = this.state.ddlSelectedUser || []
        var index = arr.indexOf(value)



        if (index === -1)
            arr.push(value)
        else
            arr.splice(index, 1)



        this.setState({ ddlSelectedUser: arr })

    }
    renderInnerContent = () => {
        //console.log('this.state.activeIndex', this.state.activeIndex)
        var userList
        switch (this.state.activeIndex) {
            case -1:
                return (
                    <div>
                        <Button compact className='btnMobile' index={1} onClick={this.handleClick} color='teal' icon='calendar times' content={window._getText('shiftuserinput.btn5')} style={{ width: '100%', margin: '5px' }}></Button>
                        <Button compact className='btnMobile' index={2} onClick={this.handleClick} color='teal' icon='time' content={window._getText('shiftuserinput.btn6')} style={{ width: '100%', margin: '5px' }}></Button>
                        <Button compact className='btnMobile' index={3} onClick={this.handleClick} color='teal' icon='long arrow alternate right' content={window._getText('shiftuserinput.btn7')} style={{ width: '100%', margin: '5px' }}></Button>
                        <Button compact className='btnMobile' index={4} onClick={this.handleClick} color='teal' icon='exchange' content={window._getText('shiftuserinput.btn8')} style={{ width: '100%', margin: '5px' }}></Button>
                    </div>
                )
            case 1:
                return (
                    <div>
                        <b>{window._getText('shiftuserinput.cancelation')}</b>


                    </div>
                )
            case 2:
                return (
                    <div>
                        <Form.Group style={{ width: '100%', marginTop: '10px' }}  >
                            <Form.Field >

                                <Field
                                    AmPm={this.props.AmPm}
                                    onChangeTime={(value) => this.props.dispatch(change('CtrlAskForChange', 'start', value))}
                                    name="start"
                                    component={TimePicker}
                                    type="text"
                                    placeholder="00:00"
                                />
                            </Form.Field>
                            <Form.Field >
                                <Field
                                    AmPm={this.props.AmPm}
                                    onChangeTime={(value) => this.props.dispatch(change('CtrlAskForChange', 'stop', value))}
                                    name="stop"
                                    component={TimePicker}
                                    type="text"
                                    placeholder="00:00"
                                />
                            </Form.Field>
                        </Form.Group>
                    </div>
                )
            case 3:

                userList = this.getUserList()
                var stateSelectedUser = this.state.ddlSelectedUser || []
                return (
                    <div>

                        <div style={{ minHeight: '300px' }}>
                            {/* TODO :language */}
                            <b style={{ marginLeft: 8, marginRight: 10 }}> Users </b>  <span style={{ color: 'silver' }}> (user need to be invited and active to show up here)</span>
                            <div className='' style={{ border: 'solid 0px silver', width: '100%', minHeight: '150px', maxHeight: '300px', overflowY: 'auto', overflowX: 'hidden', paddingTop: 4, paddingBottom: 4, marginTop: 4, marginBottom: 10 }}>
                                {
                                    userList.map((user) => {
                                        return (
                                            <div key={user.key} draggable={false} className='divUsername' onClick={this.addRemoveUser(user.value)}>
                                                <div draggable={false} alt={user.text} title={user.text} className='schedualUsername2'>

                                                    <img draggable={false} style={{ border: stateSelectedUser.indexOf(user.value) > -1 ? 'solid 3px green' : 'solid 3px silver' }} draggable={false} src={user.image} onError={this.imgError} />
                                                    <div draggable={false} >{user.text} </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>


                            {/* <Dropdown name='ddlSelectedUser' multiple placeholder='Select users' fluid scrolling selection options={userList} onChange={this.handleDropdownChange} /><br /> */}
                        </div>
                        <b>{window._getText('shiftuserinput.addCommentToUser')}</b>
                    </div >
                )
            case 4:


                userList = this.getUserList()
                var weekList = []

                var today = moment()
                //console.log('today', getWeekObject(today.format('YYYY-MM-DD'), 0).firstday.format('YYYY-MM-DD'))

                //cachethis ? 
                for (let index = 0; index < 30; index++) {
                    var d = today.clone().add((index * 7), 'days')
                    var week = getWeekObject(d.format('YYYY-MM-DD'), 1)
                    var weekEnd = week.firstday.clone().add(6, 'days')
                    //console.log(`Week ${week.firstday.format('WW')} ${week.firstday.format('MM-DD')} - ${weekEnd.format('MM-DD')}`)
                    //console.log(week.renderDateKey)
                    weekList.push({ text: `${window._getText('week')} ${week.firstday.format('ww')} (${week.firstday.format('DD MMM')} - ${weekEnd.format('DD MMM')})`, value: week.firstday.format('YYYY-MM-DD') })
                }
                // console.log('------------------')
                // console.log('renderDateKey', this.props.viewstate.renderDate.format('YYYY-MM-DD'))
                const cache = this.props.project.cache || {}
                const ddlSelectedWeek = this.state.ddlSelectedWeek
                const date = moment(ddlSelectedWeek)
                const ddlSelectedUser = this.state.ddlSelectedUser
                const dkey = getDateKey(ddlSelectedWeek)
                const loaded = this.props.fireLoaded[`weekview_${dkey}`]
                const scheduleWeekData = this.props.fireData['weekview_' + dkey] || {}
                this.swapShifts = []
                var shifts = []
                if (loaded === true && scheduleWeekData) {
                    //  console.log(scheduleWeekData)
                    Object.keys(scheduleWeekData).forEach(key => {
                        var schedual = scheduleWeekData[key]
                        if (schedual) {
                            const userRowData = schedual.rows.find(x => x.k === ddlSelectedUser)
                            if (userRowData) {
                                // console.log('userRowData', JSON.stringify(userRowData.s))
                                for (let index = 1; index < 7; index++) {
                                    if (userRowData.s[index]) {
                                        //console.log('userRowData.s[' + index + ']', userRowData.s[index])
                                        var arr = userRowData.s[index] || []
                                        for (let i = 0; i < arr.length; i++) {
                                            const s = arr[i];
                                            const _date = date.clone().add(index, 'days').format('ddd')
                                            const _name = (cache[key] || {}).n
                                            shifts.push({ text: `${_date} (${s.l}) @ ${_name} `, value: s.k })
                                            this.swapShifts.push({ ...s, dkey, schKey: key })

                                        }
                                    }
                                }
                                //if (userRowData.s)


                            }

                        }
                        return null
                    })


                }

                //console.log('shifts', this.swapShifts)



                return (
                    <div>
                        <b>{window._getText('shiftuserinput.swapTitle1')}</b>
                        <Dropdown name='ddlSelectedUser' placeholder={window._getText('shiftuserinput.swapTitle1')} fluid scrolling selection options={userList} onChange={this.handleDropdownChange} />
                        <b>{window._getText('shiftuserinput.swapTitle2')}</b><br />
                        <Dropdown name='ddlSelectedWeek' placeholder={window._getText('shiftuserinput.swapTitle2')} fluid scrolling selection options={weekList} onChange={this.handleDropdownWeekChange} />
                        <b>{window._getText('shiftuserinput.swapTitle3')}</b><br />
                        {(loaded === true) &&
                            <Dropdown name='ddlSelectedShift' placeholder={window._getText('shiftuserinput.swapTitle3')} fluid scrolling selection options={shifts} onChange={this.handleDropdownChange} />
                        }

                        {/* {(loaded !== true) && <div style={{ textAlign: 'center', marginTop: '50px' }}><Loader inline active>{window._getText('loading')}</Loader></div>} */}




                        <br />
                        <b>{window._getText('shiftuserinput.addCommentToUser')}</b>
                    </div >
                )
            default:
        }
    }

    render() {



        return (
            <Form ref="form" style={{ marginBottom: 10 }} >

                {this.renderInnerContent()}

                {this.state.activeIndex > -1 &&
                    <Field

                        // onFocus={this.ensureVisible}
                        autoComplete="off"
                        name="comment"
                        component={'textarea'}
                        type="text"
                        placeholder={window._getText('shiftuserinput.comment')}
                        rows={2}
                        maxLength={500}
                        style={{ width: '100%', resize: 'none' }}
                    />

                }


            </Form>
        )
    }
}

const mapStateToProps = state => {
    const data = state.modal.modals[modalKey].data || {}



    //const label = data.l // TODO we need to ship the dates if they exists 
    let start = data.shift ? data.shift.t1 : '08:00' // TODO if wee have a date get it here 
    let stop = data.shift ? data.shift.t2 : '16:00' // TODO if wee have a date get it here 
    let comment = ''

    if (data.meta && data.meta[dType]) {
        start = data.meta[dType].t1
        stop = data.meta[dType].t2
        comment = data.meta[dType].com || ''
    }

    return {
        data,
        project: state.firestore.data.project,
        renderDateKey: state.viewstate.renderDateKey,
        projectKey: state.firebase.profile.projectKey,
        currentUser: state.firebase.profile,
        enableReinitialize: true,
        initialValues: { start, stop, comment: comment },
        formState: state.form.CtrlAskForChange,
        viewstate: state.viewstate,

        fireData: state.firestore.data,
        fireLoaded: state.firestore.status.requested,


    }
}

const mapDispatchToProps = {

}


const styles = {


}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({ form: "CtrlAskForChange" })(ctrlAskForChangeAdmin))


