import captureException from "sen-obj";
var remote = window.require('electron').remote;
var fs = remote.require('fs');
const { inAppPurchase } = remote


// TODO:
// FIX LOGING°°°°°!!!!

//const PRODUCT_IDS = ['mac1credit', 'mac5credit', 'mac10credit', 'mac20credit', 'mac40credit', 'mac80credit', 'mac100credit', 'mac150credit']


const validatPurches = (payment, transaction) => {
    console.log('validatPurches', `${payment.productIdentifier} purchased.`)

    // Get the receipt url.
    var receiptURL = inAppPurchase.getReceiptURL()

    //console.log(`Receipt URL: ${receiptURL}`)

    var iapUrl = `https://${window._iapUrl}/iapVerify?p=${window._projectKey}` //
    //FIXME:
    //iapUrl = `http://localhost:50055/simpleschedule-9ae21/us-central1/iapVerify/iapVerify?p=${window._projectKey}`

    //console.log('iapUrl', iapUrl)
    // get receipt data from file
    var receiptRaw = fs.readFileSync(receiptURL)

    // base64 encode the receipt data
    var receiptEncoded = receiptRaw.toString('base64')
    window.dispatchEvent(new CustomEvent("onStoreUpdate", { detail: 'waiting_for_validation' }));
    var postObj = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            id: payment.productIdentifier,
            transaction: {
                extratype: 'mac',
                transactionIdentifier: transaction.transactionIdentifier,
                type: 'apple',
                appStoreReceipt: receiptEncoded
            }
        })
    }

    fetch(iapUrl, postObj).then(res => {
        console.log('res.status', res.status)


        if (res.status === 200) {
            // inAppPurchase.finishTransactionByDate(transaction.transactionDate)
            window.dispatchEvent(new CustomEvent("onStoreUpdate", { detail: 'success' }));
        }
        else
            window.dispatchEvent(new CustomEvent("onStoreUpdate", { detail: 'faild' }));

    }).catch(err => captureException(err))

}

try {
    console.log('inAppPurchase.canMakePayments()', inAppPurchase.canMakePayments())
    //if (inAppPurchase.canMakePayments() === true) {
    inAppPurchase.on('transactions-updated', (event, transactions) => {
        console.log('',)
        console.log('transactions-updated-------->')
        if (!Array.isArray(transactions)) {
            return
        }

        // console.log('transactions', transactions)
        // console.log('transactions.length', transactions.length)

        // // inAppPurchase.finishAllTransactions()

        // Check each transaction.
        transactions.forEach(function (transaction) {
            var payment = transaction.payment
            console.log('payment', payment)
            console.log('transaction.transactionState', transaction.transactionState)
            console.log('transaction - >', transaction)
            window.dispatchEvent(new CustomEvent("onStoreUpdate", { detail: transaction.transactionState }));

            switch (transaction.transactionState) {
                case 'purchasing':
                    console.log(`Purchasing ${payment.productIdentifier}...`)
                    break
                case 'purchased':
                    inAppPurchase.finishAllTransactions()
                    validatPurches(payment, transaction)

                    break
                case 'failed':
                    console.log(`Failed to purchase ${payment.productIdentifier}.`)
                    inAppPurchase.finishAllTransactions()

                    break
                case 'restored':
                    console.log('!!!!!!!!!! restored !!!!!!!!!!')
                    inAppPurchase.finishAllTransactions()


                    break
                case 'deferred':
                    console.log(`The purchase of ${payment.productIdentifier} has been deferred.`)
                    break
                default:
                    break
            }
        })
    })

    window.isStoreInit = true


    // } else {
    //     console.log('#####The user is not allowed to make in-app purchase.#######')
    // }

} catch (error) {
    console.log('error----->', error)
    captureException(error);

}
export default inAppPurchase