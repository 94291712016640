import React, { Component } from 'react'
import { connect } from 'react-redux'
//import Form from 'semantic-ui-react/dist/commonjs/collections/Form'
import { Field, reduxForm, change } from "redux-form";
import moment from 'moment'
import { FireHelper } from '../../../../core/util/FireHelper'



const dType = 'comment'
export class CtrlAddComment extends Component {
    state = { comment: '' }
    timerTime = null
    componentDidMount = () => {
    }
    componentWillUnmount = () => {

    }
    componentDidUpdate = (prevProps, prevState, snapshot) => {
        if (prevProps.doSave === false && this.props.doSave === true) {
            this.props.handleSubmit(this.onSubmit)()
            this.props.resetSave()
        }
    }

    saveData = (values) => {

    }
    onSubmit = values => {
        const { projectKey, data, project } = this.props
        const uKey = this.props.currentUser.inSysUserKey
        const settings = (project || {}).s || { com: false }


        var dataIn = { com: values.comment, sh: { ...data.shift } }
        if (dataIn.sh.k)
            delete dataIn.sh.k


        if (dataIn.com) {
            if (settings.com === true) {
                FireHelper.setShiftMetaData(projectKey, uKey, data.sKey, this.props.renderDateKey, data.shift.k, dType, dataIn, true, { userCom: dataIn.com || '' })
            }
            else {
                FireHelper.setShiftMetaData(projectKey, uKey, data.sKey, this.props.renderDateKey, data.shift.k, dType, dataIn, true)
            }
        }


        this.props.onClose()

    };
    setNow = (e, props) => {
        const { name } = props
        const time = moment.utc().format('HH:mm')
        this.props.dispatch(change('ctrlTimeDeviation', name, time));
    }

    scrolling = (e, c) => {
        e.scrollIntoView();
        if (c < 5) setTimeout(this.scrolling, 300, e, c + 1);
    }
    ensureVisible = (e) => {
        setTimeout(this.scrolling, 300, e, 0);
    };

    render() {
        console.log('this.props.doSave', this.props.doSave)
        return (

            <Field
                autoComplete="new-password"
                name="comment"
                component={'textarea'}
                type="text"
                placeholder={window._getText('shiftuserinput.comment')}
                rows={2}
                maxLength={500}
                style={{ width: '100%', height: '100%' }}
            />


        )
    }
}

const mapStateToProps = state => {
    const data = state.modal.modals['shiftuserinput'].data

    let comment = ''


    if (data.meta && data.meta[dType]) {


        comment = data.meta[dType].com || ''
    }

    return {
        data,
        renderDateKey: state.viewstate.renderDateKey,
        projectKey: state.firebase.profile.projectKey,
        project: state.firestore.data.project,
        currentUser: state.firebase.profile,
        enableReinitialize: true,
        initialValues: { comment: comment },
        formState: state.form.CtrlAddComment
    }
}

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({ form: "CtrlAddComment" })(CtrlAddComment))


