import React, { Component } from "react";
import { Icon, Image, Loader, Button } from "../../../common/component/Button";
import { connect } from 'react-redux'
//import FirestoreListnerHelper from '../../common/util/FirestoreListnerHelper'
import { FireHelper } from '../../../../core/util/FireHelper'
import PropTypes from 'prop-types'
import moment from 'moment'
import ShiftContainer from './ShiftContainer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { toastr } from 'react-redux-toastr'
import {

    faAngleDoubleUp as icoArrowUp,
} from '@fortawesome/pro-duotone-svg-icons';
import { isElectron } from 'react-device-detect';

//import Arr from 'lodash/array'

let ipcRenderer
if (isElectron) {
    ipcRenderer = window.require('electron').ipcRenderer
}

class RequestsView extends Component {
    //static contextTypes = { store: PropTypes.object.isRequired }
    state = { view: 0 }
    componentDidUpdate = (prevProps, prevState, snapshot) => {

    }


    //TODO MOVE THIS TO HELPER AND MAKE SURE WE REUSE IT !
    temp = (date, t1, t2, brake) => {

        const dateStr = moment.utc(date).format('YYYY-MM-DD')
        var startDate = moment.utc(`${dateStr} ${t1}`)
        var stopDate = moment.utc(`${dateStr} ${t2}`)

        if (startDate > stopDate)
            stopDate.add(1, 'days')


        var duration = moment.duration(stopDate.diff(startDate));
        var minutes = duration.asMinutes();

        //small hack , here we set the start and stop date to save 
        var r = {}
        r.startDate = startDate.utc().unix() * 1000
        r.stopDate = stopDate.utc().unix() * 1000
        r.timeStart = t1
        r.timeEnd = t2
        r.minutes = minutes - brake
        r.break = brake

        return r

    }

    //TODO MOVE THIS TO HELPER AND MAKE SURE WE REUSE IT !
    onClickOpenShift = (s_doc) => (e) => {

        //console.log('s_doc', s_doc)

        var shiftKey = s_doc.shiftKey
        var schedualKey = s_doc.schedualKey
        var dateStr = s_doc.data.sh.d
        var target = e.target
        var isRoot = false
        var shiftWidth = 500
        if (!target) {
            target = document.getElementById('root')
            isRoot = true
        }
        var { x, y, width, height, top, left, right } = target.getBoundingClientRect()
        x = left
        y = top
        if (isRoot === true) {
            x = width * .5 + (shiftWidth * .5)
            left = width * .5 + (shiftWidth * .5)
        }

        if (ipcRenderer)
            ipcRenderer.send('renderWindowTargetPos', { x, y, width, height, top, left, right, showArrow: !isRoot })

        this.props.openEventModal({ doLoad: { key: shiftKey, shedualKey: schedualKey, dateStr: dateStr } })
        e.stopPropagation()
    }


    approveChangeShift = (shiftKey, docKey, schedualKey, id, shiftOwnerKey, newData, n, currentUser, msg) => {
        FireHelper.approveChangeShift(shiftKey, docKey, schedualKey, id, shiftOwnerKey, newData, n, currentUser, msg).then(response => {
            if (response.success === false && response.msg === 'SHIFT_IS_MISSING') {
                alert('The shift is no longer in the workview.\nNo changes are made there.\n\nIf the shift still exists is in the published view it will be updated.')
            }
        })
    }

    onApproveChange = (doc, shiftOwnerKey, shiftChanged) => (e) => {

        //console.log('onApproveChange')

        if (this.props.currentUser && this.props.currentUser.email === 'demo@timeto.work') {
            toastr.success('Demo account', `No saves are made`);
            //console.log('Demo account = exit')
            return
        }

        const { shiftKey, docKey, schedualKey, id, n } = doc
        const { settings } = this.props
        var msg = 'Admin Approved the ' + doc.type + ' request for shift ' + doc.data.sh.d + ' > ' + doc.data.sh.l + ' @ ' + (((this.props.project.cache[doc.schedualKey] || {})).n || '')
        // console.log('>>n><', n)
        if (n === 'deviation') {
            let newData = { uid: doc.uk, ...this.temp(shiftChanged.d, shiftChanged.t1, shiftChanged.t2, shiftChanged.b || 0) }
            console.log('{deviation}', { shiftKey, docKey, schedualKey, id, shiftOwnerKey, newData, n, currentUser: this.props.currentUser, msg })
            this.approveChangeShift(shiftKey, docKey, schedualKey, id, shiftOwnerKey, newData, n, this.props.currentUser, msg)

        }
        else if (n === 'userrequestshift') {
            let newData = { uid: doc.uk }
            this.approveChangeShift(shiftKey, docKey, schedualKey, id, shiftOwnerKey, newData, n, this.props.currentUser, msg)


        }
        else if (n === 'adminrequest') {

            if (this.props.currentUser.role > 100 && doc.uk === this.props.currentUser.inSysUserKey)
                console.log('this is a asmin aprove shift change .... we need to get the current row user key')

            //console.log('doc.data.sh', doc.data.sh)

            if (doc.data.sh.newU[0]) {
                let newData = { uid: this.props.currentUser.inSysUserKey }
                this.approveChangeShift(shiftKey, docKey, schedualKey, id, shiftOwnerKey, newData, n, this.props.currentUser, msg)
            }




        }
        else if (n === 'change') {


            if (doc.data.ct === 1) // its a cancelations
            {

                FireHelper.removeShift(shiftKey, docKey, schedualKey, id, shiftOwnerKey, n, this.props.currentUser, msg)


            } else if (doc.data.ct === 2) // its a time change
            {
                let newData = { uid: doc.uk, ...this.temp(shiftChanged.d, shiftChanged.t1, shiftChanged.t2, shiftChanged.b || 0) }

                this.approveChangeShift(shiftKey, docKey, schedualKey, id, shiftOwnerKey, newData, n, this.props.currentUser, msg)

            } else if (doc.data.ct === 3) // its a user shift change
            {
                //var newUserKey = doc.data.sh.newU[0] || '' //get first user from the array 

                if (!doc.adm) {
                    //this is NOT an Admin , so we sett current user here !
                    //let newData = { uid: this.props.currentUser.inSysUserKey }

                    //update the ChangeShift to ready fro admin aporval 
                    if (settings.sMov === true) {
                        if (doc.data.sh.newU[0]) {
                            //console.log('Aproved by user')
                            let newData = { uid: this.props.currentUser.inSysUserKey }

                            this.approveChangeShift(shiftKey, docKey, schedualKey, id, shiftOwnerKey, newData, n, this.props.currentUser, msg)

                        }
                    }
                    else {
                        FireHelper.setToAdminRequest(docKey, schedualKey, shiftKey, n, this.props.currentUser)
                    }

                }
                else {
                    //admin 
                    if (doc.data.sh.newU[0]) {
                        let newData = { uid: doc.data.sh.newU[0] }

                        this.approveChangeShift(shiftKey, docKey, schedualKey, id, shiftOwnerKey, newData, n, this.props.currentUser, msg)

                    }
                }



            } else if (doc.data.ct === 4)// its a user shift swap
            {
                if (!doc.adm) {
                    if (settings.sSwp === true) {

                        //console.log('Aproved by user')

                        var uid = doc.uk
                        var uid2 = doc.data.sh.newU[0]// this.props.currentUser.inSysUserKey
                        var docKey2 = doc.data.sh2.dkey
                        var shiftKey2 = doc.data.sh2.k
                        var shiftOwnerKey2 = (this.props.project.cache[uid2] || {}).sk || ''
                        var schedualKey2 = doc.data.sh2.schKey
                        //shiftKey, shiftKey2, docKey, docKey2, scheduleKey, shiftOwnerKey, shiftOwnerKey2, uid, uid2, rType, profile, msg
                        FireHelper.approveSwapShift(shiftKey, shiftKey2, docKey, docKey2, schedualKey, schedualKey2, shiftOwnerKey, shiftOwnerKey2, uid, uid2, n, this.props.currentUser, msg)



                    }
                    else {

                        FireHelper.setToAdminRequest(docKey, schedualKey, shiftKey, n, this.props.currentUser)

                    }
                }
                else {
                    //admin 
                    if (doc.data.sh.newU[0]) {


                        var uid = doc.uk
                        var uid2 = doc.data.sh.newU[0]
                        var docKey2 = doc.data.sh2.dkey
                        var shiftKey2 = doc.data.sh2.k
                        var shiftOwnerKey2 = (this.props.project.cache[uid2] || {}).sk || ''
                        var schedualKey2 = doc.data.sh2.schKey
                        //shiftKey, shiftKey2, docKey, docKey2, scheduleKey, shiftOwnerKey, shiftOwnerKey2, uid, uid2, rType, profile, msg
                        FireHelper.approveSwapShift(shiftKey, shiftKey2, docKey, docKey2, schedualKey, schedualKey2, shiftOwnerKey, shiftOwnerKey2, uid, uid2, n, this.props.currentUser, msg)


                    }
                }
            }


        } else if (n === 'comment') {



            var msg2 = 'Admin have read your comment for shift ' + doc.data.sh.d + '>' + doc.data.sh.l + ' @ ' + ((this.props.project.cache[doc.schedualKey] || {}).n || '')
            let newData = { uid: doc.uk, userCom: doc.data.com }
            this.approveChangeShift(shiftKey, docKey, schedualKey, id, shiftOwnerKey, newData, n, this.props.currentUser, msg2)



        } else if (n === 'sick') {

            let newData = { uid: doc.uk, sick: true, timeOf: doc.data.tof || 0 }

            this.approveChangeShift(shiftKey, docKey, schedualKey, id, shiftOwnerKey, newData, n, this.props.currentUser, msg)


        } else {
            //  //console.log('#missing type=> ', n)
        }




        this.setState({ s_schedualName: null, s_user: null, s_userChanged: null, s_shiftChanged: null, s_shiftOrginal: null, s_doc: null })
        if (this.props.onDone)
            this.props.onDone()//clear parent state
    }


    onDenyRequest = (doc, shiftOwnerKey, shiftChanged) => () => {
        if (this.props.currentUser && this.props.currentUser.email === 'demo@timeto.work') {
            toastr.success('Demo account', `No saves are made`);
            //console.log('Demo account = exit')
            return
        }

        const { shiftKey, docKey, schedualKey, id, n } = doc

        if (this.state.view === 1) {
            //   //console.log('>>is admin cancle!')
            var msg1 = 'Admin cancel the ' + doc.type + ' request for shift ' + doc.data.sh.d + '>' + doc.data.sh.l + ' @ ' + ((this.props.project.cache[doc.schedualKey] || {}).n || '')

            FireHelper.removeRequest(docKey, schedualKey, shiftKey, shiftOwnerKey, n, this.props.currentUser, msg1)

        }
        else {
            if (!doc.adm) {

                //this an admin cansel a request betwwne 2 users
                if (this.props.currentUser.role > 100) {
                    var msg = 'A manager cancel the ' + doc.type + ' request for shift ' + doc.data.sh.d + '>' + doc.data.sh.l + ' @ ' + ((this.props.project.cache[doc.schedualKey] || {}).n || '')

                    FireHelper.removeRequest(docKey, schedualKey, shiftKey, shiftOwnerKey, n, this.props.currentUser, msg)//todo fix the message DUBBLE CHECK THIS!

                }
                else
                    if (doc.data.ct === 3) // its a user shift change , remove this user from the parsipants 
                    {


                        const requestOwnerKey = (this.props.project.cache[doc.uk] || {}).sk || ''
                        FireHelper.removeParticipantRequest(docKey, schedualKey, shiftKey, n, requestOwnerKey, this.props.currentUser)

                    } else if (doc.data.ct === 4) {
                        var msg = 'You cancel the ' + doc.type + ' request for shift ' + doc.data.sh.d + '>' + doc.data.sh.l + ' @ ' + ((this.props.project.cache[doc.schedualKey] || {}).n || '')

                        FireHelper.removeRequest(docKey, schedualKey, shiftKey, shiftOwnerKey, n, this.props.currentUser, msg)//todo fix the message DUBBLE CHECK THIS!

                    }
            }
            else {

                var msg2 = 'Admin cancel the ' + doc.type + ' request for shift ' + doc.data.sh.d + '>' + doc.data.sh.l + ' @ ' + ((this.props.project.cache[doc.schedualKey] || {}).n || '')

                FireHelper.removeRequest(docKey, schedualKey, shiftKey, shiftOwnerKey, n, this.props.currentUser, msg2)

            }
        }
        //FireHelper.removeRequestDirect(reqDocKey, shiftOwnerKey, rType, this.props.currentUser)

        this.setState({ s_schedualName: null, s_user: null, s_userChanged: null, s_shiftChanged: null, s_shiftOrginal: null, s_doc: null })
        if (this.props.onDone)
            this.props.onDone()//clear parent state
    }


    getTitle = (doc) => {
        switch (doc.n) {
            case 'adminrequest':
                return window._getText('request.adminRequest')
            case 'deviation':
                return window._getText('request.titleTime')
            case 'sick':
                return window._getText('request.titleSick')
            case 'change':
                switch (doc.data.ct) {
                    case 1:
                        return window._getText('request.titleCancle')
                    case 2:
                        return window._getText('request.titleChange')
                    case 3:
                        return window._getText('request.titleMove')
                    case 4:
                        return window._getText('request.titleSwap')
                    default:
                        return ''
                }
            case 'userrequestshift':
                return window._getText('request.titleUserrequestshift')
            case 'comment':
                return window._getText('request.titleComment')
            default:
                return ''
        }

    }
    getIcon = (doc) => {
        return <FontAwesomeIcon icon={icoArrowUp} style={{ fontSize: '29px' }}></FontAwesomeIcon>


        // ////console.log('doc', doc.data.ct || '')
        // switch (doc.n) {
        //   case 'deviation':
        //     return <Icon name='clock' size='big'></Icon>
        //   case 'sick':
        //     return <Icon name='bed' size='big'></Icon>
        //   case 'change':
        //     switch (doc.data.ct) {
        //       case 1:
        //         return <Icon name='calendar times outline' size='big'></Icon>
        //       case 2:
        //         return <Icon name='clock outline' size='big'></Icon>
        //       case 3:
        //         return <Icon name='arrow right' size='big'></Icon>
        //       case 4:
        //         return <Icon name='exchange' size='big'></Icon>
        //       default:
        //         return <Icon name='arrow right' size='big'></Icon>
        //     }

        //   case 'comment':
        //     return <Icon name='arrow right' size='big'></Icon>
        //   default:
        //     return <Icon name='arrow right' size='big'></Icon>
        // }

    }
    setView = (view) => () => {
        this.setState({ view, s_schedualName: null, s_user: null, s_userChanged: null, s_shiftChanged: null, s_shiftOrginal: null, s_doc: null })
        if (this.props.onDone)
            this.props.onDone()//clear parent state
    }




    render() {
        var { s_schedualName, s_user, s_userChanged, s_shiftChanged, s_shiftOrginal, s_doc } = this.props.state

        if (!s_doc)
            return ''

        var isSender = this.props.currentUser.inSysUserKey === s_doc.uk




        return (
            <div style={{ flexGrow: '1', overflowY: 'auto', overflowX: 'hidden', height: '100%', }}>
                {s_schedualName &&
                    <React.Fragment>
                        {this.props.size !== 'small' &&
                            <div className='dragWindow2' style={{ paddingTop: '0px', paddingBottom: '12px', paddingLeft: '14px', paddingRight: '12px', minHeight: '50px', height: '50px', overflow: 'hidden', display: 'flex', flexDirection: 'row' }}>
                                <div style={{ flex: 1 }}>
                                    {/* {s_doc.adm === false && this.props.currentUser.role > 100 &&
                                        <span style={{ color: 'red' }}>
                                            User to user request. pending response form resiver
                                   </span>
                                    } */}
                                </div>
                                <div style={{ paddingTop: 10 }}>




                                    {this.state.view === 0 &&
                                        <Button tooltip_r={window._getText('tooltip.Deny')} style={{ marginRight: '10px' }} className="btn btn-default tour_requests_5" onClick={this.onDenyRequest(s_doc, s_user.sk, s_shiftChanged)} icon='x'></Button>
                                    }
                                    {this.state.view === 1 ?
                                        <Button tooltip_r={window._getText('tooltip.Cancel')} style={{ marginRight: '10px' }} className="btn btn-default tour_requests_5" onClick={this.onDenyRequest(s_doc, s_user.sk, s_shiftChanged)} icon='x'></Button>
                                        :
                                        <React.Fragment>
                                            {isSender === false &&
                                                <Button tooltip_r={window._getText('tooltip.Approve')} style={{}} className="btn btn-default tour_requests_4" onClick={this.onApproveChange(s_doc, s_user.sk, s_shiftChanged)} icon='check'></Button>
                                            }
                                        </React.Fragment>
                                    }


                                </div>

                            </div>
                        }

                        <div style={{ padding: '0px', paddingLeft: 10 }} >
                            <div style={{ flexGrow: '1', fontSize: this.props.size === 'small' ? '' : '20px', fontWeight: 'bold', paddingBottom: '5px', color: 'var(--color--text)' }}>
                                {this.getTitle(s_doc)}
                            </div>
                            <div className='tour_requests_3'>
                                <ShiftContainer size={this.props.size} orginal={false} view={this.state.view} schedualName={s_schedualName} user={s_userChanged} shiftOrginal={s_shiftChanged} ></ShiftContainer>
                            </div>

                            <div style={{ textAlign: 'center', paddingTop: 20 }}>
                                {this.getIcon(s_doc)}
                            </div>
                            <div className='tour_requests_2'>
                                <ShiftContainer size={this.props.size} orginal={true} view={this.state.view} schedualName={s_schedualName} user={s_user} shiftOrginal={s_shiftOrginal} ></ShiftContainer>
                            </div>
                            <div style={{ clear: 'both', fontWeight: 'bold' }}>
                                {s_doc.data.com && <div style={{ fontSize: '15px', }}><Icon name='comment outline' /> {s_doc.data.com}</div>}
                            </div>
                            {this.props.size === 'small' &&
                                <div style={{ marginTop: 50, overflow: 'hidden', display: 'flex', flexDirection: 'row', position: 'absolute', bottom: 70, right: 10 }}>
                                    <div style={{ flex: 1 }}>
                                        {/* {s_doc.adm === false && this.props.currentUser.role > 100 &&
                                            <span style={{ color: 'red' }}>
                                                User to user request. pending response form resiver
                                   </span>
                                        } */}
                                    </div>
                                    <div>



                                        {this.state.view === 0 &&
                                            <Button tooltip_t={window._getText('tooltip.Deny')} style={{ marginRight: '10px' }} className="btn btn-default tour_requests_5" onClick={this.onDenyRequest(s_doc, s_user.sk, s_shiftChanged)} icon='x'></Button>
                                        }
                                        {this.state.view === 1 ?
                                            <Button tooltip_t={window._getText('tooltip.Cancel')} style={{ marginRight: '10px' }} className="btn btn-default tour_requests_5" onClick={this.onDenyRequest(s_doc, s_user.sk, s_shiftChanged)} icon='x'></Button>
                                            :
                                            <React.Fragment>
                                                {isSender === false &&
                                                    <Button tooltip_t={window._getText('tooltip.Approve')} style={{}} className="btn btn-default tour_requests_4" onClick={this.onApproveChange(s_doc, s_user.sk, s_shiftChanged)} icon='check'></Button>
                                                }
                                            </React.Fragment>
                                        }


                                    </div>

                                </div>
                            }
                        </div>
                    </React.Fragment>
                }
            </div>


        )
    }
}



const mapActions = {


}
const mapStateToProps = (state, ownProps, ownState) => {

    return {
        project: state.firestore.data.project,
        currentUser: state.firebase.profile,
        AmPm: state.firestore.data.project.s ? state.firestore.data.project.s.AmPm || false : false,
        settings: state.firestore.data.project.s || {}

    };
};
export default connect(
    mapStateToProps,
    mapActions
)(RequestsView);


