import React, { Component } from "react";
import { Icon, Image, Loader, Button } from "../../common/component/Button";
import { connect } from 'react-redux'
//import FirestoreListnerHelper from '../../common/util/FirestoreListnerHelper'
import PropTypes from 'prop-types'
import { getUserImage, toTop, renderTimeLable, getLocalFromNow } from '../../../core/util/helpers'
import Locked from "../schedule/settings/Locked";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import RequestList from '../../common/component/Request/RequestList'
import RequestView from '../../common/component/Request/RequestView'
import { toastr } from 'react-redux-toastr'

import {
  faInbox as icoPageHeader,
} from '@fortawesome/pro-duotone-svg-icons';


import { isElectron } from 'react-device-detect';
let ipcRenderer
if (isElectron) {
  ipcRenderer = window.require('electron').ipcRenderer
}

class Requests extends Component {
  //static contextTypes = { store: PropTypes.object.isRequired }
  state = { requestClick: {}, view: 0, showLoader: false }

  componentDidMount = () => {

  }



  onRequestResponseDone = () => {
    toastr.success('Updating request...', 3)
    this.setState({ handledKey: this.state.s_doc.id, showLoader: true, s_schedualName: null, s_user: null, s_userChanged: null, s_shiftChanged: null, s_shiftOrginal: null, s_doc: null })
  }
  onListItemClick = (clickObj) => this.setState({ ...clickObj, handledKey: undefined, showLoader: false })

  hideLoader = () => { if (this.state.showLoader === true) this.setState({ showLoader: false }) }

  render() {


    if (this.props.project && this.props.project.disabled === true)
      return <Locked />

    if (this.props.isUnknown === true)
      return (

        <div className="ui message" style={{ margin: '10px' }}>
          <div className="header">{window._getText('usernotmapped_title')}</div>
          <p>{window._getText('usernotmapped_txt1')} <br />
            {window._getText('usernotmapped_txt2')}</p>
        </div>

      )


    const { project, currentUser } = this.props


    if (currentUser && currentUser.role < 100)
      return (<div>No access.</div>)

    if (!project)
      return (<div style={{ textAlign: 'center', marginTop: '50px' }}><Loader inline active>{window._getText('loading')}</Loader></div>)



    var { s_schedualName, s_user, s_userChanged, s_shiftChanged, s_shiftOrginal, s_doc } = this.state

    // const currentUserKey = this.props.currentUser.inSysUserKey
    return (

      <React.Fragment>

        <div style={{ display: 'flex', flexDirection: 'row', height: '100%' }}>
          <div style={{ maxWidth: '45%', flex: 1, minWidth: '350px', }}>
            <div className='dragWindow2' style={{ paddingTop: '12px', paddingBottom: '12px', paddingLeft: '14px', paddingRight: '5px', color: '#000', display: 'flex', fontSize: '12px', minHeight: '50px', height: '50px', overflow: 'hidden', }}>
              <h2 style={{ color: '#82D23A' }}><FontAwesomeIcon icon={icoPageHeader}></FontAwesomeIcon>  {window._getText('schedual.m.Request')}</h2>
              {/* {this.state.showLoader === true && <h1>Loading....</h1>} */}
            </div>

            <RequestList update={this.hideLoader} handledKey={this.state.handledKey} onClick={this.onListItemClick}></RequestList>
          </div >
          <RequestView size='large' handledKey={this.state.handledKey} onDone={this.onRequestResponseDone} updater={this.state} state={{ s_schedualName, s_user, s_userChanged, s_shiftChanged, s_shiftOrginal, s_doc }}></RequestView>
        </div >
      </React.Fragment>
    )
  }
}



const mapActions = {



}
const mapStateToProps = (state, ownProps, ownState) => {

  return {
    isUnknown: !state.firebase.profile.inSysUserKey,
    project: state.firestore.data.project,
    currentUser: state.firebase.profile,
    settings: state.firestore.data.project.s || {},
  };
};
export default connect(
  mapStateToProps,
  mapActions
)(Requests);


