import React, { Component } from 'react'
import { Button, Input } from "../../common/component/Button";
import { closeEditScheduleModal } from "../../../core/redux/action/modalAction";
import { removeSchedule, addSchedule } from "../../../core/redux/action/eventAction";
import ModalFrame from './ModalFrame'
import { connect } from "react-redux";
import { toastr } from 'react-redux-toastr'

import shortid from 'shortid'


export class EditScheduleModule extends Component {


    componentDidUpdate(prevProps, prevState, snapshot) {

        if (prevProps.isOpen === false && this.props.isOpen === true) {
            this.setState({ change: false, doDelete: false, isLoading: false, valueName: this.props.sName, isNew: (this.props.sKey === null), error: (this.props.sName < 1) })
        }
    }

    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            isNew: true,
            valueName: this.props.sName,
            error: true
        };
    }

    onClickDeleteEditSchedule = () => {
        if (this.props.currentUser.email === 'demo@timeto.work') {
            toastr.success('Demo account', `No saves are made`);
            console.log('Demo account = exit')
            return
        }

        const key = this.props.sKey
        this.setState({ isLoading: true })
        this.props.removeSchedule(key).then(() => {
            this.props.onClose()
            this.props.onRemove()
            //this.setState({ isLoading: false })

        })


    }

    onChangeDivierEditScheduleModal = (e) => {
        this.setState({ change: true, valueName: e.target.value, error: (e.target.value.length < 1) });
    }
    onClickSaveEditSchedule = () => {
        const name = this.state.valueName

        if (this.props.currentUser.email === 'demo@timeto.work') {
            toastr.success('Demo account', `No saves are made`);
            console.log('Demo account = exit')
            return
        }

        if (!name)
            return


        if (this.state.change === true) {

            let key = this.props.sKey || shortid.generate()
            if (key === '__empty')
                key = shortid.generate();


            const newScheduel = { name: name, value: key, key }

            this.setState({ isLoading: true, doDelete: false })
            this.props.addSchedule(newScheduel).then(() => {
                //TODO callback here !

                this.props.onSave(key, name)
                //this.setState({ isLoading: false })
                this.props.onClose()
                //this.loadFireListner(key)
                //this.setSchedule(key);
            })

        }
        else
            this.props.onClose()

    }


    confirmDelete = () => {
        // if (window.confirm(window._getText('schedual.deleteWarning'))) {
        //     this.onClickDeleteEditSchedule()
        // }
        // else {

        // }
        this.setState({ doDelete: true })
    }
    render() {

        if (this.props.role < 199)
            return (
                <ModalFrame name='editSchedual' dimmer='inverted' size='small' open={this.props.isOpen} className='modalAniSlideUp' hideCloseButton={false} onClickClose={this.props.onClose} >

                    <ModalFrame.Content>
                        <div style={{ padding: '20px' }}>
                            <h3> {window._getText('AdminCanAddScheduls')}</h3>
                        </div>

                    </ModalFrame.Content>
                    <ModalFrame.Actions>

                    </ModalFrame.Actions>
                </ModalFrame>
            )

        if (this.props.sKey === '__empty')
            return (
                <ModalFrame name='editSchedual' dimmer='inverted' size='small' open={this.props.isOpen} className='modalAniSlideUp' hideCloseButton={true} onClickClose={this.props.onClose} >

                    <ModalFrame.Content>
                        <div style={{ paddingTop: '10px', marginTop: '10px' }}>
                            {/* {window._getText('schedual.addSFirstTitle')} */}
                            {/* //👋 */}
                            <h3><span role='img' aria-label=''>👋</span>  {window._getText('WelcomeTo')}</h3>
                            {window._getText('StartByAddSchedule')}

                        </div>
                        {/* <label>{window._getText('schedual.addSName')}</label><br /> */}
                        {/* error={this.state.error} */}
                        <Input maxLength={20} placeholder={'Name'} value={this.state.valueName || ''} onChange={this.onChangeDivierEditScheduleModal} style={{ width: '100%' }} />


                    </ModalFrame.Content>
                    <ModalFrame.Actions>
                        <div style={{ border: 'solid 0px red' }}>
                            {/* <Button onClick={this.props.onClose} icon='close' style={{ marginRight: '10px' }}></Button> */}
                            <Button icon='checkmark' onClick={this.onClickSaveEditSchedule} loading={this.state.isLoading} disabled={this.state.isLoading || this.state.error} />
                        </div>
                    </ModalFrame.Actions>
                </ModalFrame>
            )


        return (


            <ModalFrame name='editSchedual' dimmer='inverted' size='small' open={this.props.isOpen} className='modalAniSlideUp' onClickClose={this.props.onClose} >

                <ModalFrame.Content>
                    <h3 style={{ paddingTop: '10px' }}>

                        {this.state.isNew === true && window._getText('schedual.addS')}
                        {!this.state.isNew && window._getText('schedual.editS')}
                    </h3>
                    {!this.state.doDelete &&
                        <div style={{ padding: '3px' }}>
                            {/* <label>{window._getText('schedual.addSName')}</label><br /> */}
                            {/* error={this.state.error} */}
                            <input className='form-control' style={{ width: '100%', color: '#000' }} maxLength={40} placeholder={window._getText('schedual.addSName')} value={this.state.valueName || ''} onChange={this.onChangeDivierEditScheduleModal} />
                        </div>
                    }
                    {this.state.doDelete === true &&
                        <div>
                            {window._getText('schedual.deleteWarning')}
                        </div>
                    }
                </ModalFrame.Content>
                <ModalFrame.Actions>
                    <div style={{ display: 'flex', }}>
                        <div>
                            {(this.state.doDelete === true || this.state.isNew === true) && <Button onClick={this.props.onClose} icon='close' style={{ marginRight: '10px' }}></Button>}
                            {(!this.state.isNew && !this.state.doDelete) && <Button disabled={this.state.isLoading} icon='trash alternate outline' onClick={this.confirmDelete} />}
                        </div>
                        <div style={{ marginLeft: 'auto', }}>
                            {!this.state.doDelete && <Button icon='checkmark' onClick={this.onClickSaveEditSchedule} loading={this.state.isLoading} disabled={this.state.isLoading || this.state.error} />}
                            {this.state.doDelete === true && <Button icon='checkmark' onClick={this.onClickDeleteEditSchedule} />}
                        </div>
                    </div>
                </ModalFrame.Actions>
            </ModalFrame>)

    }
}


const mapActions = {
    //onOpen: openEditScheduleModal,
    onClose: closeEditScheduleModal,
    addSchedule,
    removeSchedule
};

const mapStateToProps = state => {
    return {
        isOpen: state.modal.editShedualModalOpen,
        sKey: state.modal.editShedualModalKey,
        sName: state.modal.editShedualModalName,
        role: state.firebase.profile.role || 0,
        currentUser: state.firebase.profile,

    };
};
export default connect(
    mapStateToProps,
    mapActions
)(EditScheduleModule);



