import React, { useState, useEffect } from 'react'
import moize from 'moize'
const moment = require('moment')



export const ScheduleWeekpicker = ({ firstDayOfWeekNumber, renderDayDate, onClickDate, weeksToShow }) => {
    //console.log(moment)
    const renderDate = moment().utc().add(-14, 'days').isoWeekday(firstDayOfWeekNumber || 0).format('YYYYMMDD') //TODO: THIS ON THROWS ERROR!
    const [buttons, setButtons] = useState([]);

    useEffect(() => {
        const rDate = moment(renderDate)
        const selectedWeek = moment(renderDayDate).format('ww')
        const selectedWeekEnd = moment(renderDayDate).add((weeksToShow - 1) * 7, 'days').format('ww')
        let rDateStr = rDate.format('YYYYMMDD')
        let curWeekNrStr = new moment().format('ww')
        let weekNrStr = rDate.format('ww')
        let isSelected = false
        const r = []
        let cssClass = ''

        for (let index = 0; index < 30; index++) {


            isSelected = selectedWeek <= weekNrStr && selectedWeekEnd >= weekNrStr
            if (isSelected && selectedWeek == selectedWeekEnd)
                cssClass = 'weekPickerBgOne'
            else
                cssClass = isSelected ? selectedWeek === weekNrStr ? 'weekPickerBgStart' : selectedWeekEnd == weekNrStr ? 'weekPickerBgStop' : 'weekPickerBg' : ''

            r.push(
                <div key={rDateStr} key={rDateStr} onClick={onClickDate(rDateStr)} size='mini' className={cssClass} style={styles.s1}  >
                    <div style={styles.s2} >
                        {(curWeekNrStr === weekNrStr) ?
                            <b style={styles.s3}>{window._getText('week').substring(0, 1) + '.'} {getDate(rDate)}</b>
                            :
                            <b style={styles.s4} >{window._getText('week').substring(0, 1) + '.'} {getDate(rDate)}</b>
                        }
                    </div>
                </div>
            )

            rDate.add(7, 'days')
            rDateStr = rDate.format('YYYYMMDD')
            weekNrStr = rDate.format('ww')


        }
        setButtons(r)

    }, [renderDate, renderDayDate, weeksToShow])



    const getDate = (d) => d.format('ww')

    return (
        <div style={styles.s11} className='noPrint tour_schedule_3' >
            <div style={styles.s12}>
                {buttons}
            </div>
        </div>
    )
}


const styles = {
    s1: { margin: '2px', marginRight: '0px', marginLeft: '0px', paddingLeft: '12px', paddingRight: '12px', paddingTop: '2px', cursor: 'pointer!important' },
    s2: { textAlign: 'center', whiteSpace: 'nowrap' },
    s3: { color: 'rgba(153,0,0,1)', width: '100%', display: 'block' },
    s4: { width: '100%', display: 'block', color: 'var(--color--text)' },
    s5: { color: 'silver', fontSize: '11px' },
    s6: { margin: '0px', paddingLeft: '12px', paddingRight: '12px', cursor: 'pointer!important' },
    s7: { textAlign: 'center', whiteSpace: 'nowrap' },
    s8: { color: 'rgba(153,0,0,1)', width: '100%', display: 'block' },
    s9: { color: 'var(--color--text)', width: '100%', display: 'block', fontWeight: 'normal' },
    s10: { color: 'silver', fontSize: '11px' },
    s11: { xposition: 'fixed', width: '100%' },
    s12: { overflowX: 'hidden', padding: '6px', margin: '0px', display: 'flex', borderBottom: 'none!important', padding: '2px', margin: '0px' }
}