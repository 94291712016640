import React, { Component } from "react";
import { connect } from 'react-redux'
import TextMessages from './TextMessages'
import { openModal } from "../../../core/redux/action/modalAction";
import { FireHelper } from '../../../core/util/FireHelper'
import Locked from "../schedule/settings/Locked";
import { getUserlist } from '../../../core/redux/selectors'

//import memoizeOne from 'memoize-one';

// import MobileHead from '../mobile/MobileHead'


class Chat extends Component {





  render() {

    if (this.props.project && this.props.project.disabled === true)
      return <Locked />

    if (this.props.isUnknown === true)
      return (

        <div className="ui message" style={{ margin: '10px' }}>
          <div className="header">{window._getText('usernotmapped_title')}</div>
          <p>{window._getText('usernotmapped_txt1')} <br />
            {window._getText('usernotmapped_txt2')}</p>
        </div>

      )


    return (
      <TextMessages isLandscape={this.props.isLandscape} isKeyboardOpen={this.props.isKeyboardOpen} currentUser={this.props.currentUser} openModal={this.props.openModal} userlist={this.props.userlist} />
    );
  }
}


const mapStateToProps = (state) => {

  const currentProjectKey = state.firebase.profile.projectKey || "";

  var userList = []
  var userListData = getUserlist(state, currentProjectKey) //FireHelper.getUserlist(state.firestore.data.users, state.firebase.profile.projectKey) // JSON.parse(JSON.stringify(FireHelper.getUserlist(state.firestore.data.users, state.firebase.profile.projectKey)))
  if (userListData)
    userList = (userListData.userList || []).map(a => ({ ...a })) //userListData.userList

  if (userList)
    userList = userList.filter(u => u.act === true && u.sysKey)




  return {
    isUnknown: !state.firebase.profile.inSysUserKey,
    userlist: userList,
    authUser: state.firebase.auth,
    currentUser: state.firebase.profile,
    firebase: state.firebase,
    requestData: state.firestore.ordered['requestAllListner'],
    isKeyboardOpen: state.viewstate.isKeyboardOpen,
    isLandscape: state.viewstate.isLandscape,
    project: state.firestore.data.project,


  };
}
const mapActions = {
  openModal: openModal,

}
export default connect(mapStateToProps, mapActions)(Chat);

