import React from "react";
import { findDOMNode } from "react-dom";
import PropTypes from "prop-types";

let isElectron = false
let rootUrl = ''
if (window.location.protocol === 'file:' && window.require) {
    const electron = window.require("electron")
    try {
        const currentWindow = electron.remote.getCurrentWindow();
        rootUrl = currentWindow.rootUrl;
    } catch (error) {
        console.log(error)
    }

    isElectron = true
}

class PrintHelper extends React.Component {

    static propTypes = {
        /** Copy styles over into print window. default: true */
        copyStyles: PropTypes.bool,
        /** Trigger action used to open browser print */
        trigger: PropTypes.func.isRequired,
        /** Content to be printed */
        content: PropTypes.func.isRequired,
        /** Callback function to trigger before print */
        onBeforePrint: PropTypes.func,
        /** Callback function to trigger after print */
        onAfterPrint: PropTypes.func,
        /** Override default print window styling */
        pageStyle: PropTypes.string,
        /** Optional class to pass to the print window body */
        bodyClass: PropTypes.string,
    };

    static defaultProps = {
        copyStyles: true,
        closeAfterPrint: true,
        bodyClass: '',
    };

    triggerPrint(target) {
        const { onBeforePrint, onAfterPrint } = this.props;

        if (onBeforePrint) {
            onBeforePrint();
        }

        setTimeout(() => {
            target.contentWindow.focus();
            target.contentWindow.print();
            this.removeWindow(target);

            if (onAfterPrint) {
                onAfterPrint();
            }

        }, 500);
    }

    removeWindow(target) {
        setTimeout(() => {
            target.parentNode.removeChild(target);
        }, 500);
    }

    handlePrint = () => {

        const {
            bodyClass,
            content,
            copyStyles,
            pageStyle,
            onAfterPrint// eslint-disable-line no-unused-vars
        } = this.props;

        const contentEl = content();

        if (contentEl === undefined) {
            console.error("Refs are not available for stateless components. For 'react-to-print' to work only Class based components can be printed");
            return false;
        }




        let printWindow = document.createElement('iframe');
        printWindow.style.position = 'absolute';
        printWindow.style.top = '-1000px';
        printWindow.style.left = '-1000px';
        printWindow.style.width = '1000px';
        printWindow.style.height = '1000px';

        const contentNodes = findDOMNode(contentEl);
        const linkNodes = document.querySelectorAll('link[rel="stylesheet"]');

        this.linkTotal = linkNodes.length || 0;
        this.linkLoaded = 0;

        const markLoaded = (type) => {

            this.linkLoaded++;

            if (this.linkLoaded === this.linkTotal) {
                this.triggerPrint(printWindow);
            }

        };

        printWindow.onload = () => {
            /* IE11 support */
            if (window.navigator && window.navigator.userAgent.indexOf('Trident/7.0') > -1) {
                printWindow.onload = null;
            }

            let domDoc = printWindow.contentDocument || printWindow.contentWindow.document;
            const srcCanvasEls = [...contentNodes.querySelectorAll('canvas')];




            domDoc.open();
            domDoc.write(contentNodes.outerHTML);
            domDoc.close();

            /* remove date/time from top */
            const defaultPageStyle = pageStyle === undefined ? "" : pageStyle;

            // const defaultPageStyle = pageStyle === undefined
            //     ? "@page { size: auto;  margin: 0mm; } @media print { body { -webkit-print-color-adjust: exact; } }"
            //     : pageStyle;

            let styleEl = domDoc.createElement('style');
            styleEl.appendChild(domDoc.createTextNode(defaultPageStyle));
            domDoc.head.appendChild(styleEl);

            // var base = document.createElement('base');
            // base.href = '/';
            // domDoc.head.appendChild(base)

            if (bodyClass.length) {
                domDoc.body.classList.add(bodyClass);
            }

            const canvasEls = domDoc.querySelectorAll('canvas');
            [...canvasEls].forEach((node, index) => {
                node.getContext('2d').drawImage(srcCanvasEls[index], 0, 0);
            });


            //if its a schedule we split the tables 
            //const diversEls = domDoc.querySelectorAll('.scheduleDivider');
            //if (diversEls.length > 0) {

            const divSchedulePrint = domDoc.querySelectorAll('.schedulePrint')[0];
            const divNew = domDoc.createElement('div')
            const tblSchedules = domDoc.querySelectorAll('.tblScheduel')[0];
            const tblSchedulesHead = tblSchedules.querySelectorAll('thead')[0];

            //divNew.style.border = 'solid 1px red'
            divSchedulePrint.appendChild(divNew)

            var newHead = tblSchedulesHead.cloneNode(true);
            newHead.className = 'tHeadPrint'
            newHead.querySelectorAll('th')[0].innerHTML = ''

            const trEls = tblSchedules.querySelectorAll('tr');

            //var newTblData = domDoc.createElement('style');
            var currentBody
            var currentTable


            var tblHeight = divNew.offsetHeight

            for (let index = 0; index < trEls.length; index++) {
                // if (!currentBody) {
                //     currentTable = domDoc.createElement('table')
                //     currentTable.className = 'tblPrintTable ui very basic table tableBodyScroll unstackable tblScheduel'
                //     currentBody = domDoc.createElement('tbody');

                //     //currentBody.className = 'lunch'
                //     currentTable.appendChild(newHead)
                //     currentTable.appendChild(currentBody)
                //     divSchedulePrint.appendChild(currentTable)


                // }


                const trEl = trEls[index];
                const dividerEl = trEl.querySelectorAll('.scheduleDivider');
                const isHeaderRow = trEl.querySelectorAll('th')[0] ? true : false;

                //console.log('isHeaderRow', isHeaderRow)

                if (isHeaderRow === false) {
                    if (dividerEl[0] || !currentTable) {

                        console.log('CREATE HEAD', trEl)
                        //start a new group
                        currentTable = domDoc.createElement('table')
                        currentTable.className = 'tblPrintTable ui very basic table tableBodyScroll tblBreakerNoHeader'
                        currentBody = domDoc.createElement('tbody');
                        //currentBody.className = 'lunch'
                        currentTable.appendChild(newHead.cloneNode(true))
                        currentTable.appendChild(currentBody)
                        divNew.appendChild(currentTable)
                        tblHeight = divNew.offsetHeight
                    }

                    currentBody.appendChild(trEl)
                    // console.log('currentTable.clientHeight', currentTable.clientHeight)
                    // console.log('currentTable.offsetHeight', currentTable.offsetHeight)
                    // console.log('tblHeight', tblHeight)
                    // console.log('(' + currentTable.offsetHeight + ' - ' + tblHeight + ')', (currentTable.offsetHeight - tblHeight))
                    // // console.log('(tblHeight - currentTable.offsetHeight)222', (tblHeight - currentTable.offsetHeight))
                    // console.log('divNew.offsetHeight', divNew.offsetHeight)
                    if ((divNew.offsetHeight - tblHeight) > 680) {

                        //if landscape and over 800 then add a braker 
                        console.log('CREATE breaker', divNew.offsetHeight - tblHeight)
                        currentTable = domDoc.createElement('table')
                        currentTable.className = 'tblPrintTable ui very basic table tableBodyScroll tblBreakerNoHeader'
                        currentBody = domDoc.createElement('tbody');
                        currentTable.appendChild(newHead.cloneNode(true))
                        currentTable.appendChild(currentBody)
                        divNew.appendChild(currentTable)
                        tblHeight = divNew.offsetHeight
                    }
                }





                // console.log('element', element)
                // console.log('foo', foo)
            }
            tblSchedulesHead.remove()
            tblSchedules.remove()


            // }


            if (copyStyles !== false) {

                const headEls = document.querySelectorAll('style, link[rel="stylesheet"]');

                [...headEls].forEach((node, index) => {

                    let newHeadEl = domDoc.createElement(node.tagName);
                    let styleCSS = "";

                    if (node.tagName.toLowerCase() === 'style') {

                        if (node.sheet) {
                            for (let i = 0; i < node.sheet.cssRules.length; i++) {

                                styleCSS += node.sheet.cssRules[i].cssText + "\r\n";
                            }


                            newHeadEl.setAttribute('id', `react-to-print-${index}`);
                            newHeadEl.appendChild(domDoc.createTextNode(styleCSS));

                        }

                    } else {
                        let attributes = [...node.attributes];
                        attributes.forEach(attr => {
                            newHeadEl.setAttribute(attr.nodeName, attr.nodeValue);
                        });

                        newHeadEl.onload = markLoaded.bind(null, 'link');
                        newHeadEl.onerror = markLoaded.bind(null, 'link');

                    }

                    if (isElectron) {
                        var cssFile = newHeadEl.href.split("/").pop();
                        newHeadEl.href = rootUrl + 'static\\css\\' + cssFile
                        domDoc.head.appendChild(newHeadEl);
                    }
                    else {
                        domDoc.head.appendChild(newHeadEl);
                    }



                });


            }

            if (this.linkTotal === 0 || copyStyles === false) {
                this.triggerPrint(printWindow);
            }

        };

        document.body.appendChild(printWindow);

    }

    render() {

        return React.cloneElement(this.props.trigger(), {
            ref: (el) => this.triggerRef = el,
            onClick: this.handlePrint
        });

    }

}

export default PrintHelper;