import React, { PureComponent } from "react";
import { connect } from 'react-redux'
import Message from 'semantic-ui-react/dist/commonjs/collections/Message'
import Loader from 'semantic-ui-react/dist/commonjs/elements/Loader'
import PropTypes from 'prop-types'
import moment from 'moment';
import Wallet from './Wallet'
import FirestoreListnerHelper from '../../../../core/util/FirestoreListnerHelper'



class BillingBig extends PureComponent {
  //thisMonth = moment.utc().format('YYYYMM');
  //static contextTypes = { store: PropTypes.object.isRequired }
  componentDidMount() {
    FirestoreListnerHelper.setBillingListner(this.context)
    FirestoreListnerHelper.setInvoicesListner(this.context);

  }

  componentWillUnmount = () => {
    // FirestoreListnerHelper.clearBillingListners(this.context);
  }
  componentDidUpdate = (prevProps, prevState, snapshot) => {

  }

  renderInfoTest = () => {
    return (
      <div style={{ paddingTop: '10px', maxWidth: '900px' }} >
        <h3>{window._getText('billing.payDetails')} </h3>
        <p style={{ color: 'sliver' }}>

          {window._getText('billing.infoTxt1')}<br />
          {window._getText('billing.infoTxt2')} <br />
          {window._getText('billing.infoTxt3')}<br />
        </p>
        <br />
      </div>
    )
  }

  render() {
    var { billing, billingTransactions, project } = this.props
    var billingArray = []
    var billingTransactionsArray = []
    var dayDiff = 0
    const diablseAccountAfterDays = 7
    if (project && project.disableDate) {
      var disableDate = moment(project.disableDate).utc().add(diablseAccountAfterDays, 'days')
      var now = moment().utc()
      dayDiff = disableDate.diff(now, 'days')
      console.log('-----------')
      console.log('disableDate', disableDate.format('YYYY-MM-DD HH:mm'))
      console.log('dayDiff', dayDiff)
      console.log('project.enabled', project.enabled)
      console.log('project.disabled', project.disabled)
      console.log('-----------')
    }




    if (billing && billing[0]) {
      billingArray = Object.keys(billing[0]).map(key => {
        if (key === 'id')
          return null;
        return { ...billing[0][key], key }
      }
      )
      billingArray = billingArray.filter(Boolean);

      billingArray.sort((a, b) => {
        if (a.key > b.key)
          return -1;
        if (a.key < b.key)
          return 1;

        return 0;
      })

    }

    if (billingTransactions) {
      billingTransactionsArray = Object.keys(billingTransactions).map(key => { return { ...billingTransactions[key] } })
      billingTransactionsArray.sort((a, b) => {
        if (a.date > b.date)
          return -1;
        if (a.date < b.date)
          return 1;

        return 0;
      })


    }

    //console.log('billingArray', billingArray)


    if (!billingArray || !project)
      return (<div style={{ textAlign: 'center', marginTop: '50px' }}><Loader inline active>{window._getText('loading')}</Loader></div>)

    var sum = 0 // eslint-disable-line no-unused-vars
    var activeUsers = 0
    var rows = 0

    var rowCount = Object.keys(billingArray || {}).length

    return (
      <div style={{ paddingLeft: '10px', height: '100%', overflow: 'auto', display: 'flex', flexDirection: 'column' }}>
        <h2>Billing</h2>
        {project.disabled &&
          <Message color='red'>
            <Message.Header>{window._getText('billing.msgHead')}</Message.Header>
            {window._getText('billing.msgBody')}
          </Message>
        }



        <div style={{ position: 'fixed', top: '10px', right: '10px', fontWeight: 'bold' }}>Credits:{project.credits || 0}</div>
        {/* {rowCount === 0 && this.renderInfoTest()} */}


        <div style={{ paddingTop: '10px' }}>
          <Wallet></Wallet>
        </div>

        <div style={{ maxHeight: '40%', paddingTop: '10px' }}>
          <h3>{window._getText('billing.nextInvoice')}</h3>
          <div style={{ border: 'solid 1px #f1f1f1', maxHeight: '80%', overflow: 'auto', padding: '5px', margin: '5px', borderRadius: 8 }}>
            <table >
              <tbody>
                <tr >
                  <td style={{ fontWeight: 'bold', width: '50px' }} >#</td>
                  <td style={{ fontWeight: 'bold', width: '150px' }} >{window._getText('date')}</td>
                  <td style={{ fontWeight: 'bold', textAlign: 'center' }}>{window._getText('active')}</td>
                  <td style={{ fontWeight: 'bold', textAlign: 'center' }}>{window._getText('inactive')}</td>
                </tr>
                {billingArray.map((day, index) => {

                  const daySum = Number(day.true) * 0.02
                  sum += daySum
                  rows++;
                  activeUsers += Number(day.true || 0)

                  return (


                    <tr key={day.key + '-' + index} >
                      <td style={{ width: '50px' }} >#{rowCount - index} </td>
                      <td style={{ width: '150px' }} >{moment(day.key).format('L')}  </td>
                      <td style={{ textAlign: 'center' }} >{day.true && day.true}</td>
                      <td style={{ textAlign: 'center' }} >{day.false && day.false}</td>

                    </tr>





                  )
                })
                }
                {activeUsers > 0 &&
                  <tr>
                    <td style={{ width: '50px' }}></td>
                    <td style={{ width: '150px' }}></td>
                    <td style={{ textAlign: 'center', fontWeight: 'bold' }}>{window._getText('average')} {Math.round(activeUsers / rows)} { } {window._getText('users')}</td>
                    <td></td>

                  </tr>
                }
              </tbody>
            </table>
          </div>
        </div>


        <div style={{ paddingTop: '10px', maxHeight: '30%', }}>
          <h3>{window._getText('billing.bHistory')}</h3>
          <div style={{ border: 'solid 1px #f1f1f1', maxHeight: '80%', overflow: 'auto', padding: '5px', margin: '5px', borderRadius: 8 }}>

            <table style={{ maxWidth: '900px', }}>
              <tbody>
                <tr  >
                  <td style={{ fontWeight: 'bold' }}>{window._getText('date')}</td>
                  <td style={{ fontWeight: 'bold' }}>{window._getText('type')}</td>
                  <td style={{ fontWeight: 'bold', width: '50px' }}>{window._getText('credit')}</td>


                </tr>
                {billingTransactionsArray && billingTransactionsArray.map((item, index) => {
                  //var item = this.props.billingTransactions[key]
                  return (
                    <tr key={item.key + '-' + index}>
                      <td>{moment.unix(item.date / 1000).format('L HH:mm')} </td>
                      <td>{item.bType}</td>
                      <td style={{ width: '50px' }}>{item.value}</td>

                    </tr>
                  )
                })}
                <tr>
                  <td style={{ fontWeight: 'bold', width: '50px' }}></td>
                  <td style={{ fontWeight: 'bold' }}></td>
                  <td style={{ fontWeight: 'bold' }}></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        {/* {rowCount > 0 && this.renderInfoTest()} */}
        {this.renderInfoTest()}


        <div style={{ flex: 1 }}></div>



      </div >
    );
  }

}


export default connect((state) => ({
  billing: state.firestore.ordered.billing,
  billingTransactions: state.firestore.ordered.billingTransactions,
  project: state.firestore.data.project,
  projectKey: state.firebase.profile.projectKey,
}))(BillingBig)
