module.exports = {
    'login.LoginToAExistingAccount': 'Login to an existing account',
    'confirmLogoutfromAnonymousAccount': '!WARNING!\n\nIf you log out you can not login again.\nThis is a anonymous account.\nSet you email to protect your account.\nClick abort to cancel',
    'login.or': 'Or',
    'language': 'Language',
    'joy.viewWeeks': `Number of weeks to show`,
    'schedual.btnCopySchedule': `Copy schedule`,
    'schedual.btnPasteSchedule': `Paste schedule`,
    "AddRow": "Add row",
    "Workview": "Work view",
    "Publish": "Publish",
    "Unpublish": "Unpublish",
    "Published": "Published",
    "thisIsWatStaffSee": "This is what your staff see",
    "TaskStartInfo": "Click on a task or create a new one by click on the plus button in the top right corner",
    "NewFeatuer": "This is a new feature.",
    "newTaskInfo": `If you need a quick tour click on the 'Help' tab in the left bottom menu. 
    Then click 'Take a tour' and come back to this page.`,
    "ClickHereToStart": "Click here to start",
    "QuickIntro": "Need a quick intro to how this App works?",
    "TakeTour": "Take a product tour",
    "LinkCopied": 'link copied',
    "GoToWorkView": `This is the published schedule
    Go to work view to make changes`,
    "draft": "Draft, not published",
    "ClearSchedule": 'Do you want to clear this schedule? ',
    "PrintNoAbuseMsg": `Only paying customer can use the print function
    (This is to avoid abuse of the system.)`,
    "DataComesFromPubSche": "(Data displayed here comes from the published schedules)",
    "ThereIsNoPubSchedule": "There is no published schedule this month.",
    "comefirmUnlinkUser": 'Do you want to unlink the user?',
    "AdminCanAddScheduls": "Only administrator can add new schedules",
    "NewVersion": 'A new version is available, do you want to update now?',
    "RequestIsSent": 'Request sent',
    "TaskIsUnPublished": 'Task is unpublished.',
    "TaskIsPublished": 'Task is published.',
    "TaskRestarted": 'Task is restarted and moved to the Active tab.',
    "MovetToActive": 'Task is moved to Active.',
    "MovetToArchive": 'Task is moved to Archive.',

    "AreYouSureDeleteTask": 'Are you sure you want to delete this task',
    "TaskIsRemoved": 'Task is removed.',
    "DemoAccount": "Demo account, click here to logout and start your own account.",
    "ScheduleIsCopied": `Schedule is copied`,
    "PleaseRestart": 'Please restart your app for the settings to apply.',
    "PleaseAddCreditsBeforePrinting": 'Please add credits before printing',
    "AreYouSurePublish": 'Are you sure you want to publish the schedule for all staff?',
    "Unpublishing": `Unpublishing ...`,
    "PrivateNotes": "Private notes",
    "shiftIsCloned": `Shift is cloned`,
    "schedual.m.Tasks": 'Tasks',
    "login.demo": 'Demo',
    "tour.onboardStep_mainMenu": "Start by clicking on the tab you want a tour of.",
    "tour.tour_today_1": `This how the current day looks like.
    The data comes from the published schedule.`,
    "tour.tour_today_2": `Click here if you have overlapping shifts.`,
    "tour.tour_staff_1": `Click here to add a new staffmember`,
    "tour.tour_staff_2": `Click Here to add several members at once`,
    "tour.tour_staff_3": `Click here to send invites mail to all uninvited users at once.`,
    "tour.tour_staff_4": `To view or edit a staff member, Click here`,
    "tour.tour_staff_5": `Click here to invite the user by mail or SMS.`,
    "tour.tour_schedule_1": `The schedule.
    Click on the gear to change a name or delete the schedule.
    (Please make sure you have at least one schedule user in the schedule before continue)`,
    "tour.tour_schedule_2": `Current week, use the arrows to move to the next/prev week.`,
    "tour.tour_schedule_3": `Or use this week picker to go to a specific week.`,
    "tour.tour_schedule_4": `Click here to add a user or a shift
    (If you don't have any users in your schedule please add one now)`,
    "tour.tour_schedule_5": `You can also double click on a day to add a shift
    (Please make sure you have at least one shift in the schedule before moving to the next step)`,
    "tour.tour_schedule_6": `Click on a shift to edit it or drag it to move it.
    If you hold down option when dragging the shift will be cloned.`,
    "tour.tour_schedule_6_1": `Click on the user to copy a row or view the user.`,
    "tour.tour_schedule_7": `When you are happy with your schedule and want to share it with your staff.
    Click here to publish.`,
    "tour.tour_schedule_8": `If you want to remove the published schedule you can do it by clicking here.`,
    "tour.tour_schedule_9": `This is your work view, the staff members can not see this version of the schedule.`,
    "tour.tour_schedule_10": `This is want your users see in the mobile app.
    This is also the data in reports.`,
    "tour.tour_report_1": `Current month. User the arrows to change the month.`,
    "tour.tour_report_2": `Select a user then click next.
    If the user hours are 0:00h you need to publish your schedule.
    All data here is from the published schedule.`,
    "tour.tour_report_3": `This is the timesheet
    Here you can see worked hours.
    All data is from the published schedule.
    hover a row then click on the pen to edit the shift.`,
    "tour.tour_report_4": `Click here to export an xlsx report.
    If you want to download all reports in one sheet then use the top menu.
    File -> Export -> Export as xlsx.`,
    "tour.tour_requests_empty": `Please make sure you have at least one request before taking the tour. 
    
    For example click on an shift in the schedule then right click the "Create shift request" 
    `,
    "tour.tour_requests_1": `This is inbox and outbox for you requests.`,
    "tour.tour_requests_2": `The original shift from your published schedule.`,
    "tour.tour_requests_3": `The new shift that the staff member wants to change to ...`,
    "tour.tour_requests_4": `Approve the user request 
    This shift will be updated in your published schedule.`,
    "tour.tour_requests_5": `The changes were declined and no changes are done.`,
    "tour.tour_chats_1": `This is your chat list. 
    All is a none removal chat group for all staff members. 
    Log is a log list of your requests/responses.`,
    "tour.tour_chats_2": `As an administrator, you can add a new chat rooms.`,
    "tour.tour_chats_3": `Select a chat room and write your message here.`,
    "schedual.m.Tour": "Help",

    "StartByAddSchedule": "Start by adding the name of your main schedule.",
    "AddASchedule": "Add a schedule to get started.",
    "Available": "Available",
    "CanNotWork": "Can not work",
    "WantToWork": "Want to work",
    "Vacation": "Vacation",
    "Askforthisshift": "Ask for this shift",
    "AddDivider": "Add divider",
    "AddStaff": "Add staff",
    "NewShift": "New shift",
    "PasteShift": "Paste shift",
    "CopyShift": "Copy shift",
    "DeleteShift": "Delete shift",
    "CreateShiftRequest": "Create shift request",
    "minutes": "minutes",
    "YouCanOnlyPrint4week": "You can only print four weeks ahead",
    "addMoreBeforPrint": "Please add more credits before printing",
    "Mo": "Mo",
    "Tu": "Tu",
    "We": "We",
    "Th": "Th",
    "Fr": "Fr",
    "Sa": "Sa",
    "Su": "Su",
    "dontShowThis": "Don't show this at startup",
    "shortIntro": "This is a short intro to get you up to speed with how TimeTo.work works",
    "WelcomeTo": "Welcome to TimeTo.Work",
    "EditChat": "Edit chat",
    "all": "All",
    "log": "Log",
    "companyNameIsNotValid": "Company name is not valid",
    "setup.yesImAManger": "Yes, i'm a manager",
    "OrAreYouAManger": "Or are you a manager and want to create a new staff schedule?",
    "WeCanNotMatchYou": "We can not match your mail to a user , Ask your manager to add your mail to a user in the schedule",
    "and": "and",
    "policy": "Privacy Policy",
    "terms": "Terms of Use",
    "byclicking": "By clicking 'Create' you agree to the",
    "IncomingRequests": "Incoming requests",
    "OutgoingRequests": "Outgoing requests",
    "AreYouSureYouWantToQuitTitle": "Confirm quit",
    "AreYouSureYouWantToQuitYes": "Yes",
    "AreYouSureYouWantToQuitNo": "No",
    "ShiftLabel": "Shift Label",
    "TotalBreakTime": "Total break time",
    "currentPrice": "1 user for 30 days = 1 credit",
    "userCredits": "user credits",
    "RestorePreviousPurchase": "Restore previous purchase",
    "AddMoreCredits": "Add more credits",
    "settings.billing": "Billing",
    "settings.settings": "Settings",
    "settings.account": "Account",
    "settings.title": "Preferences",
    "billing.msgLink": "Go to TimeTo.Work -> Preferences -> Billing. In the top menu",
    "AreYouSureYouWantToQuit": "Are you sure you want to quit?\n\rYou will lose any unpublished data.",
    "Saved": "Saved",
    "Edit": "Edit",
    "Cut": "Cut",
    "Copy": "Copy",
    "Paste": "Paste",
    "Select All": "Select All",
    "Help": "Help",
    "Clear all data": "Clear all data",
    "About TimeTo.Work": "About TimeTo.Work",
    "services": "Services",
    "Preferences...": "Preferences...",
    "Toggle menu bar icon": "Toggle menu bar icon",
    "Hide TimeTo.Work": "Hide TimeTo.Work",
    "Hide Others": "Hide Others",
    "Show All": "Show All",
    "Signout": "Signout",
    "Quit": "Quit",
    "File": "File",
    "New chat": "New chat",
    "New staff": "New staff",
    "New schedule": "New schedule",
    "Export": "Export",
    "Reports as XLSX": "Reports as XLSX",
    "Schedule as PDF": "Schedule as PDF",
    "Add to schedule": "Add to schedule",
    "Shift": "Shift",
    "Staff": "Staff",
    "Divider": "Divider",
    "Copy schedule": "Copy schedule",
    "Paste schedule": "Paste schedule",
    "Clear schedule": "Clear schedule",
    "Undo all changes": "Undo all changes",
    "How do i ...": "How do i ...",
    "Quick introduction": "Quick introduction",
    "Window": "Window",
    "minimize": "minimize",
    "zoom": "zoom",
    "front": "Bring all to front",
    "window": "TimeTo.Work",
    "Next": "Next",
    "Prev": "Prev",
    "joy.Back": "Back",
    "joy.Close": "Close",
    "joy.Last": "Last",
    "joy.Next": "Next",
    "joy.Skip": "Skip Tutorial",
    "joy.welcome": "Welcome!",
    "joy.startbyaddingusers": "Start by adding some staff members",
    "joy.clickonstaff": "Click on 'Staff'",
    "joy.clickHerToAddUsers": "Click here to add a staff member or two",
    "joy.clickHereToAddMultipleUsers": "Here you can add multiple staff members at once",
    "joy.whenYourDone": "When your done adding staff members,",
    "joy.goToSchedule": "go to the schedule to start scheduling",
    "joy.clickHereToAddAShift": "Click here to add a shift",
    "joy.orClickOnTheSchedule": "Or you can double click on the schedule",
    "joy.addMultipleRows": "Here you can add multiple rows at once",
    "joy.dontForgetToSave": "When you're done, don't forget to save and publish your schedule",
    "a few seconds ago": "a few seconds ago",
    "seconds ago": "seconds ago",
    "a minute ago": "a minute ago",
    "minutes ago": "minutes ago",
    "an hour ago": "an hour ago",
    "hours ago": "hours ago",
    "a day ago": "a day ago",
    "days ago": "days ago",
    "a month ago": "a month ago",
    "months ago": "months ago",
    "a year ago": "a year ago",
    "years ago": "years ago",
    "SCHEDULES": "SCHEDULES",
    "on": "On",
    "off": "Off",
    "now": "Now",
    "delete": "Delete",
    "name": "Name",
    "rotateimage": "Rotate image",
    "schedule": "Schedule",
    "hours": "Hours",
    "sick": "Sick",
    "break": "Break",
    "time": "Time",
    "signout": "Sign Out",
    "update": "Update",
    "yes": "Yes",
    "no": "No",
    "January": "January",
    "February": "February",
    "March": "March",
    "April": "April",

    "June": "June",
    "July": "July",
    "August": "August",
    "September": "September",
    "October": "October",
    "November": "November",
    "December": "December",
    "Jan": "Jan",
    "Feb": "Feb",
    "Mar": "Mar",
    "Apr": "Apr",
    "May": "May",
    "Jun": "Jun",
    "Jul": "Jul",
    "Aug": "Aug",
    "Sep": "Sep",
    "Oct": "Oct",
    "Nov": "Nov",
    "Dec": "Dec",
    "credit": "Credit",
    "type": "Type",
    "users": "users",
    "average": "Average",
    "date": "Date",
    "total": "Total",
    "week": "Week",
    "wShort": "w.",
    "monday": "Monday",
    "tuesday": "Tuesday",
    "wednesday": "Wednesday",
    "thursday": "Thursday",
    "friday": "Friday",
    "saturday": "Saturday",
    "sunday": "Sunday",
    "new": "New",
    "active": "Active",
    "inactive": "Inactive",
    "invites": "Invite",
    "import": "Import",
    "search": "Search...",
    "password": "Password",
    "oops": "Oops!",
    "uploading_image": "Uploading image...",
    "picture": "Picture",
    "add": "Add",
    "save": "Save",
    "cancel": "Cancel",
    "shifts": "shifts",
    "h": "h",
    "upload": "Upload",
    "create": "Create",
    "email": "Email",
    "phone": "Phone",
    "cellphone": "Cellphone",
    "phonenumber": "Phone Number",
    "validphone": "+ country code and mobile number ",
    "back": "back",
    "send": "Send",
    "loading": "Loading...",
    "logginin": "Logging in...",
    "logginout": "Logging out...",
    "tooltip.AddANewChat": "Add a new chat",
    "tooltip.Approve": "Approve",
    "tooltip.Cancel": "Cancel",
    "tooltip.Deny": "Deny",
    "message.empty": "You don't have any requests.",
    "message.in.empty": "You don't have any requests.",
    "message.out.empty": "You don't have any pending requests.",
    "message.in": "In",
    "message.out": "Out",
    "message.chat": "Chat",
    "login.titel": "Login",
    "login.lostpass": "Forgot your password?",
    "login.sendpass": "Send new password",
    "login.titleNewAccount": "New account",
    "login.forEmp": "For the employee",
    "login.newUser": "New staff account",
    "login.newSchedule": "New schedule account",
    "login.forCom": "For the company",
    "login.sentResetMail": "We have sent a reset link to your email.",
    "login.err.1": "There is no user corresponding to this email address. The user may have been deleted.",
    "login.err.2": "The password is invalid.",
    "login.err.3": "The email address is badly formatted.",
    "login.err.4": "The account has been disabled by an administrator.",
    "login.err.5": "The password must be 6 characters long or more.",
    "login.err.6": "This is not a valid phone number You need to start with your country code.",
    "login.btnLogin": "Login",
    "createNewAccount.dontHavePassword": "Don't have a password?",
    "createNewAccount.setPassword": "Set new password",
    "createNewAccount.smscodeTitle": "Code from the text message",
    "createNewAccount.info": "Use phone number if you got invited by SMS or Email if you got invite by mail",
    "createNewAccount.smscode": "Code from the text message",
    "createNewAccount.login": "Login",
    "createNewAccount.teleOrEmail": "Phone number or email",
    "createNewAccount.newaccount": "New staff account",
    "createNewAccount.newSchedule": "New schedule",
    "createNewAccount.theKey": "The key you got from your manager",
    "createNewAccount.secretKey": "Company key",
    "createNewAccount.comName": "Company name",
    "createNewAccount.weekstart": "Week starts with",
    "createNewAccount.comDisplayName": "Company display name",
    "createNewAccount.adminAccount": "Administrator account",
    "createNewAccount.emailAndPass": "Email and password",
    "createNewAccount.useSameEmail": "Use the same email you got in the invite. So we can mapp you to a user in the schedule",
    "createNewAccount.youneedkey": "You need to have a company key",
    "createNewAccount.youneedCompanyName": "You need to have a company name",
    "createNewAccount.terms": "I agree to the Terms and Conditions",
    "createNewAccount.termsApprove": "You need to approve the terms and conditions",
    "createNewAccount.ampmOr24h": "Time format",
    "setup.notValidKey": "This is not a valid company key",
    "setup.retry": "Retry",
    "setup.updateKey": "Update key",
    "setup.creatingCompany": "Creating business account ...",
    "setup.mappingUser": "Matching user to a schedule ...",
    "setup.createNewAccount": "Create a new user account",
    "setup.wrongKey": "Company key is missing or wrong",
    "setup.comKey": "Company key",
    "setup.needSetup": "We have create your account successfully but we need to connect it to a company",
    "schedual.emptyTitle": "Your schedule is empty !",
    "schedual.dropdelete": "Drop shift/user here to remove it!",
    "schedual.emptyTxt2": " ",
    "schedual.emptyTxt1": "Use secondary click to add staff or shifts.",
    "locked.title": "Your account is locked!",
    "locked.txt1": "Your account is temporarily locked due to lack of credits.",
    "locked.txt2": "Don't worry it's an easy fix, Add more credits and in a few minutes it will be enabled again",
    "locked.link": "Click here to get more credits",
    "schedual.last": "Last updated",
    "schedual.openshifts": "Open shifts",
    "schedual.lbl-openshifts": "Open shifts",
    "schedual.lbl-unassigned": "Unassigned",
    "schedual.m.Today": "Today",
    "schedual.m.Home": "Home",
    "schedual.m.Schedule": "Schedule",
    "schedual.m.Request": "Request",
    "schedual.m.Staff": "Staff",
    "schedual.m.Reports": "Reports",
    "schedual.m.Chat": "Chat",
    "schedual.m.print": "Print",
    "schedual.m.addNewUser": "Add new staff member",
    "schedual.m.addOpenShifts": "Add 'open shifts'",
    "schedual.m.addDiv": "Add user divider",
    "schedual.m.addNewSchedule": "Add new schedule",
    "schedual.m.copyS": "Copy schedule",
    "schedual.m.pasteS": "Paste schedule",
    "schedual.m.undo": "Undo all changes",
    "schedual.m.clear": "Clear all shifts",
    "schedual.m.tools": "Tools",
    "schedual.editS": "Edit schedules",
    "schedual.addS": "Add new schedule",
    "schedual.addSFirstTitle": "Add new schedule",
    "schedual.addSName": "Schedule name",
    "schedual.deleteBtn1": "Delete schedule",
    "schedual.deleteWarning": "If you delete the schedule , It will be permanently delete  and all historical data to it",
    "schedual.deleteBtn2": "Confirm permanently delete",
    "users.staffmembers": "Staff members",
    "users.newuser": "New",
    "users.uniqueemail": "Email must be unique",
    "users.uniqueemail2": "This Mail cannot be used",
    "users.uniquephone": "Phone must be unique",
    "users.invalidphone": "This is not a valid mobile number",
    "users.notemail": "This is not a valid email",
    "users.invalidphone2": "You need to add the country code before the number",
    "users.infoText": "These are used for connecting your staff to a user in the schedule, you only need to set one of them.",
    "users.infoText1": "This user is linked to a staff member , click the lock to unlink them.",
    "users.linked": "Linked",
    "users.dname": "Display name",
    "users.delete": `If you delete the user, it will be permanently delete !
    And all historical data will be marked with 'Unknown user'
Sometimes it might be better just to mark the user as inactive.`,
    "users.deleteBtn1": "Delete user",
    "users.deleteBtn2": "Confirm permanently delete",
    "user.active": "Active",
    "user.unlockEmail": "Unlock email",
    "user.bulkimport": "Bulk import",
    "user.bulkTitle": "Insert a comma-separated list with users",
    "user.bulkTxt": "One user per row. Separate name and number/mail with a comma",
    "user.bulkTxt2": `Display name, email or phone
John Doe, +1123456789 or Jane Doe, jane@timeto.work`,
    "user.inviteUnMaped": "Invite unlinked users",
    "user.allLinked": "All users is linked",
    "set.billing": "Billing",
    "set.settings": "Settings",
    "set.settings.title": "System settings",
    "set.settings.time": "Time",
    "set.settings.publickey": "Company key",
    "or": "or",
    "set.settings.timeWarning": "When changed you need to re-save your schedule data to renew old cached time labels",
    "set.settings.publickeytext": "This is the key your staff members need when they create a new account",
    "set.settings.auto": "Automatic approve user shift changes",
    "set.settings.btn.dev": "Deviations",
    "set.settings.btn.com": "Comments",
    "set.settings.btn.sCan": "Remove shifts",
    "set.settings.btn.sMov": "Move shifts",
    "set.settings.btn.sSwp": "Swap shifts",
    "set.settings.btn.sCha": "Change time",
    "set.settings.btn.sik": "Sick leave",
    "set.settings.btn.openshifts": "Book shift",
    "billing.msgHead": "Your account don't have enough credits.",
    "billing.msgBody": "Please add more credits to continue using the system",
    "billing.msgHead1": "Please add more credit before",
    "billing.msgBodyDays": "days",
    "billing.msgHead2": "or your account will automatically be locked",
    "billing.nextInvoice": "Next invoice",
    "billing.bHistory": "Billing history",
    "billing.infoTxt1": "Every 30 day we will check the used credits vs your existing credits , if you are low on credit you will have 7 days to add more credits",
    "billing.infoTxt2": "after that your access will be disabled until you add more credit",
    "billing.infoTxt3": "If you don't want to continue using your schedule you can just skip adding new credits, after 7 days your account will be locked and after 360 day it will be deleted",
    "wallet.title1": "Buy more credit",
    "wallet.txtWeb1": "At the moment we only support buying new credit thru Google Play or iOS App Store",
    "wallet.txtWeb2": "even if you only using TimeTo.Work at the web , you need to do the payments thru one of the stores. Please download the app from one of the stores and you will see a buy more credits button inside the app here",
    "billing.payDetails": "Payment details",
    "report.noscheduled": "No scheduled hours this month",
    "report.downloadall": "Download all",
    "report.downloadExcel": "Download excel",
    "report.h_date": "Date",
    "report.h_time": "Time",
    "report.h_break": "Break",
    "report.h_hours": "Hours",
    "report.h_sik": "Sick",
    "report.h_workedhours": "Worked hours",
    "report.h_presence": "Presence",
    "report.h_schedal": "Schedule",
    "appmenu.user": "User",
    "appmenu.pushmessages": "Push notifications",
    "appmenu.changepass": "Change password",
    "usernotmapped_txt1": "You are not mapped to a user in the schedule",
    "usernotmapped_txt2": "Ask your schema administrator to add your email address",
    "usernotmapped_title": "Important!",
    "selectuser.warning": "Select at least one user to add to the schedule",
    "selectuser.title": "User list",
    "editdivider.title": "Group divider",
    "userhome.youarefree": "You are free to do whatever you like this week ",
    "shiftuserinput.title0": "Update shift",
    "shiftuserinput.title1": "Actual worked time",
    "shiftuserinput.title2": "Ask for a change",
    "shiftuserinput.title3": "Add a comment",
    "shiftuserinput.title4": "Mark as sick",
    "shiftuserinput.title5": "Request shift",
    "shiftuserinput.btn1": "Report time deviation",
    "shiftuserinput.btn2": "Ask for a change",
    "shiftuserinput.btn3": "Add a comment",
    "shiftuserinput.btn4": "Mark as sick",
    "shiftuserinput.btn5": "Cancel shift",
    "shiftuserinput.btn6": "Adjust time",
    "shiftuserinput.btn7": "Assign shift to another user",
    "shiftuserinput.btn8": "Swap shift with another user",
    "shiftuserinput.comment": "Comment",
    "shiftuserinput.usercomment": "User comment",
    "shiftuserinput.swapTitle1": "Select user to swap with",
    "shiftuserinput.swapTitle2": "Select week",
    "shiftuserinput.swapTitle3": "Select Shift",
    "shiftuserinput.addCommentToUser": "Add comment to the user",
    "shiftuserinput.cancelation": "Cause of cancellation",
    "request.adminRequest": "Request",
    "request.titleCancle": "Cancel shift",
    "request.titleChange": "Change time",
    "request.titleMove": "Move shift",
    "request.titleSwap": "Swap shift",
    "request.titleTime": "Time deviation",
    "request.titleSick": "Sick",
    "request.titleComment": "Comment",
    "request.titleUserrequestshift": "Shift request",
    "wallet.waiting_for_validation": "Waiting for validation.",
    "wallet.pleasewait": "please wait",
    "wallet.success": "Success.",
    "wallet.success1": "the credits will come in to you account shortly",
    "wallet.faild": "contact support if you have any questions",
    "wallet.purchasing": " ",
    "wallet.failed": "Failed to purchase.",
    "wallet.failed1": "contact support if you have any questions",
    "wallet.restored": "The purchase of has been restored.",
    "wallet.deferred": "The purchase of has been deferred.",
    "appdiv.pushmessages": "Push messages",
    "appdiv.changepass": "Change password",
    "schedual.adminrequest": "Administrator",
    "schedual.btnPublish": "Save and publish",
    "schedual.btnAddUser": "Add staff",
    "schedual.btnAddShift": "Add shift",
    "schedual.btnAddDiv": "Add divider",
    "schedual.btnHelp": "Help",
    "calendar.empty": "There is no schedule this week"
}