import React, { Component } from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { connect } from 'react-redux'
import captureException from 'sen-obj';

//import firebase from '../../../core/config/firebase';
import { FireHelper } from '../../../core/util/FireHelper'
//import Image from 'semantic-ui-react/dist/commonjs/elements/Image'
//import ReactGA from 'react-ga';

import { version } from '../../../../package.json'
//mport Login from "../login/Login";
// import SignOut from '../login/SignOut'
// import PrivacyPolicy from '../login/PrivacyPolicy'
// import Setup from '../login/Setup'
// import CreateNewAccount from '../login/CreateNewAccount'

import { lLogin, lDev, lSignOut, lPrivacyPolicy, lSetup, lCreateNewAccount, lTermsConditions } from './Loader'



import imgLogo from '../../../img/logo.png'
import bglogin from '../../../img/bglogin.jpg'
//import Dev from '../dev/Dev'



import PropTypes from 'prop-types'
// auth.onAuthStateChanged(async user => {

//   console.log('!!!!!! auth.onAuthStateChanged!!!!!!!!!')
//   if (user) {
//     console.log('Logged in')

//     try {
//       const idTokenResult = await user.getIdTokenResult(true)
//       await firebase.reloadAuth();
//       console.log('idTokenResult.claims', idTokenResult.claims)
//       console.log('idTokenResult.claims.role', idTokenResult.claims.role)
//       console.log('idTokenResult.claims.pKey', idTokenResult.claims.pKey)
//       //if (idTokenResult.claims.role && idTokenResult.claims.pKey)
//       // alert('ok redirect', idTokenResult.claims.pKey)

//     } catch (error) {
//       console.log(error)
//     }

//   }
//   else
//     console.log('NOT Logged in')
//   console.log('!!!!!!')
//   console.log('')
// });
import { isElectron } from 'react-device-detect';

let ipcRenderer

if (isElectron) {
  ipcRenderer = window.require('electron').ipcRenderer
}
class LoginRouter extends Component {
  static contextTypes = { router: PropTypes.object }




  componentDidUpdate = async (prevProps, prevState, snapshot) => {
    // console.log('--------')
    // console.log('prevProps.currentUser.role ', prevProps.currentUser.role)
    // console.log('this.props.currentUser.publicKey', this.props.currentUser.publicKey)
    // console.log('this.props.currentUser.role ', this.props.currentUser.role)
    // console.log('this.props.currentUser.projectKey', this.props.currentUser.projectKey)

    const idTokenResult = await FireHelper.reloadToken(false)

    // //await firebase.reloadAuth();
    // console.log('idTokenResult.claims', idTokenResult.claims)
    // console.log('idTokenResult.claims.role', idTokenResult.claims.role)
    // console.log('idTokenResult.claims.pKey', idTokenResult.claims.pKey)
    // console.log('--------')

    if (idTokenResult.claims.role !== this.props.currentUser.role)
      FireHelper.reloadToken(true)
      // console.log(`prevProps.currentUser`, prevProps.currentUser)
      // console.log(`this.props.currentUser`, this.props.currentUser)
    if (prevProps.currentUser.role === undefined && this.props.currentUser.publicKey === null && this.props.currentUser.role === 50 && (this.props.currentUser.projectKey === undefined || this.props.currentUser.projectKey === null)) {
      //this is a reLogin of a unmaped user
      console.log('go to setup!')
      this.props.history.push('/setup')
    }


  }

  componentDidMount = () => {

    if (ipcRenderer)
      ipcRenderer.send('setMenu', '/login')

  }

  addAnimations = () => {

    if (this.props.haveUser) {
      this.timerBackup = setTimeout(() => {
        //backup, sometimes animation end dont fire
        this.animationEnd({ currentTarget: { id: 'divLoader' }, backupEvent: true })
      }, 2000);

      return 'loginHidebox'
    }
    else
      return ''


  }

  animationEnd = (e) => {
    //console.log('animationEnd')
    //TODO make sure a new user is redirectedd!
    var { currentUser } = this.props

    if (this.timerBackup)
      clearTimeout(this.timerBackup)


    if (e.backupEvent) {
      console.log('Login faild , saved by timer')
      // captureException('Login faild , saved by timer')
    }

    //  console.log('>>animationEnd', e.currentTarget.id)
    // if (currentUser)
    //   console.log('currentUser.projectKey', currentUser.projectKey)

    if (e.currentTarget.id === 'divLoader') {

      // console.log('currentUser', currentUser)
      // if (e.target.className === 'loginHidebox divLoginBox')
      //   this.props.history.push('/schedule/schedule/default') //TODO do we need to send the to /newuser ? to reload auth
      // else {
      if (currentUser.projectKey) {
        //  console.log('redirect to project key')
        this.props.history.push('/schedule/schedule/default') //TODO do we need to send the to /newuser ? to reload auth
      }
      // else {
      //   console.log('user dont have a project key', currentUser)
      // }
      // else {
      //   //console.log('PROBLEM ..else', currentUser)
      //   try {
      //     ReactGA.event({ category: 'ERROR', action: 'LoginRouter.animationEnd', value: JSON.stringify(currentUser) });

      //   } catch (error) {
      //     ReactGA.event({ category: 'ERROR', action: 'LoginRouter.animationEnd', value: 'current user problem' });

      //   }

      // }
      //}
      //   this.props.history.push('/setup')
    }
    else {
      console.log('AnimationEnd else we dont have a user')
    }
    // 
  }
  render() {
    var { currentUser } = this.props
    if (!currentUser)
      currentUser = { isEmpty: true }



    return (
      <React.Fragment>
        <div className='bgCover dragWindow' style={{ height: '100%', backgroundImage: `url(${bglogin})`, xbackgroundColor: 'rgb(69, 69, 68)' }}>
          <span style={{ color: 'silver', position: 'absolute', bottom: '2px', right: '8px', fontSize: '10px' }}>{version}</span>
          <div id='divLoader' onAnimationEnd={this.animationEnd} className={this.addAnimations() + ' divLoginBox'} >
            <div style={{ display: 'flex', flexDirection: 'column', xbackgroundColor: 'rgb(69, 69, 68)' }}  >
              <div className='logoAni' style={{ marginLeft: 'auto', marginRight: 'auto', marginBottom: 40, marginTop: 40 }}>
                <img size='mini' alt='TimeTo.Work' src={imgLogo} ></img>
              </div>
              <div  >



                {!this.props.authUser.isEmpty &&
                  <Route
                    path="*"
                    render={() => (
                      <Switch>
                        <Route path="/setup" component={lSetup} />
                        <Route path="/login" component={lLogin} />
                        <Route path="/newschedule2" component={lCreateNewAccount} />
                        <Route path="/signout" component={lSignOut} />
                        <Route path="/privacypolicy" component={lPrivacyPolicy} />
                        <Route path="/termsconditions" component={lTermsConditions} />
                        <Route path="/dev" component={lDev} />
                        <Redirect to="/setup" />
                      </Switch>
                    )}
                  />
                }

                {(!this.props.authUser || this.props.authUser.isEmpty) &&
                  <Route
                    path="*"
                    render={() => (
                      <Switch>
                        <Route path="/setup" component={lSetup} />
                        <Route path="/login" component={lLogin} />
                        <Route path="/newuser" component={lCreateNewAccount} />
                        <Route path="/newschedule" component={lCreateNewAccount} />
                        <Route path="/privacypolicy" component={lPrivacyPolicy} />
                        <Route path="/termsconditions" component={lTermsConditions} />
                        <Route path="/dev" component={lDev} />
                        <Redirect to="/login" />
                      </Switch>
                    )}
                  />
                }

              </div>

            </div>
          </div>
        </div>


      </React.Fragment>



    )
  }
}


const mapStateToProps = (state, ownProps) => {
  return { authUser: state.firebase.auth, curUser: state.firebase.profile }
};
const mapActionsFromProps = {};
export default withRouter(connect(
  mapStateToProps,
  mapActionsFromProps
)(LoginRouter))



