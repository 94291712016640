import React from 'react'
import { toastr } from 'react-redux-toastr'
import { FireHelper } from '../../../core/util/FireHelper'
import { store } from '../../../core/redux/store/configStore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faCheck as icoCheck,
    faCircle as icoTaskItem,
    faArchive as icoArchive,
    faUndoAlt as icoBackFromArchive,
    faPlayCircle as icoRunning,
    faStopCircle as icoStop,
} from '@fortawesome/pro-duotone-svg-icons';
import s from './style'

export default function TaskItem ({ id, isSelected, data: { title, status, archive },onStatusChange })   {
        const onRemoveTaskItem = (key) => (e) => {
            if (window.confirm(window._getText('AreYouSureDeleteTask'))) {
                let state = store.getState()
                FireHelper.addOrUpdateTask({ key: key, delete: true }, state.firebase.profile).then(x => {
                    toastr.success(window._getText('TaskIsRemoved'));
    
                    // if (callbackOnRemove)
                    //   callbackOnRemove()
                })
            }
    
        }
    
    
    
        const onArchiveTaskItem = (key, archive) => (e) => {
            let state = store.getState()
    
            FireHelper.addOrUpdateTask({ key: key, status: 10, archive: !archive }, state.firebase.profile).then(x => {
                if (!archive)
                    toastr.success(window._getText('MovetToArchive'));
                else
                    toastr.success(window._getText('MovetToActive'));
    
            })
            //swap tab here
            if(onStatusChange)
                onStatusChange(!archive)
        }
    
        return (
            <div style={{ ...s.taskItem, width: '100%', overflow: 'hidden' }}  >
                {status === 10 &&
                    <div style={{ width: 'calc(100% - 55px)', display: 'flex' }}>
                        <FontAwesomeIcon icon={icoStop} style={s.icoStatus10} /> <div style={{marginTop:4, width: 'calc(100vw - 50px)', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{title}</div>
                    </div>
                }
                {status === 20 &&
                    <div style={{ width: 'calc(100% - 55px)', display: 'flex' }}>
                        <FontAwesomeIcon icon={icoRunning} style={s.icoStatus20} /> <div style={{marginTop:4, width: 'calc(100vw - 50px)', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{title}</div>
                    </div>
                }
                {status === 30 &&
                    <div style={{ width: 'calc(100% - 55px)', display: 'flex' }}>
                        <FontAwesomeIcon icon={icoTaskItem} style={s.icoStatus30} /> <div style={{marginTop:4, width: 'calc(100vw - 50px)', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{title}</div>
                    </div>
                }
                {status === 40 &&
                    <div style={{ width: 'calc(100% - 55px)', display: 'flex' }}>
                        <FontAwesomeIcon icon={icoCheck} style={s.icoStatus40} /> <div style={{marginTop:4, width: 'calc(100vw - 50px)', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{title}</div>
                    </div>
                }
    
                {isSelected && (archive === false || archive === undefined) &&
                    <div tooltip={'Archive and unpublish'} style={{ marginLeft: 'auto', }}>
                        <FontAwesomeIcon onClick={onArchiveTaskItem(id, archive)} icon={icoArchive} style={s.icoTask} />
                        {/* <FontAwesomeIcon onClick={onRemoveTaskItem(id)} icon={icoRemove} style={{ ...s.icoAddTask, marginLeft: 5 }} /> */}
                    </div>
                }
                {isSelected && archive === true &&
                    <div tooltip={'restore'} style={{ marginLeft: 'auto' }}>
                        <FontAwesomeIcon onClick={onArchiveTaskItem(id, archive)} icon={icoBackFromArchive} style={s.icoTask} />
                        {/* <FontAwesomeIcon onClick={onRemoveTaskItem(id)} icon={icoRemove} style={{ ...s.icoAddTask, marginLeft: 5 }} /> */}
                    </div>
                }
    
            </div>
        )
   
}

