import React, { useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { toastr } from 'react-redux-toastr'
import ReactGA from 'react-ga';

import {
    faQuestion as icoPageHeader,
    faHandPointer as icoArrow,
    faRoute as icoTour
} from '@fortawesome/pro-duotone-svg-icons';
import { useHistory } from 'react-router-dom';
import GlobalState from '../../../globalState'
import { isElectron } from 'react-device-detect';

let ipcRenderer
let clipboard
if (isElectron) {
    ipcRenderer = window.require('electron').ipcRenderer
    clipboard = window.require('electron').clipboard

}


export default function Tour(props, context) {
    const global = useContext(GlobalState)
    const history = useHistory();

    const StartTour = () => {


        global.setState({ showTour: true })

        history.push('/tour1')
        setTimeout(() => {
            history.push('/tour')
        }, 1);

        ReactGA.event({ category: 'Tour', action: 'start tour' });
    }

    const goTo = (url) => () => {
        history.push(url)
        if (ipcRenderer) {
            if (url.startsWith('/schedule/schedule/')) { ipcRenderer.send('setMenu', '/schedule') }
            else { ipcRenderer.send('setMenu', url) }
        }
    }

    const copyText = (text) => () => {
        if (clipboard) {
            clipboard.writeText(text, 'selection')
            toastr.success(window._getText('LinkCopied')) // TODO Translate

        }

    }

    return (
        <React.Fragment>
            <div className='dragWindow2' style={{ backgroundColor: 'var(--color-bg1)', width: '100%', display: 'flex', height: '50px', zIndex: 9, padding: '10px' }}>
                <h2 style={{ color: '#E4494E' }}> {<FontAwesomeIcon icon={icoPageHeader}></FontAwesomeIcon>}  {window._getText('schedual.m.Tour')}</h2>
            </div>
            <div style={{ overflowY: 'auto', overflowX: 'hidden', marginTop: '8px' }} >
                <div style={{ display: 'flex' }}>

                    <div onClick={StartTour} style={{ textAlign: 'center', padding: '30px', marginLeft: '5px', marginRight: '5px', minWidth: '250px' }}>

                        <FontAwesomeIcon icon={icoTour} style={{ fontSize: '200px', color: '#76B0F7' }}></FontAwesomeIcon>
                        <h1 style={{ color: '#76B0F7' }}>{window._getText('TakeTour')}</h1>

                        <p>
                            {window._getText('QuickIntro')}
                        </p>

                        <FontAwesomeIcon icon={icoArrow} style={{ fontSize: '40px', color: '#76B000', transform: 'rotate(90deg)', position: 'relative', top: 16, left: -10 }}></FontAwesomeIcon>

                        <b style={{ fontSize: '20px' }} >
                            {window._getText('ClickHereToStart')}</b>
                    </div>
                    <div style={{ padding: '5px', marginLeft: '5px', marginRight: '5px' }}>
                        <div style={{ backgroundColor: 'rgba(255,255,255,0.3)', borderRadius: '8px', padding: '10px', margin: '10px' }}>
                            <h2>FAQ</h2>
                            <div style={{ overflow: 'auto', height: 'calc(100vh - 200px)' }}>
                                <div>
                                    <b>How do I change from Am Pm to 24h? </b><br />
                                    <p>
                                        You can change the time format in the top menu.<br />
                                    Preferences -> settings -> time
                                </p><br />
                                </div>

                                <div>
                                    <b>How can I copy a week schedule to a new week? </b><br />
                                    <p>
                                        Go to the week you want to copy
then in the top menu click edit -> copy schedule.<br></br>
Then navigate to the week you want to paste it in to and in the same menu click past schedule.<br></br>

                                    </p><br />
                                </div>


                                <div>
                                    <b>How can I add credits?</b><br />
                                    <p>
                                        Click billing in the top menu.<br />
Then add the select amount of credit you want to add in the dropdown.<br />
                                        <br />
When you are happy and sure you will continue using TimeTo.Work. It can be a good idea to add credits for a few months to avoid unwanted interruption.<br />

                                    </p><br />
                                </div>



                                <div>
                                    <b>How can I share the schedule with my staff? </b><br />
                                    <p>
                                        Make sure you have added the phone number to the person you want to share with. Then click the blue invite button on their contact card. on the staff tab<br></br>
                                        <br></br>
The phone number is needed for the mapping in the schedule. You can also use an email.<br></br>
But avoid using both at the same time,<br></br>

                                    If you want to share the app your self use this link <br /><br />
                                        <b onClick={copyText('https://timetowork.page.link/download')} >https://timetowork.page.link/download</b><br />
                                        (click the link to copy it)


                                    </p><br />
                                </div>



                                <div>
                                    <b>Why are my reports empty?</b><br />
                                    <p>
                                        The data in the reports is from the published schedules
                                        So even if you don't share your schedule with your staff you need to publish it.
                                    </p><br />
                                </div>


                                <div>
                                    <b>Why does TimeTo.Work cost money every month?</b><br />
                                    <p>
                                        Time To Work is more than just a schedule software <br />
                                        It's a staff handling system. Heres some of the features <br />
                                        * Mobile app  <br />
                                        * Real-time update to all staff  <br />
                                        * Time sheet and deviation reports  <br />
                                        * Staff to staff shift changes and Manager to staff shift requests  <br />
                                        * Staff can set availability and book open shift in the mobile app <br />
                                        * All your data is save safe in Google cloud  <br />
                                        * overtime warnings  <br />
                                        and more ...  <br /><br />

                                        We are doing several updates every month with new features.
                                    </p><br />
                                </div>



                                <div>



                                </div>

                            </div>



                        </div>

                    </div>
                </div>

            </div>
        </React.Fragment>
    )
}

