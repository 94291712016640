import React, { Component } from "react";
import { Button, Icon } from "../../../common/component/Button";
import { connect } from "react-redux";
import memoizeOne from 'memoize-one';
import array from "lodash/array";
import { toastr } from 'react-redux-toastr'
import { openModal, closeModal } from "../../../../core/redux/action/modalAction";
//import { registerUser } from "../../../redux/action/userAction";
import UserCard from "./UserCard";
import collection from "lodash/collection";
import UserSendInvitesModal from "../../modal/UserSendInvitesModal";
import UserBulkImport from "../../modal/UserBulkImport";
import { FireHelper } from '../../../../core/util/FireHelper'
//import { MobileView, BrowserView } from 'react-device-detect';
import Locked from "../../schedule/settings/Locked";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserFriends as icoPageHeader } from '@fortawesome/pro-duotone-svg-icons';
class Users extends Component {
  state = { searchText: "", cbShowOnlyActiveUsers: true };


  componentDidMount = () => {
    window.addEventListener('newuser', this.openEmptyModal);

  }

  componentWillUnmount = () => {
    window.removeEventListener('newuser', this.openEmptyModal);

  }
  toggleShowOnlyActiveUsers = () => this.setState({ cbShowOnlyActiveUsers: !this.state.cbShowOnlyActiveUsers })
  onOpenModal = key => () => {
    // console.log('open', key)
    this.props.onOpenModal('user', key);
  };
  onOpenModalSendInvite = () => {
    this.props.onOpenModal('userSendinvite');
  };
  onOpenModalBulkImport = () => {
    this.props.onOpenModal('userBulkImport');
  };
  // // onAddNewUser = name => () => {
  // //   {this.props.onOpenModal}

  // // };

  tbSearchChange = e => {
    this.setState({ searchText: e.target.value });
  };
  openEmptyModal = () => {
    this.props.onOpenModal('user');
  }
  sendMail = (mail) => {
    if (this.props.currentUser && this.props.currentUser.email === 'demo@timeto.work') {
      toastr.success('Demo account', `No saves are made`);
      console.log('Demo account = exit')
      return
    }

    if (this.props.currentUser)
      FireHelper.setInviteMail(this.props.project.key, mail, this.props.project.publicKey, this.props.project.name)
  }

  sendSms = (phone) => {
    if (this.props.currentUser && this.props.currentUser.email === 'demo@timeto.work') {
      toastr.success('Demo account', `No saves are made`);
      console.log('Demo account = exit')
      return
    }

    if (this.props.currentUser && phone) {
      this.setState({ isSendig: true })
      FireHelper.setInviteSms(this.props.project.key, { phone: phone, name: this.props.project.name }, this.props.project.publicKey, this.props.project.name)
      setTimeout(() => { this.setState({ isSendig: false }) }, 5000);
    }


  }

  styles = {
    meny: { border: 'none', borderBottom: 'solid 1px #f1f1f1', position: 'fixed', height: '50px', zIndex: '10' },
    input1: { width: '200px' },
    width160: { width: '125px' },
    s1: { paddingLeft: '5px', paddingRight: '5px' },
    s2: { border: 'none', },
  }

  getUsersCards = memoizeOne((users, search, active) => {
    //console.log('===>inside moize')
    let userCards = []
    if (users) {
      // var showOnlyActiveUsers = this.state.cbShowOnlyActiveUsers
      var searchList1 = collection.filter({ ...users }, (obj, key, x) => {
        if (!obj || !obj.key) return;
        //obj.key = key;// REMOVED , == error
        return (obj.name || '').toLowerCase().indexOf(search) > -1; //&& showOnlyActiveUsers === obj.isActive
      });
      var searchList2 = collection.filter({ ...users }, (obj, key, x) => {
        if (!obj || !obj.key) return;
        //obj.key = key;// REMOVED , == error
        return (obj.email || '').toLowerCase().indexOf(search) > -1; //&& showOnlyActiveUsers === obj.isActive
      });
      var searchList3 = collection.filter({ ...users }, (obj, key, x) => {
        if (!obj || !obj.key) return;
        //obj.key = key;// REMOVED , == error
        return (obj.phone || '').toLowerCase().indexOf(search) > -1; //&& showOnlyActiveUsers === obj.isActive
      });
      var searchList = array.uniq([...searchList1, ...searchList2, ...searchList3]) || []

      searchList = searchList.sort((a, b) => {

        if (!a.name || !b.name)
          return 0

        const aName = (a.name || '').toLowerCase()
        const bName = (b.name || '').toLowerCase()

        if (aName < bName) return -1
        if (aName > bName) return 1
        return 0
      })

      userCards = Object.keys(searchList).map(key => {
        if (searchList[key] == null) return null;
        return (
          <UserCard
            projectKey={this.props.projectKey}
            sendMail={this.sendMail}
            sendSms={this.sendSms}
            user={{ ...searchList[key] }}
            openModal={this.props.onOpenModal}
            key={key}
          />
        );
      });
    }
    return userCards
  })


  selectUser = (user) => () => {
    this.props.onOpenModal('user', user.key);
  }
  lastChar = ''
  listUser = (user, selectedUsers) => {
    // console.log('>>listUser')
    if (!user)
      return (null)

    const u = selectedUsers[user.key] || {}

    var firstChar = (user.name || '').substr(0, 1).toLowerCase()
    var divHeader = null

    if (firstChar !== this.lastChar) {
      this.lastChar = firstChar
      divHeader = (
        <div key={firstChar} style={{ textTransform: ' capitalize ', fontWeight: 'bold', paddingTop: '8px', paddingLeft: 5 }}>
          {firstChar}
        </div>
      )
    }


    return (
      <React.Fragment key={user.key}>
        {divHeader}
        <div className={'noSelect selectUserBtn ' + u.selected === true ? 'listItemSelected' : 'listItem'} onClick={this.selectUser(user)} key={user.key}
          style={{
            paddingLeft: 10
          }}>
          {/* <Image floated="left" style={{ width: '25px' }} size="mini" src={user.image ?
            user.image
            : noUserImage} circular
            onError={(e) => { e.target.onerror = null; e.target.src = noUserImage }}
          /> */}
          <Icon name={user.isActive === true ? 'user' : 'user outline'}></Icon>
          {user.name}

        </div>
      </React.Fragment>
    )
  }

  render() {
    const { users, project } = this.props;

    if (project && project.disabled === true)
      return <Locked />

    const search = (this.state.searchText || '').toLowerCase()

    const userCards = this.getUsersCards(users, search, this.state.cbShowOnlyActiveUsers);

    var arrUserlist = userCards.map(u => (u.props || {}).user)





    return (
      <React.Fragment>




        <div style={{ flexDirection: 'row', display: 'flex', height: '100%', width: '100%' }}>
          <div style={{ maxWidth: '250px', minWidth: '250px', }}>
            <div className='dragWindow2' style={{ paddingTop: '12px', paddingBottom: '12px', paddingLeft: '14px', paddingRight: '5px', color: 'rgba(0,0,0,0.65)', fontSize: '12px' }}>
              <h2 style={{ color: '#DEC015' }}><FontAwesomeIcon icon={icoPageHeader}></FontAwesomeIcon>  {window._getText('schedual.m.Staff')}</h2>
              <input
                className="form-control"
                icon="search"
                value={this.state.searchText}
                placeholder={window._getText('search')}
                onChange={this.tbSearchChange}
                style={{ height: '10px', marginRight: '8px' }}
              />




            </div>

            <div style={{ overflowY: 'auto', height: 'calc(100% - 133px)', position: 'relative', paddingLeft: 10 }}>

              {arrUserlist.map(u => this.listUser(u, users))}
            </div>

            <div style={{ flexGrow: '1' }}></div>
            <div style={{ height: '40px', padding: '6px', }}>
              {(this.props.currentUser.role > 100) &&


                <div style={{ display: 'flex' }}>
                  <Button className='onboardStep2 tour_staff_1' tooltip_t={window._getText("schedual.m.addNewUser")} compact icon='add user' style={{ marginRight: '8px', marginLeft: '8px' }} onClick={this.openEmptyModal} />

                  <div style={{ flexGrow: '1' }}></div>



                  <div className="btn-group" style={{ marginRight: '8px' }}>

                    <Button className='onboardStep3 tour_staff_2' tooltip_t={window._getText("user.bulkimport")} compact icon='download' onClick={this.onOpenModalBulkImport} />
                    <Button className='onboardStep4 tour_staff_3' tooltip_t={window._getText("user.inviteUnMaped")} compact icon='mail' onClick={this.onOpenModalSendInvite} />


                  </div>
                </div>
              }

            </div>
          </div>

          <div style={{ flexGrow: '1' }}  >

            <div className='dragWindow2' style={{ height: '50px', paddingTop: '12px', paddingBottom: '12px', paddingLeft: '14px', paddingRight: '5px', color: 'rgba(0,0,0,0.65)', display: 'flex', fontSize: '12px' }}>



              {/* 
              <Checkbox toggle label={window._getText('user.active')} onChange={this.toggleShowOnlyActiveUsers} checked={this.state.cbShowOnlyActiveUsers}></Checkbox> */}
              <div style={{ fontSize: '20px', fontWeight: 'bold', color: 'rgba(0,0,0,0.65)' }}>


              </div>
              {/* <Button tooltip_r={window._getText("schedual.m.addNewUser")} compact icon='add user' onClick={this.openEmptyModal} />
 */}


            </div>
            <div style={{ flexWrap: 'wrap', display: 'flex', overflowY: 'auto', overflowX: 'hidden', height: 'calc(100% - 50px)', padding: '6px', }}>
              {userCards}
            </div>
          </div>

        </div>


        <UserSendInvitesModal></UserSendInvitesModal>
        <UserBulkImport></UserBulkImport>
      </React.Fragment >
    );
  }
}
const mapActions = {
  onOpenModal: openModal,
  onCloseModal: closeModal

};
const mapStateToProps = state => {
  var userList = {};

  if (state.firestore && state.firestore.data && state.firestore.data.users) {
    userList = state.firestore.data.users.userlist;
  }

  return {
    projectKey: state.firebase.profile.projectKey,
    project: state.firestore.data.project,
    currentUser: state.firebase.profile,
    user: state.user,
    users: userList
  };
};
export default connect(
  mapStateToProps,
  mapActions
)(Users);
