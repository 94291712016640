import React, { Component } from "react";
import {
  Loader as ZZZ,
  Message as MMM,
  Input as III,
  Tab as TTT,
  Menu as MM,
  Label as LLL,
  Checkbox as CCC,
  Segment as SSS,
  Header as HHH,
  Container as CC,
  Dimmer as DD,
  Button as B3,
  Dropdown as D,
  List as L,
  Popup as P,
  TextArea as T,
  Divider as DDD,
} from "semantic-ui-react";
export class Divider extends Component {
  render() {
    const { ...rest } = this.props;

    return (
      <DDD {...rest}></DDD>
      // <button style={{ width: '100%', marginTop: '8px' }} className='ui compact icon button green' onClick={this.toggleDP} ><i aria-hidden="true" class="check icon"></i></button>
    );
  }
}
export class BButton extends Component {
  render() {
    const { ...rest } = this.props;

    return (
      <B3 {...rest}></B3>
      // <button style={{ width: '100%', marginTop: '8px' }} className='ui compact icon button green' onClick={this.toggleDP} ><i aria-hidden="true" class="check icon"></i></button>
    );
  }
}

export class TextArea extends Component {
  render() {
    const { ...rest } = this.props;

    return (
      <T {...rest}></T>
      // <button style={{ width: '100%', marginTop: '8px' }} className='ui compact icon button green' onClick={this.toggleDP} ><i aria-hidden="true" class="check icon"></i></button>
    );
  }
}

export class Popup extends Component {
  render() {
    const { ...rest } = this.props;

    return (
      <P {...rest}></P>
      // <button style={{ width: '100%', marginTop: '8px' }} className='ui compact icon button green' onClick={this.toggleDP} ><i aria-hidden="true" class="check icon"></i></button>
    );
  }
}

export class List extends Component {
  render() {
    const { ...rest } = this.props;

    return (
      <L {...rest}></L>
      // <button style={{ width: '100%', marginTop: '8px' }} className='ui compact icon button green' onClick={this.toggleDP} ><i aria-hidden="true" class="check icon"></i></button>
    );
  }
}

export class Dropdown extends Component {
  render() {
    const { ...rest } = this.props;

    return (
      <D {...rest}></D>
      // <button style={{ width: '100%', marginTop: '8px' }} className='ui compact icon button green' onClick={this.toggleDP} ><i aria-hidden="true" class="check icon"></i></button>
    );
  }
}

export class Image extends Component {
  render() {
    const {
      circular,
      size,
      floated,
      src,
      alt,
      className,
      ...rest
    } = this.props;

    return (
      <img
        src={src}
        alt={alt}
        {...rest}
        className={
          "ui " +
          (size || "") +
          " " +
          (circular ? "circular" : "") +
          " " +
          (floated ? "floated " + floated : "") +
          " image " +
          className
        }
      ></img>
    );
  }
}

export class Dimmer extends Component {
  render() {
    const { ...rest } = this.props;

    return (
      <DD {...rest}></DD>
      // <button style={{ width: '100%', marginTop: '8px' }} className='ui compact icon button green' onClick={this.toggleDP} ><i aria-hidden="true" class="check icon"></i></button>
    );
  }
}

export class Container extends Component {
  render() {
    const { ...rest } = this.props;

    return (
      <CC {...rest}></CC>
      // <button style={{ width: '100%', marginTop: '8px' }} className='ui compact icon button green' onClick={this.toggleDP} ><i aria-hidden="true" class="check icon"></i></button>
    );
  }
}

export class Header extends Component {
  render() {
    const { ...rest } = this.props;

    return (
      <HHH {...rest}></HHH>
      // <button style={{ width: '100%', marginTop: '8px' }} className='ui compact icon button green' onClick={this.toggleDP} ><i aria-hidden="true" class="check icon"></i></button>
    );
  }
}

export class Segment extends Component {
  render() {
    const { ...rest } = this.props;

    return (
      <SSS {...rest}></SSS>
      // <button style={{ width: '100%', marginTop: '8px' }} className='ui compact icon button green' onClick={this.toggleDP} ><i aria-hidden="true" class="check icon"></i></button>
    );
  }
}

export class Checkbox extends Component {
  render() {
    const { ...rest } = this.props;

    return (
      <CCC {...rest}></CCC>
      // <button style={{ width: '100%', marginTop: '8px' }} className='ui compact icon button green' onClick={this.toggleDP} ><i aria-hidden="true" class="check icon"></i></button>
    );
  }
}
export class Tab extends Component {
  render() {
    const { ...rest } = this.props;

    return (
      <TTT {...rest}></TTT>
      // <button style={{ width: '100%', marginTop: '8px' }} className='ui compact icon button green' onClick={this.toggleDP} ><i aria-hidden="true" class="check icon"></i></button>
    );
  }
}

export class Menu extends Component {
  render() {
    const { ...rest } = this.props;

    return (
      <MM {...rest}></MM>
      // <button style={{ width: '100%', marginTop: '8px' }} className='ui compact icon button green' onClick={this.toggleDP} ><i aria-hidden="true" class="check icon"></i></button>
    );
  }
}

export class Label extends Component {
  render() {
    const { ...rest } = this.props;

    return (
      <LLL {...rest}></LLL>
      // <button style={{ width: '100%', marginTop: '8px' }} className='ui compact icon button green' onClick={this.toggleDP} ><i aria-hidden="true" class="check icon"></i></button>
    );
  }
}

export class Button extends Component {
  render() {
    const {
      style,
      disabled,
      compact,
      positive,
      circular,
      loading,
      icon,
      content,
      color,
      className,
      ...rest
    } = this.props;

    return (
      // 'ui compact icon button '
      <button
        {...rest}
        className={"btn btn-default " + color + " " + className}
        style={{ ...style, color: disabled === true ? "silver" : "" }}
      >
        {icon && (
          <i
            aria-hidden="true"
            className={" icon " + icon}
            style={{
              margin: "0px",
              fontSize: "14px",

              marginRight: content ? "8px" : "auto",
              width: "14px",
              height: "14px",
              marginTop: "1px",
              marginBottom: " 1px",
              color: disabled === true ? "silver" : "#737475",

              lineHeight: "1",
            }}
          ></i>
        )}
        {content && content}
      </button>

      // <XXX {...rest}></XXX>
      // <button style={{ width: '100%', marginTop: '8px' }} className='ui compact icon button green' onClick={this.toggleDP} ><i aria-hidden="true" className="check icon"></i></button>
    );
  }
}

export class Button2 extends Component {
  render() {
    const {
      style,
      disabled,
      compact,
      positive,
      circular,
      loading,
      icon,
      content,
      color,
      className,
      ...rest
    } = this.props;

    return (
      // 'ui compact icon button '
      <button
        {...rest}
        className={"ui compact right floated button " + color + " " + className}
        style={{ ...style, color: disabled === true ? "silver" : "" }}
      >
        {icon && (
          <i
            aria-hidden="true"
            className={"icon " + icon}
            style={{
              margin: "0px",
              fontSize: "18px",

              marginRight: content ? "8px" : "auto",
              width: "18px",
              height: "18px",
              marginTop: "1px",
              marginBottom: " 1px",
              color: disabled === true ? "silver" : "#fff",

              lineHeight: "1",
            }}
          ></i>
        )}
        {content && content}
      </button>

      // <XXX {...rest}></XXX>
      // <button style={{ width: '100%', marginTop: '8px' }} className='ui compact icon button green' onClick={this.toggleDP} ><i aria-hidden="true" className="check icon"></i></button>
    );
  }
}
export class Icon extends Component {
  render() {
    const { name, ...rest } = this.props;

    return <i {...rest} aria-hidden="true" className={"icon " + name}></i>;
  }
}

export class Loader extends Component {
  render() {
    const { ...rest } = this.props;

    return <ZZZ {...rest}></ZZZ>;
  }
}

export class Message extends Component {
  render() {
    const { ...rest } = this.props;

    return <MMM {...rest}></MMM>;
  }
}
export class Form extends Component {
  render() {
    const { ...rest } = this.props;

    return <form {...rest}></form>;
  }
}
export class Input extends Component {
  render() {
    const { ...rest } = this.props;

    return <III {...rest}></III>;
  }
}
