import React, { Component } from "react";
import { Tab, Icon, Menu } from "../../../common/component/Button";

import Billing from './Billing'
import AppSettings from './AppSettings'
import { connect } from 'react-redux'


class Settings extends Component {
  panes = [
    { menuItem: <Menu.Item style={{ height: '46px' }} key='settings' ><Icon name='settings' />{window._getText('set.settings')}</Menu.Item>, render: () => <AppSettings /> },
    { menuItem: <Menu.Item style={{ height: '46px' }} key='payment' ><Icon name='payment' />{window._getText('set.billing')}</Menu.Item>, render: () => <Billing /> },
    // { menuItem: 'Tab 3', render: () => <Tab.Pane attached={false}>Tab 3 Content</Tab.Pane> },
  ]

  render() {
    const { project } = this.props
    return (

      <React.Fragment>
        <div style={{ zIndex: '200', position: 'fixed', top: '10px', right: '15px', textAlign: 'center' }} >{window._getText('credit')}: <span style={{ fontWeight: '900', fontSize: '30px', }}>{project && (project.credits || 0)}</span></div>
        <Tab menu={{ secondary: true, pointing: true }} panes={this.panes} className='fixedTabs' />

      </React.Fragment>

    );
  }
}



export default connect((state) => ({
  project: state.firestore.data.project,
}))(Settings)
