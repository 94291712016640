import React from 'react'
import { Button, Popup } from 'semantic-ui-react';


 
const InfoBoxCosts = () => {
    return (    
        <Popup
        wide='very'
            trigger={<Button circular icon='help' size='tiny' style={{ fontSize: 9, margin: 2 }} />}
        >
            <Popup.Header style={{color:'var(--color--text2)'}}>Staff costs</Popup.Header>
            <Popup.Content>
                <div style={{color:'var(--color--text2)'}}>
                    This is a preliminary overview of the scheduled cost. Other costs are not included like taxes, special days, and other changes.<br/>
                    (time * hour salery) = shift cost.<br/><br/>

                    To get started add an hour salary to your staff.<br/><br/>
                    Any questions or feedback please let us know by sending a support ticket. <br/><br/>
                </div>
            </Popup.Content>
        </Popup>
    )
}
export default InfoBoxCosts