module.exports = {

    'newTaskInfo': `Om du behöver en snabb rundtur, klicka på fliken "Hjälp" i menyn till vänster.
    Klicka sedan på "Ta en rundtur" och kom tillbaka till den här sidan.`,
    'Publish': `Publicera`,
    'schedual.btnPasteSchedule': `Klistra in schema`,
    'schedual.btnCopySchedule': `Kopiera schema`,
    'NewFeatuer': `Detta är en ny funktion.`,
    'LinkCopied': `länk kopieras`,
    'GoToWorkView': `Detta är det publicerade schemat
    Gå till arbetsvyn för att göra ändringar`,
    'QuickIntro': `Behöver du en snabb introduktion till hur den här appen fungerar?`,
    'Workview': `Arbetsvy`,
    'AddRow': `Lägg till rad`,
    'Published': `Publicerad`,
    'thisIsWatStaffSee': `Detta är vad din personal ser`,
    'Unpublish': `Avpublicera`,
    'TaskStartInfo': `Klicka på en uppgift eller skapa en ny genom att klicka på plusknappen i det övre högra hörnet`,
    'ClickHereToStart': `Tryck här för att starta`,
    'joy.viewWeeks': `Antal veckor att visa`,
    'tour.onboardStep_mainMenu': `Börja med att klicka på fliken du vill ha en rundtur i.`,
    'comefirmUnlinkUser': `Vill du ta bort länken till användaren?`,
    'draft': `Utkast, ej publicerat`,
    'Unpublishing': `Avpublicerar ...`,
    'AreYouSurePublish': `Är du säker på att du vill publicera schemat för all personal?`,
    'tour.tour_report_1': `Denna månad. Använd pilarna för att ändra månad.`,
    'tour.tour_chats_3': `Välj ett chattrum och skriv ditt meddelande här.`,
    'AdminCanAddScheduls': `Endast administratören kan lägga till nya scheman`,
    'PrintNoAbuseMsg': `Endast betalande kund kan använda utskriftsfunktionen
    (Detta för att undvika missbruk av systemet.)`,
    'tour.tour_schedule_9': `Detta är din arbetsvy, anställda kan inte se den här versionen av schemat.`,
    'CopyShift': `Kopiera skift`,
    'tour.tour_requests_2': `Det ursprungliga skiftet från ditt publicerade schema.`,
    'ClearSchedule': `Vill du rensa det här schemat?`,
    'tour.tour_schedule_6': `Klicka på ett skift för att redigera det eller dra det för att flytta det.
    Om du håller ner alternativet när du drar kommer skiftet att klonas.`,
    'tour.tour_staff_3': `Klicka här för att skicka inbjudningsmail till alla oinbjudna användare på en gång.`,
    'PleaseAddCreditsBeforePrinting': `Lägg till poäng innan du skriver ut`,
    'tour.tour_staff_1': `Klicka här för att lägga till en ny personalmedlem`,
    'AddDivider': `Lägg till avdelare`,
    'WantToWork': `Vill jobba`,
    'tour.tour_requests_5': `Ändringarna nekades och inga ändringar görs.`,
    'tour.tour_report_3': `Det här är tidtabellen
    Här kan du se arbetade timmar.
    All data är från det publicerade schemat.
    sväva en rad och klicka sedan på pennan för att redigera skiftet.`,
    'CreateShiftRequest': `Skapa skiftförfrågan`,
    'tour.tour_chats_2': `Som administratör kan du lägga till nya chattrum.`,
    'tour.tour_schedule_10': `Detta är att dina användare ska se i mobilappen.
    Detta är också uppgifterna i rapporterna.`,
    'tour.tour_schedule_6_1': `Klicka på användaren för att kopiera en rad eller visa användaren.`,
    'tour.tour_schedule_5': `Du kan också dubbelklicka på en dag för att lägga till ett skift
    (Se till att du har minst ett skift i schemat innan du går vidare till nästa steg)`,
    'TakeTour': `Ta en produktrundtur`,
    'tour.tour_report_2': `Välj en användare och klicka sedan på nästa.
    Om öppettiderna är 0: 00h måste du publicera ditt schema.
    All information här är från det publicerade schemat.`,
    'Mo': `Mo`,
    'Fr': `Fr`,
    'tour.tour_staff_2': `Klicka här för att lägga till flera medlemmar samtidigt`,
    'login.demo': `Demo`,
    'NewVersion': `En ny version finns tillgänglig, vill du uppdatera nu?`,
    'Th': `Th`,
    'Askforthisshift': `Be om det här skiftet`,
    'tour.tour_schedule_8': `Om du vill ta bort det publicerade schemat kan du göra det genom att klicka här.`,
    'tour.tour_requests_3': `Det nya skiftet som personalen vill byta till ...`,
    'tour.tour_schedule_3': `Eller använd den här veckoväljaren för att gå till en viss vecka.`,
    'tour.tour_today_2': `Klicka här om du har överlappande skift.`,
    'tour.tour_requests_empty': `Se till att du har minst en begäran innan du tar turen.
    
    Klicka till exempel på ett skift i schemat och högerklicka sedan på "Skapa skiftförfrågan"
    `,
    'ThereIsNoPubSchedule': `Det finns inget publicerat schema den här månaden.`,
    'tour.tour_staff_4': `Klicka här för att se eller redigera en anställd`,
    'PasteShift': `Klistra in skift`,
    'TaskRestarted': `Uppgiften startas om och flyttas till fliken Aktiv.`,
    'NewShift': `Nytt skift`,
    'TaskIsRemoved': `Uppgiften tas bort.`,
    'DeleteShift': `Ta bort skift`,
    'shiftIsCloned': `Skift är klonat`,
    'TaskIsPublished': `Uppgiften publiceras.`,
    'tour.tour_schedule_2': `Aktuell vecka, använd pilarna för att flytta till nästa / föregående vecka.`,
    'schedual.m.Tour': `Hjälp`,
    'MovetToArchive': `Uppgiften flyttas till Arkiv.`,
    'tour.tour_schedule_4': `Klicka här för att lägga till en användare eller ett skift
    (Om du inte har några användare i ditt schema, lägg till en nu)`,
    'tour.tour_today_1': `Så här ser dagens dag ut.
    Uppgifterna kommer från det publicerade schemat.`,
    'PrivateNotes': `Privata anteckningar`,
    'minutes': `minuter`,
    'Available': `Tillgängliga`,
    'DemoAccount': `Demokonto, klicka här för att logga ut och starta ditt eget konto.`,
    'AddASchedule': `Lägg till ett schema för att komma igång.`,
    'AreYouSureDeleteTask': `Är du säker på att du vill ta bort den här uppgiften`,
    'AddStaff': `Lägg till personal`,
    'tour.tour_schedule_7': `När du är nöjd med ditt schema och vill dela det med din personal.
    Klicka här för att publicera.`,
    'tour.tour_requests_1': `Det här är inkorg och utkorg för dina önskemål.`,
    'Vacation': `Semester`,
    'Sa': `Till`,
    'RequestIsSent': `Förfrågan skickad`,
    'DataComesFromPubSche': `(Data som visas här kommer från de publicerade scheman)`,
    'MovetToActive': `Uppgiften flyttas till Aktiv.`,
    'addMoreBeforPrint': `Lägg till fler poäng innan du skriver ut`,
    'StartByAddSchedule': `Börja med att lägga till namnet på ditt huvudschema.`,
    'We': `Vi`,
    'tour.tour_staff_5': `Klicka här för att bjuda in användaren via post eller SMS.`,
    'Tu': `din`,
    'schedual.m.Tasks': `Uppgifter`,
    'YouCanOnlyPrint4week': `Du kan bara skriva ut fyra veckor framåt`,
    'tour.tour_requests_4': `Godkänn användarförfrågan
    Detta skift kommer att uppdateras i ditt publicerade schema.`,
    'tour.tour_chats_1': `Det här är din chattlista.
    Allt är en chattgrupp som inte kan tas bort för alla anställda.
    Logg är en logglista över dina förfrågningar / svar.`,
    'ScheduleIsCopied': `Schemat kopieras`,
    'PleaseRestart': `Starta om din app för att inställningarna ska gälla.`,
    'tour.tour_schedule_1': `Schemat.
    Klicka på kugghjulet för att ändra ett namn eller ta bort schemat.
    (Se till att du har minst en schemaanvändare i schemat innan du fortsätter)`,
    'Su': `hans`,
    'TaskIsUnPublished': `Uppgiften är opublicerad.`,
    'tour.tour_report_4': `Klicka här för att exportera en xlsx-rapport.
    Om du vill ladda ner alla rapporter i ett ark använder du toppmenyn.
    Arkiv -> Exportera -> Exportera som xlsx.`,
    'CanNotWork': `Kan inte fungera`,
    'login.err.2': `Lösenordet är ogiltigt.`,
    'createNewAccount.info': `Använd telefonnummer om du blev inbjuden via SMS eller e-post om du fick inbjudan via e-post`,
    'createNewAccount.smscodeTitle': `Kod från textmeddelandet`,
    'login.err.1': `Det finns ingen användare som motsvarar den här e-postadressen. Användaren kan ha tagits bort.`,
    'login.err.4': `Kontot har inaktiverats av en administratör.`,
    'createNewAccount.newaccount': `Nytt personalkonto`,
    'login.btnLogin': `Logga in`,
    'login.err.3': `E-postadressen är dåligt formaterad.`,
    'login.err.6': `Detta är inte ett giltigt telefonnummer. Du måste börja med din landskod.`,
    'createNewAccount.newSchedule': `Nytt schema`,
    'login.err.5': `Lösenordet måste innehålla 6 tecken eller mer.`,
    'createNewAccount.teleOrEmail': `Telefonnummer eller e-post`,
    'createNewAccount.setPassword': `Ange nytt lösenord`,
    'createNewAccount.smscode': `Kod från textmeddelandet`,
    'createNewAccount.login': `Logga in`,
    'or': `eller`,
    'set.settings.btn.dev': `Avvikelser`,
    'createNewAccount.dontHavePassword': `Har du inte ett lösenord?`,
    'set.settings.time': `Tid`,
    'set.settings.timeWarning': `När du ändrar dig måste du spara dina schemaläggningsdata för att förnya gamla cachade tidsetiketter`,
    'set.settings.btn.com': `Kommentarer`,
    'set.settings.publickey': `Företagsnyckel`,
    'set.settings.auto': `Godkänn ändringar av användarskift automatiskt`,
    'set.settings.btn.sMov': `Flytta skift`,
    'set.settings.publickeytext': `Det här är nyckeln som dina anställda behöver när de skapar ett nytt konto`,
    'set.settings.btn.sCan': `Ta bort skift`,
    'createNewAccount.comName': `Företagsnamn`,
    'SCHEDULES': `SCHEMAN`,
    'on': `På`,
    'createNewAccount.weekstart': `Veckan börjar med`,
    'createNewAccount.secretKey': `Företagsnyckel`,
    'import': `Importera`,
    'signout': `Logga ut`,
    'createNewAccount.comDisplayName': `Företagets visningsnamn`,
    'createNewAccount.adminAccount': `Administratörskonto`,
    'January': `Januari`,
    'June': `Juni`,
    'March': `Mars`,
    'time': `Tid`,
    'uploading_image': `Överför bild ...`,
    'December': `December`,
    'shifts': `skift`,
    'inactive': `Inaktiv`,
    'week': `Vecka`,
    'February': `Februari`,
    'schedule': `Schema`,
    'phonenumber': `Telefonnummer`,
    'h': `h`,
    'message.out': `Ut`,
    'oops': `hoppsan!`,
    'update': `Uppdatering`,
    'save': `Spara`,
    'total': `Total`,
    'Apr': `Apr`,
    'users.infoText': `Dessa används för att ansluta din personal till en användare i schemat, du behöver bara ställa in en av dem.`,
    'schedual.m.addNewUser': `Lägg till ny anställd`,
    'schedual.lbl-unassigned': `Otilldelad`,
    'logginin': `Loggar in...`,
    'back': `tillbaka`,
    'upload': `Ladda upp`,
    'August': `Augusti`,
    'tooltip.AddANewChat': `Lägg till en ny chatt`,
    'active': `Aktiva`,
    'saturday': `Lördag`,
    'average': `Genomsnitt`,
    'September': `September`,
    'break': `Rast`,
    'billing.nextInvoice': `Nästa faktura`,
    'billing.msgBody': `Lägg till fler poäng för att fortsätta använda systemet`,
    'set.settings.btn.sik': `Sjukskriven`,
    'AreYouSureYouWantToQuitYes': `Ja`,
    'terms': `Villkor`,
    'setup.creatingCompany': `Skapar företagskonto ...`,
    'setup.notValidKey': `Detta är inte en giltig företagsnyckel`,
    'user.active': `Aktiva`,
    'users.linked': `Länkad`,
    'users.uniquephone': `Telefonen måste vara unik`,
    'schedual.addS': `Lägg till nytt schema`,
    'schedual.m.addDiv': `Lägg till användaravdelare`,
    'locked.txt2': `Oroa dig inte, det är en enkel lösning, lägg till fler poäng och om några minuter kommer det att aktiveras igen`,
    'setup.comKey': `Företagsnyckel`,
    'setup.createNewAccount': `Skapa ett nytt användarkonto`,
    'setup.updateKey': `Uppdateringsnyckel`,
    'setup.retry': `Försök igen`,
    'createNewAccount.youneedCompanyName': `Du måste ha ett företagsnamn`,
    'createNewAccount.theKey': `Nyckeln du fick från din chef`,
    'users.infoText1': `Den här användaren är länkad till en anställd, klicka på låset för att ta bort länken.`,
    'Oct': `Okt`,
    'search': `Sök...`,
    'new': `Ny`,
    'tuesday': `Tisdag`,
    'date': `Datum`,
    'users': `användare`,
    'October': `Oktober`,
    'hours': `Timmar`,
    'rotateimage': `Rotera bilden`,
    'login.lostpass': `Glömt ditt lösenord?`,
    'tooltip.Deny': `Förneka`,
    'phone': `Telefon`,
    'add': `Lägg till`,
    'monday': `Måndag`,
    'sick': `Sjuk`,
    'name': `namn`,
    'message.empty': `Du har inga förfrågningar.`,
    'cellphone': `Mobiltelefon`,
    'create': `Skapa`,
    'picture': `Bild`,
    'Dec': `Dec`,
    'delete': `Radera`,
    'message.out.empty': `Du har inga väntande begäranden.`,
    'send': `Skicka`,
    'wednesday': `Onsdag`,
    'Sep': `Sep`,
    'Jul': `Jul`,
    'Jun': `Juni`,
    'login.newUser': `Nytt personalkonto`,
    'message.chat': `Chatt`,
    'logginout': `Loggar ut...`,
    'sunday': `Söndag`,
    'wShort': `i.`,
    'Aug': `Aug`,
    'Mar': `Mar`,
    'yes': `Ja`,
    'now': `Nu`,
    'login.forEmp': `För den anställde`,
    'login.newSchedule': `Nytt schemakonto`,
    'login.titel': `Logga in`,
    'tooltip.Cancel': `Avbryt`,
    'password': `Lösenord`,
    'type': `Typ`,
    'Feb': `Feb`,
    'off': `Av`,
    'login.titleNewAccount': `Nytt konto`,
    'login.sendpass': `Skicka nytt lösenord`,
    'loading': `Läser in...`,
    'cancel': `Avbryt`,
    'thursday': `Torsdag`,
    'May': `Maj`,
    'November': `November`,
    'April': `April`,
    'billing.payDetails': `betalningsinformation`,
    'report.h_time': `Tid`,
    'request.titleChange': `Ändra tid`,
    'billing.msgHead2': `annars låses ditt konto automatiskt`,
    'shiftuserinput.title4': `Markera som sjuk`,
    'billing.infoTxt2': `efter det kommer din åtkomst att inaktiveras tills du lägger till mer kredit`,
    'appdiv.pushmessages': `Push-meddelanden`,
    'wallet.txtWeb2': `även om du bara använder TimeTo.Work på webben måste du göra betalningarna genom en av butikerna. Ladda ner appen från en av butikerna så ser du en köpkreditknapp i appen här`,
    'request.titleComment': `Kommentar`,
    'set.settings.btn.openshifts': `Bokskift`,
    'request.adminRequest': `Begäran`,
    'userhome.youarefree': `Du är fri att göra vad du vill den här veckan`,
    'report.h_workedhours': `Arbetade timmar`,
    'editdivider.title': `Gruppdelare`,
    'request.titleSwap': `Byt skift`,
    'shiftuserinput.title5': `Begär skift`,
    'wallet.purchasing': ` `,
    'shiftuserinput.btn2': `Be om en förändring`,
    'report.h_date': `Datum`,
    'shiftuserinput.btn3': `Lägg till en kommentar`,
    'report.downloadall': `Ladda ner allt`,
    'selectuser.title': `Användarlista`,
    'billing.infoTxt3': `Om du inte vill fortsätta använda ditt schema kan du bara hoppa över att lägga till nya krediter, efter 7 dagar låses ditt konto och efter 360 dagar raderas det`,
    'usernotmapped_txt1': `Du är inte mappad till en användare i schemat`,
    'schedual.adminrequest': `Administratör`,
    'wallet.waiting_for_validation': `Väntar på validering.`,
    'usernotmapped_title': `Viktig!`,
    'report.downloadExcel': `Ladda ner Excel`,
    'appdiv.changepass': `ändra lösenord`,
    'shiftuserinput.btn6': `Justera tiden`,
    'appmenu.user': `Användare`,
    'wallet.txtWeb1': `För närvarande stöder vi bara att köpa ny kredit genom Google Play eller iOS App Store`,
    'billing.msgHead1': `Lägg till mer kredit innan`,
    'appmenu.changepass': `ändra lösenord`,
    'wallet.deferred': `Köp av har skjutits upp.`,
    'request.titleUserrequestshift': `Skiftförfrågan`,
    'set.settings.btn.sSwp': `Byt skift`,
    'wallet.success': `Framgång.`,
    'wallet.failed': `Det gick inte att köpa.`,
    'schedual.btnAddUser': `Lägg till personal`,
    'shiftuserinput.swapTitle1': `Välj användare att byta med`,
    'shiftuserinput.usercomment': `Användarkommentar`,
    'shiftuserinput.btn7': `Tilldela skift till en annan användare`,
    'report.h_break': `Rast`,
    'billing.infoTxt1': `Var 30: e dag kontrollerar vi de använda krediterna kontra dina befintliga krediter. Om du har låg kredit har du 7 dagar på dig att lägga till fler krediter`,
    'billing.msgBodyDays': `dagar`,
    'wallet.faild': `kontakta support om du har några frågor`,
    'shiftuserinput.title2': `Be om en förändring`,
    'report.h_sik': `Sjuk`,
    'report.h_hours': `Timmar`,
    'report.noscheduled': `Inga schemalagda öppettider den här månaden`,
    'schedual.btnAddShift': `Lägg till skift`,
    'wallet.failed1': `kontakta support om du har några frågor`,
    'wallet.pleasewait': `Vänta`,
    'shiftuserinput.addCommentToUser': `Lägg till kommentar till användaren`,
    'shiftuserinput.btn5': `Avbryt skift`,
    'shiftuserinput.title0': `Uppdatera skift`,
    'appmenu.pushmessages': `Pushnotifikationer`,
    'wallet.title1': `Köp mer kredit`,
    'WeCanNotMatchYou': `Vi kan inte matcha din e-post till en användare. Be din chef att lägga till din e-post till en användare i schemat`,
    'settings.title': `Inställningar`,
    'and': `och`,
    'setup.yesImAManger': `Ja, jag är chef`,
    'settings.account': `konto`,
    'Help': `Hjälp`,
    'dontShowThis': `Visa inte detta vid start`,
    'IncomingRequests': `Inkommande förfrågningar`,
    'Preferences...': `Inställningar ...`,
    'billing.msgLink': `Gå till TimeTo.Work -> Inställningar -> Fakturering. I toppmenyn`,
    'policy': `Integritetspolicy`,
    'joy.welcome': `Välkommen!`,
    'joy.Last': `Sista`,
    'log': `Logga`,
    'EditChat': `Redigera chatt`,
    'joy.startbyaddingusers': `Börja med att lägga till några anställda`,
    'Toggle menu bar icon': `Växla menyradens ikon`,
    'AddMoreCredits': `Lägg till fler poäng`,
    'Paste schedule': `Klistra in schema`,
    'Staff': `Personal`,
    'Signout': `Logga ut`,
    'settings.billing': `Fakturering`,
    'Hide TimeTo.Work': `Dölj TimeTo.Work`,
    'Quit': `Sluta med`,
    'Copy': `Kopiera`,
    'joy.clickHereToAddMultipleUsers': `Här kan du lägga till flera anställda samtidigt`,
    'Reports as XLSX': `Rapporter som XLSX`,
    'Clear all data': `Rensa all data`,
    'Edit': `Redigera`,
    'OrAreYouAManger': `Eller är du chef och vill skapa ett nytt personalplan?`,
    'companyNameIsNotValid': `Företagsnamnet är inte giltigt`,
    'a minute ago': `för en minut sedan`,
    'About TimeTo.Work': `Om TimeTo.Work`,
    'OutgoingRequests': `Utgående förfrågningar`,
    'joy.clickHereToAddAShift': `Klicka här för att lägga till ett skift`,
    'settings.settings': `inställningar`,
    'AreYouSureYouWantToQuitTitle': `Bekräfta avsluta`,
    'joy.Back': `Tillbaka`,
    'window': `Dags att jobba`,
    'How do i ...': `Hur gör jag ...`,
    'Divider': `Delare`,
    'byclicking': `Genom att klicka på "Skapa" godkänner du`,
    'all': `Allt`,
    'joy.goToSchedule': `gå till schemat för att börja schemalägga`,
    'Saved': `Sparad`,
    'an hour ago': `en timme sedan`,
    'joy.whenYourDone': `När du är klar med att lägga till anställda,`,
    'Shift': `Flytta`,
    'TotalBreakTime': `Total paustid`,
    'shortIntro': `Detta är en kort introduktion för att få dig snabbare med hur TimeTo.work fungerar`,
    'a day ago': `en dag sen`,
    'minutes ago': `minuter sedan`,
    'Window': `Fönster`,
    'Clear schedule': `Tydligt schema`,
    'Copy schedule': `Kopiera schema`,
    'New schedule': `Nytt schema`,
    'Hide Others': `Dölj andra`,
    'Cut': `Skära`,
    'RestorePreviousPurchase': `Återställ tidigare köp`,
    'currentPrice': `1 användare i 30 dagar = 1 kredit`,
    'AreYouSureYouWantToQuitNo': `Nej`,
    'WelcomeTo': `Välkommen till TimeTo.Work`,
    'joy.addMultipleRows': `Här kan du lägga till flera rader samtidigt`,
    'joy.Next': `Nästa`,
    'Prev': `Föregående`,
    'Next': `Nästa`,
    'Export': `Exportera`,
    'New staff': `Ny personal`,
    'AreYouSureYouWantToQuit': `Är du säker på att du vill sluta?

Du kommer att förlora alla opublicerade data.`,
    'zoom': `zoom`,
    'joy.dontForgetToSave': `Glöm inte att spara och publicera ditt schema när du är klar`,
    'users.invalidphone2': `Du måste lägga till landskoden före numret`,
    'users.notemail': `Detta är inte ett giltigt e-postmeddelande`,
    'users.staffmembers': `Personal`,
    'schedual.addSName': `Schema namn`,
    'schedual.editS': `Redigera scheman`,
    'schedual.m.tools': `Verktyg`,
    'schedual.m.Reports': `Rapporter`,
    'schedual.m.Request': `Begäran`,
    'schedual.m.Today': `I dag`,
    'schedual.emptyTxt1': `Använd sekundärt klick för att lägga till personal eller skift.`,
    'schedual.emptyTxt2': ` `,
    'createNewAccount.ampmOr24h': `Tidsformat`,
    'createNewAccount.termsApprove': `Du måste godkänna villkoren`,
    'createNewAccount.terms': `Jag godkänner de allmänna villkoren`,
    'createNewAccount.useSameEmail': `Använd samma e-postmeddelande som du fick i inbjudan. Så vi kan mappa dig till en användare i schemat`,
    'set.billing': `Fakturering`,
    'user.allLinked': `Alla användare är länkade`,
    'user.unlockEmail': `Lås upp e-post`,
    'users.uniqueemail': `E-post måste vara unik`,
    'schedual.m.pasteS': `Klistra in schema`,
    'schedual.m.addNewSchedule': `Lägg till nytt schema`,
    'schedual.m.Staff': `Personal`,
    'schedual.m.Schedule': `Schema`,
    'schedual.last': `Senast uppdaterad`,
    'locked.txt1': `Ditt konto är tillfälligt låst på grund av brist på kredit.`,
    'locked.title': `Ditt konto är låst!`,
    'setup.needSetup': `Vi har skapat ditt konto framgångsrikt men vi måste ansluta det till ett företag`,
    'createNewAccount.youneedkey': `Du måste ha en företagsnyckel`,
    'createNewAccount.emailAndPass': `E-post och lösenord`,
    'set.settings': `inställningar`,
    'user.bulkTitle': `Infoga en kommaseparerad lista med användare`,
    'users.delete': `Om du tar bort användaren kommer den att raderas permanent!
    Och all historisk data kommer att markeras med 'Okänd användare'
Ibland kan det vara bättre att bara markera användaren som inaktiv.`,
    'users.dname': `Visningsnamn`,
    'users.newuser': `Ny`,
    'schedual.deleteBtn2': `Bekräfta permanent radering`,
    'schedual.deleteWarning': `Om du tar bort schemat raderas det permanent och all historisk data till det`,
    'schedual.deleteBtn1': `Ta bort schema`,
    'schedual.m.addOpenShifts': `Lägg till 'öppna skift'`,
    'schedual.m.print': `Skriva ut`,
    'schedual.m.Chat': `Chatt`,
    'schedual.openshifts': `Öppna skift`,
    'locked.link': `Klicka här för att få fler poäng`,
    'schedual.dropdelete': `Släpp skift / användare här för att ta bort det!`,
    'setup.mappingUser': `Matchar användare till ett schema ...`,
    'user.inviteUnMaped': `Bjud in icke länkade användare`,
    'set.settings.title': `Systeminställningar`,
    'user.bulkTxt': `En användare per rad. Separera namn och nummer / post med kommatecken`,
    'users.deleteBtn1': `Radera användare`,
    'users.uniqueemail2': `Denna e-post kan inte användas`,
    'schedual.m.undo': `Ångra alla ändringar`,
    'schedual.m.copyS': `Kopiera schema`,
    'schedual.m.Home': `Hem`,
    'schedual.emptyTitle': `Ditt schema är tomt!`,
    'setup.wrongKey': `Företagsnyckeln saknas eller är fel`,
    'user.bulkimport': `Massimport`,
    'users.deleteBtn2': `Bekräfta permanent radering`,
    'schedual.m.clear': `Rensa alla skift`,
    'users.invalidphone': `Detta är inte ett giltigt mobilnummer`,
    'user.bulkTxt2': `Visningsnamn, e-post eller telefon
John Doe, +1123456789 eller Jane Doe, jane@timeto.work`,
    'schedual.lbl-openshifts': `Öppna skift`,
    'schedual.addSFirstTitle': `Lägg till nytt schema`,
    'login.sentResetMail': `Vi har skickat en återställningslänk till din e-post.`,
    'message.in': `I`,
    'email': `E-post`,
    'login.forCom': `För företaget`,
    'message.in.empty': `Du har inga förfrågningar.`,
    'credit': `Krediter`,
    'Jan': `Jan`,
    'invites': `Inbjudan`,
    'July': `Juli`,
    'Nov': `Nov`,
    'validphone': `+ landskod och mobilnummer`,
    'friday': `fredag`,
    'tooltip.Approve': `Godkänna`,
    'no': `Nej`,
    'calendar.empty': `Det finns inget schema den här veckan`,
    'schedual.btnHelp': `Hjälp`,
    'schedual.btnAddDiv': `Lägg till avdelare`,
    'schedual.btnPublish': `Spara och publicera`,
    'wallet.restored': `Inköpet av har återställts.`,
    'wallet.success1': `krediterna kommer inom kort till ditt konto`,
    'request.titleSick': `Sjuk`,
    'request.titleTime': `Tidsavvikelse`,
    'request.titleMove': `Flytta skift`,
    'request.titleCancle': `Avbryt skift`,
    'shiftuserinput.cancelation': `Orsak till avbokning`,
    'shiftuserinput.swapTitle3': `Välj Skift`,
    'shiftuserinput.swapTitle2': `Välj vecka`,
    'shiftuserinput.comment': `Kommentar`,
    'shiftuserinput.btn8': `Byt skift med en annan användare`,
    'shiftuserinput.btn4': `Markera som sjuk`,
    'shiftuserinput.btn1': `Rapportera tidsavvikelse`,
    'shiftuserinput.title3': `Lägg till en kommentar`,
    'shiftuserinput.title1': `Faktisk arbetstid`,
    'joy.Close': `Stänga`,
    'front': `Ta alla fram`,
    'minimize': `minimera`,
    'Quick introduction': `Snabb introduktion`,
    'Schedule as PDF': `Schema som PDF`,
    'New chat': `Ny chatt`,
    'Select All': `Välj alla`,
    'services': `Tjänster`,
    'years ago': `för flera år sedan`,
    'months ago': `månader sedan`,
    'days ago': `dagar sedan`,
    'hours ago': `timmar sedan`,
    'a few seconds ago': `några sekunder sedan`,
    'joy.clickonstaff': `Klicka på 'Personal'`,
    'a year ago': `ett år sedan`,
    'a month ago': `en månad sedan`,
    'seconds ago': `för några sekunder sedan`,
    'joy.orClickOnTheSchedule': `Eller så kan du dubbelklicka på schemat`,
    'joy.clickHerToAddUsers': `Klicka här för att lägga till en eller två anställda`,
    'joy.Skip': `Hoppa över handledning`,
    'Undo all changes': `Ångra alla ändringar`,
    'Add to schedule': `Lägg till i schemat`,
    'File': `Fil`,
    'selectuser.warning': `Välj minst en användare att lägga till i schemat`,
    'usernotmapped_txt2': `Be din schemanadministratör att lägga till din e-postadress`,
    'report.h_schedal': `Schema`,
    'report.h_presence': `Närvaro`,
    'billing.bHistory': `Faktureringshistorik`,
    'billing.msgHead': `Ditt konto har inte tillräckligt med krediter.`,
    'set.settings.btn.sCha': `Ändra tid`,
    'Show All': `Visa allt`,
    'Paste': `Klistra`,
    'userCredits': `användarkrediter`,
    'ShiftLabel': `Skiftetikett`,

}

