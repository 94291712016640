import React, { Component, Suspense } from "react";
import ReactSvgPieChart from "react-svg-piechart"

import { Image, Icon, Loader } from "../../common/component/Button";
import { openEventModal } from "../../../core/redux/action/modalAction";

import { connect } from 'react-redux'
import { time24hToAmPm, getUserImage, getDateKey, getLocalDateStr2, getTimeFromMins } from '../../../core/util/helpers'
import FirestoreListnerHelper from '../../../core/util/FirestoreListnerHelper'
import EditEventModal from "../modal/EditEventModal";

import Locked from "../schedule/settings/Locked";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChartBar as icoPageHeader } from '@fortawesome/pro-duotone-svg-icons';
import Table from "../../common/component/Tabel";
import noUserImage from '../../../img/noimg.png'
import moment from 'moment';
import moize from "moize";
import _Math from "lodash/math";
// import DownloadHoursExcel from '../../common/component/DownloadHoursExcel'
// import DownloadHoursExcelAll from '../../common/component/DownloadHoursExcelAll'

import { DownloadHoursExcel, DownloadHoursExcelAll } from '../router/Loader'//

import { isElectron } from 'react-device-detect';

let ipcRenderer

if (isElectron) {
  ipcRenderer = window.require('electron').ipcRenderer
}

// const DownloadHoursExcelAll = React.lazy(() => import(/*
//   webpackChunkName: "report",
//   webpackPrefetch: true
// */ '../../common/component/DownloadHoursExcelAll'))

// const DownloadHoursExcel = React.lazy(() => import(/*
//   webpackChunkName: "report",
//   webpackPrefetch: true
// */ '../../common/component/DownloadHoursExcel'))




// const getSum = moize((userEvents) => {
//   if (userEvents) {
//     var minutes2 = math.sumBy(userEvents, function (o) {
//       if (o.removed)
//         return 0
//       if (!o.minutes)
//         return 0
//       return Number(o.minutes)
//     });
//     // console.log('redner cal',getTimeFromMins(minutes2))
//     return getTimeFromMins(minutes2);
//     //this.setState({ minutes: getTimeFromMins(minutes2) })
//   }
// })
const flatMap = moize.deep((scheduleWeekData, projectKey, cache, month, AmPm, firstDay) => {

  let users = {}
  for (const scheduleWeekDataItem of scheduleWeekData) {
    const dataArray = scheduleWeekDataItem.data
    const rDate = scheduleWeekDataItem.date
    for (const shedualKey of Object.keys(dataArray)) {
      const schedual = dataArray[shedualKey]
      if (schedual && schedual.rows)
        for (const row of schedual.rows) {
          if (!users[row.k])
            users[row.k] = []

          var arr = Object.keys(row.s)//.sort()
          for (const shiftKey of arr) {

            const shift = row.s[shiftKey]

            var dayIndex = 0

            if (firstDay === 0)
              dayIndex = shiftKey === '7' ? '0' : shiftKey
            else
              dayIndex = shiftKey - 1 // === '7' ? '0' : shiftKey
            // dayIndex = dayIndex - firstDay

            const newDate = rDate.clone().add(dayIndex, 'days') //removed -1 
            const date = newDate.format('YYYY-MM-DD')
            const dStr = getLocalDateStr2(newDate)


            if (newDate.month() === month && Array.isArray(shift)) {
              for (const shiftItem of shift) {

                var time = ''
                var breakx = getTimeFromMins(shiftItem.b)
                var hours = getTimeFromMins(shiftItem.m)


                var scheduleName = (cache[shedualKey] || {}).n || '#unknown'
                var sik = shiftItem.sik === true ? true : false
                if (AmPm) {
                  time = time24hToAmPm(shiftItem.t1) + '-' + time24hToAmPm(shiftItem.t2)
                }
                else {
                  time = shiftItem.t1 + ' - ' + shiftItem.t2
                }

                const newShiftdata = { ...shiftItem, lbl: shiftItem.l, date, shedualKey, dStr, time, break: breakx, hours, scheduleName, sik }
                users[row.k].push(newShiftdata)
              }
            }
          }

        }

    }
  }

  var newUserList = []
  for (const userKey of Object.keys(users)) {
    if ((userKey !== 'openshifts' && userKey !== 'unassigned') && !userKey.startsWith('div_')) {
      const userData = users[userKey]
      const userInfo = cache[userKey]
      const mins = _Math.sumBy(userData, function (o) {
        //if(o.sick)
        return o.m || 0
      })

      // if (userKey === 'cjoqevtbf00033r5xvjxcwpwu')
      //   console.log('!userInfo', userInfo)



      if (userInfo)
        newUserList.push({
          hours: getTimeFromMins(mins),
          image: userInfo.i ? getUserImage(projectKey, userKey, userInfo.i) : noUserImage,
          name: userInfo.n,
          key: userKey,
          active: userInfo.a,
          data: userData.sort((a, b) => {
            if (a.date < b.date) return -1
            if (a.date > b.date) return 1
            return 0
          })
        })
    }

  }

  return newUserList
}, { maxSize: 20 })

class Reports extends Component {
  //static contextTypes = { store: PropTypes.object.isRequired }

  state = { cbShowOnlyActiveUsers: false, rDate: moment().utc().startOf('month') }

  styles = {
    meny: { border: 'none', position: 'fixed', height: '50px', zIndex: '10' },
    input1: { width: '200px' },
    width160: { width: '125px' },
    s1: { paddingLeft: '5px', paddingRight: '5px' },
    s2: { border: 'none', paddingTop: '55px' },
  }


  loadData = (rDateStr) => {

    const cache = this.props.project.cache || {}
    const firstDay = this.props.project.firstDay

    const AmPm = this.props.AmPm
    //.utc().isoWeekday()

    const d = moment.utc(rDateStr)
    const month = d.month()
    const date = d.isoWeekday(firstDay === 0 ? "Sunday" : "Monday") // change from .day(0)
    const dkey = getDateKey(date)
    const loaded = this.props.fireLoaded[`weekview_${dkey}`]
    const scheduleWeekData = this.props.fireData['weekview_' + dkey] || {}

    const date2 = date.clone().add(1, 'week')
    const dkey2 = getDateKey(date2)
    // console.log('dkey2', dkey2)
    const loaded2 = this.props.fireLoaded[`weekview_${dkey2}`]
    const scheduleWeekData2 = this.props.fireData['weekview_' + dkey2] || {}

    const date3 = date.clone().add(2, 'week')
    const dkey3 = getDateKey(date3)
    //console.log('dkey3', dkey3)
    const loaded3 = this.props.fireLoaded[`weekview_${dkey3}`]
    const scheduleWeekData3 = this.props.fireData['weekview_' + dkey3] || {}

    const date4 = date.clone().add(3, 'week')
    const dkey4 = getDateKey(date4)
    //console.log('dkey4', dkey4)
    const loaded4 = this.props.fireLoaded[`weekview_${dkey4}`]
    const scheduleWeekData4 = this.props.fireData['weekview_' + dkey4] || {}

    const date5 = date.clone().add(4, 'weeks')
    const dkey5 = getDateKey(date5)
    //console.log('dkey4', dkey4)
    const loaded5 = this.props.fireLoaded[`weekview_${dkey5}`]
    const scheduleWeekData5 = this.props.fireData['weekview_' + dkey5] || {}

    const date6 = date.clone().add(5, 'weeks')
    const dkey6 = getDateKey(date6)
    //console.log('dkey4', dkey4)
    const loaded6 = this.props.fireLoaded[`weekview_${dkey6}`]
    const scheduleWeekData6 = this.props.fireData['weekview_' + dkey6] || {}

    // console.log('--------------')
    // console.log('date', date.format('YYYY-MM-DD hh:mm'))
    // console.log('date2', date2.format('YYYY-MM-DD hh:mm'))
    // console.log('date3', date3.format('YYYY-MM-DD hh:mm'))
    // console.log('date4', date4.format('YYYY-MM-DD hh:mm'))
    // console.log('date5', date5.format('YYYY-MM-DD hh:mm'))
    // console.log('date6', date6.format('YYYY-MM-DD hh:mm'))
    // console.log('--------------')





    FirestoreListnerHelper.setWeekViewListner(this.context, date)
    FirestoreListnerHelper.setWeekViewListner(this.context, date2)
    FirestoreListnerHelper.setWeekViewListner(this.context, date3)
    FirestoreListnerHelper.setWeekViewListner(this.context, date4)
    FirestoreListnerHelper.setWeekViewListner(this.context, date5)
    FirestoreListnerHelper.setWeekViewListner(this.context, date6)

    if (loaded && loaded2 && loaded3 && loaded4 && loaded5 && loaded6) {


      let r = []

      r = flatMap([{ data: scheduleWeekData, date: date }, { data: scheduleWeekData2, date: date2 }, { data: scheduleWeekData3, date: date3 }, { data: scheduleWeekData4, date: date4 }, { data: scheduleWeekData5, date: date5 }, { data: scheduleWeekData6, date: date6 }], this.props.projectKey, cache, month, AmPm, firstDay)
      r = r.sort((a, b) => {
        if (!a.name || !b.name)
          return 0

        const aName = (a.name || '').toLowerCase()
        const bName = (b.name || '').toLowerCase()

        if (aName < bName) return -1
        if (aName > bName) return 1
        return 0
      })
      return r
    }


    return null
  }
  tbSearchChange = e => {
    this.setState({ searchText: e.target.value, userKey: null });
  };
  onClickOpenShift = (shift, userKey) => (e) => {

    var target = (e || {}).target
    var isRoot = false
    var shiftWidth = 500

    if (!target) {
      target = document.getElementById('root')
      isRoot = true
    }
    var { x, y, width, height, top, left, right } = target.getBoundingClientRect()
    x = left
    y = top
    if (isRoot === true) {
      x = width * .5 + (shiftWidth * .5)
      left = width * .5 + (shiftWidth * .5)
    }

    if (ipcRenderer)
      ipcRenderer.send('renderWindowTargetPos', { x, y, width, height, top, left, right, showArrow: !isRoot })
    console.log(`open`, { doLoad: { key: shift.k, shedualKey: shift.shedualKey, dateStr: shift.date, scheduleName: shift.scheduleName, userKey } })
    this.props.openEventModal({ doLoad: { key: shift.k, shedualKey: shift.shedualKey, dateStr: shift.date, scheduleName: shift.scheduleName, userKey } })
    if (e)
      e.stopPropagation()
  }
  toggleView = (key) => () => {
    //console.log('key', key)
    this.setState({ userKey: key })
  }
  onClickChangeDate = (month) => () => {
    this.setState({ rDate: this.state.rDate.add(month, 'month') })
  }

  onDownloadReport = (data) => (e) => {
    //onDownloadReport
    e.preventDefault()
    // console.log('data', data)


  }

  getPie = (sumHours, sumHoursSick) => {

    const data = [
      { label: "Worked", title: "Worked", value: sumHours, color: "rgb(251, 189, 8)" },
      { label: "Sick", title: "Sick", value: sumHoursSick, color: "#E8E8E8" },
    ]

    return (
      <div style={{ width: '100px' }}>
        <ReactSvgPieChart
          sectorStrokeWidth={2}
          expandOnHover
          shrinkOnTouchEnd data={data} />
      </div>)
  }

  render() {
    // const { AmPm } = this.props
    //  const cache = this.props.project.cache || {}


    if (this.props.project && this.props.project.disabled === true)
      return <Locked />

    var userlist = this.loadData(this.state.rDate.format('YYYY-MM-DD'))
    var { userKey, searchText } = this.state
    var reportData = (userlist || []).find(u => u.key === userKey)




    if (userlist) {



      userlist = userlist.sort((a, b) => {

        if (!a.name || !b.name)
          return 0

        const aName = (a.name || '').toLowerCase()
        const bName = (b.name || '').toLowerCase()

        if (aName < bName) return -1
        if (aName > bName) return 1
        return 0
      })


      if (searchText && searchText !== '') {
        userlist = userlist.filter(item => {
          //console.log('??', item)
          if (!item)
            return null

          return (item.name || '').toLowerCase().indexOf(searchText) > -1
        })
      }

    }

    if (!userKey && userlist) {
      reportData = userlist[0]
      if (reportData)
        userKey = reportData.key
    }


    //console.log('moment.locale', moment.locale())

    var sumHours = 0
    var sumHoursSick = 0


    const tblHead = [window._getText('date'), window._getText('time'), window._getText('break'), window._getText('hours'), window._getText('sick'), window._getText('schedule'), '']
    const tblRows = ((reportData || {}).data || []).map(item => [item.dStr, item.time, item.break, item.hours, (item.sik === true ? 'X' : ''), item.scheduleName], '')
    const tblMetaData = ((reportData || {}).data || []).map(item => item)
    const tblWidth = [100, 100, 100, 100, 50, '100%', 1]


    const onRowClick = (rowData, e) => {
      console.log(`onRowClick`)
      console.log(this.onClickOpenShift(rowData, userKey)(e))
    }

    return (

      <React.Fragment>


        <div style={{ flexDirection: 'row', display: 'flex', height: '100%', width: '100%' }}>
          <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', display: 'flex', flexGrow: '1', alignItems: 'stretch' }}>
            <div style={{ maxWidth: '250px', minWidth: '250px', }}>
              <div className='dragWindow2' style={{ paddingTop: '12px', paddingBottom: '12px', paddingLeft: '14px', paddingRight: '5px', color: 'var(--color--text)', height: '50px', overflow: 'hidden', }}>
                <h2 style={{ color: '#6960EF' }}><FontAwesomeIcon icon={icoPageHeader} ></FontAwesomeIcon> {window._getText('schedual.m.Reports')}</h2>


                <input
                  className="form-control"
                  icon="search"
                  value={this.state.searchText}
                  placeholder={window._getText('search')}
                  onChange={this.tbSearchChange}
                  style={{ height: '10px', marginRight: '8px' }}
                />


              </div>
              <div className='tour_report_2' style={{ overflowY: 'auto', overflowX: 'hidden', height: 'calc(100% - 50px)', paddingLeft: 10 }}>
                <div >

                  {userlist && userlist.map(u => {

                    if (u.active === true)
                      return (
                        <div key={u.key}
                          className={userKey === u.key ? 'listItemSelected' : 'listItem'}
                        >
                          <div onClick={this.toggleView(u.key)} style={{ cursor: 'pointer', }}>
                            <div key={u.key} style={{ display: 'flex' }}>


                              <div style={{ flexGrow: '1', marginRight: '5px', overflow: 'hidden', padding: 4 }}>
                                <Image circular size='mini' floated='left' src={u.image} alt='' style={{ margin: '0px', marginRight: '8px', width: 30, height: 30 }} onError={(e) => { e.target.onerror = null; e.target.src = noUserImage }} />
                                <b>{u.name}</b>
                                <br />
                                <span style={{ fontSize: '12px', fontWeight: 'normal' }}> {u.data && u.data.length} {window._getText('shifts')}</span>
                              </div>

                              <div style={{ marginRight: '5px', }}>
                                <b style={{ fontWeight: 'normal' }}>{u.hours} {window._getText('h')}</b>
                              </div>

                            </div>
                          </div>



                        </div>
                      )
                    else
                      return ''
                  })}
                </div>




              </div>
              {/* <div style={{ fontSize: '9px', flexDirection: 'row', display: 'flex', paddingLeft: '14px', padding: '5px', borderTop: 'solid 1px var(--color-border)', color: '#000', overflow: 'hidden', }}>
                <div style={{ flexGrow: '1' }}>
                  Active users: 22
                </div>
                <div>Hours:343</div>

              </div> */}
            </div>

            <div style={{ width: 'calc(100% - 250px)', flexGrow: '1', display: 'flex', flexDirection: 'column' }}>




              <React.Fragment>
                <div className='dragWindow2' style={{ paddingLeft: '0px', minHeight: '50px', height: '50px', overflow: 'hidden', display: 'flex', flexDirection: 'column' }} >
                  <div style={{ border: 'solid 0px blue', display: 'flex', flexDirection: 'row', paddingLeft: '10px', paddingTop: '8px', paddingRight: '10px', }}>

                    <div className='tour_report_1' style={{ width: '200px', flexDirection: 'row', display: 'flex' }}>
                      <div onClick={this.onClickChangeDate(-1)} >
                        <Icon style={{ fontSize: '20px', paddingTop: '5px' }} name='left angle'></Icon>
                      </div>
                      <div style={{ padding: '0px', flexGrow: '1' }} >
                        <div className='topMenuWeekDateMainDiv' >
                          <h2>
                            {window._getText(this.state.rDate.clone().format("MMM"))} {this.state.rDate.format('YYYY')}
                          </h2>
                        </div>
                      </div>
                      <div onClick={this.onClickChangeDate(1)} >
                        <Icon style={{ fontSize: '20px', paddingTop: '5px' }} name='right angle'></Icon>
                      </div>
                    </div>
                    <div style={{ flexGrow: '1' }}></div>
                    <div >

                      {reportData &&
                        <Suspense>
                          <DownloadHoursExcel dataSet={reportData.data} username={reportData.name} filename={reportData.name + '_' + this.state.rDate.clone().format("YYYYMM")}></DownloadHoursExcel>
                        </Suspense>
                      }

                    </div>

                  </div>
                </div>
                <div style={{ paddingTop: 0, display: 'flex', flexDirection: 'column', flexGrow: '1', paddingRight: 10, paddingLeft: 10 }}>



                  <Table
                    onRowClick={onRowClick}
                    head={tblHead}
                    rows={tblRows}
                    metaData={tblMetaData}
                    maxHeight={'calc(100vh - 200px)'}
                    widths={tblWidth}
                  ></Table>


                  {!userlist &&
                    <div style={{ textAlign: 'center', marginTop: '50px' }}><Loader inline active>{window._getText('loading')}</Loader></div>
                  }
                  {userlist && userlist.length === 0 &&
                    <div style={{ textAlign: 'center', marginTop: '100px' }}>
                      <Icon name='folder open outline' style={{ fontSize: '100px' }}></Icon><br />

                      <b>{window._getText('ThereIsNoPubSchedule')}</b><br />
                      {window._getText('DataComesFromPubSche')}

                    </div>}




                  {/* <div style={{ width: '300px', marginLeft: 'auto', marginRight: 'auto', marginBottom: '10px' }}>{this.getPie(sumHours, sumHoursSick)}</div>  */}
                  {/* 
                  
                  Add this back!
                  <div style={{ paddingLeft: '15px' }}>
                    {userlist && userlist.length !== 0 &&
                      <React.Fragment>
                        {window._getText('total')} <b>{getTimeFromMins(sumHours + sumHoursSick)}h</b>,
                        {window._getText('report.h_hours')} <b>{getTimeFromMins(sumHours)}h</b>,
                        {window._getText('sick')}  <b>{getTimeFromMins(sumHoursSick)}h</b>
                      </React.Fragment>
                    }
                  </div> */}


                </div>
              </React.Fragment>

            </div>
          </div>
        </div >



        <DownloadHoursExcelAll btnName={window._getText('report.downloadall')} dataSet={userlist} filename={'ALL_' + this.state.rDate.clone().format("YYYYMM")}></DownloadHoursExcelAll>
        <EditEventModal></EditEventModal>
      </React.Fragment >


    );
  }
}

const mapStateToProps = state => {

  //console.log('state.firestore.status', state.firestore.status)
  return {

    project: state.firestore.data.project,
    projectKey: state.firebase.profile.projectKey,
    currentUser: state.firebase.profile,
    //    renderDateKey: state.viewstate.renderDateKey,
    // viewstate: state.viewstate,
    fireData: state.firestore.data,
    fireLoaded: state.firestore.status.requested,
    AmPm: state.firestore.data.project.s ? state.firestore.data.project.s.AmPm || false : false,
  }
}

const mapActionsFromProps = {
  openEventModal,
};


export default connect(mapStateToProps, mapActionsFromProps)(Reports)

