

const transaltions = {}
let selectedLang = 'en'
let missingKeys = {}
module.exports = {

    init: () => {
        transaltions.en = require('./language/en'); //en is fallback lang 
        // transaltions.de = {}
        // transaltions.ru = {}
        // transaltions.se = {}
        // transaltions.nl = {}
    },
    getLang: () => {
        return selectedLang
    },
    setLang: (lang) => {
        // try {
        //     transaltions[lang] = require('./lang/' + lang);
        // } catch (error) {
        //     console.log('error', error)
        // }


        switch (lang) {
            case 'pt':
                transaltions.pt = require('./language/pt');
                break;
            case 'el':
                transaltions.el = require('./language/el');
                break;
            case 'it':
                transaltions.it = require('./language/it');
                break;
            case 'fi':
                transaltions.fi = require('./language/fi');
                break;
            case 'no':
                transaltions.no = require('./language/no');
                break;
            case 'da':
                transaltions.da = require('./language/da');
                break;
            case 'tr':
                transaltions.tr = require('./language/tr');
                break;
            case 'pl':
                transaltions.pl = require('./language/pl');
                break;


            case 'fr':
                transaltions.fr = require('./language/fr');
                break;
            case 'de':
                transaltions.de = require('./language/de');
                break;
            case 'es':
                transaltions.es = require('./language/es');
                break;
            case 'nl':
                transaltions.nl = require('./language/nl');
                break;
            case 'sv':
                transaltions.sv = require('./language/sv');
                break;
            case 'ru':
                transaltions.ru = require('./language/ru');
                break;
            default:
                break;
        }

        if (lang === 'us' || lang === 'gb')
            selectedLang = 'en'
        else
            selectedLang = lang


    },

    text: (key) => {
        var tran = (transaltions[selectedLang] || {})[key]
        if (!tran) {
            tran = transaltions['en'][key] //Fallback
            missingKeys[key] = true
            // console.log('1#ERR# MISSING LANGUAGE #  selectedLang=', selectedLang)
            // console.log('key', key)
            // console.log(' ',)
            //console.log('1transaltions.' + selectedLang + '["' + key + '"] = "' + key + '"')
        }

        if (!tran) {
            missingKeys[key] = true
            //console.log('2#ERR# LANG KEY MISSING #', key)
            //console.log('2selectedLang', selectedLang)
            // console.log('transaltions.' + selectedLang + '["' + key + '"] = "' + key + '"')

            //  console.log('transaltions', transaltions)
            tran = key
        }

        return tran
    }
}
//if (window)
//    window._getText = text

// if (window !== undefined)
//     window.dumpTKeys = () => {
//         console.log(missingKeys)
//     }

