export const DataHelper = {
  emptySchedule: {
    name: "new",
    isEmpty: true,
    key: null,
    dateKey: null,
    isNew: false,
    selectedSchedule: {},
    scheduleEventsList: {}, //events belongs to schedule

    scheduleUserList: [], //users belongs to schedule
    selectedUsers: [], // new users selected from the full userslist
    sortedUserList: [], //users keys sorted
    selectedSortedUserList: [],// unsaved sorted user list 
    scheduleUserListRemoved: [], //this is array with ids of remove users // TODO 20220619 REMOVE ALL THIS!! its not needed ? 
    selectedDividerList: {}, // list of selected/creatred divs that are not saved
    dividerList: {} // list of dividers 
  },
  initSchedule: (key, dateKey) => {
    //    console.log('-->initSchedule', key)
    let r = { ...DataHelper.emptySchedule, key, dateKey }
    r.selectedSchedule.key = key
    return r;
  },
  getScheduleByKey: (props, key) => {


    if (!props.currentScheduelData) {
      // console.log('>>>> current list dose not exist yet')
      return {
        ...DataHelper.emptySchedule, key: key
      }
    }; // current list dose not exist yet}

    var schedule = props.currentScheduelData[key]; // try to get a full schema from the currentList
    if (!schedule) {
      // console.log("%%%curScheduleView IS null, we this shulde only happen first load", schedule);
      return { ...DataHelper.emptySchedule, key: key };
    }

    return { ...schedule, key: key };
  },
  getCurScheduleView: curScheduleView => {

    if (!curScheduleView) {
      // console.info("curScheduleView IS null, this shulde only happen first load", curScheduleView);
      return DataHelper.emptySchedule;
    }
    return curScheduleView;
  }
};
