import React, { Component } from "react";
import { Loader } from "../../common/component/Button";
import { FireHelper } from '../../../core/util/FireHelper'
//import { auth } from '../../config/firebase';
import { connect } from 'react-redux'
import PropTypes from 'prop-types'


class NewUserRedirecter extends Component {
  static contextTypes = { router: PropTypes.object }
  componentDidUpdate(prevProps) {
    console.log('>> NewUserRedirecter > componentDidUpdate')
  }
  componentWillUnmount = () => {
    if (this.doItCounterTimer)
      clearTimeout(this.doItCounterTimer)
  }
  componentDidMount = async () => {

    if (this.doItCounterTimer)
      clearTimeout(this.doItCounterTimer)
    this.doit()

  }
  doItCounter = 0
  doItCounterTimer
  doit = () => {
    this.doItCounter++;
    console.log('doitX', this.doItCounter)
    //await auth.currentUser.reload().then(x => {
    //console.log('Reload is done')
    //auth.currentUser.reload().then(() => {
    console.log('reload again!')
    FireHelper.reloadToken(true).then((token) => {
      console.log('>>token.claims.role in newUserRedirect', token.claims.role)
      console.log('>>token.claims.pKey in newUserRedirect', token.claims.pKey)
      if (token.claims.role && token.claims.pKey) {
        //   alert(this.props.projectKey)
        console.log('redirect by >>>>> NewUserRedirecter = ', this.props.projectKey)
        this.props.history.push('/home')
      }
      else
        if (this.doItCounter < 100) {
          // auth.currentUser.reload().then(() => {
          this.doItCounterTimer = setTimeout(() => {
            this.doit()
          }, 500 * this.doItCounter);
          // }).catch(err => {
          //   console.log('#Err 789ff564jmnk34', err)
          //   //this.setState({ isLoading: false })
          // })
        }

    })
      .catch((error) => {
        console.log(error);
      });
    // }).catch(err => {
    //   console.log('#Err 789ff564jmnk34', err)
    //   //this.setState({ isLoading: false })
    // })
    //});
    //reactReduxFirebase.reloadAuth();

    // auth.currentUser.getIdTokenResult().then((idTokenResult) => {
    //   // Confirm the user is an Admin.
    //   console.log('8')
    //   console.log('>>idTokenResult.claims', idTokenResult.claims)
    //   if (idTokenResult.claims.role) {
    //     //alert('redirect')
    //   }

    // })
    //   .catch((error) => {
    //     console.log(error);
    //   });
  }
  render() {
    return (
      <div>
        <Loader active>Loading user...</Loader>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  const projectKey = state.firebase.profile.projectKey;

  console.log('mapStateToProps')
  //state.firebase.auth().currentUser.reload()
  // auth.currentUser.getIdToken(true).then(x => console.log('>>getIdToken', x))

  // auth.currentUser.getIdTokenResult().then((idTokenResult) => {
  //   // Confirm the user is an Admin.
  //   console.log('8')
  //   console.log('>>idTokenResult.claims', idTokenResult.claims)
  //   if(idTokenResult.claims.role)
  //   {
  //     alert('redirect')
  //   }

  // })
  //   .catch((error) => {
  //     console.log(error);
  //   });

  return { authUser: state.firebase.auth, currentUser: state.firebase.profile, firebase: state.firebase, projectKey };
}
const mapActions = {}
export default connect(mapStateToProps, mapActions)(NewUserRedirecter);
