import ReactGA from 'react-ga';
import collection from "lodash/collection";
import { getDateKey } from './helpers'
import { store } from '../redux/store/configStore';


const FirestoreListnerHelper = {
    listners: [],
    listnersObjects: {},
    getListnerKey: (projectKey, scheduleKey, renderDayDate) => {
        var dateKey = FirestoreListnerHelper.getDateKey(renderDayDate);
        //console.log('getListnerKey', `${scheduleKey}_${dateKey}`)
        return `${scheduleKey}_${dateKey}`
    },
    getDateKey: (date) => {
        var key = getDateKey(date)
        //  console.log('key', key)
        return key;// moment.utc(date).format("YYYY-WW")
    },
    setProjectListner: (context) => {
        let state = store.getState()
        const projectKey = state.firebase.profile.projectKey;
        // const listners = state.firestore.listeners.byId

        var listnerKey = 'projects';
        if (!FirestoreListnerHelper.listnersObjects[listnerKey]) {
            var doc = { collection: "projects", doc: projectKey, storeAs: 'project' }
            FirestoreListnerHelper.listnersObjects[listnerKey] = doc;
            store.firestore.onSnapshot(doc);
        }



    },
    setTaskListner: (context) => {
        let state = store.getState()
        const projectKey = state.firebase.profile.projectKey;
        // const listners = state.firestore.listeners.byId

        var listnerKey = 'tasks';
        if (!FirestoreListnerHelper.listnersObjects[listnerKey]) {
            var doc = {
                collection: `projects/${projectKey}/tasks`,
                storeAs: 'tasks'
            }
            FirestoreListnerHelper.listnersObjects[listnerKey] = doc;
            store.firestore.onSnapshot(doc);
        }

    },
    setInvoicesListner: (context) => {
        let state = store.getState()
        const projectKey = state.firebase.profile.projectKey;
        // const listners = state.firestore.listeners.byId

        var listnerKey = 'invoices';
        if (!FirestoreListnerHelper.listnersObjects[listnerKey]) {
            var doc = {
                collection: `invoices`,
                storeAs: listnerKey,
                where: ['projectKey', '==', projectKey],
            }
            FirestoreListnerHelper.listnersObjects[listnerKey] = doc;
            store.firestore.onSnapshot(doc);
        }

    },
    setListner: (context, date, scheduleKey) => {

        if (!scheduleKey) {
            console.error('#Err setListner> scheduleKey ==', scheduleKey)
            return
        }
        try {


            let state = store.getState()
            const projectKey = state.firebase.profile.projectKey;
            const listners = state.firestore.listeners.byId
            if (date) {
                var dateKey = FirestoreListnerHelper.getDateKey(date);
                // console.log('dateKey', dateKey)
                var listnerKey = FirestoreListnerHelper.getListnerKey(projectKey, scheduleKey, date);

                if (!listners[listnerKey]) {
                    //console.log('>>setListner', listnerKey)

                    var doc = {
                        collection: "projects",
                        doc: projectKey,
                        subcollections: [{
                            collection: "schedules",
                            doc: scheduleKey,
                            subcollections: [{
                                collection: "events",
                                doc: dateKey,
                                storeAs: scheduleKey + '_' + dateKey + '_2'
                            }],
                            storeAs: scheduleKey + '_' + dateKey + '_1'

                        }],
                        storeAs: scheduleKey + '_' + dateKey
                    }

                    FirestoreListnerHelper.listnersObjects[scheduleKey + '_' + dateKey] = doc;
                    store.firestore.onSnapshot(doc);

                    // ReactGA.event({ category: 'Schedule', action: 'onSnapshot', label: projectKey });
                }


                FirestoreListnerHelper.setWeekViewListner(context, date)



            }
        } catch (error) {
            console.log('error', error)
        }
    },
    clearListners: (context) => {



        collection.forEach(FirestoreListnerHelper.listnersObjects, (listerObj) => { store.firestore.unsetListener(listerObj) })


        FirestoreListnerHelper.listnersObjects = {}
    },
    clearBillingListners: (context) => {
        const store = store
        store.firestore.unsetListener('billingListner')
        store.firestore.unsetListener('billingListnerTransactions')
    },
    setBillingListner: (context) => {

        const state = store.getState();
        // const listners = state.firestore.listeners.byId
        const projectKey = state.firebase.profile.projectKey;


        var listnerKey = 'billingListner'

        if (!FirestoreListnerHelper.listnersObjects[listnerKey]) {
            //console.log('SET billing listner')

            const doc = {
                collection: `projects/${projectKey}/billing`,
                doc: 'current30days',
                storeAs: 'billing'
            }

            FirestoreListnerHelper.listnersObjects[listnerKey] = doc;
            store.firestore.onSnapshot(doc);

            ReactGA.event({ category: 'Schedule', action: 'onSnapshot_billing', label: projectKey });
        }

        if (!FirestoreListnerHelper.listnersObjects[listnerKey + 'Transactions']) {
            // console.log('Add billingTransaction listner')

            var doc2 = {
                collection: `projects/${projectKey}/billingTransactions`,
                storeAs: 'billingTransactions'
            }
            FirestoreListnerHelper.listnersObjects[listnerKey + 'Transactions'] = doc2;
            store.firestore.onSnapshot(doc2);

            // ReactGA.event({ category: 'Schedule', action: 'onSnapshot_billingTransactions', label: projectKey });
        }


    },

    setRequestAllListner: (context, role, userKey) => {
        // console.log('#setRequestAllListner', context)
        const state = store.getState();
        //  const listners = state.firestore.listeners.byId
        const projectKey = state.firebase.profile.projectKey;

        // TODO  MAKE SURE ITS OK TO CREATE NEW LISTNERS ALL THE TIME!
        var listnerKey = 'requestAllListner'
        if (!FirestoreListnerHelper.listnersObjects[listnerKey]) {
            //       console.log('>>>>>>>>>!!set requests listner -> ', listnerKey)
            var doc = {}
            //   console.log('#role================>', role)

            //       console.log('userKey contains', userKey)
            doc = {
                collection: `projects/${projectKey}/requests`,
                storeAs: listnerKey,
                where: [['p', 'array-contains', userKey], ['adm', '==', false]
                ]
            }

            FirestoreListnerHelper.listnersObjects[listnerKey] = doc;
            try {
                store.firestore.onSnapshot(doc);
            } catch (error) {
                console.log('error', error)
            }


            ReactGA.event({ category: 'Schedule', action: 'onSnapshot_' + listnerKey, label: projectKey });

        }


    },

    setRequestAdminListner: (context, role, userKey) => {
        // console.log('#setRequestAllListner', context)
        const state = store.getState();
        //  const listners = state.firestore.listeners.byId
        const projectKey = state.firebase.profile.projectKey;

        // TODO  MAKE SURE ITS OK TO CREATE NEW LISTNERS ALL THE TIME!
        var listnerKey = 'requestAdminListner'
        if (!FirestoreListnerHelper.listnersObjects[listnerKey]) {
            //       console.log('>>>>>>>>>!!set requests listner -> ', listnerKey)
            var doc = {}
            // console.log('#role================>', role)

            if (role > 100) {
                doc = {
                    collection: `projects/${projectKey}/requests`,
                    storeAs: listnerKey,
                    where: ['adm', '==', true],
                }


                FirestoreListnerHelper.listnersObjects[listnerKey] = doc;
                store.firestore.onSnapshot(doc);

                ReactGA.event({ category: 'Schedule', action: 'onSnapshot_' + listnerKey, label: projectKey });
            }
        }


    },

    setRequestMyListner: (context, userKey) => {
        // console.log('setRequestMyListner')
        if (!userKey)
            return

        const state = store.getState();
        // const listners = state.firestore.listeners.byId
        const projectKey = state.firebase.profile.projectKey;


        var listnerKey = 'requestMyListner'

        if (!FirestoreListnerHelper.listnersObjects[listnerKey]) {
            // console.log('!!set requests listner -> ', listnerKey)

            var doc = {}

            //console.log('userKey contains', userKey)
            doc = {
                collection: `projects/${projectKey}/requests`,
                storeAs: listnerKey,
                where: ['uk', '==', userKey],
                // id: 'foo'
            }
            FirestoreListnerHelper.listnersObjects[listnerKey] = doc;
            store.firestore.onSnapshot(doc);
            ReactGA.event({ category: 'Schedule', action: 'onSnapshot_' + listnerKey, label: projectKey });
        }


    },
    setMessagesListner: (context, cKey) => {

        const state = store.getState();
        // const listners = state.firestore.listeners.byId
        const projectKey = state.firebase.profile.projectKey;


        var listnerKey = 'messagesListner_' + cKey
        //console.log('--->', `projects/${projectKey}/messages/` + cKey)
        if (!FirestoreListnerHelper.listnersObjects[listnerKey]) {
            // console.log('!!set messages listner -> ' + cKey)

            const doc = {
                collection: `projects/${projectKey}/messages`,
                doc: cKey,
                storeAs: listnerKey
            }
            FirestoreListnerHelper.listnersObjects[listnerKey] = doc;
            store.firestore.onSnapshot(doc);

            ReactGA.event({ category: 'Schedule', action: 'onSnapshot_' + listnerKey, label: projectKey });
        }


    },
    setWeekViewListner: (context, date) => {
        const state = store.getState();//move this ??
        // console.log('state.firestore.listeners.byId', state.firestore.listeners.allIds)
        //  const listners = state.firestore.listeners.byId
        const projectKey = state.firebase.profile.projectKey;
        // console.log(state.firestore.listeners)

        if (date) {

            var dateKey = FirestoreListnerHelper.getDateKey(date);
            var listnerKey = 'weekview_' + dateKey;// FirestoreListnerHelper.getListnerKey(projectKey, scheduleKey, date);

            if (!FirestoreListnerHelper.listnersObjects[listnerKey]) {

                // console.log('>>setWeekViewListner', listnerKey)
                var doc = {
                    collection: "projects",
                    doc: projectKey,
                    subcollections: [{
                        collection: "weekview",
                        doc: dateKey,
                    }],
                    storeAs: listnerKey
                }
                FirestoreListnerHelper.listnersObjects[listnerKey] = doc;
                store.firestore.onSnapshot(doc);
                //  ReactGA.event({ category: 'Schedule', action: 'onSnapshot_weekview', label: projectKey });
            }

            if (!FirestoreListnerHelper.listnersObjects[listnerKey + '_meta']) {

                //  console.log('>>shiftMetaData', listnerKey)
                var doc2 = {
                    collection: "projects",
                    doc: projectKey,
                    subcollections: [{
                        collection: "shiftMetaData",
                        doc: dateKey,
                    }],
                    storeAs: listnerKey + '_meta'
                }

                FirestoreListnerHelper.listnersObjects[listnerKey + '_meta'] = doc2;
                store.firestore.onSnapshot(doc2);
                // ReactGA.event({ category: 'Schedule', action: 'onSnapshot_weekview_shiftMetaData', label: projectKey });
            }

        }
    }

}

export default FirestoreListnerHelper