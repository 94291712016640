import React from 'react'
import { Image, Icon } from "../Button";
//import { short } from '../../../../core/util/helpers'
import noUserImage from '../../../../img/noimg.png'
const moment = require('moment')

export default function ShiftContainer({ schedualName, user, shiftOrginal, orginal, size }) {

    const sick = shiftOrginal.sik === true ? true : false
    //   console.log('>>participants', user)

    return (
        <React.Fragment>
            {/* {orginal === true ?
                <span>Orginal shift</span>
                :
                <span>New shift</span>
            } */}
            <div style={{ width: 'calc(100% - 30px)', padding: size === 'small' ? '4px' : '20px', background: 'rgba(1,1,1,0.04)', borderRadius: '10px', position: 'relative' }}>
                <div style={{ position: 'absolute', left: '8px', bottom: '2px', fontSize: '8px' }} >

                </div>
                <div style={{ display: 'flex', flex: 1, border: 'solid 0px red' }}>
                    <div style={{ flex: 1 }} >
                        <span style={{ fontWeight: 'bold' }}><Icon name='clock outline' /> {moment(shiftOrginal.d).format('dddd L')}</span>
                    </div>
                    {size !== 'small' &&
                        <div >
                            <Icon name='calendar alternate outline' /><span style={{ fontSize: '14px' }}>{schedualName}</span>
                        </div>
                    }
                </div>
                <div style={{ paddingTop: '4px', paddingBottom: '0px', minWidth: '170px' }}>
                    <Image className='noMargin' size='mini' style={{ width: '70px', padding: '6px' }} floated='left' src={user.img} circular onError={(e) => { e.target.onerror = null; e.target.src = noUserImage }} />
                    <div>
                        <div>{user.participants === undefined && user.n}</div>
                        {user.participants !== undefined && user.participants.length + ' Users pending answer'}

                        <div className={"cellMulti ui label large dragMe x" + (sick ? 'var(--color-font)' : shiftOrginal.c)} style={{ minHeight: '34px', width: '200px' }} >
                            <div style={{ color: (sick === true ? 'silver' : 'var(--color-font)') }}>{shiftOrginal.l}</div>
                            <div style={{ color: (sick === true ? 'silver' : 'var(--color-font)') }}>{shiftOrginal.co}</div>
                        </div>
                    </div>
                </div>

            </div>
        </React.Fragment >
    )
}
