import React, { useState, useEffect, useRef } from 'react'
import { toastr } from 'react-redux-toastr'
import { useDispatch } from 'react-redux'
import { FireHelper } from '../../../core/util/FireHelper'
import shortid from 'shortid'
import SelectUsers from '../../pages/modal/SelectUsers'
import { getUserImage } from '../../../core/util/helpers'
import noUserImage from '../../../img/noimg.png'
import { store } from '../../../core/redux/store/configStore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faClipboardListCheck as icoTitle,
    faPlusCircle as icoAddTask,
    faCheck as icoCheck,
    faCircle as icoTaskItem,
    faCircle as icoNoCheck,
    faTrashAlt as icoTrash,
    faTrashAlt as icoRemove,
    faArchive as icoArchive,
    faTasks as iconTabActive,
    faCheck as iconTabCompleted,
    faArrowLeft as icoBackFromArchive,
    faCloudUpload as icoPublish,
    faCloudDownload as icoUnpublish,
    faPlay as icoRunning,
    faStop as icoStop,
    faArchive as iconTabArchive,
    faUndoAlt as icoRestart
} from '@fortawesome/pro-duotone-svg-icons';
import { Button, Image, Icon, Loader } from "../../common/component/Button";
import Tab from "../../common/component/Tab"
import List from "../../common/component/List"
import TaskItem from './TaskItem'
import s from './style'
import TaskView from './TaskView'
export default function Tasks() {
    const [selectedTabIndex, setSelectedTabIndex] = useState(0)
    const [selectedKey, setSelectedKey] = useState()
    const [taskData, setTaskData] = useState("")
    const [refresh, setRefresh] = useState("")
    const [showNewFature, setshowNewFature] = useState('0')

    let unsubscribe
    let state = store.getState()
    var [tasks, setTasks] = useState([])
    var dbTasks = state.firestore.data.tasks || {}

    const onTaskItemStatusChange = (movedToArchive) => {

        if (movedToArchive)
            setSelectedTabIndex(1)
        else
            setSelectedTabIndex(0)
    }

    useEffect(() => {
        let arr = []
        Object.keys(dbTasks.active || {}).forEach(key => {
            // if (selectedTabIndex === 0) {
            //     if (dbTasks.active[key].archive !== true)
            //         arr.push(<TaskItem id={key} key={key} data={dbTasks.active[key]} ></TaskItem>)
            // }
            // else {
            if (selectedTabIndex !== 0) {
                if (dbTasks.active[key].archive === true)
                    arr.push(<TaskItem id={key} key={key} data={dbTasks.active[key]} onStatusChange={onTaskItemStatusChange} ></TaskItem>)
            }
            else {
                if (dbTasks.active[key].archive !== true)
                    arr.push(<TaskItem id={key} key={key} data={dbTasks.active[key]} onStatusChange={onTaskItemStatusChange} ></TaskItem>)
            }
            //}


        })
        setTasks(arr)

    }, [selectedTabIndex, selectedKey, refresh])

    const updateData = () => {
        setRefresh(Math.random())
    }
    useEffect(() => {
        setSelectedTabIndex(0)
        unsubscribe = store.subscribe(updateData)
        setshowNewFature(localStorage.getItem('showNewFature_task') || '0')
        return unsubscribe
    }, [])


    const addTask = () => {


        let state = store.getState()
        const newTask = { key: shortid.generate(), description: '', title: `#${window._getText('new')}`, feedback: '', status: 10, uk: state.firebase.profile.inSysUserKey, archive: false }
        FireHelper.addOrUpdateTask(newTask, state.firebase.profile).then(x => {

            setSelectedKey(newTask.key)
            //setTaskData(newTask)
            setRefresh(Math.random())
        })



    }

    useEffect(() => {

        // set task data
        if (dbTasks && dbTasks.active && selectedKey) {
            const selectedObj = dbTasks.active[selectedKey]
            if (taskData !== selectedObj) {
                setTaskData(selectedObj)
            }

        }

    })

    const tasksDone = tasks.filter(x => x.status === 40)

    return (
        <React.Fragment>
            <div style={s.divMain} refresh={refresh}>
                <div style={s.divTop} className={'dragWindow2'}  >
                    <h2 style={{ color: '#A334C8', marginRight: 'auto' }}> {<FontAwesomeIcon icon={icoTitle}></FontAwesomeIcon>}  {window._getText('schedual.m.Tasks')}</h2>

                    <button tooltip_r='Add new Task' onClick={addTask} className="tour_task_1 btn btn-default" style={{ height: 32, marginTop: '-2px', border: 'none', background: 'transparent' }} ><FontAwesomeIcon icon={icoAddTask} style={s.icoAddTask} /></button>


                </div>
                <div style={s.divLeft}>
                    <Tab
                        updateTabIndex={selectedTabIndex}
                        onChange={(index) => {
                            setSelectedTabIndex(index)
                        }}>
                        <Tab.View icon={iconTabActive} icoColor='rgb(148, 79, 220)' name='Active' className='tabX' selectedClassName='tabXselectedStyle'>
                            <div style={{ overflow: 'auto', height: 'calc(100vh - 100px)' }}>
                                <List onChange={(id) => { setSelectedKey(id) }} selectedKey={selectedKey}>
                                    {tasksDone && tasksDone.map((t, index) => <List.Item id={t.props.id} key={t.props.id} data={t.props.data} className='tabX' selectedClassName='tabXselectedStyle'>{t} </List.Item>)}
                                </List>
                                <List onChange={(id) => { setSelectedKey(id) }} selectedKey={selectedKey}>
                                    {tasks && tasks.map((t, index) => <List.Item id={t.props.id} key={t.props.id} data={t.props.data} className='tabX' selectedClassName='tabXselectedStyle'>{t} </List.Item>)}
                                </List>

                            </div>
                        </Tab.View>

                        <Tab.View icon={iconTabArchive} icoColor='rgb(218, 51, 145)' name='Archive' className='tabX' selectedClassName='tabXselectedStyle'>
                            <List
                                onChange={(id) => {
                                    setSelectedKey(id)
                                }}
                                selectedKey={selectedKey}
                                containerStyle={{ border: 'solid 1px red', }}
                            >
                                {tasks && tasks.map((t, index) => <List.Item id={t.props.id} key={t.props.id} data={t.props.data} className='tabX' selectedClassName='tabXselectedStyle'>{t} </List.Item>)}
                            </List>
                        </Tab.View>
                    </Tab>
                </div>
                <div style={s.divRight}>
                    <TaskView
                        callBackOnUnload={() => {
                            // alert('reload')
                        }}
                        callbackOnRemove={() => {

                            setSelectedKey(undefined)
                        }}
                        callbackOnUpdate={(key) => {
                            updateData()
                        }}
                        selectedKey={selectedKey}
                        task={taskData}
                        name='Archive' className='tabX' selectedClassName='tabXselectedStyle'>
                    </TaskView>
                </div>
            </div>



        </React.Fragment>
    )
}


