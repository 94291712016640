var React = require('react');

const stepsData =
{
    tasks: [
        {
            text: `Start by adding a task.
        then click next`, target: '.tour_task_1', placement: 'bottom',
        },
        { text: 'Give it title.', target: '.tour_task_2', placement: 'bottom', },
        { text: 'Then a short description.', target: '.tour_task_3', placement: 'bottom', },
        { text: 'Now add the staff you want the task to be assigned to.', target: '.tour_task_4', placement: 'bottom', },
        { text: 'Add some tasks, end with enter to add them.', target: '.tour_task_5', placement: 'bottom', },
        { text: 'When you are ready with the task click the publish button to share it with your staff.', target: '.tour_task_6', placement: 'bottom', },


    ],
    tour: [
        { text: window._getText('tour.onboardStep_mainMenu'), target: '.onboardStep_mainMenu', placement: 'right', }
    ],
    today: [
        { text: window._getText('tour.tour_today_1'), target: '.tour_today_1', placement: 'bottom', },
        { text: window._getText('tour.tour_today_2'), target: '.tour_today_2', placement: 'bottom', },

    ],
    staff: [
        { text: window._getText('tour.tour_staff_1'), target: '.tour_staff_1', placement: 'bottom', },
        { text: window._getText('tour.tour_staff_2'), target: '.tour_staff_2', placement: 'bottom', },
        { text: window._getText('tour.tour_staff_3'), target: '.tour_staff_3', placement: 'bottom', },
        { text: window._getText('tour.tour_staff_4'), target: '.tour_staff_4', placement: 'bottom', },
        { text: window._getText('tour.tour_staff_5'), target: '.tour_staff_5', placement: 'bottom', },
    ],
    schedule: [
        { text: window._getText('tour.tour_schedule_1'), target: '.tour_schedule_1', placement: 'right', },
        { text: window._getText('tour.tour_schedule_2'), target: '.tour_schedule_2', placement: 'bottom', },
        { text: window._getText('tour.tour_schedule_3'), target: '.tour_schedule_3', placement: 'left', },
        { text: window._getText('tour.tour_schedule_4'), target: '.tour_schedule_4', placement: 'left', },
        { text: window._getText('tour.tour_schedule_5'), target: '.tour_schedule_5', placement: 'bottom', },
        { text: window._getText('tour.tour_schedule_6'), target: '.tour_schedule_6', placement: 'bottom', },
        { text: window._getText('tour.tour_schedule_6_1'), target: '.tour_schedule_6_1', placement: 'bottom', },
        { text: window._getText('tour.tour_schedule_7'), target: '.tour_schedule_7', placement: 'left', },
        { text: window._getText('tour.tour_schedule_8'), target: '.tour_schedule_8', placement: 'left', },
        { text: window._getText('tour.tour_schedule_9'), target: '.tour_schedule_9', placement: 'bottom', },
        { text: window._getText('tour.tour_schedule_10'), target: '.tour_schedule_10', placement: 'bottom', },
    ],

    reports: [

        { text: window._getText('tour.tour_report_1'), target: '.tour_report_1', placement: 'bottom', },
        { text: window._getText('tour.tour_report_2'), target: '.tour_report_2', placement: 'right', },
        { text: window._getText('tour.tour_report_3'), target: '.tour_report_3', placement: 'left', },
        { text: window._getText('tour.tour_report_4'), target: '.tour_report_4', placement: 'bottom', },
    ],



    requests: [

        { text: window._getText('tour.tour_requests_1'), target: '.tour_requests_1', placement: 'bottom', },
        { text: window._getText('tour.tour_requests_2'), target: '.tour_requests_2', placement: 'bottom', },
        { text: window._getText('tour.tour_requests_3'), target: '.tour_requests_3', placement: 'bottom', },
        { text: window._getText('tour.tour_requests_4'), target: '.tour_requests_4', placement: 'bottom', },
        { text: window._getText('tour.tour_requests_5'), target: '.tour_requests_5', placement: 'bottom', },
        { text: window._getText('tour.tour_requests_empty'), target: '.tour_requests_empty', placement: 'bottom', },

    ],



    chat: [
        { text: window._getText('tour.tour_chats_1'), target: '.tour_chats_1', placement: 'right', },
        { text: window._getText('tour.tour_chats_2'), target: '.tour_chats_2', placement: 'bottom', },
        { text: window._getText('tour.tour_chats_3'), target: '.tour_chats_3', placement: 'bottom', },
    ]



}
module.exports = (key) => {

    const arrayData = stepsData[key] || []
    const hideFooter = arrayData.length < 2
    var r = []

    arrayData.forEach(({ text, target, placement }) => {
        r.push({
            content: (
                <div style={{ fontSize: '18px', whiteSpace: 'pre-line' }}>{text}</div>
            ),
            disableBeacon: true,
            disableOverlayClose: true,
            hideCloseButton: false,
            hideFooter,
            placement,
            spotlightClicks: true,
            styles: {
                options: {
                    zIndex: 10000,
                },
            },
            target: target,
        }
        )
    });
    return r

}