import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { actionTypes } from "redux-firestore";

import { auth } from "../../../core/config/firebase";
import { resetSchedule } from "../../../core/redux/action/currentScheduelDataAction";
import FirestoreListnerHelper from "../../../core/util/FirestoreListnerHelper";
import { FireHelper } from "../../../core/util/FireHelper";

import { isElectron } from "react-device-detect";
let ipcRenderer;
if (isElectron) {
  ipcRenderer = window.require("electron").ipcRenderer;
}

class SignOut extends Component {
  //static contextTypes = { store: PropTypes.object.isRequired }

  removeData = () => {
    this.props.onResetSchedule(); // this is nerver calles all the way!
    this.props.dispatch({ type: "currentScheduleData:reset" }); //TEMP fix

    FirestoreListnerHelper.clearListners(this.context);
    //console.log('actionTypes.CLEAR_DATA')
    this.props.dispatch({ type: actionTypes.CLEAR_DATA });
  };

  componentDidMount = () => {
    const isAnonymous =
      (this.props.currentUser.email || "").indexOf("anonymous_") !== -1;

    if (isAnonymous && this.props.currentUser) {
      if (
        window.confirm(window._getText("confirmLogoutfromAnonymousAccount"))
      ) {
        this.removeData();
      } else {
        this.props.history.goBack();
      }
    } else this.removeData();
  };
  componentWillUnmount = () => {
    //Clear all js data
    //momnet ?
    //firstday ?
    //language ?
  };
  animationEnd = async (e) => {
    if (e.target.className === "fadeOut") {
      if (isElectron) {
        ipcRenderer.send("reset-hidesplash", "login");
      }

      if (window._webToken) {
        await FireHelper.removeUserPushToken(window._webToken);
        delete window._webToken;
      }

      FirestoreListnerHelper.clearListners(this.context);

      if (window._messagingSenderId) delete window._messagingSenderId;

      window._hidesplash = false;
      delete window._moment;
      delete window._projectKey;
      delete window._AmPm;
      delete window._firstDay;
      localStorage.removeItem("savedStateVars");

      this.props.dispatch({ type: actionTypes.CLEAR_DATA });

      auth.signOut();

      // localStorage.removeItem('joyrun_pageUsersSteps');
      // localStorage.removeItem('joyrun_pageStartSteps');
      // localStorage.removeItem('joyrun_pageScheduleSteps');
      //this.props.history.push('/login')
      // window.location.reload()
    }
  };
  // test = () => {
  //   console.log('clikc')
  //   this.props.dispatch({ type: 'currentScheduleData:reset' })
  //   this.props.onResetSchedule()
  // }
  render() {
    return (
      //
      <div style={{ height: "100%", background: "rgb(69, 69, 68)" }}>
        <div className="fadeOut" onAnimationEnd={this.animationEnd}>
          <div className="cc">
            <h2
              style={{ marginTop: "200px", color: "#fff" }}
              className="fadeIn"
            >
              {window._getText("logginout")}
            </h2>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return { currentUser: state.firebase.profile };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onResetSchedule: resetSchedule,
    dispatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignOut);
