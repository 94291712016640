import React from "react";
import { Image } from "../../common/component/Button";
import { connect } from 'react-redux'
import FirestoreListnerHelper from '../../../core/util/FirestoreListnerHelper'
import { FireHelper } from '../../../core/util/FireHelper'
import PropTypes from 'prop-types'
//import { isMobile } from 'react-device-detect';
import moment from 'moment'
import { getUserImage, getLocalFromNow } from '../../../core/util/helpers'
import noUserImage from '../../../img/noimg.png'


const style = {
  msg: { display: 'block', padding: '5px', paddingLeft: '20px', paddingTop: '10px' },

}
class TextMessagesMessage extends React.Component {
  //static contextTypes = { store: PropTypes.object.isRequired }
  state = { chatData: [], editNameToggle: false, chatName: '', loadingData: true }


  componentDidMount = () => {


    if (this.props.chanelKey)
      FirestoreListnerHelper.setMessagesListner(this.context, this.props.chanelKey)

    this.setChatData()
    //toTop(document.body.scrollHeight)
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {


    var { chanelData, currentUser, chanelKey } = this.props


    if (chanelKey !== prevProps.chanelKey) {
      this.setState({ chatData: [] })
      FirestoreListnerHelper.setMessagesListner(this.context, chanelKey)

      this.setChatData()


    } else if (chanelData !== prevProps.chanelData) {

      this.setChatData()

    } else {


      if (prevProps.currentUser.alerts) {
        const prevCount = Object.keys(prevProps.currentUser.alerts.m).length
        const currCount = Object.keys(this.props.currentUser.alerts.m).length

        if (prevCount > currCount)//one is removed 
        {
          if (!this.props.currentUser.alerts.m[chanelKey])//current chanel is missing send to All
            this.props.onSelectDefault()
        }
      }

    }


    if (currentUser.alerts && currentUser.alerts.m && currentUser.alerts.m[chanelKey] && currentUser.alerts.m[chanelKey].m === 1) {
      FireHelper.setChatMesageRead(chanelKey, this.props.currentUser)
    }

    //toTop(document.body.scrollHeight)

  }
  setChatData = () => {

    const { chanelData } = this.props
    if (chanelData && chanelData.m)
      if (Array.isArray(chanelData.m)) {
        var revArray = [...chanelData.m].reverse()
        this.setState({ loadingData: false, chatData: [...revArray], editNameToggle: false, chatName: chanelData.n })
      }
  }

  toggleNameEdit = () => {
    this.setState({ editNameToggle: !this.state.editNameToggle, chatName: this.props.chanelData.n })

  }
  onEditParticipants = () => {
    this.props.onEditParticipants(this.props.chanelData)
  }
  onDeleteChat = () => {
    this.props.onDeleteChat(this.props.chanelData)
  }
  onSaveName = () => {
    const name = this.state.chatName
    if (name.length > 0 && name !== this.props.chanelData.n && name !== 'All') {

      const { currentUser, chanelKey } = this.props
      FireHelper.setChatName(name, chanelKey, currentUser)
      this.setState({ editNameToggle: false, chatName: name, newChatName: name })
    }
    else
      this.setState({ editNameToggle: false })

  }


  render() {

    const { project } = this.props
    const isAdmin = this.props.currentUser.role > 100
    var cname = this.props.chanelData ? this.props.chanelData.n : '#chat'

    //console.log('this.props.chanelData', this.props.chanelData)


    if (this.state.newChatName && this.state.newChatName !== cname)
      cname = this.state.newChatName

    if (this.props.chanelData && this.state.newChatName === this.props.chanelData.n) {
      this.setState({ newChatName: null })
    }



    const { projectKey, chanelKey, noHeader } = this.props
    // if (this.state.loadingData || !project)
    //   return (<Loader active inline>Please wait</Loader>)

    return (
      <React.Fragment>
        {noHeader !== true &&
          <div className='dragWindow2' style={{ paddingLeft: '0px', height: '50px', overflow: 'hidden', display: 'flex', flexDirection: 'column' }} >
            <div style={{ border: 'solid 0px blue', display: 'flex', flexDirection: 'row', paddingLeft: '10px', paddingTop: '8px', paddingRight: '10px', }}>


              {isAdmin && !this.state.editNameToggle && this.props.chanelData &&
                <React.Fragment>

                  <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', fontWeight: 'bold' }}>
                    {cname}
                  </div>
                  <div style={{ flexGrow: '1' }}></div>
                  <div>

                    {(chanelKey !== 'All' && !chanelKey.startsWith('sys_')) &&
                      <button style={{ marginRight: '5px', marginLeft: '8px', marginTop: '5px' }} tooltip_r={window._getText('EditChat')} className="btn btn-default" onClick={this.toggleNameEdit}>
                        <span className="ico ico-pencil"></span>
                      </button>
                    }

                  </div>
                </React.Fragment>
              }

              {!isAdmin && this.props.chanelData &&
                <div title={this.props.chanelData ? this.props.chanelData.pn : ''} style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', xwidth: this.props.isLandscape ? '500px' : '250px', fontWeight: 'bold' }}>
                  {cname}
                </div>
              }

              {isAdmin && this.state.editNameToggle && this.props.chanelData &&
                <div style={{ padding: '5px', flexGrow: '1', display: 'flex', color: '#000', }}>


                  <input
                    className="form-control"
                    maxLength={50} value={this.state.chatName} onChange={(e) => this.setState({ chatName: e.target.value })} style={{ height: '10px', flexGrow: '1' }}

                  />


                  <button style={{ marginLeft: '10px' }} className="btn btn-default" onClick={this.onSaveName}>
                    <span className="ico ico-floppy"></span>
                  </button>

                  <button style={{ marginLeft: '10px' }} className="btn btn-default" onClick={this.onEditParticipants}>
                    <span className="ico ico-user-add"></span>
                  </button>

                  <button style={{ marginLeft: '10px' }} className="btn btn-default" onClick={this.onDeleteChat}>
                    <span className="ico ico-trash"></span>
                  </button>

                  <button style={{ marginLeft: '10px' }} className="btn btn-default" onClick={this.toggleNameEdit}>
                    <span className="ico ico-cancel"></span>
                  </button>


                </div>
              }

            </div>
            {isAdmin && !this.state.editNameToggle &&
              <div style={{ maxWidth: 'calc(100% - 55px)', marginTop: '-11px', border: 'solid  0px green', paddingLeft: '10px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{this.props.chanelData && this.props.chanelData.pn}</div>
            }
          </div>
        }
        <div style={{ width: '100%', flexGrow: '1', display: 'flex', flexDirection: 'column-reverse', height: 'calc(100vh - 110px)', overflowY: 'auto', overflowX: 'hidden' }} >






          {this.state.chatName !== 'system' && this.state.chatData.map((msg, index) => {
            const user = project.cache[msg.uk] || { n: '#unknown', i: '' }

            const key = msg.d ? msg.d : msg.m
            const date = msg.d ? getLocalFromNow(moment(msg.d)) : ''
            const img = user.i;// msg.d ? msg.ui : ''
            const userKey = msg.uk
            const name = user.n // msg.d ? msg.un : ''
            if (msg.r > 100) {
              return (
                <div style={style.msg} key={key + '_' + index} >
                  <div style={{ float: 'right' }}>
                    <Image circular size='mini' floated='right' src={img ? getUserImage(projectKey, userKey, img) : noUserImage} alt='' />

                    <div style={{ display: 'inline-block', padding: '5px', paddingLeft: '15px', borderTopLeftRadius: '20px', borderBottomLeftRadius: '20px', borderTopRightRadius: '20px', background: 'rgba(0, 0, 0, 0.05)', minWidth: '200px' }}>
                      <div className='break-long-words'> {msg.m}</div>
                    </div>
                    <div style={{ textAlign: 'right', paddingRight: '5px', fontSize: '11px', color: 'gray', lineHeight: '15px' }}>{name} <span style={{ fontSize: '9px', color: 'gray', }}>{date}</span></div>
                  </div>
                  <div style={{ clear: 'both' }}></div>
                </div>
              )

            }
            else {
              return (
                <div style={style.msg} key={key + '_' + index}>

                  <div style={{ float: 'left' }}>
                    <Image circular size='mini' floated='left' src={img ? getUserImage(projectKey, userKey, img) : noUserImage} alt='' />

                    <div style={{ display: 'inline-block', padding: '5px', paddingRight: '15px', borderTopLeftRadius: '20px', borderBottomRightRadius: '20px', borderTopRightRadius: '20px', background: 'rgba(0, 0, 0, 0.05)', minWidth: '200px' }}>
                      <div className='break-long-words'> {msg.m}</div>
                    </div>
                    <div style={{ fontSize: '11px', color: 'gray', lineHeight: '15px', paddingLeft: '5px' }}>{name} <span style={{ fontSize: '9px', color: 'gray', }}>{date}</span></div>
                  </div>
                  <div style={{ clear: 'both' }}></div>

                </div>
              )
            }
          })}
          {this.state.chatName === 'system' && this.state.chatData.map((msg, index) => {
            const key = msg.d ? msg.d : msg.m
            const date = msg.d ? moment(msg.d).fromNow() : ''
            return (
              <div style={style.msg} key={key + '_' + index}>
                <div style={{ fontSize: '14px', color: 'gray', lineHeight: '15px' }}><span style={{ fontSize: '9px' }}>{date}</span></div>
                {msg.m}
                <div style={{ clear: 'both' }}></div>
              </div>
            )
          })}
          <div style={{ flexGrow: '1' }}></div>
          {/* <div style={{ minHeight: this.props.isLandscape === true ? '50px' : '85px' }}></div> */}

        </div>
      </React.Fragment >
    )
  }
}


const mapActions = {};
const mapStateToProps = (state, ownProps, ownState) => {

  return {
    projectKey: state.firebase.profile.projectKey,
    project: state.firestore.data.project,
    chanelData: state.firestore.data['messagesListner_' + ownProps.chanelKey],
    currentUser: state.firebase.profile,
    isLandscape: state.viewstate.isLandscape,

  };
};
export default connect(
  mapStateToProps,
  mapActions
)(TextMessagesMessage);
