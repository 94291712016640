import React, { Component } from "react";
import PropTypes from "prop-types";

import {
  Form,
  Input,
  Image,
  Loader,
  Button,
  Icon,
} from "../../common/component/Button";
import { connect } from "react-redux";
import { Factory } from "../../../core/util/Factory";
import { closeEventModal } from "../../../core/redux/action/modalAction";
import { addTemplateShift } from "../../../core/redux/action/eventAction";
import { getTimeFromMins, getDateKey } from "../../../core/util/helpers";
import { isMobile } from "react-device-detect";
import FirestoreListnerHelper from "../../../core/util/FirestoreListnerHelper";
import { FireHelper } from "../../../core/util/FireHelper";
import {
  openEventModal,
  openModal,
} from "../../../core/redux/action/modalAction";
import { setCloneShift } from "../../../core/redux/action/viewstatAction";

import InputToggle from "../../common/form/InputToggle";

import { toastr } from "react-redux-toastr";
import { getUserlist } from "../../../core/redux/selectors";

import {
  setSchedule,
  copyScheduleShift,
} from "../../../core/redux/action/currentScheduelDataAction";
import { Field, reduxForm } from "redux-form";
import moment from "moment";
import { renderTimeLable, getUserImage } from "../../../core/util/helpers";
import noUserImage from "../../../img/noimg.png";
import captureException from "sen-obj";

import TimeSlider from "../../common/form/TimeSlider";
import ColorSlider from "../../common/form/ColorSlider";
import BreakSlider from "../../common/form/BreakSlider";
import ModalFrame from "./ModalFrame";
import Tab from "../../common/component/Tab";
import RequestMiniView from "../../common/component/Request/RequestMiniView";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendar as icoDate,
  faClock as icoBrake,
  faTag as icoLabel,
  faTags as icoComment,
  faComment as icoUserComment,
  faTrash as icoDelete,
  faPaperPlane as icoRequest,
  faBed as icoSick,
  faPaintBrushAlt as icoClone,
} from "@fortawesome/pro-duotone-svg-icons";
import { faCheck as icoSave } from "@fortawesome/free-solid-svg-icons";

import { isElectron } from "react-device-detect";

class EditEventModal extends Component {
  //static contextTypes = { store: PropTypes.object.isRequired }
  state = {
    tabIndex: 0,
    showDate1: false,
    showDate2: false,
    showColor: false,
    showBreake: false,
  };
  isSave = true;
  constructor(props) {
    super(props);
    this.isSave = true;
  }

  componentWillUnmount = () => {};

  componentDidMount = () => {
    // console.log('>>componentDidMount eventModal')
  };

  componentDidUpdate = async (prevProps, prevState, snapshot) => {
    if (this.props.eventObj) {
      const shiftItem = document.getElementById(this.props.eventObj.key);
      if (shiftItem) {
        shiftItem.style.setProperty("border", "solid 2px gold", "important");
        shiftItem.style.setProperty("margin", "1px", "important");
        //TODO SCROLL TO THIS
        // const container = document.getElementById('tbodySchedule1')
        // if (container) {
        //   const containerPos = container.scrollHeight
        //   container.scrollTo(0, (containerPos - shiftItem.offsetTop))
        // }
      }
    }

    if (
      prevProps &&
      prevProps.eventObj &&
      prevProps.eventObj !== this.props.eventObj
    ) {
      const prevShiftItem = document.getElementById(prevProps.eventObj.key);
      if (prevShiftItem) {
        prevShiftItem.style.setProperty("border", "");
        prevShiftItem.style.setProperty("margin", "");
      }
    }

    if (prevProps && prevProps.isOpen === true && this.props.isOpen === false) {
      if (isElectron) {
        if (window._modals["eventModal"])
          window._modals["eventModal"].resizeTo(360, 280);
        if (this.props.formState.initial.isNew) {
          //save the new shift
          // console.log('<<save the new shift', prevProps.eventObj)
          this.isSave = true;
          this.onSubmitOnClose(this.props.formState.values, prevProps.eventObj);
        } else if (
          this.props.formState.values !== this.props.formState.initial
        ) {
          //if change save the old shift
          // console.log('<<if change save the old shift')
          this.onSubmitOnClose(this.props.formState.values, prevProps.eventObj);
        } else {
          // dont save if old and not changed!
          // console.log('<<dont save if old and not changed!')
          this.isSave = false;
          this.setState({
            showColor: false,
            showDate1: false,
            showDate2: false,
            showBreake: false,
          });
          this.props.onCloseModal();
        }
      }
    }

    const { doLoad } = this.props;
    if (doLoad) {
      const dateFirstDayOfWeek = moment.utc(doLoad.dateStr).startOf("week");
      const dateKey = getDateKey(dateFirstDayOfWeek);
      //const eventDataKey = doLoad.shedualKey + '_' + dateKey
      const shiftKey = doLoad.key;

      //

      //Old wheen we picked evetn from events
      //const loaded = this.props.fireLoaded[`projects/${this.props.projectKey}/schedules/${doLoad.shedualKey}/events/${dateKey}`]

      const loaded = this.props.fireLoaded[`weekview_${dateKey}`];

      //¡ if (loaded !== true)
      FirestoreListnerHelper.setListner(
        this.context,
        dateFirstDayOfWeek,
        doLoad.shedualKey
      );

      if (loaded) {
        // console.log('doLoad', doLoad)
        //TODO this need to get the shift from the weekview , not the workview

        var weekview = this.props.fireData[`weekview_${dateKey}`] || {};
        var schedule = weekview[doLoad.shedualKey] || { rows: [] };
        var userRow = (schedule.rows.filter((x) => x.k === doLoad.userKey) ||
          [])[0] || { s: {} };
        var shift;
        var date;

        var firstDay = this.props.viewstate.firstDayOfWeek;

        for (let index = 0; index < 8; index++) {
          const shiftArray = userRow.s[index];
          if (shiftArray) {
            shift = (shiftArray.filter((x) => x.k === shiftKey) || [])[0];
            if (firstDay === 0)
              date = dateFirstDayOfWeek
                .clone()
                .utc()
                .add(index, "days");
            else
              date = dateFirstDayOfWeek
                .clone()
                .utc()
                .add(index - 1, "days");
          }
          if (shift) break;
        }

        // var events = (this.props.fireData[eventDataKey] || {}).events || {}
        // var event = events[shiftKey]
        if (shift) {
          //console.log('shift', shift)
          var event = Factory.unpackCompactShift(shift, doLoad.userKey, date);
          // console.log('event', event)

          if (event) {
            this.props.openEventModal({
              ...event,
              standALone: {
                scheduleKey: doLoad.shedualKey,
                dateKey,
                dateFirstDayOfWeek,
              },
            });
          } else {
            console.log("can't find Shift", shiftKey);
          }
        } else {
          console.log("can't find Shift", shiftKey);
        }
      }
    }
  };

  bodyClick = () => {
    // // console.log('bodyClick')
  };
  doSumit = (values, eventObj) => {
    if (
      this.props.currentUser &&
      this.props.currentUser.email === "demo@timeto.work"
    ) {
      toastr.success("Demo account", `No saves are made`);
      console.log("Demo account = exit");
      this.isSave = true;
      this.props.onCloseModal();
      return;
    }

    //// console.log('onSubmit values>', values)
    const {
      onAddTemplateShift,
      curScheduleView,
      onSetSchedule,
      projectKey,
      AmPm,
    } = this.props;
    this.getTotalMin(); //TOOD fix tthis its fro , calculate values

    if (eventObj.standALone) {
      var standAloneObj = eventObj.standALone;
      delete eventObj.standALone;
      delete values.standALone;
      var dayIndex = moment.utc(values.date).isoWeekday();

      // // console.log('values', values)
      FireHelper.setShiftData(
        projectKey,
        standAloneObj.scheduleKey,
        standAloneObj.dateKey,
        eventObj.key,
        { ...values },
        true
      );
      FireHelper.setShiftDataWeekView(
        projectKey,
        standAloneObj.scheduleKey,
        standAloneObj.dateKey,
        eventObj.key,
        { ...values },
        AmPm,
        dayIndex,
        standAloneObj.scheduleKey,
        "scheduleName77",
        false
      );
    } else {
      if (values.template) {
        // // console.log('save temaplele')
        var newTemplateShift = { ...eventObj, ...values };
        // // console.log('before')
        onAddTemplateShift(newTemplateShift);
        //// console.log('after')
      } else {
        if (curScheduleView && curScheduleView.scheduleEventsList) {
          var currentEvent = curScheduleView.scheduleEventsList[eventObj.key];
          //// console.log('eventObj', eventObj)
          if (currentEvent) {
            var newEvent = { ...currentEvent, ...values, isNew: false };
            //// console.log('>> SAVE newEvent', newEvent)
            //TODO parse @start/@end here
            var scheduleEventsList = {
              ...curScheduleView.scheduleEventsList,
              [newEvent.key]: newEvent,
            };
            onSetSchedule(
              { ...curScheduleView, scheduleEventsList },
              "EditEventModal.onSubmit"
            );
            window.dispatchEvent(
              new CustomEvent("doSaveAfterShiftChange", {
                detail: { newEvent },
              })
            );
          }
        }
      }
    }
    this.isSave = true;
    this.props.onCloseModal();
  };
  onSubmitOnClose = (values, eventObj) => {
    //console.log('onSubmitOnClose')
    this.doSumit(values, eventObj);
    // // // console.log('<<onSubmit values>', values)
    // const { onAddTemplateShift, curScheduleView, onSetSchedule } = this.props;
    // this.getTotalMin();//TOOD fix tthis its fro , calculate values
    // if (eventObj.standALone) {
    //   var standAloneObj = eventObj.standALone
    //   delete eventObj.standALone
    //   delete values.standALone
    //   // // console.log('values', values)
    //   FireHelper.setShiftData(this.props.projectKey, standAloneObj.scheduleKey, standAloneObj.dateKey, eventObj.key, { ...values })

    // }
    // else {
    //   if (values.template) {
    //     //// console.log('save temaplele')
    //     var newTemplateShift = { ...eventObj, ...values };
    //     //// console.log('before')
    //     onAddTemplateShift(newTemplateShift);
    //     // // console.log('after')
    //   }
    //   else {

    //     if (curScheduleView && curScheduleView.scheduleEventsList) {
    //       var currentEvent = curScheduleView.scheduleEventsList[eventObj.key];
    //       //// console.log('<<eventObj', eventObj)
    //       if (currentEvent) {
    //         var newEvent = { ...currentEvent, ...values, isNew: false };
    //         //// console.log('<< SAVE newEvent', newEvent)
    //         //TODO parse @start/@end here
    //         var scheduleEventsList = { ...curScheduleView.scheduleEventsList, [newEvent.key]: newEvent };
    //         onSetSchedule({ ...curScheduleView, scheduleEventsList }, "EditEventModal.onSubmit");
    //         window.dispatchEvent(new CustomEvent("doSaveAfterShiftChange", { detail: {} }))

    //       }
    //     }

    //   }

    // }
    this.setState({
      showColor: false,
      showDate1: false,
      showDate2: false,
      showBreake: false,
    });
    // this.isSave = true
    // this.props.onCloseModal();
  };
  onSubmit = (values) => {
    this.doSumit(values, this.props.eventObj);
  };

  removeEvent = () => {
    if (
      this.props.currentUser &&
      this.props.currentUser.email === "demo@timeto.work"
    ) {
      toastr.success("Demo account", `No saves are made`);
      console.log("Demo account = exit");
      this.isSave = true;
      this.props.onCloseModal();
      return;
    }

    const { curScheduleView, eventObj, onSetSchedule, projectKey } = this.props;
    if (eventObj.standALone) {
      var standAloneObj = eventObj.standALone;
      delete eventObj.standALone;
      FireHelper.deleteShiftDataWeekView(
        projectKey,
        standAloneObj.scheduleKey,
        standAloneObj.dateKey,
        eventObj.key
      );
      FireHelper.removeShiftData(
        projectKey,
        standAloneObj.scheduleKey,
        standAloneObj.dateKey,
        eventObj.key
      );
    } else {
      // var currentEvent = curScheduleView.scheduleEventsList[eventObj.key];
      //  if (currentEvent) {
      var eventId = eventObj.key;
      window.dispatchEvent(
        new CustomEvent("onRemoveEvent", { detail: { eventId } })
      );
      // var newEvent = { ...currentEvent, removed: true };
      // var scheduleEventsList = { ...curScheduleView.scheduleEventsList, [newEvent.key]: newEvent };
      // onSetSchedule({ ...curScheduleView, scheduleEventsList }, "EditEventModal.removeEvent");
      // window.dispatchEvent(new CustomEvent("", { detail: {} }))

      //}
    }
    this.isSave = true;
    this.props.onCloseModal();
  };

  pasteShift = (e) => {
    e.preventDefault();
    const copyObj = this.props.copyObj;
    this.props.change("label", copyObj.label);
    this.props.change("comment", copyObj.comment);
    this.props.change("color", copyObj.color);

    this.props.change("hours", copyObj.hours);
    this.props.change("minutes", copyObj.minutes);
    this.props.change("startDate", copyObj.startDate);
    this.props.change("stopDate", copyObj.stopDate);
    this.props.change("timeEnd", copyObj.timeEnd);
    this.props.change("timeStart", copyObj.timeStart);
    this.props.change("breakMin", copyObj.break);

    //setTimeout(() => {
    //this.props.handleSubmit(this.onSubmit)()
    //}, 1000)

    //this.closeModule()
    //this.isSave = true
    //this.props.onCloseModal()
  };
  copyShift = (e) => {
    var newEvent = { ...this.props.eventObj, ...this.props.formState.values };
    console.log("newEvent", newEvent);
    e.preventDefault();
    this.props.setCloneShift(true);
    this.props.onCopyScheduleShift(newEvent);
    toastr.success("", window._getText("shiftIsCloned"));
    this.isSave = true;
    this.props.onCloseModal();
  };
  closeModule = () => {
    // console.log('<< vcloseModule ')

    if (this.isSave === false) {
      const { curScheduleView, eventObj, onSetSchedule } = this.props;
      if (eventObj && eventObj.isNew) {
        var currentEvent = curScheduleView.scheduleEventsList[eventObj.key];
        if (currentEvent) {
          var newEvent = { ...currentEvent, isNew: false, removed: false }; //JENS change from remove:true to false
          var scheduleEventsList = {
            ...curScheduleView.scheduleEventsList,
            [newEvent.key]: newEvent,
          };
          onSetSchedule(
            { ...curScheduleView, scheduleEventsList },
            "EditEventModal.closeModule"
          );
          window.dispatchEvent(
            new CustomEvent("doSaveAfterShiftChange", { detail: {} })
          );
        }
      }
    }
    //alert('closed')
    this.props.onCloseModal();
  };
  getTimeSum = () => {
    const totalMin = Number(this.getTotalMin() || 0);
    const totalBrake = Number(this.props.formState.values.breakMin) || 0; //this.getBreakTime();
    const total = totalMin - totalBrake;
    //// console.log('total', total)
    // const totalHours = (totalMin / 60)
    // const totalHours2 = (total / 60)
    const h = getTimeFromMins(total);
    //`(${totalMin}min / ${totalHours}h)   - ${totalBrake}m =

    // if (total < 0)
    //   return (<span>There is more break then time scheduled</span>)

    return (
      <React.Fragment>
        {/* <span>{total}min / </span> */}
        {h} {window._getText("hours")}
      </React.Fragment>
    );
  };

  getTotalMin = () => {
    const { formState, eventObj } = this.props;

    // try {
    //   var errorCustom = new Error('getTotalMin.eventObj is null')
    //   errorCustom.extra = 'extra data'
    //   throw errorCustom

    // } catch (err) {

    //   console.log(captureException(err))
    // }

    try {
      if (eventObj) {
        const dateStr = moment.utc(eventObj.date).format("YYYY-MM-DD");
        var startDate = moment.utc(`${dateStr} ${formState.values.timeStart}`);
        var stopDate = moment.utc(`${dateStr} ${formState.values.timeEnd}`);
        var brake = Number(formState.values.breakMin);
        if (startDate > stopDate) stopDate.add(1, "days");

        var duration = moment.duration(stopDate.diff(startDate));
        var minutes = duration.asMinutes();

        //TODO DUBBLE SCHEK THIS it might be broken
        //small hack , here we set the start and stop date to save
        formState.values.startDate = startDate.utc().unix() * 1000;
        formState.values.stopDate = stopDate.utc().unix() * 1000;
        formState.values.minutes = minutes - brake;
        formState.values.break = brake;

        return Number(minutes);
      } else {
        // var customError = new Error('EditEventModal.getTotalMin.eventObj is null')
        // customError.projectKey = (this.props.projectKey || 'noProjectKey')
        // captureException(customError);

        return 0;
      }
    } catch (error) {
      console.log("#Err e4ff2", error);
      captureException(error);
      return 0;
    }
  };

  getLabel = () => {
    //const startDate = moment.utc(this.props.formState.values.startDate).format('HH:mm')
    //const stopDate = moment.utc(this.props.formState.values.stopDate).format('HH:mm')
    return renderTimeLable(
      this.props.formState.values.label || "",
      moment.utc(this.props.formState.values.startDate),
      moment.utc(this.props.formState.values.stopDate),
      this.props.AmPm
    );

    //return  //lbl.replace('@start', startDate).replace('@end', stopDate)
  };

  doScroll = (top) => {
    var div = document.getElementById("modalBodyScroll");
    if (div) {
      div.scrollTo(0, top);
      // console.log('top', top)
      // window.log('scroll to ', top)
    }
  };

  onFocus = (e) => {
    // const target = e.target
    // var top = target.parentNode.offsetTop
    // if (document.getElementById('modalBodyScroll')) {
    //   document.getElementById('modalBodyScroll').scrollTo(0, top);
    //   setTimeout(() => { this.doScroll(top) }, 100)
    //   setTimeout(() => { this.doScroll(top) }, 250)
    //   setTimeout(() => { this.doScroll(top) }, 500)
    // }
  };
  onBlur = (e) => {
    // if (document.getElementById('modalBodyScroll')) {
    //   document.getElementById('modalBodyScroll').scrollTo(0, 0);
    //   setTimeout(() => { this.doScroll(0) }, 100)
    //   setTimeout(() => { this.doScroll(0) }, 250)
    //   setTimeout(() => { this.doScroll(0) }, 500)
    // }
  };
  selectUser = () => {
    this.props.openModal("selectUsers");
  };
  changeUser = (user) => {
    this.props.formState.values.uid = user.key;
    this.props.eventObj.uid = user.key;
    this.props.change("uid", user.key);
    this.setState({ reRender: true });
    return true;
  };
  setDay = (day) => (e) => {
    var d = day;
    // if (day === '0')
    //   d = 7

    var dateFirstDayOfWeek;
    if (this.props.eventObj.standALone)
      dateFirstDayOfWeek = this.props.eventObj.standALone.dateFirstDayOfWeek;
    else
      dateFirstDayOfWeek = this.props.currentScheduelData.renderDayDate.utc();

    var rDate = dateFirstDayOfWeek.clone().add(d, "days");

    this.props.formState.values.date = +rDate;
    this.props.eventObj.date = +rDate;
    this.props.change("date", +rDate);
    this.setState({ reRender: true });

    if (e) e.preventDefault();
  };

  toggleColor = (e) => {
    // console.log('toggleColor', this.state.showColor)
    this.setState({
      showColor: !this.state.showColor,
      showDate1: false,
      showDate2: false,
      showBreake: false,
    });
    if (e) e.preventDefault();
  };

  toggleshowBreake = (e) => {
    this.setState({
      showBreake: !this.state.showBreake,
      showDate1: false,
      showDate2: false,
      showColor: false,
    });
    e.preventDefault();
  };
  toggleshowDate1 = (e) => {
    this.setState({
      showDate1: !this.state.showDate1,
      showBreake: false,
      showDate2: false,
      showColor: false,
    });
    e.preventDefault();
  };
  toggleshowDate2 = (e) => {
    this.setState({
      showDate2: !this.state.showDate2,
      showBreake: false,
      showDate1: false,
      showColor: false,
    });
    e.preventDefault();
  };
  hideAllPopups = (e) => {
    this.setState({
      showDate2: false,
      showBreake: false,
      showDate1: false,
      showColor: false,
    });
  };

  setDayValue = (e) => {
    this.setDay(e.target.value === "0" ? 6 : e.target.value - 1)();
  };

  render() {
    let {
      eventObj,
      handleSubmit,
      formState,
      copyObj,
      AmPm,
      doLoad,
      projectKey,
      project,
    } = this.props;
    if (!formState || !formState.values) return null;
    if (!eventObj) return null;
    const cache = project.cache || {};
    const user = cache[eventObj.uid]
      ? { ...cache[eventObj.uid] }
      : { n: "unknown", i: "", sk: null };
    var firstDayOfWeek = this.props.viewstate.firstDayOfWeek;
    var shiftDayOfWeek = moment(eventObj.date)
      .utc()
      .isoWeekday();

    // console.log('eventObj.date', moment(eventObj.date).utc().isoWeekday())
    // console.log('eventObj.date', moment(eventObj.date).isoWeekday())
    // console.log('eventObj.date', moment(eventObj.date).day())
    // console.log('eventObj.date', moment(eventObj.date).toString())
    // console.log('eventObj.standALone', eventObj.standALone)

    if (shiftDayOfWeek === 7) shiftDayOfWeek = 0;

    return (
      <React.Fragment>
        <ModalFrame
          name="eventModal"
          className="modalAniSlideUp"
          open={this.props.isOpen}
          //onClose={this.handleClose}
          dimmer="inverted"
          onClickClose={this.closeModule}
        >
          <ModalFrame.Content>
            <div style={{ minHeight: isElectron ? 200 : 300 }}>
              <Tab
                onChange={(index) => {
                  this.setState({ tabIndex: index });
                  if (window._modals["eventModal"])
                    switch (index) {
                      case 0:
                        window._modals["eventModal"].resizeTo(360, 280);
                        break;
                      case 1:
                        window._modals["eventModal"].resizeTo(360, 280);
                        break;
                      case 2:
                        //window._modals['eventModal'].resizeTo(360, 450)
                        break;
                      default:
                        break;
                    }
                }}
              >
                <Tab.View
                  name="Shift"
                  className="tabX"
                  selectedClassName="tabXselectedStyle"
                >
                  <div
                    style={{
                      padding: "2px",
                      paddingLeft: "6px",
                      overflow: "hidden",
                      xminHeight: "180px",
                    }}
                    onMouseDown={this.hideAllPopups}
                  >
                    {doLoad && (
                      <div style={{ marginTop: "50px" }}>
                        <Loader inline active>
                          {window._getText("loading")}
                        </Loader>
                      </div>
                    )}

                    {eventObj.requests && (
                      <React.Fragment>
                        <div
                          style={{
                            zIndex: 10000,
                            padding: 20,
                            position: "absolute",
                            top: 78,
                            left: 50,
                            border: "solid 1px gray",
                            background: "#fff",
                            borderRadius: 10,
                            width: "calc(100% - 100px)",
                            height: 100,
                            fontWeight: "bold",
                          }}
                        >
                          Please handle requests before doing any changes to the
                          shift.
                        </div>
                        <div
                          style={{
                            position: "absolute",
                            top: 48,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            background: "#fff",
                            opacity: 0.5,
                          }}
                          onClick={(e) => e.preventDefault()}
                        ></div>
                      </React.Fragment>
                    )}

                    <React.Fragment>
                      {!doLoad && (
                        <Form
                          onSubmit={handleSubmit(this.onSubmit)}
                          style={{
                            margin: "0px",
                            display: "flex",
                            flexDirection: "column",
                            height: "calc(100% - 65px)",
                          }}
                        >
                          {/* <div style={{ border: 'solid 1px red', position: 'absolute', width: '35px', height: '145px' }}></div> */}

                          <div
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <div style={{ width: 60, height: 30 }}>
                              <b>Staff :</b>
                            </div>
                            <div
                              style={{ marginLeft: "10px", marginRight: "2px" }}
                            >
                              {eventObj.uid === "openshifts" ||
                              eventObj.uid === "unassigned" ? (
                                <Image
                                  src={noUserImage}
                                  circular
                                  size="mini"
                                  draggable="false"
                                  onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = noUserImage;
                                  }}
                                  style={{ width: "20px", height: "20px" }}
                                />
                              ) : (
                                <Image
                                  src={
                                    user.i
                                      ? getUserImage(
                                          projectKey,
                                          eventObj.uid,
                                          user.i
                                        )
                                      : noUserImage
                                  }
                                  circular
                                  size="mini"
                                  style={{ width: "20px", height: "20px" }}
                                  draggable="false"
                                  onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = noUserImage;
                                  }}
                                />
                              )}
                            </div>
                            <div style={{ border: "solid 0px red" }}>
                              <span
                                style={{
                                  paddingTop: "3px",
                                  paddingBottom: "3px",
                                  borderRadius: "5px",
                                }}
                                className="editHover"
                              >
                                <select
                                  autoFocus
                                  onChange={(e) =>
                                    this.changeUser({ key: e.target.value })
                                  }
                                  value={eventObj.uid}
                                  className="selectEventForm"
                                >
                                  {this.props.optionList}
                                </select>
                              </span>
                            </div>

                            <Field
                              name="color"
                              component={ColorSlider}
                              isMobile={isMobile}
                              showColor={this.state.showColor}
                              toggle={this.toggleColor}
                            />
                          </div>

                          <div
                            style={{
                              padding: "1px",
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <div
                              style={{
                                border: "solid 0px green",
                                display: "flex",
                                flexDirection: "row",
                                paddingRight: "4px",
                              }}
                            >
                              <div
                                style={{
                                  fontWeight: "bold",
                                  marginRight: "8px",
                                  width: 60,
                                  height: 30,
                                }}
                              >
                                {/* <FontAwesomeIcon icon={icoDate} style={{ fontSize: '19px' }}></FontAwesomeIcon> */}
                                <b>Date :</b>
                              </div>
                              <div style={{ paddingRight: "4px" }}>
                                {firstDayOfWeek === 0 && (
                                  <span
                                    style={{
                                      paddingTop: "3px",
                                      paddingBottom: "3px",

                                      borderRadius: "5px",
                                    }}
                                    className="editHover"
                                  >
                                    <select
                                      style={{ textTransform: "capitalize" }}
                                      onChange={(e) =>
                                        this.setDay(e.target.value)()
                                      }
                                      value={shiftDayOfWeek}
                                      className="selectEventForm"
                                    >
                                      <option value={0}>
                                        {window
                                          ._getText("sunday")
                                          .substring(0, 3)}
                                      </option>
                                      <option value={1}>
                                        {window
                                          ._getText("monday")
                                          .substring(0, 3)}
                                      </option>
                                      <option value={2}>
                                        {window
                                          ._getText("tuesday")
                                          .substring(0, 3)}
                                      </option>
                                      <option value={3}>
                                        {window
                                          ._getText("wednesday")
                                          .substring(0, 3)}
                                      </option>
                                      <option value={4}>
                                        {window
                                          ._getText("thursday")
                                          .substring(0, 3)}
                                      </option>
                                      <option value={5}>
                                        {window
                                          ._getText("friday")
                                          .substring(0, 3)}
                                      </option>
                                      <option value={6}>
                                        {window
                                          ._getText("saturday")
                                          .substring(0, 3)}
                                      </option>
                                    </select>
                                  </span>
                                )}
                                {firstDayOfWeek === 1 && (
                                  <span
                                    style={{
                                      padding: "3px",

                                      borderRadius: "5px",
                                    }}
                                    className="editHover"
                                  >
                                    <select
                                      style={{ textTransform: "capitalize" }}
                                      onChange={this.setDayValue}
                                      value={shiftDayOfWeek}
                                      className="selectEventForm"
                                    >
                                      <option value={1}>
                                        {window
                                          ._getText("monday")
                                          .substring(0, 3)}
                                      </option>
                                      <option value={2}>
                                        {window
                                          ._getText("tuesday")
                                          .substring(0, 3)}
                                      </option>
                                      <option value={3}>
                                        {window
                                          ._getText("wednesday")
                                          .substring(0, 3)}
                                      </option>
                                      <option value={4}>
                                        {window
                                          ._getText("thursday")
                                          .substring(0, 3)}
                                      </option>
                                      <option value={5}>
                                        {window
                                          ._getText("friday")
                                          .substring(0, 3)}
                                      </option>
                                      <option value={6}>
                                        {window
                                          ._getText("saturday")
                                          .substring(0, 3)}
                                      </option>
                                      <option value={0}>
                                        {window
                                          ._getText("sunday")
                                          .substring(0, 3)}
                                      </option>
                                    </select>
                                  </span>
                                )}
                              </div>

                              <div style={{ border: "solid 0px green" }}>
                                <Field
                                  name="timeStart"
                                  AmPm={AmPm}
                                  component={TimeSlider}
                                  lable="Shift start"
                                  toggle={this.toggleshowDate1}
                                  show={this.state.showDate1}
                                />
                              </div>
                              <div
                                style={{
                                  paddingLeft: "5px",
                                  paddingRight: "5px",
                                }}
                              >
                                -
                              </div>
                              <div style={{ border: "solid 0px green" }}>
                                <Field
                                  right={true}
                                  name="timeEnd"
                                  AmPm={AmPm}
                                  component={TimeSlider}
                                  lable="Shift end"
                                  toggle={this.toggleshowDate2}
                                  show={this.state.showDate2}
                                />
                              </div>
                            </div>

                            <div
                              style={{ marginLeft: "auto", textAlign: "right" }}
                            >
                              {" "}
                              <span className="shiftSumHours">
                                {this.getTimeSum()}
                              </span>
                            </div>
                          </div>

                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              padding: "1px",
                            }}
                          >
                            <div
                              style={{
                                fontWeight: "bold",
                                marginRight: "9px",
                                width: 60,
                                height: 30,
                              }}
                              tooltip={window._getText("TotalBreakTime")}
                            >
                              {/* <FontAwesomeIcon icon={icoBrake} style={{ fontSize: '19px' }}></FontAwesomeIcon> */}
                              <b>Break :</b>
                            </div>
                            <Field
                              name="breakMin"
                              AmPm={AmPm}
                              sumLable=""
                              component={BreakSlider}
                              toggle={this.toggleshowBreake}
                              show={this.state.showBreake}
                            />
                            <span className="shiftSumHours">
                              {window._getText("minutes")}
                            </span>

                            <div style={{ flexGrow: "1" }}></div>
                          </div>

                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              padding: "1px",
                            }}
                          >
                            <div
                              style={{
                                fontWeight: "bold",
                                marginRight: "6px",
                                width: 60,
                                height: 30,
                              }}
                              tooltip={window._getText("ShiftLabel")}
                            >
                              {/* <FontAwesomeIcon icon={icoLabel} style={{ fontSize: '19px' }}></FontAwesomeIcon> */}
                              <b>Lable :</b>
                            </div>
                            <Field
                              autoComplete="new-password"
                              name="label"
                              component={Input}
                              className="fieldInput editHover"
                              type="text"
                              placeholder="@start - @end"
                              maxLength={30}
                              style={{
                                width: "80%",
                                height: "25px",

                                borderRadius: "5px",
                                maxWidth: "395px",
                                borderBottom: "1px dotted var(--color-border)",
                              }}
                            />
                            <div style={{ flexGrow: "1" }}></div>
                          </div>

                          <div
                            style={{
                              borderBottom: "solid 0px var(--color-border)",
                              display: "flex",
                              padding: "1px",
                            }}
                          >
                            <div
                              style={{
                                fontWeight: "bold",
                                marginRight: "6px",
                                width: 60,
                                height: 30,
                              }}
                              tooltip={window._getText(
                                "shiftuserinput.comment"
                              )}
                            >
                              {/* <FontAwesomeIcon icon={icoComment} style={{ fontSize: '19px' }}></FontAwesomeIcon> */}
                              <b>Lable 2 :</b>
                            </div>

                            <Field
                              style={{
                                width: "80%",
                                maxWidth: "395px",
                                height: "25px",

                                borderRadius: "5px",
                                borderBottom: "1px dotted var(--color-border)",
                              }}
                              className="fieldInput editHover"
                              autoComplete="new-password"
                              maxLength={100}
                              name="comment"
                              component={Input}
                              type="text"
                              placeholder={window._getText(
                                "shiftuserinput.comment"
                              )}

                              // onFocus={this.onFocus}
                              // onBlur={this.onBlur}
                            />
                          </div>
                          {eventObj.userCom && (
                            <div
                              style={{
                                borderBottom: "solid 0px var(--color-border)",
                                display: "flex",
                                padding: "1px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              <div
                                style={{
                                  fontWeight: "bold",
                                  marginRight: "6px",
                                  width: 60,
                                  height: 30,
                                }}
                                tooltip={window._getText(
                                  "shiftuserinput.usercomment"
                                )}
                              >
                                {/* <FontAwesomeIcon icon={icoUserComment} style={{ fontSize: '19px' }}></FontAwesomeIcon> */}
                                <b>User c.. :</b>
                              </div>
                              <Field
                                style={{
                                  width: "100%",
                                  maxWidth: "395px",
                                  height: "25px",
                                }}
                                className="fieldInput editHover"
                                autoComplete="new-password"
                                maxLength={100}
                                name="userCom"
                                component={Input}
                                type="text"
                                placeholder=""
                                // onFocus={this.onFocus}
                                // onBlur={this.onBlur}
                              />
                            </div>
                          )}

                          <Field
                            name="startDate"
                            component={Input}
                            type="hidden"
                          />
                          <Field
                            name="stopDate"
                            component={Input}
                            type="hidden"
                          />
                          <Field
                            name="minutes"
                            component={Input}
                            type="hidden"
                          />
                          <Field name="break" component={Input} type="hidden" />
                          <div style={{ display: "flex" }}>
                            <div style={{ paddingTop: "2px" }}>
                              <div style={{ display: "flex" }}>
                                <div
                                  style={{
                                    fontWeight: "bold",
                                    marginRight: "12px",
                                    width: 60,
                                    height: 30,
                                  }}
                                  tooltip_t={window._getText("sick")}
                                >
                                  {/* <FontAwesomeIcon icon={icoSick} style={{ fontSize: '19px' }}></FontAwesomeIcon> */}
                                  <b>Sick :</b>
                                </div>

                                <div
                                  style={{
                                    verticalAlign: "center",
                                    transform: "scale(0.7,0.7)",
                                  }}
                                >
                                  <Field name="sick" component={InputToggle} />
                                </div>
                                <div style={{ verticalAlign: "center" }}>
                                  <span className="shiftSumHours">
                                    {" "}
                                    {window._getText("sick")}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Form>
                      )}
                    </React.Fragment>
                  </div>
                </Tab.View>
                {/* <Tab.View name='Details' className='tabX' selectedClassName='tabXselectedStyle'>
                <div>
                  <Field name="note"
                    style={{
                      width: '100%',
                      height: '70%',
                      paddingTop: '9px',
                      border: 'solid 1px var(--color-border)',
                      backgroundColor: 'rgba(255,255,255,0.8)',
                      borderRadius: '4px',
                    }}
                    maxLength={200}
                    className='fieldInput tbTrans'
                    autoComplete="new-password"
                    component='textarea'
                    type="text"
                    placeholder={window._getText('Private notes')}
                  />
                </div>
              </Tab.View> */}
                <Tab.View
                  name="Requests"
                  className="tabX"
                  selectedClassName="tabXselectedStyle"
                >
                  <RequestMiniView
                    onDone={(doc) => {
                      toastr.success("Updating request...", 3);
                      //dont work....
                      //eventObj.requests = eventObj.requests.filter(x => x.id !== doc.id) ///optimistic update...

                      // TODO move this...
                      this.closeModule();
                    }}
                    shift={eventObj}
                    currentUserKey={this.props.currentUser.inSysUserKey}
                  ></RequestMiniView>
                </Tab.View>
              </Tab>
            </div>
          </ModalFrame.Content>

          <ModalFrame.Actions>
            <div style={{ minHeight: 25 }}>
              {this.state.tabIndex === 0 && (
                <React.Fragment>
                  <div style={{ flexGrow: "1" }}></div>
                  <div style={{ display: "flex" }}>
                    <button
                      onClick={this.removeEvent}
                      style={{
                        background: "none",
                        border: 0,
                        marginRight: "10px",
                        fontWeight: "normal",
                      }}
                    >
                      <FontAwesomeIcon icon={icoDelete}></FontAwesomeIcon>
                    </button>

                    {!eventObj.standALone && (
                      <React.Fragment>
                        <button
                          onClick={this.copyShift}
                          style={{
                            background: "none",
                            border: 0,
                            marginRight: "10px",
                            fontWeight: "normal",
                          }}
                        >
                          <FontAwesomeIcon icon={icoClone}></FontAwesomeIcon>{" "}
                          Clone
                        </button>

                        <button
                          style={{
                            background: "none",
                            border: 0,
                            marginRight: "10px",
                            fontWeight: "normal",
                          }}
                          onClick={(e) => {
                            e.preventDefault();
                            if (eventObj) {
                              //this.props.onCloseModal()
                              handleSubmit(this.onSubmit)();
                              setTimeout(() => {
                                window.dispatchEvent(
                                  new CustomEvent("onCreateShiftRequest", {
                                    detail: { eventId: eventObj.key },
                                  })
                                );
                              }, 1);
                            }
                          }}
                        >
                          <FontAwesomeIcon icon={icoRequest}></FontAwesomeIcon>{" "}
                          Send
                        </button>
                      </React.Fragment>
                    )}

                    <button
                      style={{
                        background: "none",
                        border: 0,
                        marginLeft: "auto",
                        fontWeight: "normal",
                      }}
                      type="submit"
                      onClick={handleSubmit(this.onSubmit)}
                    >
                      <FontAwesomeIcon
                        style={{ fontSize: 20 }}
                        icon={icoSave}
                      ></FontAwesomeIcon>
                    </button>
                  </div>
                </React.Fragment>
              )}
            </div>
          </ModalFrame.Actions>
        </ModalFrame>

        {/* <SelectUsers name='shift' isMultiSelect={true} onSelect={this.changeUser}></SelectUsers> */}
      </React.Fragment>
    );
  }
}

const mapActions = {
  onCloseModal: closeEventModal,
  onSetSchedule: setSchedule,
  onCopyScheduleShift: copyScheduleShift,
  onAddTemplateShift: addTemplateShift,
  openEventModal,
  openModal,
  setCloneShift,
};

const mapStateToProps = (state, ownProps) => {
  if (state.modal.eventModalOpen === false) {
    return {
      isOpen: state.modal.eventModalOpen,
      formState: state.form.eventForm,
    };
  }

  let eventObj = state.modal.event || {};
  let copyObj = {};

  //TODO:cache optionlist CACHEME!
  var allUsers = getUserlist(state).userList; // FireHelper.getUserlist(state.firestore.data.users, state.firebase.profile.projectKey).userList;
  var optionList;
  if (allUsers) {
    allUsers = allUsers.filter((u) => u.act === true);
    allUsers = allUsers.sort((a, b) => a - b);

    var ABC = [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z",
    ];
    var mapAbcUsers = {};
    allUsers.forEach((u) => {
      var firstChar = (u.text || "").substring(0, 1).toUpperCase();
      if (!mapAbcUsers[firstChar]) mapAbcUsers[firstChar] = [];

      mapAbcUsers[firstChar].push(u);

      if (ABC.indexOf(firstChar) === -1) ABC.push(firstChar);
    });

    optionList = ABC.map((char) => {
      // console.log('char', char)

      if (mapAbcUsers[char])
        return (
          <optgroup label={char} key={char}>
            {mapAbcUsers[char].map((u) => (
              <option value={u.key} key={u.key}>
                {u.text}
              </option>
            ))}
          </optgroup>
        );
      else return null;
    });

    optionList.unshift(
      <optgroup label="" key="openshifts1">
        <option value="unassigned" key="unassigned">
          {window._getText("schedual.lbl-unassigned")}
        </option>
        <option value="openshifts" key="openshifts">
          {window._getText("schedual.lbl-openshifts")}
        </option>
      </optgroup>
    );

    //// console.log('optionList', optionList)
  }

  if (eventObj.doLoad) {
    //   console.log('>>doLoad')
  } else {
    copyObj = state.currentScheduelData.copyShiftObject;

    var curScheduleView =
      state.currentScheduelData[
        state.currentScheduelData.selectedKey +
          "_" +
          state.currentScheduelData.selectedDateKey
      ];
    if (!eventObj) {
      console.log("eventobj is NULL", eventObj);
      eventObj = null; //TODO default values
    }
    if (eventObj) {
      if (!eventObj.timeStart) eventObj.timeStart = "00:00";

      if (!eventObj.timeEnd) eventObj.timeEnd = "00:00";

      if (!eventObj.breakMin) eventObj.breakMin = 0;

      if (!eventObj.sick) eventObj.sick = false;
    }
  }

  return {
    optionList: optionList,
    fireData: state.firestore.data,
    fireLoaded: state.firestore.status.requested,
    projectKey: state.firebase.profile.projectKey,
    project: state.firestore.data.project,

    doLoad: eventObj.doLoad,
    copyObj,
    isOpen: state.modal.eventModalOpen,
    enableReinitialize: true,
    initialValues: eventObj,
    eventObj,
    curScheduleView,
    formState: state.form.eventForm,
    AmPm: state.firestore.data.project.s
      ? state.firestore.data.project.s.AmPm || false
      : false,
    viewstate: state.viewstate,
    currentScheduelData: state.currentScheduelData, // WE only need this fro the render date , maybe we can do this better ?
    currentUser: state.firebase.profile,
  };
};
export default connect(
  mapStateToProps,
  mapActions
)(reduxForm({ form: "eventForm" })(EditEventModal));
