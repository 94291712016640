import { toastr } from 'react-redux-toastr'
import shortid from 'shortid'
import "firebase/storage";
import captureException from 'sen-obj';
// import $ from 'jquery'
// import faker from "faker";

export const UPDATE_USER = 'users:updateUsers'
export const UPDATE_ERROR = 'users:showError'

// set the state data here
export function showError() {
    //console.log('action.showError');

    return {
        type: UPDATE_ERROR,
        payload: { name: 'error user' }
    }
}


export const uploadProfileImage = (file, userKey, projectKey) => async (dispatch, getState, { getFirebase, getFirestore }) => {
    console.log('>>>>>uploadProfileImage')
    // const imageName = shortid.generate();
    const firebase = getFirebase();
    // const firestore = getFirestore();
    //const projectKey = firebase.auth().currentUser.projectKey;
    const path = `p/${projectKey}/t`;
    const options = {
        public: true, name: userKey,
        cacheControl: 'public, max-age=31536000'
    };
    // 

    let downloadURL = ''
    try {
        //  dispatch(asyncActionStart());
        // upload the file to firebase storage
        let uploadedFile = await firebase.uploadFile(path, file, null, options);
        // get url of the image
        downloadURL = await uploadedFile.uploadTaskSnapshot.ref.getDownloadURL()

        // Create file metadata to update
        var newMetadata = {
            cacheControl: 'public, max-age=public, max-age=31536000',
            contentType: 'image/jpeg'
        }

        // Update metadata properties
        uploadedFile.uploadTaskSnapshot.ref.updateMetadata(newMetadata).then(function (metadata) {
            // console.log('metadata', metadata)
            // Updated metadata for 'images/forest.jpg' is returned in the Promise
        }).catch(function (error) {
            console.log('error', error)
            // Uh-oh, an error occurred!
        });


    } catch (error) {
        console.log(error);
        captureException(error);
        //dispatch(asyncActionError());
        throw new Error('Problem uploading photo');
    }
    return downloadURL;
};


export const deleteUser = user => async (dispatch, getState, { getFirebase, getFirestore }) => {

    try {
        const firestore = getFirestore();
        const projectKey = getState().firebase.profile.projectKey;
        const delUser = { ...user }

        await firestore.doc(`projects/${projectKey}/users/userlist`).set({ [delUser.key]: firestore.FieldValue.delete() }, { merge: true })
        if (delUser.image) {
            var imgRef = getFirebase().storage().ref(`p/${projectKey}/t/${delUser.key}`);
            imgRef.delete().then(function () {
                //console.log('delet done');
            }).catch(function (error) {
                console.log('#err Deleting file , ', error);
            });
        }
        console.log('>deleteUser>>Done')
        if (toastr)
            toastr.success(`User '${delUser.name}' was successfully deleted`); //movet toster out from lib!
    } catch (error) {
        console.log('deleteUser', error)

        if (toastr)
            toastr.error('Error', error); //movet toster out from lib!
        captureException(error);
    }

}
export const OLDaddUser = user => async (dispatch, getState, { getFirebase, getFirestore }) => {
    let r = { success: true, msg: '' }

    try {
        const firestore = getFirestore();
        const projectKey = getState().firebase.profile.projectKey;

        const newUser = {
            ...user,
            updatedAt: firestore.FieldValue.serverTimestamp()
        }

        if (!newUser.key)
            newUser.key = shortid.generate()

        delete newUser.newUser
        delete newUser.isDeleted

        await firestore.doc(`projects/${projectKey}/users/userlist`).set({ [newUser.key]: newUser }, { merge: true })

        toastr.success(`User '${newUser.name}' was successfully created`); //movet toster out from lib!

    } catch (error) {
        console.log('OLDaddUser', error)
        toastr.error('Error', error); //movet toster out from lib!
        r.success = false;
        r.msg = error.message
        captureException(error);
    }
    return r

}

export const setUser = user => async (dispatch, getState, { getFirebase, getFirestore }) => {

    return new Promise(async (resolve, reject) => {


        let r = { success: true, msg: '' }
        try {
            const firestore = getFirestore();
            delete user.newUser
            const updatedUser = {
                ...user,
                updatedAt: firestore.FieldValue.serverTimestamp()
            }
            const projectKey = getState().firebase.profile.projectKey;
            await firestore.doc(`projects/${projectKey}/users/userlist`).set({ [updatedUser.key]: updatedUser }, { merge: true })
        } catch (error) {
            console.log(error);
            //toastr.error('Error', error);
            r.success = false;
            r.msg = error.message
            captureException(error);

        }

        resolve(r)

    })
};




























