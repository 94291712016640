import React, { Component, useContext } from "react";

import { Route, Switch, withRouter, Redirect } from "react-router-dom";
import { store } from "../../../core/redux/store/configStore";
import { version } from "../../../../package.json";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import { isMobile, osName } from "react-device-detect";
import {
  initRenderDate,
  setLeftMenuOpen,
} from "../../../core/redux/action/viewstatAction";

import { lSignOut } from "./Loader";
import SettingsModal from "./SettingsModal";
import AppMenu from "../modal/AppMenu";
import OnboardingModule from "../modal/OnboardingModule";
import Schedule from "../schedule/Schedule";
import Users from "../schedule/users/Users";
import Settings from "../schedule/settings/Settings";
import SignOut from "../login/SignOut";
import UserHome from "../userhome/Userhome";
import ScheduleUser from "../userhome/ScheduleUser";
import Messages from "../messages/Messages";
import Requests from "../messages/Requests";
import RequestsMy from "../messages/RequestsMy";
import Tasks from "../tasks/Tasks";

import Chat from "../messages/Chat";
import Dev from "../dev/Dev";
import LoginRouter from "./LoginRouter";
import { closeModal, openModal } from "../../../core/redux/action/modalAction";
import firebase from "../../../core/config/firebase";
import { FireHelper } from "../../../core/util/FireHelper";
import MainMenu from "../../common/menu/MainMenu";

import Joyrider from "../../common/component/Joyrider";
import JoyriderTour from "../../common/component/JoyriderTour";
import { withGlobalState } from "../../../globalState";

import { isElectron, isBrowser } from "react-device-detect";

import NewUserRedirecter from "../userhome/NewUserRedirecter";
import Warning from "../schedule/settings/Warning";
import Help from "../help/Help";
import Tour from "../tour/Tour";
import Reports from "../reports/Reports";
import ScheduleTodayTaskbar from "../schedule/ScheduleTodayTaskbar";
import ScheduleToday from "../schedule/ScheduleToday";
//import ElectronTodayPortal from "../modal/ElectronTodayPortal";
import { getUserImage } from "../../../core/util/helpers";
import UserModal from "../modal/UserModal";
import CopyScheduleModal from "../modal/CopyScheduleModal";
import ReactGA from "react-ga";
import FirestoreListnerHelper from "../../../core/util/FirestoreListnerHelper";
import BillingBig from "../schedule/settings/BillingBig";
import { createBrowserHistory } from "history";
import LogRocket from "logrocket";

const history = createBrowserHistory();

let isMacOs = process.env.REACT_APP_MACOS === "1";

let ipcRenderer;
let shell;
const showWebMenu = isElectron === false && isBrowser;
if (isElectron) {
  const elApp = window.require("electron");
  ipcRenderer = elApp.ipcRenderer;
  shell = elApp.shell;
}

class AppLoggedInRouter extends Component {
  state = {
    msgCount: 0,
    leftMenuOpen: true,
  };
  listners = {};
  constructor(props) {
    super(props);

    const { currentUser } = this.props;
    const firstDayOfWeekNumber = Number(currentUser.firstDay); //sunday 0 , monday 1

    // console.log('currentUser', currentUser)

    if (currentUser.countryInfo) {
      window._countryCode = currentUser.countryInfo.countryCode;
      window._calling_code = currentUser.countryInfo.callCode;
    }

    this.props.initRenderDate(firstDayOfWeekNumber);
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    if (prevProps && prevProps.isLeftMenuOpen !== this.props.isLeftMenuOpen) {
      this.setState({ leftMenuOpen: this.props.isLeftMenuOpen });
    }

    if (
      prevProps &&
      prevProps.currentUser &&
      this.props.currentUser &&
      prevProps.currentUser.role !== this.props.currentUser.role
    ) {
      if (prevProps.currentUser.role > this.props.currentUser.role) {
        //signout
        this.goTo("/signout")();
        return;
      } else {
        this.counter = 0;
        this.updateClaim();
        this.checkPushToken();
      }
    } else if (prevProps.token !== this.props.token) {
      //console.log('>>UPDATE TOKEN')
      this.checkPushToken();
    }

    const { project } = this.props;

    if (window._AmPm === undefined) {
      const p = project || { s: { AmPm: false, firstDay: 0 } };
      window._AmPm = p.AmPm;
      window._firstDay = p.firstDay;
    }

    if (this.props.currentUser.email !== "demo@timeto.work")
      if (project && project.credits > 1) {
        //if (!localStorage.getItem('openRatingDialog')) {
        if (this.timerSetReview) {
          clearTimeout(this.timerSetReview);
        }

        this.timerSetReview = setTimeout(() => {
          // if (window.confirm("Hi!\n\nWe hope you like TimeTo.Work, We would be very happy if you could take a minute to leave a review or rating in the App Store.\n\nIt's very important for your visibility in the Store. Thank you!")) {
          if (ipcRenderer) ipcRenderer.send("rate");
          //  localStorage.setItem('openRatingDialog', 1)

          // if (shell)
          //   shell.openExternal('itms-apps://itunes.apple.com/app/itunes-u/id1457815576?action=write-review')

          //} else {
          //  localStorage.setItem('openRatingDialog', 0)
          // }
        }, 2000);
        //}
      }
  };

  componentWillUnmount = () => {
    window.removeEventListener("openintro", this.onClickIntro);
    window.removeEventListener("preferences", this.onClickPreferences);
    window.removeEventListener("cleardata", this.onCleardata);
    window.removeEventListener("signout", this.onClickSignout);
    const { currentUser } = this.props;
    const role = currentUser.role || 0;
    let state = store.getState();
    // console.log('########>> AppLoggedInRouter.componentDidUnmount UNLISTEN')
    if (this.listners["projects"])
      store.firestore.unsetListener(this.listners["projects"]);

    if (role > 100) {
      if (this.listners["users"])
        store.firestore.unsetListener(this.listners["users"]);

      if (this.listners["schedules"])
        store.firestore.unsetListener(this.listners["schedules"]);

      FirestoreListnerHelper.clearListners(this.context);
      //TODO here is the place to destroy all listners

      //TODO KILL request listner here!
    }

    if (ipcRenderer) ipcRenderer.send("stopPushListner");
  };

  onToggleLeftMenuOpen = () => {
    const value = !this.state.leftMenuOpen;
    this.props.setLeftMenuOpen(value);
    this.setState({ leftMenuOpen: value });
  };

  onClickSignout = () => {
    this.goTo("/signout")();
  };

  onClickIntro = () => {
    this.props.openModal("onboardingmodule");

    //this.goTo('/appmenu')()
  };

  onClickPreferences = (args) => {
    if (args && args.detail && args.detail.path)
      this.props.openModal("appSettings", { path: args.detail.path });
    else this.props.openModal("appSettings");
  };

  onCleardata = async (args) => {
    try {
      localStorage.clear();
      sessionStorage.clear();
      const dbs = await window.indexedDB.databases();
      dbs.forEach((db) => {
        window.indexedDB.deleteDatabase(db.name);
      });
    } catch (error) {
      alert("Error");
    }
    window.location.reload();
  };

  checkPushToken = () => {
    var { token, currentUser } = this.props;

    if (!token && window._webToken) token = window._webToken;

    if (token && currentUser && currentUser.role > 99) {
      //only for active users
      //    console.log('>>new token ? ', token)
      var tokens = [...(currentUser.token || [])];
      //  console.log('check token --> ', token)
      if (tokens.indexOf(token) === -1) {
        //  console.log('Add token=!>', token)
        FireHelper.setUserPushToken(token);
      } else {
        // console.log('Token exists')
      }
    }
  };
  listnerIsNotSet = false;
  scheduleKey = "default";
  componentDidMount = () => {
    window.addEventListener("openintro", this.onClickIntro);
    window.addEventListener("preferences", this.onClickPreferences);
    window.addEventListener("cleardata", this.onCleardata);
    window.addEventListener("signout", this.onClickSignout);

    var elem = document.getElementById("divPreLoader");
    elem.style.opacity = 1;
    elem.style.display = "block";
    window._doOnce = true;
    window._hidesplash = false;
    setTimeout(() => {
      window.hideLoader(); // dont want this like a timer
    }, 2500);

    const { currentUser } = this.props;
    // var token = this.props.token
    const role = currentUser.role || 0;
    const projectKey = currentUser.projectKey;
    window._projectKey = projectKey;

    var jsonData = localStorage.getItem("savedStateVars");

    if (jsonData) {
      const rehydrateSaveData = JSON.parse(jsonData);
      if (rehydrateSaveData && rehydrateSaveData.scheduleDropdownKeySaved) {
        this.scheduleKey = rehydrateSaveData.scheduleDropdownKeySaved;
      }
    }

    //console.log('########>> AppLoggedInRouter.componentDidMount', currentUser)

    if (role >= 100) {
      this.setListners();
      this.checkPushToken();
      if (ipcRenderer) {
        ipcRenderer.send("startPushListner");
      }
    } else this.listnerIsNotSet = true;

    const showIntro = JSON.parse(localStorage.getItem("showIntro"));
    if (showIntro !== true) {
      //console.log('this shuld not run now!')
      this.props.openModal("onboardingmodule");
    }
  };
  preLoadImages = () => {
    //console.log('***************************************preLoadImages***************************************')
    const { project } = this.props;
    //console.log('project', project)
    if (project) {
      if (project.cache)
        Object.keys(project.cache).forEach((key) => {
          var user = project.cache[key];
          if (user && user.i) {
            var img = new Image();
            var url = "";

            url = getUserImage(project.key, key, user.i);
            img.src = url;
            img.onload = () => { };
            //console.log(url)
          }
        });
    }
  };

  setListners = async () => {
    // console.log('setListners')
    const { currentUser } = this.props;
    const role = currentUser.role || 0;
    const projectKey = currentUser.projectKey;

    //this.props.history.push('/signout')

    //  if (this.props.authUser) {
    await FireHelper.reloadToken().then(async (token) => {
      if (!token.claims.pKey) await FireHelper.reloadToken(true);

      //let state = store.getState()

      // console.log('2oken.claims.pKey', token.claims.pKey)
      // console.log('2oken.claims.role', token.claims.role)
      if (role >= 100) {
        FirestoreListnerHelper.setRequestAllListner(
          this.context,
          role,
          currentUser.inSysUserKey
        );
        FirestoreListnerHelper.setRequestMyListner(
          this.context,
          currentUser.inSysUserKey
        );

        //console.log('setListners', role)
        this.listners["projects"] = {
          collection: "projects",
          doc: projectKey,
          storeAs: "project",
        };
        store.firestore.onSnapshot(this.listners["projects"]);
      }
      if (role > 100) {
        FirestoreListnerHelper.setRequestAdminListner(
          this.context,
          role,
          currentUser.inSysUserKey
        );

        FirestoreListnerHelper.setTaskListner(this.context);


        this.listners["users"] = {
          collection: "projects",
          doc: projectKey,
          subcollections: [{ collection: "users" }],
          storeAs: "users",
        };
        store.firestore.onSnapshot(this.listners["users"]);

        this.listners["schedules"] = {
          collection: "projects",
          doc: projectKey,
          subcollections: [{ collection: "schedules" }],
          storeAs: "schedules",
        };
        store.firestore.onSnapshot(this.listners["schedules"]);
      }
      try {
        // LOG ROCKET
        if (currentUser.doLog === true) {
          console.log("%c Init log ", "color:#fff;background:green");

          LogRocket.init("kmh7kf/test", {
            release: version,
            // network: {
            //   isEnabled: false,
            // },
          });

          var uData = {
            name: currentUser.companyName || "#Unknown",
            email: currentUser.email
              ? currentUser.email
              : currentUser.phone || "#Unknown",
            role: currentUser.role,
            //data: currentUser
            // Add your own custom user variables here, ie:
            //subscriptionType: 'pro'
          };

          LogRocket.identify(currentUser.key, uData);

          // LogRocket.identify('gC1T6k6t9IgmAgBtONl59H0M1de2', { name: "Puzzle Kings AB", email: "blomjens@hotmail.com" });
        }
      } catch (error) {
        console.log("Do logg", error);
      }
    });

    //}
    //admin

    setTimeout(this.preLoadImages, 500);
  };
  styles = {
    s1: { paddingLeft: "10px", paddingTop: isMobile ? "45px" : "0px" },
  };
  counter = 0;
  updateClaim = () => {
    firebase.reloadAuth().then(() =>
      FireHelper.reloadToken(true).then((u) => {
        //console.log('u.claims.role', u.claims.role)
        if (u.claims.role > 50 && u.claims.pKey) {
          //console.log('this.listnerIsNotSet', this.listnerIsNotSet)
          //  if (this.listnerIsNotSet === true) {
          this.listnerIsNotSet = true;
          this.setListners();
          //}
        } else {
          if (this.counter < 5) {
            this.counter++;
            //console.log('again')
            setTimeout(this.updateClaim, 1000 * this.counter);
          }
        }
      })
    );
  };

  onSignOut = () => {
    console.log("onSignOut");
    this.setState({});
  };

  goTo = (url) => () => {
    console.log("goto");
    this.props.history.push(url);
  };

  getMsgCount = () => {
    if (this.timerGetMsgCount) clearTimeout(this.timerGetMsgCount);

    this.timerGetMsgCount = setTimeout(() => {
      const alerts = this.props.currentUser.alerts || {};
      let msgCount = 0;
      if (alerts.m)
        Object.keys(alerts.m).forEach((o) => {
          var obj = alerts.m[o];
          msgCount += Number(obj.m || 0);
        });
      if (this.state.msgCount !== msgCount && msgCount !== NaN) {
        this.setState({ msgCount });
      }
    }, 400);
  };

  windowClose = () => {
    console.log("windowClose");
    window.close();
  };

  render() {
    this.getMsgCount();

    //TODO FIX COLORS
    const { currentUser } = this.props;
    const role = currentUser.role || 0;
    const isAdmin = role > 101;

    setTimeout(() => {
      ReactGA.event({
        category: "Open project",
        action: `${currentUser.projectKey}`,
      });
      ReactGA.event({
        category: isElectron?'Electron':'Browser',
        action: `${osName}`,
      });
      ReactGA.pageview(
        window.location.pathname +
        window.location.search +
        "/" +
        currentUser.projectKey
      );
    }, 100);

    if (window.location.pathname !== "/signout") {
      if (!this.props.project) {
        return "";
      }
    }

    if (
      window.location.pathname === "/login" ||
      window.location.pathname === "/setup"
    )
      //
      return (
        <Route
          path="*"
          history={history}
          render={() => (
            <Switch>
              <Route path="/login" component={LoginRouter} />
              <Route path="/newuser" component={LoginRouter} />
              <Route path="/setup" component={LoginRouter} />
              <Redirect to="/login" />
            </Switch>
          )}
        />
      );
    if (window.location.pathname === "/signout") {
      return (
        <React.Fragment>
          <Route
            path="*"
            history={history}
            render={() => (
              <Switch>
                <Route path="/signout" component={lSignOut} />
                <Redirect to="/signout" />
              </Switch>
            )}
          />
        </React.Fragment>
      );
    }

    if (isMobile) document.body.classList.add("mobile");
    else document.body.classList.remove("mobile");

    if (this.props.isLandscape) document.body.classList.add("landscape");
    else document.body.classList.remove("landscape");

    if (showWebMenu) document.body.classList.add("showWebMenu");
    else document.body.classList.remove("showWebMenu");

    if (window.location.pathname === "/todaytaskbar") {
      return (
        <Route
          path="*"
          history={history}
          render={() => (
            <Switch>
              <Route path="/todaytaskbar" component={ScheduleTodayTaskbar} />
              <Redirect to="/todaytaskbar" />
            </Switch>
          )}
        />
      );
    }

    var productTour;
    if (this.props.global.state.showTour === true)
      productTour = (
        <JoyriderTour page={window.location.pathname}></JoyriderTour>
      );

    return (
      <React.Fragment>
        {isAdmin && (
          <React.Fragment>
            <div
              ref={(div) => (this.target = div)}
              className="main"
              style={{
                paddingTop: "0px",
                marginBottom: !this.props.isLandscape ? "50px" : "0px",
                display: "flex",
                height: "100%",
              }}
            >
              <MainMenu
                defaultScheduleKey={this.scheduleKey}
                onOpenModal={this.props.openModal}
                onToggleLeftMenuOpen={this.onToggleLeftMenuOpen}
                leftMenuOpen={this.state.leftMenuOpen}
              />

              <div
                id="submain"
                style={{
                  flexGrow: "1",
                  paddingLeft:
                    isMacOs === false && this.props.isLandscape
                      ? "70px"
                      : "0px",
                  maxWidth: this.state.leftMenuOpen
                    ? "calc(100% - 200px)"
                    : "calc(100% - 70px)",
                  marginTop: showWebMenu === true ? 4 : 0,
                  height: "100%",
                  backgroundColor:'var(--color-bg-submain)',
                }}
              >
                <Route
                  history={history}
                  path="*"
                  render={() => (
                    <Switch>
                      <Route path="/signout" component={SignOut} />
                      <Route path="/schedule/users" component={Users} />
                      <Route path="/schedule/settings" component={Settings} />
                      <Route
                        path="/schedule/schedule/:key"
                        component={Schedule}
                      />

                      <Route path="/tasks" component={Tasks} />
                      <Route path="/requests" component={Requests} />
                      <Route path="/requestsmy" component={RequestsMy} />
                      <Route path="/chat" component={Chat} />
                      <Route path="/messages" component={Messages} />
                      <Route path="/billing" component={BillingBig} />

                      <Route path="/help" component={Help} />
                      <Route path="/reports" component={Reports} />
                      <Route path="/today" component={ScheduleToday} />

                      <Route path="/tour" component={Tour} />
                      <Route path="/tour1" component={Tour} />

                      <Route path="/AppMenu" component={AppMenu} />
                      <Route path="/dev" component={Dev} />

                      <Redirect
                        exact
                        from="/schedule/refresh"
                        to="/schedule/schedule/default"
                      />
                      <Redirect
                        exact
                        from="/"
                        to="/schedule/schedule/default"
                      />
                    </Switch>
                  )}
                />
              </div>
            </div>
            {this.props.isOpen_UserModal && <UserModal></UserModal>}
            {this.props.isOpen_CopyScheduleModal && (
              <CopyScheduleModal></CopyScheduleModal>
            )}
            {/* {isElectron === true && <ElectronTodayPortal></ElectronTodayPortal>} */}
            {/* 
            {console.log('this.props.currentUser.email', this.props.currentUser.email)}
            {this.props.currentUser.email === 'demo@timeto.work' &&
              <div onClick={this.onClickSignout} style={{ zIndex: 1000, borderRadius: 6, color: '#fff', fontWeight: 'bold', position: 'absolute', top: 2, right: 140, background: 'rgba(255,0,0,.7)', paddingLeft: 10, paddingRight: 10, }}>{window._getText('DemoAccount')}</div>
            } */}

            <Warning></Warning>
            {/* <OnboardingModule></OnboardingModule> */}
            {this.props.currentUser.email !== "demo@timeto.work" && (
              <Joyrider page={window.location.pathname}></Joyrider>
            )}

            {productTour && productTour}
          </React.Fragment>
        )}

        {!isAdmin && (
          <React.Fragment>
            <div
              className="main"
              style={{
                paddingTop: "0px",
                marginBottom: !this.props.isLandscape ? "50px" : "0px",
                display: "flex",
                height: "100%",
              }}
            >
              <MainMenu
                defaultScheduleKey={this.scheduleKey}
                onOpenModal={this.props.openModal}
                onToggleLeftMenuOpen={this.onToggleLeftMenuOpen}
                leftMenuOpen={this.state.leftMenuOpen}
              />

              <div
                id="submain"
                style={{
                  flexGrow: "1",
                  paddingLeft:
                    isMacOs === false && this.props.isLandscape
                      ? "62px"
                      : "0px",
                  maxWidth: this.state.leftMenuOpen
                    ? "calc(100% - 0px)"
                    : "calc(100% - 70px)",
                  height: "100%",
                  overflowY: "auto",
                  overflowX: "hidden",
                  backgroundColor:'var(--color-bg-submain)',
                }}
              >
                <Route
                  history={history}
                  path="*"
                  render={() => (
                    <Switch>
                      <Route path="/newuser" component={NewUserRedirecter} />
                      <Route path="/setup" component={NewUserRedirecter} />
                      <Route path="/signout" component={SignOut} />
                      <Route path="/home" component={UserHome} />
                      <Route path="/schedules" component={ScheduleUser} />
                      <Route path="/chat1" component={Chat} />

                      <Route path="/requests1" component={Requests} />

                      <Route path="/help" component={Help} />

                      <Route path="/dev" component={Dev} />
                      <Route path="/AppMenu" component={AppMenu} />
                      <Redirect to="/home" />
                    </Switch>
                  )}
                />
              </div>
            </div>

            {this.props.isOpen_AppMenu && (
              <AppMenu fixPopUp={this.fixPopUp}></AppMenu>
            )}
          </React.Fragment>
        )}

        {this.props.isOpen_AppSettings && (
          <SettingsModal
            role={role}
            isOpen={this.props.isOpen_AppSettings}
            closeModal={this.props.closeModal}
          ></SettingsModal>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let schedules = null;
  if (state.firestore.data.schedules) {
    schedules = state.firestore.data.schedules;
  }

  //console.log(state.firestore.status.requested)

  //console.log(`projects/${state.firebase.profile.projectKey}/weekview/${state.viewstate.renderDateKey}`)
  return {
    isOpen_CopyScheduleModal: state.modal.copyShedualModalOpen,
    isOpen_UserModal: state.modal.modals["user"].open || false,
    isOpen_AppMenu: state.modal.modals["appMenu"].open || false,
    isOpen_AppSettings: state.modal.modals["appSettings"].open || false,
    authUser: state.firebase.auth,
    isLandscape: state.viewstate.isLandscape,
    isLeftMenuOpen: state.viewstate.isLeftMenuOpen,
    project: state.firestore.data.project,
    schedules,
    // requestData: state.firestore.ordered['requestAllListner'],
  };
};
const mapActionsFromProps = {
  initRenderDate,
  openModal,
  closeModal,
  setLeftMenuOpen,
};

export default withGlobalState(
  withRouter(connect(mapStateToProps, mapActionsFromProps)(AppLoggedInRouter))
);
