import React, { PureComponent } from "react";
// import PropTypes from "prop-types";
//import { Header, Image } from "../../../common/component/Button";
import ScheduleCell from "./ScheduleCell";
import moment from "moment";
//import collection from "lodash/collection";
import math from "lodash/math";
import noUserImage from '../../../../img/noimg.png'
import { getTimeFromMins, getUserImage, getCurrency } from '../../../../core/util/helpers'
import VisibilitySensor from "react-visibility-sensor";
 
// import { shallowEqual } from 'shouldcomponentupdate-children';


import lang from 'lodash/lang'
import moize from "moize";
// import TimePicker from '../../../common/form/TimePicker';
import { Button } from 'semantic-ui-react';
import Avatar from '../../../common/component/Avatar'
import InfoBoxCosts from '../../../common/component/InfoBoxCosts';
const _colorsAvilibility = ['', 'red', 'green', 'yellow', 'yellow', 'yyellow']
const _msgAvilibility = ['', 'CanNotWork', 'WantToWork', 'Vacation', '', '', '', '']


var _firstDay = window._firstDay
var _getText = window._getText

const momentGetDate = moize((ms, daysToAdd) => {
  if (daysToAdd)
    return moment.utc(new Date(ms)).add(daysToAdd, 'days')

  return moment.utc(new Date(ms))
}
  // , {
  //   onCacheHit: (
  //     cache,
  //     options
  //   ) => console.log('hit')
  // }
)
// FIXME: dose moize.deep help or hurt ?

const getShifts = (shifts, renderDay) => {
  const d1 = new Date(renderDay);
  const renderDayKey = `${d1.getMonth()}-${d1.getDate()}`

  const shiftArr = []
  Object.keys(shifts).forEach(key => {
    const shift = shifts[key]

    if (shift.renderDayKey === renderDayKey)
      shiftArr.push(shift)
  })



  return shiftArr.sort((a, b) => {
    if (!a.timeStart || !b.timeStart)
      return 0

    if (a.timeStart < b.timeStart) return -1
    if (a.timeStart > b.timeStart) return 1
    return 0
  })




}

const getDayKey = moize((renderDay) => {
  const d1 = new Date(renderDay);
  const renderDayKey = `${d1.getMonth()}-${d1.getDate()}` //cache this
  //console.log('renderDayKey', renderDayKey)
  return renderDayKey
}
  // , {
  //   onCacheHit: (
  //     cache,
  //     options
  //   ) => console.log('hit getDayKey')
  // }
)
const emptyArray = []
const getShifts2 = (shifts, renderDay) => {

  if (!shifts)
    return this.emptyArray

  //const d1 = new Date(renderDay);

  const renderDayKey = getDayKey(renderDay) // `${d1.getMonth()}-${d1.getDate()}` //cache this
  const r = shifts[renderDayKey] || emptyArray

  return r //Array.from(r)




}




const sumFunc = (o) => {
  if (o.removed)
    return 0
  if (!o.minutes)
    return 0
  return Number(o.minutes)
}
const getSum = moize((userEvents) => {
  let sum = 0
  if (userEvents) {
    Object.keys(userEvents).forEach(key => {
      const dayEvents = userEvents[key]
      var minutes2 = math.sumBy(dayEvents, sumFunc);
      sum += minutes2;

    })

    return sum
  }
}
)

 


export class ScheduleRow extends PureComponent {




  constructor(props) {
    super(props);

    _firstDay = window._firstDay
    _getText = window._getText
    this.state = {
      showAdd: {},
      minutes: null,
      rData: [],
      isVisible: this.props.order < 20 ? true : false, // number of rows to load stright away
      haveFetched: false
    }





  }

  componentDidUpdate(prevProps, prevState, snapshot) {

    if (this.props.forceRender && this.props.forceRender === 1 && prevProps && prevProps.forceRender === 0) {
      this.renderCells(true)
    }
    else if (this.props.weeksToShow !== prevProps.weeksToShow) {
      //   console.log('weeksToShow is changed')
      this.renderCells(true)

    }
    else if (this.props.renderDayDate.format('YYYYMMDD') !== prevProps.renderDayDate.format('YYYYMMDD')) {
      // console.log('renderDayDate is changed! 3')
      this.renderCells(true)
    }
    else if (this.props.cloneShift !== prevProps.cloneShift) {
      // console.log('meta is changed! 4')
      this.renderCells(true)
    }
    else if (!lang.isEqual(this.props.userEvents, prevProps.userEvents)) {
      // console.log('userEvents is changed! 122', this.props.userEvents)

      this.renderCells(true)
    }
    else if (!lang.isEqual(this.props.metaDataList, prevProps.metaDataList)) {
      //console.log('meta is changed! 1')
      this.renderCells(true)
    }
    else if (!lang.isEqual(this.props.availabilityMetaData, prevProps.availabilityMetaData)) {
      //console.log('meta is changed! 3')
      this.renderCells(true)
    }
    else {
      // console.log('caccchhhehehd')
    }



    //console.timeEnd('componentDidUpdate')


  }

  componentDidMount = () => {

    //if (!this.state.minutes) {
    // this.getSum()
    //}
    //    console.log('componentDidMount')
    if (this.state.isVisible === true) {
      this.renderCells(true)
    }


  }


  preventDefault = event => () => {
    event.preventDefault();
  };
  onDragStart = e => {
    // console.log('onDragStart', e.target.id)


    this.hideAddBtn()
    var clone = e.metaKey || e.ctrlKey || e.shiftKey || e.altKey
    e.dataTransfer.setData("text", JSON.stringify({ t: "cell", id: e.target.id, clone: clone }));
    this.props.onDragOverShowDropZone();
  };
  onDragOver = e => {
    //console.log('onDragOver')  
    e.stopPropagation();
    e.preventDefault();
  };
  onDrop = eventDate => e => {
    e.preventDefault();

    this.props.onDragOverHideDropZone();
    var data;
    var clone = false;
    try {
      data = JSON.parse(e.dataTransfer.getData("text"));
      clone = data.clone
    } catch (error) {
      console.log("JSON.parse error");
      return;
    }

    if (data.t === "cell") {
      this.props.onMoveEvent(this.props.userKey, eventDate, data.id, clone);
    }
    else if (data.t === "template") {
      this.props.onAddTemplateShift(this.props.userKey, eventDate, data.id);
    }



  };



  // getCss = events => {
  //   var cssClass = "large cellOne";
  //   const eventCounts = collection.size(events);
  //   if (eventCounts === 2) cssClass = "small cellMulti";
  //   if (eventCounts > 2) cssClass = "mini cellMulti";
  //   return cssClass;
  // };

  lastDate = null
  showAddBtn = (eventDate) => (e) => {
    // // // // //console.log('showAddBtn')
    //console.log(e.clientY)
    // console.log(eventDate.format('YYYY-MM-DD'))
    if (e.target.tagName.toLowerCase() === 'td')
      this.props.showAddShiftButton(e.target.getBoundingClientRect(), +eventDate, this.props.userKey)
    else if (e.target.parentElement.tagName.toLowerCase() === 'td')
      this.props.showAddShiftButton(e.target.parentElement.getBoundingClientRect(), + eventDate, this.props.userKey)
    else if (e.target.parentElement.parentElement.tagName.toLowerCase() === 'td')
      this.props.showAddShiftButton(e.target.parentElement.parentElement.getBoundingClientRect(), + eventDate, this.props.userKey)

    // // // // // console.log('rectObject', e.target.offsetLeft)
    // // // // // console.log('showAddBtn1', e.target.offsetLeft)
    // // // // // console.log('showAddBtn2', eventDate)

    // this.lastDate = eventDate
    // var showAdd = { ...this.state.showAdd }
    // showAdd[eventDate] = true
    // this.setState({ showAdd })
  }


  hideAddBtn = (eventDate) => (e) => {
    // // // // //console.log('hideAddBtn')
    // // // // //console.log('e.target.tagName')
    //if (e.target.tagName === 'TD')
    this.props.hideAddShiftButton()
    // else if (e.target.parentElement.tagName === 'TD')
    //   this.props.showAddShiftButton(null, null, null)
    // else if (e.target.parentElement.parentElement.tagName === 'TD')
    //   this.props.showAddShiftButton(null, null, null)
    // var showAdd = { ...this.state.showAdd }
    // showAdd[eventDate] = false
    // this.setState({ showAdd })

  }

  onTdClick = (userKey, dataedate) => (e) => {
    window.dispatchEvent(new CustomEvent("pasteshift", { detail: { userkey: userKey, dataedate: new Date(dataedate) } }))
    e.stopPropagation()
  }

  renderCells = (force) => {
    //console.time('t1')

    //console.log('_renderCells')

    if (!this.state.rData || force === true) {

      var rData = []
      var { renderDayDate, renderDayCount, userEvents, metaDataList, userKey, onDragOverHideDropZone, onOpenEventModal } = this.props
      if (!metaDataList)
        metaDataList = {}

      var startDate = moment.utc(renderDayDate).utcOffset(0);
      startDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      const startDateMs = +startDate

      if (userKey === 'costSummary') {
        for (let i = 0; i < renderDayCount; i++) {

          const _tdStyle = this.tdStyle({}, i)
          const _tdClass = this.tdClass({}, i)



          var eventDate = momentGetDate(startDateMs, i)
          const msEventDate = +eventDate
          const renderDayKey = getDayKey(msEventDate)

          const costObject = userEvents[renderDayKey] || { time: 0, cost: 0 }
          rData.push(<td key={`row_costs_${i}`} style={styles.sCostSum} className={_tdClass}>
            <div style={{ textAlign: 'center', }}>
              <div className="oneLine" style={{ borderBottom: 'dotted 1px silver' }}>{costObject && getTimeFromMins(costObject.time)}</div>
              <div className="oneLine" >{costObject && getCurrency(Math.round(costObject.cost))} </div>



            </div>
          </td>)
        }
        this.setState({ rData, haveFetched: true })
        return
      }


      const availability = this.props.availabilityMetaData || {}
      const isCloneShift = this.props.cloneShift === true ? { cursor: 'cell' } : {}
      for (let i = 0; i < renderDayCount; i++) {
        var d = momentGetDate(startDateMs, i)
        rData.push(this.renderCell(d, userEvents, i, availability, userKey, onDragOverHideDropZone, onOpenEventModal, isCloneShift, metaDataList))
      }

      this.setState({ rData, haveFetched: true })


    }
    //  console.timeEnd('t1')

  }





  renderCell = (eventDate, shifts, dayIndex, availability, userKey, onDragOverHideDropZone, onOpenEventModal, isCloneShift, metaDataList) => {

    var dayIndexMonOrSun = dayIndex
    if (_firstDay === 0)
      dayIndexMonOrSun = dayIndex === 0 ? 7 : dayIndex
    else
      dayIndexMonOrSun = dayIndex + 1


    //console.log('renderCell!', shifts)
    //FIXME: THIS IS SLOW
    const availabilityIndex = availability[dayIndexMonOrSun] // TODO se if week start on mon or sun
    const msEventDate = +eventDate
    const cellEvents = getShifts2(shifts, msEventDate);// collection.filter(shifts, event => event.date22.isSame(renderDay, "day")) || [];


    const setCloneShift = this.props.cloneShift === true ? this.onTdClick(userKey, +eventDate) : null

    const _tdStyle = this.tdStyle(isCloneShift, dayIndex)
    const _tdClass = this.tdClass(isCloneShift, dayIndex)


    return (
      <td
        style={_tdStyle}
        onDragEnd={onDragOverHideDropZone}

        onDrop={this.onDrop(msEventDate)}
        onDragOver={this.onDragOver}
        draggable="false"
        //userkey={userKey}
        key={`${userKey}-${msEventDate}-${dayIndex % 7}`}
        className={'scheduelCell ' + _tdClass}
        dataedate={eventDate}
        // onMouseMove={this.showAddBtn(eventDate)} //TODO this is not good when we render so mush  FIXME:
        // // onMouseLeave={this.hideAddBtn(eventDate)}
        onMouseDown={setCloneShift}
      >

        {/* <span className='addShiftBtn' >
          {this.state.showAdd[eventDate] &&
            <Button onClick={onAddEvent(+eventDate, userKey)} circular icon='add' size='tiny' />
          }
        </span> */}



        <div className='flexDiv' style={styles.s2}>
          {availabilityIndex > 0 &&
            <div className='availabilityDiv' style={styles.s3}>{_getText(_msgAvilibility[availabilityIndex])}</div>
          }




          {cellEvents.map(event => {
            if (event.removed) return null;

            return (
              <ScheduleCell
                hourSalary={this.props.hourSalary}
                AmPm={this.props.AmPm}
                onOpenEventModal={onOpenEventModal}
                //cssClass={cssClass}
                key={event.key}
                eventKey={event.key}
                userKey={userKey}
                date={event.date}
                onDragStart={this.onDragStart}
                onDragOverHideDropZone={onDragOverHideDropZone}
                //event={{ ...event, meta: metaDataList[event.key] }}
                requests={event.requests}
                sick={event.sick}
                color={event.color}
                comment={event.comment}
                userCom={event.userCom}
                fontSize={event.fontSize}
                startDate={event.startDate}
                stopDate={event.stopDate}
                label={event.label}
                event={event}

              />
            );

          })}


        </div>
      </td>
    );
  };
  onOpenUserModal = key => () => {
    if (this.props.role > 199)
      return this.props.openUser(key)


    return
  }
  onOpenDivierModal = (key, value) => () => this.props.openDivierModal(key, value)


  tblRowCss = (isOpenshifts) => ({ background: isOpenshifts ? 'rgba(239, 239, 219, 0.20)' : '' }) // change to className
  style = {
    header: { display: 'inline', margin: '0px', padding: '0px' }
  }

  

  onChange = (isVisible) => {

    if (this.state.haveFetched === false)
      this.setState({ isVisible }, () => {
        if (isVisible === true)
          this.renderCells(true)

      })

    //this.setState({ isVisible })
    //console.log('Element is now %s', isVisible ? 'visible' : 'hidden');
  }
  getTds = moize((weeksToShow) => {

    const r = []

    for (let index = 0; index < (7 * weeksToShow); index++) {
      const _tdStyle = this.tdStyle({}, index)
      const _tdClass = this.tdClass({}, index)

      r.push(
        <td key={index}
          style={_tdStyle}
          className={'scheduelCell ' + _tdClass}
        >
          &nbsp;
        </td>)

    }
    return r
  })

  // dose this even shows ? 
  getSumTds = moize((weeksToShow) => {

    const r = []

    for (let index = 0; index < (7 * weeksToShow); index++) {
      const _tdStyle = this.tdStyle({}, index)
      const _tdClass = this.tdClass({}, index)

      r.push(
        <td key={index}
          style={styles.sCostSum}
          className={'scheduelCell ' + _tdClass}
        >
          &nbsp;
        </td>)

    }
    return r
  })

  getTotalCostData = (events) => {
    return Object.values(events || {}).reduce((previus, current) => {
      let pre = previus ? previus : { cost: 0, time: 0 }
      let cur = current ? current : { cost: 0, time: 0 }
      return { cost: pre.cost + cur.cost, time: pre.time + cur.time }
    }
      , { cost: 0, time: 0 })
  }

  // kFormatter = (num) => {
  //   return Math.abs(num) > 999 ? Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + 'k' : Math.sign(num) * Math.abs(num)
  // }

  render = () => {
    const { name, userKey, dragStartRow, dragOverRow, dropRow, onDragOverHideDropZone, order, dividerList, projectKey, weeksToShow } = this.props;

    var { imgUrl } = this.props;
    //const { name, userKey, dragStartRow, dragOverRow, dropRow, onDragOverHideDropZone, order, dividerList, projectKey } = this.props;


    if (!userKey)
      return null // <tr><td colSpan='8'>#user is missing</td></tr>





    const isDiv = userKey.startsWith("div_");
    var dividerName = '[click to set divider name]'
    var dividerNameData = ''
    var dividerRemoved = false;

    var divider = dividerList[userKey]
    if (divider !== undefined) {
      dividerName = divider.name
      dividerNameData = divider.name
      dividerRemoved = divider.removed ? true : false
    }

    var minutes1 = getSum(this.props.userEvents)
    var minutesFromOtherSchedules1 = this.props.totalMinutes

    const totalMinutes = getTimeFromMins(minutes1 + minutesFromOtherSchedules1)
    const over40h = { color: ((minutes1 + minutesFromOtherSchedules1) / 60) > 40 ? '#DB2828' : '' }

    var minutes = getTimeFromMins(minutes1)
    var minutesFromOtherSchedules = getTimeFromMins(minutesFromOtherSchedules1)

    const isSum = userKey.startsWith("costSummary");
    const isOpenshifts = userKey.startsWith("openshifts");
    const isUnassigned = userKey.startsWith("unassigned");
    const noPrint = (isOpenshifts || isUnassigned || isSum) ? ' noPrint' : ''

    const totalCostData = isSum ? this.getTotalCostData(this.props.userEvents) : { time: 0, cost: 0 }

   

    return (
      <VisibilitySensor onChange={this.onChange}>
        <React.Fragment>
          <tr draggable="false" key={userKey} id={userKey} order={order} className={'tour_schedule_5 ' + (isSum ? '' : 'sRowTbl') + noPrint} style={this.tblRowCss((isOpenshifts || isDiv || isUnassigned))}  >
            {isSum &&
              <React.Fragment>
                <td
                  draggable="false"
                  className='sCellName'
                  style={styles.sCostSum}
                >
                  <div style={{ display: 'flex' }}>
                    <div className="hideOnMediumScreen onLine" style={{ flexGrow: 1, paddingLeft: 4, display: 'flex', alignItems: 'flex-end' }}>
                      <InfoBoxCosts/>
                    </div>
                    <div style={{ textAlign: 'right', fontWeight: 'bold', paddingLeft: 4 }}>
                      <div className="oneLine" style={{ borderTop: 'dotted 1px rbga(0,0,0,0.1)', borderBottom: 'dotted 1px rbga(0,0,0,0.1)', paddingRight: 20 }}>{totalCostData && getTimeFromMins(totalCostData.time)}</div>
                      <div className="oneLine" style={{ paddingRight: 20, borderBottom: 'dotted 1px rbga(0,0,0,0.1)', }}>{totalCostData && getCurrency(totalCostData.cost)}</div>
                      {/* { new Intl.NumberFormat('sv', { style: 'currency', currency: 'SEK' }).format(totalCostData.cost)} */}
                      {/* this.kFormatter(totalCostData.cost) */}
                    </div>

                  </div>
                </td>
                {this.state.rData.length > 0 ?
                  this.state.rData
                  :
                  this.getSumTds(weeksToShow)
                }

              </React.Fragment>
            }

            {(isOpenshifts || isUnassigned) &&
              <React.Fragment>
                <td

                  draggable="false"
                  className='sCellName'
                  style={styles.s4}
                >

                  <div>
                    {minutes && <label style={over40h} className='sRowCellLbl noSelect noPrint' size='tiny' alt={`This schedule:${minutes}, others:${minutesFromOtherSchedules}`} title={`This schedule:${minutes}, others:${minutesFromOtherSchedules}`}  >{totalMinutes}</label>}
                    <div alt={name} title={name} draggable="false" className='schedualUsernameUnassing'>
                      <div style={styles.s81} className='contextMenu' onMouseDown={this.props.contexMenu(userKey, false)} >{_getText(`schedual.lbl-${userKey}`)}</div>
                    </div>
                  </div>


                </td>

                {this.state.rData.length > 0 ?
                  this.state.rData
                  :
                  this.getTds(weeksToShow)
                }

              </React.Fragment>
            }

            {!isSum && !isDiv && !isOpenshifts && !isUnassigned &&
              <React.Fragment>
                <td

                  onDragStart={dragStartRow} onDragEnd={onDragOverHideDropZone}
                  onDrop={dropRow}
                  onDragOver={dragOverRow}
                  draggable="false"
                  className='sCellName'
                  onMouseMove={this.hideAddBtn()}

                >
                  <div className='divUsername tour_schedule_6_1'>
                    {minutes && <label style={over40h} className='sRowCellLbl noSelect noPrint' size='tiny' alt={`This schedule:${minutes}, others:${minutesFromOtherSchedules}`} title={`This schedule:${minutes}, others:${minutesFromOtherSchedules}`}  >{totalMinutes}</label>}
                    <div alt={name} title={name} draggable onDragStart={dragStartRow} onDragEnd={onDragOverHideDropZone} className='schedualUsername'
                    >

                      <Avatar 
                      name={name} 
                      userKey={userKey} 
                      dragStartRow={dragStartRow} 
                      onDragOverHideDropZone={onDragOverHideDropZone} 
                      imgUrl={imgUrl}></Avatar>     
                      <div className='contextMenu' onMouseDown={this.props.contexMenu(userKey)}  >{name}</div>
                      {/* onClick={this.onOpenUserModal(userKey)}  */}
                    </div>
                  </div>
                </td>
                {/* */}
                {this.state.rData.length > 0 ?
                  this.state.rData
                  :
                  this.getTds(weeksToShow)
                }

              </React.Fragment>
            }


            {isDiv && !dividerRemoved &&
              <td colSpan={8}
                onDrop={dropRow}
                onDragOver={dragOverRow}
                draggable="false"
                className='scheduleDivider'
                style={styles.s9}
              >
                <div style={styles.s9_1} onClick={this.onOpenDivierModal(userKey, dividerNameData)} className='dragMe sRowCellHeader' draggable onDragStart={dragStartRow} onDragEnd={onDragOverHideDropZone}    >
                  {/* <Image src={imgUrl ? imgUrl : img} circular size="mini" onClick={this.onOpenUserModal(userKey)} draggable="false" style={styles.s10} /> */}
                  {dividerName}
                </div>

              </td>
            }




          </tr>

        </React.Fragment>
      </VisibilitySensor>

    );
  }

  tdStyle = (isCloneShift, dayIndex) => {
    if (_firstDay === 1)
      return { ...isCloneShift, width: '12.5%', background: (dayIndex % 7 === 6 || dayIndex % 7 === 5) ? 'rgba(0,0,0,0.05)' : '', }
    else
      return { ...isCloneShift, width: '12.5%', background: (dayIndex % 7 === 6 || dayIndex % 7 === 0) ? 'rgba(0,0,0,0.05)' : '', }

  }
  tdClass = (isCloneShift, dayIndex) => {
    if (_firstDay === 1)
      return (dayIndex % 7 === 6 || dayIndex % 7 === 5) ? 'stripes' : ''
    else
      return (dayIndex % 7 === 6 || dayIndex % 7 === 0) ? 'stripes' : ''

  }
}


const styles = {
  //s1:{ ...isCloneShift, background: (dayIndex % 7 === 6 || dayIndex % 7 === 5) ? 'rgba(0,0,0,0.05)' : '', },
  sCostSum: { background: 'rgba(0,0,0,0.03)' },
  s2: {},
  s3: { paddingBottom: 0, height: '14px', fontSize: 8, textAlign: 'center' },
  s4: { width: '16%', borderLeft: 'none' },
  s5: { paddingLeft: '5px', paddingTop: '7px' },
  //s6: { color: over40h ? '#DB2828' : '' },
  s7: { display: 'inline', margin: '0px', padding: '0px', touchAction: 'none', fontSize: '14px', fontWeight: 'bold' },
  s8: { touchAction: 'none', width: '30px', height: '30px', borderRadius: 15 },
  s81: { touchAction: 'none', paddingLeft: 10 },

  s9: { borderLeft: 'none' },
  s9_1: { fontWeight: 'bold' },
  s10: { marginRight: '8px', cursor: 'grab' },
  s11: { paddingBottom: '10px' },
  s12: { textAlign: 'right' },
  s13: { textAlign: 'center' },
  s14: { textAlign: 'center' },
  s15: { textAlign: 'center' },
  s16: { textAlign: 'center' },
  s17: { textAlign: 'center' },
  s18: { textAlign: 'center' },
  s19: { textAlign: 'center' }
}