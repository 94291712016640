import React, { useState, useEffect } from 'react'
import RequestView from './RequestView'
import FactoryRequests from '../../../../core/util/FactoryRequests'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faFolderOpen as icoEmpty
} from '@fortawesome/pro-duotone-svg-icons';
let foo
export default function RequestMiniView({ shift, currentUserKey, onDone }) {

    const [refresh, setRefresh] = useState(0); // integer state





    useEffect(() => {
        foo = FactoryRequests(() => {
            console.log('do re render')
            setRefresh(Math.random())
        })



        return () => {
            foo.destroy()
        }
    }, [])



    if (!shift.requests)
        return (<div style={{ padding: 20, fontWeight: 'bold', textAlign: 'center', marginTop: 20 }}>
            <FontAwesomeIcon icon={icoEmpty} style={{ fontSize: 50 }}></FontAwesomeIcon>
            <br />
            There is no requests on this shift</div>)

    if (!foo)
        return (<div>Loading...</div>)

    if (window._modals['eventModal'])
        window._modals['eventModal'].resizeTo(360, 400)

    //const requestList = foo.getRequestList()
    const doc = shift.requests[0]
    const { user, userChanged, shiftChanged, shiftOrginal, schedualName, requestIsCurrentUsers } = foo.getListsItem(doc, 0)

    const onDoneRequest = () => {
        onDone(doc)
        setRefresh(Math.random())
    }
    return (

        <RequestView size='small' shift={shift} onDone={onDoneRequest} state={{ s_schedualName: schedualName, s_user: user, s_userChanged: userChanged, s_shiftChanged: shiftChanged, s_shiftOrginal: shiftOrginal, s_doc: doc }} />

    )
}
