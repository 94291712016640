import moize from 'moize'
import array from "lodash/array";
import { createSelector } from 'reselect';
import { createCachedSelector } from 're-reselect';

import { getDateKey, getUserImage } from '../util/helpers'
//import memoize from 'lodash.memoize'


const schedules = state => state.firestore.data.schedules
const data = state => state.firestore.data
const selectedKey = state => state.currentScheduelData.selectedKey
const renderDayDate = state => state.currentScheduelData.renderDayDate
const users = state => state.firestore.data.users
const projectKey = state => state.firebase.profile.projectKey
const weeksToShow = state => state.viewstate.weeksToShow
const requesting = state => state.firestore.status.requesting
const requested = state => state.firestore.status.requested


export const getScheduleDropdown = createSelector(
    schedules,
    (schedules) => {
        var scheduleDropDownList = [];
        if (schedules) {
            // console.log('grenerate scheduleDropDownList')
            //TODO MOVE THIS MAP AND ONLY REDO IT WHEN ITS UPDATED !
            scheduleDropDownList = Object.keys(schedules).map(key => {
                if (!schedules[key])
                    return null

                return { text: schedules[key].name, key: key, value: key, createdAt: schedules[key].createdAt };
            });

            scheduleDropDownList = array.without(scheduleDropDownList, null) //MOVE THIS ONLY DO WHEN NEEDED
            scheduleDropDownList.sort((item1, item2) => {
                if (!item1 || !item2)
                    return 0

                if (!item1.createdAt || !item2.createdAt)
                    return 0
                // console.log(item1.createdAt)

                if (item1.createdAt.seconds < item2.createdAt.seconds) return -1
                if (item1.createdAt.seconds > item2.createdAt.seconds) return 1
                return 0
            })


            //sort((a, b) => { return a.text > b.text })//MOVE THIS ONLY DO WHEN NEEDED

            return scheduleDropDownList || []
        }
    })



export const getAvailabilityMetaData = createCachedSelector(
    data, selectedKey, renderDayDate, weeksToShow,
    (data, selectedKey, renderDayDate, weeksToShow) => {
        if (renderDayDate) {
            let availabilityMetaData = {}
            for (let index = 0; index < weeksToShow; index++) {
                const dateKey = getDateKey(renderDayDate.clone().add(index, 'weeks'));
                const metaData = data['weekview_' + dateKey + '_meta'] || {}
                var newMetaData = metaData[selectedKey]

                var availabilityMetaDataTemp = metaData.avil || {}
                Object.keys(availabilityMetaDataTemp).forEach((key) => {
                    Object.keys(availabilityMetaDataTemp[key]).forEach((key2) => {
                        if (!availabilityMetaData[key])
                            availabilityMetaData[key] = {}

                        availabilityMetaData[key][Number(key2) + (index * 7)] = availabilityMetaDataTemp[key][key2]
                    })

                });


            }


            //console.log('cache me ')

            return { availabilityMetaData, newMetaData }
        }
        return { availabilityMetaData: {}, newMetaData: {} }
    }
    // _getAvailabilityMetaData(data, selectedKey, renderDayDate, weeksToShow)
)(
    (state, dateKey) => dateKey//cache key
)


//const customSelectorCreator = createSelectorCreator(memoize, hashFn)
export const getUserlist = createSelector([users, projectKey],
    (users, projectKey) => {
        //  console.log('get user list', ' raw get')
        //console.log('#>>>>>>>>>getUserlist', users)
        var userList = [];
        var userFullList = [];

        if (users) {
            userFullList = users.userlist || {};
            userList = Object.keys(userFullList).map(key => {
                if (userFullList[key] == null) return null;
                if (userFullList[key].isActive == null) return null;
                // if (userFullList[key].sysUserKey == null) return null; // TODO JENS FIX THIS IN THE CHATT!

                const u = userFullList[key]
                const image = getUserImage(projectKey, key, u.image || 'x')
                return { text: u.name, key: key, sysKey: u.sysUserKey, value: key, image, act: userFullList[key].isActive };
            });
            userList = userList.filter(u => u !== null)
            return { userList: userList || [], userFullList: userFullList || {} }
        }
        else
            return { userList: [], userFullList: {} }

    })

var cache = moize((cCachekey) => {

})

export const eventDataObject = createCachedSelector(
    data, renderDayDate, selectedKey, weeksToShow, requesting, requested,
    (data, renderDayDate, selectedKey, weeksToShow, requesting, requested) => {


        if (renderDayDate && selectedKey) {
            //THIS IS NOT CACHED
            //console.log('get raw eventDataObject', selectedKey)
            let dateKey// = getDateKey(renderDayDate.clone());
            let eventObject = {}
            let scheduleWeekData = {}// { ...(data['weekview_' + dateKey] || {}) }//
            // console.time('xxx')
            var cCachekey = ''

            for (let index = 0; index < weeksToShow; index++) {
                dateKey = getDateKey(renderDayDate.clone().add((index * 7), 'days'));

                if (requesting[selectedKey + '_' + dateKey] === false && requested[selectedKey + '_' + dateKey] === true) {
                    var eventObject1 = data[selectedKey + '_' + dateKey] //|| { events: {}, sortedUserList: [] };
                    if (eventObject1) {
                        var _dividerList = { ...eventObject1.dividerList }
                        // console.log('cacheKey', eventObject1.cacheKey)
                        cCachekey += (eventObject1.cacheKey === undefined ? +new Date() : eventObject1.cacheKey) + '-'
                        Object.keys(_dividerList).forEach(key => {
                            if (!_dividerList[key].dateKey) // add this , its for backwards compatility
                                _dividerList[key] = { ..._dividerList[key], dateKey }
                        })
                        // console.time('cache')
                        eventObject = {
                            // We need to check the name and only add whats in the first list, alos maybe ad a weekKey to the diviver so we know where to save it!    
                            dividerList: { ..._dividerList, ...eventObject.dividerList }, // remove this to avoide duplicat dividers ? or use the same id on divders ? 
                            events: { ...eventObject1.events, ...eventObject.events },
                            publish: eventObject1.publish,
                            scheduleName: eventObject1.scheduleName,
                            sortedUserList: [...eventObject1.sortedUserList || [], ...eventObject.sortedUserList || []],
                            unPublish: eventObject1.unPublish
                        }
                        //console.timeEnd('cache')
                    }
                } else {
                    //  console.log('we dont have the data')
                }



                if (requesting['weekview_' + dateKey] === false && requested['weekview_' + dateKey] === true)
                    scheduleWeekData[dateKey] = data['weekview_' + dateKey]
            }

            // console.timeEnd('xxx')
            // console.log('eventObject', eventObject)
            // console.log('{ scheduleWeekData, eventObject, cCachekey }', { scheduleWeekData, eventObject, cCachekey })
            return ({ scheduleWeekData, eventObject, cCachekey })

        }

        return { scheduleWeekData: {}, eventObject: {}, cCachekey }


        //_eventDataObject(data, renderDayDate, selectedKey, weeksToShow)
    }
)(
    (state, dateKey, weeksToShow) => {
        // console.log('cacheKey', dateKey + '_' + weeksToShow)
        return dateKey + '_' + weeksToShow ///+ +new Date() //cache key
    }
)