import React, { useEffect } from 'react';
import * as lang from '../core/util/translation'
import { isMobile } from 'react-device-detect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faBars as icoPlus,


} from '@fortawesome/pro-solid-svg-icons';


const MenuTemplates = require("./MenuTemplates")
export default function WebMenu() {

    if (isMobile === true)
        return ''

    const closeMenu = () => {

        document.getElementById("menu-toggle0").checked = true;
    }

    useEffect(() => {
        document.body.addEventListener("mouseup", closeMenu)
        return () => {
            document.body.removeEventListener("mouseup", closeMenu)
        }

    }, [])


    const sendAppCmd = (cmd) => {
        if (cmd === 'clear') // dont thik this is used
            this.props.history.push('/signout')
        else
            window.dispatchEvent(new CustomEvent(cmd, { detail: { ...cmd.arg } }));

        document.getElementById("menu-toggle0").checked = true;
    }

    var MenuList = []
    var Menu = { buildFromTemplate: (item) => item }
    var printToPDF, app, _debug
    var { topContextMenuDefault, topContextMenuLoader, topContextMenuSchedular, topContextMenuChat, topContextMenuRequest, topContextMenuUsers, topContextMenuReports, topContextMenuStaff } = MenuTemplates.templates(Menu, sendAppCmd, printToPDF, app, _debug, window._getTextLanguage, lang)








    //console.log('url-->', url)
    const getMenuList = () => {
        const path = window.location.pathname.toLowerCase()
        // console.log('path', path)
        switch (path) {

            case '/chat1':
                //   console.log('go to chat1')
                return topContextMenuStaff
                break;

            case '/requests1':
                return topContextMenuStaff
                break;

            case '/home':
                return topContextMenuStaff
                break;


            case '/schedules':
                return topContextMenuStaff
                break;


            case '/schedule':
                return topContextMenuSchedular
                break;

            case '/chat':
                console.log('go to chat')
                return topContextMenuChat
                break;

            case '/requests':
                //    console.log('go to request')
                return topContextMenuRequest
                break;

            case '/schedule/users':
                //    console.log('go to users')
                return topContextMenuUsers
                break;

            case '/reports':
                return topContextMenuReports
                break;

            case '/today':
                return topContextMenuDefault
                break;

            case '/tour':
                return topContextMenuDefault
                break;
            default:


                if (path.indexOf('schedule') > -1) {
                    //   console.log('go to schedule')
                    return topContextMenuSchedular
                    break;
                }

                return topContextMenuLoader
                break;
        }

    }


    const doClick = (item) => (e) => {
        // console.log('click', item)
        if (item.click)
            item.click()

        e.stopPropagation()
    }



    MenuList = getMenuList()
    let counter = 0
    if (!MenuList)
        return ''

    // MenuList = [{
    //     label: "TopMennu",
    //     role: "appMenu",
    //     hideOnWeb: false,
    //     submenu: [
    //         ...MenuList
    //     ]

    // }]



    return (
        <React.Fragment>



            <div className='webMenuMain noPrint'   >
                <input type="radio" name="webMenuTop" id={"menu-toggle" + counter} />
                {MenuList.map((item, index) => {
                    counter++
                    if (!item.label)
                        return

                    if (item.hideOnWeb)
                        return

                    let label = item.label

                    if (label === 'TimeTo.Work')
                        label = <FontAwesomeIcon icon={icoPlus} style={{}}></FontAwesomeIcon>

                    return (

                        <div key={'1_' + index} style={{ padding: '2px', border: 'solid 0px red', background: 'rgba(0,0,0,0)' }} onClick={doClick(item)}>
                            <label style={{ border: 'solid 0px red', paddingLeft: '10px', paddingRight: '10px', display: 'block', fontFamily: 'sans-serif' }} htmlFor={"menu-toggle" + counter}>{label}</label>
                            <input type="radio" name="webMenuTop" id={"menu-toggle" + counter} />

                            <div id={"menu" + counter} className={'webMenuContainer'} >
                                {item.submenu && item.submenu.map((subItem, index1) => {
                                    if (!subItem.label)
                                        return

                                    if (subItem.hideOnWeb)
                                        return

                                    return (
                                        <div key={'2_' + index1} className={subItem.click ? 'webMenuCanClick' : ''} style={{ color: !subItem.enabled ? 'silver' : '#000', paddingLeft: !subItem.click ? 0 : '20px', paddingRight: !subItem.click ? 0 : '20px', borderBottom: 'solid 0px silver', fontWeight: subItem.click !== undefined ? 'normal' : 'bold', cursor: 'pointer' }} onClick={doClick(subItem)}>
                                            <span style={{ paddingLeft: subItem.click ? 0 : '20px', paddingRight: subItem.click ? 0 : '20px', fontFamily: 'sans-serif' }}>{subItem.label}</span>

                                            {subItem.submenu && subItem.submenu.map((subItem1, index2) => {

                                                if (subItem1.hideOnWeb)
                                                    return



                                                return (
                                                    <div key={'3_' + index2} className={subItem1.click ? 'webMenuCanClick' : ''} style={{ color: !subItem1.enabled ? 'silver' : '#000', paddingLeft: '20px', paddingRight: '20px', borderBottom: 'solid 0px silver', fontWeight: subItem1.click !== undefined ? 'normal' : 'bold', cursor: 'pointer' }} onClick={doClick(subItem1)}>
                                                        <span style={{ paddingLeft: 20, fontFamily: 'sans-serif' }}>{subItem1.label}</span>
                                                    </div>)
                                            })
                                            }
                                        </div>)
                                })
                                }
                            </div>
                        </div>)
                })}
            </div>
        </React.Fragment>)
}
