//import React from 'react';
import Loadable from 'react-loadable';

// import Loader from 'semantic-ui-react/dist/commonjs/elements/Loader'
// import Dimmer from 'semantic-ui-react/dist/commonjs/modules/Dimmer'


const Loading = () => ('')



export const DownloadHoursExcel = Loadable({
    loader: () => import(/* webpackChunkName: "report", webpackPrefetch: true */ '../../common/component/DownloadHoursExcel'),
    loading: Loading
});

export const DownloadHoursExcelAll = Loadable({
    loader: () => import(/* webpackChunkName: "report", webpackPrefetch: true */'../../common/component/DownloadHoursExcelAll'),
    loading: Loading
});


export const lSignOut = Loadable({
    loader: () => import('../login/SignOut'),
    loading: Loading
});

export const lPrivacyPolicy = Loadable({
    loader: () => import('../login/PrivacyPolicy'),
    loading: Loading
});

export const lLogin = Loadable({
    loader: () => import('../login/Login'),
    loading: Loading
});
export const lSetup = Loadable({
    loader: () => import('../login/Setup'),
    loading: Loading
});

export const lCreateNewAccount = Loadable({
    loader: () => import('../login/CreateNewAccount'),
    loading: Loading
});

export const lTermsConditions = Loadable({
    loader: () => import('../login/TermsConditions'),
    loading: Loading
});



export const lSchedule = Loadable({
    loader: () => import('../schedule/Schedule'),
    loading: Loading
});
export const lSettings = Loadable({
    loader: () => import('../schedule/settings/Settings'),
    loading: Loading
});
export const lUsers = Loadable({
    loader: () => import('../schedule/users/Users'),
    loading: Loading
});
export const lAccount = Loadable({
    loader: () => import('../user/account/Account'),
    loading: Loading
});
export const lUserSettings = Loadable({
    loader: () => import('../user/settings/UserSettings'),
    loading: Loading
});

export const lUserHome = Loadable({
    loader: () => import('../userhome/Userhome'),
    loading: Loading
});
export const lScheduleUser = Loadable({
    loader: () => import('../userhome/ScheduleUser'),
    loading: Loading
});
export const lMessages = Loadable({
    loader: () => import('../messages/Messages'),
    loading: Loading
});
export const lReports = Loadable({
    loader: () => import('../reports/Reports'),
    loading: Loading
});
export const lDev = Loadable({
    loader: () => import('../dev/Dev'),
    loading: Loading
});
export const lNewUserRedirecter = Loadable({
    loader: () => import('../userhome/NewUserRedirecter'),
    loading: Loading
});