module.exports = {
    'newTaskInfo': `Si vous avez besoin d'une visite rapide, cliquez sur l'onglet 'Aide' dans le menu en bas à gauche.
        Cliquez ensuite sur «Faire un tour» et revenez sur cette page.`,
    'Publish': `Publier`,
    'schedual.btnPasteSchedule': `Coller le programme`,
    'schedual.btnCopySchedule': `Copier le programme`,
    'NewFeatuer': `C'est une nouvelle fonctionnalité.`,
    'LinkCopied': `lien copié`,
    'GoToWorkView': `Ceci est le calendrier publié
        Accédez à la vue de travail pour apporter des modifications`,
    'QuickIntro': `Besoin d'une introduction rapide sur le fonctionnement de cette application?`,
    'Workview': `Vue de travail`,
    'AddRow': `Ajouter une rangée`,
    'Published': `Publié`,
    'thisIsWatStaffSee': `C'est ce que voient votre personnel`,
    'Unpublish': `Annuler la publication`,
    'TaskStartInfo': `Cliquez sur une tâche ou créez-en une nouvelle en cliquant sur le bouton plus dans le coin supérieur droit`,
    'ClickHereToStart': `Cliquez ici pour commencer`,
    'joy.viewWeeks': `Nombre de semaines à afficher`,
    'tour.onboardStep_mainMenu': `Commencez par cliquer sur l'onglet dont vous souhaitez visiter.`,
    'comefirmUnlinkUser': `Voulez-vous dissocier l'utilisateur?`,
    'draft': `Brouillon, non publié`,
    'Unpublishing': `Annulation de la publication ...`,
    'AreYouSurePublish': `Voulez-vous vraiment publier le calendrier pour tout le personnel?`,
    'tour.tour_report_1': `Mois en cours. Utilisez les flèches pour changer le mois.`,
    'tour.tour_chats_3': `Sélectionnez un salon de discussion et écrivez votre message ici.`,
    'AdminCanAddScheduls': `Seul l'administrateur peut ajouter de nouveaux horaires`,
    'PrintNoAbuseMsg': `Seul le client payant peut utiliser la fonction d'impression
        (Ceci afin d'éviter les abus du système.)`,
    'tour.tour_schedule_9': `Ceci est votre vue de travail, les membres du personnel ne peuvent pas voir cette version de l'horaire.`,
    'CopyShift': `Copier le quart`,
    'tour.tour_requests_2': `Le décalage d'origine par rapport à votre horaire publié.`,
    'ClearSchedule': `Voulez-vous effacer ce calendrier?`,
    'tour.tour_schedule_6': `Cliquez sur une équipe pour la modifier ou faites-la glisser pour la déplacer.
        Si vous maintenez l'option enfoncée lors du glissement, le décalage sera cloné.`,
    'tour.tour_staff_3': `Cliquez ici pour envoyer des invitations à tous les utilisateurs non invités à la fois.`,
    'PleaseAddCreditsBeforePrinting': `Veuillez ajouter des crédits avant d'imprimer`,
    'tour.tour_staff_1': `Cliquez ici pour ajouter un nouveau membre du personnel`,
    'AddDivider': `Ajouter un diviseur`,
    'WantToWork': `Veut travailler`,
    'tour.tour_requests_5': `Les modifications ont été refusées et aucune modification n'est effectuée.`,
    'tour.tour_report_3': `Ceci est la feuille de temps
        Ici vous pouvez voir les heures travaillées.
        Toutes les données proviennent du calendrier publié.
        survolez une ligne puis cliquez sur le stylo pour modifier le quart de travail.`,
    'CreateShiftRequest': `Créer une demande de quart`,
    'tour.tour_chats_2': `En tant qu'administrateur, vous pouvez ajouter de nouveaux salons de discussion.`,
    'tour.tour_schedule_10': `C'est ce que vos utilisateurs voient dans l'application mobile.
        Ce sont également les données des rapports.`,
    'tour.tour_schedule_6_1': `Cliquez sur l'utilisateur pour copier une ligne ou afficher l'utilisateur.`,
    'tour.tour_schedule_5': `Vous pouvez également double-cliquer sur un jour pour ajouter un quart de travail
        (Veuillez vous assurer d'avoir au moins un quart de travail dans l'horaire avant de passer à l'étape suivante)`,
    'TakeTour': `Faites une visite des produits`,
    'tour.tour_report_2': `Sélectionnez un utilisateur puis cliquez sur suivant.
        Si les heures de l'utilisateur sont 0: 00h, vous devez publier votre horaire.
        Toutes les données ici proviennent du calendrier publié.`,
    'Mo': `Mo`,
    'Fr': `Fr`,
    'tour.tour_staff_2': `Cliquez ici pour ajouter plusieurs membres à la fois`,
    'login.demo': `Démo`,
    'NewVersion': `Une nouvelle version est disponible, souhaitez-vous mettre à jour maintenant?`,
    'Th': `Th`,
    'Askforthisshift': `Demandez ce changement`,
    'tour.tour_schedule_8': `Si vous souhaitez supprimer le calendrier publié, vous pouvez le faire en cliquant ici.`,
    'tour.tour_requests_3': `Le nouveau quart de travail que le membre du personnel veut changer en ...`,
    'tour.tour_schedule_3': `Ou utilisez ce sélecteur de semaine pour aller à une semaine spécifique.`,
    'tour.tour_today_2': `Cliquez ici si vos équipes se chevauchent.`,
    'tour.tour_requests_empty': `Veuillez vous assurer d'avoir au moins une demande avant de faire la visite.
        
        Par exemple, cliquez sur un quart de travail dans l'horaire puis faites un clic droit sur "Créer une demande de quart"
        `,
    'ThereIsNoPubSchedule': `Il n'y a pas d'horaire publié ce mois-ci.`,
    'tour.tour_staff_4': `Pour afficher ou modifier un membre du personnel, cliquez ici`,
    'PasteShift': `Coller le décalage`,
    'TaskRestarted': `La tâche est redémarrée et déplacée vers l'onglet Actif.`,
    'NewShift': `Nouveau quart de travail`,
    'TaskIsRemoved': `La tâche est supprimée.`,
    'DeleteShift': `Supprimer l'équipe`,
    'shiftIsCloned': `Shift est cloné`,
    'TaskIsPublished': `La tâche est publiée.`,
    'tour.tour_schedule_2': `Semaine en cours, utilisez les flèches pour passer à la semaine suivante / précédente.`,
    'schedual.m.Tour': `Aidez-moi`,
    'MovetToArchive': `La tâche est déplacée vers Archive.`,
    'tour.tour_schedule_4': `Cliquez ici pour ajouter un utilisateur ou une équipe
        (Si vous n'avez aucun utilisateur dans votre calendrier, veuillez en ajouter un maintenant)`,
    'tour.tour_today_1': `Voici à quoi ressemble la journée actuelle.
        Les données proviennent du calendrier publié.`,
    'PrivateNotes': `Notes privées`,
    'minutes': `minutes`,
    'Available': `Disponible`,
    'DemoAccount': `Compte démo, cliquez ici pour vous déconnecter et créer votre propre compte.`,
    'AddASchedule': `Ajoutez un calendrier pour commencer.`,
    'AreYouSureDeleteTask': `Êtes-vous sûr de vouloir supprimer cette tâche`,
    'AddStaff': `Ajouter du personnel`,
    'tour.tour_schedule_7': `Lorsque vous êtes satisfait de votre emploi du temps et que vous souhaitez le partager avec votre personnel.
        Cliquez ici pour publier.`,
    'tour.tour_requests_1': `Il s'agit de la boîte de réception et d'envoi pour vos demandes.`,
    'Vacation': `Vacances`,
    'Sa': `À`,
    'RequestIsSent': `Demande envoyée`,
    'DataComesFromPubSche': `(Les données affichées ici proviennent des horaires publiés)`,
    'MovetToActive': `La tâche est déplacée vers Active.`,
    'addMoreBeforPrint': `Veuillez ajouter plus de crédits avant d'imprimer`,
    'StartByAddSchedule': `Commencez par ajouter le nom de votre horaire principal.`,
    'We': `nous`,
    'tour.tour_staff_5': `Cliquez ici pour inviter l'utilisateur par mail ou SMS.`,
    'Tu': `votre`,
    'schedual.m.Tasks': `Tâches`,
    'YouCanOnlyPrint4week': `Vous ne pouvez imprimer que quatre semaines à l'avance`,
    'tour.tour_requests_4': `Approuver la demande de l'utilisateur
        Ce quart de travail sera mis à jour dans votre calendrier publié.`,
    'tour.tour_chats_1': `Ceci est votre liste de discussion.
        Tout est un groupe de discussion sans suppression pour tous les membres du personnel.
        Le journal est une liste de journaux de vos demandes / réponses.`,
    'ScheduleIsCopied': `L'horaire est copié`,
    'PleaseRestart': `Veuillez redémarrer votre application pour que les paramètres s'appliquent.`,
    'tour.tour_schedule_1': `L'horaire.
        Cliquez sur l'engrenage pour changer un nom ou supprimer l'horaire.
        (Veuillez vous assurer d'avoir au moins un utilisateur de planification dans la planification avant de continuer)`,
    'Su': `le sien`,
    'TaskIsUnPublished': `La tâche n'est pas publiée.`,
    'tour.tour_report_4': `Cliquez ici pour exporter un rapport xlsx.
        Si vous souhaitez télécharger tous les rapports sur une seule feuille, utilisez le menu supérieur.
        Fichier -> Exporter -> Exporter au format xlsx.`,
    'CanNotWork': `Ne peut pas fonctionner`,
    'login.err.2': `Le mot de passe n'est pas valide.`,
    'createNewAccount.info': `Utilisez le numéro de téléphone si vous avez été invité par SMS ou par e-mail si vous avez reçu une invitation par e-mail`,
    'createNewAccount.smscodeTitle': `Code du message texte`,
    'login.err.1': `Aucun utilisateur ne correspond à cette adresse e-mail. L'utilisateur a peut-être été supprimé.`,
    'login.err.4': `Le compte a été désactivé par un administrateur.`,
    'createNewAccount.newaccount': `Nouveau compte personnel`,
    'login.btnLogin': `S'identifier`,
    'login.err.3': `L'adresse e-mail est mal formatée.`,
    'login.err.6': `Ce n'est pas un numéro de téléphone valide. Vous devez commencer par le code de votre pays.`,
    'createNewAccount.newSchedule': `Nouveau programme`,
    'login.err.5': `Le mot de passe doit contenir au moins 6 caractères.`,
    'createNewAccount.teleOrEmail': `Numéro de téléphone ou email`,
    'createNewAccount.setPassword': `Definir un nouveau mot de passe`,
    'createNewAccount.smscode': `Code du message texte`,
    'createNewAccount.login': `S'identifier`,
    'or': `ou`,
    'set.settings.btn.dev': `Les écarts`,
    'createNewAccount.dontHavePassword': `Vous n'avez pas de mot de passe?`,
    'set.settings.time': `Temps`,
    'set.settings.timeWarning': `Une fois modifiés, vous devez réenregistrer vos données de planification pour renouveler les anciennes étiquettes d'heure en cache`,
    'set.settings.btn.com': `commentaires`,
    'set.settings.publickey': `Clé de l'entreprise`,
    'set.settings.auto': `Approuver automatiquement les changements de quart d'utilisateur`,
    'set.settings.btn.sMov': `Déplacer les quarts de travail`,
    'set.settings.publickeytext': `C'est la clé dont vos collaborateurs ont besoin lorsqu'ils créent un nouveau compte`,
    'set.settings.btn.sCan': `Supprimer les équipes`,
    'createNewAccount.comName': `Nom de la compagnie`,
    'SCHEDULES': `DES HORAIRES`,
    'on': `Sur`,
    'createNewAccount.weekstart': `La semaine commence par`,
    'createNewAccount.secretKey': `Clé de l'entreprise`,
    'import': `Importer`,
    'signout': `Déconnexion`,
    'createNewAccount.comDisplayName': `Nom d'affichage de l'entreprise`,
    'createNewAccount.adminAccount': `Compte administrateur`,
    'January': `janvier`,
    'June': `juin`,
    'March': `Mars`,
    'time': `Temps`,
    'uploading_image': `Téléchargement de l'image ...`,
    'December': `décembre`,
    'shifts': `décalages`,
    'inactive': `Inactif`,
    'week': `La semaine`,
    'February': `février`,
    'schedule': `Programme`,
    'phonenumber': `Numéro de téléphone`,
    'h': `h`,
    'message.out': `En dehors`,
    'oops': `Oops!`,
    'update': `Mise à jour`,
    'save': `sauvegarder`,
    'total': `Total`,
    'Apr': `avr`,
    'users.infoText': `Ceux-ci sont utilisés pour connecter votre personnel à un utilisateur dans le planning, il vous suffit de définir l'un d'entre eux.`,
    'schedual.m.addNewUser': `Ajouter un nouveau membre du personnel`,
    'schedual.lbl-unassigned': `Non attribué`,
    'logginin': `Se connecter...`,
    'back': `retour`,
    'upload': `Télécharger`,
    'August': `août`,
    'tooltip.AddANewChat': `Ajouter un nouveau chat`,
    'active': `actif`,
    'saturday': `samedi`,
    'average': `Moyenne`,
    'September': `septembre`,
    'break': `Pause`,
    'billing.nextInvoice': `Prochaine facture`,
    'billing.msgBody': `Veuillez ajouter plus de crédits pour continuer à utiliser le système`,
    'set.settings.btn.sik': `Congé de maladie`,
    'AreYouSureYouWantToQuitYes': `Oui`,
    'terms': `Conditions d'utilisation`,
    'setup.creatingCompany': `Création d'un compte professionnel ...`,
    'setup.notValidKey': `Ce n'est pas une clé d'entreprise valide`,
    'user.active': `actif`,
    'users.linked': `Lié`,
    'users.uniquephone': `Le téléphone doit être unique`,
    'schedual.addS': `Ajouter un nouvel horaire`,
    'schedual.m.addDiv': `Ajouter un séparateur utilisateur`,
    'locked.txt2': `Ne vous inquiétez pas, c'est une solution facile, ajoutez plus de crédits et dans quelques minutes, il sera à nouveau activé`,
    'setup.comKey': `Clé de l'entreprise`,
    'setup.createNewAccount': `Créer un nouveau compte utilisateur`,
    'setup.updateKey': `Mettre à jour la clé`,
    'setup.retry': `Recommencez`,
    'createNewAccount.youneedCompanyName': `Vous devez avoir un nom d'entreprise`,
    'createNewAccount.theKey': `La clé que vous avez obtenue de votre manager`,
    'users.infoText1': `Cet utilisateur est lié à un membre du personnel, cliquez sur le verrou pour le dissocier.`,
    'Oct': `oct`,
    'search': `Chercher...`,
    'new': `Nouveau`,
    'tuesday': `Mardi`,
    'date': `Date`,
    'users': `utilisateurs`,
    'October': `octobre`,
    'hours': `Heures`,
    'rotateimage': `Faire pivoter l'image`,
    'login.lostpass': `Mot de passe oublié?`,
    'tooltip.Deny': `Nier`,
    'phone': `Téléphone`,
    'add': `Ajouter`,
    'monday': `Lundi`,
    'sick': `Malade`,
    'name': `Nom`,
    'message.empty': `Vous n'avez aucune demande.`,
    'cellphone': `Téléphone portable`,
    'create': `Créer`,
    'picture': `Image`,
    'Dec': `déc`,
    'delete': `Effacer`,
    'message.out.empty': `Vous n'avez aucune demande en attente.`,
    'send': `Envoyer`,
    'wednesday': `Mercredi`,
    'Sep': `SEP`,
    'Jul': `juil`,
    'Jun': `Juin`,
    'login.newUser': `Nouveau compte personnel`,
    'message.chat': `Bavarder`,
    'logginout': `Déconnecter...`,
    'sunday': `dimanche`,
    'wShort': `dans.`,
    'Aug': `Août`,
    'Mar': `Mer`,
    'yes': `Oui`,
    'now': `Maintenant`,
    'login.forEmp': `Pour l'employé`,
    'login.newSchedule': `Nouveau compte de planification`,
    'login.titel': `S'identifier`,
    'tooltip.Cancel': `Annuler`,
    'password': `Mot de passe`,
    'type': `Type`,
    'Feb': `fév`,
    'off': `De`,
    'login.titleNewAccount': `Nouveau compte`,
    'login.sendpass': `Envoyer un nouveau mot de passe`,
    'loading': `Chargement...`,
    'cancel': `Annuler`,
    'thursday': `Jeudi`,
    'May': `Peut`,
    'November': `novembre`,
    'April': `avril`,
    'billing.payDetails': `Détails de paiement`,
    'report.h_time': `Temps`,
    'request.titleChange': `Changer le temps`,
    'billing.msgHead2': `ou votre compte sera automatiquement verrouillé`,
    'shiftuserinput.title4': `Marquer comme malade`,
    'billing.infoTxt2': `après cela, votre accès sera désactivé jusqu'à ce que vous ajoutiez plus de crédit`,
    'appdiv.pushmessages': `Messages push`,
    'wallet.txtWeb2': `même si vous n'utilisez TimeToWork que sur le Web, vous devez effectuer les paiements via l'un des magasins. Veuillez télécharger l'application depuis l'un des magasins et vous verrez un bouton acheter plus de crédits dans l'application ici`,
    'request.titleComment': `Commentaire`,
    'set.settings.btn.openshifts': `Changement de livre`,
    'request.adminRequest': `Demande`,
    'userhome.youarefree': `Vous êtes libre de faire ce que vous voulez cette semaine`,
    'report.h_workedhours': `Heures travaillées`,
    'editdivider.title': `Diviseur de groupe`,
    'request.titleSwap': `Swap shift`,
    'shiftuserinput.title5': `Demander un quart de travail`,
    'wallet.purchasing': ` `,
    'shiftuserinput.btn2': `Demander un changement`,
    'report.h_date': `Date`,
    'shiftuserinput.btn3': `Ajouter un commentaire`,
    'report.downloadall': `Tout télécharger`,
    'selectuser.title': `Liste d'utilisateur`,
    'billing.infoTxt3': `Si vous ne souhaitez pas continuer à utiliser votre emploi du temps, vous pouvez simplement ignorer l'ajout de nouveaux crédits, après 7 jours, votre compte sera verrouillé et après 360 jours, il sera supprimé`,
    'usernotmapped_txt1': `Vous n'êtes pas associé à un utilisateur dans la planification`,
    'schedual.adminrequest': `Administrateur`,
    'wallet.waiting_for_validation': `En attente de validation.`,
    'usernotmapped_title': `Important!`,
    'report.downloadExcel': `Télécharger excel`,
    'appdiv.changepass': `Changer le mot de passe`,
    'shiftuserinput.btn6': `Ajuster l'heure`,
    'appmenu.user': `Utilisateur`,
    'wallet.txtWeb1': `Pour le moment, nous ne prenons en charge l'achat de nouveaux crédits que via Google Play ou iOS App Store`,
    'billing.msgHead1': `Veuillez ajouter plus de crédit avant`,
    'appmenu.changepass': `Changer le mot de passe`,
    'wallet.deferred': `L'achat de a été reporté.`,
    'request.titleUserrequestshift': `Demande de changement`,
    'set.settings.btn.sSwp': `Swap shift`,
    'wallet.success': `Succès.`,
    'wallet.failed': `Échec de l'achat.`,
    'schedual.btnAddUser': `Ajouter du personnel`,
    'shiftuserinput.swapTitle1': `Sélectionnez l'utilisateur avec lequel échanger`,
    'shiftuserinput.usercomment': `Commentaire utilisateur`,
    'shiftuserinput.btn7': `Attribuer une équipe à un autre utilisateur`,
    'report.h_break': `Pause`,
    'billing.infoTxt1': `Tous les 30 jours, nous vérifierons les crédits utilisés par rapport à vos crédits existants, si votre crédit est faible, vous aurez 7 jours pour ajouter plus de crédits`,
    'billing.msgBodyDays': `journées`,
    'wallet.faild': `contactez le support si vous avez des questions`,
    'shiftuserinput.title2': `Demander un changement`,
    'report.h_sik': `Malade`,
    'report.h_hours': `Heures`,
    'report.noscheduled': `Aucune heure programmée ce mois-ci`,
    'schedual.btnAddShift': `Ajouter une équipe`,
    'wallet.failed1': `contactez le support si vous avez des questions`,
    'wallet.pleasewait': `S'il vous plaît, attendez`,
    'shiftuserinput.addCommentToUser': `Ajouter un commentaire à l'utilisateur`,
    'shiftuserinput.btn5': `Annuler le quart`,
    'shiftuserinput.title0': `Mettre à jour le quart de travail`,
    'appmenu.pushmessages': `Notifications push`,
    'wallet.title1': `Achetez plus de crédit`,
    'WeCanNotMatchYou': `Nous ne pouvons pas faire correspondre votre courrier à un utilisateur, demandez à votre responsable d'ajouter votre courrier à un utilisateur dans le planning`,
    'settings.title': `Préférences`,
    'and': `et`,
    'setup.yesImAManger': `Oui, je suis manager`,
    'settings.account': `Compte`,
    'Help': `Aidez-moi`,
    'dontShowThis': `Ne montrez pas cela au démarrage`,
    'IncomingRequests': `Demandes entrantes`,
    'Preferences...': `Préférences ...`,
    'billing.msgLink': `Accédez à TimeTo.Work -> Préférences -> Facturation. Dans le menu du haut`,
    'policy': `Politique de confidentialité`,
    'joy.welcome': `Bienvenue!`,
    'joy.Last': `Dernier`,
    'log': `Journal`,
    'EditChat': `Modifier le chat`,
    'joy.startbyaddingusers': `Commencez par ajouter quelques membres du personnel`,
    'Toggle menu bar icon': `Basculer l'icône de la barre de menu`,
    'AddMoreCredits': `Ajouter plus de crédits`,
    'Paste schedule': `Coller le calendrier`,
    'Staff': `Personnel`,
    'Signout': `Déconnexion`,
    'settings.billing': `Facturation`,
    'Hide TimeTo.Work': `Masquer TimeTo.Work`,
    'Quit': `Quitter`,
    'Copy': `Copie`,
    'joy.clickHereToAddMultipleUsers': `Ici, vous pouvez ajouter plusieurs membres du personnel à la fois`,
    'Reports as XLSX': `Rapports comme XLSX`,
    'Clear all data': `Effacer toutes les données`,
    'Edit': `Éditer`,
    'OrAreYouAManger': `Ou êtes-vous un manager et souhaitez créer un nouvel emploi du temps?`,
    'companyNameIsNotValid': `Le nom de l'entreprise n'est pas valide`,
    'a minute ago': `Il y'a une minute`,
    'About TimeTo.Work': `À propos de TimeTo.Work`,
    'OutgoingRequests': `Demandes sortantes`,
    'joy.clickHereToAddAShift': `Cliquez ici pour ajouter une équipe`,
    'settings.settings': `Réglages`,
    'AreYouSureYouWantToQuitTitle': `Confirmer l'arrêt`,
    'joy.Back': `Retour`,
    'window': `Temps de travailler`,
    'How do i ...': `Comment puis-je ...`,
    'Divider': `Diviseur`,
    'byclicking': `En cliquant sur «Créer», vous acceptez les`,
    'all': `Tout`,
    'joy.goToSchedule': `aller à la planification pour commencer la planification`,
    'Saved': `Enregistré`,
    'an hour ago': `il y a une heure`,
    'joy.whenYourDone': `Lorsque vous avez terminé d'ajouter des membres du personnel,`,
    'Shift': `Décalage`,
    'TotalBreakTime': `Temps de pause total`,
    'shortIntro': `Ceci est une courte introduction pour vous familiariser avec le fonctionnement de TimeTo.work`,
    'a day ago': `il y a un jour`,
    'minutes ago': `il y a quelques minutes`,
    'Window': `Fenêtre`,
    'Clear schedule': `Calendrier clair`,
    'Copy schedule': `Copier le calendrier`,
    'New schedule': `Nouveau programme`,
    'Hide Others': `Masquer les autres`,
    'Cut': `Couper`,
    'RestorePreviousPurchase': `Restaurer l'achat précédent`,
    'currentPrice': `1 utilisateur pendant 30 jours = 1 crédit`,
    'AreYouSureYouWantToQuitNo': `Non`,
    'WelcomeTo': `Bienvenue chez TimeTo.Work`,
    'joy.addMultipleRows': `Ici, vous pouvez ajouter plusieurs lignes à la fois`,
    'joy.Next': `Suivant`,
    'Prev': `Précédente`,
    'Next': `Suivant`,
    'Export': `Exportation`,
    'New staff': `Nouvelle équipe`,
    'AreYouSureYouWantToQuit': `Êtes-vous sûr de vouloir quitter?
    
    Vous perdrez toutes les données non publiées.`,
    'zoom': `Zoom`,
    'joy.dontForgetToSave': `Lorsque vous avez terminé, n'oubliez pas d'enregistrer et de publier votre emploi du temps`,
    'users.invalidphone2': `Vous devez ajouter le code du pays avant le numéro`,
    'users.notemail': `Ce n'est pas un email valide`,
    'users.staffmembers': `Les membres du personnel`,
    'schedual.addSName': `Nom du programme`,
    'schedual.editS': `Modifier les horaires`,
    'schedual.m.tools': `Outils`,
    'schedual.m.Reports': `Rapports`,
    'schedual.m.Request': `Demande`,
    'schedual.m.Today': `Aujourd'hui`,
    'schedual.emptyTxt1': `Utilisez le clic secondaire pour ajouter du personnel ou des équipes.`,
    'schedual.emptyTxt2': ` `,
    'createNewAccount.ampmOr24h': `Format de l'heure`,
    'createNewAccount.termsApprove': `Vous devez approuver les termes et conditions`,
    'createNewAccount.terms': `J'accepte les termes et conditions`,
    'createNewAccount.useSameEmail': `Utilisez le même e-mail que vous avez reçu dans l'invitation. Nous pouvons donc vous mapper à un utilisateur dans le calendrier`,
    'set.billing': `Facturation`,
    'user.allLinked': `Tous les utilisateurs sont liés`,
    'user.unlockEmail': `Déverrouiller l'e-mail`,
    'users.uniqueemail': `L'e-mail doit être unique`,
    'schedual.m.pasteS': `Coller le calendrier`,
    'schedual.m.addNewSchedule': `Ajouter un nouvel horaire`,
    'schedual.m.Staff': `Personnel`,
    'schedual.m.Schedule': `Programme`,
    'schedual.last': `Dernière mise à jour`,
    'locked.txt1': `Votre compte est temporairement bloqué en raison d'un manque de crédits.`,
    'locked.title': `Ton compte est bloqué!`,
    'setup.needSetup': `Nous avons créé votre compte avec succès mais nous devons le connecter à une entreprise`,
    'createNewAccount.youneedkey': `Vous devez avoir une clé d'entreprise`,
    'createNewAccount.emailAndPass': `Email et mot de passe`,
    'set.settings': `Réglages`,
    'user.bulkTitle': `Insérer une liste d'utilisateurs séparés par des virgules`,
    'users.delete': `Si vous supprimez l'utilisateur, il sera définitivement supprimé!
        Et toutes les données historiques seront marquées avec "Utilisateur inconnu"
    Parfois, il peut être préférable de simplement marquer l'utilisateur comme inactif.`,
    'users.dname': `Afficher un nom`,
    'users.newuser': `Nouveau`,
    'schedual.deleteBtn2': `Confirmer la suppression définitive`,
    'schedual.deleteWarning': `Si vous supprimez le calendrier, il sera définitivement supprimé et toutes les données historiques s'y rattachant`,
    'schedual.deleteBtn1': `Supprimer le calendrier`,
    'schedual.m.addOpenShifts': `Ajouter des «équipes ouvertes»`,
    'schedual.m.print': `Impression`,
    'schedual.m.Chat': `Bavarder`,
    'schedual.openshifts': `Quarts ouverts`,
    'locked.link': `Cliquez ici pour obtenir plus de crédits`,
    'schedual.dropdelete': `Déposez shift / user ici pour le supprimer!`,
    'setup.mappingUser': `Associer un utilisateur à un calendrier ...`,
    'user.inviteUnMaped': `Inviter des utilisateurs dissociés`,
    'set.settings.title': `Les paramètres du système`,
    'user.bulkTxt': `Un utilisateur par ligne. Séparez le nom et le numéro / mail par une virgule`,
    'users.deleteBtn1': `Supprimer l'utilisateur`,
    'users.uniqueemail2': `Ce courrier ne peut pas être utilisé`,
    'schedual.m.undo': `Annuler toutes les modifications`,
    'schedual.m.copyS': `Copier le calendrier`,
    'schedual.m.Home': `Accueil`,
    'schedual.emptyTitle': `Votre emploi du temps est vide!`,
    'setup.wrongKey': `La clé de l'entreprise est manquante ou erronée`,
    'user.bulkimport': `Importation en masse`,
    'users.deleteBtn2': `Confirmer la suppression définitive`,
    'schedual.m.clear': `Effacer tous les quarts de travail`,
    'users.invalidphone': `Ce n'est pas un numéro de mobile valide`,
    'user.bulkTxt2': `Nom d'affichage, e-mail ou téléphone
    John Doe, +1123456789 ou Jane Doe, jane@timeto.work`,
    'schedual.lbl-openshifts': `Quarts ouverts`,
    'schedual.addSFirstTitle': `Ajouter un nouvel horaire`,
    'login.sentResetMail': `Nous avons envoyé un lien de réinitialisation à votre e-mail.`,
    'message.in': `Dans`,
    'email': `Email`,
    'login.forCom': `Pour la compagnie`,
    'message.in.empty': `Vous n'avez aucune demande.`,
    'credit': `Crédit`,
    'Jan': `Jan`,
    'invites': `Inviter`,
    'July': `juillet`,
    'Nov': `nov`,
    'validphone': `+ code pays et numéro de portable`,
    'friday': `Vendredi`,
    'tooltip.Approve': `Approuver`,
    'no': `Non`,
    'calendar.empty': `Il n'y a pas d'horaire cette semaine`,
    'schedual.btnHelp': `Aidez-moi`,
    'schedual.btnAddDiv': `Ajouter un diviseur`,
    'schedual.btnPublish': `Enregistrer et publier`,
    'wallet.restored': `L'achat de a été restauré.`,
    'wallet.success1': `les crédits viendront sur votre compte sous peu`,
    'request.titleSick': `Malade`,
    'request.titleTime': `Écart de temps`,
    'request.titleMove': `Déplacer le quart`,
    'request.titleCancle': `Annuler le quart`,
    'shiftuserinput.cancelation': `Cause d'annulation`,
    'shiftuserinput.swapTitle3': `Sélectionnez Shift`,
    'shiftuserinput.swapTitle2': `Sélectionnez la semaine`,
    'shiftuserinput.comment': `Commentaire`,
    'shiftuserinput.btn8': `Échange de quart avec un autre utilisateur`,
    'shiftuserinput.btn4': `Marquer comme malade`,
    'shiftuserinput.btn1': `Signaler l'écart de temps`,
    'shiftuserinput.title3': `Ajouter un commentaire`,
    'shiftuserinput.title1': `Temps de travail réel`,
    'joy.Close': `proche`,
    'front': `Mettez tout au premier plan`,
    'minimize': `minimiser`,
    'Quick introduction': `Introduction rapide`,
    'Schedule as PDF': `Planifier au format PDF`,
    'New chat': `Nouveau chat`,
    'Select All': `Tout sélectionner`,
    'services': `Prestations de service`,
    'years ago': `il y a des années`,
    'months ago': `il y a des mois`,
    'days ago': `il y a quelques jours`,
    'hours ago': `il y a des heures`,
    'a few seconds ago': `il ya quelques secondes`,
    'joy.clickonstaff': `Cliquez sur 'Staff'`,
    'a year ago': `il y a un an`,
    'a month ago': `il y a un mois`,
    'seconds ago': `il y a quelques instants`,
    'joy.orClickOnTheSchedule': `Ou vous pouvez double-cliquer sur l'horaire`,
    'joy.clickHerToAddUsers': `Cliquez ici pour ajouter un ou deux membres du personnel`,
    'joy.Skip': `Passer le tutoriel`,
    'Undo all changes': `Annuler toutes les modifications`,
    'Add to schedule': `Ajouter au programme`,
    'File': `Fichier`,
    'selectuser.warning': `Sélectionnez au moins un utilisateur à ajouter à la planification`,
    'usernotmapped_txt2': `Demandez à votre administrateur de schéma d'ajouter votre adresse e-mail`,
    'report.h_schedal': `Programme`,
    'report.h_presence': `Présence`,
    'billing.bHistory': `Historique de facturation`,
    'billing.msgHead': `Votre compte n'a pas assez de crédits.`,
    'set.settings.btn.sCha': `Changer le temps`,
    'Show All': `Afficher tout`,
    'Paste': `Pâte`,
    'userCredits': `crédits utilisateurs`,
    'ShiftLabel': `Étiquette de décalage`,

}