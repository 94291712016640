export const TOGGLE_TEMPLATES = 'viewstate:toggletemplate'
export const ORIENTATION = 'viewstate:orientation'
export const KEYBOARD_OPEN = 'viewstate:keyboardopen'
export const RENDER_INIT = 'viewstate:renderdateinit'
export const RENDER_DATE = 'viewstate:renderdate'
export const RENDER_DATE_NOW = 'viewstate:renderdatenow'
export const RENDER_DATE_ADD_DAYS = 'viewstate:renderdateadddays'
export const SET_NEVER = 'viewstate:setNerver'
export const SET_FLATLIST_INDEX = 'viewstate:setFlatlistIndex'
export const CLONE_SHIFT = 'viewstate:cloneShift'
export const SET_WEEKS_TO_SHOW = 'viewstate:weeksToShow'

export const SET_LEFT_MENU_OPEN = 'viewstate:setLeftMenuOpen'

export function setFlatlistIndex(value) {
    return {
        type: SET_FLATLIST_INDEX,
        payload: value
    }
}

export function setNerver(action, value) {
    return {
        type: SET_NEVER,
        payload: { action, value }
    }
}


export function renderDateAddDays(days) {
    return {
        type: RENDER_DATE_ADD_DAYS,
        payload: days
    }
}

export function initRenderDate(firstDayOfWeek) {
    return {
        type: RENDER_INIT,
        payload: firstDayOfWeek
    }
}

export function setRenderDate(date) {
    return {
        type: RENDER_DATE,
        payload: date
    }
}
export function setRenderNow() {
    return {
        type: RENDER_DATE_NOW
    }
}

export function setOrientation(isLandscape) {
    return {
        type: ORIENTATION,
        payload: isLandscape
    }
}
export function setKeyboardOpen(open) {
    return {
        type: KEYBOARD_OPEN,
        payload: open
    }
}

export function toggelTemplates() {
    return {
        type: TOGGLE_TEMPLATES
    }
}


export function setCloneShift(active) {
    return {
        type: CLONE_SHIFT,
        payload: active
    }
}



export function setLeftMenuOpen(open) {
    return {
        type: SET_LEFT_MENU_OPEN,
        payload: open
    }
}


export function setWeeksToShow(weeks) {
    return {
        type: SET_WEEKS_TO_SHOW,
        payload: weeks
    }
}

