import moment from 'moment';
import moize from "moize";
import firebase, { config } from '../config/firebase'
import momentDurationFormatSetup from 'moment-duration-format'
momentDurationFormatSetup(moment)

export const arrayPutOnTop = (key, array) => {
    const index = array.findIndex((k) => k === key);
    if (index > -1) {
        array.splice(index, 1); // REMOVE ORGINAL ITEM
        array.unshift(key);
    }
    else
        array.unshift(key)

    return array;
};
export const arrayPutOnTopIfExits = (key, array) => {
    const index = array.findIndex((k) => k === key);
    if (index > -1) {
        array.splice(index, 1); // REMOVE ORGINAL ITEM
        array.unshift(key);
    }


    return array;
};
export const toTop = (height = 0) => {
    window.scrollTo(0, height);
    if (document.body.scrollTo)
        document.body.scrollTo(0, height);
}
export const getWeekObject = (date, firstDayOfWeek) => {

    if (firstDayOfWeek === 0)
        moment.locale('en')
    else
        moment.locale('en-gb')

    var renderDate = moment.utc(date).startOf('week');
    //const dateKey = getDateKey(renderDate)
    return { firstday: renderDate }//, renderDateKey: dateKey }
}
export const short = (str, n) => {
    if (!str)
        return ''
    return (str.length > n) ? str.substr(0, n - 1) + '...' : str
}
export const objectToArray = (object) => {
    if (object) {
        return Object.entries(object).map(e => Object.assign(e[1], { id: e[0] }))
    }
}
export const times = {
    '00': ['12', 'am'],
    '01': ['1', 'am'],
    '02': ['2', 'am'],
    '03': ['3', 'am'],
    '04': ['4', 'am'],
    '05': ['5', 'am'],
    '06': ['6', 'am'],
    '07': ['7', 'am'],
    '08': ['8', 'am'],
    '09': ['9', 'am'],
    '10': ['10', 'am'],
    '11': ['11', 'am'],
    '12': ['12', 'pm'],
    '13': ['1', 'pm'],
    '14': ['2', 'pm'],
    '15': ['3', 'pm'],
    '16': ['4', 'pm'],
    '17': ['5', 'pm'],
    '18': ['6', 'pm'],
    '19': ['7', 'pm'],
    '20': ['8', 'pm'],
    '21': ['9', 'pm'],
    '22': ['10', 'pm'],
    '23': ['11', 'pm'],
}
export const time24hToAmPm = (time) => {
    var t = (time || '').split(":")
    if (t.length === 2) {
        const amPm = times[t[0]]

        return amPm[0] + ':' + t[1] + amPm[1]

    }

    return time;
}




export const uploadAvatar = (blob) => {

    const storageBucket = firebase.storage().ref();
    var storageRef = storageBucket.child('images/mountains.jpg');

    // console.log(`https://storage.googleapis.com/simpleschedule-9ae21.appspot.com/images/mountains.jpg`)



    storageRef.put(blob, { cacheControl: 'max-age=31536000' })


    // uploadedFile.uploadTaskSnapshot.ref.updateMetadata(newMetadata).then(function (metadata) {
    //     // console.log('metadata', metadata)
    //     // Updated metadata for 'images/forest.jpg' is returned in the Promise
    // }).catch(function (error) {
    //     console.log('error', error)
    //     // Uh-oh, an error occurred!
    // });

    // storageBucket.upload('p/t/image', {
    //     public: true,
    //     destination: storageBucket.file('imageName.png'),
    //     metadata: {
    //         cacheControl: 'public, max-age=14400'
    //     }

    // }, (err, file, apiResponse) => {
    // });
}

export const getUserImage = moize((projectKey, userKey, imgUrl) => {
    const imgBase = config.imgBase
    const storageBucket = config.storageBucket
    //  const url = `https://storage.googleapis.com/${storageBucket}/p/${projectKey}/t/${userKey}?${imgUrl}`
    //return url
    // console.log(`https://storage.googleapis.com/static.timeto.work/p/${projectKey}/t/${userKey}?${imgUrl}`)
    // console.log(`https://storage.googleapis.com/${storageBucket}/p/${projectKey}/t/${userKey}?${imgUrl}`)
    return `https://storage.googleapis.com/${storageBucket}/p/${projectKey}/t/${userKey}?${imgUrl}`
    // console.log(`https://storage.googleapis.com/${storageBucket}/p/${projectKey}/t/${userKey}?${imgUrl}`)
    // // console.log(`https://storage.googleapis.com/static.timeto.work/p/${projectKey}/t/${userKey}?${imgUrl}`)
    // // console.log(`https://us-central1-simpleschedule-9ae21.cloudfunctions.net/ImgCache?p=${projectKey}&i=${userKey}&r=${imgUrl}`)
    // //  console.log(`https://${imgBase}/ImgCache?p=${projectKey}&i=${userKey}&r=${imgUrl}`)
    // return `https://${imgBase}/ImgCache?p=${projectKey}&i=${userKey}&r=${imgUrl}`
}, { maxSize: 100 })
export const getDateKey = (date) => {

    if (date) {
        if (typeof date === 'string') { return getDateKey2(date) }
        else { return getDateKey2(date.format('YYYY-MM-DD')) }

    }
    else
        return getDateKey2(moment().format('YYYY-MM-DD'))
}

const getDateKey2 = moize((date) => {

    var d = moment(date);
    var d2 = d.clone().add(6, 'days');
    var r = d.format('YYYY-WW') + '_' + d2.format('YYYY-WW')
    return r
})

export const renderTimeLable = moize((lbl, startDate, stopDate, AmPm = false) => {


    let s = lbl || ''
    if (s.indexOf('@') > -1) {
        if (startDate) {
            const sDate = moment.utc(startDate).format(AmPm ? 'h:mma' : 'HH:mm')
            //// console.log('startDate', startDate)
            //// console.log('sDate', moment.utc(startDate).format('YYYYMMDD'))
            s = s.replace('@start', sDate)
        }
        else
            s = s.replace('@start', '')
        if (stopDate) {
            const eDate = moment.utc(stopDate).format(AmPm ? 'h:mma' : 'HH:mm')
            s = s.replace('@end', eDate)
        }
        else
            s = s.replace('@end', '')
    }

    // s = s.replace(/m/g, '')
    s = s.replace(/:00/g, '')
    if (s === '0 - 0' || s === '12a - 12a')
        s = ' '


    return s
})

export const getTimeFromMins = moize((mins) => {


    var dur = moment.duration(mins, 'minutes').format('HH:mm')
    if (dur.indexOf(':') === -1)
        dur = '0:' + dur

    return dur
})


export const getTimeFromMins2 = moize((mins) => {
    var dur = moment.duration(mins, 'minutes').format('H.mm')

    if (dur.indexOf('.') === -1)
        dur = '0.' + dur
    return dur
})




export const getLocalFromNow = (mDate) => {



    var r = mDate.clone().fromNow()

    if (r.indexOf('a few seconds ago') > -1)
        r = r.replace('a few seconds ago', window._getText('a few seconds ago'))
    else if (r.indexOf('seconds ago') > -1)
        r = r.replace('seconds ago', window._getText('seconds ago'))
    else if (r.indexOf('a minute ago') > -1)
        r = r.replace('a minute ago', window._getText('a minute ago'))
    else if (r.indexOf('minutes ago') > -1)
        r = r.replace('minutes ago', window._getText('minutes ago'))
    else if (r.indexOf('an hour ago') > -1)
        r = r.replace('an hour ago', window._getText('an hour ago'))
    else if (r.indexOf('hours ago') > -1)
        r = r.replace('hours ago', window._getText('hours ago'))
    else if (r.indexOf('a day ago') > -1)
        r = r.replace('a day ago', window._getText('a day ago'))
    else if (r.indexOf('days ago') > -1)
        r = r.replace('days ago', window._getText('days ago'))
    else if (r.indexOf('a month ago') > -1)
        r = r.replace('a month ago', window._getText('a month ago'))
    else if (r.indexOf('months ago') > -1)
        r = r.replace('months ago', window._getText('months ago'))
    else if (r.indexOf('year ago') > -1)
        r = r.replace('year ago', window._getText('year ago'))
    else if (r.indexOf('years ago') > -1)
        r = r.replace('years ago', window._getText('years ago'))




    return r




}


export const getLocalDate = (mDate, format) => {


    if (!window._moment) {
        var language = window.navigator.userLanguage || window.navigator.language;
        var m = moment()
        m.locale(language)
        window._moment = m
    }


    var r = mDate.clone().set({
        'year': mDate.get('year'),
        'month': mDate.get('month'),
        'day': mDate.get('day'),
        'minute': mDate.get('minute'),
        'second': mDate.get('second')
    }).format(format)

    if (r.indexOf('January') > -1)
        r = r.replace('January', window._getText('January'))
    else if (r.indexOf('February') > -1)
        r = r.replace('February', window._getText('February'))
    else if (r.indexOf('March') > -1)
        r = r.replace('March', window._getText('March'))
    else if (r.indexOf('April') > -1)
        r = r.replace('April', window._getText('April'))
    else if (r.indexOf('May') > -1)
        r = r.replace('May', window._getText('May'))
    else if (r.indexOf('June') > -1)
        r = r.replace('June', window._getText('June'))
    else if (r.indexOf('July') > -1)
        r = r.replace('July', window._getText('July'))
    else if (r.indexOf('August') > -1)
        r = r.replace('August', window._getText('August'))
    else if (r.indexOf('September') > -1)
        r = r.replace('September', window._getText('September'))
    else if (r.indexOf('October') > -1)
        r = r.replace('October', window._getText('October'))
    else if (r.indexOf('November') > -1)
        r = r.replace('November', window._getText('November'))
    else if (r.indexOf('December') > -1)
        r = r.replace('December', window._getText('December'))



    return r




}

export const getLocalDateStr = (mDate) => {


    if (!window._mFormat) {
        var language = window.navigator.userLanguage || window.navigator.language;
        var m = moment()
        m.locale(language)
        var formatL = m.localeData().longDateFormat('ll');
        // // console.log('m1_>>>>>>>>>>>', m.locale())
        // // console.log('formatL', formatL)
        // // console.log('m.locale', m.locale())
        window._moment = m
        window._mFormat = formatL.replace(/Y/g, ' ').replace(/^\W|\W$|\W\W/, ' ');
    }

    var format = window._mFormat



    var r = mDate.clone().format(format || 'MMM DD')

    if (r.indexOf('Jan') > -1)
        r = r.replace('Jan', window._getText('Jan').substr(0, 3))
    else if (r.indexOf('Feb') > -1)
        r = r.replace('Feb', window._getText('Feb').substr(0, 3))
    else if (r.indexOf('Mar') > -1)
        r = r.replace('Mar', window._getText('Mar').substr(0, 3))
    else if (r.indexOf('Apr') > -1)
        r = r.replace('Apr', window._getText('Apr').substr(0, 3))
    else if (r.indexOf('May') > -1)
        r = r.replace('May', window._getText('May').substr(0, 3))
    else if (r.indexOf('Jun') > -1)
        r = r.replace('Jun', window._getText('Jun').substr(0, 3))
    else if (r.indexOf('Jul') > -1)
        r = r.replace('Jul', window._getText('Jul').substr(0, 3))
    else if (r.indexOf('Aug') > -1)
        r = r.replace('Aug', window._getText('Aug').substr(0, 3))
    else if (r.indexOf('Sep') > -1)
        r = r.replace('Sep', window._getText('Sep').substr(0, 3))
    else if (r.indexOf('Oct') > -1)
        r = r.replace('Oct', window._getText('Oct').substr(0, 3))
    else if (r.indexOf('Nov') > -1)
        r = r.replace('Nov', window._getText('Nov').substr(0, 3))
    else if (r.indexOf('Dec') > -1)
        r = r.replace('Dec', window._getText('Dec').substr(0, 3))



    return r
}

export const getLocalDateStr2 = (mDate) => {


    if (!window._mFormat2) {
        var language = window.navigator.userLanguage || window.navigator.language;
        // console.log('languag', language)
        var m = moment()
        m.locale(language)
        var formatL = m.localeData().longDateFormat('llll');
        // console.log('m1_>>>>>>>>>>>', m.locale())
        // console.log('formatL', formatL)
        // console.log('m.locale', m.locale())
        window._moment = m
        window._mFormat2 = formatL.replace(/Y/g, ' ').replace(/^\W|\W$|\W\W/, ' ').replace(/HH:mm/, '').replace(/h:mm A/, '').replace(/,/, '').replace(/MMM/, '');

        // console.log('window._mFormat2 >>>>> ', window._mFormat2)

    }
    var format = window._mFormat2

    return mDate.format(format || 'MMM DD')

}
export const getTimeFromMins1 = (mins) => {
    var dur = moment.duration(mins, 'minutes').format('HH:mm')

    if (dur.indexOf(':') === -1)
        dur = '0:' + dur
    return dur
}

export const getCurrency = (value) => {
    const lang = window._getTextLanguage
    const currency = window._currency || 'USD'
    // TODO reuse the nFormater object
    const nFormater = new Intl.NumberFormat(lang, { maximumFractionDigits: 0, style: 'currency', currency })
    return nFormater.format(Number(value) || 0)

}

// export const createNewEvent = (user, photoURL, event) => {
//     event.date = moment.utc(event.date).toDate();
//     return {
//         ...event,
//         hostUid: user.uid,
//         hostedBy: user.displayName,
//         hostPhotoURL: photoURL || '/assets/user.png',
//         created: Date.now(),
//         attendees: {
//             [user.uid]: {
//                 going: true,
//                 joinDate: Date.now(),
//                 photoURL: photoURL || '/assets/user.png',
//                 displayName: user.displayName,
//                 host: true
//             }
//         }
//     }
// }

// export const createDataTree = dataset => {
//     let hashTable = Object.create(null);
//     dataset.forEach(a => hashTable[a.id] = { ...a, childNodes: [] });
//     let dataTree = [];
//     dataset.forEach(a => {
//         if (a.parentId) hashTable[a.parentId].childNodes.push(hashTable[a.id]);
//         else dataTree.push(hashTable[a.id])
//     });
//     return dataTree
// };
