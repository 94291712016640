import { combineReducers } from 'redux';
import { reducer as FormReducer } from 'redux-form';
import { reducer as toastrReducer } from 'react-redux-toastr';
import { firebaseReducer } from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore'
//import userReducer from './userReducer'
import modalReducer from './modalReduser'
import currentScheduelDataReducer from './currentScheduelDataReducer'
import viewstateReducer from './viewstateReducer'
import mobileHeaderReducer from './mobileHeaderReducer'
//import authUserReducer from './authUserReducer'




const rootReducer = combineReducers({
    firebase: firebaseReducer,
    firestore: firestoreReducer,
    form: FormReducer,
    toastr: toastrReducer,
    mobileHeader: mobileHeaderReducer,
    viewstate: viewstateReducer,
    //  user: userReducer,
    modal: modalReducer,
    currentScheduelData: currentScheduelDataReducer
    // authUser: authUserReducer
})

export default rootReducer;