import React, { Component } from "react";
import {
  Icon,
  Image,
  Button,
  Input,
} from "../../common/component/Button";
import { connect } from "react-redux";
import { openModal, closeModal } from "../../../core/redux/action/modalAction";
import {
  setUser,
  deleteUser,
  uploadProfileImage,
} from "../../../core/redux/action/userAction";
import { Field, reduxForm } from "redux-form";
import InputToggle from "../../common/form/InputToggle";
import noUserImage from "../../../img/noimg.png";
import AvatarEditor from "react-avatar-editor";
import shortid from "shortid";
import { FireHelper } from "../../../core/util/FireHelper";
import { FactoryUser } from "../../../core/util/FactoryUser";
import ModalFrame from "./ModalFrame";
import { getUserImage } from "../../../core/util/helpers";
import { toastr } from "react-redux-toastr";
import Tab from "../../common/component/Tab";
import TextMessagesMessage from "../messages/TextMessagesMessage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser as icoUser,
  faCommentsAlt as icoChat,
  faMoneyCheckEdit as icoNote,
  faLink as icoLink,
} from "@fortawesome/pro-duotone-svg-icons";
const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value,
});
const validate = (values) => {
  const errors = { name: false };
  if (!values.name) {
    errors.name = true;
  } else if (values.name.length <= 1) {
    errors.name = true;
  }

  return errors;
};

const INIT_STATE = {
  savingImage: false,
  sliderValue: 1.0,
  image: undefined,
  loading: false,
  rotate: 0,
  error: undefined,
  doDelete: false,
  isSendig: false,
  tabIndex: 0,
};

class UserModal extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INIT_STATE };
  }
  componentDidUpdate = (prevProps, prevState, snapshot) => {
    if (this.props.userObj.key !== prevProps.userObj.key) {
      this.setState({ ...INIT_STATE });
    }
  };

  saveData = async (values) => {
    //isActive
    var oldData = { ...this.props.userObj };
    var userToSave = { ...oldData, ...values };

    if (!values.name) userToSave.name = "#unknown";

    if (!values.email) userToSave.email = "";

    if (!values.phone) userToSave.phone = "";

    userToSave.role = Number(values.role);

    userToSave.hourSalary = parseFloat(values.hourSalary) || 0;

    var validatedUserResponse = await FactoryUser.validateUser(
      userToSave,
      oldData,
      this.props.userList,
      window._calling_code
    );

    var savedUserResponse = { success: false, error: null };
    if (validatedUserResponse && validatedUserResponse.success) {
      userToSave = validatedUserResponse.userObj;

      //console.log(4, validatedUserResponse.userObj)
      savedUserResponse = await this.props.onSetUser({ ...userToSave });
      //console.log('5', savedUserResponse)
      this.props.reset();
      toastr.success(window._getText("Saved"));
    } else {
      //console.log(41)
      if (validatedUserResponse) {
        toastr.error("Error", validatedUserResponse.error);
        this.setState({ error: validatedUserResponse.error });
        //console.log('Error', validatedUserResponse.error)
        savedUserResponse.error = validatedUserResponse;
      }
      //else
      //console.log('response is null')
    }
    //console.log(99)
    return savedUserResponse;
  };
  onSubmit = async (values) => {
    if (
      this.props.currentUser &&
      this.props.currentUser.email === "demo@timeto.work"
    ) {
      toastr.success("Demo account", `No saves are made`);
      console.log("Demo account = exit");
      this.onCloseModal();
      return;
    }

    var saveObj = { ...values };
    if (saveObj.name) {
      this.setState({ loading: true });
      var r = { success: true, msg: "" };

      if (this.state.image) {
        if (this.editor) {
          try {
            //console.log('save image', saveObj)
            this.editor.getImageScaledToCanvas().toBlob(async (blob) => {
              if (!saveObj.key) saveObj.key = shortid.generate();

              //uploadAvatar(blob)
              //console.log('save image no', saveObj)
              await this.props.uploadProfileImage(
                blob,
                saveObj.key,
                this.props.project.key
              );
              saveObj.image = shortid.generate();
              r = await this.saveData(saveObj);
            }, "image/jpg");
          } catch (error) {
            this.setState({ savingImage: false });
          }
        }
      } else {
        r = await this.saveData(saveObj);
        //console.log('r==', r)
      }

      if (r.success === true) {
        this.setState({ ...INIT_STATE });
        //  if (this.props.userObj.newUser)
        this.props.closeModal("user");
      } else {
        this.setState({ loading: false, error: r.error, msg: r.msg });
      }
    } else {
      // this.setState({ noName: true })
      //TODO validate input!
    }
  };

  onDeleteUser = () => {
    if (
      this.props.currentUser &&
      this.props.currentUser.email === "demo@timeto.work"
    ) {
      toastr.success("Demo account", `No saves are made`);
      console.log("Demo account = exit");
      this.onCloseModal();
      return;
    }

    this.props.deleteUser(this.props.userObj);
    this.props.closeModal("user");
  };
  onCloseModal = () => {
    this.props.closeModal("user");
  };

  renderSelect = (role) => {
    if (role > 199) return <span>Administrator</span>;

    return (
      <select {...role.input}>
        <option value={100}>Staff member</option>
        <option value={150}>Schedule manager</option>
      </select>
    );
  };

  renderInput = (name) => {
    return (
      <Input
        style={{
          width: "270px",
          paddingTop: "9px",
        }}
        className="fieldInput tbTrans"
        autoComplete="new-password"
        type="text"
        {...name.input}
        placeholder={window._getText("users.dname")}
        error={name.meta.touched && name.meta.error}
      />
    );
  };

  handleFileUpload = (e) => {
    // const file = files[0]

    if (e.target.files) {
      var file = e.target.files[0];
      this.setState({ image: file });
      //this.props.userObj.image = file;
    }
  };

  setEditorRef = (editor) => (this.editor = editor);
  handleChange = (event) => {
    this.setState({ sliderValue: Number(event.target.value) });
  };

  onRotateImage = (e) => {
    e.preventDefault();
    this.setState({ rotate: this.state.rotate + 90 });
  };

  removeMappedUser = async (e) => {
    e.preventDefault();

    if (window.confirm(window._getText("comefirmUnlinkUser"))) {
      let { userObj, projectKey } = this.props;
      this.setState({ loading: true });

      await FireHelper.removeInAppUserMapping({ ...userObj, projectKey });
      this.setState({ loading: false });
    } else {
    }
  };
  // remappUser = async (e) => {
  //   e.preventDefault()
  //   let { userObj, projectKey } = this.props;
  //   this.setState({ loading: true })
  //   await FireHelper.updateInAppUser({ remappuser: true }, { inSysUserKey: userObj.key, projectKey })
  //   this.setState({ loading: false })
  // }
  getRole = (role) => {
    if (role === 200) return <Icon name="chess king"></Icon>;

    if (role === 150) return <Icon name="chess rook"></Icon>;

    if (role === 50) return <Icon name="chess pawn"></Icon>;

    return <Icon name="chess pawn"></Icon>;
  };
  confirmDelete = () => {
    this.setState({ doDelete: true });
  };
  onCancle = () => {
    this.setState({ image: null });
  };
  sendMail = () => {
    if (
      this.props.currentUser &&
      this.props.currentUser.email === "demo@timeto.work"
    ) {
      toastr.success("Demo account", `No saves are made`);
      console.log("Demo account = exit");
      this.onCloseModal();
      return;
    }

    const { userObj } = this.props;
    if (this.props.currentUser && userObj.email) {
      this.setState({ isSendig: true });
      FireHelper.setInviteMail(
        this.props.project.key,
        { email: userObj.email },
        this.props.project.publicKey,
        this.props.project.name
      );
      setTimeout(() => {
        this.setState({ isSendig: false });
      }, 2000);
    }
  };
  sendSms = () => {
    const { userObj } = this.props;
    if (this.props.currentUser && userObj.phone) {
      this.setState({ isSendig: true });
      FireHelper.setInviteSms(
        this.props.project.key,
        { phone: userObj.phone, name: this.props.project.name },
        this.props.project.publicKey,
        this.props.project.name
      );
      setTimeout(() => {
        this.setState({ isSendig: false });
      }, 5000);
    }
  };

  onPhoneChange = () => {
    // alert(1)
    //const { userObj } = this.props
    ////console.log('this.props.formState', this.props.formState)
    // setTimeout(() => {
    //this.props.change('phone', new AsYouType().input(this.props.formState.values.phone))
    // }, 1000);
    //this.props.change('phone', new AsYouType().input('+12133734'));
  };

  userCard = () => {
    let { projectKey, userObj } = this.props;
    let {
      error,
      image,
      rotate,
      sliderValue,
      value,
      savingImage,
      doDelete,
    } = this.state;

    return (
      <React.Fragment>
        {!doDelete && (
          <div>
            {userObj.role !== 200 && (
              <div style={{ position: "absolute", right: 10, top: 10 }}>
                <b>{window._getText("active")}: </b>
                <Field name="isActive" component={InputToggle} />
              </div>
            )}
          </div>
        )}
        <div style={{ minHeight: "300px" }}>
          {!doDelete && (
            <React.Fragment>
              <input type="hidden" value="something" />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  paddingLeft: "20px",
                  paddingRight: "20px",
                }}
              >
                <div style={{ marginBottom: 10 }}>
                  {/* {!image &&
              <Image style={{ maxWidth: '100px', maxHeight: '100px', marginLeft: 'auto', marginRight: 'auto' }}
                src={userObj.image ?
                  getUserImage(projectKey, userObj.key, userObj.image)
                  : noUserImage}
                circular
                onError={(e) => { e.target.onerror = null; e.target.src = noUserImage }}
              />

            } */}

                  {image && (
                    <React.Fragment>
                      <AvatarEditor
                        style={{
                          display: "block",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                        ref={this.setEditorRef}
                        image={URL.createObjectURL(image)}
                        width={80}
                        height={80}
                        borderRadius={50}
                        border={12}
                        scale={Number(sliderValue || 1)}
                        rotate={rotate}
                      />

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          paddingTop: "4px",
                        }}
                      >
                        <Button
                          style={{ marginRight: "5px", maxHeight: "30px" }}
                          compact
                          onClick={this.onCancle}
                          icon="x"
                          disabled={savingImage}
                        />
                        <input
                          id="typeinp"
                          type="range"
                          min="0.5"
                          max="5"
                          defaultValue="1"
                          value={value}
                          onChange={this.handleChange}
                          step="0.01"
                          style={{ width: "100%", flexGrow: "1" }}
                        />
                        <Button
                          style={{ marginLeft: "5px", maxHeight: "30px" }}
                          compact
                          onClick={this.onRotateImage}
                          icon="redo"
                          disabled={savingImage}
                        />
                      </div>
                    </React.Fragment>
                  )}

                  <div style={{}}>
                    {!savingImage && !image && (
                      <React.Fragment>
                        <label htmlFor="upload" style={{ width: "100%" }}>
                          <Image
                            style={{
                              maxWidth: "100px",
                              maxHeight: "100px",
                              marginLeft: "auto",
                              marginRight: "auto",
                            }}
                            src={
                              userObj.image
                                ? getUserImage(
                                  projectKey,
                                  userObj.key,
                                  userObj.image
                                )
                                : noUserImage
                            }
                            circular
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = noUserImage;
                            }}
                          />
                        </label>
                        <input
                          type="file"
                          ref={this.fileRef}
                          id="upload"
                          accept="image/*"
                          onChange={(e) => {
                            this.handleFileUpload(e);
                          }}
                          style={{ display: "none", border: "none" }}
                        />
                      </React.Fragment>
                    )}
                  </div>
                </div>
                <div>
                  {this.props.currentUser.role === 200 && userObj.role < 200 && (
                    <div
                      style={{
                        display: "flex",
                        borderBottom: "solid 1px var(--color-border)",
                        minHeight: "35px",
                      }}
                    >
                      <div
                        style={{
                          width: "30%",
                          paddingTop: "11px",
                          textAlign: "right",
                          fontWeight: "bold",
                        }}
                      >
                        {window._getText("Systems role")}:{" "}
                      </div>
                      <div style={{ paddingTop: "11px" }}>
                        <Field
                          name="role"
                          type="number"
                          component={this.renderSelect}
                          required
                          parse={(val) => Number(val)}
                        />
                      </div>
                    </div>
                  )}
                  <div
                    style={{
                      display: "flex",
                      borderBottom: "solid 1px var(--color-border)",
                      minHeight: "35px",
                    }}
                  >
                    <div
                      style={{
                        width: "30%",
                        paddingTop: "11px",
                        textAlign: "right",
                        fontWeight: "bold",
                      }}
                    >
                      {window._getText("users.dname")}:
                    </div>
                    <div>
                      <Field
                        name="name"
                        className="fieldInput tbTrans"
                        component={this.renderInput}
                        required
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      borderBottom: "solid 1px var(--color-border)",
                      minHeight: "35px",
                    }}
                  >
                    <div
                      style={{
                        width: "30%",
                        paddingTop: "11px",
                        textAlign: "right",
                        fontWeight: "bold",
                      }}
                    >
                      {userObj.sysUserKey && userObj.role !== 200 && (
                        <span
                          onClick={this.removeMappedUser}
                          tooltip={window._getText("user.unlockEmail")}
                        >
                          <Icon name="lock"></Icon>
                        </span>
                      )}
                      {!userObj.sysUserKey && (
                        <span style={{ fontWeight: "normal" }}>*</span>
                      )}
                      {window._getText("email")}:
                    </div>
                    <div>
                      <Field
                        name="email"
                        style={{
                          width: "270px",
                          paddingTop: "9px",
                        }}
                        className="fieldInput tbTrans"
                        error={
                          error && (error.msg === "dup" || error.msg === "dup2")
                        }
                        readOnly={userObj.sysUserKey ? true : false}
                        autoComplete="new-password"
                        component={Input}
                        type="text"
                        placeholder={window._getText("email")}
                      />
                      <div>
                        {error && error.msg === "dup" && (
                          <span style={{ color: "darkred" }}>
                            ({window._getText("users.uniqueemail")})
                          </span>
                        )}
                        {error && error.msg === "dup2" && (
                          <span style={{ color: "darkred" }}>
                            ({window._getText("users.uniqueemail2")})
                          </span>
                        )}
                        {error && error.msg === "notemail" && (
                          <span style={{ color: "darkred" }}>
                            ({window._getText("users.notemail")})
                          </span>
                        )}
                      </div>
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      borderBottom: "solid 1px var(--color-border)",
                      minHeight: "35px",
                    }}
                  >
                    <div
                      style={{
                        width: "30%",
                        paddingTop: "11px",
                        textAlign: "right",
                        fontWeight: "bold",
                      }}
                    >
                      {userObj.sysUserKey && userObj.role !== 200 && (
                        <span
                          onClick={this.removeMappedUser}
                          tooltip={window._getText("user.unlockEmail")}
                        >
                          <Icon name="lock"></Icon>
                        </span>
                      )}
                      {/* {!userObj.sysUserKey && (
                        <span style={{ fontWeight: "normal" }}>*</span>
                      )} */}
                      {window._getText("phone")} :
                    </div>
                    <div>
                      <Field
                        name="phone"
                        style={{
                          width: "270px",
                          paddingTop: "9px",
                        }}
                        className="fieldInput tbTrans"
                        autoComplete="new-password"
                        readOnly={userObj.sysUserKey ? true : false}
                        component={Input}
                        type="text"
                        placeholder={
                          window._calling_code
                            ? `+${window._calling_code}`
                            : window._getText("validphone")
                        }
                        error={
                          error &&
                          (error.msg === "dupPhone" ||
                            error.msg === "dupPhone2" ||
                            error.msg === "invalidPhone")
                        }
                        onKeyUp={this.onPhoneChange}
                      />
                      <div>
                        {error && error.msg === "dupPhone" && (
                          <span style={{ color: "darkred" }}>
                            {window._getText("users.uniquephone")}
                          </span>
                        )}
                        {error && error.msg === "dupPhone2" && (
                          <span style={{ color: "darkred" }}>
                            {window._getText("users.uniquephone")}
                          </span>
                        )}
                        {error && error.msg === "invalidPhone" && (
                          <span style={{ color: "darkred" }}>
                            {window._getText("users.invalidphone")}
                          </span>
                        )}
                        {error && error.msg === "invalidPhone2" && (
                          <span style={{ color: "darkred" }}>
                            {window._getText("users.invalidphone2")}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>

                  <div style={{ display: 'flex', borderBottom: 'solid 1px var(--color-border)', minHeight: '35px' }}>
                    <div style={{ width: '30%', paddingTop: '11px', textAlign: 'right', fontWeight: 'bold' }}>
                      {window._getText('HourSalary')}  :
                    </div>
                    <div >
                      <Field name="hourSalary"
                        style={{
                          width: '270px',
                          paddingTop: '9px',
                        }}
                        className='fieldInput tbTrans'
                        autoComplete="new-password"
                        component={Input}
                        placeholder={0}
                      />
                    </div>
                  </div>
                  {/* <div style={{ display: 'flex', borderBottom: 'solid 1px var(--color-border)', minHeight: '35px' }}>
                    <div style={{ width: '30%', paddingTop: '11px', textAlign: 'right', fontWeight: 'bold' }}>
                      {window._getText('Tag / Group')}  :
                  </div>
                    <div >
                      <Field name="tag"
                        style={{
                          width: '270px',
                          paddingTop: '9px',
                        }}
                        className='fieldInput tbTrans'
                        autoComplete="new-password" readOnly={userObj.sysUserKey ? true : false} component={Input}
                        type="text" placeholder={0}
                      />
                    </div>
                  </div> */}

                  {!userObj.sysUserKey ? (
                    <div
                      style={{
                        textAlign: "center",
                        fontSize: "12px",
                        color: "#888",
                      }}
                    >
                      {/* * = {window._getText("users.infoText")} */}
                    </div>
                  ) : (
                    <div
                      style={{
                        textAlign: "center",
                        fontSize: "12px",
                        color: "#888",
                      }}
                    >
                      {window._getText("users.infoText1")}
                    </div>
                  )}
                </div>
                {userObj.sysUserKey === undefined &&
                  !this.props.userObj.newUser && (
                    <div
                      style={{
                        borderWidth: "1px",
                        borderColor: "red",
                        marginTop: "30px",
                        display: "flex",
                      }}
                    >
                      {/* {isLoading={this.state.isSendig}} */}
                      {this.props.userObj.phone && (
                        <Button
                          disabled={this.state.isSendig}
                          icon="phone"
                          onClick={this.sendSms}
                          content="send sms invite"
                        />
                      )}
                      <div style={{ flex: 1 }}></div>
                      {/* {this.props.userObj.email && (
                        <Button
                          disabled={this.state.isSendig}
                          icon="mail"
                          onClick={this.sendMail}
                          content="send mail invite"
                        />
                      )} */}
                    </div>
                  )}
              </div>
            </React.Fragment>
          )}
          {doDelete === true && (
            <span>
              <p>{window._getText("users.delete")}</p>
            </span>
          )}
        </div>
      </React.Fragment>
    );
  };

  sendMsg = () => {
    if (
      this.props.currentUser &&
      this.props.currentUser.email === "demo@timeto.work"
    ) {
      toastr.success("Demo account", `No saves are made`);
      console.log("Demo account = exit");
      return;
    }

    FireHelper.createChat(
      this.props.userObj.name,
      this.props.userObj.name,
      this.props.userObj.sysUserKey,
      [this.props.userObj.sysUserKey, this.props.currentUser.key],
      this.props.currentUser
    );

    const val = this.state.tbValue;
    if (val.length > 0) {
      this.setState({ tbValue: "", isLoading: true, onTimer: true });
      FireHelper.setChatMesage(
        this.props.userObj.sysUserKey,
        val,
        this.props.currentUser
      );
      setTimeout(() => {
        clearTimeout(this.loadingTimer);
        this.setState({ isLoading: false, onTimer: false });
      }, 1005);
    }
  };

  onKeyPress = (event) => {
    if (event.key === "Enter") {
      this.sendMsg();
    }
  };

  tabOnChange = (index) => this.setState({ tabIndex: index });

  render() {
    if (this.props.isOpen === false) return "";

    let { handleSubmit, projectKey } = this.props;
    let {
      error,
      image,
      rotate,
      sliderValue,
      value,
      savingImage,
      doDelete,
    } = this.state;
    var userObj = this.props.userObj;

    //if (!userObj.email) userObj.email = "";
    return (
      <React.Fragment>
        <ModalFrame
          name="userModal"
          className="modalAniSlideUp"
          open={this.props.isOpen}
          // onClose={this.handleClose}
          dimmer="inverted"
          size="tiny"
          onClickClose={this.onCloseModal}
        >
          <ModalFrame.Header>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div style={{ flexGrow: "1" }}>
                {doDelete === true && <span>Delete user {userObj.name}</span>}
              </div>
            </div>
          </ModalFrame.Header>
          <ModalFrame.Content>
            <div style={{ minHeight: 400 }}>
              <Tab style={{ padding: 10 }} onChange={this.tabOnChange}>
                <Tab.View
                  icon={icoUser}
                  name="User"
                  style={{
                    backgroundColor: "transparent",
                    borderRadius: 25,
                    margin: 5,
                    padding: 3,
                    paddingLeft: 8,
                    paddingRight: 8,
                  }}
                  selectedStyle={{
                    fontWeight: "bold",
                    backgroundColor: "silver",
                    borderRadius: 25,
                    margin: 5,
                    padding: 3,
                    paddingLeft: 8,
                    paddingRight: 8,
                  }}
                >
                  {this.userCard()}
                </Tab.View>
                <Tab.View
                  icon={icoNote}
                  name="Notes"
                  style={{
                    backgroundColor: "transparent",
                    borderRadius: 25,
                    margin: 5,
                    padding: 3,
                    paddingLeft: 8,
                    paddingRight: 8,
                  }}
                  selectedStyle={{
                    fontWeight: "bold",
                    backgroundColor: "silver",
                    borderRadius: 25,
                    margin: 5,
                    padding: 3,
                    paddingLeft: 8,
                    paddingRight: 8,
                  }}
                >
                  <Field
                    name="note"
                    style={{
                      width: "100%",
                      height: 330,
                      paddingTop: "9px",
                      border: "solid 1px var(--color-border)",
                      backgroundColor: "rgba(255,255,255,0.8)",
                      borderRadius: "4px",
                    }}
                    maxLength={2000}
                    className="fieldInput tbTrans"
                    autoComplete="new-password"
                    component="textarea"
                    type="text"
                    placeholder={window._getText("PrivateNotes")}
                  />
                </Tab.View>
                <Tab.View
                  icon={icoChat}
                  name="Chat"
                  style={{
                    backgroundColor: "transparent",
                    borderRadius: 25,
                    margin: 5,
                    padding: 3,
                    paddingLeft: 8,
                    paddingRight: 8,
                  }}
                  selectedStyle={{
                    fontWeight: "bold",
                    backgroundColor: "silver",
                    borderRadius: 25,
                    margin: 5,
                    padding: 3,
                    paddingLeft: 8,
                    paddingRight: 8,
                  }}
                >
                  {userObj.sysUserKey ? (
                    <div
                      style={{
                        width: "100%",
                        overflowX: "hidden",
                        overflowY: "auto",
                        maxHeight: 340,
                        flexGrow: "1",
                        display: "flex",
                        flexDirection: "column-reverse",
                      }}
                    >
                      <div
                        style={{
                          flexGrow: "1",
                          flexDirection: "column",
                          display: "flex",
                          alignContent: "flex-end",
                        }}
                      >
                        <TextMessagesMessage
                          noHeader={true}
                          onSelectDefault={userObj.sysUserKey}
                          chanelKey={userObj.sysUserKey}
                        />
                      </div>
                    </div>
                  ) : (
                    <div
                      style={{
                        textAlign: "center",
                        padding: 20,
                        marginTop: 10,
                        fontSize: "bold",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={icoLink}
                        style={{ fontSize: "50px" }}
                      ></FontAwesomeIcon>
                      <br />
                      <br />
                      User need to be linked to use the chat function.
                    </div>
                  )}
                </Tab.View>
              </Tab>
            </div>
          </ModalFrame.Content>
          <ModalFrame.Actions>
            <div style={{ display: "flex", maxHeight: "40px" }}>
              {this.state.tabIndex === 2 && userObj.sysUserKey ? (
                <React.Fragment>
                  <input
                    className="form-control "
                    style={{
                      resize: "none",
                      flexGrow: 1,
                      marginRight: "8px",
                      height: "10px",
                    }}
                    value={this.state.tbValue}
                    maxLength={500}
                    onChange={(event) => {
                      this.setState(byPropKey("tbValue", event.target.value));
                    }}
                    onKeyPress={this.onKeyPress}
                  />

                  <button
                    style={{ marginRight: "8px", marginLeft: "8px" }}
                    onClick={this.sendMsg}
                    className="btn btn-default"
                    disabled={this.state.isLoading}
                  >
                    <span className="ico ico-comment"></span>
                  </button>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {userObj.newUser !== true &&
                    userObj.role !== 200 &&
                    !doDelete &&
                    this.props.currentUser.role === 200 && (
                      <Button
                        disabled={this.state.loading}
                        color="red"
                        icon="trash alternate outline"
                        onClick={this.confirmDelete}
                      />
                    )}
                  {userObj.newUser !== true &&
                    userObj.role !== 200 &&
                    doDelete === true && (
                      <React.Fragment>
                        {/* <Button onClick={this.onCloseModal} content='cancel' compact style={{ marginRight: '10px' }}></Button> */}
                        <Button
                          style={{ marginLeft: "auto" }}
                          content={window._getText("users.deleteBtn2")}
                          onClick={this.onDeleteUser}
                        />
                      </React.Fragment>
                    )}

                  {!doDelete && (
                    <React.Fragment>
                      {/* <Button onClick={this.onCloseModal} content='cancel' compact style={{ marginRight: '10px' }}></Button> */}
                      <Button
                        style={{ marginLeft: "auto" }}
                        compact
                        type="submit"
                        onClick={handleSubmit(this.onSubmit)}
                        positive
                        icon="checkmark"
                        disabled={this.state.loading}
                        loading={this.state.loading}
                      />
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}
            </div>
          </ModalFrame.Actions>
        </ModalFrame>
      </React.Fragment>
    );
  }
}

const mapActions = {
  onOpenModal: openModal,
  closeModal: closeModal,
  //updateUser: updateUser,
  uploadProfileImage,
  deleteUser,
  onSetUser: setUser,
  //addUser
};

const mapStateToProps = (state) => {
  let userObj;
  var userList = {};
  const data = state.modal.modals["user"].data;

  if (state.firestore.data && state.firestore.data.users)
    userList = state.firestore.data.users.userlist;

  if (data && userList)
    userObj = { ...userList[state.modal.modals["user"].data] }; //get user

  if (!userObj) {
    userObj = FactoryUser.newUser();

    if (data) userObj.key = data;
  }

  if (!userObj.role) {
    if (userObj.sysUserKey) userObj.role = 100;
    // mapped usr
    else userObj.role = 50; // if the user dont have a role its a 50
  }

  return {
    userList: userList,
    formState: state.form.userForm,
    currentUser: state.firebase.profile,
    projectKey: state.firebase.profile.projectKey,
    project: state.firestore.data.project,
    isOpen: state.modal.modals["user"].open,
    enableReinitialize: true,
    initialValues: userObj,
    userObj,
  };
};
export default connect(
  mapStateToProps,
  mapActions
)(reduxForm({ form: "userForm", touchOnChange: true, validate })(UserModal));
