import React, { Component } from "react";


// const byPropKey = (propertyName, value) => () => ({
//   [propertyName]: value,
// });

class Help extends Component {

  state = { selectedHelpView: 100 }
  setHelpView = (key) => () => {
    this.setState({ selectedHelpView: key })
  }

  render() {
    const currentHelpFiles = [
      { key: 100, name: 'Welcome' },
      { key: 200, name: 'Quik start' },
      { key: 300, name: 'Schedule' },
      { key: 400, name: 'Staff' },
      { key: 500, name: 'Shift' },
      { key: 600, name: 'Requests' },
      { key: 700, name: 'Reports' },
      { key: 800, name: 'AM/PM or 24h' },
      { key: 900, name: 'Settings' },
      { key: 1000, name: 'Company key' },
      { key: 1100, name: 'Download apps' },
      { key: 1200, name: 'Billing' },
    ]


    const url = true ? `https://www.timeto.work/help/${this.state.selectedHelpView}.html?cache=${this.state.selectedHelpView}&lang=1&cache=1` : `http://127.0.0.1:5500/help/${this.state.selectedHelpView}.html?cache=${this.state.selectedHelpView}&lang=1&cache=1` + Math.random()


    return (

      <React.Fragment>
        <div style={{ flexDirection: 'row', display: 'flex', height: '100%', width: '100%' }}>
          <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', display: 'flex', flexGrow: '1', alignItems: 'stretch' }}>

            <div style={{ maxWidth: '250px', minWidth: '250px', background: 'rgba(204,204,204,0.1)', borderRight: 'solid 1px var(--color-border)' }}>
              {/* {(this.props.currentUser.role > 100) && <Button compact color='blue' icon='comment outline' content={window._getText('new')} onClick={this.openUserDialog}></Button>} */}
              {/* <div onClick={this.setChanel('All')} key='All' style={{ cursor: 'pointer', fontWeight: this.state.selectedChanel === 'All' ? 'bold' : 'normal' }}>All</div> */}



              <div className='dragWindow2' style={{ paddingTop: '12px', paddingBottom: '12px', paddingLeft: '14px', paddingRight: '5px', borderBottom: 'solid 1px var(--color-border)', color: '#000', display: 'flex', fontSize: '12px' }}>

                <input
                  className="form-control"
                  icon="search"
                  placeholder={window._getText('search')}
                  onChange={(e) => this.setState({ chatSearch: e.target.value })}
                  style={{ height: '10px', marginRight: '8px' }}

                />



              </div>


              <div style={{ overflowY: 'auto', overflowX: 'hidden', height: 'calc(100% - 90px)', border: 'solid 0px green' }}>
                {
                  currentHelpFiles.map(helpView => {
                    return (
                      <div onClick={this.setHelpView(helpView.key)} key={helpView.key} style={{ borderBottom: 'dotted 1px var(--color-border)', cursor: 'pointer', fontWeight: helpView.message === 1 ? 'bold' : 'normal' }}>

                        <div style={{
                          position: 'relative', padding: '16px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', fontSize: '12px',
                          borderLeft: this.state.selectedHelpView === helpView.key ? 'solid 3px rgb(251, 189, 8)' : 'solid 3px transparent',
                          backgroundColor: this.state.selectedHelpView === helpView.key ? 'rgba(204,204,204,0.3)' : ''
                        }}>
                          <i aria-hidden="true" className="question icon" style={{ fontSize: '20px' }}></i>




                          {helpView.name}<br></br>
                          {/* <span style={{ color: 'silver', fontSize: '11px' }}>{12} participants</span>

                            <span style={{ color: 'silver', position: 'absolute', right: '10px', top: '0px', fontSize: '9px' }}>23 feb 20:33</span> */}
                        </div>

                      </div>
                    )
                  })
                }

              </div>
              <div style={{ flexGrow: '1' }}></div>
              <div style={{ height: '40px', padding: '6px', borderTop: 'solid 1px var(--color-border)', background: 'rgba(204,204,204,0.1)' }}>
                {/* {(this.props.currentUser.role > 100) &&
                    <button tooltip_t={'Add a new chat'} style={{ marginRight: '8px', marginLeft: '8px' }} className="btn btn-default" onClick={this.openUserDialog}>
                      <span className="ico ico-plus"></span>
                    </button>
                  } */}

              </div>
            </div>


            <div style={{ width: 'calc(100% - 250px)', flexGrow: '1', display: 'flex', flexDirection: 'column-reverse' }}>




              <div style={{}}>
                <div className='dragWindow2' style={{ borderBottom: 'solid 1px var(--color-border)', paddingLeft: '0px', height: '50px', overflow: 'hidden', display: 'flex', flexDirection: 'column' }} >
                  <div style={{ border: 'solid 0px blue', display: 'flex', flexDirection: 'row', paddingLeft: '10px', paddingTop: '8px', paddingRight: '10px', }}>
                    <h2>Help</h2>
                  </div>

                </div>
                <div style={{ overflow: 'none', height: 'calc(100vh - 50px)', position: 'relative', }}>
                  <iframe src={url + '&-'} title='help' style={{ position: 'absolute', top: '0px', left: '0px', bottom: '0px', right: '0px', height: '100%', width: '100%', backgroundColor: 'var(--color-bg1)', zIndex: 2 }}>


                  </iframe>
                  <div style={{ position: 'absolute', top: '20px', left: '20px', zIndex: 1 }}>

                    <i aria-hidden="true" className="download icon" style={{ fontSize: '20px' }}></i>

                    Downloading help file ...<br />
                    Please make sure you have connection to the internet
                  </div>
                </div>
              </div>

            </div>
            <div style={{ clear: 'both' }}></div>
          </div>
        </div>

      </React.Fragment >

    );
  }
}

export default Help;
