import React, { PureComponent } from "react";
import { Button, Icon } from "../../../common/component/Button";
import { connect } from 'react-redux'
import moment from 'moment';
import PropTypes from 'prop-types'


class Warning extends PureComponent {
  static contextTypes = { router: PropTypes.object }
  state = { isClosed: false }

  onClose = () => {
    this.setState({ isClosed: true })
  }

  onClickBilling = () => {
    window.dispatchEvent(new CustomEvent('preferences', { detail: { path: 'billing' } }));

  }

  render() {
    var { project, isLandscape } = this.props
    if (!project || !project.disableDate)
      return ('')

    var dayDiff = 0
    const diablseAccountAfterDays = 7
    if (project && project.disableDate) {
      var disableDate = moment(project.disableDate).utc().add(diablseAccountAfterDays, 'days')
      var now = moment().utc()
      dayDiff = disableDate.diff(now, 'days')
      // console.log('-----------')
      // console.log('disableDate', disableDate.format('YYYY-MM-DD HH:mm'))
      console.log('disabled in ', dayDiff)
      // console.log('project.enabled', project.enabled)
      // console.log('project.disabled', project.disabled)
      // console.log('-----------')
    }




    if (!project.enabled && !project.disabled) {
      if (this.state.isClosed === true)
        return (
          // onClick={() => { this.props.history.push('/schedule/settings') }}
          <span onClick={this.onClickBilling} style={{ border: 'solid 1px silver', color: '#DD3C3E', borderRadius: '5px', padding: '3px', backgroundColor: '#fff', opacity: '0.9', zIndex: '100', position: 'fixed', right: '15px', bottom: isLandscape ? '3px' : '75px', }}><Icon name='exclamation' color='red' />{dayDiff} days left<Icon name='exclamation' color='red' /></span>
        )


      return (
        <div className='fixedWarning ' style={{ opacity: '0.9', width: '300', zIndex: '100', position: 'fixed', marginLeft: '2px', bottom: '3px', right: '15px', padding: '10px', }}>


          <div className='ui icon message red' >
            <div style={{ padding: '5px' }} className="content" >
              <Button compact className='closeButton' size='huge' onClick={this.onClose} icon='close' style={{ position: 'absolute', top: 8, right: 10, border: 'none', background: 'transparent' }} />
              <h3 className="header" style={{ color: '#000', }}>{window._getText('billing.msgHead')} </h3>
              <p style={{ color: '#000', }}>
                {window._getText('billing.msgHead1')} <b>{dayDiff}</b> {window._getText('billing.msgBodyDays')}{' '}
                {window._getText('billing.msgHead2')}<br />
                {window._getText('billing.msgLink')}
              </p>
              <b onClick={this.onClickBilling}>Click here to goto billing.</b>

            </div>

          </div>


        </div>
      )
    }


    return ('')

  }
}



export default connect((state) => ({
  project: state.firestore.data.project,
  isLandscape: state.viewstate.isLandscape
}))(Warning)
