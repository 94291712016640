import React, { Component } from "react";
import {
  Button,
  Image,
  Loader,
  Message,
  Checkbox,
} from "../../common/component/Button";
import { closeModal, setData } from "../../../core/redux/action/modalAction";
import { connect } from "react-redux";
import { isAndroid } from "react-device-detect";
import PropTypes from "prop-types";
import { FireHelper } from "../../../core/util/FireHelper";
import noUserImage from "../../../img/noimg.png";
import AvatarEditor from "react-avatar-editor";
import { uploadProfileImage } from "../../../core/redux/action/userAction";
import { toastr } from "react-redux-toastr";
import { auth } from "../../../core/config/firebase";
import { getUserImage } from "../../../core/util/helpers";

const shortid = require("shortid");
const modalKey = "appdiv";
export class Appdiv extends Component {
  static contextTypes = { router: PropTypes.object };
  componentDidMount() { }

  componentDidUpdate(prevProps, prevState, snapshot) { }
  componentWillUnmount = () => {
    this.props.setModalData("appSettings", { path: null });
  };

  setProjectName = (newName) => {
    if (newName.length > 3) {
      FireHelper.setProject(this.props.projectKey, { name: newName });
      FireHelper.addUserData({ companyName: newName }); // TODO CHANGE FOR ALL USERS
    } else toastr.error("Error", window._getText("companyNameIsNotValid"));
  };

  constructor(props) {
    super(props);
    //this.fileRef = React.createRef();
    var phone = "";
    var phone2 = "";
    var email = "";
    var push = false;
    let companyName = "";
    if (
      this.props.currentUser &&
      this.props.currentUser.extraData &&
      this.props.currentUser.extraData.phone
    )
      phone = this.props.currentUser.extraData.phone;

    if (this.props.currentUser && this.props.currentUser.email)
      email = this.props.currentUser.email;

    if (this.props.currentUser.push === true) push = true;

    const isAnonymous =
      (this.props.currentUser.email || "").indexOf("anonymous_") !== -1;
    companyName = isAnonymous ? "" :  this.props.companyName;

    this.state = {
      companyName,
      email: email,
      isLoading: false,
      isNew: true,
      phone: phone,
      phone2: phone2,
      error: true,
      image: null,
      pass: "",
      rotate: 0,
      savingImage: false,
      push: push,
      providerId: this.props.authUser.providerData[0].providerId, //null chek ?
    };
  }
  onCloseModal = () => {
    console.log(">>>>>>>onCloseModal");
    this.props.onCloseModal(modalKey);
  };

  onClickDelete = () => {
    this.setState({ isLoading: true });
    // doShit.then(() => {
    //     this.setState({ isLoading: false })
    //     this.onCloseModal()
    // })
  };

  onChangeText = (e) => {
    this.setState({
      valueName: e.target.value,
      error: e.target.value.length < 1,
    });
  };
  onClickSave = () => {
    this.setState({ isLoading: true });
    // doShit.then(() => {
    //     this.setState({ isLoading: false })
    //     this.onCloseModal()
    // })
  };

  signOut = () => {
    this.props.onCloseModal(modalKey);
    //this.props.onSignOut()
    //this.props.history.push('/signout')
    window.dispatchEvent(
      new CustomEvent("onGoTo", { detail: { page: "/signout" } })
    );
  };

  onSaveALL = async (e) => {
    e.preventDefault();

    if (this.props.currentUser.email === "demo@timeto.work") {
      toastr.success("Demo account", `No saves are made`);
      console.log("Demo account = exit");
      return;
    }

    if (this.state.email.startsWith("anonymous_")) {
      toastr.error("error", window._getText("login.err.3"));
      return;
    }

    const { currentUser } = this.props;
    console.log(`currentUser`, currentUser);

    if (this.state.companyName !== currentUser.companyName && this.state.companyName.length>2 && currentUser.role > 199) {
      
      this.setProjectName(this.state.companyName);
    }

    this.setState({ isLoading: true });
    var name = (currentUser.extraData || {}).name;
    var email = currentUser.email;
    var img = await this.onSaveImage();
    //var phone = currentUser.phone || (currentUser.extraData || {}).phone
    var newData = {};

    if (img) newData.image = img;

    if (
      this.state.name &&
      this.state.name.length > 1 &&
      name !== this.state.name
    ) {
      newData.name = this.state.name;
    }

    if (
      this.state.email &&
      this.state.email.length > 1 &&
      email !== this.state.email
    ) {
      if ((auth.currentUser.email || "").indexOf("anonymous_") !== -1) {
        await auth.signInWithEmailAndPassword(
          auth.currentUser.email,
          "kastakastakasta"
        );
      }

      await auth.currentUser
        .updateEmail(this.state.email)
        .then(async (res) => {
          newData.email = this.state.email;
        })
        .catch((e) => {
          //ERRORO CODES USE THI TO TRANSALTETYE
          //auth/invalid-email
          // Thrown if the email used is invalid.
          // auth/email-already-in-use
          // Thrown if the email is already used by another user.
          // auth/requires-recent-login
          toastr.error("", e.message);
          this.setState({ email: email });
        });
    } else this.setState({ email: email });

    if (Object.keys(newData).length > 0) {
      const res = await FireHelper.updateInAppUser(newData, currentUser);
      if (res.success) toastr.success("Saved", 3);
      else toastr.error("", res.error);
    }

    this.onSavePassword();
  };

  tbChange = (tb) => (e) => this.setState({ tb: e.target.value });

  setEditorRef = (editor) => (this.editor = editor);

  handleChange = (event) =>
    this.setState({ sliderValue: Number(event.target.value) });

  onRotateImage = (e) => {
    e.preventDefault();
    this.setState({ rotate: this.state.rotate + 90 });
  };

  onSaveImage = async () => {
    if (this.props.currentUser.email === "demo@timeto.work") {
      toastr.success("Demo account", `No saves are made`);
      console.log("Demo account = exit");
      return;
    }
    return new Promise(async (resolve, reject) => {
      if (this.state.image) {
        this.setState({ savingImage: true });
        const { currentUser } = this.props;
        try {
          this.editor.getImageScaledToCanvas().toBlob(async (blob) => {
            this.props
              .uploadProfileImage(
                blob,
                currentUser.inSysUserKey,
                this.props.projectKey
              )
              .then((res) => {
                resolve(shortid.generate());
              });
          }, "image/jpg");
          this.setState({ savingImage: false, image: null });
        } catch (error) {
          this.setState({ savingImage: false, image: null });
          resolve(null);
          if (error.message) {
            //console.log(error.message)
            toastr.error("Error", error.message, { timeOut: 30000 });
          } else toastr.error("Error", error, { timeOut: 30000 });
        }
        // }
      } else resolve(null);
    });
  };

  onSavePassword = async (e) => {
    console.log(`onSavePassword`);
    if (this.props.currentUser.email === "demo@timeto.work") {
      toastr.success("Demo account", `No saves are made`);
      console.log("Demo account = exit");
      return;
    }

    if (e) e.preventDefault();

    if (!this.props.currentUser.inSysUserKey) {
      toastr.error("Error", window._getText("usernotmapped_txt1"), {
        timeOut: 30000,
      });
      return;
    }
    if (this.state.pass) {
      if (this.state.pass.length > 5) {
        var pass = this.state.pass;
        this.setState({ pass: "" });

        auth.currentUser
          .updatePassword(pass)
          .then(() => {
            // Update successful.
            toastr.success("Password is updated");
          })
          .catch(function (error) {
            console.log(`error`, error.code);
            //if (error && error.code === "auth/requires-recent-login")

            if (error.message)
              toastr.error("Error", error.message, { timeOut: 30000 });
            else toastr.error("Error", error, { timeOut: 30000 });
          });
      } else toastr.error("Error", "Minimum 6 chars", { timeOut: 30000 });
    }
  };

  onSettingsCheck = async (e, { name, value, checked }) => {
    if (this.props.currentUser.email === "demo@timeto.work") {
      toastr.success("Demo account", `No saves are made`);
      console.log("Demo account = exit");
      return;
    }

    e.preventDefault();
    if (!this.props.currentUser.inSysUserKey) {
      toastr.error("Error", window._getText("usernotmapped_txt1"), {
        timeOut: 30000,
      });
      return;
    }

    const { currentUser } = this.props;
    this.setState({ push: checked });
    await FireHelper.addUserData({ push: checked }, currentUser);
  };
  handleFileUpload = (e) => {
    if (this.props.currentUser.email === "demo@timeto.work") {
      toastr.success("Demo account", `No saves are made`);
      console.log("Demo account = exit");
      return;
    }

    if (e.target.files) {
      var file = e.target.files[0];
      this.setState({ image: file });
    }
  };
  render() {
    if (this.props.isUnknown === true)
      return (
        <div className="ui message" style={{ margin: "10px" }}>
          <div className="header">{window._getText("usernotmapped_title")}</div>
          <p>
            {window._getText("usernotmapped_txt1")} <br />
            {window._getText("usernotmapped_txt2")}
          </p>
        </div>
      );

    const { currentUser, projectKey } = this.props;
    let isCordovaAndroid = isAndroid && window.isCordovaApp;
    let isMapped = !!currentUser.inSysUserKey;

    if (this.state.name === undefined && currentUser.extraData)
      this.setState({ name: currentUser.extraData.name || "#unknown" });

    const isAnonymous =
      (this.props.currentUser.email || "").indexOf("anonymous_") !== -1;
    return (
      <div>
        {!isMapped && (
          <Message>
            <Message.Header>
              {window._getText("usernotmapped_title")}
            </Message.Header>
            <p>
              {window._getText("usernotmapped_txt1")} <br />
              {window._getText("usernotmapped_txt2")}
            </p>
          </Message>
        )}

        <div style={{ display: "flex" }} className="dragWindow">
          <Button
            style={{ marginLeft: "auto", marginRight:10 }}
            compact
            onClick={() => {
              this.signOut();
            }}
            color="blue"
            content={window._getText("signout")}
            icon="log out"
          ></Button>
        </div>

        <div>
          {this.state.savingImage && (
            <Loader inline>{window._getText("uploading_image")}</Loader>
          )}

          <form>
            {!this.state.savingImage && this.state.image && (
              <div style={{ marginLeft: "auto", marginRight: "auto" }}>
                <AvatarEditor
                  style={{
                    display: "block",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                  ref={this.setEditorRef}
                  image={URL.createObjectURL(this.state.image)}
                  width={90}
                  height={90}
                  border={20}
                  borderRadius={50}
                  scale={Number(this.state.sliderValue || 1)}
                  rotate={this.state.rotate}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    paddingTop: "4px",
                  }}
                >
                  <Button
                    style={{ marginRight: "5px", maxHeight: "30px" }}
                    compact
                    onClick={() => this.setState({ image: null })}
                    icon="x"
                    disabled={this.state.savingImage}
                  />
                  <input
                    id="typeinp"
                    type="range"
                    min="0.5"
                    max="5"
                    defaultValue="1"
                    value={this.state.value || ''}
                    onChange={this.handleChange}
                    step="0.01"
                    style={{ width: "330px", float: "right" }}
                  />

                  <Button
                    style={{ marginLeft: "5px", maxHeight: "30px" }}
                    compact
                    onClick={this.onRotateImage}
                    icon="redo"
                    disabled={this.state.savingImage}
                  />
                  {/* <Button style={{ marginLeft: 'auto', maxHeight: '30px' }} compact onClick={this.onSaveImage} color='green' content={window._getText('upload')} ></Button> */}
                </div>
              </div>
            )}

            {!this.state.savingImage && !this.state.image && (
              <div style={{ padding: "10px" }}>
                <label
                  htmlFor="upload"
                  style={{
                    width: "100%",
                    display: isCordovaAndroid ? "none" : "",
                  }}
                >
                  <div style={{ height: "130px" }}>
                    {currentUser && currentUser.extraData && (
                      <Image
                        style={{
                          maxWidth: "100px",
                          maxHeight: "100px",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                        src={
                          currentUser.extraData.image
                            ? getUserImage(
                              projectKey,
                              currentUser.inSysUserKey,
                              currentUser.extraData.image
                            )
                            : noUserImage
                        }
                        circular
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = noUserImage;
                        }}
                      />
                    )}
                  </div>
                </label>
                <input
                  className={isCordovaAndroid ? "custom-file-input" : ""}
                  type="file"
                  ref={this.fileRef}
                  id="upload"
                  accept="image/*"
                  onChange={(e) => {
                    this.handleFileUpload(e);
                  }}
                  style={{
                    display: isCordovaAndroid ? "inline-block" : "none",
                    border: "none",
                  }}
                />
              </div>
            )}

            <div style={{ display: "flex", flexDirection: "column" }}>
              {currentUser.role > 199 &&
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    borderBottom: "dotted 1px var(--color-border)",
                    minHeight: "35px",
                    marginLeft: "30px",
                    marginRight: "30px",
                  }}
                >


                  <div
                    style={{
                      paddingTop: "11px",
                      paddingRight: "8px",
                      width: "150px",
                      textAlign: "right",
                      fontWeight: "bold",
                      color: isAnonymous ? "red" : "",
                    }}
                  >
                    {window._getText("createNewAccount.comName")} :
                </div>
                  <div style={{ paddingTop: "11px" }}>
                    <input
                      className="tbTrans"
                      autoComplete="new-password"
                      type="text"
                      maxLength={40}
                      value={this.state.companyName || ''}
                      onChange={(e) =>
                        this.setState({ companyName: e.target.value })
                      }
                      style={{ border: "none", width: "250px" }}
                    />
                  </div>


                </div>
              }
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  borderBottom: "dotted 1px var(--color-border)",
                  minHeight: "35px",
                  marginLeft: "30px",
                  marginRight: "30px",
                }}
              >
                <div
                  style={{
                    paddingTop: "11px",
                    paddingRight: "8px",
                    width: "150px",
                    textAlign: "right",
                    fontWeight: "bold",
                  }}
                >
                  {window._getText("name")} :
                </div>
                <div style={{ paddingTop: "11px" }}>
                  <input
                    className="tbTrans"
                    autoComplete="new-password"
                    type="text"
                    maxLength={40}
                    value={this.state.name || ''}
                    onChange={(e) => this.setState({ name: e.target.value })}
                    style={{ border: "none", width: "250px" }}
                  />
                </div>
              </div>

              {this.state.providerId === "password" ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    borderBottom: "dotted 1px var(--color-border)",
                    minHeight: "35px",
                    marginLeft: "30px",
                    marginRight: "30px",
                  }}
                >
                  <div
                    style={{
                      paddingTop: "11px",
                      paddingRight: "8px",
                      width: "150px",
                      textAlign: "right",
                      fontWeight: "bold",
                      color: isAnonymous ? "red" : "",
                    }}
                  >
                    {window._getText("email")} :
                  </div>
                  <div style={{ paddingTop: "11px" }}>
                    <input
                      className="tbTrans"
                      autoComplete="new-password"
                      type="mail"
                      maxLength={40}
                      value={this.state.email || ''}
                      onChange={(e) => this.setState({ email: e.target.value })}
                      style={{ border: "none", width: "250px" }}
                    />
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    borderBottom: "dotted 1px var(--color-border)",
                    minHeight: "35px",
                    marginLeft: "30px",
                    marginRight: "30px",
                  }}
                >
                  <div
                    style={{
                      paddingTop: "11px",
                      paddingRight: "8px",
                      width: "150px",
                      textAlign: "right",
                      fontWeight: "bold",
                    }}
                  >
                    {window._getText("phone")} :
                  </div>
                  <div style={{ paddingTop: "11px" }}>
                    <input
                      className="tbTrans"
                      autoComplete="new-password"
                      type="tel"
                      maxLength={40}
                      value={this.state.phone || ''}
                      onChange={(e) => this.setState({ phone: e.target.value })}
                      style={{ border: "none", width: "250px" }}
                    />
                  </div>
                </div>
              )}

              {this.state.providerId === "password" && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    borderBottom: "dotted 1px var(--color-border)",
                    minHeight: "35px",
                    marginLeft: "30px",
                    marginRight: "30px",
                  }}
                >
                  <div
                    style={{
                      paddingTop: "11px",
                      paddingRight: "8px",
                      width: "150px",
                      textAlign: "right",
                      fontWeight: "bold",
                      color: isAnonymous ? "red" : "",
                    }}
                  >
                    {window._getText("appdiv.changepass")} :
                  </div>
                  <div style={{ paddingTop: "11px" }}>
                    <input
                      className="tbTrans"
                      autoComplete="new-password"
                      type="text"
                      maxLength={40}
                      value={this.state.pass || ''}
                      onChange={(e) => this.setState({ pass: e.target.value })}
                      style={{ border: "none", width: "250px" }}
                    />
                  </div>
                </div>
              )}
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                borderBottom: "dotted 1px var(--color-border)",
                minHeight: "35px",
                marginLeft: "30px",
                marginRight: "30px",
              }}
            >
              <div
                style={{
                  paddingTop: "11px",
                  paddingRight: "8px",
                  width: "150px",
                  textAlign: "right",
                  fontWeight: "bold",
                }}
              >
                {window._getText("appdiv.pushmessages")} :
              </div>
              <div style={{ paddingTop: "11px" }}>
                {window._getText("off")}{" "}
                <Checkbox
                  toggle
                  name="push"
                  onChange={this.onSettingsCheck}
                  checked={this.state.push}
                />{" "}
                {window._getText("on")}
              </div>
            </div>

            <div
              style={{
                textAlign: "right",
                position: "fixed",
                bottom: "10px",
                right: "10px",
              }}
            >
              <Button
                color="green"
                onClick={this.onSaveALL}
                icon="check"
                compact
              ></Button>
            </div>
          </form>
          {/* <span style={{ color: '#fff' }}>{currentUser.inSysUserKey} | {currentUser.key}</span> */}
        </div>
      </div>
    );
  }
}

const mapActions = {
  onCloseModal: closeModal,
  uploadProfileImage,
  setModalData: setData,
};

const mapStateToProps = (state, ownProps) => {
  if (
    state &&
    state.modal &&
    state.modal.modals.appSettings.data &&
    state.modal.modals.appSettings.data.path === "billing"
  ) {
    ownProps.setTabIndex(3)();
  } else if (
    state &&
    state.modal &&
    state.modal.modals.appSettings.data &&
    state.modal.modals.appSettings.data.path === "settings"
  ) {
    ownProps.setTabIndex(2)();
  }

  return {
    authUser: state.firebase.auth,
    isUnknown: !state.firebase.profile.inSysUserKey,
    projectKey: state.firebase.profile.projectKey,
    currentUser: state.firebase.profile,
    companyName: state.firestore.data.project.name,
    //isOpen: state.modal.modals[modalKey].open || false,
    //data: state.modal.modals[modalKey].data || {}
  };
};
export default connect(mapStateToProps, mapActions)(Appdiv);
