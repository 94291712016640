export const GET_SCHEDULE = 'currentScheduleData:get'
export const SET_SCHEDULE = 'currentScheduleData:set'
export const SET_SCHEDULE_ISLOADING = 'currentScheduleData:setIsLoading'
export const SET_SCHEDULE_RENDERDATE = 'currentScheduleData:renderDate'
export const COPY_SCHEDULE_SHIFT = 'currentScheduleData:copyScheduleShift'
export const COPY_SCHEDULE_ROW = 'currentScheduleData:copyScheduleRow'
export const RESET_SCHEDULE = 'currentScheduleData:reset'



export function resetSchedule() {
    return {
        type: RESET_SCHEDULE,
        payload: null
    }
}

export function copyScheduleRow(clone) {
    return {
        type: COPY_SCHEDULE_ROW,
        payload: clone
    }
}

export function copyScheduleShift(clone) {
    return {
        type: COPY_SCHEDULE_SHIFT,
        payload: clone
    }
}


export function getSchedule(key) {
    return {
        type: GET_SCHEDULE,
        payload: key
    }
}

export function setSchedule(schedule) {
    return {
        type: SET_SCHEDULE,
        payload: schedule
    }
}

export function setIsLoadingSchedule(isLoading) {
    //console.log('setIsLoadingSchedule >> ', isLoading)
    return {
        type: SET_SCHEDULE_ISLOADING,
        payload: isLoading
    }
}

export function setRenderDayDateSchedule(date) {
    //console.log('setRenderDateSchedule >> ', date)
    return {
        type: SET_SCHEDULE_RENDERDATE,
        payload: date
    }
}



