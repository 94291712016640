import React, { Component } from "react";
import AppLoggedInRouter from './AppLoggedInRouter'

class AppRouter extends Component {

  render() {
    const { haveUser, currentUser } = this.props
    if (haveUser !== true) {
      return ('')
    }

    return (


      <AppLoggedInRouter token={this.props.token} currentUser={currentUser} />



    );
  }
}

export default (AppRouter)


