import firebase, { db, auth } from '../config/firebase'
import { toastr } from 'react-redux-toastr'
import shortid from 'shortid'
import moment from 'moment'
import { createSelector } from 'reselect';
import moize from "moize";
import captureException from 'sen-obj';
import { getUserImage, renderTimeLable } from './helpers'
import { Factory } from '../util/Factory'
//import noUserImage from '../../img/noimg.png'

//const collection = require('lodash/collection')

export const FireHelper = {
    getLanguageCode: async () => {
        return new Promise(async (resolve, reject) => {
            if (window === undefined) {
                global.window = global;
                console.log('Set global obj for RN')
            }


            if (!window._contryInfo) {
                var url = `https://${window._iapUrl}/getContryCode`
                await fetch(url, { method: 'Post', headers: { 'Content-Type': 'text/plain' } }).then(response => response.json()).then(response => {
                    if (response && response.country_code) {
                        window._contryInfo = { countryCode: response.country_code, callCode: (response.location || {}).calling_code }
                        //       console.log('got contry code')
                        resolve(window._contryInfo)
                    }
                    else
                    {      
                        reject()
                    }
                })
                // console.log('get contry code')
            }
            else {

                //  console.log('window._contryInfo cachad', window._contryInfo)
                resolve(window._contryInfo)
            }
        }
        )



    },


    getUserlistOld: moize.deep((users, projectKey) => {
        // console.log('#>>>>>>>>getUserlist', users)
        var userList = [];
        var userFullList = [];

        if (users) {
            userFullList = users.userlist || {};
            userList = Object.keys(userFullList).map(key => {
                if (userFullList[key] == null) return null;
                if (userFullList[key].isActive == null) return null;
                // if (userFullList[key].sysUserKey == null) return null; // TODO JENS FIX THIS IN THE CHATT!

                const u = userFullList[key]
                const image = getUserImage(projectKey, key, u.image || 'x')
                return { text: u.name, key: key, sysKey: u.sysUserKey, value: key, image, act: userFullList[key].isActive };
            });
            userList = userList.filter(u => u !== null)
            return { userList: userList || [], userFullList: userFullList || {} }
        }
        else
            return { userList: [], userFullList: {} }

    }, { maxSize: 5 }),


    approveSwapShift: async (shiftKey, shiftKey2, docKey, docKey2, schedualKey, schedualKey2, shiftOwnerKey, shiftOwnerKey2, uid, uid2, rType, profile, msg) => {
        //console.log('approveSwapShift')

        try {
            const projectKey = profile.projectKey
            var batch = db.batch();
            const message = msg ? msg : 'Request approved!'

            //if(deviation)
            //remove the old metadata
            batch.set(db.doc(`projects/${projectKey}/shiftMetaData/${docKey}`), { [schedualKey]: { [shiftKey]: { [rType]: firebase.firestore.FieldValue.delete() } } }, { merge: true })

            batch.set(db.doc(`projects/${projectKey}/schedules/${schedualKey}/events/${docKey}`), { cacheKey: +new Date(), events: { [shiftKey]: { uid: uid2 } }, sortedUserList: firebase.firestore.FieldValue.arrayUnion(uid2) }, { merge: true })
            batch.set(db.doc(`projects/${projectKey}/schedules/${schedualKey2}/events/${docKey2}`), { cacheKey: +new Date(), events: { [shiftKey2]: { uid: uid } }, sortedUserList: firebase.firestore.FieldValue.arrayUnion(uid) }, { merge: true })




            // var newUserId = newData.uid

            // if (responseFromWeekView.success === false) {
            //     r.success = false
            //     r.msg = 'SHIFT_IS_MISSING'
            //     console.log('update the weekview', 'fail')
            // }

            await batch.commit().then(async () => {
                toastr.success('', message);
                //var responseFromWeekView = await 

                //update weekview
                await FireHelper.updatetShiftDataWeekView(projectKey, schedualKey, shiftKey, docKey, {}, uid2)
                await FireHelper.updatetShiftDataWeekView(projectKey, schedualKey2, shiftKey2, docKey2, {}, uid)
                //var responseFromWeekView = await 
                FireHelper.setSystemLogMsg(projectKey, shiftOwnerKey, message)
                FireHelper.setSystemLogMsg(projectKey, shiftOwnerKey2, message)
            }).catch(err => console.log('#Err 5fcGDG' + err))
        } catch (error) {
            console.log('approveSwapShift', error)
            toastr.error('Error', error);
            captureException(error);
        }

    },
    approveChangeShift: async (shiftKey, docKey, scheduleKey, reqDocKey, shiftOwnerKey, newData, rType, profile, msg) => {
        return new Promise(async (resolve, reject) => {


            let r = { success: true, msg: null }
            try {




                // console.log('-----------------')
                // console.log('-----------------')
                const projectKey = profile.projectKey
                var batch = db.batch();
                const message = msg ? msg : 'Request approved!'
                const docPath = `projects/${projectKey}/schedules/${scheduleKey}/events/${docKey}`
                //if(deviation)
                //remove the old metadata

                const oldDoc = await db.doc(docPath).get()
                let oldEvent = false
                if (oldDoc) {
                    const eventsDoc = oldDoc.data()
                    if (eventsDoc && eventsDoc.events) {
                        oldEvent = eventsDoc.events[shiftKey]
                    }

                }

                if (oldEvent) {
                    //console.log('Update shiftMeta')
                    batch.set(db.doc(`projects/${projectKey}/shiftMetaData/${docKey}`), { [scheduleKey]: { [shiftKey]: { [rType]: firebase.firestore.FieldValue.delete() } } }, { merge: true })

                    //remove the old request
                    //batch.delete(db.doc(`projects/${projectKey}/requests/${reqDocKey}`))


                    if (newData) {
                        //update the shift in the schedual
                        if (newData.uid)
                            batch.set(db.doc(docPath), { cacheKey: +new Date(), events: { [shiftKey]: newData }, sortedUserList: firebase.firestore.FieldValue.arrayUnion(newData.uid) }, { merge: true })
                        else
                            batch.set(db.doc(docPath), { cacheKey: +new Date(), events: { [shiftKey]: newData } }, { merge: true })

                    }


                    await batch.commit().then(() => {
                        // toastr.success('', message);
                        resolve(r)
                        FireHelper.setSystemLogMsg(projectKey, shiftOwnerKey, message)
                    }).catch(err => {
                        r.success = false
                        r.msg = err
                        console.log('#Err 5fcGDG' + err)
                    })
                } else {
                    //  console.log('Shit dont exits in work view', 'Save only shift meta')
                    db.doc(`projects/${projectKey}/shiftMetaData/${docKey}`).set({ [scheduleKey]: { [shiftKey]: { [rType]: firebase.firestore.FieldValue.delete() } } }, { merge: true })
                    r.success = false
                    r.msg = 'SHIFT_IS_MISSING'
                }


                //try to update the weekview
                var compactShiftData = Factory.compactPartialData({ ...oldEvent, ...newData })

                var newUserId = newData.uid

                var responseFromWeekView = await FireHelper.updatetShiftDataWeekView(projectKey, scheduleKey, shiftKey, docKey, compactShiftData, newUserId)

                if (responseFromWeekView && responseFromWeekView.success === false) {
                    r.success = false
                    r.msg = 'SHIFT_IS_MISSING'
                    console.log('update the weekview', 'fail')
                }


            } catch (error) {
                console.log('approveChangeShift', error)
                r.success = false
                r.msg = error
                toastr.error('Error', error);
                captureException(error);
            }
            resolve(r)
        })
    },

    removeShift: async (shiftKey, docKey, scheduleKey, reqDocKey, shiftOwnerKey, rType, profile) => {
        //console.log('removeShift')

        try {
            const projectKey = profile.projectKey
            var batch = db.batch();
            //batch.delete(db.doc(`projects/${projectKey}/requests/${reqDocKey}`))
            batch.set(db.doc(`projects/${projectKey}/shiftMetaData/${docKey}`), { [scheduleKey]: { [shiftKey]: { [rType]: firebase.firestore.FieldValue.delete() } } }, { merge: true })

            batch.set(db.doc(`projects/${projectKey}/schedules/${scheduleKey}/events/${docKey}`), { cacheKey: +new Date(), events: { [shiftKey]: firebase.firestore.FieldValue.delete() } }, { merge: true })
            await batch.commit().then(() => {
                toastr.success('', 'shift is removed');
                FireHelper.setSystemLogMsg(projectKey, shiftOwnerKey, 'Shift cancelation is approved!')
            }).catch(err => {
                console.log('#Err 5fcGDG' + err)
                captureException(err);
            }
            )
        } catch (error) {
            console.log('removeShift', error)
            toastr.error('Error', error);
            captureException(error);
        }

    },

    removeRequest: async (docKey, scheduleKey, shiftKey, shiftOwnerKey, rType, profile, msg) => {
        //console.log('removeRequest')
        try {
            const projectKey = profile.projectKey
            const message = msg ? msg : 'Request is declined'
            var batch = db.batch();
            batch.set(db.doc(`projects/${projectKey}/shiftMetaData/${docKey}`), { [scheduleKey]: { [shiftKey]: { [rType]: firebase.firestore.FieldValue.delete() } } }, { merge: true })
            //todo edit metadata!
            await batch.commit().then(() => {
                // toastr.success(message);
                FireHelper.setSystemLogMsg(projectKey, shiftOwnerKey, message)
            }).catch(err => {
                console.log('#Err 53456vfG' + err)
                captureException(err);
            })
        } catch (error) {
            console.log('removeRequest', error)
            toastr.error('Error', error);
            captureException(error);
        }

    },
    removeRequestDirect: async (reqDocKey, shiftOwnerKey, rType, profile, msg) => {
        try {
            const projectKey = profile.projectKey
            var batch = db.batch();
            const message = msg ? msg : 'Request is declined'
            //console.log('reqDocKey', reqDocKey)
            batch.delete(db.doc(`projects/${projectKey}/requests/${reqDocKey}`))
            //todo edit metadata!
            await batch.commit().then(() => {
                // toastr.success(message);
                FireHelper.setSystemLogMsg(projectKey, shiftOwnerKey, message)
            }).catch(err => {
                console.log('#Err 53456vfG' + err)
                captureException(err)
            })
        } catch (error) {
            console.log('removeRequestDirect', error)
            toastr.error('Error', error);
            captureException(error)
        }

    },
    removeParticipantRequest: async (docKey, scheduleKey, shiftKey, rType, shiftOwnerKey, profile) => {
        try {
            //console.log('removeParticipantRequest', shiftOwnerKey)
            const projectKey = profile.projectKey
            const userKey = profile.inSysUserKey

            var oldDoc = await db.doc(`projects/${projectKey}/shiftMetaData/${docKey}`).get()
            //Maybe use the firebase.firestore.FieldValue.arrayRemove('east_coast')
            if (oldDoc.exists) {
                var oldData = oldDoc.data()
                var newUser = oldData[scheduleKey][shiftKey][rType].sh.newU || []
                oldData[scheduleKey][shiftKey][rType].sh.newU = newUser.filter(x => x !== userKey) // remove current user
                if (oldData[scheduleKey][shiftKey][rType].sh.newU.length === 0) {
                    //ALL partisipants have decliend the request , now we delete it and systemMsg the sender !
                    delete oldData[scheduleKey][shiftKey][rType]
                    const keyCount = Object.keys(oldData[scheduleKey][shiftKey]).length
                    if (keyCount <= 1) {
                        //it is empty , remove it 
                        // delete oldData[scheduleKey][shiftKey]
                        // const keyCount2 = Object.keys(oldData[scheduleKey]).length
                        // alert(keyCount2)
                        oldDoc.ref.set({ [scheduleKey]: { [shiftKey]: firebase.firestore.FieldValue.delete() } }, { merge: true })
                            .then(() => {
                                toastr.success('You declined the offer ');
                                FireHelper.setSystemLogMsg(projectKey, shiftOwnerKey, 'All participants have declined your shift change request')
                            }).catch(err => {
                                console.log('#Err 53456vfG' + err)
                                captureException(err)
                            }
                            )
                    }
                    else {
                        oldDoc.ref.set({ [scheduleKey]: { [shiftKey]: { [rType]: firebase.firestore.FieldValue.delete() } } }, { merge: true })
                            .then(() => {
                                toastr.success('You declined the offer ');
                                FireHelper.setSystemLogMsg(projectKey, shiftOwnerKey, 'All participants have declined your shift change request')
                            }).catch(err => {
                                console.log('#Err 53456vfG' + err)
                                captureException(err)
                            })
                    }
                    // oldDoc.ref.set(oldData, { merge: false }).then(() => {
                    //     toastr.success('You declined the offer ');
                    //     FireHelper.setSystemLogMsg(projectKey, shiftOwnerKey, 'All participants have declined your shift change request')
                    // }).catch(err => console.log('#Err 53456vfG' + err))
                }
                else {
                    // oldDoc.ref.set(oldData, { merge: false }).then(() => {
                    //     toastr.success('You declined the offer ');
                    //     //FireHelper.setSystemLogMsg(projectKey, shiftOwnerKey, 'User XXX have decliend your shift request')
                    // }).catch(err => console.log('#Err 53456vfG' + err))
                    oldDoc.ref.set({ [scheduleKey]: { [shiftKey]: { [rType]: { sh: { newU: firebase.firestore.FieldValue.arrayRemove(userKey) } } } } }, { merge: true })
                        .then(() => {
                            toastr.success('You declined the offer ');
                            //FireHelper.setSystemLogMsg(projectKey, shiftOwnerKey, 'User XXX have decliend your shift request')
                        }).catch(err => console.log('#Err 53456vfG' + err))

                }
            }
        } catch (error) {
            console.log('removeParticipantRequest', error)
            toastr.error('Error', error);
        }

    },
    setToAdminRequest: async (docKey, scheduleKey, shiftKey, rType, profile) => {
        try {
            const projectKey = profile.projectKey
            const userKey = profile.inSysUserKey
            const shiftOwnerKey = profile.key

            db.doc(`projects/${projectKey}/shiftMetaData/${docKey}`).set(
                { [scheduleKey]: { [shiftKey]: { [rType]: { adm: true, sh: { newU: [userKey] } } } } },
                { merge: true }).then(() => {
                    toastr.success('Request is sent to admin for approval');
                    FireHelper.setSystemLogMsg(projectKey, shiftOwnerKey, 'Request is sent to admin for approval')
                }).catch(err => {
                    console.log('#Err 53456vfG' + err)
                    captureException(err)
                })


        } catch (error) {
            console.log('setToAdminRequest', error)
            toastr.error('Error', error);
            captureException(error)
        }

    },
    // setToAdminRequestPld: async (docKey, scheduleKey, shiftKey, rType, profile) => {
    //     try {
    //         //console.log('setToAdminRequest')
    //         const projectKey = profile.projectKey
    //         const userKey = profile.inSysUserKey
    //         const shiftOwnerKey = profile.key
    //         var oldDoc = await db.doc(`projects/${projectKey}/shiftMetaData/${docKey}`).get()
    //         //Maybe use the firebase.firestore.FieldValue.arrayRemove('east_coast')
    //         if (oldDoc.exists) {
    //             var oldData = oldDoc.data()
    //             oldData[scheduleKey][shiftKey][rType].adm = true //set to admin
    //             oldData[scheduleKey][shiftKey][rType].sh.newU = [userKey] // remove old user and add only current
    //             oldDoc.ref.set(oldData, { merge: false }).then(() => {
    //                 toastr.success('Request is xxxxxx');
    //                 FireHelper.setSystemLogMsg(projectKey, shiftOwnerKey, 'XXXXXXX')
    //             }).catch(err => console.log('#Err 53456vfG' + err))

    //         }
    //     } catch (error) {
    //         //console.log(error)
    //         toastr.error('Error', error);
    //     }

    // },




    reloadToken: (reload) => {
        // //console.log('reloadToken')


        if (!auth.currentUser)
            return new Promise((resolve, reject) => { resolve({ claims: { role: '', pKey: '' } }) })//THIS IS WRONG

        auth.currentUser.reload();

        return auth.currentUser.getIdTokenResult(reload)

    },

    removeTemplateShift: async (key, projectKey, scheduleKey) => {
        try {
            //console.log('remoevTemplateShift')
            //console.log('projectKey', projectKey)
            //console.log('scheduleKey', scheduleKey)
            //console.log('key', key)
            await db.doc(`projects/${projectKey}/schedules/${scheduleKey}`).set({ templates: { [key]: firebase.firestore.FieldValue.delete() } }, { merge: true })
            //console.log('-->', res)
            toastr.success('Template is removed');
        } catch (error) {
            console.log('removeTemplateShift', error)
            toastr.error('Error', error);
            captureException(error)
        }

    },

    getCompanyId: async (publicKey) => {
        var error = ''
        var success = true
        var data = {}
        await db.collection(`projects`).where('publicKey', '==', publicKey).limit(1)
            .get().then(docRef => {
                if (docRef && docRef.docs[0])
                    data = { ...docRef.docs[0].data(), projectKey: docRef.docs[0].id }
                else
                    success = false
            })
            .catch(err => {
                error = err
                success = false
                captureException(err)
            }

            );

        return { success, error, data }
    },

    isLinkFree: async (str) => {
        return new Promise((resolve, reject) => {

            var error = ''
            var success = true
            var data = {}
            console.log('isLinkFree', str)
            db.collection(`links`).where('list', 'array-contains', str).limit(1)
                .get().then(async docRef => {
                    console.log('docRef.empty', docRef.empty)
                    if (docRef.empty === false) {
                        success = false
                    }
                    console.log('success', success)
                    resolve({ success, error, data })
                })
                .catch(err => {
                    error = err
                    success = false
                    captureException(err)
                    resolve({ success, error, data })
                }

                );


        })

    },
    getUserData: async () => {
        //NOT IN USER? 
        console.log('#NOT IN USER? ', 'Firebasehelper.getUserData')
        const user = auth.currentUser
        var error = ''
        var success = true
        var data = {}
        await db.doc(`users/${user.uid}`).get().then(docRef => {
            data = docRef.data()
        })
            .catch(err => {
                error = err
                success = false
                captureException(err)
            }

            );
        return { success, error, data }
    },
    removeShiftData: async (projectKey, scheduleKey, dateKey, shiftKey) => {
        var error = ''
        var success = true
        db.doc(`projects/${projectKey}/schedules/${scheduleKey}/events/${dateKey}`).set({ cacheKey: +new Date(), events: { [shiftKey]: firebase.firestore.FieldValue.delete() } }, { merge: true })
        //console.log('>>setShiftData', error)
        return { success, error }
    },
    setShiftData: async (projectKey, scheduleKey, dateKey, shiftKey, data, dontRecreatWeekView, prevScheduleKey) => {
        var error = ''
        var success = true
        if (dontRecreatWeekView) // so the triger on the table dont recreate weekview
        {

            //its a scheduel move we need to remove the old shift 

            if (prevScheduleKey && prevScheduleKey !== scheduleKey) {

                await db.doc(`projects/${projectKey}/schedules/${prevScheduleKey}/events/${dateKey}`).set({ cacheKey: +new Date(), events: { [shiftKey]: firebase.firestore.FieldValue.delete() }, dontRecreatWeekView }, { merge: true }).catch(err => {
                    error = err
                    success = false
                    console.log('>> setShiftData err', err)
                    captureException(err)
                })

            }

            await db.doc(`projects/${projectKey}/schedules/${scheduleKey}/events/${dateKey}`).set({ cacheKey: +new Date(), events: { [shiftKey]: data }, dontRecreatWeekView }, { merge: true }).catch(err => {
                error = err
                success = false
                console.log('>> setShiftData err', err)
                captureException(err)
            })
        }
        else
            await db.doc(`projects/${projectKey}/schedules/${scheduleKey}/events/${dateKey}`).set({ cacheKey: +new Date(), events: { [shiftKey]: data } }, { merge: true }).catch(err => {
                error = err
                success = false
                console.log('>> setShiftData err', err)
                captureException(err)
            })

        return { success, error }
    },


    removeShiftFromWeekView: (weekData, scheduleKey, shiftKey) => {
        let schedule = { ...weekData[scheduleKey] }
        let shiftIndex = null
        if (schedule && schedule.rows) {
            schedule.rows.some(userRow => {
                for (let index = 0; index <= 7; index++) {

                    if (userRow.s[index] && userRow.s[index]) {
                        for (let k = 0; k < (userRow.s[index] || []).length; k++) {
                            const shiftItem = userRow.s[index][k];
                            if (shiftItem.k === shiftKey) {
                                shiftIndex = index
                                userRow.s[index].splice(k, 1)
                                break
                            }

                        }
                        if (shiftIndex !== null)
                            break
                    }
                }

                if (shiftIndex !== null)
                    return true
            });
        }
        return schedule

    },

    addOrUpdateShiftFromWeekView: (weekData, scheduleKey, dayIndex, userId, shift, renderDateKey, scheduleName) => {
        let schedule = { ...weekData[scheduleKey] }

        if (!schedule.sName) //this a schedule that has not been published yet..
        {
            schedule.key = renderDateKey + '_' + scheduleKey
            schedule.dateKey = renderDateKey
            schedule.sKey = scheduleKey
            schedule.sName = scheduleName
            schedule.cacheKey = shortid.generate()
            schedule.unPublish = false
            schedule.publish = true
        }

        if (!schedule)
            throw new Error('no schedula for ' + scheduleKey)

        if (!schedule.rows)
            schedule.rows = []

        console.log(`schedule.rows`, schedule.rows)

        let rowIndex = schedule.rows.findIndex(x => x.k === userId)

        if (rowIndex == -1)
            rowIndex = schedule.rows.length

        if (!schedule.rows[rowIndex])
            schedule.rows[rowIndex] = { s: {}, k: userId }

        if (!schedule.rows[rowIndex].s[dayIndex])
            schedule.rows[rowIndex].s[dayIndex] = []


        let shiftIndex = schedule.rows[rowIndex].s[dayIndex].findIndex(x => x.k == shift.k)

        if (shiftIndex == -1)
            shiftIndex = schedule.rows[rowIndex].s[dayIndex].length

        schedule.rows[rowIndex].s[dayIndex][shiftIndex] = shift

        return schedule

    },

    // NOT IN USE
    getShiftFromWeekView_old: (weekData, scheduleKey, shiftKey) => {
        //loop tru all schedule , dont use scheduleKey

        let schedule = { ...weekData[scheduleKey] }
        let userId
        let dayIndex
        let rowIndex
        let shiftIndex = null
        let shift
        if (schedule && schedule.rows) {
            for (let r = 0; r < schedule.rows.length; r++) {
                var userRow = schedule.rows[r]
                userId = userRow.k
                rowIndex = r
                for (let index = 0; index <= 7; index++) {
                    if (userRow.s[index] && userRow.s[index]) {
                        for (let k = 0; k < (userRow.s[index] || []).length; k++) {
                            const shiftItem = userRow.s[index][k];
                            if (shiftItem.k === shiftKey) {
                                dayIndex = index
                                shiftIndex = k
                                shift = shiftItem
                                break
                            }
                        }
                        if (shiftIndex !== null)
                            break
                    }
                }

                if (shiftIndex !== null)
                    break
            }
        }
        return { scheduleKey, shift, userId, dayIndex, shiftIndex, rowIndex }
    },
    deleteShiftDataWeekView: async (projectKey, scheduleKey, renderDateKey, shiftKey) => {
        console.table({ projectKey, scheduleKey, renderDateKey, shiftKey })
        var error = ''
        var success = true
        var doc = await db.doc(`projects/${projectKey}/weekview/${renderDateKey}`).get()
        if (!doc)
            return { success: false, error: 'can not find document' }

        const weekData = doc.data()
        let scheduleToUpdate = FireHelper.removeShiftFromWeekView(weekData, scheduleKey, shiftKey) //remove old shift
        await db.doc(`projects/${projectKey}/weekview/${renderDateKey}`).set({ [scheduleKey]: scheduleToUpdate }, { merge: true }).catch(err => {
            error = err
            success = false
            console.log('>> setShiftData err', err)
            captureException(err)
        })



        return { success, error }
    },

    updatetShiftDataWeekView: async (projectKey, scheduleKey, shiftKey, renderDateKey, newData, newUid) => {
        // console.log(' updatetShiftDataWeekView  ' + shiftKey, newUid)
        var error = ''
        var success = true
        var doc = await db.doc(`projects/${projectKey}/weekview/${renderDateKey}`).get()
        if (!doc)
            return { success: false, error: 'can not find document' }

        const weekData = doc.data()
        let schedule = { ...weekData[scheduleKey] }
        if (!schedule)
            return { success: false, error: 'can not find schedule' }



        let userId
        let dayIndex
        let rowIndex
        let shiftIndex = null
        let shift
        if (schedule && schedule.rows) {
            for (let r = 0; r < schedule.rows.length; r++) {
                var userRow = schedule.rows[r]
                userId = userRow.k
                //rowIndex = r
                for (let index = 0; index <= 7; index++) {
                    if (userRow.s[index] && userRow.s[index]) {
                        for (let k = 0; k < (userRow.s[index] || []).length; k++) {
                            const shiftItem = userRow.s[index][k];
                            //  console.log(shiftItem.k, shiftKey)
                            if (shiftItem.k === shiftKey) {
                                //    console.log('match!', shiftItem)
                                //  console.log('userRow.s[index][k]', userRow.s[index][k])
                                userRow.s[index].splice(k, 1)
                                // console.log('index', index)
                                // console.log('k', k)
                                dayIndex = index
                                shiftIndex = k
                                shift = shiftItem
                                break
                            }
                        }
                        if (shiftIndex !== null)
                            break
                    }
                }

                if (shiftIndex !== null)
                    break
            }
        }


        if (newData && newData.d) {
            dayIndex = moment(newData.d).utc().isoWeekday() + 1
            if (dayIndex > 7)
                dayIndex = 1
        }




        //TODO PROBLEM; HERE...i fwe move a shift more than once schedule.rows is empty 
        // if (schedule.rows) {
        rowIndex = schedule.rows.findIndex(userRow => userRow.k === newUid)

        if (!shiftIndex && rowIndex === -1)// shioft index is null = user have not saved the shift yet , but done a request
            shiftIndex = 0
        else if (!shiftIndex) // user exists but not the shift 
            shiftIndex = (schedule.rows[rowIndex].s[dayIndex] || []).length



        if (rowIndex === -1) { // user row dont exist
            schedule.rows.push({ k: newUid, s: { [dayIndex]: [{}] } })
            rowIndex = schedule.rows.length - 1
        }








        if (!schedule.rows[rowIndex].s[dayIndex]) // day dont exits 
            schedule.rows[rowIndex].s[dayIndex] = [{}]



        if (newData.t1 && newData.t2) // time is change reCreate lable
        {
            var dummyDate = moment().utc().format('YYYY-MM-DD')
            var startDate = dummyDate + ' ' + newData.t1 || shift.t1
            var stopDate = dummyDate + ' ' + newData.t2 || shift.t2

            newData.l = renderTimeLable(newData.la || shift.la, startDate, stopDate, window._AmPm)
        } else {
            // console.log('????')
        }
        schedule.rows[rowIndex].s[dayIndex][shiftIndex] = { ...shift, ...newData }

        await db.doc(`projects/${projectKey}/weekview/${renderDateKey}`).set({ [scheduleKey]: schedule }, { merge: true }).catch(err => {
            error = err
            success = false
            console.log('>> updatetShiftDataWeekView err', err)
            captureException(err)
        })


        return { success, error }

    },



    setShiftDataWeekView: async (projectKey, scheduleKey, renderDateKey, shiftKey, data, AmPm, dIndex, prevScheduleKey, scheduleName, isNewShift) => {
        var error = ''
        var success = true
        var doc = await db.doc(`projects/${projectKey}/weekview/${renderDateKey}`).get()
        if (!doc)
            return { success: false, error: 'can not find document' }

        const weekData = doc.data()
        const scheduleKey1 = (prevScheduleKey !== scheduleKey) ? prevScheduleKey : scheduleKey //schedule to remove from 
        const scheduleKey2 = scheduleKey //schedule to add to

        const userId = data.uid
        const newShift = Factory.compactShift(data, AmPm) //create new shift from data
        let scheduleToUpdate

        if (!isNewShift) {
            scheduleToUpdate = FireHelper.removeShiftFromWeekView(weekData, scheduleKey1, shiftKey) //remove old shift
            await db.doc(`projects/${projectKey}/weekview/${renderDateKey}`).set({ [scheduleKey1]: scheduleToUpdate }, { merge: true }).catch(err => {
                error = err
                success = false
                console.log('>> setShiftData err', err)
                captureException(err)
            })
        }

        //Add or update
        scheduleToUpdate = FireHelper.addOrUpdateShiftFromWeekView(weekData, scheduleKey2, dIndex, userId, newShift, renderDateKey, scheduleName)
        await db.doc(`projects/${projectKey}/weekview/${renderDateKey}`).set({ [scheduleKey2]: scheduleToUpdate }, { merge: true }).catch(err => {
            error = err
            success = false
            console.log('>> setShiftData err', err)
            captureException(err)
        })

        return { success, error }
    },

    setDayMetaDataAllWeek: async (projectKey, userKey, dateKey, availabilityIndex) => {
        var error = ''
        var success = true

        await db.doc(`projects/${projectKey}/shiftMetaData/${dateKey}`).set({
            avil: {
                [userKey]: {
                    1: availabilityIndex,
                    2: availabilityIndex,
                    3: availabilityIndex,
                    4: availabilityIndex,
                    5: availabilityIndex,
                    6: availabilityIndex,
                    7: availabilityIndex,
                }
            }
        }, { merge: true }).catch(err => {
            error = err
            success = false
            captureException(err)
        })

        if (error !== '')
            console.log('#setDayMetaData', error)

        //console.log('>>setShiftMetaData', error)
        return { success, error }
    },

    setDayMetaData: async (projectKey, userKey, dateKey, dayIndex, availabilityIndex) => {
        var error = ''
        var success = true

        await db.doc(`projects/${projectKey}/shiftMetaData/${dateKey}`).set({ avil: { [userKey]: { [dayIndex]: availabilityIndex } } }, { merge: true }).catch(err => {
            error = err
            success = false
            captureException(err)
        })

        if (error !== '')
            console.log('#setDayMetaData', error)

        //console.log('>>setShiftMetaData', error)
        return { success, error }
    },
    setShiftMetaData: async (projectKey, userKey, schedulaKey, dateKey, shiftKey, dType, data, isForAdmin, autoData, extraKeyData) => {
        var error = ''
        var success = true
        const _extraKeyData = extraKeyData ? `_${extraKeyData}` : ''
        const requestKey = `${shiftKey}_${dType}${_extraKeyData}`


        if (autoData) {
            if (autoData.delete === true) {
                await db.doc(`projects/${projectKey}/schedules/${schedulaKey}/events/${dateKey}`).set({ cacheKey: +new Date(), events: { [shiftKey]: firebase.firestore.FieldValue.delete() } }, { merge: true }).catch(err => {
                    error = err
                    success = false
                    captureException(err)
                })
            }
            else {
                await db.doc(`projects/${projectKey}/schedules/${schedulaKey}/events/${dateKey}`).set({ cacheKey: +new Date(), events: { [shiftKey]: autoData } }, { merge: true }).catch(err => {
                    error = err
                    success = false
                    console.log('#Err 235c1', err)
                    captureException(err)
                })
            }
        }
        else {

            // console.log(`projects/${projectKey}/shiftMetaData/${dateKey}`)
            // console.log({ [schedulaKey]: { [shiftKey]: { uk: userKey, [dType]: { ...data, adm: isForAdmin, k: requestKey } } } })
            await db.doc(`projects/${projectKey}/shiftMetaData/${dateKey}`).set({ [schedulaKey]: { [shiftKey]: { uk: userKey, [dType]: { ...data, adm: isForAdmin, k: requestKey } } } }, { merge: true }).catch(err => {
                error = err
                success = false
                console.log('#Err 235234541', err)
                captureException(err)
            })

        }
        if (error !== '')
            console.log('#setShiftMetaData', error)
        //console.log('>>setShiftMetaData', error)
        return { success, error }
    },
    setInviteMailBulk: async (projectKey, users, publicKey, companyName) => {
        var error = ''
        var success = false
        var batch = db.batch();

        for (let index = 0; index < users.length; index++) {
            const user = users[index];
            if (user) {
                var key = shortid.generate()
                //const userRef = firestore.doc(`projects/${projectKey}/mailbox/${key}`)
                const userRef = db.collection('projects').doc(projectKey).collection('mailbox').doc(key)
                batch.set(userRef, { key, user, companyName, publicKey, t: 'invite' })
            }

        }
        await batch.commit().then(() => {
            success = true;
        }).catch(err => {
            error = err
            console.log(`error #werf`, err)
            captureException(err)
        })

        toastr.success('Mails sent');
        //console.log('>>setInviteMail', error)
        //if (success)
        //  await FireHelper.addUserData(user)

        return { success, error }
    },


    setInviteSms: async (projectKey, user, publicKey, companyName) => {
        var error = ''
        var success = true
        var key = shortid.generate()
        await db.doc(`projects/${projectKey}/mailbox/${key}`).set({ key, user, companyName, publicKey, t: 'sms_invite' }).catch(err => {
            error = err
            success = false
            captureException(err)
        });


        return { success, error }
    },
    sendFeedbackMail: async (projectKey, user, publicKey, companyName) => {
        var error = ''
        var success = true
        var key = shortid.generate()
        //console.log({ key, user, companyName, publicKey, t: 'feedback' })
        //console.log('>>', `projects/${projectKey}/mailbox/${key}`)
        await db.doc(`projects/${projectKey}/mailbox/${key}`).set({ key, user, companyName, publicKey, t: 'feedback' }).catch(err => {
            error = err
            success = false
            console.log('err', err)
            captureException(err)
        });


        return { success, error }
    },
    setInviteMail: async (projectKey, user, publicKey, companyName) => {
        var error = ''
        var success = true
        var key = shortid.generate()
        await db.doc(`projects/${projectKey}/mailbox/${key}`).set({ key, user, companyName, publicKey, t: 'invite' }).catch(err => {
            error = err
            success = false
            captureException(err)
        });


        return { success, error }
    },


    // updateInAppUserMail: async (email, profile) => {
    //     const key = profile.inSysUserKey
    //     const projectKey = profile.projectKey
    //     var error = ''
    //     var success = true
    //     await db.doc(`projects/${projectKey}/users/userlist`).set({ [key]: { email }, saveKey: key }, { merge: true }).catch(err => {
    //         error = err
    //         success = false
    //     }

    //     );
    //     return { success, error }
    // },

    removeInAppUserMapping: async (curUser) => {
        const projectKey = curUser.projectKey
        var error = ''
        var success = true

        await db.doc(`projects/${projectKey}/users/userlist`).set({
            [curUser.key]: {
                role: 100,// firebase.firestore.FieldValue.delete(),
                phone: firebase.firestore.FieldValue.delete(),
                email: firebase.firestore.FieldValue.delete(),
                sysUserKey: firebase.firestore.FieldValue.delete(),
                providerId: firebase.firestore.FieldValue.delete(),

            }, saveKey: curUser.key
        }, { merge: true }).catch(err => {
            error = err
            success = false
            console.log('#err #¤453', err)
            captureException(err)
        });

        return { success, error }
    },
    setInAppUser: async (user, profile) => {

        return new Promise(async (resolve, reject) => {

            const projectKey = profile.projectKey
            let r = { success: true, msg: '' }
            try {
                delete user.newUser
                const updatedUser = { ...user, updatedAt: firebase.firestore.FieldValue.serverTimestamp() }
                await db.doc(`projects/${projectKey}/users/userlist`).set({ [updatedUser.key]: updatedUser }, { merge: true })
            } catch (error) {
                console.log(error);
                //toastr.error('Error', error);
                r.success = false;
                r.msg = error.message
                captureException(error);

            }

            resolve(r)

        })
    },
    updateInAppUser: async (data, profile) => {
        //console.log('updateInAppUser', data)
        const key = profile.inSysUserKey
        if (key) {
            const projectKey = profile.projectKey
            var error = ''
            var success = true

            if (data.phone) { // add email ?
                // console.log('chek', data.phone)
                await db.collection(`links`).where('list', 'array-contains', data.phone).limit(1)
                    .get().then(async docRef => {
                        //       console.log('docRef', docRef.empty)
                        if (docRef.empty) {
                            //         console.log('save new phone', data.phone)
                            // await db.doc(`projects/${projectKey}/users/userlist`).set({ [updatedUser.key]: updatedUser }, { merge: true })

                            await db.doc(`projects/${projectKey}/users/userlist`).set({ [key]: { ...data }, saveKey: key }, { merge: true }).catch(err => {
                                error = err
                                success = false
                                captureException(err)
                            })
                        } else {
                            error = 'dup-phone'
                            success = false
                        }
                    })
            }
            if (data.email) { // add email ?
                // console.log('chek', data.phone)
                await db.collection(`links`).where('list', 'array-contains', data.email).limit(1)
                    .get().then(async docRef => {
                        //       console.log('docRef', docRef.empty)
                        if (docRef.empty) {
                            //         console.log('save new phone', data.phone)
                            // await db.doc(`projects/${projectKey}/users/userlist`).set({ [updatedUser.key]: updatedUser }, { merge: true })

                            await db.doc(`projects/${projectKey}/users/userlist`).set({ [key]: { ...data }, saveKey: key }, { merge: true }).catch(err => {
                                error = err
                                success = false
                                captureException(err)
                            })
                        } else {
                            error = 'dup-email'
                            success = false
                        }
                    })
            }
            else {
                await db.doc(`projects/${projectKey}/users/userlist`).set({ [key]: { ...data }, saveKey: key }, { merge: true }).catch(err => {
                    error = err
                    success = false
                    captureException(err)
                })
            }



            //console.log(success, error)
            return { success, error }
        }
        success = false
        error = 'inSysUserKey is null'
        return { success, error }
    },
    removeUserPushToken: async (token) => {
        if (auth) {
            const user = auth.currentUser
            var error = ''
            var success = true
            await db.doc(`users/${user.uid}`).set({ token: firebase.firestore.FieldValue.arrayRemove(token) }, { merge: true }).catch(err => {
                error = err
                success = false
                captureException(err)

            });
            //console.log('>> removeUserPushToken', error)
            //if (success)
            //  await FireHelper.addUserData(user)
            return { success, error }
        }
        else
            return { success: false, error: 'no auth' }

    },
    setUserPushToken: async (token) => {
        if (auth && auth.currentUser) {
            const user = auth.currentUser

            var error = ''
            var success = true
            //console.log('add token', `users/${user.uid}`)
            await db.doc(`users/${user.uid}`).set({ token: firebase.firestore.FieldValue.arrayUnion(token) }, { merge: true }).catch(err => {
                error = err
                success = false
                captureException(err)

            });
            //console.log('>> setUserPushToken', error)
            //if (success)
            //  await FireHelper.addUserData(user)
            return { success, error }
        }
        else
            return { success: false, error: 'no auth' }

    },
    setUserToNewScheduleAccount: async (companyName, firstDay, AmPm) => {
        const user = auth.currentUser
        var error = ''
        var success = true
        console.log(`users/${user.uid}`)
        await db.doc(`users/${user.uid}`).set({ companyName, recreateUser: true, firstDay, AmPm }, { merge: true }).catch(err => {
            error = err
            success = false
            console.log(err)
            captureException(err)

        });
        //console.log('okej setUserPublicKey', error)
        //if (success)
        //  await FireHelper.addUserData(user)

        return { success, error }
    },
    setUserPublicKey: async (key) => {
        const user = auth.currentUser
        var error = ''
        var success = true

        await db.doc(`users/${user.uid}`).set({ publicKey: key }, { merge: true }).catch(err => {
            error = err
            success = false
            console.log(err)
            captureException(err)

        });
        //console.log('okej setUserPublicKey', error)
        //if (success)
        //  await FireHelper.addUserData(user)

        return { success, error }
    },

    bulkImportEmployees: async (userList, projectKey) => {
        var error = ''
        var success = false

        if (userList) {
            var batch = db.batch();
            const userRef = db.collection('projects').doc(projectKey).collection('users').doc('userlist')
            const projRef = db.doc(`projects/${projectKey}`)



            for (let index = 0; index < userList.length; index++) {
                const user = userList[index];
                if (user && user.key && user.name && user.isValid === true) {
                    var saveUser = { ...user, isActive: true }
                    delete saveUser.isValid
                    if (saveUser.phone === '')
                        delete saveUser.phone
                    if (saveUser.phone2 === '')
                        delete saveUser.phone2
                    batch.set(userRef, { [saveUser.key]: saveUser }, { merge: true })



                    //set cache
                    batch.set(projRef, { cache: { [saveUser.key]: { sk: saveUser.sysUserKey || '', n: saveUser.name || '', i: saveUser.image || '', a: saveUser.isActive } } }, { merge: true })



                }
            }
            await batch.commit().then(() => {
                success = true;
            }).catch(err => {
                error = err
                captureException(err)
            })
        }

        return { success, error }

    },
    addUserData: async (userData) => {
        const user = auth.currentUser
        var error = ''
        var success = true
        console.log(`users/${user.uid}`, userData)
        await db.doc(`users/${user.uid}`).set({ ...userData }, { merge: true }).catch(err => {
            error = err
            success = false
            captureException(err)
        });
        console.log('done!', success)
        return { success, error }
    },
    setUser: async (user) => {
        var error = ''
        var success = true
        await db.doc(`users/${user.uid}`).set({ ...user }).catch(err => {
            error = err
            success = false
            captureException(err)
        }

        );
        return { success, error }
    },

    creatUser: async (userData) => {
        const user = auth.currentUser
        var error = ''
        var success = true
        var newUser = { ...userData, key: user.uid }
        //console.log('newUser', newUser)
        await db.doc(`users/${user.uid}`).set(newUser).catch(err => {
            error = err
            success = false
            captureException(err);
        }

        );
        return { success, error, user: newUser }
    },
    setProject: async (projectKey, data) => {

        var error = ''
        var success = true
        ////console.log('setProject', data)
        await db.doc(`projects/${projectKey}`).set({ ...data }, { merge: true })
            .then(docRef => {
                toastr.success('', `Saved`)
            })
            .catch(err => {
                error = err
                success = false
                captureException(err)
            })

        return { success, error }
    },
    createProject: async (name) => {

        const key = shortid.generate();
        const newProject = { name, key, publicKey: shortid.generate(), enabled: true }
        var error = ''
        var success = true
            //console.log('createProject', newProject)
            //await db.doc(`projects/${key}`).set(newProject)
            .then(docRef => {
                //console.log('ss')
                toastr.success('Yes!', `Your new project was successfully created`)
            })
            .catch(err => {
                //console.log('ssss')
                error = err
                success = false
                captureException(err)
            })

        return { success, error, project: newProject }
    },
    setChatName: async (chatName, chatKey, currentUser) => {
        // //console.log('createChat')
        const projectKey = currentUser.projectKey
        var error = ''
        var success = true
        await db.doc(`projects/${projectKey}/messages/${chatKey}`).set(
            { newName: chatName },
            { merge: true }).catch(err => {
                error = err
                success = false
                captureException(err)
            });

        return { success, error }
    },

    deleteChat: async (chatKey, currentUser) => {
        //console.log('deleteChat')
        const projectKey = currentUser.projectKey
        var error = ''
        var success = true
        await db.doc(`projects/${projectKey}/messages/${chatKey}`).set(
            { delete: true },
            { merge: true }).catch(err => {
                error = err
                success = false
                captureException(err)
            });

        return { success, error }
    },
    modifyChat: async (partisipantsNames, chatKey, users, currentUser) => {
        //console.log('modifyChat')
        const projectKey = currentUser.projectKey
        var error = ''
        var success = true
        await db.doc(`projects/${projectKey}/messages/${chatKey}`).set(
            { p: users, pn: partisipantsNames, key: chatKey, reset: true },
            { merge: true }).catch(err => {
                error = err
                success = false
                captureException(err)
            });

        return { success, error }
    },
    createChat: async (chatName, partisipantsNames, chatKey, users, currentUser) => {
        //console.log('createChat')
        const projectKey = currentUser.projectKey
        var error = ''
        var success = true
        await db.doc(`projects/${projectKey}/messages/${chatKey}`).set(
            { p: users, n: chatName, pn: partisipantsNames, key: chatKey },
            { merge: true }).catch(err => {
                error = err
                success = false
                captureException(err)
            });

        return { success, error }
    },


    setSystemLogMsg: async (projectKey, userKey, msg) => {
        await db.doc(`projects/${projectKey}/messages/sys_${userKey}`).set(
            { p: [userKey], n: 'system', pn: '', key: `sys_${userKey}`, m: firebase.firestore.FieldValue.arrayUnion({ m: msg, d: moment().utc().unix() * 1000 }) },
            { merge: true }).catch(err => {
                //console.log('#Err 4g¤%#g44 ', err)
                // error = err
                // success = false
                captureException(err)
            }

            );
    },
    setChatMesage: async (cKey, msg, currentUser) => {
        //console.log('setChatMesage')
        const key = currentUser.inSysUserKey
        const role = currentUser.role || 0

        //const img = currentUser.extraData ? currentUser.extraData.image : ''
        // const name = currentUser.extraData ? currentUser.extraData.name : ''
        //const key = currentUser.inSysUserKey
        const projectKey = currentUser.projectKey
        var error = ''
        var success = true
        await db.doc(`projects/${projectKey}/messages/${cKey}`).set(
            { m: firebase.firestore.FieldValue.arrayUnion({ r: role, m: msg, uk: key, d: moment().utc().unix() * 1000 }) }, //,  
            { merge: true }).catch(err => {
                error = err
                success = false
                captureException(err)
            }

            );
        return { success, error }
    },

    setChatMesageRead: async (chanleKey, currentUser) => {
        //console.log('>>setChatMesageRead')
        var error = ''
        var success = true
        await db.doc(`users/${currentUser.key}`).set(
            { alerts: { m: { [chanleKey]: { m: 0 } } } },
            { merge: true }).catch(err => {
                error = err
                success = false
                captureException(err)
            }

            );
        return { success, error }
    },

    addCmd: async (cmdData, profile) => {
        const projectKey = profile.projectKey
        var error = ''
        var success = true
        var key = shortid.generate()
        await db.doc(`projects/${projectKey}/cmd/${key}`).set({ ...cmdData }, { merge: true }).catch(err => {
            error = err
            success = false
            captureException(err)
        });
        //console.log('done!', success)
        return { success, error }
    },
    addRemoveTask: async (taskKey, profile) => {

        const projectKey = profile.projectKey
        var error = ''
        var success = true
        await db.doc(`projects/${projectKey}/tasks/active`).set({ [taskKey]: firebase.firestore.FieldValue.delete() }, { merge: true }).catch(err => {
            error = err
            success = false
            captureException(err)
        })

        //console.log('done!', success)
        return { success, error }
    },
    addOrUpdateTask: async (taskData, profile) => {

        const projectKey = profile.projectKey
        var error = ''
        var success = true
        var key = taskData.key || shortid.generate()

        if (taskData.delete) {
            await db.doc(`projects/${projectKey}/tasks/active`).set({ [key]: firebase.firestore.FieldValue.delete() }, { merge: true }).catch(err => {
                error = err
                success = false
                captureException(err)
            })
        }
        else {
            await db.doc(`projects/${projectKey}/tasks/active`).set({ [key]: { ...taskData, key, updatedAt: firebase.firestore.FieldValue.serverTimestamp() } }, { merge: true }).catch(err => {
                error = err
                success = false
                captureException(err)
            })
        }
        //console.log('done!', success)
        return { success, error }
    },
    createInvoice: async (amount, reciptName, profile) => {
        const projectKey = profile.projectKey
        // const userId = user.email
        const roundenAmount = Math.ceil(Number(amount))
        var error = ''
        var success = true
        var key = shortid.generate()

        if (roundenAmount > 0) {
            await db.collection('invoices').doc(key).set({
                createDate: +new Date(),
                projectKey: projectKey,
                name: (reciptName || profile.email) || 'Online user',
                email: profile.email,
                credits: roundenAmount,
                items: [{
                    amount: 100,
                    currency: "USD",
                    description: 'Credits',
                    quantity: roundenAmount,

                }]
            })
                .then(() => {
                    console.log("Document successfully written!");
                })
                .catch((error) => {
                    console.error("Error writing document: ", error);
                });


        }

        //console.log('done!', success)
        return { success, error }
    },
}