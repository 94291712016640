import React, { Component } from "react";
import PropTypes from 'prop-types'
import { connect } from "react-redux";
import Image from 'semantic-ui-react/dist/commonjs/elements/Image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar as icoEmpty } from '@fortawesome/pro-duotone-svg-icons';
import memoizeOne from 'memoize-one';
import moize from "moize";

import Message from 'semantic-ui-react/dist/commonjs/collections/Message'
import Loader from 'semantic-ui-react/dist/commonjs/elements/Loader'
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon'
import moment from 'moment'

import { openModal } from "../../../core/redux/action/modalAction";
import ShiftUserInput from '../modal/ShiftUserInput'
import FirestoreListnerHelper from '../../../core/util/FirestoreListnerHelper'
import noUserImage from '../../../img/noimg.png'
//import Swipe from 'react-swipe-component'
import { renderDateAddDays, setRenderNow } from '../../../core/redux/action/viewstatAction'
import { isMobile } from 'react-device-detect';
import { getUserImage, toTop, getLocalDate, getDateKey } from '../../../core/util/helpers'
//import SwipeX from '../../common/component/SwipeX'

import { isElectron } from 'react-device-detect';
import Avatar from '../../common/component/Avatar';
// eslint-disable-next-line no-unused-vars
let ipcRenderer
// let electronMenu
// let electronRemote

if (isElectron) {
  ipcRenderer = window.require('electron').ipcRenderer
  // electronRemote = window.require('electron').remote;
  // electronMenu = electronRemote.Menu;
}

const modalKey = 'shiftuserinput'
class SchedulePublished extends Component {
  state = { schedulesList: [], isEmpty: true, slideAni: '' }
  // //static contextTypes = { store: PropTypes.object.isRequired }




  componentDidMount = () => {
    toTop()
    if (this.props.currentUser.isLoaded === true) {
      if (this.props.currentUser.role >= 100)
        FirestoreListnerHelper.setWeekViewListner(this.context, this.props.renderDate)
    }
  }
  componentDidUpdate = async (prevProps, prevState, snapshot) => {
    //console.log('this.props.renderDateKey', this.props.renderDateKey)
    // if (prevProps.renderDateKey !== this.props.renderDateKey) {
    if (this.props.currentUser.role >= 100)
      FirestoreListnerHelper.setWeekViewListner(this.context, this.props.renderDate)
    // }
    // else {
    //console.log('omponent did update =  no change')
    // }

  }


  onOpenModal = (data) => () => {
    this.props.onOpenModal(modalKey, data)
  }
  // openContextMenu = (data) => (e) => {

  //   e.preventDefault();

  //   //console.log('data', data)

  //   if (electronMenu && electronRemote) {
  //     const contextMenu = electronMenu.buildFromTemplate([
  //       {
  //         label: window._getText('Askforthisshift'),
  //         click: () => this.onOpenModal({ ...data, activeIndex: 1 }),
  //       }
  //     ])
  //     contextMenu.popup(electronRemote.getCurrentWindow());
  //   }
  //   else
  //     this.onOpenModal(data)
  // }

  renderCells = (shifts, todayStr, scheduleKey) => {
    var shift = shifts[todayStr]

    const _tdClass = this.tdClass(todayStr)

    if (shift) {


      return (
        <td className={`two wide scheduelCell ${_tdClass}`}  >
          <div className='flexDiv'>
            {shift.map(x => {

              return (
                // onClick={this.openContextMenu(x)}
                <div key={x.k} className={'x' + (x.sik === true ? '' : x.c) + ' cellMulti ui label'}>
                  <div style={{ color: x.sik ? 'silver' : '' }} >{x.l}</div>
                  {x.co && <span >{x.co}</span>}
                  {x.sik && <span> <Icon name='bed' size='small' /></span>}

                </div>
              )
            })}
          </div>
        </td>
      )
    }
    return (
      <td className={`two wide scheduelCell ${_tdClass}`}></td>
    )
  }

  renderHeaders = memoizeOne((renderDayDate, weeksToShow) => {
    //  const today = moment.utc(todayDate).format('dd\u00A0DD')
    //console.log('>>getHeader memoizeOne', renderDayDate)
    // console.log('>>today', today)
    // // console.log('---------')

    const css = { color: 'rgba(153,0,0,1)' }    /// DATE BUG do wee need to hace UTC in the display date ?

    let ddToday = moment(new Date())
    let r = []
    for (let index = 0; index < weeksToShow; index++) {
      let _renderDayDate = moment(renderDayDate).clone()

      _renderDayDate.add(index, 'weeks')

      let dd1 = moment(_renderDayDate)
      let dd2 = moment(_renderDayDate).add(1, 'days')
      let dd3 = moment(_renderDayDate).add(2, 'days')
      let dd4 = moment(_renderDayDate).add(3, 'days')
      let dd5 = moment(_renderDayDate).add(4, 'days')
      let dd6 = moment(_renderDayDate).add(5, 'days')
      let dd7 = moment(_renderDayDate).add(6, 'days')

      // let d1 = `${window._getText(dd1.format("dd"))} ${dd1.format("DD")}`
      // let d2 = `${window._getText(dd2.format("dd"))} ${dd2.format("DD")}`
      // let d3 = `${window._getText(dd3.format("dd"))} ${dd3.format("DD")}`
      // let d4 = `${window._getText(dd4.format("dd"))} ${dd4.format("DD")}`
      // let d5 = `${window._getText(dd5.format("dd"))} ${dd5.format("DD")}`
      // let d6 = `${window._getText(dd6.format("dd"))} ${dd6.format("DD")}`
      // let d7 = `${window._getText(dd7.format("dd"))} ${dd7.format("DD")}`



      // if (ddToday.isSame(dd1, "day"))
      //   d1 = <span style={css}>{dd1.format("dd")} {dd1.format("DD")}</span>
      // else if (ddToday.isSame(dd2, "day"))
      //   d2 = <span style={css}>{dd2.format("dd")} {dd2.format("DD")}</span>
      // else if (ddToday.isSame(dd3, "day"))
      //   d3 = <span style={css}>{dd3.format("dd")} {dd3.format("DD")}</span>
      // else if (ddToday.isSame(dd4, "day"))
      //   d4 = <span style={css}>{dd4.format("dd")} {dd4.format("DD")}</span>
      // else if (ddToday.isSame(dd5, "day"))
      //   d5 = <span style={css}>{dd5.format("dd")} {dd5.format("DD")}</span>
      // else if (ddToday.isSame(dd6, "day"))
      //   d6 = <span style={css}>{dd6.format("dd")} {dd6.format("DD")}</span>
      // else if (ddToday.isSame(dd7, "day"))
      //   d7 = <span style={css}>{dd7.format("dd")} {dd7.format("DD")}</span>


      let d1 = `${window._getText(dd1.format("dddd").toLowerCase()).substr(0, 2)}  ${dd1.format("DD")}`
      let d2 = `${window._getText(dd2.format("dddd").toLowerCase()).substr(0, 2)}  ${dd2.format("DD")}`
      let d3 = `${window._getText(dd3.format("dddd").toLowerCase()).substr(0, 2)}  ${dd3.format("DD")}`
      let d4 = `${window._getText(dd4.format("dddd").toLowerCase()).substr(0, 2)} ${dd4.format("DD")}`
      let d5 = `${window._getText(dd5.format("dddd").toLowerCase()).substr(0, 2)} ${dd5.format("DD")}`
      let d6 = `${window._getText(dd6.format("dddd").toLowerCase()).substr(0, 2)} ${dd6.format("DD")}`
      let d7 = `${window._getText(dd7.format("dddd").toLowerCase()).substr(0, 2)}  ${dd7.format("DD")}`



      if (ddToday.isSame(dd1, "day"))
        d1 = <span style={css}>{window._getText(dd1.format("dddd").toLowerCase()).substr(0, 2)}  {dd1.format("DD")}</span>
      else if (ddToday.isSame(dd2, "day"))
        d2 = <span style={css}>{window._getText(dd2.format("dddd").toLowerCase()).substr(0, 2)}  {dd2.format("DD")}</span>
      else if (ddToday.isSame(dd3, "day"))
        d3 = <span style={css}>{window._getText(dd3.format("dddd").toLowerCase()).substr(0, 2)}  {dd3.format("DD")}</span>
      else if (ddToday.isSame(dd4, "day"))
        d4 = <span style={css}>{window._getText(dd4.format("dddd").toLowerCase()).substr(0, 2)}   {dd4.format("DD")}</span>
      else if (ddToday.isSame(dd5, "day"))
        d5 = <span style={css}>{window._getText(dd5.format("dddd").toLowerCase()).substr(0, 2)}  {dd5.format("DD")}</span>
      else if (ddToday.isSame(dd6, "day"))
        d6 = <span style={css}>{window._getText(dd6.format("dddd").toLowerCase()).substr(0, 2)}  {dd6.format("DD")}</span>
      else if (ddToday.isSame(dd7, "day"))
        d7 = <span style={css}>{window._getText(dd7.format("dddd").toLowerCase()).substr(0, 2)}  {dd7.format("DD")}</span>






      r.push(
        <React.Fragment key={`key_${index}`}>
          {index === 0 &&
            <td className='two wide scheduelHeaderCell ' style={styles.s13} >

            </td>
          }


          <td className='two wide scheduelHeaderCell  noSelect' style={styles.s2}>{d1}
            <span style={styles.s3}>w.{_renderDayDate.format("ww")}</span>
          </td>
          <td className='two wide scheduelHeaderCell  noSelect textCap'>{d2}</td>
          <td className='two wide scheduelHeaderCell  noSelect textCap'>{d3}</td>
          <td className='two wide scheduelHeaderCell  noSelect textCap'>{d4}</td>
          <td className='two wide scheduelHeaderCell  noSelect textCap'>{d5}</td>
          <td className='two wide scheduelHeaderCell  noSelect textCap'>{d6}</td>
          <td className='two wide scheduelHeaderCell  noSelect textCap'>{d7}</td>
        </React.Fragment>

      )

    }



    return (
      <tr style={styles.s4}>
        {r}
      </tr >
    )


  })

  mergeUserShifts = (userKey, data) => {

  }

  getMultiRowData = (schedule, data, doRenderHead, multiRowData, index) => {
    

// TODO id openShifts is empty dont show it 

    if (schedule.unPublish !== true)
      for (var user of schedule.rows) {
        if (!multiRowData[user.k])
          multiRowData[user.k] = { s: {}, k: user.k, u: user.u }


        for (var key in user.s) {
          var _key = Number(key) + (index * 7)

          if (!multiRowData[user.k].s[_key])
            multiRowData[user.k].s[_key] = []

          multiRowData[user.k].s[_key] = [...multiRowData[user.k].s[_key], ...user.s[key]]
        }
      }
    return multiRowData

  }


  toggleFullScreen = () => {
    var doc = window.document;
    var docEl = doc.documentElement;

    var requestFullScreen = docEl.requestFullscreen || docEl.mozRequestFullScreen || docEl.webkitRequestFullScreen || docEl.msRequestFullscreen;
    var cancelFullScreen = doc.exitFullscreen || doc.mozCancelFullScreen || doc.webkitExitFullscreen || doc.msExitFullscreen;

    if (!doc.fullscreenElement && !doc.mozFullScreenElement && !doc.webkitFullscreenElement && !doc.msFullscreenElement) {
      requestFullScreen.call(docEl);
    }
    else {
      cancelFullScreen.call(doc);
    }
  }

  setRenderDate = (d) => () => {
    this.props.renderDateAddDays(d)
  }

  gotoToday = () => {
    this.props.setRenderNow()
  }


  onSwipedLeft = () => {
    this.setState({ slideAni: 'slideAniLeft' })
    this.props.renderDateAddDays(7)

  }
  onSwipedRight = () => {
    this.setState({ slideAni: 'slideAniRight' })
    this.props.renderDateAddDays(-7)
  }
  animationEnd = (e) => {
    if (e.target.className === 'slideAniLeft') {
      this.setState({ slideAni: '' })
    }
    else if (e.target.className === 'slideAniRight') {
      this.setState({ slideAni: '' })
    }
  }

  xtdClass = (dayIndex) => {
    var _dayIndex = Number(dayIndex)
    if (window._firstDay === 1)
      return (_dayIndex % 7 === 6 || _dayIndex % 7 === 5) ? 'stripes' : ''
    else
      return (_dayIndex % 7 === 6 || _dayIndex % 7 === 0) ? 'stripes' : ''

  }

  tdClass = moize((nr) => {
    var index = Number(nr)
    if (window._firstDay === 0 && (index % 7 === 6 || index % 7 === 0))
      return 'stripes'
    else if (window._firstDay === 1 && (index % 7 === 0 || index % 7 === 6))
      return 'stripes'
    else return null
  })

  renderHtml = (data2, projectKey, sKey, weeksToShow) => {

    const { project } = this.props




    const renderdDivToIgnor = {}
    const data = Object.keys(data2).map(x => data2[x]) //create a array
    var rObj = (<React.Fragment>

      <React.Fragment>



        {(data || []).map(row => {

          //if (row.k === this.props.inSysUserKey || this.props.isUnknown === true)
          if (row.k.startsWith('div_')) {
            var name = row.u
            if (!renderdDivToIgnor[name]) {

              if (!renderdDivToIgnor[name]) {
                renderdDivToIgnor[name] = 1
              }

            }
            else {
              return null
            }





            //console.log('row', row)
            return (
              <tr key={row.k}>
                <td rowSpan='8' style={{ padding: '0px', paddingTop: '10px', }} >
                  <span style={{ fontSize: '13px', fontWeight: 'bold' }}>{row.u}</span>
                </td>
              </tr>
            )
          }
          else {
            var user = project.cache[row.k] || { i: '', n: '#unknown', a: false, sk: '' } //
            //Show open shifts
            if (row.k === 'openshifts') {
              user.a = true
              user.n = window._getText('schedual.lbl-openshifts')
            }

            if (user.a === false)
              return null


            return (
              <tr key={row.k} style={{ height: '30px' }}>
                <React.Fragment>

                  <td
                    // draggable="false"
                    className='two wide scheduelCell'
                    style={{ borderLeft: 'none', width: '16%' }}
                  >

                    <div alt={user.n} title={user.n} className='schedualUsername' style={{ display: 'inline', margin: '0px', padding: '0px', touchAction: 'none', fontSize: '14px', fontWeight: 'bold' }}  >
                      {row.k === 'openshifts' ?
                        <React.Fragment>
                          <div style={{ fontWeight: 'bold' }} className='divUsername'>{user.n}</div>
                        </React.Fragment>
                        :
                        <React.Fragment>
                          <div className='divUsername'>
                          <Avatar 
                            name={user.n}  
                            userKey={row.k} 
                            imgUrl={user.i}>
                          </Avatar>     
                            <div>{user.n}</div>
                          </div>
                        </React.Fragment>
                      }

                    </div>
                  </td>


                  {[...Array(weeksToShow).keys()].map((nr, index) => {

                    //TODO: we need to fix this, !!!! its for the sun -> sat weeks

                    if (this.props.firstDayOfWeekNumber === 0)
                      return (<React.Fragment key={`key_${nr}`}>
                        {this.renderCells(row.s, (7 + (index * 7)), sKey)}
                        {this.renderCells(row.s, (1 + (index * 7)), sKey)}
                        {this.renderCells(row.s, (2 + (index * 7)), sKey)}
                        {this.renderCells(row.s, (3 + (index * 7)), sKey)}
                        {this.renderCells(row.s, (4 + (index * 7)), sKey)}
                        {this.renderCells(row.s, (5 + (index * 7)), sKey)}
                        {this.renderCells(row.s, (6 + (index * 7)), sKey)}

                      </React.Fragment>)

                    // this.props.firstDayOfWeekNumber === 1
                    return (<React.Fragment key={`key_${nr}`}>
                      {this.renderCells(row.s, (1 + (index * 7)), sKey)}
                      {this.renderCells(row.s, (2 + (index * 7)), sKey)}
                      {this.renderCells(row.s, (3 + (index * 7)), sKey)}
                      {this.renderCells(row.s, (4 + (index * 7)), sKey)}
                      {this.renderCells(row.s, (5 + (index * 7)), sKey)}
                      {this.renderCells(row.s, (6 + (index * 7)), sKey)}
                      {this.renderCells(row.s, (7 + (index * 7)), sKey)}

                    </React.Fragment>)


                  })}
                </React.Fragment>
              </tr>
            )
          }


        })}




      </React.Fragment>
      {/* } */}
    </React.Fragment>)

    return rObj

  }




  render() {
    const { currentScheduelKey, isDataLoaded, weeksToShow, projectKey } = this.props
    const rDate = this.props.renderDate.clone()
    let renderData = []


    var multiRowData = {}
    var sKey = ''

    for (let index = 0; index < weeksToShow; index++) {
      var _rDate = rDate.clone().utc()
      var dateKey = getDateKey(_rDate.add(index * 7, 'days'))
      var scheduleWeekData = this.props.scheduleWeekData[dateKey] || {}// this.props.fireData['weekview_' + this.props.renderDateKey] || {}


      if (scheduleWeekData) {
        renderData.push(Object.keys(scheduleWeekData).map((key) => {
          if (index === 0) {
            //get schdeule key and more.... We can do this better!
            sKey = scheduleWeekData[key].sKey
          }


          if (currentScheduelKey === key) {
            this.getMultiRowData(scheduleWeekData[key], _rDate, index === 0, multiRowData, index) //TODO: split this so we can loop the body whit out the head!
            return null
          }
        }))
      }
    }

    renderData = renderData.filter(x => x) // remove empty stuff , DO we need this in v2 ? 
    var gaHemData = this.renderHtml(multiRowData, projectKey, sKey, weeksToShow)


    return (

      <table

        onClick={() => { alert('This is the published schedule\nGo to work view to make changes') }} //LANG:

        style={styles.tbl}
        className="ui very basic table tableBody unstackable tblScheduel">
        <thead className='fixed tblSchedualHead' >
          {this.renderHeaders(rDate.format('YYYY-MM-DD'), weeksToShow)}
        </thead>

        {(Object.keys(multiRowData).length < 1) ?
          <tbody>
            <tr>
              <td>

                <div style={{ textAlign: 'center', color: 'rgba(0,0,0,0.6)', height: '100%', paddingTop: '150px' }}>
                  <FontAwesomeIcon icon={icoEmpty} style={{ fontSize: '150px', color: 'rgba(0,0,0,0.3)', }}></FontAwesomeIcon>
                  <br />
                  No schedule is published
           </div>
              </td>
            </tr>
          </tbody>
          :
          <tbody style={{ display: 'block', overflow: 'scroll', height: 'calc(100vh -  130px)', }}  >
            {gaHemData}
            <tr><td style={{ height: 50 }}>&nbsp;</td></tr>
          </tbody>
        }

      </table>

    )
  }
}


const mapStateToProps = (state, ownProps, ownState) => {


  const inSysUserKey = state.firebase.profile.inSysUserKey
  const firstDayOfWeekNumber = Number(state.firebase.profile.firstDay) //sunday 0 , monday 1


  var isUnknown = true
  if (inSysUserKey)
    isUnknown = false;



  return ({
    isDataLoaded: state.firestore.status.requested[`weekview/${state.viewstate.renderDateKey}`],

    // isDataLoaded: state.firestore.status.requested[`weekview_${state.viewstate.renderDateKey}`],
    renderDate: state.viewstate.renderDate,
    renderDateKey: state.viewstate.renderDateKey,
    projectKey: state.firebase.profile.projectKey,
    project: state.firestore.data.project,
    fireRequested: state.firestore.status.requested,
    fireData: state.firestore.data,

    isLandscape: state.viewstate.isLandscape,
    currentUser: state.firebase.profile,
    firstDayOfWeekNumber,
    isUnknown,
    inSysUserKey,

  });
}
const mapActionsFromProps = {
  onOpenModal: openModal,
  renderDateAddDays,
  setRenderNow
}
export default connect(
  mapStateToProps,
  mapActionsFromProps
)(SchedulePublished);


const styles = {
  //s1: { border: 'none', borderBottom: 'solid 1px rgba(34, 36, 38, 0.1)', width: (16 / weeksToShow) + '%' },
  s2: { position: 'relative' },
  s3: { position: 'absolute', top: 3, left: 8, color: 'blueviolet', opacity: .9 },
  s4: { height: '40px', border: 'solid 1px transparent', borderBottom: 'solid 1px rgba(34, 36, 38, 0.1)', paddingRight: '6px' },
  s13: { border: 'none', width: '16%' },
  tbl: { minHeight: '100%', marginTop: '0px', marginBottom: '70px', }


}//jens