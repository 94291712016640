import React, { Component } from 'react'
import { Button, Icon } from "../../common/component/Button";
import { closeModal } from "../../../core/redux/action/modalAction";
import { connect } from "react-redux";
import { toastr } from 'react-redux-toastr'
import { FireHelper } from '../../../core/util/FireHelper'
import shortid from 'shortid'
import ModalFrame from './ModalFrame'
import object from 'lodash/object'
import { parsePhoneNumberFromString } from 'libphonenumber-js'

import { isElectron } from 'react-device-detect';


let ipcRenderer

if (isElectron) {
    ipcRenderer = window.require('electron').ipcRenderer


}


const byPropKey = (propertyName, value) => () => ({
    [propertyName]: value,
});
const modalKey = 'userBulkImport'

export class UserBulkImport extends Component {

    getMacContacts = (event, arg) => {
        console.log('>>> IN MSG getMacContacts-reply', arg.m)

        var str = ''
        if (arg.d) {
            arg.d.forEach(contact => {
                if (contact.n) {
                    if (contact.e)
                        str += contact.n + ',' + contact.e + '\n'
                    else
                        str += contact.n + '\n'
                }

            });
        }

        this.setState({ tbValue: str }, this.saveListUser)


    }
    componentWillUnmount = () => {
        //        console.log('componentWillUnmount>getMacContacts')

        if (ipcRenderer)
            ipcRenderer.removeListener('getMacContacts-reply', this.getMacContacts)

    }

    componentDidMount = () => {
        // console.log('componentDidMount>>>>>>>>>>>>>>>>>>getMacContacts')
        // console.log('ipcRenderer', ipcRenderer)
        if (ipcRenderer)
            ipcRenderer.on('getMacContacts-reply', this.getMacContacts)

    }

    validateEmail(email) {
        // var re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        // return re.test(String(email).toLowerCase());
        //return true
        return email.length > 5 && email.indexOf('@') > 0;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        //on open
        if (prevProps.isOpen === false && this.props.isOpen === true) {
            this.setState({ isLoading: false, valueName: this.props.sName, isNew: (this.props.sKey === null), error: (this.props.sName < 1) })
        }
    }

    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            error: true,
            tbValue: '',
            users: []
        };
    }
    onCloseModal = () => {
        this.setState({ isLoading: false, })
        this.props.onCloseModal(modalKey)
    }

    onClickDelete = () => {
        this.setState({ isLoading: true, tbValue: '', fixedUsers: [] })
        // doShit.then(() => {
        //     this.setState({ isLoading: false })
        //     this.onCloseModal()
        // })

    }


    onClickSave = () => {


        if (this.props.currentUser && this.props.currentUser.email === 'demo@timeto.work') {
            toastr.success('Demo account', `No saves are made`);
            console.log('Demo account = exit')
            this.onCloseModal()
            return
        }


        this.setState({ isLoading: true })
        var success = true
        var userList = [...this.state.fixedUsers || []].map(async user => {
            if (user) {
                var isOK = true
                if (user.email.length > 0) {
                    var isEmailFree = await FireHelper.isLinkFree(user.email)
                    isOK = isEmailFree.success

                }


                if (isOK === true && user.phone.length > 0) {
                    console.log('test if its free')
                    var isPhoneFree = await FireHelper.isLinkFree(user.phone)
                    isOK = isPhoneFree.success

                }

                if (isOK)
                    return user
                else
                    success = false
            }
            else
                success = false
        })

        console.log('userList', userList)
        userList = userList.filter(u => u)
        Promise.all(userList).then(newList => {
            console.log(newList)
            FireHelper.bulkImportEmployees(newList, this.props.project.key)
            this.setState({ isLoading: false, tbValue: '', fixedUsers: [] })

            if (success)
                toastr.success('Import succeeded');
            else
                toastr.error('Import partial succeeded');


            this.props.onCloseModal(modalKey)
        })

    }

    saveListUser = () => {
        // var userList = this.props.userList
        var rawData = this.state.tbValue || ''
        var users = rawData.split('\n') || [];
        var tempCacheDoExist = {}
        var fixedUsers = users.map(row => {
            if (!row)
                return (null)

            var userRaw = row.split(',')

            var user = {}
            user.key = shortid.generate()
            user.name = (userRaw[0] || (row || '')).trim()
            user.email = (userRaw[1] || '').trim()
            if (user.email.indexOf('@') === -1) {
                user.phone = user.email
                user.email = ''
            }
            else
                user.phone = (userRaw[2] || '').trim()

            if (!user.phone.startsWith('+'))
                user.phone = '+' + (window._calling_code || '') + user.phone

            if (user.phone) {
                var parsedNumber = parsePhoneNumberFromString(user.phone)
                if (parsedNumber) {
                    user.phone = parsedNumber.number
                }
                else
                    user.phone = ''
            }


            user.isValid = false

            if (user.email !== '' || user.phone !== '') {

                if (user.email !== '') {
                    if (user.name && user.email) {
                        if (user.name.length > 1 && this.validateEmail(user.email))
                            user.isValid = true
                    }

                    let dupUserKey = object.findKey(this.props.userList, (u) => {
                        if (!u)
                            return false
                        return u.email === user.email
                    })

                    if (dupUserKey)
                        user.isValid = false



                    //onlye add one 
                    if (tempCacheDoExist[user.email])
                        user.isValid = false


                    tempCacheDoExist[user.email] = true

                }
                if (user.phone !== '') {
                    user.isValid = true

                    let dupPhoneKey = object.findKey(this.props.userList, (u) => {
                        if (!u)
                            return false
                        return u.phone === user.phone
                    })

                    if (dupPhoneKey)
                        user.isValid = false

                    //onlye add one 
                    if (tempCacheDoExist[user.phone])
                        user.isValid = false

                    tempCacheDoExist[user.phone] = true
                }




            }
            else {
                if (user.name && user.name.length > 1)
                    user.isValid = true

            }




            return user

        })

        this.setState({ fixedUsers })


    }

    listUser = () => {

        var fixedUsers = this.state.fixedUsers || []
        return fixedUsers.map(user => {
            if (!user)
                return null

            return (
                <div key={user.key} style={{ display: 'flex' }}>
                    <div >
                        {user.isValid &&
                            <Icon name='check' color='green' />
                        }
                        {!user.isValid &&
                            <Icon name='close' color='red' />
                        }
                    </div>
                    <div>
                        {user.name && <React.Fragment><Icon name='user' />{user.name}</React.Fragment>}
                        {user.email && <React.Fragment><Icon style={{ paddingLeft: '4px' }} name='mail' />{user.email}</React.Fragment>}
                        {user.phone && <React.Fragment><Icon style={{ paddingLeft: '4px' }} name='phone' />{user.phone}</React.Fragment>}
                        {/* {user.phone2 && <React.Fragment><Icon style={{ paddingLeft: '4px' }} name='phone' />{user.phone2}</React.Fragment>} */}
                    </div>
                </div>
            )
        }
        )
    }

    // fakeData = () => {
    //onClick={this.fakeData}
    //     var data = ''
    //     for (let index = 0; index < 10; index++) {
    //         data += faker.name.findName() + ', testma' + index + '@binkmail.com\n'
    //     }
    //     this.setState({ tbValue: data });
    // }

    onImportFromMac = () => {
        if (isElectron) {
            ipcRenderer.send('getMacContacts', true)
        }
    }

    render() {

        return (


            <ModalFrame name='userBulkImport' dimmer='inverted' open={this.props.isOpen} className='modalAniSlideUp' onClickClose={this.onCloseModal} >
                <ModalFrame.Header>
                </ModalFrame.Header>
                <ModalFrame.Content>
                    <React.Fragment>
                        <h2 style={{ margin: '0px' }}>{window._getText('user.bulkimport')}</h2>
                        <div style={{ display: 'flex', }}>


                            <div style={{ width: '50%', height: 'cacl(100% - 100px)' }}>
                                <label>{window._getText('user.bulkTxt')} </label><br />
                                <textarea style={{
                                    resize: 'none', width: '100%', height: '350px', minHeight: '350px', boxSizing: 'border-box', lineHeight: '20px',
                                    border: '1px solid rgba(34,36,38,.15)',
                                    outline: 'none',
                                    padding: '4px',
                                    color: 'rgba(0,0,0,.87)',
                                    borderRadius: '.28571429rem',
                                    boxShadow: 'inset 0 0 0 0 transparent',
                                    transition: 'color .1s ease,border-color .1s ease',

                                }} placeholder={window._getText('user.bulkTxt2')}
                                    value={this.state.tbValue} onChange={event => { this.setState(byPropKey('tbValue', event.target.value), () => this.saveListUser()) }}
                                />

                            </div>
                            <div style={{ paddingLeft: '10px', height: '370px', overflow: 'auto', width: '50%', paddingTop: '20px' }}>
                                {this.listUser()}
                            </div>
                        </div>

                    </React.Fragment>
                </ModalFrame.Content>
                <ModalFrame.Actions>
                    <div style={{ display: 'flex' }}>

                        {/* {isElectronApp &&
                            <Button content={'Get contact from your Mac'} onClick={this.onImportFromMac} loading={this.state.isLoading} disabled={this.state.isLoading || this.state.error} />
                        } */}

                        <Button icon='checkmark' style={{ marginLeft: 'auto', }} content={window._getText('import')} onClick={this.onClickSave} loading={this.state.isLoading} disabled={this.state.isLoading || this.state.error} />
                    </div>
                </ModalFrame.Actions>
            </ModalFrame>)

    }
}


const mapActions = {
    onCloseModal: closeModal,
};

const mapStateToProps = state => {

    var userList = {};

    if (state.firestore && state.firestore.data && state.firestore.data.users) {
        userList = state.firestore.data.users.userlist;
    }

    return {
        userList: userList,
        project: state.firestore.data.project,
        isOpen: state.modal.modals[modalKey].open || false,
        data: state.modal.modals[modalKey].data || {},
        currentUser: state.firebase.profile,

    };
};
export default connect(
    mapStateToProps,
    mapActions
)(UserBulkImport);



