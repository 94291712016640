
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

let isReactNative = false


// import 'firebase/functions';
// import 'firebase/storage';
// import 'firebase/database';


//import 'firebase/messaging';
//const cors = require('cors')({ origin: true });

// eslint-disable-next-line
const devConfig = {
    apiKey: "AIzaSyCKSc7auF6aHqcVGSbDbeGjdbv9sXp-XTc",
    authDomain: "simpleschedule-9ae21.firebaseapp.com",
    databaseURL: "https://simpleschedule-9ae21.firebaseio.com",
    projectId: "simpleschedule-9ae21",
    storageBucket: "simpleschedule-9ae21.appspot.com",
    messagingSenderId: "328636915454",
    timestampsInSnapshots: true,
    experimentalTabSynchronization: true,
    imgBase: 'us-central1-simpleschedule-9ae21.cloudfunctions.net',
    appId: "1:328636915454:web:fb93c058252e8a26a00faf",
    measurementId: "G-9Q13TWPZQR" //TODO change this to dev id

}

const prodConfig = {
    apiKey: "AIzaSyClVenkfR062MtZQJUR-zvK_j42s1boFrk",
    authDomain: "timetowork-prod.firebaseapp.com",
    databaseURL: "https://timetowork-prod.firebaseio.com",
    projectId: "timetowork-prod",
    storageBucket: "timetowork-prod.appspot.com",
    messagingSenderId: "580487839902",
    timestampsInSnapshots: true,
    experimentalTabSynchronization: true,
    imgBase: 'us-central1-timetowork-prod.cloudfunctions.net',
    appId: "1:580487839902:web:e24c449c267e769ba6fb8f",
    measurementId: "G-9Q13TWPZQR"
};
const stagingConfig = {
    apiKey: "AIzaSyBAiZs9L562ohBJU3kBlMdnEPFcfD2MHEY",
    authDomain: "timetowork-staging.firebaseapp.com",
    databaseURL: "https://timetowork-staging.firebaseio.com",
    projectId: "timetowork-staging",
    storageBucket: "timetowork-staging.appspot.com",
    messagingSenderId: "1039703131188",
    timestampsInSnapshots: true,
    experimentalTabSynchronization: true,
    imgBase: 'us-central1-timetowork-staging.cloudfunctions.net',
    measurementId: "G-9Q13TWPZQR" //TODO change this to stage id

};

/// *** Dont change lines below *** ///
/// *** thes are auto replaces in the mobile app *** ///

const ENV = process.env.REACT_APP_ENV

/// *** ****************************** *** ///
/// *** ****************************** *** ///

const config = ENV === 'production'
    ? prodConfig
    : ENV === 'staging' ? stagingConfig : devConfig;

if (ENV !== 'production') {
    console.log('**CONFIG**', ENV)
    console.log('**CONFIG**', config.authDomain)
}
window._storageBucket = config.storageBucket
window._iapUrl = config.imgBase
window._messagingSenderId = config.messagingSenderId

console.log('isReactNative', isReactNative)
var db;
if (!firebase.apps.length) {
    if (ENV !== 'production') {
        console.log('INIT FIREBASE', firebase.SDK_VERSION)
        console.log('Config', ENV)

    }

    firebase.initializeApp(config);
    if (ENV === 'production') {
        try {
            if (isReactNative === false) {
                console.log('start init analytics')
                import('firebase/analytics').then(() => {
                    console.log('init analytics')
                    firebase.analytics();
                });
            }

        } catch (error) {
            console.log('#err annalytics', error)
        }
    }

    db = firebase.firestore()
    firebase.firestore.setLogLevel("error");
    // firebase.database.enableLogging(function (message) {
    //     console.log("[FIREBASE]", message);
    // });
    // db.settings({ timestampsInSnapshots: true })
    var conf = {}
    if (window.localStorage)
        conf = { synchronizeTabs: true }


    db.enablePersistence(conf).then(() => {
        if (ENV !== 'production') {
            console.log("Persistence is enabled");
        }
    }).catch(function (err) {
        console.log('enablePersistence', err)
        // alert( err)
        if (err.code === 'failed-precondition') {

            // Multiple tabs open, persistence can only be enabled
            // in one tab at a a time.
            // ...
        } else if (err.code === 'unimplemented') {
            // The current browser does not support all of the
            // features required to enable persistence
            // ...
        }
    });



}
//firebase.firestore.setLogLevel('debug')
export default firebase;
const auth = firebase.auth();
auth.useDeviceLanguage();





const getMessaging = () => {
    console.log('firebase.apps.length', firebase.apps.length)
    require('firebase/messaging')
    return firebase.messaging()
}

const setOnlinePresence = (userKey, projectKey, data, currentUser) => {
    require('firebase/database')
    return new Promise(async (resolve, reject) => {
        //TODO clean this up we dont need all this no more
        var onDisconnectRef
        var onDisconnectRef2
        try {




            var name = projectKey
            var userName = userKey

            if (currentUser && currentUser.email)
                userName = (currentUser.email || '').replace('@', '-').replace('.', '-').replace(/[^/-0-9a-z]/gi, '');
            else if (currentUser && currentUser.phone)
                userName = (currentUser.phone || '').replace(/[^0-9a-z]/gi, '');


            // since I can connect from multiple devices or browser tabs, we store each connection instance separately
            // any time that connectionsRef's value is null (i.e. has no children) I am offline
            var countRef = firebase.database().ref(`${name}/${userName}`)
            var count = 0
            countRef.once('value').then(function (snapshot) {
                count = ((snapshot.val() && snapshot.val().count) || 0) + 1
                var myConnectionsRef = firebase.database().ref(`${name}/${userName}/c`);

                // stores the timestamp of my last disconnect (the last time I was seen online)
                var lastOnlineRef = firebase.database().ref(`${name}/lastOnline`);

                var connectedRef = firebase.database().ref('.info/connected');
                connectedRef.on('value', function (snap) {
                    if (snap.val() === true) {



                        // We're connected (or reconnected)! Do anything here that should happen only if online (or on reconnect)
                        onDisconnectRef2 = myConnectionsRef.push();
                        // When I disconnect, remove this device
                        onDisconnectRef2.onDisconnect().remove()
                        countRef.update({ count, role: currentUser.role || 0 })


                        //foo.set({ con: null })
                        //.remove();

                        // Add this device to my connections list
                        // this value could contain info about the device or a timestamp too
                        onDisconnectRef2.update({ t: data, d: firebase.database.ServerValue.TIMESTAMP });

                        // When I disconnect, update the last time I was seen online
                        onDisconnectRef = lastOnlineRef.onDisconnect()
                        onDisconnectRef.remove()
                        var extraData = {}
                        onDisconnectRef.update({ t: firebase.database.ServerValue.TIMESTAMP, l: new Date().toString(), ...extraData });
                        resolve([onDisconnectRef, onDisconnectRef2])
                    }
                });
            });


        } catch (error) {
            console.log('Error presenceRef ', error)
            reject(error)
        }


    })
}

// const messaging = undefined
// try {
//     messaging = firebase.messaging()
// } catch (error) {
//     console.log('error', error)
// }
//export const provider = new firebase.auth.GoogleAuthProvider();
export { auth };
export { db };
export { config };
export { getMessaging };
export { setOnlinePresence }



