import React, { Component } from "react";
import { Tab, Menu, Label, Icon } from '../../common/component/Button'
import { connect } from 'react-redux'
import Requests from './Requests'
import RequestsMy from './RequestsMy'
import TextMessages from './TextMessages'
import { openModal } from "../../../core/redux/action/modalAction";
import { FireHelper } from '../../../core/util/FireHelper'
import { getUserlist } from '../../../core/redux/selectors'

//import memoizeOne from 'memoize-one';

// import MobileHead from '../mobile/MobileHead'


class Messages extends Component {

  getPanes = () => {

    const { currentUser, requestData } = this.props
    const alerts = currentUser.alerts || {}
    const requestCount = requestData ? requestData.length : 0
    let msgCount = 0
    if (alerts.m)
      Object.keys(alerts.m).forEach(o => {
        var obj = alerts.m[o]
        msgCount += Number(obj.m || 0)
      })

    return [


      {
        menuItem: <Menu.Item key='download' >
          <Icon name='download' />{window._getText('message.in')}
          {requestCount > 0 && <Label color='teal'>{requestCount}</Label>}
          {requestCount === 0 && <Label >{requestCount}</Label>}
        </Menu.Item>, render: () => <div className='innerDiv'><Requests /></div>
      },
      {
        menuItem: <Menu.Item key='upload' icon='upload' content={window._getText('message.out')} />, render: () => <div className='innerDiv'><RequestsMy /></div>
      },
      {
        menuItem: <Menu.Item key='messages' >
          <Icon name='envelope outline' />{window._getText('message.chat')}
          {msgCount > 0 && <Label color='purple'>{msgCount}</Label>}
          {msgCount === 0 && <Label style={{ visable: 'none' }}>{msgCount}</Label>}

        </Menu.Item>
        , render: () => <div className='innerDiv'><TextMessages isLandscape={this.props.isLandscape} isKeyboardOpen={this.props.isKeyboardOpen} currentUser={this.props.currentUser} openModal={this.props.openModal} userlist={this.props.userlist} /></div>
      },
    ]
  }

  render() {
    return (
      <React.Fragment>

        <Tab menu={{ secondary: true, pointing: true }} panes={this.getPanes()} className='fixedTabs' />


      </React.Fragment>
    );
  }
}


const mapStateToProps = (state) => {


  var userList = []
  var userListData = getUserlist(state) // FireHelper.getUserlist(state.firestore.data.users, state.firebase.profile.projectKey) // JSON.parse(JSON.stringify(FireHelper.getUserlist(state.firestore.data.users, state.firebase.profile.projectKey)))
  if (userListData)
    userList = (userListData.userList || []).map(a => ({ ...a })) //userListData.userList


  userList = userList.filter(u => u.act === true && u.sysKey)


  return {
    userlist: userList,
    authUser: state.firebase.auth,
    currentUser: state.firebase.profile,
    firebase: state.firebase,
    requestData: state.firestore.ordered['requestAllListner'],
    isKeyboardOpen: state.viewstate.isKeyboardOpen,
    isLandscape: state.viewstate.isLandscape,


  };
}
const mapActions = {
  openModal: openModal,

}
export default connect(mapStateToProps, mapActions)(Messages);

