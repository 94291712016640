import React, { useState, useEffect } from 'react';
import { BButton, Icon, Message } from '../Button'
import html2canvas from 'html2canvas';
import firebase from '../../../../core/config/firebase'
import { toastr } from 'react-redux-toastr'
import moment from 'moment';
import { FireHelper } from '../../../../core/util/FireHelper'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faAt as icoHelp,
} from '@fortawesome/pro-solid-svg-icons';


const shortid = require('shortid');

export default function Feedback() {
    const [open, setOpen] = useState(false)
    const [value, setValue] = useState('');
    const [valueType, setValueType] = useState('');
    const [email, setEmail] = useState('');

    const handleChange = (event) => {
        setValue(event.target.value);
    };
    const handleChangeType = (event) => {
        setValueType(event.target.value);
    };
    const handleChangeEmail = (event) => {
        setEmail(event.target.value);
    };

    const onToggleWindow = () => {
        setOpen(!open)

    }
    const onClickSend = () => {
        //setTimeout(() => {

            const pKey = window._projectKey || 'system'
            FireHelper.sendFeedbackMail(pKey, { path: window.location.pathname, projectKey: pKey, data: value, typ: valueType, email, url: `` }, '', '')
            //old code that sends screenshot
            // html2canvas(document.body, { foreignObjectRendering: true, allowTaint: true, useCORS: true }).then((canvas) => {
            //     canvas.toBlob(async (blob) => {
            //         try {
            //             const storageBucket = firebase.storage().ref();
            //             let fileaName = `feedback/${moment().utc().format('YYYY-MM-DD')}_${shortid.generate()}.png`
            //             const storageRef = storageBucket.child(fileaName);
            //             const pKey = window._projectKey || 'system'
            //             //                        if (pKey !== 'system')
            //             storageRef.put(blob)
            //             // else
            //             //     fileaName = 'system'
            //             FireHelper.sendFeedbackMail(pKey, { path: window.location.pathname, projectKey: pKey, data: value, typ: valueType, email, url: `https://storage.googleapis.com/${window._storageBucket}/${fileaName}` }, '', '')
            //         } catch (error) {
            //             //console.log('error', error)
            //         }
            //     }, 'image/png');
            // });
        //}, 300);

        setOpen(false)
        setTimeout(() => {
            toastr.success('Sent!', `Thank you for your feedback`);
        }, 100);

    }

    return (

        <div className='feedbackWindow noPrint' data-html2canvas-ignore="true" style={{ bottom: open ? '10px' : '-277px' }}>
            <div className='feedbackTab' onClick={onToggleWindow}>
                <FontAwesomeIcon swapOpacity icon={icoHelp} style={{ fontSize: 15 }}></FontAwesomeIcon>
            </div>
            <div className='feedbackForm'>
                {/* <div style={{ marginBottom: '10px', fontWeight: 'bold', color: '#fff' }}>Feedback</div> */}
                <div style={{ marginBottom: '10px', display: 'flex' }}>

                    <select style={{ width: '100%', color: 'gray' }} onChange={handleChangeType} >
                        <option style={{ color: '#fff' }}>Feedback type</option>
                        <option style={{ color: '#fff' }}>Question</option>
                        <option style={{ color: '#fff' }}>Bug</option>
                        <option style={{ color: '#fff' }}>Translation error</option>
                        <option style={{ color: '#fff' }}>New feature</option>
                        <option style={{ color: '#fff' }}>Other</option>
                    </select>
                </div>
                <div style={{}}>
                    <textarea value={value} onChange={handleChange} className="form-control" placeholder={'Please write your message in english\n\nThank you '} style={{ width: '100%', height: '150px' }} ></textarea>
                </div>
                <div>
                    <input className="form-control" value={email} onChange={handleChangeEmail} placeholder='your@email.com'></input>
                </div>
                <div style={{}}>
                    <BButton className='ui compact button' style={{ marginTop: '8px', backgroundColor: 'var(--color-btn-blue)', width: '100%', color: '#fff' }} compact onClick={onClickSend} content={window._getText('send')}  ></BButton>
                </div>

            </div>
        </div>



    )
}
