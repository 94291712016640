import React, { Component } from "react";
import PropTypes from 'prop-types'
import { connect } from "react-redux";
import Image from 'semantic-ui-react/dist/commonjs/elements/Image'
import Loader from 'semantic-ui-react/dist/commonjs/elements/Loader'
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon'
import { BButton } from '../../common/component/Button'


import { openModal } from "../../../core/redux/action/modalAction";
//import ShiftUserInput from '../modal/ShiftUserInput'
import FirestoreListnerHelper from '../../../core/util/FirestoreListnerHelper'
import noUserImage from '../../../img/noimg.png'
//import Swipe from 'react-swipe-component'
import { renderDateAddDays, setRenderNow } from '../../../core/redux/action/viewstatAction'
//import { isMobile } from 'react-device-detect';
import { getUserImage, getLocalDate } from '../../../core/util/helpers'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock as icoPageHeader } from '@fortawesome/pro-duotone-svg-icons';

import moment from 'moment'
import { isElectron } from 'react-device-detect';

const lbl24 = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '00']
const lblAmPm = ['12a', '1a', '2a', '3a', '4a', '5a', '6a', '7a', '8a', '9a', '10a', '11a', '12p', '1p', '2p', '3p', '4p', '5p', '6p', '7p', '8p', '9p', '10p', '11p', '12a']



let ipcRenderer

if (isElectron) {
  ipcRenderer = window.require('electron').ipcRenderer


}



const modalKey = 'shiftuserinput'
class ScheduleToday extends Component {
  //static contextTypes = { store: PropTypes.object.isRequired }


  constructor(props) {
    super(props);
    this.nowRef = React.createRef()
    this.divMain = React.createRef()

    const viewShiftsAsTasked = localStorage.getItem('todayview.viewShiftsAsTasked') === 'true' ? true : false


    this.state = {
      viewShiftsAsTasked,
      schedulesList: [],
      isEmpty: true,
      slideAni: '',
      renderDate: moment.utc(new Date()).startOf('week')

    }

  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.handleResize);
    if (this.reRenderTimer)
      clearInterval(this.reRenderTimer)
  }

  reRenderTimer
  componentDidMount = () => {

    window.addEventListener('resize', this.handleResize, true);
    this.reRenderTimer = setInterval(() => {
      this.setState({})
    }, 60000);

    if (isElectron) {
      ipcRenderer.on('onTodayWindowFocus', (event, message) => {
        const rDate = moment.utc(new Date()).startOf('week')


        this.setState({ renderDate: rDate }, () => {
          FirestoreListnerHelper.setWeekViewListner(this.context, this.state.renderDate)
        })
      })
    }

    if (this.props.currentUser.isLoaded === true) {
      FirestoreListnerHelper.setWeekViewListner(this.context, this.state.renderDate)
    }

    this.handleResize()

  }
  componentDidUpdate = async (prevProps, prevState, snapshot) => {
    var renderDate = moment.utc(new Date()).startOf('week'); //TODO MOE TO RENDER IF DATE CHANGE 

    if (renderDate !== this.state.renderDate) {

      FirestoreListnerHelper.setWeekViewListner(this.context, this.state.renderDate)
    }
    this.handleResize()
  }


  handleResize = () => {

    if (this.divMain && this.divMain.current) {
      const w = Math.floor((Math.floor((this.divMain.current.offsetWidth - 40) / 24) * 24) / 60) * 60
      const pxUnit = (w / 25) / 60


      if (this.state.divMainWidth !== w)
        this.setState({ divMainWidth: w, divMainHeight: this.divMain.current.scrollHeight, pxUnit })
    }
  }


  onOpenModal = (data) => () => {
    this.props.onOpenModal(modalKey, data)
  }

  doStackItems = () => {
    return this.state.viewShiftsAsTasked === true ? { height: '34px', position: 'relative', marginLeft: '-5px' } : {}
  }

  scale = 4
  renderCells = (shifts, todayStr, scheduleKey, user, projectKey, row) => {
    var shift = shifts[todayStr]

    if (shift) {
      return (


        <div className='flexDiv' style={{ borderBottom: 'dotted 0px var(--color-border2)' }
        }>
          {
            shift.map((x, index) => {

              var time = x.t1.split(':')
              var pxUnit = this.state.pxUnit

              var h = (Number(time[0]) * 60) * pxUnit
              var min = (Number(time[1])) * pxUnit
              var leftPos = ((h + min))
              var width = ((x.m || 0) + (x.b || 0)) * pxUnit


              if (x.t1 === x.t2 && width === 0) {
                width = this.state.divMainWidth - (pxUnit * 60)
              }



              return (
                <div style={this.doStackItems()} key={x.k}>
                  <div style={{ left: leftPos + 'px', position: 'absolute', }}  >
                    <div style={{ position: 'absolute', left: '-26px' }} tooltip_t={user.n} >
                      <Image style={{ zIndex: '7', border: 'solid 1px #fff', width: '25px', height: '25px', marginTop: '5px' }} size='mini' src={user.i ?
                        getUserImage(projectKey, row, user.i)
                        : noUserImage} circular


                        onError={(e) => { e.target.onerror = null; e.target.src = noUserImage }}
                      />

                    </div>
                    <div style={{ position: 'relative', height: '30px', width: width + 'px', textAlign: 'center', left: '-4px' }} onClick={() => { console.log('show info box!') }} key={x.k} className={'x' + (x.sik === true ? '' : x.c) + ' cellMulti ui label'} >
                      {/* {x.sik && <span style={{ float: 'left' }}> <Icon name='list ul' style={{ fontSize: '8px' }} /></span>} */}
                    </div>
                    <span style={{ position: 'absolute', left: '5px', fontSize: '12px', padding: '5px', width: '200px' }}>{x.l}</span>
                  </div>
                </div>


              )
            })
          }
        </div >

      )
    }
    return (
      null
    )
  }

  renderSchedual = (schedule, date) => {
    const { projectKey, project } = this.props
    const dayOfWeek = moment().isoWeekday()

    if (!project)
      return <div key='loading' style={{ textAlign: 'center', marginTop: '50px' }}><Loader inline active>{window._getText('loading')}</Loader></div>

    if (!schedule)
      return <div key='loading1' style={{ textAlign: 'center', marginTop: '50px' }}><Loader inline active>{window._getText('loading')}</Loader></div>

    let haveUserData = false
    let innerData = (schedule.rows || []).map(row => {

      //if (row.k === this.props.inSysUserKey || this.props.isUnknown === true)
      if (row.k.startsWith('div_')) {
        return (
          <div style={{ height: '20px' }} key={row.k} >
            <span style={{ position: 'absolute', fontSize: '12px', fontWeight: 'bold' }}>{row.u}</span>
          </div>
        )
      }
      else {
        var user = project.cache[row.k] || { i: '', n: '#unknown', a: false, sk: '' }
        if (user.a === false)
          return null

        const shiftData = this.renderCells(row.s, dayOfWeek, schedule.sKey, user, projectKey, row.k)

        if (!shiftData)
          return null

        return (
          <div key={row.k} style={{ borderBottom: 'solid 0px var(--color-border2)', display: 'flex', padding: '5px', width: '100vw' }}>
            <div style={{ height: '30px' }}></div>
            {/* <div style={{ zIndex: '10', left: '8px', width: '30px', position: 'absolute', }}>
              <Image style={{ zIndex: '11', border: 'solid 1px #fff', width: '25px', height: '25px', float: 'left', marginRight: '5px', marginTop: '5px' }} size='mini' src={user.i ?
                getUserImage(projectKey, row.k, user.i)
                : noUserImage} circular
                title={user.n}
                alt={user.n}

                onError={(e) => { e.target.onerror = null; e.target.src = noUserImage }}
              /> */}
            {haveUserData = true}
            {/* <div style={{

                paddingTop: '6px',
                fontSize: '14px',

                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}>{user.n}</div>*/}
            {/* </div> */}
            <div style={{ width: '100%' }}>
              {shiftData}
            </div>
          </div >
        )
      }


    })

    if (innerData)
      innerData = innerData.filter(function (el) {
        return el != null;
      });



    if (innerData && innerData.length > 0 && haveUserData === true)
      return (
        <div key={schedule.sKey} style={{ width: '100vw' }}>
          <div style={{ position: 'absolute', fontWeight: 'bolder', fontSize: '20px', }}>{schedule.sName}</div>
          <div style={{ height: '26px', borderBottom: 'solid px var(--color-border2)', padding: '2px', width: '500px' }}></div>
          {innerData}
        </div>
      )


    return null

  }



  onBtnOpen = () => {
    if (isElectron) {
      ipcRenderer.send('todayWindow-btnopen', true)
    }
  }
  onBtnClose = () => {
    if (isElectron) {
      ipcRenderer.send('todayWindow-btnclose', true)
    }
  }



  toggleStackedView = () => {
    this.setState({ viewShiftsAsTasked: !this.state.viewShiftsAsTasked }, () => localStorage.setItem('todayview.viewShiftsAsTasked', this.state.viewShiftsAsTasked))
  }


  render() {

    const rDate = this.state.renderDate.clone()
    const dateKey = FirestoreListnerHelper.getDateKey(rDate);
    const scheduleWeekData = this.props.fireData['weekview_' + dateKey] || {}
    const pxUnit = this.state.pxUnit || 0
    const hours = this.props.AmPm ? lblAmPm : lbl24
    var m = moment(new Date())


    var mmtMidnight = m.clone().startOf('day');

    // Difference in minutes
    var diffMinutes = m.diff(mmtMidnight, 'minutes');

    var posNow = ((((m.hour() * 60) + m.minute())) * pxUnit) + 'px'


    // posNow = diffMinutes * pxUnit
    let renderData =
      Object.keys(scheduleWeekData).map(key => {
        return this.renderSchedual(scheduleWeekData[key], rDate)
      })

    if (renderData)
      renderData = renderData.filter((el) => el != null)


    //setTimeout(() => {
    //this.scrollToNowRef()
    //}, 100);

    return (
      <React.Fragment>

        <BButton compact tooltip_r={window._getText('Stack overlapping shifts')} style={{ position: 'absolute', right: '10px', top: '8px' }} onClick={this.toggleStackedView} icon='list ul' className='tour_today_2'  ></BButton>

        <div className='dragWindow2 tour_today_1' style={{ backgroundColor: 'var(--color-bg1)', width: '100%', display: 'flex', height: '50px', zIndex: 9, padding: '10px' }}>



          <h2 style={{ color: '#DA3492' }}> {<FontAwesomeIcon icon={icoPageHeader}></FontAwesomeIcon>}  {window._getText('schedual.m.Today')}</h2>

          <div style={{ fontWeight: 'bold', marginLeft: '20px', padding: '10px', marginLeft: 'auto', marginRight: 'auto' }}>{getLocalDate(moment(new Date()), "LL")}</div>

        </div>
        <div id='divTodayView' onClick={this.onBtnOpen} style={{ position: 'relative', height: 'calc(100% - 60px)', overflowY: 'auto', overflowX: 'hidden', marginTop: '8px' }} >

          {/* <div className='topPointer'></div> */}






          <div ref={this.divMain} style={{}}>

            <div style={{ position: 'relative', width: this.state.divMainWidth, display: 'flex', height: '100vh', marginLeft: 'auto', marginRight: 'auto', }} >

              <div style={{ position: 'fixed', width: this.state.divMainWidth, display: 'flex', height: '100vh', marginLeft: 'auto', marginRight: 'auto', }}>

                {hours.map((item, index) => {
                  return (
                    <div key={index.toString()} style={{ borderLeft: 'dotted 0px var(--color-border2)', flex: 12, position: 'relative', top: '-20px' }}>
                      <div style={{ color: '#000', zIndex: 100, position: 'absolute', left: '0px', borderLeft: 'dotted 1px var(--color-border2)', height: this.state.divMainHeight + 'px' }}></div>
                      {item}</div>
                  )
                })}

              </div>



              <div id='mainTodayScroll' style={{ position: 'absolute', height: '100%' }}>


                <div style={{ zIndex: '10', bottom: 0, borderLeft: 'dotted 2px deepskyblue', position: 'absolute', left: posNow, top: '-25px', height: this.state.divMainHeight + 'px' }} ref={this.nowRef}>
                  {/* <span style={{ border: 'solid 1px deepskyblue', borderRadius: '8px', position: 'absolute', left: '-15px', backgroundColor: 'deepskyblue', padding: '0px', color: '#fff', fontWeight: 'bold', fontSize: '9px' }}>{m.format('hh:mm')}</span> */}
                </div>


                {/* {(renderData.length === 0 && this.props.isDataLoaded) &&
                  <div style={{ textAlign: 'center', marginTop: '60px', marginBottom: '20px' }}>
                    <Icon name='calendar outline' size='huge'></Icon><br />
                    There is no shifts scheduled today.
                 </div>
                } */}
                <div style={{ height: '25px' }}></div>
                {renderData.length > 0 && renderData}
                <div style={{ height: '60px' }}></div>


              </div>

            </div>
          </div >
        </div>
      </React.Fragment>
    )
  }
}


const mapStateToProps = (state, ownProps, ownState) => {


  const inSysUserKey = state.firebase.profile.inSysUserKey
  const firstDayOfWeekNumber = Number(state.firebase.profile.firstDay) //sunday 0 , monday 1


  var isUnknown = true
  if (inSysUserKey)
    isUnknown = false;



  return ({
    isDataLoaded: state.firestore.status.requested[`weekview/${state.viewstate.renderDateKey}`],
    renderDate: state.viewstate.renderDate,
    renderDateKey: state.viewstate.renderDateKey,
    projectKey: state.firebase.profile.projectKey,
    project: state.firestore.data.project,
    fireRequested: state.firestore.status.requested,
    fireData: state.firestore.data,

    isLandscape: state.viewstate.isLandscape,
    currentUser: state.firebase.profile,
    firstDayOfWeekNumber,
    isUnknown,
    inSysUserKey,
    AmPm: state.firestore.data.project.s ? state.firestore.data.project.s.AmPm || false : false
  });
}
const mapActionsFromProps = {
  onOpenModal: openModal,
  renderDateAddDays,
  setRenderNow
}
export default connect(
  mapStateToProps,
  mapActionsFromProps
)(ScheduleToday);

