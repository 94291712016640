import React, {useCallback} from 'react'

const Tabel = ({ head, rows, maxHeight, widths, onRowClick , metaData}) => {
    const headWidths = [...widths]
    headWidths[head.length - 1] = headWidths[head.length - 1] + 5

    const rowClick = useCallback(
        (index) => (e) => {
            if (onRowClick) onRowClick(metaData[index],e)
        },
        [rows])



    return (
        <>

            <table style={{ backgroundColor: 'var(--color-bgTable)' }} className="ui dataTable celled striped table unstackable ">
                <thead className='dataTable'>
                    <tr className="">
                        {head && head.map((item, index) => {
                            return (
                                <th key={`key_${index}`} className="dataTable" style={{ minWidth: headWidths[index], width: headWidths[index], maxWidth: headWidths[index] }}  >
                                    {item}
                                </th>
                            )
                        })}
                    </tr>
                </thead>
                <tbody style={{ maxHeight }} className='dataTable' >
                    {rows && rows.map((row,index) => (
                        <tr onClick={rowClick(index)} key={`key1_${index}`}>
                            {row && row.map((item, index) =>
                                <td key={`key2_${index}`} className="dataTable" style={{ minWidth: widths[index], width: widths[index], maxWidth: widths[index] }}  >{item}</td>
                            )}
                        </tr>
                    ))
                    }
                </tbody>

            </table>


        </>
    )
}

export default Tabel