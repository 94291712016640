const _lang = require('lodash/lang')

module.exports = {

    templates: (Menu, sendAppCmd, printToPDF, app, _debug, language, lang) => {

        if (language)
            lang.setLang(language)


        const edit = {
            hideOnWeb: true,
            label: lang.text('Edit'),
            submenu: [
                {
                    label: lang.text('Cut'),
                    accelerator: 'CmdOrCtrl+X',
                    role: 'cut',
                },
                {
                    label: lang.text('Copy'),
                    accelerator: 'CmdOrCtrl+C',
                    role: 'copy',
                },
                {
                    label: lang.text('Paste'),
                    accelerator: 'CmdOrCtrl+V',
                    role: 'paste',
                },
                {
                    label: lang.text('Select All'),
                    accelerator: 'CmdOrCtrl+A',
                    role: 'selectAll',
                },
            ],
        };


        // const templateLoader = [{
        //     label: 'TimeTo.Work',
        //     submenu: [
        //         { label: 'Quit', accelerator: 'CmdOrCtrl+Q', click: function () { app.quit(); } }]

        // }, edit]

        const templateStaff = [
            {
                label: 'TimeTo.Work',
                submenu: [
                    { label: lang.text('About TimeTo.Work'), role: 'about', hideOnWeb: true },
                    { type: 'separator', hideOnWeb: true },
                    { role: 'services', hideOnWeb: true },
                    { view: 0, type: 'separator', hideOnWeb: true },
                    { label: lang.text('Preferences...'), accelerator: 'Command+,', click: () => { sendAppCmd('preferences') } },
                    { label: lang.text('Toggle menu bar icon'), click: () => { sendAppCmd('toggleTodayView') }, hideOnWeb: true },
                    { type: 'separator', hideOnWeb: true },
                    { label: lang.text('Hide TimeTo.Work'), accelerator: 'Command+H', role: 'hide', hideOnWeb: true },
                    { label: lang.text('Hide Others'), role: 'hideothers', accelerator: 'Command+Alt+H', hideOnWeb: true },
                    { label: lang.text('Show All'), role: 'unhide', hideOnWeb: true },
                    { type: 'separator', hideOnWeb: true },
                    { label: lang.text('Signout'), click: () => { sendAppCmd('signout') } },
                    { label: lang.text('Quit'), accelerator: 'CmdOrCtrl+Q', role: 'quit', hideOnWeb: true }]
            }, edit
            ,
            {
                label: lang.text('Window'),
                role: 'Window',
                hideOnWeb: true,
                submenu: [
                    { role: 'minimize', label: lang.text('minimize') },
                    { role: 'zoom', label: lang.text('zoom') },
                    { type: 'separator' },
                    { role: 'front', label: lang.text('front') },
                    { type: 'separator' },
                    { role: 'window', label: lang.text('window') }
                ]
            },
            {
                label: lang.text('Help'),
                role: 'help',
                hideOnWeb: false,
                submenu: [
                    {
                        label: lang.text('Clear all data'), enabled: true, click: () => { sendAppCmd('cleardata') }
                    },


                ]
            }

        ];



        const template = [
            {
                label: 'TimeTo.Work',
                role: 'appMenu',
                submenu: [
                    { label: lang.text('About TimeTo.Work'), role: 'about', hideOnWeb: true },
                    { type: 'separator' },
                    { role: 'services' },
                    { view: 0, type: 'separator' },
                    { label: lang.text('Preferences...'), accelerator: 'Command+,', click: () => { sendAppCmd('preferences') } },
                    { label: lang.text('Toggle menu bar icon'), click: () => { sendAppCmd('toggleTodayView') }, hideOnWeb: true },
                    { type: 'separator' },
                    { label: lang.text('Hide TimeTo.Work'), accelerator: 'Command+H', role: 'hide', hideOnWeb: true },
                    { label: lang.text('Hide Others'), role: 'hideothers', accelerator: 'Command+Alt+H', hideOnWeb: true },
                    { label: lang.text('Show All'), role: 'unhide', hideOnWeb: true },
                    { type: 'separator' },
                    { label: lang.text('Signout'), click: () => { sendAppCmd('signout') } },
                    { label: lang.text('Quit'), accelerator: 'CmdOrCtrl+Q', role: 'quit', hideOnWeb: true }]

            }
            , {
                label: lang.text('File'),
                key: 'File',
                hideOnWeb: false,
                submenu: [
                    // { view: 1, label: 'Add Shift', accelerator: 'CmdOrCtrl+N', enabled: true, click: () => { sendAppCmd('newshift') } },
                    // { view: 1, label: 'Add Users', enabled: true, click: () => { sendAppCmd('newuser') } },
                    // { view: 1, label: 'Add Divider', enabled: true, click: () => { sendAppCmd('newdivider') } },
                    // { view: 1, label: 'Add Schedule', enabled: true, click: () => { sendAppCmd('newschedule') } },
                    // { view: 0, type: 'separator' },
                    // { view: 2, label: 'New chat', enabled: true, click: () => { sendAppCmd('newchat') } },
                    // { view: 0, type: 'separator' },
                    // { view: 3, label: 'New user', enabled: true, click: () => { sendAppCmd('newuser') } },
                    // { view: 0, type: 'separator' },
                    { hideOnWeb: true, view: 2, label: lang.text('New chat'), enabled: true, click: () => { sendAppCmd('newchat') } },
                    { hideOnWeb: true, view: 3, label: lang.text('New staff'), enabled: true, click: () => { sendAppCmd('newuser') } },
                    { hideOnWeb: true, view: 1, label: lang.text('New schedule'), enabled: true, click: () => { sendAppCmd('newschedule') } },
                    { hideOnWeb: true, view: 0, type: 'separator' },
                    {
                        view: 0,
                        label: lang.text('Export'), enabled: true,
                        submenu: [
                            { hideOnWeb: true, view: 4, label: lang.text('Reports as XLSX'), enabled: true, click: () => { sendAppCmd('reportDownloadAll') } },
                            { view: 1, label: lang.text('Schedule as PDF'), enabled: true, accelerator: 'CmdOrCtrl+P', click: () => { sendAppCmd('canIPrint') } },//TEMP REMOVED , this dont work on electron for now..


                        ]
                    }

                ]
            }
            , {
                label: lang.text('Edit'),
                key: 'Edit',
                hideOnWeb: true,
                submenu: [
                    //   { label: 'Copy shift', accelerator: 'CmdOrCtrl+Z', selector: 'undo:', click: () => { sendAppCmd('copyshift') } },
                    // { label: 'Paste shift', accelerator: 'Shift+CmdOrCtrl+Z', selector: 'redo:', click: () => { sendAppCmd('pastshift') } },
                    // { type: 'separator' },
                    {
                        view: 0,
                        label: lang.text('Add to schedule'), enabled: true,
                        submenu: [
                            { view: 1, label: lang.text('Shift'), accelerator: 'CmdOrCtrl+N', enabled: true, click: () => { sendAppCmd('newshift') } },
                            { view: 1, label: lang.text('Staff'), enabled: true, click: () => { sendAppCmd('newuser') } },
                            { view: 1, label: lang.text('Divider'), enabled: true, click: () => { sendAppCmd('newdivider') } },


                        ]
                    },
                    { view: 1, label: lang.text('Copy schedule'), enabled: true, click: () => { sendAppCmd('copyschedule') } },
                    { view: 1, label: lang.text('Paste schedule'), enabled: true, click: () => { sendAppCmd('pasteschedule') } },
                    { view: 1, label: lang.text('Clear schedule'), enabled: true, click: () => { sendAppCmd('clearshifts') } },
                    { view: 0, type: 'separator' },
                    // { view: 1, label: lang.text('Undo all changes'), enabled: true, click: () => { sendAppCmd('undochanges') } },


                    {
                        view: 0,
                        type: 'separator',
                    },
                    { view: 0, label: lang.text('Cut'), accelerator: 'CmdOrCtrl+X', role: 'cut', hideOnWeb: true },
                    { view: 0, label: lang.text('Copy'), accelerator: 'CmdOrCtrl+C', role: 'copy', hideOnWeb: true },
                    { view: 0, label: lang.text('Paste'), accelerator: 'CmdOrCtrl+V', role: 'paste', hideOnWeb: true },
                    { view: 0, label: lang.text('Select All'), accelerator: 'CmdOrCtrl+A', role: 'selectAll', hideOnWeb: true },

                ]
            },
            {
                role: 'Billing',
                label: lang.text('set.billing'),
                hideOnWeb: false,
                submenu: [
                    { view: 1, label: lang.text('set.billing'), enabled: true, click: () => { sendAppCmd('preferences', { path: 'billing' }) } },


                ]
            },
            {
                role: 'Settings',
                label: lang.text('set.settings'),
                hideOnWeb: false,
                submenu: [
                    { view: 1, label: lang.text('set.settings'), enabled: true, click: () => { sendAppCmd('preferences', { path: 'settings' }) } },


                ]
            },
            {
                label: lang.text('Window'),
                role: 'window',
                hideOnWeb: true,
                submenu: [
                    { role: 'minimize', label: lang.text('minimize') },
                    { role: 'zoom', label: lang.text('zoom') },
                    { type: 'separator' },
                    { role: 'front', label: lang.text('front') },
                    { type: 'separator' },
                    { role: 'unhide', label: lang.text('window') }
                ]
            },
            {
                role: 'help',
                label: lang.text('Help'),
                hideOnWeb: false,
                submenu: [
                    // {
                    //     label: lang.text('How do i ...'),
                    //     click: () => { sendAppCmd('openUrl', 'https://www.timeto.work/help/?l=' + language) }
                    // },
                    // {
                    //     label: lang.text('Quick introduction'), enabled: true, click: () => { sendAppCmd('openintro') }
                    // },
                    {
                        label: lang.text('Clear all data'), enabled: true, click: () => { sendAppCmd('cleardata') }
                    },


                ]
            }

        ]






        const setMenu = (menu, view) => {
            menu.forEach(obj => {

                if (obj.view !== view)
                    obj.enabled = false
                else
                    obj.enabled = true

                if (obj.view === 0)
                    obj.enabled = true

                if (obj.view === 0)

                    if (obj.submenu)
                        obj.submenu.forEach(o => {
                            if (o.view !== view)
                                o.enabled = false
                            else
                                o.enabled = true

                            if (o.view === 0)
                                o.enabled = true
                        })
            })
            return menu
        }
        const getBaseMenu = (menu, key) => {
            var r = _lang.cloneDeep(menu)// Object.assign([], JSON.parse(JSON.stringify(menu))) // we need to do a deep clone ! PROBLEM med att click funtioner inte hänger med
            var FileMenu
            var EditMenu
            var MainMenu = r.find(obj => { return obj.label === 'TimeTo.Work' }).submenu
            MainMenu[4].enabled = true
            MainMenu[5].enabled = true
            MainMenu[11].enabled = true

            switch (key) {
                case 'topContextMenuDefault':
                    //  console.log('topContextMenuDefault')
                    EditMenu = r.find(obj => { return obj.key === 'Edit' }).submenu
                    FileMenu = r.find(obj => { return obj.key === 'File' }).submenu
                    setMenu(FileMenu, 0)
                    setMenu(EditMenu, 0)

                    break;
                case 'topContextMenuSchedular':
                    //console.log('topContextMenuSchedular')
                    EditMenu = r.find(obj => { return obj.key === 'Edit' }).submenu
                    FileMenu = r.find(obj => { return obj.key === 'File' }).submenu
                    setMenu(FileMenu, 1)
                    setMenu(EditMenu, 1)

                    break;
                case 'topContextMenuChat':
                    //  console.log('------>topContextMenuChat')

                    EditMenu = r.find(obj => { return obj.key === 'Edit' }).submenu
                    FileMenu = r.find(obj => { return obj.key === 'File' }).submenu
                    setMenu(FileMenu, 2)
                    setMenu(EditMenu, 2)


                    break;

                case 'topContextMenuReports':

                    EditMenu = r.find(obj => { return obj.key === 'Edit' }).submenu
                    FileMenu = r.find(obj => { return obj.key === 'File' }).submenu
                    setMenu(FileMenu, 4)
                    setMenu(EditMenu, 4)

                    break;
                case 'topContextMenuUsers':

                    EditMenu = r.find(obj => { return obj.key === 'Edit' }).submenu
                    FileMenu = r.find(obj => { return obj.key === 'File' }).submenu
                    setMenu(FileMenu, 3)
                    setMenu(EditMenu, 3)
                    break;

                case 'topContextMenuRequest':

                    EditMenu = r.find(obj => { return obj.key === 'Edit' }).submenu
                    FileMenu = r.find(obj => { return obj.key === 'File' }).submenu
                    setMenu(FileMenu, 5)
                    setMenu(EditMenu, 5)

                    break;
                case 'topContextMenuLoader':
                    MainMenu[4].enabled = false
                    MainMenu[5].enabled = false
                    MainMenu[11].enabled = false //signout 
                    // MainMenu[12].enabled = false

                    EditMenu = r.find(obj => { return obj.key === 'Edit' }).submenu
                    FileMenu = r.find(obj => { return obj.key === 'File' }).submenu
                    setMenu(FileMenu, 6)
                    setMenu(EditMenu, 6)


                    break;

                default:
                    break;
            }

            if (_debug === true) {
                r.push({
                    label: 'Dev',
                    role: 'toggleDevTools',
                    submenu: [
                        {
                            label: 'Dev',
                            role: 'toggleDevTools',

                        }
                    ]
                })
            }

            return Menu.buildFromTemplate(r)

        }









        const topContextMenuDefault = getBaseMenu(template, 'topContextMenuDefault');
        const topContextMenuLoader = getBaseMenu(template, 'topContextMenuLoader');
        const topContextMenuSchedular = getBaseMenu(template, 'topContextMenuSchedular');
        const topContextMenuChat = getBaseMenu(template, 'topContextMenuChat');
        const topContextMenuRequest = getBaseMenu(template, 'topContextMenuRequest');
        const topContextMenuReports = getBaseMenu(template, 'topContextMenuReports');
        const topContextMenuUsers = getBaseMenu(template, 'topContextMenuUsers');
        const topContextMenuStaff = getBaseMenu(templateStaff, 'topContextMenuStaff');








        return { topContextMenuDefault, topContextMenuLoader, topContextMenuSchedular, topContextMenuChat, topContextMenuRequest, topContextMenuUsers, topContextMenuReports, topContextMenuStaff }
    }

}
