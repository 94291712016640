import React, { createContext, useState, useContext } from 'react'

const GlobalState = createContext(null)
export default GlobalState


export const withGlobalState = (WrappedComponent) => {
    return function component(props) {
        const global = useContext(GlobalState)
        return <WrappedComponent global={global}  {...props} ></WrappedComponent>
    }
}