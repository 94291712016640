
export default {
    divMain: {


        display: 'grid',
        gridTemplateColumns: '30% 70%',
        gridTemplateRows: '35px auto',
        gridTemplateAreas: `'topContent topContent'
                            'leftContent rightContent'`,
        height: '100%',

    },
    divTop: {
        // border: 'solid 1px yellow',
        display: 'flex',
        gridArea: 'topContent',
        justifyContent: 'flex-end',
        padding: 10,

    },
    divLeft: {
        // border: 'solid 1px blue',
        gridArea: 'leftContent',
        padding: 10,



    },
    divRight: {
        // border: 'solid 1px green',
        display: 'flex',
        gridArea: 'rightContent',
        padding: 5
    },
    icoAddTask: {
        fontSize: 28,
        color: 'var(--color--text)'
    },
    icoTask: {
        fontSize: 20,

    },
    icoStartStopStart: {
        color: 'green',
        fontSize: 20,
        marginTop: 3,

    },
    icoDelete: {

        fontSize: 18,
        marginTop: 3,
        color: 'var(--color-text)'


    },
    icoStartStopRestart: {
        color: 'var(--color-text)',
        fontSize: 20,
        marginTop: 3,
        // marginRight: 3,
    },
    icoStartStopStop: {
        fontSize: 20,
        marginTop: 3,
        color: 'red'
        //  marginRight: 3,
    },
    icoCheck: {
        color: '#679951'
    },
    icoStatus10: {
        color: '#FF9999',
        margin: 4,
        fontSize: 20
    },
    icoStatus20: {
        color: 'green',
        margin: 4,
        fontSize: 20
    },
    icoStatus30: {
        color: 'red',
        margin: 4,
        fontSize: 20
    },
    icoStatus40: {
        color: 'darkgreen',
        margin: 4,
        fontSize: 20
    },
    paddingLeftList: {
        paddingLeft: 10,
        paddingTop: 5
    },
    taskItemSelected: {
        background: 'silver',
        borderRadius: 6,
        padding: 6,
        marginTop: 1
    },
    taskItem: {
        padding: 2,
        marginTop: 1,
        display: 'flex'

    },
    tbTaskItem: {
        width: '100%',
        background: 'rgba(255,255,255,0.3)',
        border: 'none',

        height: 60,

    },
    tbDescription: {
        width: '100%',
        height: '50px',
        background: 'rgba(255,255,255,0)',
        // border: 'none',
        color: 'var(--color-text)'

    },
    taskView: {
        width: '100%',
        // borderTopLeftRadius: 20,
        padding: 10,
        // background: 'rgba(255,255,255,0.5)'
    },
    taskViewRow: { marginBottom: 10, },
    taskViewRowTitle: { marginBottom: 10, display: 'flex' },
    tbFeedback: {
        width: '100%',
        height: '100px',
        background: 'rgba(255,255,255,0.3)',
        // border: 'none',
    },
    tbTitle: {
        fontWeight: 'bold',
        width: '100%',
        marginRight: 10,
        background: 'rgba(255,255,255,0)',
        // border: 'none',
        // borderBottom: 'solid 1px var(--color-border)',
        // borderRadius: 0,
        // borderTopLeftRadius: 4,
        // borderTopRightRadius: 4,
        color: 'var(--color-text)'

    }
}