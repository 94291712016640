


import { FireHelper } from '../util/FireHelper'
import { getDateKey, getLocalDate, renderTimeLable } from '../util/helpers'
import { DataHelper } from "../util/DataHelper";
import { getScheduleDropdown, getEventObject, getAvailabilityMetaData, eventDataObject, getUserlist, getMyData } from './selectors'
import memoizeOne from 'memoize-one';
import moize from "moize";

export const mapStateToPropsFactory = (initialState, ownProps) => {
    // a closure for ownProps is created
    // this factory is not invoked everytime the component
    // changes it's props
    return mapStateToProps
}


const eventLists = moize(({ cacheKey, eventObject }) => {
    return { cacheKey, eventObject } // return exact the same object we dont rerender
},
    {
        matchesKey(cacheKey, key) {

            return cacheKey[0].cCachekey === key[0].cCachekey

        },
        // onCacheHit: (
        //     cache,
        //     options
        // ) => console.log('eventLists cached')

    }

)

const emptyString = ''
const emptyObject = {}
const emptyArray = []
const mapStateToProps = (state, ownProps) => {
    // console.log('mapStateToProps')
    //console.log('get eventsList list')
    const userList = getUserlist(state, currentProjectKey);
    const schedules = state.firestore.data.schedules || emptyObject;
    const currentProjectKey = state.firebase.profile.projectKey || emptyString;
    const dateKey = getDateKey(state.currentScheduelData.renderDayDate);//moment.utc(state.currentScheduelData.renderDayDate).add(6, 'days').format("YYYY-WW")

    const { availabilityMetaData, newMetaData } = getAvailabilityMetaData(state, dateKey, weeksToShow)


    const weeksToShow = state.viewstate.weeksToShow
    // console.log('get', 'eventDataObject')
    const { scheduleWeekData, eventObject, cCachekey } = eventDataObject(state, dateKey, weeksToShow)
    //console.log('>>', eventObject)
    const _eventObject = eventLists({ cCachekey, eventObject }).eventObject
    const eventsList = _eventObject.events //we culd user cache herer insted
    const sortedUserList2 = _eventObject.sortedUserList || emptyArray // this use to be a object
    const dividerList = _eventObject.dividerList || emptyObject
    const haveData = (state.firestore.status.requested[`users`] && state.firestore.status.requested[`project`])
    const isLoadedSchedule = (state.firestore.status.requested[`schedules`]) //projects/${currentProjectKey}/
    const curScheduleView = state.currentScheduelData[state.currentScheduelData.selectedKey + '_' + dateKey] || DataHelper.initSchedule(state.currentScheduelData.selectedKey, dateKey)
    // console.log('curScheduleView', curScheduleView)



    return {
        isOpen_CopyScheduleModal: state.modal.copyShedualModalOpen,
        isOpen_AppMenu: state.modal.modals['appMenu'].open || false,
        isOpen_EditEventModal: state.modal.eventModalOpen || false,
        isOpen_EditShedualModalOpen: state.modal.editShedualModalOpen || false,
        cloneShift: state.viewstate.cloneShift,
        weeksToShow,
        isLoadedSchedule,
        currentProjectKey,
        sortedUserList2,
        listners: state.firestore.listeners.byId,
        listnersStatusRequesting: state.firestore.status.requesting,
        listnersStatusRequested: state.firestore.status.requested,
        isLandscape: state.viewstate.isLandscape,
        viewstateRenderDate: state.viewstate.renderDate,
        neverSavedShift: state.viewstate.neverSavedShift,
        project: state.firestore.data.project,
        currentScheduelData: state.currentScheduelData,
        user: state.user, //current user
        currentUser: state.firebase.profile,
        requestData: state.firestore.ordered['requestAllListner'],
        requestMyData: state.firestore.ordered['requestMyListner'],
        requestAdminData: state.firestore.ordered['requestAdminListner'],
        haveData,


        AmPm: state.firestore.data.project.s ? state.firestore.data.project.s.AmPm || false : false,
        users: userList, // all users
        scheduleDropDownList: getScheduleDropdown(state), // dd List of scheduels
        schedules,
        eventsList,
        dividerList,
        curScheduleView,
        scheduleWeekDataMeta: newMetaData,
        scheduleWeekData,
        availabilityMetaData,
        publish: (eventObject || {}).publish !== undefined ? eventObject.publish : true,
        isUnPublish: (eventObject || {}).unPublish !== undefined ? eventObject.unPublish : false,
        dateKey

    };
};


