import React, { Component } from "react";
import PropTypes from 'prop-types'
import { connect } from "react-redux";
import Image from 'semantic-ui-react/dist/commonjs/elements/Image'
import Loader from 'semantic-ui-react/dist/commonjs/elements/Loader'
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon'

import { openModal } from "../../../core/redux/action/modalAction";
//import ShiftUserInput from '../modal/ShiftUserInput'
import FirestoreListnerHelper from '../../../core/util/FirestoreListnerHelper'
import noUserImage from '../../../img/noimg.png'
//import Swipe from 'react-swipe-component'
import { renderDateAddDays, setRenderNow } from '../../../core/redux/action/viewstatAction'
//import { isMobile } from 'react-device-detect';
import { getUserImage, getLocalDate } from '../../../core/util/helpers'

import { isElectron } from 'react-device-detect';


import moment from 'moment'


let ipcRenderer

if (isElectron) {
  ipcRenderer = window.require('electron').ipcRenderer


}



const modalKey = 'shiftuserinput'
class ScheduleTodayTaskbar extends Component {
  ////static contextTypes = { store: PropTypes.object.isRequired }


  constructor(props) {
    super(props);
    this.nowRef = React.createRef()
    this.state = {
      schedulesList: [],
      isEmpty: true,
      slideAni: '',
      renderDate: moment.utc(new Date()).startOf('week')
    }

  }


  componentDidMount = () => {

    if (isElectron) {
      ipcRenderer.on('onTodayWindowFocus', (event, message) => {
        const rDate = moment.utc(new Date()).startOf('week')


        this.setState({ renderDate: rDate }, () => {
          FirestoreListnerHelper.setWeekViewListner(this.context, this.state.renderDate)
        })//reRender
      })
    }

    if (this.props.currentUser.isLoaded === true) {
      FirestoreListnerHelper.setWeekViewListner(this.context, this.state.renderDate)
    }
  }
  componentDidUpdate = async (prevProps, prevState, snapshot) => {
    //console.log('componentDidUpdate')
    var renderDate = moment.utc(new Date()).startOf('week'); //TODO MOE TO RENDER IF DATE CHANGE 

    if (renderDate !== this.state.renderDate) {
      // console.log('componentDidUpdate> REMDER date is new!')
      // console.log('this.state.renderDate', this.state.renderDate)
      // console.log('renderDate', renderDate)
      // console.log('renderDate', moment().weekday())
      // console.log('----')
      FirestoreListnerHelper.setWeekViewListner(this.context, this.state.renderDate)
    }
    //console.log('componentDidUpdate')

  }


  onOpenModal = (data) => () => {
    this.props.onOpenModal(modalKey, data)
  }
  scale = 4
  renderCells = (shifts, todayStr, scheduleKey, user, projectKey, row) => {
    var shift = shifts[todayStr]

    if (shift) {
      return (

        <div className='flexDiv'>
          {shift.map(x => {
            try {



              var time = x.t1.split(':')
              var h = Number(time[0]) * 60
              var min = Number(time[1])
              var leftPos = ((h + min) / this.scale) + 40//((Number(x.t1.replace(':',''))/60) /2)
              //  console.log('(x.m||0+x.b||0)', ((x.m || 0) + (x.b || 0)))
              return (
                <div style={{ left: leftPos + 'px', position: 'absolute' }} key={x.k} >
                  <div style={{ position: 'absolute', left: '-26px' }}>
                    <Image style={{ zIndex: '7', border: 'solid 1px #fff', width: '25px', height: '25px', marginTop: '5px' }} size='mini' src={user.i ?
                      getUserImage(projectKey, row, user.i)
                      : noUserImage} circular
                      title={user.n}
                      alt={user.n}

                      onError={(e) => { e.target.onerror = null; e.target.src = noUserImage }}
                    />
                  </div>
                  <div style={{ position: 'absolute', height: '30px', width: (((x.m || 0) + (x.b || 0)) / this.scale) + 'px', textAlign: 'center' }} onClick={() => { console.log('show info box!') }} key={x.k} className={'x' + (x.sik === true ? '' : x.c) + ' cellMulti ui label'} >
                    {/* {x.sik && <span style={{ float: 'left' }}> <Icon name='bed' style={{ fontSize: '8px' }} /></span>} */}
                  </div>
                  <span style={{ position: 'absolute', left: '5px', fontSize: '12px', padding: '5px', width: '200px' }}>{x.l}</span>
                </div>



              )
            } catch (error) {
              return null
            }
          })}
        </div>

      )
    }
    return (
      null
    )
  }

  renderSchedual = (schedule, date) => {
    const { projectKey, project } = this.props
    const dayOfWeek = moment().isoWeekday()

    if (!project)
      return <div key='loading' style={{ textAlign: 'center', marginTop: '50px' }}><Loader inline active>{window._getText('loading')}</Loader></div>

    if (!schedule)
      return <div key='loading1' style={{ textAlign: 'center', marginTop: '50px' }}><Loader inline active>{window._getText('loading')}</Loader></div>

    let haveUserData = false
    let innerData = (schedule.rows || []).map(row => {

      //if (row.k === this.props.inSysUserKey || this.props.isUnknown === true)
      if (row.k.startsWith('div_')) {
        return (
          <div style={{ height: '20px' }} key={row.k} >
            <span style={{ position: 'absolute', fontSize: '12px', fontWeight: 'bold' }}>{row.u}</span>
          </div>
        )
      }
      else {
        var user = project.cache[row.k] || { i: '', n: '#unknown', a: false, sk: '' }
        if (user.a === false)
          return null

        const shiftData = this.renderCells(row.s, dayOfWeek, schedule.sKey, user, projectKey, row.k)

        if (!shiftData)
          return null

        return (
          <div key={row.k} style={{ borderBottom: 'solid 0px var(--color-border2)', display: 'flex', padding: '5px', width: '100vw' }}>
            <div style={{ height: '30px' }}></div>
            {/* <div style={{ zIndex: '10', left: '8px', width: '30px', position: 'absolute', }}>
              <Image style={{ zIndex: '11', border: 'solid 1px #fff', width: '25px', height: '25px', float: 'left', marginRight: '5px', marginTop: '5px' }} size='mini' src={user.i ?
                getUserImage(projectKey, row.k, user.i)
                : noUserImage} circular
                title={user.n}
                alt={user.n}

                onError={(e) => { e.target.onerror = null; e.target.src = noUserImage }}
              /> */}
            {haveUserData = true}
            {/* <div style={{

                paddingTop: '6px',
                fontSize: '14px',

                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}>{user.n}</div>*/}
            {/* </div> */}
            <div style={{ width: '100%' }}>
              {shiftData}
            </div>
          </div >
        )
      }


    })

    if (innerData)
      innerData = innerData.filter(function (el) {
        return el != null;
      });



    if (innerData && innerData.length > 0 && haveUserData === true)
      return (
        <div key={schedule.sKey} style={{ width: '100vw' }}>
          <div style={{ position: 'absolute', fontWeight: 'bolder', fontSize: '20px', }}>{schedule.sName}</div>
          <div style={{ height: '26px', borderBottom: 'solid px var(--color-border2)', padding: '2px', width: '500px' }}></div>
          {innerData}
        </div>
      )


    return null

  }



  onBtnOpen = () => {
    if (isElectron) {
      ipcRenderer.send('todayWindow-btnopen', true)
    }
  }
  onBtnClose = () => {
    if (isElectron) {
      ipcRenderer.send('todayWindow-btnclose', true)
    }
  }




  scrollToNowRef = () => {
    // if (this.nowRef && this.nowRef.current) {
    //   this.nowRef.current.scrollIntoView({
    //     behavior: 'auto',
    //     block: 'center',
    //     inline: 'center'
    //   })

    // }
  }

  render() {

    const rDate = this.state.renderDate.clone()
    const dateKey = FirestoreListnerHelper.getDateKey(rDate);
    const scheduleWeekData = this.props.fireData['weekview_' + dateKey] || {}

    var m = moment(new Date())
    var posNow = (((m.hour() * 60) + m.minute()) / this.scale) + 40 + 'px'
    // console.log('posNow', posNow)
    let renderData =
      Object.keys(scheduleWeekData).map(key => {
        return this.renderSchedual(scheduleWeekData[key], rDate)
      })

    if (renderData)
      renderData = renderData.filter((el) => el != null)


    //setTimeout(() => {
    this.scrollToNowRef()
    //}, 100);

    return (
      <div className='mainToday' onClick={this.onBtnOpen} >
        {/* <div className='topPointer'></div> */}



        <div style={{ height: '24px' }}>
          <div className='macHeader' style={{ position: 'absolute' }}>{getLocalDate(moment(new Date()), "LL")}</div>
        </div>



        <div style={{ zIndex: '10', position: 'fixed', left: ((0 * 60) / this.scale) + 40 + 'px', fontSize: '10px' }} > <span style={{ position: 'fixed', fontSize: '10px' }}>00</span></div>
        <div style={{ zIndex: '10', position: 'fixed', left: ((2 * 60) / this.scale) + 40 + 'px', fontSize: '10px' }} > <span style={{ position: 'fixed', fontSize: '10px' }}>02</span></div>
        <div style={{ zIndex: '10', position: 'fixed', left: ((4 * 60) / this.scale) + 40 + 'px', fontSize: '10px' }} > <span style={{ position: 'fixed', fontSize: '10px' }}>04</span></div>
        <div style={{ zIndex: '10', position: 'fixed', left: ((6 * 60) / this.scale) + 40 + 'px', fontSize: '10px' }} > <span style={{ position: 'fixed', fontSize: '10px' }}>06</span></div>
        <div style={{ zIndex: '10', position: 'fixed', left: ((8 * 60) / this.scale) + 40 + 'px', fontSize: '10px' }} > <span style={{ position: 'fixed', fontSize: '10px' }}>08</span></div>
        <div style={{ zIndex: '10', position: 'fixed', left: ((10 * 60) / this.scale) + 40 + 'px', fontSize: '10px' }} > <span style={{ position: 'fixed', fontSize: '10px' }}>10</span></div>
        <div style={{ zIndex: '10', position: 'fixed', left: ((12 * 60) / this.scale) + 40 + 'px', fontSize: '10px' }} > <span style={{ position: 'fixed', fontSize: '10px' }}>12</span></div>
        <div style={{ zIndex: '10', position: 'fixed', left: ((14 * 60) / this.scale) + 40 + 'px', fontSize: '10px' }} > <span style={{ position: 'fixed', fontSize: '10px' }}>14</span></div>
        <div style={{ zIndex: '10', position: 'fixed', left: ((16 * 60) / this.scale) + 40 + 'px', fontSize: '10px' }} > <span style={{ position: 'fixed', fontSize: '10px' }}>16</span></div>
        <div style={{ zIndex: '10', position: 'fixed', left: ((18 * 60) / this.scale) + 40 + 'px', fontSize: '10px' }} > <span style={{ position: 'fixed', fontSize: '10px' }}>18</span></div>
        <div style={{ zIndex: '10', position: 'fixed', left: ((20 * 60) / this.scale) + 40 + 'px', fontSize: '10px' }} > <span style={{ position: 'fixed', fontSize: '10px' }}>20</span></div>
        <div style={{ zIndex: '10', position: 'fixed', left: ((22 * 60) / this.scale) + 40 + 'px', fontSize: '10px' }} > <span style={{ position: 'fixed', fontSize: '10px' }}>22</span></div>
        <div style={{ zIndex: '10', position: 'fixed', left: ((24 * 60) / this.scale) + 40 + 'px', fontSize: '10px' }} > <span style={{ position: 'fixed', fontSize: '10px' }}>00</span></div>

        <div style={{ zIndex: '8', background: 'var(--color-bg2)', position: 'absolute', left: '0px', right: '0px', height: '24px' }}></div>
        <div className='mainTodayScroll' id='mainTodayScroll' style={{ position: 'absolute', height: '100%' }}>


          <div style={{ zIndex: '10', bottom: '0px', borderLeft: 'dotted 1px deepskyblue', position: 'fixed', left: posNow, top: '24px' }} ref={this.nowRef}>
            {/* <span style={{ border: 'solid 1px deepskyblue', borderRadius: '8px', position: 'absolute', top: '-15px', left: '-10px', backgroundColor: 'var(--color-bg2)', fontSize: '10px', padding: '1px' }}>{m.format('hh:mm')}</span> */}
          </div>

          <div style={{ zIndex: '10', bottom: '0px', borderLeft: 'dotted 1px var(--color-border2)', position: 'fixed', left: '40px', top: '24px' }} ></div>
          <div style={{ bottom: '0px', borderLeft: 'dotted 1px var(--color-border2)', position: 'fixed', left: ((2 * 60) / this.scale) + 40 + 'px', fontSize: '10px', top: '24px' }} > </div>
          <div style={{ zIndex: '10', bottom: '0px', borderLeft: 'dotted 1px var(--color-border2)', position: 'fixed', left: ((4 * 60) / this.scale) + 40 + 'px', fontSize: '10px', top: '24px' }} > </div>
          <div style={{ zIndex: '10', bottom: '0px', borderLeft: 'dotted 1px var(--color-border2)', position: 'fixed', left: ((6 * 60) / this.scale) + 40 + 'px', fontSize: '10px', top: '24px' }} > </div>
          <div style={{ zIndex: '10', bottom: '0px', borderLeft: 'dotted 1px var(--color-border2)', position: 'fixed', left: ((8 * 60) / this.scale) + 40 + 'px', fontSize: '10px', top: '24px' }} > </div>
          <div style={{ zIndex: '10', bottom: '0px', borderLeft: 'dotted 1px var(--color-border2)', position: 'fixed', left: ((10 * 60) / this.scale) + 40 + 'px', fontSize: '10px', top: '24px' }} > </div>
          <div style={{ zIndex: '10', bottom: '0px', borderLeft: 'dotted 1px var(--color-border2)', position: 'fixed', left: ((12 * 60) / this.scale) + 40 + 'px', fontSize: '10px', top: '24px' }} ></div>
          <div style={{ zIndex: '10', bottom: '0px', borderLeft: 'dotted 1px var(--color-border2)', position: 'fixed', left: ((14 * 60) / this.scale) + 40 + 'px', fontSize: '10px', top: '24px' }} > </div>
          <div style={{ zIndex: '10', bottom: '0px', borderLeft: 'dotted 1px var(--color-border2)', position: 'fixed', left: ((16 * 60) / this.scale) + 40 + 'px', fontSize: '10px', top: '24px' }} ></div>
          <div style={{ zIndex: '10', bottom: '0px', borderLeft: 'dotted 1px var(--color-border2)', position: 'fixed', left: ((18 * 60) / this.scale) + 40 + 'px', fontSize: '10px', top: '24px' }} > </div>
          <div style={{ zIndex: '10', bottom: '0px', borderLeft: 'dotted 1px var(--color-border2)', position: 'fixed', left: ((20 * 60) / this.scale) + 40 + 'px', fontSize: '10px', top: '24px' }} > </div>
          <div style={{ zIndex: '10', bottom: '0px', borderLeft: 'dotted 1px var(--color-border2)', position: 'fixed', left: ((22 * 60) / this.scale) + 40 + 'px', fontSize: '10px', top: '24px' }} > </div>
          <div style={{ zIndex: '10', bottom: '0px', borderLeft: 'dotted 1px var(--color-border2)', position: 'fixed', left: ((24 * 60) / this.scale) + 40 + 'px', fontSize: '10px', top: '24px' }} > </div>




          {/* <div style={{ zIndex:10, height: '100%', width:'40px', backgroundColor:'var(--color-bg2)', position: 'fixed', left: '0px' ,fontSize:'10px' }} ></div> */}



          {/* <div style={{overflowY:'auto',overflowX:'hidden',height:'600px',border:'solid 0px red',position:'relative',width:'1000px'}}> */}
          {/* <Loader active={!this.props.isDataLoaded} style={{ marginTop: '50px' }}>{window._getText('loading')}</Loader> */}
          {(renderData.length === 0 && this.props.isDataLoaded) &&
            <div style={{ textAlign: 'center', marginTop: '60px', marginBottom: '20px' }}>
              <Icon name='calendar outline' size='huge'></Icon><br />
              There is no shifts scheduled today.
            </div>
          }
          <div style={{ height: '25px' }}></div>
          {renderData.length > 0 && renderData}
          <div style={{ height: '20px' }}></div>

          {/* </div> */}
        </div>
        {/* <div className='macFooter'  > */}

        {/* <Button compact style={{ fontSize: '10px', margin: '4px', marginBottom: '4px' }} onClick={this.onBtnOpen} >open</Button> */}
        {/* <Button compact style={{ fontSize: '10px', margin: '4px', marginBottom: '4px' }} onClick={this.onBtnClose} >close</Button> */}
        {/* </div> */}

      </div >

    )
  }
}


const mapStateToProps = (state, ownProps, ownState) => {


  const inSysUserKey = state.firebase.profile.inSysUserKey
  const firstDayOfWeekNumber = Number(state.firebase.profile.firstDay) //sunday 0 , monday 1


  var isUnknown = true
  if (inSysUserKey)
    isUnknown = false;



  return ({
    isDataLoaded: state.firestore.status.requested[`weekview/${state.viewstate.renderDateKey}`],
    renderDate: state.viewstate.renderDate,
    renderDateKey: state.viewstate.renderDateKey,
    projectKey: state.firebase.profile.projectKey,
    project: state.firestore.data.project,
    fireRequested: state.firestore.status.requested,
    fireData: state.firestore.data,

    isLandscape: state.viewstate.isLandscape,
    currentUser: state.firebase.profile,
    firstDayOfWeekNumber,
    isUnknown,
    inSysUserKey,

  });
}
const mapActionsFromProps = {
  onOpenModal: openModal,
  renderDateAddDays,
  setRenderNow
}
export default connect(
  mapStateToProps,
  mapActionsFromProps
)(ScheduleTodayTaskbar);

