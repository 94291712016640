import React, { Component } from "react";
import { isMobile, isElectron } from "react-device-detect";
import { Button, Image } from "../../common/component/Button";
import collection from "lodash/collection";
import { closeModal, openModal } from "../../../core/redux/action/modalAction";
import { connect } from "react-redux";
import noUserImage from "../../../img/noimg.png";
//import { height } from 'window-size';
import ModalFrame from "./ModalFrame";
import { FireHelper } from "../../../core/util/FireHelper";
import { getUserlist } from "../../../core/redux/selectors";

const modalKey = "selectUsers";
export class SelectUsers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isNew: true,
      valueName: this.props.sName,
      error: true,
      addErrorText: "",
      searchText: "",
      abcBtn: "ALL",
      selectedUsers: {},
    };
  }
  componentDidMount = () => {
    // if (this.state.searchList === undefined)
    //console.log('this.getUsers')

    this.getUsers();
  };
  componentDidUpdate(prevProps, prevState, snapshot) {
    //on open
    // console.log('allUsers', this.props.allUsers)
    // this.props.allUsers.forEach(user => {
    //     if (user.selected === true)
    //         console.log(user.text, user.selected)

    // });
    if (prevProps.isOpen === false && this.props.isOpen === true) {
      var selectedUsers = {};
      if (this.props.allUsers)
        this.props.allUsers.forEach((user) => {
          if (user && user.selected) selectedUsers[user.key] = { ...user };
        });
      this.setState({
        isLoading: false,
        searchText: "",
        abcBtn: "All",
        searchList: undefined,
        selectedUsers,
        addErrorText: "",
      });
    } else if (prevProps.isOpen === true && this.props.isOpen === false) {
    } else {
      if (
        this.props &&
        prevProps &&
        prevProps.allUsers &&
        this.props.allUsers.length !== prevProps.allUsers.length &&
        this.props.isOpen === true
      ) {
        this.getUsers();
      } else {
        if (this.state.searchList === undefined) {
          this.getUsers();
        }
      }
    }
  }

  onCloseModal = () => {
    this.props.onCloseModal(modalKey);
    // if (this.props.allUsers)
    //     this.props.allUsers.forEach(user => {
    //         if (user && user.selected)
    //             delete user.selected
    //     });
  };

  onClickDelete = () => {
    // this.setState({ isLoading: true })
    // doShit.then(() => {
    //     this.setState({ isLoading: false })
    //     this.onCloseModal()
    // })
  };

  // onChangeText = (e) => {
  //     this.setState({ valueName: e.target.value, error: (e.target.value.length < 1) });
  // }
  onClickSave = () => {
    //console.log('allUsers>>', { ...this.props.allUsers })
    var selectedUsers = Object.keys(this.state.selectedUsers).map((element) => {
      return this.state.selectedUsers[element];
    });

    if (selectedUsers && selectedUsers.length > 0) {
      this.props.onAddSelectedUsers(selectedUsers);
      this.props.onCloseModal(modalKey);
      if (this.props.allUsers) {
        this.props.allUsers.forEach((user) => {
          if (user && user.selected) delete user.selected;
        });
      }
    } else {
      this.setState({ addErrorText: window._getText("selectuser.warning") });
    }
  };
  selectUser = (user) => () => {
    var { selectedUsers } = this.state;

    var u = { ...user };
    var oldU = selectedUsers[u.key] || {};
    u.selected = !oldU.selected;
    selectedUsers[u.key] = u;
    if (u.selected === false) delete selectedUsers[u.key];

    this.setState({
      reRender: true,
      selectedUsers: selectedUsers,
      addErrorText: "",
    });
    if (this.props.onSelect) {
      if (this.props.onSelect(user) === true) this.onCloseModal();
    }
  };
  lastChar = "";
  listUser = (user, selectedUsers) => {
    // console.log('>>listUser')
    if (!user) return null;

    const u = selectedUsers[user.key] || {};

    var firstChar = (user.text || "").substr(0, 1).toUpperCase();
    var divHeader = null;

    if (firstChar !== this.lastChar) {
      this.lastChar = firstChar;
      divHeader = (
        <div
          key={firstChar}
          style={{
            borderBottom: "dotted 1px var(--color-border)",
            fontWeight: "bold",
            paddingTop: "8px",
            paddingLeft: "4px",
          }}
        >
          {firstChar}
        </div>
      );
    }

    return (
      <React.Fragment key={user.key}>
        {divHeader}

        <div
          className="noSelect selectUserBtn"
          onClick={this.selectUser(user)}
          key={user.key}
          style={{
            cursor: "pointer",

            margin: "2px",
            borderRadius: "0px",
            padding: "5px",
            paddingBottom: "8px",
            display: "flex",
          }}
        >
          <i
            aria-hidden="true"
            className="check icon"
            style={{
              color:
                u.selected === true
                  ? "rgba(33, 186, 69, 0.9)"
                  : "rgba(255, 255, 255, 1)",
            }}
          ></i>
          <Image
            style={{ width: "25px", marginLeft: "5px", marginRight: "10px" }}
            size="mini"
            src={user.image ? user.image : noUserImage}
            circular
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = noUserImage;
            }}
          />
          {user.text}
        </div>
      </React.Fragment>
    );
  };
  tbSearchChange = (e) => {
    this.setState({ searchText: e.target.value, abcBtn: "ALL" }, this.getUsers);
  };
  onCreateNewUser = () => {
    this.props.onOpenModal("user");
  };

  styles = {
    input1: { width: "98%", marginRight: "20px" },
    btnABC: { width: "40px", margin: "1px" },
  };

  filter = (abc) => () => {
    this.setState({ abcBtn: abc }, this.getUsers);
  };

  scrollTop = () => {
    var element = document.getElementById("modalBodyScroll_" + this.props.name);
    if (element) element.scroll({ top: 0, behavior: "smooth" });
  };

  getUsers = () => {
    //console.log('>>>>------------------>>getUsers')
    var { allUsers } = this.props;
    const abcBtn = this.state.abcBtn.toLowerCase();

    var allUsers2 = collection.sortBy(allUsers, [(u) => u.text.toLowerCase()]);
    const search = (this.state.searchText || "").toLowerCase();
    var searchList = [];
    if (abcBtn !== "all") {
      searchList = collection.filter({ ...allUsers2 }, (obj, key, x) => {
        if (!obj || !obj.key) return;
        //obj.key = key;// REMOVED , == error

        return (obj.text || "").toLowerCase().startsWith(abcBtn);
      });
    } else {
      searchList = collection.filter({ ...allUsers2 }, (obj, key, x) => {
        if (!obj || !obj.key) return;
        //obj.key = key;// REMOVED , == error
        return (obj.text || "").toLowerCase().indexOf(search) > -1;
      });
    }
    this.setState({ searchList }, this.scrollTop);
  };

  render() {
    const { selectedUsers } = this.state;
    const abcBtn = this.state.abcBtn;
    var searchList = this.state.searchList || [];

    var ABC = [
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z",
    ];

    return (
      <ModalFrame
        name={this.props.name || "selectusersModal"}
        className="modalAniSlideUp"
        dimmer="inverted"
        size="large"
        open={this.props.isOpen}
        onClickClose={this.onCloseModal}
        headerspace={"110px"}
      >
        {true == false && (
          <ModalFrame.Header>
            <div id="userScrollTo">
              <div
                className="ui input"
                style={{ width: "100%", paddingRight: "5px" }}
              >
                <input
                  icon="search"
                  placeholder={window._getText("search")}
                  onChange={(e) => {
                    this.tbSearchChange(e);
                  }}
                  onClick={(e) => {
                    this.tbSearchChange(e);
                  }}
                  style={this.styles.input1}
                />
              </div>
            </div>
            <div
              style={{
                display: isMobile ? "flex" : "block",
                paddingTop: "5px",
                border: "solid 0px red",
              }}
            >
              {isMobile && (
                <div
                  style={{
                    minWidth: "100px",
                    maxWidth: "100px",
                    display: "flex",
                    flexWrap: "wrap",
                    flexDirection: "column",
                    maxHeight: "500px",
                  }}
                >
                  <Button
                    compact
                    icon="users"
                    color={abcBtn === "ALL" ? "blue" : null}
                    onClick={this.filter("ALL")}
                    style={this.styles.btnABC}
                  ></Button>
                  {ABC.map((abc) => (
                    <Button
                      key={abc}
                      compact
                      color={abcBtn === abc ? "blue" : null}
                      style={this.styles.btnABC}
                      onClick={this.filter(abc)}
                    >
                      {abc}
                    </Button>
                  ))}
                </div>
              )}
              {!isMobile && (
                <div
                  style={{ width: "100%", display: "flex", flexWrap: "wrap" }}
                >
                  <Button
                    compact
                    icon="users"
                    color={abcBtn === "ALL" ? "blue" : null}
                    onClick={this.filter("ALL")}
                    style={this.styles.btnABC}
                  ></Button>
                  {/* <Button compact icon='calendar' color={abcBtn === 'SCHEDULE' ? 'blue' : null} onClick={this.filter('SCHEDULE')} style={this.styles.btnABC} ></Button> */}

                  {ABC.map((abc) => (
                    <Button
                      key={abc}
                      compact
                      color={abcBtn === abc ? "blue" : null}
                      style={this.styles.btnABC}
                      onClick={this.filter(abc)}
                    >
                      {abc}
                    </Button>
                  ))}
                </div>
              )}
            </div>
          </ModalFrame.Header>
        )}
        <ModalFrame.Header></ModalFrame.Header>

        <ModalFrame.Content>
          <div
            style={{
              paddingBottom: "10px",
              paddingTop: "5px",
              paddingLeft: "10px",
              paddingRight: "10px",
              color: "#000",
              overflowX: "hidden",
              overflowY: "auto",
            }}
          >
            <input
              className="form-control"
              icon="search"
              placeholder={window._getText("search")}
              onChange={(e) => {
                this.tbSearchChange(e);
              }}
              onClick={(e) => {
                this.tbSearchChange(e);
              }}
              style={this.styles.input1}
            />
          </div>
          <div
            style={{
              width: "100%",
              height: "100%",
              overflowX: "hidden",
              overflowY: "auto",
              border: "solid 0px red",
              maxHeight: isElectron
                ? "calc(100vh - 110px)"
                : "calc(100vh - 250px)",
            }}
          >
            {searchList.map((u) => this.listUser(u, selectedUsers))}
          </div>
          {/* <div style={{ flexGrow: '2', border: 'solid 0px red' }}>3</div> */}
        </ModalFrame.Content>
        <ModalFrame.Actions>
          {this.props.isMultiSelect && this.props.role > 199 && (
            <div style={{ border: "solid 0px red" }}>
              <Button
                icon="add user"
                content={window._getText("login.newUser")}
                onClick={this.onCreateNewUser}
              />
            </div>
          )}

          {!this.props.isMultiSelect && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                border: "solid 0px red",
              }}
            >
              {this.props.canCreateNew && (
                <React.Fragment>
                  {this.props.role > 199 && (
                    <Button
                      icon="add user"
                      content={window._getText("login.newUser")}
                      onClick={this.onCreateNewUser}
                    />
                  )}
                  <Button
                    onClick={this.onClickSave}
                    icon="check"
                    content={window._getText("add")}
                    style={{ marginLeft: "auto" }}
                  ></Button>
                </React.Fragment>
              )}

              {!this.props.canCreateNew && (
                <Button
                  icon="check"
                  onClick={this.onClickSave}
                  style={{ marginLeft: "auto" }}
                ></Button>
              )}
            </div>
          )}
        </ModalFrame.Actions>
      </ModalFrame>
    );
  }
}

const mapActions = {
  onCloseModal: closeModal,
  onOpenModal: openModal,
};

const mapStateToProps = (state, ownProps) => {
  var isOpen = state.modal.modals[modalKey].open || false;

  var allUsers = [];

  if (isOpen) {
    allUsers = ownProps.availableUsers;
    if (!allUsers) {
      //default load form state
      allUsers = getUserlist(state).userList; // FireHelper.getUserlist(state.firestore.data.users, state.firebase.profile.projectKey).userList;

      //allUsers = FireHelper.getUserlist(state.firestore.data.users, state.firebase.profile.projectKey).userList;
    }

    if (allUsers) allUsers = allUsers.filter((u) => u.act === true);
  }
  // console.log('>>mapStateToProps>>ownProps.availableUsers', ownProps.availableUsers)
  // console.log('>>>mapStateToProps>>allUsers', allUsers)

  // console.log('ownProps', ownProps)
  // console.log('state', state)

  // if (ownProps.availableUsers)
  //     ownProps.availableUsers.forEach(user => {
  //         console.log('#0 ' + user.text, user.selected)
  //     });

  // allUsers.forEach(user => {
  //     console.log('#1 ' + user.text, user.selected)
  // });

  // console.log('this.props', this.props)
  // if (this.props.allUsers)
  //     this.props.allUsers.forEach(user => {
  //         console.log('#2 ' + user.text, user.selected)
  //     });

  return {
    allUsers: allUsers,
    projectKey: state.firebase.profile.projectKey,
    role: state.firebase.profile.role || 0,
    isOpen,
    data: state.modal.modals[modalKey].data || {},
  };
};
export default connect(mapStateToProps, mapActions)(SelectUsers);
