import React, { PureComponent } from 'react'
import { renderTimeLable } from '../../../../core/util/helpers';
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {

  faMapMarkerQuestion as icoRequests,
  faCommentLines as icoComment,
  faBed as icoSick
} from '@fortawesome/pro-duotone-svg-icons';
class ScheduleCell extends PureComponent {
  state = {hourSalery:this.props.hourSalary, popupIsOpen: false, labelText: null, labelTextRaw: null, labelHeight: '14px' }

  onCellDragStart = (e) => {

    this.setState({ popupIsOpen: false })
    this.props.onDragStart(e);
  }
  onCellClick = (e) => {
    this.props.onOpenEventModal(e, this.props.event);
  }
  getLabel = (forceUpdate) => {
    // console.log('render lable')
    //     if (!this.state.labelText || forceUpdate === true) {

    //       var shift = this.props.event
    //       var lbl = renderTimeLable(shift.label || '', shift.startDate, shift.stopDate)
    //       this.setState({ labelText: lbl, labelTextRaw: shift.label })
    //     }


  }
  textColor = (event) => {
    return { color: event.sick ? 'silver' : '' }
  }
  renderLabel = () => {


    const { event, userkey, onDragOverHideDropZone, AmPm } = this.props
    var labelText = renderTimeLable(event.label || '', event.startDate, event.stopDate, AmPm)
    //console.log('event', event.sick)
    var _textColor = this.textColor(event)
    const cost = Math.round(Number((this.props.hourSalary/60) || 0) * Number(event.minutes || 0))
    return (
      <div
        // ref={r => this.content = r}
        title={`Cost:${cost}`}
        onClick={this.onCellClick}
        // onDoubleClick={this.onCellClick}

        draggable={event.requests ? false : true}
        userkey={userkey}
        onDragStart={event.requests ? null : this.onCellDragStart}
        onDragEnd={event.requests ? null : onDragOverHideDropZone}
        id={event.key}
        // TODO: add this to the first row/cell tour_schedule_6 
        className={'sCel x' + (event.sick ? '' : event.color)}

      >






        <b>{labelText}</b>
        {event.comment && <span style={_textColor}>{event.comment}</span>}
        {/* {event.meta && <br />} */}
        {event.userCom && <span><FontAwesomeIcon icon={icoComment} style={styles.fontSize}></FontAwesomeIcon> {event.userCom}<br /></span>}
        {/* {event.meta && event.meta['deviation'] && <span> <Icon name='tag' size='small' /></span>}
        {event.meta && event.meta['comment'] && <span> <Icon name='comment' size='small' /></span>}
        {event.meta && event.meta['change'] && <span> <Icon name='comments' size='small' /></span>}
        {event.meta && event.meta['sick'] && <span> <Icon name='bed' size='small' /></span>} */}
        {event.sick && <span style={{ ..._textColor, display: 'block' }}><FontAwesomeIcon icon={icoSick} style={styles.fontSize}></FontAwesomeIcon> Sick</span>}
        {event.requests && <span style={{ ..._textColor, display: 'block' }}><FontAwesomeIcon icon={icoRequests} style={styles.fontSize}></FontAwesomeIcon> Request pending</span>}



        {/* <span>Cost:{cost}</span> */}
      </div>
    )

  }

  render() {

    //console.log('render celll')
    return this.renderLabel()


  }


}

export default ScheduleCell;

const styles = {

  fontSize: { fontSize: '14px' },

}