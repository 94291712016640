import React, { PureComponent } from 'react'
import Joyride, { STATUS } from 'react-joyride';
import { withGlobalState } from '../../../globalState'


//const step = require('./joyride/tour');


export class JoyriderTour extends PureComponent {

    Steps = []
    getPageSteps = () => {
        switch (window.location.pathname) {
            case '/schedule/users':
                return { stepsData: require('./joyride/tour')('staff'), stepKey: 'staff' }
                break;
            case '/reports':
                return { stepsData: require('./joyride/tour')('reports'), stepKey: 'reports' }
                break;

            case '/tour':
                return { stepsData: require('./joyride/tour')('tour'), stepKey: 'tour' }
                break;
            case '/requests':
                return { stepsData: require('./joyride/tour')('requests'), stepKey: 'requests' }
                break;
            case '/chat':
                return { stepsData: require('./joyride/tour')('chat'), stepKey: 'chat' }
                break;
            case '/today':
                return { stepsData: require('./joyride/tour')('today'), stepKey: 'today' }
                break;

            case '/tasks':
                return { stepsData: require('./joyride/tour')('tasks'), stepKey: 'tasks' }
                break;
            default:
                if (window.location.pathname.indexOf('schedule') > -1)
                    return { stepsData: require('./joyride/tour')('schedule'), stepKey: 'schedule' }

                break;
        }
        return { stepsData: [], stepKey: 'Steps' }

    }






    handleJoyrideCallback = (data) => {
        // eslint-disable-next-line no-unused-vars
        const { action, index, type, status, lifecycle } = data;
        const { stepKey } = this.getPageSteps()

        if (([STATUS.FINISHED, STATUS.SKIPPED]).includes(status)) {
            this.props.global.setState({ showTour: false })
        }
        else if (action === 'close') {
            this.props.global.setState({ showTour: false })
            this.setState({ refresh: '' })
        }


    }



    render() {
        const { stepsData, stepKey } = this.getPageSteps(window.location.pathname)
        if (stepsData.length === 0)
            return null

        return (
            <Joyride
                locale={{ back: window._getText('joy.Back'), close: window._getText('joy.Close'), last: window._getText('joy.Last'), next: window._getText('joy.Next'), skip: window._getText('joy.Skip') }}
                key={stepKey}
                steps={stepsData}
                continuous={true}
                run={this.props.global.state.showTour}
                styles={{
                    options: {

                    }
                }}
                hideBackButton={true}
                disableScrolling={true}
                scrollToFirstStep={false}
                showProgress={true}
                showSkipButton={false}
                callback={this.handleJoyrideCallback}

            />
        )
    }
}

export default withGlobalState(JoyriderTour)
