import React, { Component } from 'react';




const colors = [
  "red",
  "orange",
  "yellow",
  "olive",
  "green",
  "teal",
  "blue",
  "violet",
  "purple",
  "pink",
  "brown",
  "grey",
  "black"
];

const colorsCodes = [
  'rgba(219, 40, 40, 0.4)',
  'rgba(242, 113, 28, 0.4)',
  'rgba(251, 189, 8, 0.4)',
  'rgba(181, 204, 24, 0.4)',
  'rgba(33, 186, 69, 0.4)',
  'rgba(2, 195, 154, 0.4)',
  'rgba(33, 133, 208, 0.4)',
  'rgba(100, 53, 201, 0.4)',
  'rgba(163, 51, 200, 0.4)',
  'rgba(224, 57, 151, 0.4)',
  'rgba(165, 103, 63, 0.4)',
  'rgba(118, 118, 118, 0.4)',
  'rgba(27, 28, 29, 0.4)'
]
export default class ColorSlider extends Component {
  state = { colorIndex: -1 }


  componentDidMount = () => {
    this.setValue()
  }
  componentDidUpdate = (prevProps, prevState, snapshot) => {

    if (prevProps.input.value !== this.props.input.value)
      this.setValue()


  }
  setValue = () => {
    const { input } = this.props
    if (input.value) {
      const colorIndex = colors.indexOf(input.value)
      this.setState({ colorIndex: Number(colorIndex) })
    }

  }
  changeValue = () => {
    this.setState({ show: false })
    this.props.input.onChange(colors[this.state.colorIndex])
  }
  //handleChange = (event) => this.setState({ colorIndex: Number(event.target.value) }, () => { this.changeValue() });

  setColor = (event) => {
    // console.log(event.target.value);
    const colorIndex = colors.indexOf(event.target.value)
    this.setState({ colorIndex: Number(colorIndex) }, () => { this.changeValue() });
    this.props.toggle()
  }

  render() {
    const { input, isMobile, lable } = this.props

    if (this.state.colorIndex === -1)
      return (null)


    return (

      <div style={{ position: 'absolute', maxWidth: '100px', right: '18px', top: '10px' }}>
        <input onChange={(param, data) => input.onChange(data.value)} {...input} type='hidden' />

        {!isMobile && <span style={{ width: '100%' }} >
          {lable && <b>{lable}:</b>}

        </span>}




        {!this.props.showColor ?
          <div onClick={this.props.toggle} style={{ width: '22px', height: '22px', borderRadius: '22px', border: 'solid 2px #f1f1f1', backgroundColor: colorsCodes[this.state.colorIndex] }}></div>
          :
          <div onMouseDown={(e) => { e.stopPropagation() }} onChange={this.setColor} style={{
            display: 'flex',
            flexWrap: 'wrap'
          }}>

            {colors.map((item, index) => {
              return (
                <div style={{ paddingRight: '1px', paddingBottom: '1px' }} key={item} className='noSelect'>
                  <input style={{ display: 'none' }} type="radio" id={item} value={item} name="color" defaultChecked={this.state.colorIndex === index} />
                  <label htmlFor={item} style={{ backgroundColor: colorsCodes[index], borderRadius: '1px', content: '', width: '13px', height: '10px', display: 'block' }}>{'\u00A0'}</label>
                </div>

              )
            })}



          </div>
        }

        {/* <div style={{ border: 'solid 0px green', margin: '0px', padding: '0px' }}>
          <input
            type="range"
            min="0" max="12"
            //defaultValue="0"
            value={this.state.colorIndex || 0}
            onChange={this.handleChange}
            step="1"
            style={{ width: '100%', left: '0px', top: '0px', margin: '0px' }}
          />
        </div> */}
      </div>

    )

  }
}