module.exports = [{ countryCode: 'AF', currency: 'AFN', label: 'Afghan Afghani' },
{ countryCode: 'AL', currency: 'ALL', label: 'Albanian Lek' },
{ countryCode: 'DZ', currency: 'DZD', label: 'Algerian Dinar' },
{ countryCode: 'AO', currency: 'AOA', label: 'Angolan Kwanza' },
{ countryCode: 'AR', currency: 'ARS', label: 'Argentine Peso' },
{ countryCode: 'AM', currency: 'AMD', label: 'Armenian Dram' },
{ countryCode: 'AW', currency: 'AWG', label: 'Aruban Florin' },
{ countryCode: 'AU', currency: 'AUD', label: 'Australian Dollar' },
{ countryCode: 'AZ', currency: 'AZN', label: 'Azerbaijan New Manat' },
{ countryCode: 'BS', currency: 'BSD', label: 'Bahamian Dollar' },
{ countryCode: 'BH', currency: 'BHD', label: 'Bahraini Dinar' },
{ countryCode: 'BD', currency: 'BDT', label: 'Bangladeshi Taka' },
{ countryCode: 'BB', currency: 'BBD', label: 'Barbados Dollar' },
{ countryCode: 'BY', currency: 'BYR', label: 'Belarusian Ruble' },
{ countryCode: 'BZ', currency: 'BZD', label: 'Belize Dollar' },
{ countryCode: 'BM', currency: 'BMD', label: 'Bermudian Dollar' },
{ countryCode: 'BT', currency: 'BTN', label: 'Bhutan Ngultrum' },
{ countryCode: 'BO', currency: 'BOB', label: 'Bolivian Mvdol' },
{ countryCode: 'BA', currency: 'BAM', label: 'Bosnian Mark' },
{ countryCode: 'BW', currency: 'BWP', label: 'Botswana Pula' },
{ countryCode: 'BR', currency: 'BRL', label: 'Brazilian Real' },
{ countryCode: 'BN', currency: 'BND', label: 'Brunei Dollar' },
{ countryCode: 'BG', currency: 'BGN', label: 'Bulgarian Lev' },
{ countryCode: 'BI', currency: 'BIF', label: 'Burundi Franc' },
{ countryCode: 'BJ', currency: 'XOF', label: 'CFA Franc BCEAO' },
{ countryCode: 'CM', currency: 'XAF', label: 'CFA Franc BEAC' },
{ countryCode: 'NC', currency: 'XPF', label: 'CFP Franc' },
{ countryCode: 'KH', currency: 'KHR', label: 'Cambodian Riel' },
{ countryCode: 'CA', currency: 'CAD', label: 'Canadian Dollar' },
{ countryCode: 'CV', currency: 'CVE', label: 'Cape Verde Escudo' },
{ countryCode: 'KY', currency: 'KYD', label: 'Cayman Islands Dollar' },
{ countryCode: 'CL', currency: 'CLP', label: 'Chilean Peso' },
{ countryCode: 'KM', currency: 'KMF', label: 'Comoros Franc' },
{ countryCode: 'ZR', currency: 'CDF', label: 'Congolese Franc' },
{ countryCode: 'CR', currency: 'CRC', label: 'Costa Rican Colon' },
{ countryCode: 'CZ', currency: 'CZK', label: 'Czech Koruna' },
{ countryCode: 'DK', currency: 'DKK', label: 'Danish Krone' },
{ countryCode: 'DJ', currency: 'DJF', label: 'Djibouti Franc' },
{ countryCode: 'DO', currency: 'DOP', label: 'Dominican Peso' },
{ countryCode: 'AI', currency: 'XCD', label: 'East Carribean Dollar' },
{ countryCode: 'EG', currency: 'EGP', label: 'Egyptian Pound' },
{ countryCode: 'ER', currency: 'ERN', label: 'Eritrean Nakfa' },
{ countryCode: 'ET', currency: 'ETB', label: 'Ethiopian Birr' },
{ countryCode: 'ALA', currency: 'EUR', label: 'Euro' },
{ countryCode: 'FK', currency: 'FKP', label: 'Falkland Islands Pound' },
{ countryCode: 'FJ', currency: 'FJD', label: 'Fiji Dollar' },
{ countryCode: 'GM', currency: 'GMD', label: 'Gambian Dalasi' },
{ countryCode: 'GE', currency: 'GEL', label: 'Georgian Lari' },
{ countryCode: 'GH', currency: 'GHS', label: 'Ghanaian New Cedi' },
{ countryCode: 'GI', currency: 'GIP', label: 'Gibraltar Pound' },
{ countryCode: 'GT', currency: 'GTQ', label: 'Guatemalan Quetzal' },
{ countryCode: 'GN', currency: 'GNF', label: 'Guinea Franc' },
{ countryCode: 'GY', currency: 'GYD', label: 'Guyanan Dollar' },
{ countryCode: 'HT', currency: 'HTG', label: 'Haitian Gourde' },
{ countryCode: 'HN', currency: 'HNL', label: 'Honduran Lempira' },
{ countryCode: 'HK', currency: 'HKD', label: 'Hong Kong Dollar' },
{ countryCode: 'HU', currency: 'HUF', label: 'Hungarian Forint' },
{ countryCode: 'IS', currency: 'ISK', label: 'Iceland Krona' },
{ countryCode: 'IN', currency: 'INR', label: 'Indian Rupee' },
{ countryCode: 'ID', currency: 'IDR', label: 'Indonesian Rupiah' },
{ countryCode: 'IQ', currency: 'IQD', label: 'Iraqi Dinar' },
{ countryCode: 'IL', currency: 'ILS', label: 'Israeli New Shekel' },
{ countryCode: 'JM', currency: 'JMD', label: 'Jamaican Dollar' },
{ countryCode: 'JP', currency: 'JPY', label: 'Japanese Yen' },
{ countryCode: 'JO', currency: 'JOD', label: 'Jordanian Dinar' },
{ countryCode: 'KZ', currency: 'KZT', label: 'Kazakhstan Tenge' },
{ countryCode: 'KE', currency: 'KES', label: 'Kenyan Shilling' },
{ countryCode: 'KR', currency: 'KRW', label: 'Korean Won' },
{ countryCode: 'KW', currency: 'KWD', label: 'Kuwaiti Dinar' },
{ countryCode: 'KG', currency: 'KGS', label: 'Kyrgyzstanian Som' },
{ countryCode: 'LA', currency: 'LAK', label: 'Lao Kip' },
{ countryCode: 'LB', currency: 'LBP', label: 'Lebanese Pound' },
{ countryCode: 'LS', currency: 'LSL', label: 'Lesotho Loti' },
{ countryCode: 'LR', currency: 'LRD', label: 'Liberian Dollar' },
{ countryCode: 'LY', currency: 'LYD', label: 'Libyan Dinar' },
{ countryCode: 'MO', currency: 'MOP', label: 'Macanese pataca' },
{ countryCode: 'MK', currency: 'MKD', label: 'Macedonian Denar' },
{ countryCode: 'MW', currency: 'MWK', label: 'Malawi Kwacha' },
{ countryCode: 'MY', currency: 'MYR', label: 'Malaysian Ringgit' },
{ countryCode: 'MV', currency: 'MVR', label: 'Maldive Rufiyaa' },
{ countryCode: 'MU', currency: 'MUR', label: 'Mauritius Rupee' },
{ countryCode: 'MX', currency: 'MXN', label: 'Mexican peso' },
{ countryCode: 'MD', currency: 'MDL', label: 'Moldovan Leu' },
{ countryCode: 'MN', currency: 'MNT', label: 'Mongolian Tugrik' },
{ countryCode: 'MA', currency: 'MAD', label: 'Moroccan Dirham' },
{ countryCode: 'MZ', currency: 'MZN', label: 'Mozambique New Metical' },
{ countryCode: 'MM', currency: 'MMK', label: 'Myanmar Kyat' },
{ countryCode: 'NA', currency: 'NAD', label: 'Namibia Dollar' },
{ countryCode: 'NP', currency: 'NPR', label: 'Nepalese Rupee' },
{ countryCode: 'CK', currency: 'NZD', label: 'New Zealand Dollar' },
{ countryCode: 'NI', currency: 'NIO', label: 'Nicaraguan Cordoba Oro' },
{ countryCode: 'NG', currency: 'NGN', label: 'Nigerian Naira' },
{ countryCode: 'BV', currency: 'NOK', label: 'Norwegian Kroner' },
{ countryCode: 'OM', currency: 'OMR', label: 'Omani Rial' },
{ countryCode: 'PK', currency: 'PKR', label: 'Pakistan Rupee' },
{ countryCode: 'PA', currency: 'PAB', label: 'Panamanian balboa' },
{ countryCode: 'PG', currency: 'PGK', label: 'Papua New Guinea Kina' },
{ countryCode: 'PY', currency: 'PYG', label: 'Paraguay Guarani' },
{ countryCode: 'PE', currency: 'PEN', label: 'Peruvian Nuevo Sol' },
{ countryCode: 'PH', currency: 'PHP', label: 'Philippine Peso' },
{ countryCode: 'PL', currency: 'PLN', label: 'Polish Zloty' },
{ countryCode: 'QA', currency: 'QAR', label: 'Qatari Rial' },
{ countryCode: 'RO', currency: 'RON', label: 'Romanian New Leu' },
{ countryCode: 'RU', currency: 'RUB', label: 'Russian Rouble' },
{ countryCode: 'RW', currency: 'RWF', label: 'Rwandan Franc' },
{ countryCode: 'WS', currency: 'WST', label: 'Samoan Tala' },
{ countryCode: 'ST', currency: 'STD', label: 'Sao Tome/Principe Dobra' },
{ countryCode: 'SA', currency: 'SAR', label: 'Saudi Riyal' },
{ countryCode: 'SRB', currency: 'RSD', label: 'Serbian Dinar' },
{ countryCode: 'SC', currency: 'SCR', label: 'Seychelles Rupee' },
{ countryCode: 'SL', currency: 'SLL', label: 'Sierra Leone Leone' },
{ countryCode: 'SG', currency: 'SGD', label: 'Singapore Dollar' },
{ countryCode: 'SB', currency: 'SBD', label: 'Solomon Islands Dollar' },
{ countryCode: 'SO', currency: 'SOS', label: 'Somali Shilling' },
{ countryCode: 'ZA', currency: 'ZAR', label: 'South African Rand' },
{ countryCode: 'LK', currency: 'LKR', label: 'Sri Lanka Rupee' },
{ countryCode: 'ASC', currency: 'SHP', label: 'St. Helena Pound' },
{ countryCode: 'SR', currency: 'SRD', label: 'Suriname Dollar' },
{ countryCode: 'SZ', currency: 'SZL', label: 'Swaziland Lilangeni' },
{ countryCode: 'SE', currency: 'SEK', label: 'Swedish Krona' },
{ countryCode: 'CH', currency: 'CHF', label: 'Swiss Franc' },
{ countryCode: 'TW', currency: 'TWD', label: 'Taiwan Dollar' },
{ countryCode: 'TJ', currency: 'TJS', label: 'Tajikistan Somoni' },
{ countryCode: 'TZ', currency: 'TZS', label: 'Tanzanian Shilling' },
{ countryCode: 'TH', currency: 'THB', label: 'Thai Baht' },
{ countryCode: 'TO', currency: 'TOP', label: 'Tongan Paanga' },
{ countryCode: 'TT', currency: 'TTD', label: 'Trinidad/Tobago Dollar' },
{ countryCode: 'TN', currency: 'TND', label: 'Tunisian Dinar' },
{ countryCode: 'TR', currency: 'TRY', label: 'Turkish Lira' },
{ countryCode: 'TM', currency: 'TMT', label: 'Turkmenistan New Manat' },
{ countryCode: 'US', currency: 'USD', label: 'US Dollar' },
{ countryCode: 'UG', currency: 'UGX', label: 'Uganda Shilling' },
{ countryCode: 'UA', currency: 'UAH', label: 'Ukraine Hryvnia' },
{ countryCode: 'CO', currency: 'COP', label: 'Unidad de Valor Real' },
{ countryCode: 'UK', currency: 'GBP', label: 'United Kingdom Pound' },
{ countryCode: 'UY', currency: 'UYU', label: 'Uruguayan Peso' },
{ countryCode: 'AE', currency: 'AED', label: 'Utd. Arab Emir. Dirham' },
{ countryCode: 'UZ', currency: 'UZS', label: 'Uzbekistan Som' },
{ countryCode: 'VU', currency: 'VUV', label: 'Vanuatu Vatu' },
{ countryCode: 'VE', currency: 'VEF', label: 'Venezuelan Bolivar Fuerte' },
{ countryCode: 'VN', currency: 'VND', label: 'Vietnamese Dong' },
{ countryCode: 'YE', currency: 'YER', label: 'Yemen Rial' },
{ countryCode: 'ZM', currency: 'ZMW', label: 'Zambian Kwacha' },
{ countryCode: 'ZW', currency: 'ZWL', label: 'Zimbabwe Dollar' }]
