import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


export const Item = ({ children }) => {

    if (Array.isArray(children)) {
        return (
            children.map(child => {
                return child;
            })
        )
    }

    if (children) {
        if (typeof children === 'string' || children instanceof String)
            return children

        return (
            { ...children }
        )
    }

    return null
}
Item.displayName = "Item"

export default function List({ selectedKey, children, style, containerStyle, containerClassName, onChange, selectedData }) {
    const [currentKey, setCurrentKey] = useState()
    const setList = (id) => () => {
        setCurrentKey(id)
        if (onChange) {
            onChange(id)
        }
    }

    useEffect(() => {
        if (!currentKey && children[0])
            setList(children[0].props.id)() // set first ket

        if (selectedKey && (currentKey !== selectedKey)) {
            setList(selectedKey)() //set new key
        }


    })


    return (
        <div style={{ ...style }}>
            {children.map(({ props: { id, style, selectedStyle, className, selectedClassName, icon } }, index) => {
                const isSelected = id === currentKey
                const s = isSelected ? { ...selectedStyle } : { ...style }
                const c = isSelected ? selectedClassName : className
                let child = children[index]

                //console.log('child.children', child.props.children)
                if (isSelected) {
                    const newChilds = React.Children.map(child.props.children, (c) => {
                        if (c && c.props)
                            return React.cloneElement(c, { isSelected: true })

                        return c
                    })

                    return (
                        <div className={c} onClick={setList(id)} key={id} style={{ ...s }}>
                            {React.cloneElement(child, { isSelected: true }, newChilds)}
                        </div>
                    )
                }

                return (
                    <div className={c} onClick={setList(id)} key={id} style={{ ...s }}>
                        {child}
                    </div>
                )
            })}
        </div>
    )
}
List.Item = Item