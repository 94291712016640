import React, { Component } from 'react'
import { BButton, Checkbox } from "../../common/component/Button";
import { closeModal } from "../../../core/redux/action/modalAction";
import { connect } from "react-redux";
import ModalFrame from './ModalFrame'

import { isElectron } from 'react-device-detect';
let ipcRenderer
if (isElectron) {
    ipcRenderer = window.require('electron').ipcRenderer
}

const modalKey = 'onboardingmodule'
export class OnboardingModule extends Component {

    componentDidMount = () => {
        this.doOnce = false

    };


    componentDidUpdate(prevProps, prevState, snapshot) {

        //on open
        if (prevProps.isOpen === false && this.props.isOpen === true) {
            this.doOnce = false
            this.setState({ step: 0, disableNext: false, isLoading: false, valueName: this.props.sName, isNew: (this.props.sKey === null), error: (this.props.sName < 1) })
        }
    }

    constructor(props) {
        super(props);
        const cbDontShow = JSON.parse(localStorage.getItem(' '))
        this.state = {
            step: 0,
            isLoading: false,
            isNew: true,
            valueName: this.props.sName,
            error: true,
            disableNext: false,
            cbDontShow


        };


    }
    componentWillUnmount() {

    }



    onCloseModal = () => {
        //console.log('onCloseModal')
        localStorage.setItem('showIntro', true)
        this.props.onCloseModal(modalKey)
    }

    onClickDelete = () => {
        this.setState({ isLoading: true })
        // doShit.then(() => {
        //     this.setState({ isLoading: false })
        //     this.onCloseModal()
        // })

    }

    onChangeText = (e) => {
        this.setState({ valueName: e.target.value, error: (e.target.value.length < 1) });
    }
    onClickSave = () => {

        this.setState({ isLoading: true })
        // doShit.then(() => {
        //     this.setState({ isLoading: false })
        //     this.onCloseModal()
        // })
    }

    doOnce = false
    getStep = () => {
        const { step } = this.state

        switch (step) {
            case 0:
                return this.step0()

            default:
                return this.step1()

        }
    }
    setLocalstorage = (_, data) => {

        var value = Boolean(!this.state.cbDontShow)
        this.setState({ cbDontShow: value }, () => {
            localStorage.setItem('showIntro', this.state.cbDontShow)
        }
        )
    }
    step0 = () => (
        <React.Fragment>
            <div style={{ flex: 1 }}>
                <h3>{window._getText('WelcomeTo')}</h3>
                <p>
                    {window._getText('shortIntro')}<br />
                </p>
                <span >
                    <Checkbox id='cb' onChange={this.setLocalstorage} checked={this.state.cbDontShow} label={window._getText('dontShowThis')}></Checkbox>

                </span>
            </div>
        </React.Fragment>
    )

    step1 = () => (

        <React.Fragment>
            <iframe sandbox="allow-top-navigation allow-same-origin allow-scripts" style={{ width: '100%', height: '100%', }} title='Intro' src={`https://timeto.work/help/intro.html?step=${this.state.step}&l=${window._getTextLanguage}`} frameBorder="0"></iframe>
        </React.Fragment>
    )

    stepDEV = () => (

        <React.Fragment>
            <iframe sandbox="allow-top-navigation allow-same-origin allow-scripts" style={{ width: '100%', height: 'calc(100% - 50px)', }} title='Intro' src={`http://127.0.0.1:5500/help/intro.html?step=${this.state.step}`} frameBorder="0"></iframe>
        </React.Fragment>
    )







    gotoStep = (step) => {
        this.setState({ step: Number(step) })
    }

    setStepValue = (direction) => () => {

        let { step } = this.state
        var val = (direction === 'forward' ? step + 1 : step - 1)
        if (val < 1)
            val = 1

        this.setState({ step: val, disableNext: (val > 1 ? true : false) }, this.setStep)

    }

    setStep = () => {
        //const moduleWindow = window._modals['onboardingModule']
        let { step } = this.state

        // let height = 600
        // let width = 600
        if (step > 0) {
            //            width = 900
            //          height = 700
            if (step === 1)
                if (isElectron) {
                    ipcRenderer.send('resizeIntroWindow', { w: 800, h: 650 })
                }
            // moduleWindow.resizeTo(width, height);
            // if (step === 1)
            //     moduleWindow.moveTo(window.screenLeft + Math.round(window.outerWidth / 2) - (width / 2), window.screenTop + Math.round(window.outerHeight / 2) - (height / 2));
        }
        else {
            // moduleWindow.resizeTo(width, height);
            // moduleWindow.moveTo(window.screenLeft + Math.round(window.outerWidth / 2) - (width / 2), window.screenTop + Math.round(window.outerHeight / 2) - (height / 2));
        }


        this.gotoStep(step)
    }

    render() {

        return (


            <ModalFrame
                name='onboardingModule'
                className='modalAniSlideUp'
                open={this.props.isOpen}
                //onClose={this.handleClose}
                dimmer='inverted'
                onClickClose={this.onCloseModal}
            >


                <ModalFrame.Content>
                    <div style={{ height: '100%', }}>

                        {this.getStep(0)}
                    </div>
                </ModalFrame.Content>
                <ModalFrame.Actions>
                    <div style={{}}>
                        <BButton
                            onClick={this.setStepValue('back')}
                            style={{ height: '30px', float: 'left' }}
                            icon='arrow left'
                            color='blue'
                            compact
                            content={window._getText('Prev')}
                            labelPosition='left'
                            disabled={!this.state.disableNext}
                        ></BButton>

                        <BButton
                            onClick={this.setStepValue('forward')}
                            style={{ height: '30px', float: 'right' }}
                            icon='arrow right'
                            color='blue'
                            compact
                            content={window._getText('Next')}
                            labelPosition='right'
                        ></BButton>

                        {/* <div style={{ paddingRight: '20px', float: 'right' }}>   {this.state.step} of 5</div> */}
                        <div style={{ clear: 'both' }}></div>
                    </div>
                </ModalFrame.Actions>

            </ModalFrame >)

    }
}


const mapActions = {
    onCloseModal: closeModal,
};

const mapStateToProps = state => {

    return {
        isOpen: state.modal.modals[modalKey].open || false,
        data: state.modal.modals[modalKey].data || {}
    };
};
export default connect(
    mapStateToProps,
    mapActions
)(OnboardingModule);



