import { SET_TITLE } from '../action/mobileHeaderAction'
import { createReducer } from '../../util/reducerUtil'
const initState = {
    title: '',
}
export const setTitle = (state, payload) => {
    return { ...state, title: payload }
}

export default createReducer(initState, {
    [SET_TITLE]: setTitle,

})






