import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const View = ({ children }) => {
  if (Array.isArray(children)) {
    return children.map((child) => {
      return child;
    });
  }

  if (children) {
    if (typeof children === "string" || children instanceof String)
      return children;

    return { ...children };
  }

  return null;
};
View.displayName = "Item";

const inlineCss = {
  div1: { display: "flex", flex: 1 },
  div2: { textAlign: "center" ,paddingBottom:6 ,paddingTop:6,paddingLeft:10 ,paddingRight:10 ,},
  ico: { fontSize: 16,   marginRight: 3, marginLeft: 2 },
  name: { whiteSpace: "nowrap" },
};
export default function Tab({
  children,
  style,
  containerStyle,
  containerClassName,
  onChange,
  updateTabIndex,
}) {
  const [tabIndex, setTabIndex] = useState(0);
  const setTab = (index) => () => {
    setTabIndex(index);
    if (onChange) onChange(index);
  };

  useEffect(() => {
    // console.log(`tabIndex`, tabIndex);
    // console.log(`updateTabIndex`, updateTabIndex);
    if (updateTabIndex && tabIndex !== updateTabIndex)
      setTabIndex(updateTabIndex);
  }, [updateTabIndex]);

  return (
    <div style={{ ...style }}>
      <div style={inlineCss.div1}>
        {children.map(
          (
            {
              props: {
                name,
                style,
                selectedStyle,
                className,
                selectedClassName,
                icoColor,
                icon,
              },
            },
            index
          ) => {
            const s = index === tabIndex ? { ...selectedStyle } : { ...style };
            const c = index === tabIndex ? selectedClassName : className;

            return (
              <div
                className={c}
                onClick={setTab(index)}
                key={`tab${index}`}
                style={{ ...s, ...inlineCss.div2 }}
              >
                {icon && (
                  <FontAwesomeIcon
                    style={{ ...inlineCss.ico, color: icoColor }}
                    icon={icon}
                  />
                )}{" "}
                <span style={inlineCss.name}>{name}</span>
              </div>
            );
          }
        )}
      </div>
      <div style={{ ...containerStyle }} className={containerClassName}>
        {children[tabIndex]}
      </div>
    </div>
  );
}
Tab.View = View;
