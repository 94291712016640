import React, { Component } from "react";
//import { shallowEqual } from 'shouldcomponentupdate-children';
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal'


import { Label, Button, Input, Menu } from "../../common/component/Button";
import { toastr } from 'react-redux-toastr'
//import Swipe from '../../common/component/Swipe';//'react-swipe-component'
//import memoizeOne from 'memoize-one';
import moize from 'moize'

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { ScheduleTopMenu } from "./ScheduleTopMenu";

import PrintHelper from '../../../core/util/PrintHelper'

import EditScheduleModule from '../modal/EditScheduleModule'
import SelectSchedules from '../modal/SelectSchedules'
import SelectUsers from '../modal/SelectUsers'
//import imgLogo from '../../../img/32x32.png'
import { ScheduleWeekpicker } from './ScheduleWeekpicker'
import Schedule1 from "./schedule1/Schedule1";
import array from "lodash/array";
import merge from "lodash/merge";
import object from "lodash/object";
import collection from "lodash/collection";
import lang from 'lodash/lang'
import { openEventModal, openEditScheduleModal, openModal, closeEventModal } from "../../../core/redux/action/modalAction";
import { toggelTemplates, renderDateAddDays, setRenderDate, setNerver, setCloneShift, setWeeksToShow } from "../../../core/redux/action/viewstatAction";
import { addevent, updateEventBatch2, updateEventBatchMultiWeeks, addSchedule, unPublish } from "../../../core/redux/action/eventAction";
import { getSchedule, setSchedule, setRenderDayDateSchedule, copyScheduleShift } from "../../../core/redux/action/currentScheduelDataAction";
import { DataHelper } from "../../../core/util/DataHelper";
import { arrayPutOnTopIfExits } from "../../../core/util/helpers";
import shortid from 'shortid'
import moment from 'moment'
import PropTypes from 'prop-types'
//import noUserImage from '../../../img/noimg.png'
import FirestoreListnerHelper from '../../../core/util/FirestoreListnerHelper'
import { FireHelper } from '../../../core/util/FireHelper'
import { Factory } from '../../../core/util/Factory'
import { getDateKey, getLocalDate, renderTimeLable } from '../../../core/util/helpers'
import EditEventModal from "../modal/EditEventModal";
import ShiftAdminRequest from "../modal/ShiftAdminRequest";
import Locked from "../schedule/settings/Locked";
import ModalFrame from "../modal/ModalFrame";
import SchedulePublished from "./SchedulePublished"
import { mapStateToPropsFactory } from '../../../core/redux/mapProps'
import { isElectron } from 'react-device-detect';

const saveTimerTime = 60 * 1000

let ipcRenderer
if (isElectron) {
  ipcRenderer = window.require('electron').ipcRenderer
}

let onSaveScheduleTimer = null
let runAwayTimer = 10000
window.onbeforeunload = (e) => {

  if (runAwayTimer > 0) {
    runAwayTimer--
    if (onSaveScheduleTimer !== null) {
      // call save now 
      console.log('wait for the save..')
      window.dispatchEvent(new CustomEvent("doSaveBeforeExit", { detail: {} }))
      //stall for 2 sec


      setTimeout(() => {
        window.close()
      }, 100);
      e.returnValue = false



    }
  }
}


const emptyArray = []
class Schedule extends Component {

  static contextTypes = { router: PropTypes.object } //store: PropTypes.object.isRequired, 
  constructor(props) {
    super(props);

    this.doOnce = true;
    this.state = {
      forceRender: 0,
      copyObj: { eventsList: null, sortedUserList: null, dividerList: null, startDate: null },
      showWeekPicker: true,
      renderDayCount: 7,
      scheduleDropdownName: null,
      scheduleDropdownKey: null,
      setInitState: true,
      openDivderModal: false,
      divierModalNameValue: '',
      dividerKey: null,
      slideAni: '',
      view: 1,
      joyRiderIsActive: localStorage.getItem('joyrun_pageScheduleSteps') ? true : false, // this shuld be the last step in the joyrider
      haveReadTheVersionUpdateMsg: localStorage.getItem('updatemsg_1.2.3') ? true : false, // this shuld be the last app version

    };

  }


  componentUpdateData = (prevProps, prevState) => {


    if (prevProps)
      if (this.state.setInitState === true && this.props.project) {

        // this will onlY run once
        //console.log('00', 0)
        if (!this.props.currentScheduelData.renderDayDate) {
          //  console.log('11', 11)
          var renderDate = this.props.viewstateRenderDate
          this.props.setRenderDayDateSchedule(renderDate)
        } else if (this.props.isLoadedSchedule === true) {
          //console.log('22', 22)
          if (this.props.scheduleDropDownList && this.props.scheduleDropDownList.length !== 0) {
            //console.log('33', 33)
            var scheduleDropdownName = this.props.scheduleDropDownList[0].text
            var scheduleDropdownKey = this.props.scheduleDropDownList[0].key
            if (this.props.scheduleDropDownList && this.props.scheduleDropDownList[0] && this.state.setInitState === true) {

              //console.log('44', 44)
              if (this.state.scheduleDropdownKeySaved) {
                var indexOfKey = this.props.scheduleDropDownList.findIndex(item => item.key === this.state.scheduleDropdownKeySaved)
                if (indexOfKey > -1) {
                  scheduleDropdownName = this.state.scheduleDropdownNameSaved
                  scheduleDropdownKey = this.state.scheduleDropdownKeySaved

                }
              }
              // console.log('55', 55)
              this.setState({ setInitState: false, scheduleDropdownName: scheduleDropdownName, scheduleDropdownKey: scheduleDropdownKey })
              this.loadFireListner(scheduleDropdownKey)
              this.setSchedule(scheduleDropdownKey);
              if (this.props.match.params.key === 'default' && this.props.scheduleDropDownList[0]) {
                this.setSchedule(this.props.scheduleDropDownList[0].key); //change data 
                this.props.history.push('/schedule/schedule/' + this.props.scheduleDropDownList[0].key) //redirect to fist schedule
              }

            }

          }
          else {
            // console.log('77', 77)
            if (this.state.noSchedual === undefined) {
              // console.log('66', 66)
              //IF WE DONT HAVE ANY SCHEUEL
              //TODO FIX THIS
              //  this.setState({ noSchedual: true }, this.openEditScheduleModal('__empty', null))
            }
          }

        }


      }
      else {
        // console.log('88', 88)
      }
  }

  componentWillUnmount = () => {



    if (onSaveScheduleTimer) {

      this.onSaveSchedule(false, false) //save the old 
    }



    window.removeEventListener("mobileSaveButton", this.onMobileSaveButton)
    const saveData = { scheduleDropdownNameSaved: this.state.scheduleDropdownName, scheduleDropdownKeySaved: this.state.scheduleDropdownKey }
    localStorage.setItem('savedStateVars', JSON.stringify(saveData))
    window.removeEventListener('onTopAddEvent', this.onContextMenuNewShift);

    window.removeEventListener('beforeprint', this.beforePrint);
    window.removeEventListener('keydown', this.keydown);
    window.removeEventListener('doSaveBeforeExit', this.doSaveBeforeExit);
    window.removeEventListener('doSaveAfterShiftChange', this.doSaveAfterShiftChange);
    window.removeEventListener('canIPrint', this.printPdf);
    window.removeEventListener('pasteshift', this.onContextMenuPasteShift);
    window.removeEventListener('pasterow', this.onContextMenuPasteRow);
    window.removeEventListener('copyshift', this.onContextMenuCopyShift);
    window.removeEventListener('newshift', this.onContextMenuNewShift);
    window.removeEventListener('newuser', this.onClickToolsAddUser);
    window.removeEventListener('newdivider', this.onClickToolsAddDivider);
    window.removeEventListener('newschedule', this.openEditScheduleModal);
    window.removeEventListener('copyschedule', this.onClickToolsCopy);
    window.removeEventListener('pasteschedule', this.onClickToolsPaste);
    window.removeEventListener('clearshifts', this.onClickToolsClear);
    window.removeEventListener('undochanges', this.onClickToolsReload);
    window.removeEventListener('onRemoveEvent', this.onRemoveEvent2);
    window.removeEventListener('onCreateShiftRequest', this.onContextMenuCreateShiftRequest);
    window.removeEventListener('saveSchedule', this.saveSchedule);

  };


  // onCloseBlur = (event, arg) => {
  //   console.log('onCloseBlur')
  //   this.props.onCloseModal();
  // }



  beforePrint = (e) => {
    toastr.success('Loading...', 'Please wait'); //TODO: translre
    this.setState({ forceRender: 1 }) //forece page to render once
  }

  componentDidMount = () => {
    window.addEventListener('beforeprint', this.beforePrint);
    window.addEventListener('keydown', this.keydown);
    window.addEventListener('doSaveBeforeExit', this.doSaveBeforeExit);
    window.addEventListener('doSaveAfterShiftChange', this.doSaveAfterShiftChange);
    window.addEventListener('canIPrint', this.printPdf);
    window.addEventListener('pasteshift', this.onContextMenuPasteShift);
    window.addEventListener('pasterow', this.onContextMenuPasteRow);
    window.addEventListener('copyshift', this.onContextMenuCopyShift);
    window.addEventListener('newshift', this.onContextMenuNewShift);
    window.addEventListener('newuser', this.onClickToolsAddUser);
    window.addEventListener('newdivider', this.onClickToolsAddDivider);
    window.addEventListener('newschedule', this.openEditScheduleModal);
    window.addEventListener('copyschedule', this.onClickToolsCopy);
    window.addEventListener('pasteschedule', this.onClickToolsPaste);
    window.addEventListener('clearshifts', this.onClickToolsClear);
    window.addEventListener('undochanges', this.onClickToolsReload);
    window.addEventListener('onRemoveEvent', this.onRemoveEvent2);
    window.addEventListener('onCreateShiftRequest', this.onContextMenuCreateShiftRequest);
    window.addEventListener('saveSchedule', this.saveSchedule);




    if (ipcRenderer)
      ipcRenderer.send('setMenu', '/schedule')



    window.addEventListener('onTopAddEvent', this.onContextMenuNewShift);
    window.addEventListener("mobileSaveButton", this.onMobileSaveButton)


    var jsonData = localStorage.getItem('savedStateVars');

    if (jsonData) {
      const rehydrateSaveData = JSON.parse(jsonData)
      if (rehydrateSaveData && rehydrateSaveData.scheduleDropdownName && rehydrateSaveData.scheduleDropdownKey) {
        this.props.scheduleDropDownList[0].text = rehydrateSaveData.scheduleDropdownName
        this.props.scheduleDropDownList[0].key = rehydrateSaveData.scheduleDropdownKey
      }
      this.setState({ ...rehydrateSaveData || {} }, () => {
        this.componentUpdateData()
      })
    }
    else { this.componentUpdateData() }





  };


  componentDidUpdate(prevProps, prevState, snapshot) {



    this.componentUpdateData(prevProps, prevState)

    if (this.state.setInitState === false) {
      //var dateKey = this.getDateKey();

      if (prevProps && prevProps.dateKey !== this.props.dateKey) {
        //console.log('2', 2)
        this.setSchedule(this.props.currentScheduelData.selectedKey); // changedata
      }
      else if (this.props.match.params.key && this.props.match.params.key !== 'default' && (this.props.match.params.key !== prevProps.currentScheduelData.selectedKey)) {
        this.onChangeSchema2('', this.props.match.params.key); //change data 
        //console.log('0', 0)
      }
      else if (prevProps.currentScheduelData.selectedKey !== this.props.currentScheduelData.selectedKey) {
        this.setSchedule(this.props.currentScheduelData.selectedKey); //change data 
        this.props.history.push('/schedule/schedule/' + this.props.currentScheduelData.selectedKey)
        //  console.log('1', 1)
      }
      else if (this.props.weeksToShow !== prevProps.weeksToShow) {
        //console.log('10', 10)
        this.setSchedule(this.props.currentScheduelData.selectedKey);
      }
      else if (this.props.schedules !== prevProps.schedules) {
        //   console.log('this.props.schedules', this.props.schedules)
        //console.log('4', 4)
        //use timer to remove flicker....
        setTimeout(() => {
          this.setSchedule(this.props.currentScheduelData.selectedKey); // we have new schduela data , refresh! 
        }, 500);


      }
      else if (this.props.scheduleWeekDataMeta !== prevProps.scheduleWeekDataMeta) {
        // console.log('5', 5)
        this.setSchedule(this.props.currentScheduelData.selectedKey); // we have new schduela data , refresh!
      }
      else if (this.props.requestData !== prevProps.requestData) {
        //console.log('6', 6)
        this.setSchedule(this.props.currentScheduelData.selectedKey); // we have new schduela data , refresh!
      }
      else if (this.props.requestMyData !== prevProps.requestMyData) {
        //console.log('7', 7)
        this.setSchedule(this.props.currentScheduelData.selectedKey); // we have new schduela data , refresh!
      }
      else if (this.props.requestAdminData !== prevProps.requestAdminData) {
        // console.log('8', 8)
        this.setSchedule(this.props.currentScheduelData.selectedKey); // we have new schduela data , refresh!
      }
      else if (!lang.isEqual(prevProps.eventsList, this.props.eventsList)) {
        // console.log('3 TUUUNGT test emot cachen', 3)
        this.setSchedule(this.props.currentScheduelData.selectedKey); // changedata
      }
      else {
        //  console.log('no update')
        //&   console.log('9', 9)

      }


    }
  }

  saveSchedule = () => {

    // if (onSaveScheduleTimer) //if we have a timer , do save

    this.onSaveSchedule(false, false) //save the old 
  }

  keydown = (e) => {
    switch (e.code) {
      case 'ArrowLeft':
       // this.onClickChangeDate(-7)() // TODO bring this back when this is disabled on popups
        break;
      case 'ArrowRight':
       // this.onClickChangeDate(7)()
        break;
      case 'ArrowUp':
        //console.log('TODO , go to next schedule')
        break;
      case 'ArrowDown':
        //console.log('TODO , go to next schedule')
        break;
      default:
        break;
    }

  }

  doSaveBeforeExit = () => {
    this.onSaveSchedule(false, false)
  }

  listnerKey = (key, renderDayDate) => {

    var dateKey = this.getDateKey(renderDayDate);
    return `projects/${this.props.currentProjectKey}/schedules/${key}/events/${dateKey}`
  }
  getDateKey = (date) => {
    var r = ''
    if (date) {
      r = getDateKey(date);// moment.utc(date).add(6, 'days').format("YYYY-WW")
      return r
    }
    r = getDateKey(this.props.currentScheduelData.renderDayDate)// moment.utc(this.props.currentScheduelData.renderDayDate).add(6, 'days').format("YYYY-WW")

    return r;
  }


  setSortedUserList = (userKeyList, moveKey) => {
    this.updateCurScheduleView({ ...this.getCurScheduleView(), sortedUserList: userKeyList, selectedSortedUserList: userKeyList }, 'setSortedUserList');
  };


  updateCurScheduleView = (data, caller) => {
    this.onSaveScheduleWithTimer(false)
    this.props.setCurrentScheduleData({ ...this.getCurScheduleView(), ...data });
  };

  //TODO: FIXME: varf kommer det in tom data och gör en blink effekt ? / kolla request status innan update ? 
  //  moize.deep(
  setCurScheduleView = (data) => {
    const curScheduel = this.getCurScheduleView()
    var update = 0

    if (!lang.isEqual(data.key, curScheduel.key))
      update = 1
    else if (!lang.isEqual(data.isEmpty, curScheduel.isEmpty))
      update = 3
    else if (!lang.isEqual(data.loading, curScheduel.loading))
      update = 4
    else if (!lang.isEqual(data.dateKey, curScheduel.dateKey))
      update = 5
    else if (!lang.isEqual(data.selectedSchedule, curScheduel.selectedSchedule))
      update = 5
    else if (!lang.isEqual(data.weeksToShow, curScheduel.weeksToShow))
      update = 8
    else if (!lang.isEqual(data.dividerList, curScheduel.dividerList))
      update = 10
    else if (!lang.isEqual(data.selectedDividerList, curScheduel.selectedDividerList))
      update = 12
    else if (!lang.isEqual(data.selectedSortedUserList, curScheduel.selectedSortedUserList))
      update = 13
    else if (!lang.isEqual(data.scheduleUserListRemoved, curScheduel.scheduleUserListRemoved))
      update = 14
    else if (!lang.isEqual(data.scheduleUserList, curScheduel.scheduleUserList))
      update = 8
    else if (!lang.isEqual(data.selectedUsers, curScheduel.selectedUsers))
      update = 2
    else if (!lang.isEqual(data.sortedUserList, curScheduel.sortedUserList))
      update = 11
    else if (!lang.isEqual(data.metaData, curScheduel.metaData))
      update = 15
    else if (!lang.isEqual(data.scheduleEventsList, curScheduel.scheduleEventsList))
      update = 7
    else {

    }





    if (update > 0) {
      this.props.setCurrentScheduleData(data);
    }
    // else {

    //   console.log('xxxSAMMMME', this.props.weeksToShow)
    // }
    //  }
  }
  //   ,
  //   {
  //     // equals: () => 1 === 1,
  //     maxSize: 10,
  //     onCacheHit: (
  //       cache,
  //       options
  //     ) => console.log('>>>>hit')
  //   }
  // )


  getScheduleEmpty = (key, dateKey) => {
    return DataHelper.initSchedule(key, dateKey);
  };
  getScheduleByKey = key => {

    return DataHelper.getScheduleByKey(this.props, key);
  }

  getCurScheduleView = () => {
    const r = this.getCurScheduleView2(this.props.curScheduleView)
    return r;
  }


  getCurScheduleView2 = moize((curScheduleView) => {

    var r = DataHelper.getCurScheduleView(curScheduleView);
    return r;
  }, { maxSize: 5 })

  onAddTemplateShift = (toUser, toDate, eventKey) => {

    //TODO TEMP FIX !!
    const { schedules, currentScheduelData } = this.props
    if (schedules && currentScheduelData && schedules[currentScheduelData.selectedKey]) {
      var templates = schedules[currentScheduelData.selectedKey].templates


      var curScheduleView = this.getCurScheduleView()
      var currentEvent = { ...templates[eventKey] }; //get it from templates


      if (currentEvent) {


        var newShift = { ...currentEvent }
        newShift.key = shortid.generate()
        newShift.isNew = true
        delete newShift.template

        newShift.uid = toUser;
        newShift.date = toDate;

        var scheduleEventsList = { ...curScheduleView.scheduleEventsList, [newShift.key]: newShift }


        this.updateCurScheduleView({ scheduleEventsList }, "onMoveEvent");
      }
    }
  };
  onMoveEvent = (toUser, toDateStr, eventKey, clone) => {
    //console.log('onMoveEvent')
    var curScheduleView = this.getCurScheduleView()
    var currentEvent = { ...curScheduleView.scheduleEventsList[eventKey] };


    if (currentEvent) {

      if (clone) {
        currentEvent = { ...currentEvent }
        currentEvent.key = shortid.generate()
        currentEvent.isNew = true
      }

      const toDate = moment.utc(Number(toDateStr))
      const dateStr = toDate.format('YYYY-MM-DD')
      const _startDate = moment.utc(`${dateStr} ${currentEvent.timeStart}`)
      const _stopDate = moment.utc(`${dateStr} ${currentEvent.timeEnd}`)
      const currentEventDate = moment.utc(new Date(currentEvent.date))
      var startDate = +_startDate
      var stopDate = +_stopDate



      let prevId
      if (toDate.format('WW') !== currentEventDate.format('WW')) {

        // currentEvent = { ...currentEvent }
        // currentEvent.uid = toUser;
        // currentEvent.date = +toDate
        prevId = currentEvent.key
        currentEvent.key = shortid.generate()
        currentEvent.isNew = true
        // remove old event and add a new id to current
      }


      currentEvent.uid = toUser;
      currentEvent.date = +toDate;
      currentEvent.startDate = startDate;
      currentEvent.stopDate = stopDate;






      var scheduleEventsList = { ...curScheduleView.scheduleEventsList, [currentEvent.key]: currentEvent }
      if (prevId) {
        scheduleEventsList = { ...scheduleEventsList, [prevId]: { ...scheduleEventsList[prevId], removed: true } } // remove old
        //onRemoveEvent
      }

      this.updateCurScheduleView({ scheduleEventsList }, "onMoveEvent");
    }
  };

  getScheduleUserlistKeys = scheduleEventsList => {
    return array.uniq(collection.map(scheduleEventsList, "uid")); // get users from events
  };

  getScheduleUserlistKeysWithActiveEvents = scheduleEventsList => {

    //  if (this.props.weeksToShow > 1)
    //   console.log('weeekkkks is more then 1')

    var list = object.pickBy(scheduleEventsList, obj => {
      if (obj.removed) return false;

      return true;
    });

    return array.uniq(collection.map(list, "uid"));

    //    var listWithOutRemovedEvents = collection.map(scheduleEventsList,(o)=>!o.removed)
    //  return array.unionBy(listWithOutRemovedEvents,'uid')
    //return array.uniq(collection.map(scheduleEventsList, "uid")); // get users from events
  };


  setSortedSchedulelist = () => {
    console.log('>>>setSortedSchedulelist')
    this.props.setCurrentScheduleData({
      ...this.getCurScheduleView(),

      sortedUserList: this.getMergedScheduleUserlist()
    });
  };

  getMergedScheduleUserlistWithSelectedUsers = (selectedUsers) => {

     var scheduel = this.getCurScheduleView();
    var userKeysFormEventList = this.getScheduleUserlistKeysWithActiveEvents(scheduel.scheduleEventsList); // get users from events
    var sortedUsers = scheduel.sortedUserList || emptyArray; //TODO this shude not be null its miss its default value ?






    var _selectedUsers = [...selectedUsers]
    var _userKeysFormEventList = [...userKeysFormEventList]
    for (let index = 0; index < sortedUsers.length; index++) {
      const key = sortedUsers[index];

      indexKey = _selectedUsers.indexOf(key)
      if (indexKey > -1)
        delete _selectedUsers[indexKey]


      var indexKey = _userKeysFormEventList.indexOf(key)
      if (indexKey > -1)
        delete _userKeysFormEventList[indexKey]

    }



    var userKeyList = [..._userKeysFormEventList, ..._selectedUsers, ...sortedUsers]///.filter(x => x.uid)
     return userKeyList;
  }

  creatFireListner = (scheduleKey, date) => {
    if (date) {
      FirestoreListnerHelper.setListner(this.context, date, scheduleKey)
    }
  }

  loadFireListner = (key, renderDayDate, weeksToShow = this.props.weeksToShow) => {
    renderDayDate = renderDayDate || this.props.currentScheduelData.renderDayDate
    for (let index = 0; index < (Number(weeksToShow)); index++) {
      setTimeout(() => {
        this.creatFireListner(key, moment.utc(renderDayDate).add(index * 7, 'days'))//preload next and prevweek 
      });
    }
    //preload...
    setTimeout(() => {
      this.creatFireListner(key, moment.utc(renderDayDate).add((weeksToShow) * 7, 'days'))//preload next and prevweek
      this.creatFireListner(key, moment.utc(renderDayDate).subtract(7, 'days'))
    });
  }

  getMergedScheduleUserlist = () => {
    var scheduel = this.getCurScheduleView();
    return this.getMergedScheduleUserlist2(scheduel)
  }


  onAddUserToSchedule = (users) => {


    const schedule = this.getCurScheduleView()
    //var scheduleUserListRemoved =  [...schedule.scheduleUserListRemoved]
    var selectedUsers = [...schedule.selectedUsers]
    var doUpdate = false
    for (let index = 0; index < users.length; index++) {
      const user = users[index];

      // console.log('user', user.selected)
      if (user && user.selected === true) {

        doUpdate = true
        //  delete user.selected
       // scheduleUserListRemoved = array.remove([...scheduleUserListRemoved], (o) => o !== user.key);
        selectedUsers.unshift(user.key)
      } else if (user && user.selected === false) {
        // delete user.selected
      }
    }
    if (doUpdate) {
      var mergedUsers = this.getMergedScheduleUserlistWithSelectedUsers(selectedUsers);
     // scheduleUserListRemoved,
      this.updateCurScheduleView({ selectedUsers,  sortedUserList: mergedUsers }, "onAddUserToSchedule");
    }

  };
  onChangeSchema2 = (text, value) => {
    if (value !== this.state.scheduleDropdownKey) {
      this.setState({ scheduleDropdownName: text, scheduleDropdownKey: value }, () => {
        const saveData = { scheduleDropdownNameSaved: this.state.scheduleDropdownName, scheduleDropdownKeySaved: this.state.scheduleDropdownKey }
        localStorage.setItem('savedStateVars', JSON.stringify(saveData))

      })
    }

    this.loadFireListner(value)
    this.setSchedule(value);


  };
  onChangeSchema = (e, { value, text }) => {
    var selectedObject = collection.find(this.props.scheduleDropDownList, o => {
      if (!o)
        return false

      return o.value === value
    });
    if (!selectedObject) {
      return // exit whitout doing anything 
    }
    this.onChangeSchema2(text, value)
  };

  uListSorted_empty = {}
  setSchedule = async (scheduelKey, clean, callback) => {

    //console.log('setSchedule', scheduelKey)
    if (onSaveScheduleTimer) {
      this.onSaveSchedule(false, false) //save the old 
    }
    var selectedScheduleData = this.props.schedules[scheduelKey];

    if (selectedScheduleData) {



      var dateKey = this.getDateKey();
      var eList = this.props.eventsList || {}
      var uListSorted = this.props.sortedUserList2
      var dList = this.props.dividerList || {}
      var isEmpty = false;


      //clean requests...
      if (eList)
        Object.keys(eList).forEach(key => {
          if (eList[key] && eList[key].requests)
            delete eList[key].requests
        })


      //get this schedule evetns 
      var eventsList = eList
      //[dateKey]


      if (!eventsList) {
        isEmpty = true
        //console.log('THE LISTIS EMPTY');

      }

      //get currentScheduleView
      const currentScheduleView = clean ? { ...this.getScheduleEmpty(scheduelKey, dateKey) } : { ...this.getScheduleByKey(scheduelKey + '_' + dateKey) }

      //lit of removed users
      //var scheduleUserListRemoved = currentScheduleView.scheduleUserListRemoved || []

      var selectedSortedUserList = currentScheduleView.selectedSortedUserList || []
      //if (currentScheduleData.key !== scheduelKey)
      //  currentScheduleData = { selectedUsers: [], sortedUserList: [] } //if we change schedule we dont want old select data

      // selectedusers 
      var selectedUsers = currentScheduleView.selectedUsers

      // sorted user
      var sortedUserList = uListSorted ? [...uListSorted] : []

      // get dividerLit

      //...dList,currentScheduleView.dividerList
      var dividerList = { ...dList } || {}

      // get the selected/unsaved divs
      var selectedDividerList = { ...currentScheduleView.selectedDividerList } || {}

      //remove users that have been removed but not saved
      // JENSJENS
      // if (scheduleUserListRemoved.length > 0) {
      //   console.log('HERER')
      //   selectedSortedUserList = array.difference(selectedSortedUserList, scheduleUserListRemoved)
      //   sortedUserList = array.difference(sortedUserList, scheduleUserListRemoved)
      // }



      // get sortedusers
      sortedUserList = array.uniq([...selectedSortedUserList, ...sortedUserList] || []) //, 

      //const { eventsList } = this.props; //all events 


      //get evetn thata belongs to the selected scheduel
      var scheduleEventsList = eventsList;//object.pickBy(eventsList, obj => obj && obj.sid === scheduelKey);




      const mergedEventList = { ...scheduleEventsList }

      //get all events that are not marked removed 
      const newEventList = mergedEventList;//object.pickBy(mergedEventList, o => o && !o.removed);






      if (false) {
        //debug data
        console.log(' ')
        console.log('>>>>>setSchedule eventsList', eventsList)
        console.log('>>>>>setSchedulex scheduleEventsList', scheduleEventsList)
        console.log('>>>>>setSchedulex newEventList', newEventList)
        console.log('>>>>>setSchedulex dividerList', dividerList)
        console.log('>>>>>>>>>>>>>this.props.scheduleWeekDataMeta', this.props.scheduleWeekDataMeta)
        console.log('selectedScheduleData', selectedScheduleData)
        console.log('>>>>>setSchedulex')
      }


      var requestList = [
        ...(this.props.requestData || []).filter(x => x.schedualKey === scheduelKey && x.docKey === dateKey),
        ...(this.props.requestMyData || []).filter(x => x.schedualKey === scheduelKey && x.docKey === dateKey),
        ...(this.props.requestAdminData || []).filter(x => x.schedualKey === scheduelKey && x.docKey === dateKey)
      ]



      if (requestList && requestList.length > -1 && newEventList)
        requestList.forEach(request => {
          if (newEventList[request.shiftKey]) {
            if (!newEventList[request.shiftKey].requests)
              newEventList[request.shiftKey] = { ...newEventList[request.shiftKey], requests: [] }


            //TODO sh2 måste också markeras!!



            try {
              newEventList[request.shiftKey].requests.push({ ...request })

              if (request.data.sh2) //it is a swap mark shift 2
              {

                // console.log('newEventList[request.data.sh2.schKey.k]', newEventList[request.data.sh2.k])
                // console.log('request.data.sh2.schKey.k', request.data.sh2.k)

                if (newEventList[request.data.sh2.k]) {
                  if (!newEventList[request.data.sh2.k].requests)
                    newEventList[request.data.sh2.k] = { ...newEventList[request.data.sh2.k], requests: [] }

                  newEventList[request.data.sh2.k].requests.push({ ...request })
                }
              }


            } catch (error) {
              console.log('shiftKey', request.shiftKey)
              console.log('newEventList[request.shiftKey]', newEventList[request.shiftKey])
              console.log('request', request)
              console.log('error', error)
              throw error
            }
          }
        });


      var newScheduleView = {
        key: scheduelKey,
        selectedUsers: selectedUsers,
        isEmpty,
        loading: false,
        dateKey: dateKey,
        selectedSchedule: selectedScheduleData, //TODO remove this
        scheduleEventsList: newEventList,
        scheduleUserList: this.getScheduleUserlistKeys(scheduleEventsList),
        sortedUserList: sortedUserList,
        dividerList,
        selectedDividerList,
        selectedSortedUserList,
       // scheduleUserListRemoved,
        metaData: this.props.scheduleWeekDataMeta,
        weeksToShow: this.props.weeksToShow,

      };
      //save to redux 
      // console.log('befoer', 'setCurScheduleView')
      this.setCurScheduleView(newScheduleView, scheduelKey, "setSchedule");

      if (callback)
        callback()
    }
  };

  onMobileSaveButton = (e) => {
    if (window.location.pathname === '/schedule/schedule')
      this.onSaveSchedule()
  }
  onUnpublishSchedule = async () => {
    if (window.confirm('Do you want to unpublish this schedule ? ')) {
      let { weeksToShow, currentScheduelData } = this.props
      let _renderDayDate = moment(currentScheduelData.renderDayDate).clone()
      var curScheduleView = this.getCurScheduleView();
      for (let index = 0; index < (Number(weeksToShow)); index++) {
        var dateKey = this.getDateKey(_renderDayDate);
        _renderDayDate.add(7, 'days')
        this.props.unPublish(curScheduleView.selectedSchedule, dateKey)
      }

      this.setState({ update: 1 })
      toastr.success('', window._getText('Unpublishing'))
    }

  }

  onSaveScheduleWithTimer = () => {
     if (onSaveScheduleTimer) {
      clearTimeout(onSaveScheduleTimer)
      onSaveScheduleTimer = null
    }

    onSaveScheduleTimer = setTimeout(() => {
      console.log('SAVE by timer')
      this.onSaveSchedule(false, false)
    }, saveTimerTime);

  }
  onSaveScheduleNoPublish = (publish) => {
    //console.log('##>onSaveScheduleNoPublish')
    this.onSaveSchedule(publish)
  }

  onSaveScheduleRuning = false
  onSaveSchedule = async (publish, updateCurrentView) => {
    const { weeksToShow } = this.props

    if (this.props.currentUser.email === 'demo@timeto.work1') {
      onSaveScheduleTimer = null
      toastr.success('Demo account', `No saves are made`);
      //console.log('Demo account = exit')
      return
    }


    //console.log('##>onSaveSchedule')
    if (this.onSaveScheduleRuning === true) {
      console.log('WE already have a save runing = exit')
      return
    }
    this.onSaveScheduleRuning = true

    if (onSaveScheduleTimer) {
      clearTimeout(onSaveScheduleTimer)
      onSaveScheduleTimer = null
    }




    if (publish !== false)
      if (!window.confirm(window._getText('AreYouSurePublish'))
      ) {
        this.onSaveScheduleRuning = false

        return
      }
      else
        toastr.info('Publishing...', 'This may take up to 120 sec.', { timeOut: 6000 }); //TODO: translre

    var curScheduleView = this.getCurScheduleView();

    //get all events 
    var scheduleEventsList = curScheduleView.scheduleEventsList;
    //console.log("onSaveSchedule -> scheduleEventsList", scheduleEventsList)
    var mergedUsers = this.getMergedScheduleUserlist()

 
    var scheduleToUpdate = {
      ...curScheduleView.selectedSchedule
      //, sortedUserList: mergedUsers,//removed 201900930
      //dividerList: curScheduleView.dividerList//removed 201900930
    };

    if (scheduleToUpdate.key === 'NEW-TEMP-ID') {

      scheduleToUpdate.key = shortid.generate()
      this.setState({ scheduleDropdownName: scheduleToUpdate.name, scheduleDropdownKey: scheduleToUpdate.key })

    }


    //save to firestore 
    var unPublish = publish === true ? false : (this.props.isUnPublish === undefined ? true : this.props.isUnPublish)

    var dateKey = this.getDateKey();
    var renderDayDate = this.props.currentScheduelData.renderDayDate

    var sortedUserList = arrayPutOnTopIfExits("openshifts",[...mergedUsers])
    this.props.updateEventBatchMultiWeeks(scheduleEventsList, scheduleToUpdate, dateKey, sortedUserList, { ...curScheduleView.dividerList }, publish, unPublish, weeksToShow, renderDayDate).then(() => {


      //await this.props.updateEventBatch2(scheduleEventsList, scheduleToUpdate, dateKey, [...mergedUsers], { ...curScheduleView.dividerList }, publish, unPublish)


      //update redux state
      Object.keys(scheduleEventsList).forEach(key => {
        var shift = scheduleEventsList[key]
        if (shift) {
          if (shift.removed === true || shift.removed === false)
            delete scheduleEventsList[key]
        }
      });

      if (this.props.neverSavedShift === true) {
        this.props.setNerver('neverSavedShift', false)
      }


      if (updateCurrentView !== false)
        this.updateCurScheduleView({ selectedSortedUserList: [], scheduleEventsList, selectedUsers: [], scheduleUserListRemoved: [] }, "onSaveSchedule");

      //console.log('onSaveSchedule Done')

      this.onSaveScheduleRuning = false

      onSaveScheduleTimer = null
    })
  };

  onRemoveRow = userKey => {
    var curScheduleView = this.getCurScheduleView();

    if (!userKey)
      return

    const isDiv = userKey.startsWith("div_");
    var scheduleUserList = array.pull([...curScheduleView.scheduleUserList || []], userKey);
    var sortedUserList = array.pull([...curScheduleView.sortedUserList || []], userKey);
    var selectedUsers = array.pull([...curScheduleView.selectedUsers || []], userKey);
   // var scheduleUserListRemoved = [...curScheduleView.scheduleUserListRemoved, userKey];
    var scheduleEventsList = curScheduleView.scheduleEventsList
    var scheduleDividerList = curScheduleView.dividerList


    // console.log('urScheduleView.scheduleEventsList', curScheduleView.scheduleEventsList)

    if (isDiv) {
      scheduleDividerList[userKey] = { ...scheduleDividerList[userKey], removed: true }
    }
    else {


      collection.forEach(curScheduleView.scheduleEventsList,
        (o, key) => {
          var obj = { ...o }
          if (obj.uid === userKey) {
            obj.removed = true;
            scheduleEventsList[key] = obj
          }
        }
      );
    }


    this.updateCurScheduleView(
      {
        ...curScheduleView,
        selectedUsers,
        scheduleEventsList,
        scheduleUserList,
        sortedUserList,
       // scheduleUserListRemoved,
      },
      "onRemoveRow"
    );
  };

  onRemoveTemplate = shiftKey => {
    FireHelper.removeTemplateShift(shiftKey, this.props.currentProjectKey, this.props.currentScheduelData.selectedKey)
    // var currentEvent = { ...this.getCurScheduleView().scheduleEventsList[eventKey] };
    // if (currentEvent) {



    //   this.updateCurScheduleView(
    //     {
    //       scheduleEventsList: {
    //         ...this.getCurScheduleView().scheduleEventsList,
    //         [currentEvent.key]: currentEvent
    //       },

    //     },
    //     "onRemoveEvent"
    //   );
    // }
  };

  onRemoveEvent2 = args => {
    //console.log('args.detail.eventId', args.detail.eventId)
    if (args.detail.eventId)
      this.onRemoveEvent(args.detail.eventId)

  };

  onRemoveEvent = eventKey => {
    var currentEvent = { ...this.getCurScheduleView().scheduleEventsList[eventKey] };
    if (currentEvent) {

      const newScheduleEventsList = { ...this.getCurScheduleView().scheduleEventsList, [eventKey]: { ...currentEvent, removed: true } }

      this.updateCurScheduleView(
        {
          scheduleEventsList: newScheduleEventsList

        },
        "onRemoveEvent"
      );


    }
    else
      alert('we can not find it')
  };

  doSaveAfterShiftChange = (args) => {
    this.onSaveScheduleWithTimer()
  }

  printPdf = () => {
    console.log('printPdf', 'before prinintg')
    //console.clear()
    // if (isElectron) {
    const project = this.props.project || {}
    const credits = project.credits || 0


    if (credits === 0) {
      //credits is 0 thi scan be anew customer or a customer that have payed to zero
      const createdDate = moment.utc(project.createdAt.seconds * 1000)
      const payingCustomerDate = createdDate.clone().add('days', 37)
      if (moment.utc() <= payingCustomerDate) {
        //console.log('This is a NOT paying cutomer...')

        alert(window._getText('PrintNoAbuseMsg'))
        return
      }
      //this is a old cutomer with 0 credits , still valid for printing  
      if (ipcRenderer) {
        toastr.success('Loading...', 'Please wait'); //TODO: translre
        this.setState({ forceRender: 1 }, () => {
          this.setState({ forceRender: 0 })
          ipcRenderer.send('printToPDF', '')
        }) //forece page to render once

      }

    }
    else if (credits < 0) {
      //this is customert that is low on credits
      alert(window._getText('addMoreBeforPrint'))
    }
    else {
      //this is a customer with +credits
      if (isElectron && ipcRenderer) {
        toastr.success('Loading...', 'Please wait'); //TODO: translre
        this.setState({ forceRender: 1 }, () => {
          this.setState({ forceRender: 0 })

          ipcRenderer.send('printToPDF', '')

        }) //forece page to render once

      }
      else
        window.print()
    }
    //}
    // }
  }

  onContextMenuPasteRow = (args) => {

    var uid = args.detail.uid

    if (!uid)
      return


    var copyRowObject = this.props.currentScheduelData.copyRowObject


    if (Array.isArray(copyRowObject.rows)) {

      var mergedUsers = this.getMergedScheduleUserlist()
      var scheduleEventsList = { ...this.getCurScheduleView().scheduleEventsList }
      const startCopyDate = moment.utc(copyRowObject.startDate)
      const rDate = moment.utc(this.props.currentScheduelData.renderDayDate)
      const startDateDiff = rDate.diff(startCopyDate, 'days')
      copyRowObject.rows.forEach(shift => {
        //console.log(shift)
        if (shift.date) {


          if (shift.date) {


            //TODO: this belongs in a factory!
            var newShift = { ...shift, uid, date: shift.date, key: shortid.generate(), isNew: true }
            const sDate = moment(shift.date).utc()
            const dayDiff = sDate.diff(rDate, 'days')
            var newStartDate = moment(rDate).utc().add(startDateDiff, 'days').add((dayDiff), 'days')
            newShift.date = +newStartDate
            newShift.startDate = +moment.utc(`${newStartDate.format('YYYY-MM-DD')} ${shift.timeStart}`)
            newShift.stopDate = +moment.utc(`${newStartDate.format('YYYY-MM-DD')} ${shift.timeEnd}`)

            scheduleEventsList[newShift.key] = newShift
          }

        }
      })

      this.updateCurScheduleView({ scheduleEventsList: scheduleEventsList, sortedUserList: mergedUsers }, "onContextMenuPasteRow");

    }

    //  toastr.success('', `You need to copy a shift first`);

  }

  onContextMenuPasteShift = (args) => {
    var cloneObj = this.props.currentScheduelData.copyShiftObject
    if (cloneObj) {
      var mergedUsers = this.getMergedScheduleUserlist()
      var userKey = args.detail.userkey || 'unassigned'
      var date = Number(args.detail.dataedate) || cloneObj.date
      //console.log('args.detail.dataedate', args.detail.dataedate)

      const dateStr = moment.utc(Number(date)).format('YYYY-MM-DD')
      //console.log(date, dateStr)
      var startDate = +moment.utc(`${dateStr} ${cloneObj.timeStart}`)
      var stopDate = +moment.utc(`${dateStr} ${cloneObj.timeEnd}`)

      var newEvent = { ...cloneObj, uid: userKey, key: shortid.generate(), date, startDate, stopDate };



      this.updateCurScheduleView({

        scheduleEventsList: { ...this.getCurScheduleView().scheduleEventsList, [newEvent.key]: newEvent },
        sortedUserList: mergedUsers
      }, "onContextMenuPasteShift");

    }
    else {
      toastr.success('', `You need to copy a shift first`);
    }

  }

  onContextMenuCreateShiftRequest = (args) => {

    if (args && args.detail && args.detail.eventId) {
      //if (onSaveScheduleTimer) //if we have a timer , do save
      this.onSaveSchedule(false, false) //save the old 



      var eList = this.getCurScheduleView().scheduleEventsList || {}
      const orginalShift = eList[args.detail.eventId]
      const shift = Factory.compactShift(orginalShift, this.props.AmPm)

      // we need to pick up the shift that we see in the evetnModal
      this.props.onOpenModal('shiftadminrequest', { shift: { ...shift }, sKey: this.props.currentScheduelData.selectedKey, meta: null, })

    }
  }

  onContextMenuCopyShift = (args) => {
    //this.props.onCopyScheduleShift()

    var currentEvent = { ...this.getCurScheduleView().scheduleEventsList[args.detail.eventId] };
    if (currentEvent) {
      //console.log('args.detail.eventId', currentEvent)
      this.props.onCopyScheduleShift(currentEvent)
      toastr.success('', `Shift is copied`);
    }
  }
  onContextMenuNewShift = (args) => {

    var target = window._clickedTarget
    var userkey = args.detail.userkey || null
    var isRoot = false
    var shiftWidth = 500
    if (!target || userkey === null) {
      target = document.getElementById('root')
      isRoot = true
    }
    var { x, y, width, height, top, left, right } = target.getBoundingClientRect()
    x = left
    y = top
    if (isRoot === true) {
      x = width * .5 + (shiftWidth * .5)
      left = width * .5 + (shiftWidth * .5)
    }



    if (ipcRenderer)
      ipcRenderer.send('renderWindowTargetPos', { x, y, width, height, top, left, right, showArrow: !isRoot })
    delete window._clickedTarget

    this.onAddEvent(args.detail.dataedate ? Number(args.detail.dataedate) : null, userkey)()

  }

  onAddEvent = (date, userKey) => () => {
    var mergedUsers = this.getMergedScheduleUserlist()


    if (date == null) {
      date = +this.props.currentScheduelData.renderDayDate
    }

    if (userKey == null) {
      userKey = 'unassigned' //default shift
    }



    var newEvent = Factory.newShift(date, userKey)



    this.updateCurScheduleView({

      scheduleEventsList: { ...this.getCurScheduleView().scheduleEventsList, [newEvent.key]: newEvent },
      sortedUserList: mergedUsers
    }, "onAddEvent");

    this.props.openEventModal(newEvent)

  };
  onAdNewScheduled = (e, sender) => {

    if (this.props.currentUser.email === 'demo@timeto.work1') {
      onSaveScheduleTimer = null
      toastr.success('Demo account', `No saves are made`);
      console.log('Demo account = exit')
      return
    }

    var key = shortid.generate()
    const newScheduel = { name: sender.value, value: key, key }
    this.props.addSchedule(newScheduel).then(() => {
      this.setState({ scheduleDropdownName: sender.value, scheduleDropdownKey: key })
      this.loadFireListner(key)
      this.setSchedule(key);

    })


  };

  onClickChangeDate2 = (date) => (event) => {
    if (onSaveScheduleTimer) //if we have a timer , do save
      this.onSaveSchedule(false, false) //save the old 


    var renderDayDate = moment.utc(date)

    this.props.setRenderDate(renderDayDate)
    this.props.setRenderDayDateSchedule(renderDayDate)
    this.loadFireListner(this.props.currentScheduelData.selectedKey, renderDayDate)
    this.setSchedule(this.props.currentScheduelData.selectedKey);
  }

  onClickChangeDate = (days) => () => {
    if (onSaveScheduleTimer) //if we have a timer , do save
      this.onSaveSchedule(false, false) //save the old 

    var renderDayDate = moment.utc(this.props.currentScheduelData.renderDayDate).add(days, 'days')
    this.props.renderDateAddDays(days)
    this.props.setRenderDayDateSchedule(renderDayDate)
    this.loadFireListner(this.props.currentScheduelData.selectedKey, renderDayDate)
    this.setSchedule(this.props.currentScheduelData.selectedKey);
  }
  preventDefault = event => () => {
    event.preventDefault();
  };
  onClickShowWeekPicker = () => {
    this.setState({ showWeekPicker: this.state.showWeekPicker ? false : true })
  }

  onClickToolsAddDivider = () => {
    var dividerId = 'div_' + shortid.generate()
    var selectedUsers = [...this.getCurScheduleView().selectedUsers];

    selectedUsers.unshift(dividerId); // add to list of selected but unsaved users/divs
    var mergedUsers = this.getMergedScheduleUserlistWithSelectedUsers(selectedUsers);

    this.updateCurScheduleView({ selectedUsers, sortedUserList: mergedUsers }, "onClickToolsAddDivider");
    this.setState({ openDivderModal: true, dividerKey: dividerId })
  }


  onClickToolsReload = () => {

    this.setSchedule(this.props.currentScheduelData.selectedKey, true);
  }
  onClickToolsCopy = () => {
    if (onSaveScheduleTimer) {
      console.log('Save old schedule')
      this.onSaveSchedule(false, false) //save the old 
    }

    //TODO COPY CURRENT VIEW NOT THE SAVED DATA!!!

    const curScheduleView = this.getCurScheduleView()


    const eList = curScheduleView.scheduleEventsList || {}
    const uList = curScheduleView.sortedUserList || {}
    const dList = curScheduleView.dividerList || {}
    //get this schedule evetns 
    const eventsList = {}
    //dont copy deleted shifts 
    Object.keys(eList).forEach(key => {
      const item = eList[key]
      if (item.date)
        eventsList[key] = item
    })

    const sortedUserList = uList//[dateKey]
    const dividerList = dList//[dateKey]

    if (collection.size(eventsList) > 0 || sortedUserList.length > 0) {
      this.setState({ copyObj: { eventsList, sortedUserList, dividerList, startDate: this.props.currentScheduelData.renderDayDate } })
      toastr.success('', window._getText('ScheduleIsCopied'));
    }
  }
  onClickToolsPaste = () => {

    //TODO: TRAN:
    if (window.confirm('Do you want to paste the copied schedule?')) {
        const { firstDay } = this.props.project
      const { weeksToShow } = this.props
      const curScheduleView = this.getCurScheduleView()
      const { eventsList = {}, sortedUserList = [], dividerList = {}, startDate } = this.state.copyObj
      const startCopyDate = moment.utc(startDate)
      const rDate = moment.utc(this.props.currentScheduelData.renderDayDate)
      const startDateDiff = rDate.diff(startCopyDate, 'days')

       const newSortedUserList = [...sortedUserList || []]
   

      var cloneList = {}
      if (eventsList) {
        collection.forEach(eventsList, function (shift) {

          if (shift.date) {


            //TODO: this belongs in a factory!
            var newShift = { ...shift, date: shift.date, key: shortid.generate(), isNew: true }
            const sDate = moment(shift.date).utc()
            const dayDiff = sDate.diff(rDate, 'days')
            var newStartDate = moment(rDate).utc().add(startDateDiff, 'days').add((dayDiff), 'days')
            newShift.date = +newStartDate
            newShift.startDate = +moment.utc(`${newStartDate.format('YYYY-MM-DD')} ${shift.timeStart}`)
            newShift.stopDate = +moment.utc(`${newStartDate.format('YYYY-MM-DD')} ${shift.timeEnd}`)





            cloneList[newShift.key] = newShift
          }

        })


        //...curScheduleView.sortedUserList || [],
        var _scheduleEventsList = { ...curScheduleView.scheduleEventsList, ...cloneList }
        var _sortedUserList = [...newSortedUserList]
        var _dividerList = { ...curScheduleView.dividerList || [], ...dividerList }

        // jens
        ///TODO: Why dont new div name show up  // ,maybe we tak it from the cache ? moste likely
        // FIXME: chang key in sort order so we can keep the order 
        // What to do if we paste same week ? 

        //console.log('paste', { scheduleEventsList, _sortedUserList, dividerList },)
        this.updateCurScheduleView({ scheduleEventsList: _scheduleEventsList, sortedUserList: _sortedUserList, dividerList: _dividerList }, "onClickToolsPaste");


      }
    }
  }
  onClickToolsPrint = () => {

  }




  onClickToolsClear = () => {
    if (window.confirm(window._getText('ClearSchedule'))) {

      this.updateCurScheduleView({
        dividerList:{},
        scheduleEventsList:{},
        sortedUserList:[],
       // scheduleUserListRemoved:[],
        selectedUsers: [],
        scheduleUserList: [],
      },'onClickToolsClear')
      

      this.onSaveSchedule(false, false) //save the old 
    }


  }


 

  onClickToolsAddOpenShifts = () => {
    //console.log('onClickToolsAddOpenShifts')

    var key = 'openshifts'
    var selectedUsers = [...this.getCurScheduleView().selectedUsers];

    selectedUsers.unshift(key); // add to list of selected but unsaved users/divs
    var mergedUsers = this.getMergedScheduleUserlistWithSelectedUsers(selectedUsers);


    this.updateCurScheduleView({ selectedUsers, sortedUserList: mergedUsers }, "onClickToolsAddOpenShifts");
    // this.setState({ openDivderModal: true, dividerKey: key })

  }
  onClickToolsAddUser = () => {
    //this.props.onOpenModal('user');
    this.props.onOpenModal('selectUsers')
  }
  onClickToolsViewTemplate = () => {
    this.props.toggelTemplates()
  }

  onSwipedLeft = () => {
    this.onClickChangeDate(7)()
    this.setState({ slideAni: 'slideAniLeft' })
  }
  onSwipedRight = () => {
    this.onClickChangeDate(-7)()
    this.setState({ slideAni: 'slideAniRight' })
  }
  animationEnd = (e) => {
    if (e.target.className === 'slideAniLeft') {
      this.setState({ slideAni: '' })
    }
    else if (e.target.className === 'slideAniRight') {
      this.setState({ slideAni: '' })
    }
  }
  openEditScheduleModal = (key, name) => {
    //console.log('###>>openEditScheduleModal', typeof key === 'object')

    if (typeof key === 'object') {
      if (key.detail && key.detail.schedule) { this.props.openEditScheduleModal(key.detail.schedule.key, key.detail.schedule.name) }
      else { this.props.openEditScheduleModal(null, null) }
    }
    else { this.props.openEditScheduleModal(key, name) }
  }
  openSelectSchedualModal = () => { this.props.onOpenModal('selectschedules') }
  getPrintButton = () => {
    return (<PrintHelper trigger={() => <Menu.Item icon='print' />} content={() => this.componentRef} />)
  }

  editScheduleModuleOnRemove = () => {
    if (this.props.scheduleDropDownList) {
      var firstSchedule = this.props.scheduleDropDownList[0];
      if (firstSchedule) {
        this.setState({ scheduleDropdownName: firstSchedule.text, scheduleDropdownKey: firstSchedule.key });
        this.loadFireListner(firstSchedule.value)
        this.setSchedule(firstSchedule.value);
      }
    } else {
      this.setState({ scheduleDropdownName: undefined, scheduleDropdownKey: undefined, noSchedual: undefined, setInitState: true });
    }

  }
  editScheduleModuleOnSave = (key, name) => {
    this.setState({ scheduleDropdownName: name, scheduleDropdownKey: key })
    this.loadFireListner(key)
    this.setSchedule(key);
  }
  getClassNamesSchedule1 = () => this.state.slideAni + ' divSchedual1'

  setScheduleView = (view) => () => {
    if (onSaveScheduleTimer) {
      this.onSaveSchedule(false, false) //save the old 
    }

    this.setState({ view })
  }
  setCloneShift = () => {
    this.props.setCloneShift(false)
  }

  getAvailableUsersList = moize.deep((users, mergedUsers) => {
    // console.log('>>>memoizeOne>>>>>>getAvailableUsersList')
    return collection.filter(users, obj => {
      if (obj.act === false)
        return false

      return array.findIndex(mergedUsers, x => {
        if (!obj) obj = { key: null }
        return x === obj.key
      }) === -1;
    });
  }, { maxSize: 5 })

  //moize.deep(

  getMergedScheduleUserlist2 = (scheduel) => {
    const selectedUsers = scheduel.selectedUsers;
    var userKeysFormEventList = this.getScheduleUserlistKeysWithActiveEvents(scheduel.scheduleEventsList); // get users from events
    var sortedUsers = scheduel.sortedUserList || emptyArray; //TODO this shude not be null its miss its default value ?

    var userKeyList = array.uniq([...sortedUsers || emptyArray, ...userKeysFormEventList || emptyArray, ...selectedUsers || emptyArray], "uid");

    // if (true) {
    //   console.log('**selectedUsers', selectedUsers)
    //   console.log('**userKeysFormEventList', userKeysFormEventList)
    //   console.log('**sortedUsers', sortedUsers)
    //   console.log('**userKeyList', userKeyList)
    //   console.log('********************')
    // }
 

    return userKeyList;
  }///, { maxSize: 5 })




  //FIXME: is this working  ?


  logCacheKeys = (
    cache,
    options,
    moized
  ) => console.log(cache.keys);



  onSelectWeeks = ({ target: { value } }) => {

    this.props.setWeeksToShow(Number(value))
    //this.setState({ refresh: +new Date() })
    this.setSchedule(this.props.currentScheduelData.selectedKey, false, () => {
      this.loadFireListner(this.props.currentScheduelData.selectedKey, this.props.currentScheduelData.renderDayDate, Number(value)) // preload new data
    });


  }

  doOnce = true
  renders = 0
  sumActualDuration = 0
  clockPerformance = (id, // the "id" prop of the Profiler tree that has just committed
    phase, // either "mount" (if the tree just mounted) or "update" (if it re-rendered)
    actualDuration, // time spent rendering the committed update
    baseDuration, // estimated time to render the entire subtree without memoization
    startTime, // when React began rendering this update
    commitTime, // when React committed this update
    interactions) => {
    this.sumActualDuration += actualDuration
    this.renders += 1

    // console.log()

    //console.log(phase, { dur: actualDuration, sumd: this.sumActualDuration, renders: this.renders })
    // console.log('this.sumActualDuration', this.sumActualDuration)
    // console.log('this.renders', this.renders)

    //console.log({ profilerId, mode, actualTime, baseTime, startTime, commitTime });
  }

  render() {
    // console.log('schedule>RENDER> ');
    var { project } = this.props


    const curScheduleView = this.getCurScheduleView();



    var listnerKey = this.listnerKey(curScheduleView.key, curScheduleView.renderDayDate);// `projects/${this.props.user.projectKey}/schedules/${key}/events/${dateKey}` 
    var isLoadingEvents = false// (this.props.listnersStatusRequested[listnerKey] === false && this.props.listnersStatusRequesting[listnerKey] === true)


    // if (curScheduleView && curScheduleView.isEmpty && collection.size(curScheduleView.scheduleEventsList) === 0 && this.props.listnersStatusRequested[listnerKey] === true && this.props.listnersStatusRequesting[listnerKey] === false)
    //   console.log('IM EMPTY FOR REAL!', curScheduleView.isEmpty);


    const { scheduleDropdownName, scheduleDropdownKey, joyRiderIsActive, haveReadTheVersionUpdateMsg } = this.state;
    // console.log('scheduleDropdownName', scheduleDropdownName)
    const { dateKey, users, scheduleDropDownList, haveData, currentScheduelData, isLandscape, availabilityMetaData, publish, weekData, cloneShift } = this.props;
    var updatedAt = moment.utc(new Date());

    if (curScheduleView.selectedSchedule && curScheduleView.selectedSchedule.updatedAt) {
      updatedAt = moment.unix(curScheduleView.selectedSchedule.updatedAt.seconds);
    }


    var mergedUsers = this.getMergedScheduleUserlist(); //this.state.currentScheduleData.userList;


    //this list is for the user dropdown

    // if (haveData === false) {
    //   //this.setState({ok:true})
    //   return('<span>xxx</span>')
    //   return (

    //     <Dimmer active inverted >
    //       <div style={{ alignContent: 'center', alignItems: 'center', }}>
    //         <Loader  >
    //           <h2>
    //             {window._getText('loading')}
    //           </h2>
    //         </Loader>
    //       </div>
    //     </Dimmer>

    //   );
    // } 



    if (haveData === true && window._doOnce === true) {
      // console.log('onAppReady dispatch')
      //

      window._doOnce = false;


      // window.dispatchEvent(new CustomEvent("onAppReady", { detail: { } }));
      // setTimeout(() => {
      if (isElectron) {
        ipcRenderer.send('hidesplash', 'schedual')
      }
      else { window._hidesplash = true }
      // }, 500);

    }



    if (project && project.disabled === true)
      return <Locked />

    const deployTimeStamp = 1580587709
    const createdAt = ((this.props.project || { createdAt: { seconds: deployTimeStamp } }).createdAt || { seconds: deployTimeStamp }).seconds
    const isOldProject = createdAt < deployTimeStamp


    return (
      <div>
        {/* <Profiler id="test" onRender={this.clockPerformance}> */}

        <React.Fragment>


          {/* {joyRiderIsActive && isOldProject && haveReadTheVersionUpdateMsg === false &&
          <Modal
            size='small'
            defaultOpen={true}
            closeIcon={true}
            closeOnDimmerClick={false}
            onClose={() => {
              localStorage.setItem('updatemsg_1.2.3', true)
              this.setState({ haveReadTheVersionUpdateMsg: true })
            }
            }
           >
            <Modal.Header>Important update version 1.2.3</Modal.Header>
            <Modal.Content>
              <Modal.Description>
                <div style={{ padding: '20px' }}>
                  <p style={{ fontSize: '20px' }}>
                    The save button is now swaped out for autosave.<br />
                    You need to <b style={{ fontSize: '20px' }}>republish all scheduals</b>.<br />To make them visable for your staff again.
              </p>
                 </div>
              </Modal.Description>
            </Modal.Content>
          </Modal>
        } */}



          {currentScheduelData.renderDayDate &&
            <ScheduleTopMenu
              neverSavedShift={this.props.neverSavedShift}
              isLandscape={isLandscape}
              onClickToolsViewTemplate={this.onClickToolsViewTemplate}
              firstDayOfWeekNumber={this.props.project.firstDay}
              onClickToolsAddUser={this.onClickToolsAddUser}
              onClickToolsAddOpenShifts={this.onClickToolsAddOpenShifts}
              onClickToolsAddDivider={this.onClickToolsAddDivider}
              onClickToolsReload={this.onClickToolsReload}
              onClickToolsCopy={this.onClickToolsCopy}
              onClickToolsPaste={this.onClickToolsPaste}
              onClickToolsClear={this.onClickToolsClear}
              onClickToolsPrint={this.onClickToolsPrint}
              onClickShowWeekPicker={this.onClickShowWeekPicker}
              onClickChangeDate={this.onClickChangeDate}
              onClickChangeDate2={this.onClickChangeDate2}
              showWeekPicker={this.state.showWeekPicker}
              // curScheduleView={curScheduleView}
              onSaveSchedule={this.onSaveSchedule}
              onSaveScheduleNoPublish={this.onSaveScheduleNoPublish}
              onUnpublishSchedule={this.onUnpublishSchedule}
              onAdNewScheduled={this.onAdNewScheduled}
              onChangeSchema={this.onChangeSchema}
              //currentScheduelData={currentScheduelData}
              renderDayDate={currentScheduelData.renderDayDate.format('YYYYMMDD')}
              scheduleDropdownName={scheduleDropdownName}
              scheduleDropdownKey={scheduleDropdownKey}
              scheduleDropDownList={scheduleDropDownList}
              openEditScheduleModal={this.openEditScheduleModal}
              openSelectSchedualModal={this.openSelectSchedualModal}
              //getPrintButton={this.getPrintButton()}
              setScheduleView={this.setScheduleView}
              isPublish={publish}
              cloneShift={cloneShift}
              setCloneShift={this.setCloneShift}
              workview={this.state.view}
              weeksToShow={this.props.weeksToShow}
            />

          }



          {/* <Swipe
          delta={100}
          className='swipeDiv'
          onSwipedLeft={this.onSwipedLeft}
          onSwipedRight={this.onSwipedRight}
        > */}

          <div className={this.getClassNamesSchedule1()} onAnimationEnd={this.animationEnd}>


            {/* marginTop: this.state.showWeekPicker ? '55px' : '0px', */}
            <div style={{ display: 'flex', }} className='noPrint' >

              <button style={{ whiteSpace: 'nowrap' }} className={(this.state.view === 1 ? 'btn-tab-active' : 'btn-tab') + ' tour_schedule_9'} onClick={this.setScheduleView(1)}   >

                <i style={{ color: '#944FDC', }} className="ico ico-monitor hideOnSmallScreenBtn " ></i>
                {' ' + window._getText('Workview')}
              </button>

              <button style={{ whiteSpace: 'nowrap' }} className={(this.state.view === 0 ? 'btn-tab-active' : 'btn-tab') + ' tour_schedule_10'} tooltip={window._getText('thisIsWatStaffSee')} onClick={this.setScheduleView(0)}   >

                <i style={{ color: '#DA3391' }} className="ico ico-globe hideOnSmallScreenBtn" ></i>
                {' ' + window._getText('Published')}
              </button>

              {/* //FIXME: this sude be in a function */}
              <select value={this.props.weeksToShow} style={css.s1} className='onboardStep_topmenu_viewweeks' onChange={this.onSelectWeeks}>
                <option value={1} >1 week</option>
                <option value={2} >2 week</option>
                <option value={3} >3 week</option>
                <option value={4} >4 week</option>
                <option value={5} >5 week</option>
                <option value={6} >6 week</option>
                {/* <option value={7} >7 week</option>
                <option value={8} >8 week</option>
                <option value={9} >9 week</option>
                <option value={10} >10 week</option> */}

              </select>
              {this.state.showWeekPicker && currentScheduelData && currentScheduelData.renderDayDate && currentScheduelData.renderDayDate !== null &&

                <ScheduleWeekpicker
                  firstDayOfWeekNumber={Number(this.props.project.firstDay)}
                  renderDayDate={currentScheduelData.renderDayDate.format('YYYYMMDD')}
                  onClickDate={this.onClickChangeDate2}
                  weeksToShow={this.props.weeksToShow}
                ></ScheduleWeekpicker>
              }

            </div>


            {(haveData === true && this.props.scheduleDropDownList !== undefined) ?
              //FIXME:
              this.state.view === 1 ?
                <Schedule1
                  forceRender={this.state.forceRender}
                  dateKey={dateKey}
                  availabilityMetaData={availabilityMetaData}
                  schedules={this.props.schedules}
                  currentScheduelData={currentScheduelData}

                  //ref={el => (this.componentRef = el)}
                  isLoadingEvents={isLoadingEvents}

                  renderDayDate={currentScheduelData.renderDayDate}
                  renderDayCount={this.state.renderDayCount}
                  weeksToShow={this.props.weeksToShow}
                  setSortedUserList={this.setSortedUserList}
                  onRemoveTemplate={this.onRemoveTemplate}
                  onRemoveRow={this.onRemoveRow}
                  onRemoveEvent={this.onRemoveEvent}
                  onMoveEvent={this.onMoveEvent}
                  onAddTemplateShift={this.onAddTemplateShift}
                  mergedUsers={mergedUsers}
                  // availableUsers={availableUsersList}
                  //onChangeUser={this.onChangeUser}
                  //onAddNewUseFromDropdown={this.onAddNewUseFromDropdown}
                  onAddEvent={this.onAddEvent}
                  //currentScheduleData={curScheduleView}
                  curScheduleView={curScheduleView}
                  openDivierModal={this.openDivierModal}
                  scheduleWeekData={this.props.scheduleWeekData}
                />
                :
                <SchedulePublished
                  scheduleWeekData={this.props.scheduleWeekData}
                  currentScheduelKey={scheduleDropdownKey}
                  weeksToShow={this.props.weeksToShow}
                ></SchedulePublished>
              :
              <div style={{ paddingTop: '15px', }}>

                {this.props.isOpen_EditShedualModalOpen === false &&
                  <div style={{ fontSize: '20px', margin: 'auto ', position: 'absolute', top: '130px', opacity: '0', }} className='fadeInSlow'>
                    <div style={{ animation: 'fadein 1s', boxShadow: '1px 3px 3px 0px rgba(0, 0, 0, 0.2), 1px 3px 15px 2px rgba(0, 0, 0, 0.2)', border: 'solid 1px silver', zIndex: 19999, borderRadius: '10px', background: '#f1f1f1', color: '#888', padding: '20px', margin: '10px' }}>

                      {window._getText('AddASchedule')}
                      <img src={require('../../../img/arrows/1.png')} style={{ fill: 'green', transform: 'rotate(-60deg)', position: 'absolute', top: '-70px', left: '-30px', height: '120px' }} alt=''></img>


                    </div>

                  </div>
                }
              </div>
            }
          </div>
          {/* </Swipe> */}

          {/* <Dimmer active={this.props.currentScheduelData.isLoading} inverted>
            <Loader>Saving..</Loader>
          </Dimmer> */}


          {this.renderChangeDivierModal()}
          {!this.props.isOpen_EditEventModal && <EditScheduleModule onSave={this.editScheduleModuleOnSave} onRemove={this.editScheduleModuleOnRemove}  ></EditScheduleModule>}
          {!this.props.isOpen_EditEventModal && <SelectUsers canCreateNew={true} name='selectuser_schedual' availableUsers={this.getAvailableUsersList(users.userList, mergedUsers)} onAddSelectedUsers={this.onAddUserToSchedule} ></SelectUsers>}
          {/* 
          //this is not in use
          <SelectSchedules onChangeSchema={this.onChangeSchema2}></SelectSchedules> */}


          <Label className='lblLastChange noPrint'>
            {!publish &&
              <span style={{ color: 'green', fontWeight: 'bold', marginRight: '30px' }}>
                <i className='icon warning circle'></i>
                {window._getText('draft')}
                <i className='icon warning circle'></i>
              </span>}

            {window._getText('schedual.last')} : {getLocalDate(updatedAt, "LLLL")}
          </Label>


          {<EditEventModal ></EditEventModal>}
          <ShiftAdminRequest></ShiftAdminRequest>






        </React.Fragment >
        {/* </Profiler> */}
      </div>

    );
  }




  // -------------- Dvider start ---------------------------
  openDivierModal = (key, value) => {
    this.setState({ openDivderModal: !this.state.openDivderModal, dividerKey: key, divierModalNameValue: value })
  }
  onClickDeleteDevider = () => {
    const key = this.state.dividerKey
    if (key)
      this.onRemoveRow(key)

    this.setState({ openDivderModal: false })
  }
  onClickCloseDevider = () => {
    this.setState({ openDivderModal: false })
  }
  onChangeDivierModal = (e) => {
    this.setState({ divierModalNameValue: e.target.value });
  }
  onClickSaveDevider = () => {
    const name = this.state.divierModalNameValue
    const key = this.state.dividerKey
    if (name && name.length > 1) {
      const curScheduleView = this.getCurScheduleView()

      if (!curScheduleView.dividerList[key])
        curScheduleView.dividerList[key] = { key, name, }
      else {
        curScheduleView.dividerList[key] = { ...curScheduleView.dividerList[key], name, }
      }




      // console.log('>>curScheduleView', curScheduleView)
      // console.log('>>>curScheduleView.dividerList[key]', curScheduleView.dividerList[key])
      // console.log('>>dividerList', curScheduleView.dividerList)
      this.updateCurScheduleView({ dividerList: { ...curScheduleView.dividerList } }, "onClickSaveDevider");

     // console.log('>>>onClickSaveDevider')

    }
    this.setState({ openDivderModal: false, divierModalNameValue: '' })
    //this.onSaveScheduleWithTimer()
  }


  renderChangeDivierModal = () => {
    if (!this.state.openDivderModal)
      return

    return (
      // onClose={() => { this.setState({ openDivderModal: false }) }}
      <ModalFrame name='addDivider' dimmer='inverted' size='mini' open={this.state.openDivderModal} onClickClose={this.onClickCloseDevider} >

        < ModalFrame.Content >
          <h3 style={{ paddingTop: '10px' }}>
            {window._getText('editdivider.title')}
          </h3>
          {/* <label>{window._getText('editdivider.title')}</label> <br /> */}

          <div style={{ padding: '6px' }}>
            <input className='form-control' maxLength={60} style={{ width: '100%', color: '#000' }} placeholder={'Name'} value={this.state.divierModalNameValue} onChange={this.onChangeDivierModal} />
          </div>
        </ModalFrame.Content >
        <ModalFrame.Actions>
          <div style={{ display: 'flex', }}>
            <div>
              <Button onClick={this.onClickDeleteDevider} icon='trash'   ></Button>
            </div>
            <div style={{ marginLeft: 'auto', }}>
              <Button icon='checkmark' onClick={this.onClickSaveDevider} />
            </div>
          </div>
        </ModalFrame.Actions>
      </ModalFrame >)
  }

  // -------------- Dvider stop ---------------------------
}





const mapActionsFromProps = {
  onCloseModal: closeEventModal,
  onOpenModal: openModal,
  addSchedule,
  addevent,
  updateEventBatch2,
  updateEventBatchMultiWeeks,
  unPublish,
  getCurrentScheduleData: getSchedule,
  setCurrentScheduleData: setSchedule,
  onCopyScheduleShift: copyScheduleShift,
  setRenderDayDateSchedule: setRenderDayDateSchedule,
  openEventModal,
  openEditScheduleModal,
  toggelTemplates,
  renderDateAddDays,
  setNerver,
  setRenderDate,
  setCloneShift,
  setWeeksToShow
};

export default withRouter(connect(
  mapStateToPropsFactory,
  mapActionsFromProps
)(Schedule));

const css = {
  s1: { marginLeft: '10px', border: 'solid 1px rgba(34, 36, 38, 0.1)', borderRadius: 5 }
}