import shortid from 'shortid'
import moment from 'moment'
import { renderTimeLable } from '../util/helpers'

export const Factory = {
    newShift: (date, userKey) => {
        let _date = date
        if (!date) {
            _date = +moment()
            //console.log('new date factory', moment().format())
        }


        if (!userKey)
            userKey = 'unassigned'


        const dateStr = moment.utc(_date).format('YYYY-MM-DD')
        const startStopDate = +moment.utc(`${dateStr} 00:00`)
        // const stopDate = startDate //moment.utc(`${dateStr} 00:00`)

        let obj = {

            isNew: true,
            color: 'blue',
            date: _date,
            hours: 0,
            label: "@start - @end",
            uid: userKey,
            key: shortid.generate(),
            break: 0,
            breakMin: 0,
            minutes: 0,
            sick: false,
            startDate: startStopDate,
            stopDate: startStopDate,
            timeEnd: '00:00',
            timeStart: '00:00'


        }
        return obj;
    },
    setTimevalues: (shift) => {

        const dateStr = moment.utc(shift._date).format('YYYY-MM-DD')
        const startDate = moment.utc(`${dateStr} ${shift.timeStart}`)
        const stopDate = moment.utc(`${dateStr} ${shift.timeEnd}`)
        var brake = shift.brake || shift.breakMin
        if (startDate > stopDate)
            stopDate.add(1, 'days')

        var duration = moment.duration(stopDate.diff(startDate));
        var minutes = duration.asMinutes() - brake;
        return { ...shift, startDate: startDate.unix() * 1000, stopDate: stopDate.unix() * 1000, minutes }
    },

    validateShift: (shift) => {
        // test so we have all data before saving to firestore
        let newShift = { ...shift }

        newShift.break = newShift.break ? newShift.break : 0
        newShift.breakMin = newShift.breakMin ? newShift.breakMin : 0
        newShift.color = newShift.color ? newShift.color : 'green'
        newShift.date = newShift.date ? newShift.date : +new moment()
        newShift.hours = newShift.hours ? newShift.hours : 0
        newShift.key = newShift.key ? newShift.key : shortid.generate()
        newShift.label = newShift.label ? newShift.label : "@start - @end"
        newShift.minutes = newShift.minutes ? newShift.minutes : 0
        newShift.sick = newShift.sick ? newShift.sick : false
        newShift.startDate = newShift.startDate ? newShift.startDate : 0
        newShift.stopDate = newShift.stopDate ? newShift.stopDate : 0
        newShift.timeEnd = newShift.timeEnd ? newShift.timeEnd : '00:00'
        newShift.timeStart = newShift.timeStart ? newShift.timeStart : '00:00'
        newShift.uid = newShift.uid ? newShift.uid : 'unassigned'





        return newShift
    },
    cleanShift: (shift) => {
        //remove data that we dont want in the DB
        var _shift = { ...shift }
        delete _shift.meta

        delete _shift.isNew
        delete _shift.date2
        delete _shift.removed
        delete _shift.requests
        delete _shift.renderDayKey
        // delete shift.break //old data , we user breakMin now

        return _shift
    },
    validateAndClean: (shift) => {
        var _shift = { ...shift }
        _shift = Factory.cleanShift(_shift)
        _shift = Factory.validateShift(_shift)
        return _shift
    },
    compactPartialData: (data, AmPm) => {

        var shift = {}
        if (data.color)
            shift.c = data.color

        if (data.key)
            shift.k = data.key

        if (data.label && data.startDate && data.stopDate) {
            shift.l = renderTimeLable(data.label || '', data.startDate, data.stopDate, AmPm)
            shift.la = data.label
        }

        if (data.date)
            shift.d = moment(data.date).format('YYYY-MM-DD')



        if (data.minutes)
            shift.m = data.minutes

        if (data.break)
            shift.b = data.break

        if (data.comment)
            shift.co = data.comment

        if (data.timeStart)
            shift.t1 = data.timeStart

        if (data.timeEnd)
            shift.t2 = data.timeEnd

        if (data.sick)
            shift.sik = data.sick

        if (data.timeOf)
            shift.tof = data.timeOf


        return shift


    },
    compactShift: (orginalShift, AmPm) => {

        var shift = orginalShift ? {
            c: orginalShift.color,
            k: orginalShift.key,
            l: renderTimeLable(orginalShift.label || '', orginalShift.startDate, orginalShift.stopDate, AmPm),
            la: orginalShift.label,
            d: moment(orginalShift.date).format('YYYY-MM-DD')
        } : {};


        if (orginalShift.minutes)
            shift.m = orginalShift.minutes

        if (orginalShift.break)
            shift.b = orginalShift.break

        if (orginalShift.comment)
            shift.co = orginalShift.comment

        if (orginalShift.timeStart)
            shift.t1 = orginalShift.timeStart

        if (orginalShift.timeEnd)
            shift.t2 = orginalShift.timeEnd

        if (orginalShift.sick)
            shift.sik = orginalShift.sick

        if (orginalShift.timeOf)
            shift.tof = orginalShift.timeOf


        return shift


    },
    unpackCompactShift: (compactShift, uid, date) => {
        var shift = {}
        shift.color = compactShift.c
        shift.key = compactShift.k
        shift.label = compactShift.la
        shift.date = +date
        shift.minutes = compactShift.m
        shift.uid = uid
        shift.timeStart = compactShift.t1
        shift.timeEnd = compactShift.t2
        if (compactShift.b) shift.break = compactShift.b
        if (compactShift.b) shift.breakMin = compactShift.b
        if (compactShift.co) shift.comment = compactShift.co
        if (compactShift.sik) shift.sick = compactShift.sik



        return shift



    },
    setMin: (shift) => {



    }


}
