import React, { Component } from "react";
import Checkbox from "semantic-ui-react/dist/commonjs/modules/Checkbox";
import { connect } from "react-redux";
import { FireHelper } from "../../../../core/util/FireHelper";
import * as lang from "../../../../core/util/translation";
import { toastr } from "react-redux-toastr";
import { isElectron } from "react-device-detect";
const currencyCodes = require('../../../../core/util/CurrencyCodes')
let ipcRenderer;
if (isElectron) ipcRenderer = window.require("electron").ipcRenderer;

class AppSettings extends Component {
  state = {
    dev: false,
    AmPm: false,
    com: false,
    sCan: false,
    sMov: false,
    sSwp: false,
    sCha: false,
    sik: false,
    opn: false,
    language: "en",
  };
  componentDidMount = () => {
    const { project } = this.props;
    const settings = project.s || {};
    this.setState({
      AmPm: settings.AmPm || false,
      dev: settings.dev || false,
      com: settings.com || false,
      sCan: settings.sCan || false,
      sMov: settings.sMov || false,
      sSwp: settings.sSwp || false,
      sCha: settings.sCha || false,
      sik: settings.sik || false,
      opn: settings.opn || false,
      language: lang.getLang() || {},
    });
  };

  onSettingsCheck = (e, { name, value, checked }) => {
    if (this.props.currentUser.email === "demo@timeto.work") {
      toastr.success("Demo account", `No saves are made`);
      console.log("Demo account = exit");
      return;
    }

    //console.log('? ' + [name], checked)
    this.setState({ [name]: checked }, () => {
      FireHelper.setProject(this.props.projectKey, { s: { [name]: checked } });
    });
  };

  setProjectName = (newName) => {
    if (newName.length > 3) {
      FireHelper.setProject(this.props.projectKey, { name: newName });
      FireHelper.addUserData({ companyName: newName });
    } else toastr.success("Error", `Company name is to short`);
  };

  handleChange = (event) => {
    if (this.props.currentUser.email === "demo@timeto.work") {
      toastr.success("Demo account", `No saves are made`);
      console.log("Demo account = exit");
      return;
    }

    var language = event.target.value;
    localStorage.setItem("sysLang", language);
    this.setState({ language });
    //TODO SEND TO ELECTRON!
    if (ipcRenderer) ipcRenderer.send("setLanguage", language);

    lang.setLang(language);

    alert(window._getText("PleaseRestart"));
  };

  handleChangeCurrency = (event) => {
    if (this.props.currentUser.email === "demo@timeto.work") {
      toastr.success("Demo account", `No saves are made`);
      console.log("Demo account = exit");
      return;
    }
    var currency = event.target.value;
    localStorage.setItem("currency", currency);
    this.setState({currency})
    window._currency = currency
  };

  render() {

   
    return (

      <div style={{ paddingLeft: "10px", overflow: 'auto', paddingBottom: '80px', maxHeight: 500,paddingRight:10 }}>
        {/* <h2 style={{ paddingTop: '10px' }}>{window._getText('set.settings.title')}</h2> */}

        {/* <h5>{window._getText('set.settings.publickey')}</h5>
        <div style={{ display: 'flex', flex: 'row', }}>
          <div style={{ textAlign: 'right', width: '40%', marginRight: '8px', fontWeight: 'bold', userSelect: 'all' }}>
            {this.props.publicKey}
          </div>
        </div>
        <div style={{ fontSize: '10px', marginBottom: '10px', color: 'silver' }}>({window._getText('set.settings.publickeytext')}) </div> */}

        {/* <h5 style={{ paddingTop: "5x" }}>{window._getText("Language")}</h5> */}
        <div
          style={{
   
            fontWeight: "bold",
          }}
        >
          <h5 style={{ paddingTop: "0.8rem",margin:0 }}>{window._getText("Language")}</h5>
          <select
            onChange={this.handleChange}
            value={this.state.language || "en"}
            className="selectEventForm form-control"
            style={{ display: "inline", fontWeight: "normal",color:'gray' }}
          >
            <option value="da">Danish</option>
            <option value="nl">Dutch</option>
            <option value="en">English</option>
            <option value="fi">Finnish</option>
            <option value="fr">French</option>
            <option value="de">German</option>
            <option value="el">Greece</option>
            <option value="it">Italian</option>
            <option value="no">Norwegian</option>
            <option value="pl">Polish</option>
            <option value="pt">Portugal</option>
            <option value="ru">Russian</option>
            <option value="es">Spanish</option>
            <option value="sv">Swedish</option>
            <option value="tr">Turkish</option>
          </select>
        </div>
        <div
          style={{
        
        
            fontWeight: "bold",
          }}
        >

        <h5 style={{ paddingTop: "0.8rem",margin:0 }}>{window._getText("Currency")}</h5>          
          <select
            onChange={this.handleChangeCurrency}
            value={window._currency || "USD"}
            className="selectEventForm form-control"
            style={{ display: "inline", fontWeight: "normal",color:'gray' }}
          >
            
        

            {currencyCodes.map(c=>{
              return <option value={c.currency}>{c.label}  ({c.currency})</option>
            })

            }
          </select>

          
        </div>
    
        <h5 style={{ paddingTop: "0.8rem",margin:0}}>
          {window._getText("set.settings.time")}
        </h5>
        <div style={{ display: "flex", flex: "row" }}>
          <div
            style={{
              textAlign: "right",
              width: "40%",
              marginRight: "8px",
              fontWeight: "bold",
            }}
          >
            {this.state.AmPm === true && (
              <span>
                {" "}
                24h {window._getText("or")} <b>AM/PM</b>
              </span>
            )}
            {this.state.AmPm === false && (
              <span>
                {" "}
                <b>24h</b> {window._getText("or")} AM/PM
              </span>
            )}
          </div>
          <div style={{}}>
            <Checkbox
              toggle
              name="AmPm"
              onChange={this.onSettingsCheck}
              checked={this.state.AmPm}
            />
          </div>
        </div>
        <div style={{ fontSize: "10px", marginBottom: "2px", color: "silver" }}>
          ({window._getText("set.settings.timeWarning")}){" "}
        </div>

        <h5 style={{paddingTop: "0.8rem",margin:0}}>{window._getText("set.settings.auto")}</h5>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            border: "dotted 0px var(--color-border)",
          }}
        >
          <div style={{ display: "flex", flex: "row", paddingBottom: "8px" }}>
            <div
              style={{
                textAlign: "right",
                width: "40%",
                marginRight: "8px",
                fontWeight: "bold",
              }}
            >
              {window._getText("set.settings.btn.dev")}
            </div>
            <div>
              <Checkbox
                toggle
                name="dev"
                onChange={this.onSettingsCheck}
                checked={this.state.dev}
              />
            </div>
          </div>

          <div style={{ display: "flex", flex: "row", paddingBottom: "8px" }}>
            <div
              style={{
                textAlign: "right",
                width: "40%",
                marginRight: "8px",
                fontWeight: "bold",
              }}
            >
              {window._getText("set.settings.btn.com")}
            </div>
            <div>
              <Checkbox
                toggle
                name="com"
                onChange={this.onSettingsCheck}
                checked={this.state.com}
              />
            </div>
          </div>

          <div style={{ display: "flex", flex: "row", paddingBottom: "8px" }}>
            <div
              style={{
                textAlign: "right",
                width: "40%",
                marginRight: "8px",
                fontWeight: "bold",
              }}
            >
              {window._getText("set.settings.btn.sCan")}
            </div>
            <div>
              <Checkbox
                toggle
                name="sCan"
                onChange={this.onSettingsCheck}
                checked={this.state.sCan}
              />
            </div>
          </div>
          <div style={{ display: "flex", flex: "row", paddingBottom: "8px" }}>
            <div
              style={{
                textAlign: "right",
                width: "40%",
                marginRight: "8px",
                fontWeight: "bold",
              }}
            >
              {window._getText("set.settings.btn.sMov")}
            </div>
            <div>
              <Checkbox
                toggle
                name="sMov"
                onChange={this.onSettingsCheck}
                checked={this.state.sMov}
              />
            </div>
          </div>
          <div style={{ display: "flex", flex: "row", paddingBottom: "8px" }}>
            <div
              style={{
                textAlign: "right",
                width: "40%",
                marginRight: "8px",
                fontWeight: "bold",
              }}
            >
              {window._getText("set.settings.btn.sSwp")}
            </div>
            <div>
              <Checkbox
                toggle
                name="sSwp"
                onChange={this.onSettingsCheck}
                checked={this.state.sSwp}
              />
            </div>
          </div>
          <div style={{ display: "flex", flex: "row", paddingBottom: "8px" }}>
            <div
              style={{
                textAlign: "right",
                width: "40%",
                marginRight: "8px",
                fontWeight: "bold",
              }}
            >
              {window._getText("set.settings.btn.sCha")}
            </div>
            <div>
              <Checkbox
                toggle
                name="sCha"
                onChange={this.onSettingsCheck}
                checked={this.state.sCha}
              />
            </div>
          </div>
          <div style={{ display: "flex", flex: "row", paddingBottom: "8px" }}>
            <div
              style={{
                textAlign: "right",
                width: "40%",
                marginRight: "8px",
                fontWeight: "bold",
              }}
            >
              {window._getText("set.settings.btn.sik")}
            </div>
            <div>
              <Checkbox
                toggle
                name="sik"
                onChange={this.onSettingsCheck}
                checked={this.state.sik}
              />
            </div>
          </div>

          <div style={{ display: "flex", flex: "row", paddingBottom: "8px" }}>
            <div
              style={{
                textAlign: "right",
                width: "40%",
                marginRight: "8px",
                fontWeight: "bold",
              }}
            >
              {window._getText("set.settings.btn.openshifts")}
            </div>
            <div>
              <Checkbox
                toggle
                name="opn"
                onChange={this.onSettingsCheck}
                checked={this.state.opn}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    projectKey: state.firebase.profile.projectKey,
    project: state.firestore.data.project,
    publicKey: state.firestore.data.project.publicKey,
    currentUser: state.firebase.profile,
  };
};

const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(AppSettings);
