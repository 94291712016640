import React, { Component } from "react";
import {
  Segment,
  Button,
  Icon,
  Loader,
} from "../../../common/component/Button";
import { ScheduleRow } from "./ScheduleRow";
import { connect } from "react-redux";
import moment from "moment";
import {
  openModal,
  closeModal,
  openEventModal,
  openCopyScheduleModal,
} from "../../../../core/redux/action/modalAction";
import { copyScheduleRow } from "../../../../core/redux/action/currentScheduelDataAction";
import ScheduleEmpty from "./ScheduleEmpty";
import ScheduleTemplates from "./ScheduleTemplates";
//import { addUser } from "../../../../core/redux/action/userAction";
import moize from "moize";
import { getDateKey ,arrayPutOnTop} from "../../../../core/util/helpers";
import WebContextMenu from "../../../common/component/ContextMenu";


import { isMobile } from "react-device-detect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle as icoPlus } from "@fortawesome/pro-duotone-svg-icons";
import math from "lodash/math";

import { isElectron } from "react-device-detect";
let ipcRenderer;

if (isElectron) {
  ipcRenderer = window.require("electron").ipcRenderer;
}

// const getUserEvents2 = (userSortedEvents, userKey) => {
//   //// console.log('>>>get fresh userevetn list',user.key)
//   console.log(">>userSortedEvents");
//   if (!userKey) return;

//   return userSortedEvents[userKey] || [];
// };
const getUserEvents3 = (userSortedEvents2, userKey) => {
  if (!userKey) return [];
  if (!userSortedEvents2) return [];

  return userSortedEvents2[userKey] || [];
};

const getHeader = moize((renderDayDate, todayDate, onDragOver, weeksToShow) => {
  //  const today = moment.utc(todayDate).format('dd\u00A0DD')
  // // console.log('>>getHeader memoizeOne', renderDayDate)
  // console.log('>>today', today)
  // // console.log('---------')

  const css = { color: "rgba(153,0,0,1)", textTransform: "capitalize" };
  /// DATE BUG do wee need to hace UTC in the display date ?

  let ddToday = moment(new Date());
  let r = [];
  for (let index = 0; index < weeksToShow; index++) {
    let _renderDayDate = moment(renderDayDate).clone();

    _renderDayDate.add(index, "weeks");

    let dd1 = moment(_renderDayDate);
    let dd2 = moment(_renderDayDate).add(1, "days");
    let dd3 = moment(_renderDayDate).add(2, "days");
    let dd4 = moment(_renderDayDate).add(3, "days");
    let dd5 = moment(_renderDayDate).add(4, "days");
    let dd6 = moment(_renderDayDate).add(5, "days");
    let dd7 = moment(_renderDayDate).add(6, "days");

    let d1 = `${window
      ._getText((dd1.format("dddd") || '').toLowerCase())
      .substr(0, 2)}  ${dd1.format("DD")}`;
    let d2 = `${window
      ._getText((dd2.format("dddd") || '').toLowerCase())
      .substr(0, 2)}  ${dd2.format("DD")}`;
    let d3 = `${window
      ._getText((dd3.format("dddd") || '').toLowerCase())
      .substr(0, 2)}  ${dd3.format("DD")}`;
    let d4 = `${window
      ._getText((dd4.format("dddd") || '').toLowerCase())
      .substr(0, 2)} ${dd4.format("DD")}`;
    let d5 = `${window
      ._getText((dd5.format("dddd") || '').toLowerCase())
      .substr(0, 2)} ${dd5.format("DD")}`;
    let d6 = `${window
      ._getText((dd6.format("dddd") || '').toLowerCase())
      .substr(0, 2)} ${dd6.format("DD")}`;
    let d7 = `${window
      ._getText((dd7.format("dddd") || '').toLowerCase())
      .substr(0, 2)}  ${dd7.format("DD")}`;

    if (ddToday.isSame(dd1, "day"))
      d1 = (
        <span style={css}>
          {window._getText((dd1.format("dddd") || '').toLowerCase()).substr(0, 2)}{" "}
          {dd1.format("DD")}
        </span>
      );
    else if (ddToday.isSame(dd2, "day"))
      d2 = (
        <span style={css}>
          {window._getText((dd2.format("dddd") || '').toLowerCase()).substr(0, 2)}{" "}
          {dd2.format("DD")}
        </span>
      );
    else if (ddToday.isSame(dd3, "day"))
      d3 = (
        <span style={css}>
          {window._getText((dd3.format("dddd") || '').toLowerCase()).substr(0, 2)}{" "}
          {dd3.format("DD")}
        </span>
      );
    else if (ddToday.isSame(dd4, "day"))
      d4 = (
        <span style={css}>
          {window._getText((dd4.format("dddd") || '').toLowerCase()).substr(0, 2)}{" "}
          {dd4.format("DD")}
        </span>
      );
    else if (ddToday.isSame(dd5, "day"))
      d5 = (
        <span style={css}>
          {window._getText((dd5.format("dddd") || '').toLowerCase()).substr(0, 2)}{" "}
          {dd5.format("DD")}
        </span>
      );
    else if (ddToday.isSame(dd6, "day"))
      d6 = (
        <span style={css}>
          {window._getText((dd6.format("dddd") || '').toLowerCase()).substr(0, 2)}{" "}
          {dd6.format("DD")}
        </span>
      );
    else if (ddToday.isSame(dd7, "day"))
      d7 = (
        <span style={css}>
          {window._getText((dd7.format("dddd") || '').toLowerCase()).substr(0, 2)}{" "}
          {dd7.format("DD")}
        </span>
      );

    r.push(
      <React.Fragment key={`key_${index}`}>
        {index === 0 && (
          <td className="two wide scheduelHeaderCell  oneLine" style={styles.s13}></td>
        )}

        <td className="two wide scheduelHeaderCell  noSelect  oneLine" style={styles.s2}>
          {d1}
          <span style={styles.s3}>w.{_renderDayDate.format("ww")}</span>
        </td>
        <td className="two wide scheduelHeaderCell  noSelect textCap oneLine">{d2}</td>
        <td className="two wide scheduelHeaderCell  noSelect textCap oneLine">{d3}</td>
        <td className="two wide scheduelHeaderCell  noSelect textCap oneLine">{d4}</td>
        <td className="two wide scheduelHeaderCell  noSelect textCap oneLine">{d5}</td>
        <td className="two wide scheduelHeaderCell  noSelect textCap oneLine">{d6}</td>
        <td className="two wide scheduelHeaderCell  noSelect textCap oneLine">{d7}</td>
      </React.Fragment>
    );
  }

  return (
    <tr onDragOver={onDragOver} style={styles.s4}>
      {r}
    </tr>
  );
});
let _getText = window._getText;
class Schedule1 extends Component {
  constructor(props) {
    super(props);
    this.AddButtonRef = React.createRef();
    _getText = window._getText;
  }
  state = {
    showDropZone: false,
    templates: {},
    isContextMenuOpen: false,
    contextMenu: [],
  };

  componentUpdateData(prevProps, prevState) {
    var { schedules, currentScheduelData } = this.props;
    if (
      (this.props.forceRender &&
        this.props.forceRender === 1 &&
        prevProps &&
        prevProps.forceRender === 0) ||
      (prevProps || { curScheduleView: "" }).curScheduleView.cacheKey !==
      this.props.curScheduleView.cacheKey ||
      (prevProps && prevProps.cloneShift !== this.props.cloneShift)
    ) {
      //console.log('Recerate cached')
      this.renderRows(this.props.renderDayDate);

    } else {
      // console.log('its cached')
    }

    if (schedules && currentScheduelData) {
      if (
        schedules &&
        currentScheduelData &&
        schedules[currentScheduelData.selectedKey]
      ) {
        if (
          schedules[currentScheduelData.selectedKey].templates !==
          this.state.templates
        ) {
          this.setState({
            templates: schedules[currentScheduelData.selectedKey].templates,
          });
        }
      }
    }
  }
  componentDidMount(prevProps, prevState) {
    //   // console.log('componentDidMount')
    this.componentUpdateData(prevProps, prevState);
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    //console.log('componentDidUpdate schedule1', this.props.cloneShift)
    var { schedules, currentScheduelData } = this.props;
    if (
      (this.props.forceRender &&
        this.props.forceRender === 1 &&
        prevProps.forceRender === 0) ||
      schedules !== prevProps.schedules ||
      currentScheduelData !== prevProps.currentScheduelData ||
      (prevProps && prevProps.cloneShift !== this.props.cloneShift)
    ) {
      // // console.log('do force update')
      this.componentUpdateData(prevProps, prevState);
    }
  };

  //TODO clean this dragand drop events ! maybe we can reuse some also
  preventDefault = (event) => () => {
    event.preventDefault();
  };

  onSchedualContextMenu = (e) => {
    //console.log("e.nativeEvent.which", e.nativeEvent.which);

    //  e.preventDefault();

    if (e.nativeEvent.which !== 3) return;

    var item = e.target;

    if (e.button === 0 && (item.tagName || '').toLowerCase() !== "td") {
      if (item.children.length === 0 && item.className === "flexDiv") {
      } else return;
    }
    var exit = 20;
    var eventId;
    if ((item.tagName || '').toLowerCase() !== "td") {
      do {
        if (item) {
          if (item.id) eventId = item.id;

          item = item.parentNode;
        } else exit = -1;
        // // console.log(exit, item)

        exit--;
      } while (
        ((item.tagName || '').toLowerCase() !== "body" &&
          (item.tagName || '').toLowerCase() !== "td") ||
        exit < 0
      );
    }

    if (item.className.indexOf("sCellName") > -1)
      // we dont want a context menu on first TD
      return;

    exit = 6;
    var parent = item;
    var userkey = parent.getAttribute("id");
    do {
      if (parent && parent.getAttribute) {
        userkey = parent.getAttribute("id");
        if (userkey) exit = -1;
        else parent = parent.parentNode;
      }
      exit--;
    } while (exit > 0);

    window._clickedTarget = item;

    if ((item.tagName || '').toLowerCase() === "td") {
      var dataedate = item.getAttribute("dataedate");
      var contextMenu;
      if (eventId) {
        contextMenu = [
          {
            label: _getText("NewShift"),

            click: () =>
              window.dispatchEvent(
                new CustomEvent("newshift", { detail: { userkey, dataedate } })
              ),
          },
          {
            label: _getText("PasteShift"),

            click: () =>
              window.dispatchEvent(
                new CustomEvent("pasteshift", {
                  detail: { userkey, dataedate },
                })
              ),
          },
          {
            label: _getText("CopyShift"),
            click: () =>
              window.dispatchEvent(
                new CustomEvent("copyshift", { detail: { eventId } })
              ),
          },

          {
            label: _getText("DeleteShift"),

            click: () =>
              window.dispatchEvent(
                new CustomEvent("onRemoveEvent", { detail: { eventId } })
              ),
          },
          { type: "separator" },
          {
            label: _getText("CreateShiftRequest"),

            click: () =>
              window.dispatchEvent(
                new CustomEvent("onCreateShiftRequest", { detail: { eventId } })
              ),
          },
        ];
      } else {
        contextMenu = [
          {
            label: _getText("NewShift"),

            click: () =>
              window.dispatchEvent(
                new CustomEvent("newshift", { detail: { userkey, dataedate } })
              ),
          },
          {
            label: _getText("PasteShift"),

            click: () =>
              window.dispatchEvent(
                new CustomEvent("pasteshift", {
                  detail: { userkey, dataedate },
                })
              ),
          },
          { type: "separator" },
          {
            label: _getText("AddStaff"),

            click: () =>
              window.dispatchEvent(new CustomEvent("newuser", { detail: {} })),
          },
          { type: "separator" },
          {
            label: _getText("AddDivider"),

            click: () =>
              window.dispatchEvent(
                new CustomEvent("newdivider", { detail: {} })
              ),
          },
        ];
      }

      this.setState({ isContextMenuOpen: true, contextMenu }, () => {
        if (isElectron)
          this.setState({ isContextMenuOpen: false, contextMenu: [] });
      });

      // this.setState({ webContexMeny: OpenContextMenu(contextMenu) }, () => {
      //     console.log('webContexMeny', this.state.webContexMeny)
      // })
    }
  };

  onUserRowContextMenu = (uid, isAUser = true) => (e) => {
    //e.preventDefault();

    let menuObj = [
      {
        label: _getText("Copy row"),
        click: this.onCopyRow(uid),
      },
      {
        label: _getText("Paste row"),
        click: this.onPasteRow(uid),
      },
    ];

    if (isAUser === true) {
      menuObj.push({ type: "separator" });

      menuObj.push({
        label: _getText("View user"),
        click: this.onOpenUserModal(uid),
      });
      menuObj.push({ type: "separator" });
      menuObj.push({
        label: _getText("Delete row"),
        click: this.onDeleteRow(uid),
      });
    }
    //OpenContextMenu(menuObj)

    this.setState({ isContextMenuOpen: true, contextMenu: menuObj }, () => {
      if (isElectron)
        this.setState({ isContextMenuOpen: false, contextMenu: [] });
    });
    return true;
  };

  onDeleteRow = (uid) => () => {
    if (window.confirm("Do you want to remove the user row? ")) {
      this.props.onRemoveRow(uid);
    }
  };
  onPasteRow = (uid) => () => {
    window.dispatchEvent(new CustomEvent("pasterow", { detail: { uid } }));
  };

  onCopyRow = (uid) => () => {
    const eventList = this.props.curScheduleView.scheduleEventsList;
    const userEvents = Object.keys(eventList)
      .map((key) => (eventList[key].uid === uid ? eventList[key] : null))
      .filter((x) => x);
    //var userEvents = getUserEvents2(eventList, uid)
    var cloneRow = [];
    userEvents.forEach((shift) => {
      if (shift.key && shift.date)
        cloneRow.push({
          ...shift,
          key: "",
          uid: "",
          day: moment(shift.date)
            .utc()
            .isoWeekday(),
        });
    });
    this.props.copyScheduleRow({
      rows: cloneRow,
      startDate: this.props.currentScheduelData.renderDayDate,
    });
  };

  onOpenUserModal = (key) => () => {
    if (this.props.role > 199) return this.onOpenModal(key);

    return;
  };
  onDragStartRow = (e) => {
    const tag = (e.target.tagName || '').toLowerCase()
    if (
      tag === "div" ||
      tag === "img" ||
      tag === "td" ||
      tag === "h5" ||
      tag === "h4"
    ) {
      var TR = e.target;
      var exit = 10;
      do {
        TR = TR.parentNode;
        exit--;
      } while ((TR.tagName || '').toLowerCase() !== "tr" || exit < 0);

      e.dataTransfer.setData("text", JSON.stringify({ id: TR.id, t: "row" }));
      this.onDragOverShowDropZone();
    }
  };
  onDragOver = (e) => {
    // console.log('onDragOver')
    e.stopPropagation();
    e.preventDefault();
  };
  onDragOverRow = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };
  onDropRow = (e) => {
    e.preventDefault();

    var data;
    try {
      data = JSON.parse(e.dataTransfer.getData("text"));
    } catch (error) {
      console.log("JSON.parse error");
      return;
    }
    if (data.t !== "row") {
      console.log("exit row drop");
      return;
    }

    let dragItem = document.getElementById(data.id);
    // console.log(">>>dragItem", dragItem);

    var dropObj = e.target;
    var exit = 10;
    do {
      dropObj = dropObj.parentNode;
      //// console.log('dropObj',dropObj.order)
      exit--;
    } while ((dropObj.tagName || '').toLowerCase() !== "tr" || exit < 0);

    try {
      var dragValue = dragItem.attributes["order"].value || 0;
      var dropValue = dropObj.attributes["order"].value || 0;

      this.props.mergedUsers.splice(dragValue - 1, 1); // REMOVE ORGINAL ITEM
      this.props.mergedUsers.splice(dropValue - 1, 0, data.id); // dubbel check this ?
      this.props.setSortedUserList(this.props.mergedUsers, data.id);
      //what to do herer ? save a sorted list ?
    } catch (error) {
      console.log("error -- error swapping", error);
    }

    this.onDragOverHideDropZone();
  };

  onDragOverHideDropZone = () => {
    //// console.log(">>onDragOverHideDropZone");
    this.setState({ showDropZone: false });
  };
  onDragOverShowDropZone = () => {
    if (this.AddButtonRef && this.AddButtonRef.current) {
      this.AddButtonRef.current.style.top = "-500px";
    }
    this.setState({ showDropZone: true });
  };

  onDropRemove = (e) => {
    var data;
    try {
      data = JSON.parse(e.dataTransfer.getData("text"));
    } catch (error) {
      console.log("JSON.parse error");
      return;
    }

    if (data.t === "cell") this.props.onRemoveEvent(data.id);
    else if (data.t === "row") this.props.onRemoveRow(data.id);
    else if (data.t === "template") this.props.onRemoveTemplate(data.id);

    this.onDragOverHideDropZone();
  };

  onOpenModal = (key) => {
    this.props.openModal("user", key);
  };
  onOpenModalSelectUsers = () => {
    this.props.openModal("selectUsers");
  };
  addButtonItem = null;
  showAddShiftButton = (pos, eventDate, userKey) => {
    if (this.AddButtonRef && this.AddButtonRef.current) {
      if (pos) {
        if (this.hideAddShiftButtonTimer)
          clearTimeout(this.hideAddShiftButtonTimer);

        this.addButtonItem = { eventDate, userKey };
        this.AddButtonRef.current.style.left = pos.right - 30 + "px";
        this.AddButtonRef.current.style.top = pos.top + "px";
        this.AddButtonRef.current.style.opacity = 1;
        //hide the + if mouse in the top , this is not the best way to do it !
        if (this.AddButtonRef.current.getBoundingClientRect().top < 39) {
          this.AddButtonRef.current.style.opacity = 0;
          this.AddButtonRef.current.style.top = "-500px"; //TODO WE can do this better
        }
      } else {
        this.AddButtonRef.current.style.opacity = 0;
        this.AddButtonRef.current.style.top = "-500px";
      }
    }
  };
  hideAddShiftButtonTimer = null;
  hideAddShiftButton = () => {
    if (this.AddButtonRef && this.AddButtonRef.current) {
      if (this.hideAddShiftButtonTimer)
        clearTimeout(this.hideAddShiftButtonTimer);

      this.hideAddShiftButtonTimer = setTimeout(() => {
        if (this.AddButtonRef.current) {
          this.AddButtonRef.current.style.top = "-500px";
          this.AddButtonRef.current.style.opacity = 0;
        }
      }, 100);
    }
  };
  clearhideAddShiftButtonTimer = () => {
    clearTimeout(this.hideAddShiftButtonTimer);
  };
  onClickAddButton = () => {
    const { userKey, eventDate } = this.addButtonItem;
    this.props.onAddEvent(eventDate, userKey)();
    this.hideAddShiftButton();
  };
  onClickAddButton2 = () => {
    this.props.onAddEvent(null, null)();
    this.hideAddShiftButton();
  };

  onOpenEventModal = (event, shift) => {
    if (isElectron) {
      var target = event.target;
      // if(target.tagName!=='div')
      // {

      // }
      var exit = 5;

      if (!target.id) {
        do {
          // console.log('target', target)
          if (target) target = target.parentNode;
          else exit = -1;

          exit--;
        } while (target.id === "" || exit < 0);
      }

      // // console.log('target============', target)

      var {
        x,
        y,
        width,
        height,
        top,
        left,
        right,
      } = target.getBoundingClientRect();
      x = left;
      y = top;

      if (ipcRenderer)
        ipcRenderer.send("renderWindowTargetPos", {
          x,
          y,
          width,
          height,
          top,
          left,
          right,
        });
    }

    this.props.openEventModal(shift);
  };

  //TODO CACHE THIS!!
  getUserTotalMinutes = moize(
    (userKey, currentScheduleKey, weeksToShow) => {
      const { scheduleWeekData, dateKey, renderDayDate } = this.props;
      let totalMinutes = 0;
      let rDate = renderDayDate.clone();

      const innerFunction = (scheduleKey) => {
        if (currentScheduleKey !== scheduleKey) {
          //console.log('_scheduleWeekData', _scheduleWeekData[scheduleKey])//[this.props.dateKey]
          const schedule = _scheduleWeekData[scheduleKey];
          const rows = schedule.rows;
          if (rows) {
            var userShifts = rows.find((shift) => shift.k === userKey);
            if (userShifts && userShifts.s) {
              Object.keys(userShifts.s).forEach((dayKey) => {
                const day = userShifts.s[dayKey];

                const result = (day || []).reduce(function (
                  accumulator,
                  obj
                ) {
                  return accumulator + (obj.m || 0);
                },
                  0);
                totalMinutes += isNaN(result) ? 0 : result;
              });
            }
          }
        }
      }


      for (let index = 0; index < weeksToShow; index++) {
        rDate.add(7, "days");

        var _scheduleWeekData = scheduleWeekData[getDateKey(rDate)];
        if (_scheduleWeekData) {
          Object.keys(_scheduleWeekData).forEach(innerFunction);
        }
      }

      // console.log('totalMinutes', totalMinutes)
      return totalMinutes;
    }
    // , {
    //     onCacheHit: (
    //         cache,
    //         options
    //     ) => console.log('hit32')
    // }
  );





  // TODO we use this code inside of the row also, do a caluator object for user,day,week sums
  sumFunc = (o) => {
    if (o.removed)
      return 0
    if (!o.minutes)
      return 0
    return Number(o.minutes)
  }
  getCostSum = moize.deep((userEvents, salary, totalCostHashTable) => {
    //console.log('>>getCostSum');
    let sum = []

    if (userEvents) {
      Object.keys(userEvents).forEach(key => {
        const dayEvents = userEvents[key]
        var minutes2 = math.sumBy(dayEvents, this.sumFunc);

        if (!sum[key])
          sum[key] = 0

        sum[key] += minutes2;
        const totalMinutes = sum[key]
        if (!totalCostHashTable[key])
          totalCostHashTable[key] = {
            cost: (totalMinutes * (salary / 60)),
            time: totalMinutes
          }
        else {
          const oldVal = totalCostHashTable[key]
          totalCostHashTable[key] = {
            cost: oldVal.cost + (totalMinutes * (salary / 60)),
            time: oldVal.time + totalMinutes
          }

        }
      })
      //console.log('>>totalCostHashTable', totalCostHashTable);

      return totalCostHashTable
      //this.setState({ minutes: getTimeFromMins(minutes2) })
      //return sum
    }
    return null
  }
    //     , {
    //   onCacheHit: (
    //     cache,
    //     options
    //   ) => console.log('>>hit getCostSum')
    // }
  )

  renderRows = () => {
    // console.log(`>>renderRows!`);
    let totalCostHashTable = {}
    const {
      forceRender,
      openDivierModal,
      users,
      onAddEvent,
      curScheduleView,
      mergedUsers,
      onAddTemplateShift,
      onMoveEvent,
      renderDayDate,
      renderDayCount,
      weeksToShow,
      availabilityMetaData,
      role,
      cloneShift,
    } = this.props;
    var userList = mergedUsers;
    const userSortedEvents = curScheduleView.userSortedEvents2;
    const metaDataList = curScheduleView.metaData;
    const scheduleKey = curScheduleView.key;
    if (!mergedUsers) return; //TEMP FUCKER

    var order = 0;
 
    if (userList) {
      userList = arrayPutOnTop("openshifts", userList);
      userList = arrayPutOnTop("unassigned", userList);
    }

 
    var _renderDayDate = moment.utc(renderDayDate).utcOffset(0);
    const _renderDayCount = renderDayCount * weeksToShow;

    const renderdDivToIgnor = {};
    var userRows = userList.map((key) => {
      order++;
      if (users) {
        var user = users[key];
        if (!user) {
          user = { name: "Unknown user", newUser: true, key, isActive: true }; //TODO user factory
        }
      }
      if (user && user.key) {
        if (user.key.startsWith("div_")) {
          var div = curScheduleView.dividerList[user.key] || {
            name: "unknown",
          };
          var name = (div.name || '').toLowerCase();
          if (!renderdDivToIgnor[name]) {
            if (!renderdDivToIgnor[name]) {
              renderdDivToIgnor[name] = 1;
            }
          } else {
            return null;
          }
        }

        var userEvents = getUserEvents3(userSortedEvents, user.key);
        if (!user) return null;

        const totalMinutes = this.getUserTotalMinutes(
          user.key,
          scheduleKey,
          weeksToShow
        );


        totalCostHashTable = this.getCostSum(userEvents, Number(user.hourSalary || 0), totalCostHashTable)
        const newRow = (
          <ScheduleRow
            forceRender={this.props.forceRender}
            index={order}
            weeksToShow={weeksToShow}
            contexMenu={this.onUserRowContextMenu}
            AmPm={this.props.AmPm}
            showAddShiftButton={this.showAddShiftButton}
            hideAddShiftButton={this.hideAddShiftButton}
            projectKey={this.props.projectKey}
            dividerList={curScheduleView.dividerList}
            openDivierModal={openDivierModal}
            renderDayDate={_renderDayDate}
            renderDayCount={_renderDayCount}
            onOpenEventModal={this.onOpenEventModal}
            order={order}
            onDragOverShowDropZone={this.onDragOverShowDropZone}
            onDragOverHideDropZone={this.onDragOverHideDropZone}
            onMoveEvent={onMoveEvent}
            onAddTemplateShift={onAddTemplateShift}
            key={key}
            userKey={user.key}
            dragStartRow={this.onDragStartRow}
            dropRow={this.onDropRow}
            dragOverRow={this.onDragOverRow}
            imgUrl={user.image}
            openUser={this.onOpenModal}
            name={user.name}
            subText={user.name}
            onAddEvent={onAddEvent}
            userEvents={userEvents}
            metaDataList={metaDataList}
            availabilityMetaData={availabilityMetaData[user.key]}
            role={role}
            totalMinutes={totalMinutes} //this if minutes from other schedules
            cloneShift={cloneShift}
            hourSalary={Number(user.hourSalary || 0)}
          />
        );


        return (
          // <VisibilitySensor key={key} >
          newRow
          // </VisibilitySensor>
        );

      } else return null;
    });




    const sumRow = (
      <ScheduleRow
        forceRender={true}
        index={order}
        weeksToShow={weeksToShow}
        contexMenu={this.onUserRowContextMenu}
        AmPm={this.props.AmPm}
        showAddShiftButton={this.showAddShiftButton}
        hideAddShiftButton={this.hideAddShiftButton}
        projectKey={this.props.projectKey}
        dividerList={curScheduleView.dividerList}
        openDivierModal={openDivierModal}
        renderDayDate={_renderDayDate}
        renderDayCount={_renderDayCount}
        onOpenEventModal={this.onOpenEventModal}
        order={order}
        onDragOverShowDropZone={this.onDragOverShowDropZone}
        onDragOverHideDropZone={this.onDragOverHideDropZone}
        onMoveEvent={onMoveEvent}
        onAddTemplateShift={onAddTemplateShift}
        key={'costSummary'}
        userKey={'costSummary'}
        dragStartRow={this.onDragStartRow}
        dropRow={this.onDropRow}
        dragOverRow={this.onDragOverRow}
        imgUrl={''}
        openUser={this.onOpenModal}
        name={''}
        subText={''}
        onAddEvent={onAddEvent}
        userEvents={{ ...totalCostHashTable }}
        metaDataList={null}
        availabilityMetaData={null}
        role={role}
        totalMinutes={0} //this if minutes from other schedules
        cloneShift={cloneShift}
        hourSalary={0}
      />
    );
    // userRows.push(sumRow)
    // console.log('>>redner sum row',totalCostHashTable);
    this.setState({ userRows, sumRow }); // TODO these should have init decleration
  };

  // styleTbl = () => ({ minHeight: '100%', marginTop: '0px', marginBottom: '70px', }) //paddingLeft: '10px', borderTopLeftRadius: '8px', background: 'rgba(255,255,255,0.15)'
  style = {
    tbl: { minHeight: "100%", marginTop: "0px", marginBottom: "70px" },
    tblHead: { xposition: "fixed", background: "#fff" },
    tblRowDelete: { height: "40px" },
    tblRowDelete1: { position: "relative" },
    tblRowDelete2: {
      height: "40px",
      overflow: "hidden",
      position: "absolute",
      top: "0px",
      width: "100%",
    },
    tblRowDelete3: {
      paddingTop: "5px",
      paddingBottom: "1px",
      height: "31px",
      marginTop: "7px",
    },
    tblBody: {
      xmarginTop: "40px",
      display: "block",
      overflowY: "scroll",
      height: `calc(100vh -  ${isElectron ? 206 : 236}px)`, //height on tbl body
    },
    tblBodySum: {
      display: "block",
      overflowY: "scroll",
    },
    addShiftBtn: {
      position: "absolute",
      top: "-500px",
      opacity: "0",
    },
    addShiftBtnBtn: {
      padding: "5px",
      border: "none",
      background: "transparent",
    },
    addShiftBtn2: {
      position: "fixed",
      bottom: "80px",
      right: "35px",
      opacity: "0.7",
      zIndex: "1000",
    },
  };
  onDblClick = (e) => {
    e.preventDefault();

    try {
      if (!isElectron) return;

      var item = e.target;

      if (item.className === "availabilityDiv") item = item.parentNode;

      if (e.button === 0 && (item.tagName || '').toLowerCase() !== "td") {
        if (item.className === "flexDiv") {
          console.log("(ok)");
        } else {
          console.log("exit");
          return;
        }
      }
      var exit = 20;
      // eslint-disable-next-line no-unused-vars
      var eventId;
      if ((item.tagName || '').toLowerCase() !== "td") {
        do {
          if (item) {
            if (item.id) eventId = item.id;

            item = item.parentNode;
          } else exit = -1;
          // // console.log(exit, item)

          exit--;
        } while (
          ((item.tagName || '').toLowerCase() !== "body" &&
            (item.tagName || '').toLowerCase() !== "td") ||
          exit < 0
        );
      }

      window._clickedTarget = item;

      if ((item.tagName || '').toLowerCase() === "td") {
        var userkey = item.parentNode.getAttribute("id") || null;
        var dataedate = item.getAttribute("dataedate") || null;
        window.dispatchEvent(
          new CustomEvent("newshift", { detail: { userkey, dataedate } })
        );
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  createNewUser = () =>
    window.dispatchEvent(new CustomEvent("newuser", { detail: {} }));

  cellBgColor = moize((index) => {
    if (window._firstDay === 0 && (index === 0 || index === 6))
      return "stripes";
    else if (window._firstDay === 1 && (index === 5 || index === 6))
      return "stripes";
    else return null;
  });

  render() {
    if (false) {
      // console.log('************')
      // console.log('Schedle 1 ', this.props);
      // console.log('Schedle 1 ', this.props.curScheduleView);
      // console.log('isEmpty ', this.props.curScheduleView.isEmpty);
      // console.log('loading ', this.props.curScheduleView.loading);
      // console.log('************')
    }

    const tds = [];
    for (let index = 0; index < this.props.weeksToShow; index++) {
      //if (index > 0)
      //    tds.push(<td className="two wide scheduelCell" ></td>)

      tds.push(
        <td
          key={"id_1_" + index}
          className={"two wide scheduelCell " + this.cellBgColor(0)}
        ></td>
      );
      tds.push(
        <td key={"id_2_" + index} className="two wide scheduelCell"></td>
      );
      tds.push(
        <td key={"id_3_" + index} className="two wide scheduelCell"></td>
      );
      tds.push(
        <td key={"id_4_" + index} className="two wide scheduelCell"></td>
      );
      tds.push(
        <td key={"id_5_" + index} className="two wide scheduelCell"></td>
      );

      tds.push(
        <td
          key={"id_6_" + index}
          className={"two wide scheduelCell " + this.cellBgColor(5)}
        ></td>
      );
      tds.push(
        <td
          key={"id_7_" + index}
          className={"two wide scheduelCell " + this.cellBgColor(6)}
        ></td>
      );
    }

    return (
      <React.Fragment>
        <WebContextMenu
          open={this.state.isContextMenuOpen}
          config={this.state.contextMenu}
          onClose={() => {
            this.setState({ isContextMenuOpen: false });
          }}
        ></WebContextMenu>
        <div className="divFooter">www.TimeTo.Work</div>

        {this.props.userList && Object.keys(this.props.userList).length === 0 && (
          <React.Fragment>
            {this.props.isLoadingEvents === false &&
              this.props.currentScheduelData &&
              this.props.currentScheduelData.selectedKey && (
                <ScheduleEmpty
                  onOpenCopySheduleModal={this.props.openCopyScheduleModal}
                ></ScheduleEmpty>
              )}
            <div className="cc" style={styles.s5}>
              <Loader
                inline
                active={this.props.isLoadingEvents}
                style={styles.s6}
              ></Loader>
            </div>
          </React.Fragment>
        )}

        <table
          className="ui very basic table tableBodyScroll unstackable tblScheduel tblPrintTable"
          style={this.style.tbl}
          onMouseDown={this.onSchedualContextMenu} // this is changed from onContexmenu
          onDoubleClick={this.onDblClick}
        >
          <thead style={this.style.tblHead} className="fixed tblSchedualHead">
            {this.state.showDropZone === true && (
              <tr
                onDrop={this.onDropRemove}
                onDragOver={this.onDragOverShoweDropZone}
                style={this.style.tblRowDelete}
              >
                <th colSpan={8} style={this.style.tblRowDelete1}>
                  <div className="printHidden" style={this.style.tblRowDelete2}>
                    <Segment
                      style={this.style.tblRowDelete3}
                      inverted
                      color="red"
                      tertiary
                      onDragOver={this.onDragOver}
                    >
                      <Icon name="trash" /> {_getText("schedual.dropdelete")}{" "}
                      <Icon name="trash" />
                    </Segment>
                  </div>
                </th>
              </tr>
            )}
            {this.state.showDropZone === false &&
              this.props.renderDayDate &&
              getHeader(
                this.props.renderDayDate.format("YYYY-MM-DD"),
                moment().format("YYYY-MM-DD"),
                this.onDragOver,
                this.props.weeksToShow
              )}
          </thead>

          <tbody
            id="tbodySchedule1"
            style={this.style.tblBody}
            onScroll={this.hideAddShiftButton}
            onMouseLeave={this.hideAddShiftButton}
          >
            {this.state.userRows}

            <tr className="sRowTblAddRow" style={styles.s7}>
              <td className="two wide sRowCell " style={styles.s8}>
                <div
                  style={styles.s10}
                  onClick={this.createNewUser}
                  className="onboardStep_topmenu_addusers noPrint"
                >
                  <FontAwesomeIcon
                    icon={icoPlus}
                    style={styles.s11}
                  ></FontAwesomeIcon>{" "}
                  {_getText("AddRow")}
                </div>
              </td>
              {tds}
            </tr>

          </tbody>

        </table>
        {/* TODO add a view toggle here */}
        <table className="ui very basic table tableBodyScroll unstackable noPrint"
          style={{ ...this.style.tbl, position: 'sticky', bottom: 30 }}>
          <tbody style={this.style.tblBodySum}>
            {this.state.sumRow}
          </tbody>
        </table>

        {!isMobile && isElectron === false && (
          <span
            className="addShiftBtn"
            ref={this.AddButtonRef}
            style={this.style.addShiftBtn}
            onMouseMove={this.clearhideAddShiftButtonTimer}
          >
            <Button
              style={this.style.addShiftBtnBtn}
              compact
              onClick={this.onClickAddButton}
              circular
              icon="add"
              size="tiny"
            />
          </span>
        )}
        {/* {isMobile && isElectronApp === false &&
                        <span style={this.style.addShiftBtn2} >
                            <Button style={this.style.addShiftBtnBtn} compact onClick={this.onClickAddButton2} circular icon='add' color='blue' size='huge' style={styles.s12} />
                        </span>
                    } */}

        {this.props.viewstate.template && (
          <ScheduleTemplates
            onDragOverShowDropZone={this.onDragOverShowDropZone}
            onDragOverHideDropZone={this.onDragOverHideDropZone}
            templates={this.state.templates}
            onOpenEventModal={this.props.onOpenEventModal}
          ></ScheduleTemplates>
        )}
      </React.Fragment>
    );
  }
}

const mapActions = {
  openModal: openModal,
  onCloseModal: closeModal,
  openEventModal: openEventModal,
  openCopyScheduleModal,
  copyScheduleRow,
  //addUser
};
const mapStateToPropsFactory = (initialState, ownProps) => {
  // a closure for ownProps is created
  // this factory is not invoked everytime the component
  // changes it's props
  return mapStateToProps;
};
const mapStateToProps = (state, ownProps) => {
  var userList = {};

  //var dateKey = ownProps.dateKey;
  //const metaData = state.firestore.data["weekview_" + dateKey + "_meta"] || {};
  // var newMetaData = metaData[state.currentScheduelData.selectedKey];


  // const availabilityMetaData = metaData.avil || {};

  //USERS
  if (state.firestore.data && state.firestore.data.users)
    userList = state.firestore.data.users.userlist;

  return {
    //availabilityMetaData,
    currentScheduelData: state.currentScheduelData,
    role: state.firebase.profile.role || 0,
    projectKey: state.firebase.profile.projectKey,
    isLandscape: state.viewstate.isLandscape,
    cloneShift: state.viewstate.cloneShift,
    viewstate: state.viewstate,
    users: userList,
    AmPm: state.firestore.data.project.s
      ? state.firestore.data.project.s.AmPm || false
      : false,
  };
};
export default connect(mapStateToPropsFactory, mapActions)(Schedule1);



const styles = {
  //s1: { border: 'none', borderBottom: 'solid 1px rgba(34, 36, 38, 0.1)', width: (16 / weeksToShow) + '%' },
  s2: { position: "relative" },
  s3: {
    position: "absolute",
    top: 3,
    left: 8,
    color: "blueviolet",
    opacity: 0.9,
  },
  s4: {
    height: "40px",
    border: "solid 1px transparent",
    borderBottom: "solid 1px rgba(34, 36, 38, 0.1)",
    paddingRight: "6px",
  },
  s5: { animation: "fadeinLoader 3s", position: "fixed" },
  s6: { textAlign: "center", marginTop: "50px" },
  s7: { maxHeight: "100%", height: "100%" },
  s8: { maxHeight: "100%", height: "10%", verticalAlign: "top", width: "16%" },
  s9: { maxHeight: "100%", height: "10%" },
  s10: { padding: 2, marginLeft: 6 },
  s11: { fontSize: 15 },
  s12: { boxShadow: " 1px 1px 1px rgba(68,68,68,0.6)" },
  s13: { border: "none", width: "16%" },
};  
