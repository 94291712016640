module.exports = {
    'newTaskInfo': `Si necesita un recorrido rápido, haga clic en la pestaña 'Ayuda' en el menú inferior izquierdo.
        Luego haga clic en 'Hacer un recorrido' y vuelva a esta página.`,
    'Publish': `Publicar`,
    'schedual.btnPasteSchedule': `Pegar horario`,
    'schedual.btnCopySchedule': `Copiar horario`,
    'NewFeatuer': `Esta es una característica nueva.`,
    'LinkCopied': `enlace copiado`,
    'GoToWorkView': `Este es el horario publicado         Ir a la vista de trabajo para realizar cambios`,
    'QuickIntro': `¿Necesita una introducción rápida de cómo funciona esta aplicación?`,
    'Workview': `Vista de trabajo`,
    'AddRow': `Agregar fila`,
    'Published': `Publicado`,
    'thisIsWatStaffSee': `Esto es lo que su personal ve`,
    'Unpublish': `Anular publicación`,
    'TaskStartInfo': `Haga clic en una tarea o cree una nueva haciendo clic en el botón de más en la esquina superior derecha`,
    'ClickHereToStart': `Haga clic aquí para comenzar`,
    'joy.viewWeeks': `Número de semanas para mostrar`,
    'tour.onboardStep_mainMenu': `Empiece haciendo clic en la pestaña que quiere visitar.`,
    'comefirmUnlinkUser': `¿Quiere desvincular el usuario?`,
    'draft': `Borrador, no publicado`,
    'Unpublishing': `Anulando la publicación ...`,
    'AreYouSurePublish': `¿Está seguro de que quiere publicar el horario para todo el personal?`,
    'tour.tour_report_1': `Mes actual. Utilice las flechas para cambiar el mes.`,
    'tour.tour_chats_3': `Seleccione una sala de chat y escriba su mensaje aquí.`,
    'AdminCanAddScheduls': `Solo el administrador puede agregar nuevos horarios`,
    'PrintNoAbuseMsg': `Solo el cliente que pague puede usar la función de impresión
        (Esto es para evitar el abuso del sistema).`,
    'tour.tour_schedule_9': `Esta es su vista de trabajo, los miembros del personal no pueden ver esta versión del horario.`,
    'CopyShift': `Copiar turno`,
    'tour.tour_requests_2': `El turno original de su horario publicado.`,
    'ClearSchedule': `¿Quiere borrar este horario?`,
    'tour.tour_schedule_6': `Haga clic en un turno para editarlo o arrástrelo para desplazarlo.
        Si mantiene pulsada la opción, al arrastrar, el turno se clonará.`,
    'tour.tour_staff_3': `Haga clic aquí para enviar un correo de invitación a todos los usuarios no invitados al mismo tiempo.`,
    'PleaseAddCreditsBeforePrinting': `Por favor, agregue créditos antes de imprimir`,
    'tour.tour_staff_1': `Haga clic aquí para agregar un nuevo miembro del personal`,
    'AddDivider': `Agregar divisor`,
    'WantToWork': `Quiere trabajar`,
    'tour.tour_requests_5': `Los cambios fueron rechazados, ningún cambio se ha realizado.`,
    'tour.tour_report_3': `Esta es la hoja de tiempo
        Aquí puede ver las horas trabajadas.
        Todos los datos provienen del horario publicado.
        coloque el cursor sobre una fila y luego haga clic en el lápiz para editar el turno.`,
    'CreateShiftRequest': `Crear solicitud de turno`,
    'tour.tour_chats_2': `Como administrador, puede agregar nuevas salas de chat.`,
    'tour.tour_schedule_10': `Esto es lo que sus usuarios ven en la aplicación móvil.
        Estos son también los datos de los informes.`,
    'tour.tour_schedule_6_1': `Haga clic en el usuario para copiar una fila o ver el usuario.`,
    'tour.tour_schedule_5': `También puede hacer doble clic en un día para añadir un turno
        (Por favor, asegúrese de tener al menos un turno en el horario antes de pasar al siguiente paso)`,
    'TakeTour': `Haga un recorrido por el producto`,
    'tour.tour_report_2': `Seleccione un usuario y luego haga clic en Siguiente.
        Si el horario de usuario es 0: 00h, debe publicar su horario.
        Todos los datos aquí mostrados provienen del horario publicado.`,
    'Mo': `Lu`,
    'Fr': `Vier `,
    'tour.tour_staff_2': `Haga clic aquí para agregar varios miembros a la vez`,
    'login.demo': `Demo`,
    'NewVersion': `Hay una nueva versión disponible, ¿quiere actualizar ahora?`,
    'Th': `Jue `,
    'Askforthisshift': `Preguntar por este turno`,
    'tour.tour_schedule_8': `Si desea eliminar el horario publicado, puede hacerlo haciendo clic aquí.`,
    'tour.tour_requests_3': `El nuevo turno que el miembro del personal quiere cambiar ...`,
    'tour.tour_schedule_3': `O utilice el selector de semanas para ir a una semana específica.`,
    'tour.tour_today_2': `Haga clic aquí si tiene turnos que se superponen.`,
    'tour.tour_requests_empty': `Asegúrese de tener al menos una solicitud antes de realizar el recorrido.
        
        Por ejemplo, haga clic en un turno en el horario y luego haga clic derecho en "Crear solicitud de turno"
        `,
    'ThereIsNoPubSchedule': `No hay un horario publicado este mes.`,
    'tour.tour_staff_4': `Para ver o editar un miembro del personal, haga clic aquí`,
    'PasteShift': `Pegar turno`,
    'TaskRestarted': `La tarea se reanuda y se mueve a la pestaña Activa.`,
    'NewShift': `Nuevo turno`,
    'TaskIsRemoved': `La tarea se ha eliminado.`,
    'DeleteShift': `Eliminar turno`,
    'shiftIsCloned': `El turno se clonó`,
    'TaskIsPublished': `La tarea está publicada.`,
    'tour.tour_schedule_2': `Semana actual, use las flechas para pasar a la semana siguiente/anterior.`,
    'schedual.m.Tour': `Ayuda`,
    'MovetToArchive': `La tarea se mueve al Archivo.`,
    'tour.tour_schedule_4': `Haga clic aquí para añadir un usuario o un turno
        (Si no tiene ningún usuario en su agenda, por favor añada uno ahora)`,
    'tour.tour_today_1': `Así es como se ve el día de hoy.  Los datos provienen del horario publicado.`,
    'PrivateNotes': `Notas privadas`,
    'minutes': `minutos`,
    'Available': `Disponible`,
    'DemoAccount': `Cuenta demo, haga clic aquí para cerrar la sesión y comenzar su propia cuenta.`,
    'AddASchedule': `Agregue un horario para comenzar.`,
    'AreYouSureDeleteTask': `¿Está seguro de que desea eliminar esta tarea?`,
    'AddStaff': `Agregar personal`,
    'tour.tour_schedule_7': `Cuando esté satisfecho con su horario y quiera compartirlo con su personal.
        Haga clic aquí para publicar.`,
    'tour.tour_requests_1': `Esta es la bandeja de entrada y la bandeja de salida para sus solicitudes.`,
    'Vacation': `Vacaciones`,
    'Sa': `Sab`,
    'RequestIsSent': `Solicitud enviada`,
    'DataComesFromPubSche': `(Los datos que se muestran aquí provienen de los horarios publicados)`,
    'MovetToActive': `La tarea se traslada a Activa.`,
    'addMoreBeforPrint': `Agregue más créditos antes de imprimir`,
    'StartByAddSchedule': `Comience agregando el nombre de su horario principal.`,
    'We': `Mie`,
    'tour.tour_staff_5': `Haga clic aquí para invitar a un usuario por correo o SMS.`,
    'Tu': `Ma`,
    'schedual.m.Tasks': `Tareas`,
    'YouCanOnlyPrint4week': `Solo puede imprimir con cuatro semanas de antelación`,
    'tour.tour_requests_4': `Aprobar la solicitud del usuario
        Este turno se actualizará en su horario publicado.`,
    'tour.tour_chats_1': `Esta es su lista de chat. 
        Se trata de un grupo de chat sin eliminación para todos los miembros del personal. 
        Log es una lista de registro de sus solicitudes/respuestas.`,
    'ScheduleIsCopied': `El horario se ha copiado`,
    'PleaseRestart': `Por favor, reinicie su aplicación para que se aplique la configuración.`,
    'tour.tour_schedule_1': `El horario.
        Haga clic en el engranaje para cambiar un nombre o borrar el horario.
        (Por favor, asegúrese de que tiene al menos un usuario dentro del horario antes de continuar)`,
    'Su': `Dom `,
    'TaskIsUnPublished': `La tarea no está publicada.`,
    'tour.tour_report_4': `Haga clic aquí para exportar un informe xlsx.
        Si desea descargar todos los informes en una hoja, utilice el menú superior.
        Archivo -> Exportar -> Exportar como xlsx.`,
    'CanNotWork': `No puede trabajar`,
    'login.err.2': `La contraseña es inválida.`,
    'createNewAccount.info': `Use el número de teléfono si recibió una invitación por SMS o un correo electrónico si recibió una invitación por correo`,
    'createNewAccount.smscodeTitle': `Código del mensaje de texto`,
    'login.err.1': `No hay ningún usuario que se corresponda con esta dirección de correo electrónico. Es posible que se haya eliminado el usuario.`,
    'login.err.4': `Un administrador inhabilitó la cuenta.`,
    'createNewAccount.newaccount': `Nueva cuenta de personal`,
    'login.btnLogin': `Iniciar sesión`,
    'login.err.3': `La dirección de correo electrónico tiene un formato incorrecto.`,
    'login.err.6': `Este no es un número de teléfono válido. Debe comenzar con el código de su país.`,
    'createNewAccount.newSchedule': `Nuevo horario`,
    'login.err.5': `La contraseña debe tener 6 caracteres o más.`,
    'createNewAccount.teleOrEmail': `Número de teléfono o correo electrónico`,
    'createNewAccount.setPassword': `Establecer nueva contraseña`,
    'createNewAccount.smscode': `Código del mensaje de texto`,
    'createNewAccount.login': `Iniciar sesión`,
    'or': `o`,
    'set.settings.btn.dev': `Desviaciones`,
    'createNewAccount.dontHavePassword': `¿No tiene una contraseña?`,
    'set.settings.time': `Hora`,
    'set.settings.timeWarning': `Cuando se cambia, debe volver a guardar los datos de su horario para renovar las etiquetas de tiempo almacenadas en caché`,
    'set.settings.btn.com': `Comentarios`,
    'set.settings.publickey': `Clave de la empresa`,
    'set.settings.auto': `Aprobación automática de cambios de turno de usuario`,
    'set.settings.btn.sMov': `Mover turnos`,
    'set.settings.publickeytext': `Esta es la clave que los miembros de su personal necesitan cuando crean una nueva cuenta`,
    'set.settings.btn.sCan': `Eliminar turnos`,
    'createNewAccount.comName': `Nombre de empresa`,
    'SCHEDULES': `HORARIOS`,
    'on': `Encendido`,
    'createNewAccount.weekstart': `La semana comienza con`,
    'createNewAccount.secretKey': `Clave de la empresa`,
    'import': `Importar`,
    'signout': `Desconectar`,
    'createNewAccount.comDisplayName': `Nombre de la empresa  `,
    'createNewAccount.adminAccount': `Cuenta de administrador`,
    'January': `Enero`,
    'June': `Junio`,
    'March': `Marzo`,
    'time': `Tiempo `,
    'uploading_image': `Subiendo imagen ...`,
    'December': `Diciembre`,
    'shifts': `turnos`,
    'inactive': `Inactivo`,
    'week': `Semana`,
    'February': `Febrero`,
    'schedule': `Horario `,
    'phonenumber': `Número de teléfono`,
    'h': `h`,
    'message.out': `Fuera`,
    'oops': `¡Ups!`,
    'update': `Actualizar`,
    'save': `Guardar  `,
    'total': `Total`,
    'Apr': `Abr`,
    'users.infoText': `Estos se utilizan para conectar a su personal con un usuario en el horario, solo necesita configurar uno de ellos.`,
    'schedual.m.addNewUser': `Agregar nuevo miembro del personal`,
    'schedual.lbl-unassigned': `Sin asignar`,
    'logginin': `Iniciando sesión...`,
    'back': `Volver`,
    'upload': `Subir`,
    'August': `Agosto`,
    'tooltip.AddANewChat': `Agregar un nuevo chat`,
    'active': `Activo`,
    'saturday': `Sábado`,
    'average': `Promedio`,
    'September': `Septiembre`,
    'break': `Descanso`,
    'billing.nextInvoice': `Próxima factura`,
    'billing.msgBody': `Agregue más créditos para continuar usando el sistema`,
    'set.settings.btn.sik': `Baja por enfermedad`,
    'AreYouSureYouWantToQuitYes': `Si`,
    'terms': `Términos de Uso`,
    'setup.creatingCompany': `Creando cuenta comercial ...`,
    'setup.notValidKey': `Esta no es una clave de empresa válida`,
    'user.active': `Activo`,
    'users.linked': `Vinculado`,
    'users.uniquephone': `El teléfono debe ser único`,
    'schedual.addS': `Agregar nuevo horario`,
    'schedual.m.addDiv': `Agregar divisor de usuario`,
    'locked.txt2': `No se preocupe, es una solución fácil, agregue más créditos y en unos minutos se habilitará nuevamente`,
    'setup.comKey': `Clave de la empresa`,
    'setup.createNewAccount': `Cree una nueva cuenta de usuario`,
    'setup.updateKey': `Actualizar clave`,
    'setup.retry': `Reintentar`,
    'createNewAccount.youneedCompanyName': `Necesita tener un nombre de empresa`,
    'createNewAccount.theKey': `La clave que recibió de su gerente`,
    'users.infoText1': `Este usuario está vinculado a un miembro del personal, haga clic en el candado para desvincularlo.`,
    'Oct': `Oct`,
    'search': `Buscar...`,
    'new': `Nuevo`,
    'tuesday': `Martes`,
    'date': `Fecha`,
    'users': `usuarios`,
    'October': `Octubre`,
    'hours': `Horas`,
    'rotateimage': `Rotar imagen`,
    'login.lostpass': `¿Olvido su contraseña?`,
    'tooltip.Deny': `Negar`,
    'phone': `Teléfono`,
    'add': `Añadir`,
    'monday': `Lunes`,
    'sick': `Enfermo`,
    'name': `Nombre`,
    'message.empty': `No tiene ninguna solicitud.`,
    'cellphone': `Teléfono móvil`,
    'create': `Crear`,
    'picture': `Imagen`,
    'Dec': `Dic`,
    'delete': `Borrar`,
    'message.out.empty': `No tiene ninguna solicitud pendiente.`,
    'send': `Enviar`,
    'wednesday': `Miércoles`,
    'Sep': `Sept`,
    'Jul': `Jul`,
    'Jun': `Jun`,
    'login.newUser': `Nueva cuenta de personal`,
    'message.chat': `Conversación`,
    'logginout': `Saliendo de la sesión…`,
    'sunday': `Domingo`,
    'wShort': `Se.`,
    'Aug': `Ago`,
    'Mar': `Mar`,
    'yes': `Si `,
    'now': `Ahora`,
    'login.forEmp': `Para el empleado`,
    'login.newSchedule': `Nueva cuenta de horario`,
    'login.titel': `Iniciar sesión`,
    'tooltip.Cancel': `Cancelar`,
    'password': `Contraseña`,
    'type': `Tipo`,
    'Feb': `feb`,
    'off': `Apagado`,
    'login.titleNewAccount': `Nueva cuenta`,
    'login.sendpass': `Enviar nueva contraseña`,
    'loading': `Cargando...`,
    'cancel': `Cancelar`,
    'thursday': `Jueves`,
    'May': `May`,
    'November': `Noviembre`,
    'April': `Abril`,
    'billing.payDetails': `Detalles del pago`,
    'report.h_time': `Hora`,
    'request.titleChange': `Cambio de hora`,
    'billing.msgHead2': `o su cuenta se bloqueará automáticamente`,
    'shiftuserinput.title4': `Marcar como enfermo`,
    'billing.infoTxt2': `después de eso, su acceso se desactivará hasta que agregue más crédito`,
    'appdiv.pushmessages': `Mensajes push`,
    'wallet.txtWeb2': `incluso si solo usa TimeTo.Work en la web, debe realizar los pagos a través de una de las tiendas. Descargue la aplicación de una de las tiendas y verá un botón de comprar más créditos dentro de la aplicación aquí`,
    'request.titleComment': `Comentario`,
    'set.settings.btn.openshifts': `Turno de libro`,
    'request.adminRequest': `Solicitud`,
    'userhome.youarefree': `Es libre de hacer lo que quiera esta semana`,
    'report.h_workedhours': `Horas trabajadas`,
    'editdivider.title': `Divisor de grupo`,
    'request.titleSwap': `Cambio de turno`,
    'shiftuserinput.title5': `Solicitar turno`,
    'wallet.purchasing': ` `,
    'shiftuserinput.btn2': `Pide un cambio`,
    'report.h_date': `Fecha`,
    'shiftuserinput.btn3': `Añadir un comentario`,
    'report.downloadall': `Descargar todo`,
    'selectuser.title': `Lista de usuarios`,
    'billing.infoTxt3': `Si no desea continuar usando su horario, simplemente puede omitir la adición de nuevos créditos, después de 7 días, su cuenta se bloqueará y después de 360 días se eliminará.`,
    'usernotmapped_txt1': `No está asignado a un usuario en el horario`,
    'schedual.adminrequest': `Administrador`,
    'wallet.waiting_for_validation': `Esperando validación.`,
    'usernotmapped_title': `¡Importante!`,
    'report.downloadExcel': `Descargar Excel`,
    'appdiv.changepass': `Cambiar la contraseña`,
    'shiftuserinput.btn6': `Ajustar tiempo`,
    'appmenu.user': `Usuario`,
    'wallet.txtWeb1': `Por el momento, solo admitimos la compra de crédito nuevo a través de Google Play o iOS App Store`,
    'billing.msgHead1': `Agregue más crédito antes`,
    'appmenu.changepass': `Cambiar la contraseña`,
    'wallet.deferred': `La compra de se ha aplazado.`,
    'request.titleUserrequestshift': `Solicitud de turno`,
    'set.settings.btn.sSwp': `Cambiar turnos`,
    'wallet.success': `Exitoso.`,
    'wallet.failed': `No se pudo comprar.`,
    'schedual.btnAddUser': `Agregar personal`,
    'shiftuserinput.swapTitle1': `Seleccionar usuario para intercambiar`,
    'shiftuserinput.usercomment': `Comentario de usuario`,
    'shiftuserinput.btn7': `Asignar turno a otro usuario`,
    'report.h_break': `Descansos`,
    'billing.infoTxt1': `Cada 30 días verificaremos los créditos usados frente a sus créditos existentes, si tiene poco crédito, tendrá 7 días para agregar más créditos`,
    'billing.msgBodyDays': `dias`,
    'wallet.faild': `comuníquese con soporte si tiene alguna pregunta`,
    'shiftuserinput.title2': `Pida un cambio`,
    'report.h_sik': `Enfermo`,
    'report.h_hours': `Horas`,
    'report.noscheduled': `No hay horas programadas este mes`,
    'schedual.btnAddShift': `Agregar turno`,
    'wallet.failed1': `comuníquese con soporte si tiene alguna pregunta`,
    'wallet.pleasewait': `por favor espere`,
    'shiftuserinput.addCommentToUser': `Agregar comentario al usuario`,
    'shiftuserinput.btn5': `Cancelar turno`,
    'shiftuserinput.title0': `Actualizar turno`,
    'appmenu.pushmessages': `Notificaciones push`,
    'wallet.title1': `Comprar más créditos`,
    'WeCanNotMatchYou': `No podemos relacionar su correo con un usuario, pídale a su gerente que agregue su correo a un usuario en el horario`,
    'settings.title': `Preferencias`,
    'and': `y`,
    'setup.yesImAManger': `Si, soy gerente`,
    'settings.account': `Cuenta`,
    'Help': `Ayuda`,
    'dontShowThis': `No mostrar esto al inicio`,
    'IncomingRequests': `Solicitudes entrantes`,
    'Preferences...': `Preferencias ...`,
    'billing.msgLink': `Vaya a TimeTo.Work -> Preferencias -> Facturación. En el menú superior`,
    'policy': `Política de privacidad`,
    'joy.welcome': `¡Bienvenido!`,
    'joy.Last': `Último`,
    'log': `Iniciar sesión`,
    'EditChat': `Editar chat`,
    'joy.startbyaddingusers': `Comience agregando algunos miembros del personal`,
    'Toggle menu bar icon': `Alternar icono de barra de menú`,
    'AddMoreCredits': `Agregar más créditos`,
    'Paste schedule': `Pegar horario`,
    'Staff': `Personal`,
    'Signout': `Cerrar sesión`,
    'settings.billing': `Facturación`,
    'Hide TimeTo.Work': `Ocultar TimeTo.Work`,
    'Quit': `Salir`,
    'Copy': `Copiar`,
    'joy.clickHereToAddMultipleUsers': `Aquí puede agregar varios miembros del personal a la vez`,
    'Reports as XLSX': `Informes como XLSX`,
    'Clear all data': `Borrar todos los datos`,
    'Edit': `Editar`,
    'OrAreYouAManger': `¿O es gerente y desea crear un nuevo horario de personal?`,
    'companyNameIsNotValid': `El nombre de la empresa no es válido`,
    'a minute ago': `hace un minuto`,
    'About TimeTo.Work': `Acerca de TimeTo.Work`,
    'OutgoingRequests': `Solicitudes emitidas`,
    'joy.clickHereToAddAShift': `Haga clic aquí para agregar un turno`,
    'settings.settings': `Configuraciones`,
    'AreYouSureYouWantToQuitTitle': `Confirmar salida`,
    'joy.Back': `atrás`,
    'window': `TimeTo.Work`,
    'How do i ...': `Cómo puedo ...`,
    'Divider': `Divisor`,
    'byclicking': `Al hacer clic en 'Crear', acepta las`,
    'all': `Todos`,
    'joy.goToSchedule': `vaya al horario para comenzar a programar`,
    'Saved': `Guardado`,
    'an hour ago': `hace una hora`,
    'joy.whenYourDone': `Cuando haya terminado de agregar miembros del personal,`,
    'Shift': `Turno`,
    'TotalBreakTime': `Tiempo total de descanso`,
    'shortIntro': `Esta es una breve introducción para que se ponga al día con el funcionamiento de TimeTo.work`,
    'a day ago': `Hace un día`,
    'minutes ago': `hace unos minutos`,
    'Window': `Ventana`,
    'Clear schedule': `Limpiar horario`,
    'Copy schedule': `Copiar horario`,
    'New schedule': `Nuevo horario`,
    'Hide Others': `Ocultar otros`,
    'Cut': `Cortar`,
    'RestorePreviousPurchase': `Restaurar compra anterior`,
    'currentPrice': `1 usuario por 30 días = 1 crédito`,
    'AreYouSureYouWantToQuitNo': `No`,
    'WelcomeTo': `Bienvenido a TimeTo.Work`,
    'joy.addMultipleRows': `Aquí puede agregar varias filas a la vez`,
    'joy.Next': `próximo`,
    'Prev': `Anterior`,
    'Next': `próximo`,
    'Export': `Exportar`,
    'New staff': `Personal nuevo`,
    'AreYouSureYouWantToQuit': `¿Seguro que quiere salir?
    
    Perderá todos los datos no publicados.`,
    'zoom': `enfocar`,
    'joy.dontForgetToSave': `Cuando haya terminado, no olvide guardar y publicar su horario`,
    'users.invalidphone2': `Debe agregar el código del país antes del número`,
    'users.notemail': `Este no es un email válido`,
    'users.staffmembers': `Miembros del personal`,
    'schedual.addSName': `Nombre del horario`,
    'schedual.editS': `Editar horarios`,
    'schedual.m.tools': `Herramientas`,
    'schedual.m.Reports': `Informes`,
    'schedual.m.Request': `Solicitud`,
    'schedual.m.Today': `Hoy`,
    'schedual.emptyTxt1': `Utilice el clic secundario para agregar personal o turnos.`,
    'schedual.emptyTxt2': ` `,
    'createNewAccount.ampmOr24h': `Formato de tiempo`,
    'createNewAccount.termsApprove': `Debe aprobar los términos y condiciones.`,
    'createNewAccount.terms': `Estoy de acuerdo con los términos y condiciones`,
    'createNewAccount.useSameEmail': `Utilice el mismo correo electrónico que recibió en la invitación. Para que podamos mapearlo a un usuario en el horario`,
    'set.billing': `Facturación`,
    'user.allLinked': `Todos los usuarios están vinculados`,
    'user.unlockEmail': `Desbloquear correo electrónico`,
    'users.uniqueemail': `El correo electrónico debe ser único`,
    'schedual.m.pasteS': `Pegar horario`,
    'schedual.m.addNewSchedule': `Agregar nuevo horario`,
    'schedual.m.Staff': `Personal`,
    'schedual.m.Schedule': `Horario`,
    'schedual.last': `Última actualización`,
    'locked.txt1': `Su cuenta está bloqueada temporalmente debido a la falta de créditos.`,
    'locked.title': `¡Su cuenta esta bloqueada!`,
    'setup.needSetup': `Hemos creado su cuenta correctamente, pero necesitamos conectarla a una empresa.`,
    'createNewAccount.youneedkey': `Necesita tener una clave de empresa`,
    'createNewAccount.emailAndPass': `Correo electrónico y contraseña`,
    'set.settings': `Configuraciones`,
    'user.bulkTitle': `Insertar una lista separada por comas con usuarios`,
    'users.delete': `Si elimina el usuario, se eliminará permanentemente.
        Y todos los datos históricos se marcarán con 'Usuario desconocido'
    A veces puede ser mejor marcar al usuario como inactivo.`,
    'users.dname': `Nombre para mostrar`,
    'users.newuser': `Nuevo`,
    'schedual.deleteBtn2': `Confirmar eliminación permanente`,
    'schedual.deleteWarning': `Si elimina el horario, se eliminará permanentemente junto todos sus datos históricos.`,
    'schedual.deleteBtn1': `Eliminar horario`,
    'schedual.m.addOpenShifts': `Agregar 'turnos abiertos'`,
    'schedual.m.print': `Impresión`,
    'schedual.m.Chat': `Chat`,
    'schedual.openshifts': `Turnos abiertos`,
    'locked.link': `Haga clic aquí para obtener más créditos`,
    'schedual.dropdelete': `¡Suelte el turno / usuario aquí para eliminarlo!`,
    'setup.mappingUser': `Ajustando el usuario a un horario ...`,
    'user.inviteUnMaped': `Invitar a usuarios desvinculados`,
    'set.settings.title': `Ajustes del sistema`,
    'user.bulkTxt': `Un usuario por fila. Separe el nombre y el número / correo con una coma`,
    'users.deleteBtn1': `Borrar usuario`,
    'users.uniqueemail2': `Este correo no se puede utilizar`,
    'schedual.m.undo': `Deshacer todos los cambios`,
    'schedual.m.copyS': `Copiar horario`,
    'schedual.m.Home': `Inicio`,
    'schedual.emptyTitle': `¡Su horario está vacío!`,
    'setup.wrongKey': `Falta la clave de la empresa o es incorrecta`,
    'user.bulkimport': `Importación masiva`,
    'users.deleteBtn2': `Confirmar eliminación permanente`,
    'schedual.m.clear': `Borrar todos los turnos`,
    'users.invalidphone': `este no es un numero de telefono válido`,
    'user.bulkTxt2': `Nombre para mostrar, correo electrónico o teléfono
    John Doe, +1123456789 o Jane Doe, jane@timeto.work`,
    'schedual.lbl-openshifts': `Turnos abiertos`,
    'schedual.addSFirstTitle': `Agregar nuevo horario`,
    'login.sentResetMail': `Hemos enviado un enlace de restablecimiento a su correo electrónico.`,
    'message.in': `En`,
    'email': `Correo electrónico`,
    'login.forCom': `Para la compañía`,
    'message.in.empty': `No tiene ninguna solicitud.`,
    'credit': `Crédito`,
    'Jan': `ene`,
    'invites': `Invitar  `,
    'July': `Julio`,
    'Nov': `Nov`,
    'validphone': `+ código de país y número de móvil`,
    'friday': `Viernes`,
    'tooltip.Approve': `Aprobar`,
    'no': `No`,
    'calendar.empty': `No hay horario esta semana`,
    'schedual.btnHelp': `Ayuda`,
    'schedual.btnAddDiv': `Agregar divisor`,
    'schedual.btnPublish': `Guardar y publicar`,
    'wallet.restored': `Se ha restaurado la compra de.`,
    'wallet.success1': `los créditos llegarán a su cuenta en breve`,
    'request.titleSick': `Enfermo`,
    'request.titleTime': `Desviación de tiempo`,
    'request.titleMove': `Mover turno`,
    'request.titleCancle': `Cancelar turno`,
    'shiftuserinput.cancelation': `Causa de cancelación`,
    'shiftuserinput.swapTitle3': `Seleccionar turno`,
    'shiftuserinput.swapTitle2': `Seleccionar semana`,
    'shiftuserinput.comment': `Comentario`,
    'shiftuserinput.btn8': `Cambiar turno con otro usuario`,
    'shiftuserinput.btn4': `Marcar como enfermo`,
    'shiftuserinput.btn1': `Informe de desviación de tiempo`,
    'shiftuserinput.title3': `Añadir un comentario`,
    'shiftuserinput.title1': `Tiempo de trabajo real`,
    'joy.Close': `Cerrar`,
    'front': `Traer todo al frente`,
    'minimize': `minimizar`,
    'Quick introduction': `Introducción rápida`,
    'Schedule as PDF': `Horario como PDF`,
    'New chat': `Nueva conversación`,
    'Select All': `Seleccionar todo`,
    'services': `Servicios`,
    'years ago': `hace años`,
    'months ago': `Hace meses`,
    'days ago': `hace días`,
    'hours ago': `horas atras`,
    'a few seconds ago': `hace unos pocos segundos`,
    'joy.clickonstaff': `Haga clic en 'Personal'`,
    'a year ago': `Hace un año`,
    'a month ago': `hace un mes`,
    'seconds ago': `hace unos segundos`,
    'joy.orClickOnTheSchedule': `O puede hacer doble clic en el horario`,
    'joy.clickHerToAddUsers': `Haga clic aquí para agregar uno o dos miembros del personal`,
    'joy.Skip': `Saltar tutorial`,
    'Undo all changes': `Deshacer todos los cambios`,
    'Add to schedule': `Agregar al horario`,
    'File': `Archivo`,
    'selectuser.warning': `Seleccione al menos un usuario para agregar al horario`,
    'usernotmapped_txt2': `Pídale a su administrador de esquema que agregue su dirección de correo electrónico`,
    'report.h_schedal': `Horario`,
    'report.h_presence': `Presencia`,
    'billing.bHistory': `Historial de facturación`,
    'billing.msgHead': `Su cuenta no tiene suficientes créditos`,
    'set.settings.btn.sCha': `Cambio de hora`,
    'Show All': `Mostrar todo`,
    'Paste': `Pegar`,
    'userCredits': `créditos de usuario`,
    'ShiftLabel': `Etiqueta de turno`,

}