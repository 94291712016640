module.exports = {
    'newTaskInfo': `Se hai bisogno di un rapido tour, fai clic sulla scheda "Guida" nel menu in basso a sinistra. Clicca poi "Fai un tour" e torna su questa pagina.`,
    'Publish': `Pubblicare`,
    'schedual.btnPasteSchedule': `Incolla pianificazione`,
    'schedual.btnCopySchedule': `Copia programma`,
    'NewFeatuer': `Questa è una nuova funzionalità.`,
    'LinkCopied': `link copiato`,
    'GoToWorkView': `Questo è il programma pubblicato
        Vai alla visualizzazione lavoro per apportare modifiche`,
    'QuickIntro': `Hai bisogno di una rapida introduzione su come funziona questa app?`,
    'Workview': `Visualizzazione lavoro`,
    'AddRow': `Aggiungi riga`,
    'Published': `Pubblicato`,
    'thisIsWatStaffSee': `Questo è ciò che vede il tuo staff`,
    'Unpublish': `Non pubblicato`,
    'TaskStartInfo': `Cliccare su un'attività o crearne una nuova facendo clic sul pulsante +  nell'angolo in alto a destra`,
    'ClickHereToStart': `Clicca qui per iniziare`,
    'joy.viewWeeks': `Numero di settimane da mostrare`,
    'tour.onboardStep_mainMenu': `Inizia facendo clic sulla scheda per cui desideri un tour.`,
    'comefirmUnlinkUser': `Vuoi scollegare l'utente?`,
    'draft': `Bozza, non pubblicata`,
    'Unpublishing': `Annullamento pubblicazione in corso ...`,
    'AreYouSurePublish': `Sei sicuro di voler pubblicare il programma per tutto il personale?`,
    'tour.tour_report_1': `Mese corrente. Usa le frecce per cambiare il mese.`,
    'tour.tour_chats_3': `Seleziona una chat room e scrivi qui il tuo messaggio.`,
    'AdminCanAddScheduls': `Solo l'amministratore può aggiungere nuove programmi`,
    'PrintNoAbuseMsg': `Solo il cliente che paga può utilizzare la funzione di stampa (Questo per evitare abusi di sistema.)`,
    'tour.tour_schedule_9': `Questa è la visualizzazione del lavoro, i membri del personale non possono vedere questa versione del programma.`,
    'CopyShift': `Copia turno`,
    'tour.tour_requests_2': `Il turno originale dal programma pubblicato.`,
    'ClearSchedule': `Vuoi cancellare questo programma?`,
    'tour.tour_schedule_6': `Fare clic su un turno per modificarlo o trascinalo per spostarlo.
        Se tieni premuta l'opzione durante il trascinamento, lo spostamento verrà clonato.`,
    'tour.tour_staff_3': `Fare clic qui per inviare un unico messaggio di invito a tutti gli utenti non invitati.`,
    'PleaseAddCreditsBeforePrinting': `Si prega di aggiungere crediti prima di stampare`,
    'tour.tour_staff_1': `Fare clic qui per aggiungere un nuovo membro dello staff`,
    'AddDivider': `Aggiungi separatore`,
    'WantToWork': `Voler lavorare`,
    'tour.tour_requests_5': `Le modifiche sono state rifiutate e non verranno apportate modifiche.`,
    'tour.tour_report_3': `Questa è la scheda attività
        Qui puoi vedere le ore lavorative.
        Tutti i dati provengono dalla pianificazione pubblicata.
        passa il mouse su una riga, quindi fai clic sulla penna per modificare lo spostamento.`,
    'CreateShiftRequest': `Crea richiesta di turno`,
    'tour.tour_chats_2': `In qualità di amministratore, puoi aggiungere una nuova chat room.`,
    'tour.tour_schedule_10': `Questo è ciò che i tuoi utenti vedono nell'app mobile. Questi sono anche i dati nei rapporti.`,
    'tour.tour_schedule_6_1': `Fare clic sull'utente per copiare una riga o visualizzare l'utente.`,
    'tour.tour_schedule_5': `Puoi anche fare doppio clic su un giorno per aggiungere un turno
        (Assicurati di avere almeno un turno nella pianificazione prima di passare alla fase successiva)`,
    'TakeTour': `Fai un tour del prodotto`,
    'tour.tour_report_2': `Seleziona un utente, quindi fai clic su Avanti. Se l'orario utente è 0: 00h, devi pubblicare la tua pianificazione. Tutti i dati qui provengono dal programma pubblicato.`,
    'Mo': `Lu`,
    'Fr': `Ve `,
    'tour.tour_staff_2': `Fare clic qui per aggiungere più membri contemporaneamente`,
    'login.demo': `Demo`,
    'NewVersion': `È disponibile una nuova versione, vuoi aggiornarla adesso?`,
    'Th': `Gio`,
    'Askforthisshift': `Richiedi questo turno`,
    'tour.tour_schedule_8': `Se vuoi rimuovere il programma pubblicato puoi farlo cliccando qui.`,
    'tour.tour_requests_3': `Il nuovo turno che il membro del personale vuole cambiare ...`,
    'tour.tour_schedule_3': `Oppure utilizza il selettore di questa settimana per andare a una settimana specifica.`,
    'tour.tour_today_2': `Fare clic qui se si hanno turni sovrapposti.`,
    'tour.tour_requests_empty': `Assicurati di avere almeno una richiesta prima di iniziare il tour.
        
        Ad esempio, fai clic su un turno nella pianificazione, poi fai clic con il pulsante destro del mouse su "Crea richiesta di turno"
        `,
    'ThereIsNoPubSchedule': `Nessun programma pubblicato questo mese.`,
    'tour.tour_staff_4': `Per visualizzare o modificare un membro dello staff, fare clic qui`,
    'PasteShift': `Incolla turno`,
    'TaskRestarted': `L'attività viene riavviata e spostata nella scheda Attiva.`,
    'NewShift': `Nuovo turno`,
    'TaskIsRemoved': `L'attività è stata rimossa.`,
    'DeleteShift': `Elimina turno`,
    'shiftIsCloned': `Turno clonato`,
    'TaskIsPublished': `L'attività è pubblicata.`,
    'tour.tour_schedule_2': `Settimana corrente, utilizzare le frecce per passare alla settimana successiva / precedente.`,
    'schedual.m.Tour': `Aiuto`,
    'MovetToArchive': `L'attività viene spostata nello Archivio.`,
    'tour.tour_schedule_4': `Fare clic qui per aggiungere un utente o un turno
        (Se non hai utenti nella tua pianificazione, aggiungine uno ora)`,
    'tour.tour_today_1': `Ecco come appare il giorno corrente.
        I dati provengono dal programma pubblicato.`,
    'PrivateNotes': `Note private`,
    'minutes': `minuti`,
    'Available': `Disponibile`,
    'DemoAccount': `Account demo, fare clic qui per disconnettersi e avviare il proprio account.`,
    'AddASchedule': `Aggiungi una pianificazione per iniziare.`,
    'AreYouSureDeleteTask': `Sei sicuro di voler eliminare questa attività`,
    'AddStaff': `Aggiungi personale`,
    'tour.tour_schedule_7': `Quando sei soddisfatto del tuo programma e vuoi condividerlo con il tuo staff. Clicca qui per pubblicarlo.`,
    'tour.tour_requests_1': `Questa è la posta in arrivo e la posta in uscita per le tue richieste.`,
    'Vacation': `Ferie`,
    'Sa': `Sa`,
    'RequestIsSent': `Richiesta inviata`,
    'DataComesFromPubSche': `(I dati visualizzati qui provengono dai programmi pubblicati)`,
    'MovetToActive': `L'attività viene spostata in Attiva.`,
    'addMoreBeforPrint': `Si prega di aggiungere altri crediti prima di stampare`,
    'StartByAddSchedule': `Inizia aggiungendo il nome del tuo programma principale.`,
    'We': `Me `,
    'tour.tour_staff_5': `Fare clic qui per invitare l'utente tramite mail o SMS.`,
    'Tu': `Ma`,
    'schedual.m.Tasks': `Compiti`,
    'YouCanOnlyPrint4week': `Puoi stampare solo fino a quattro settimane `,
    'tour.tour_requests_4': `Approva la richiesta dell'utente
        Questo turno verrà aggiornato nel programma pubblicato.`,
    'tour.tour_chats_1': `Questo è il tuo elenco di chat.
        Tutto è un gruppo di chat di nessuna rimozione per tutti i membri dello staff.
        Il registro è un elenco di registro delle tue richieste / risposte.`,
    'ScheduleIsCopied': `La pianificazione viene copiata`,
    'PleaseRestart': `Riavvia l'app per applicare le impostazioni.`,
    'tour.tour_schedule_1': `Il programma.
        Fare clic sull'ingranaggio per modificare un nome o eliminare la pianificazione.
        (Assicurati di avere almeno un utente nella pianificazione prima di continuare)`,
    'Su': `Do`,
    'TaskIsUnPublished': `L'attività non è stata pubblicata.`,
    'tour.tour_report_4': `Fare clic qui per esportare un report xlsx.
        Se desideri scaricare tutti i rapporti in un foglio, utilizza il menu in alto.
        File -> Esporta -> Esporta come xlsx.`,
    'CanNotWork': `Non funziona`,
    'login.err.2': `La password non è valida.`,
    'createNewAccount.info': `Usa il numero di telefono se sei stato invitato tramite SMS o e-mail se hai ricevuto l'invito per posta`,
    'createNewAccount.smscodeTitle': `Codice dal messaggio di testo`,
    'login.err.1': `Nessun utente corrisponde a questo indirizzo email. L'utente potrebbe essere stato eliminato.`,
    'login.err.4': `L'account è stato disabilitato da un amministratore.`,
    'createNewAccount.newaccount': `Nuovo account del personale`,
    'login.btnLogin': `Accesso`,
    'login.err.3': `L'indirizzo e-mail è formattato male.`,
    'login.err.6': `Questo non è un numero di telefono valido È necessario iniziare con il prefisso internazionale.`,
    'createNewAccount.newSchedule': `Nuovo programma`,
    'login.err.5': `La password deve contenere almeno 6 caratteri.`,
    'createNewAccount.teleOrEmail': `Numero di telefono o e-mail`,
    'createNewAccount.setPassword': `Imposta nuova password`,
    'createNewAccount.smscode': `Codice dal SMS`,
    'createNewAccount.login': `Accesso`,
    'or': `o`,
    'set.settings.btn.dev': `Variazioni`,
    'createNewAccount.dontHavePassword': `Non hai una password?`,
    'set.settings.time': `Orario`,
    'set.settings.timeWarning': `Quando vengono modificati, è necessario salvare nuovamente i dati della pianificazione per rinnovare le vecchie etichette dell'ora memorizzata nella cache`,
    'set.settings.btn.com': `Commenti`,
    'set.settings.publickey': `Codice dell'azienda`,
    'set.settings.auto': `Approvazione automatica delle modifiche al turno dell'utente`,
    'set.settings.btn.sMov': `Spostare turni`,
    'set.settings.publickeytext': `Questo è il codice di cui hanno bisogno i membri del tuo staff quando creano un nuovo account`,
    'set.settings.btn.sCan': `Rimuovere turni`,
    'createNewAccount.comName': `Nome dell'azienda`,
    'SCHEDULES': `ORARI`,
    'on': `Attivo`,
    'createNewAccount.weekstart': `La settimana inizia con`,
    'createNewAccount.secretKey': `Codice dell'azienda`,
    'import': `Importare`,
    'signout': `Disconnessione`,
    'createNewAccount.comDisplayName': `Nome visualizzato della società`,
    'createNewAccount.adminAccount': `Account amministratore`,
    'January': `gennaio`,
    'June': `giugno`,
    'March': `marzo`,
    'time': `Tempo`,
    'uploading_image': `Caricamento immagine ...`,
    'December': `dicembre`,
    'shifts': `turni`,
    'inactive': `Inattivo`,
    'week': `Settimana`,
    'February': `febbraio`,
    'schedule': `Programma`,
    'phonenumber': `Numero di telefono`,
    'h': `h`,
    'message.out': `Out`,
    'oops': `Ops!`,
    'update': `Aggiorna `,
    'save': `Salva`,
    'total': `Totale`,
    'Apr': `Apr`,
    'users.infoText': `Questi sono usati per connettere il tuo staff a un utente nella pianificazione, devi solo impostarne uno.`,
    'schedual.m.addNewUser': `Aggiungi un nuovo membro dello staff`,
    'schedual.lbl-unassigned': `Non assegnato`,
    'logginin': `Accedendo…`,
    'back': `indietro`,
    'upload': `Caricare`,
    'August': `agosto`,
    'tooltip.AddANewChat': `Aggiungi una nuova chat`,
    'active': `Attivo`,
    'saturday': `Sabato`,
    'average': `Media`,
    'September': `settembre`,
    'break': `Fine turno`,
    'billing.nextInvoice': `Prossima fattura`,
    'billing.msgBody': `Aggiungi altri crediti per continuare a utilizzare il sistema`,
    'set.settings.btn.sik': `Congedo per malattia`,
    'AreYouSureYouWantToQuitYes': `sì`,
    'terms': `Condizioni d'uso`,
    'setup.creatingCompany': `Creazione dell'account aziendale in corso ...`,
    'setup.notValidKey': `Questa non è una chiave aziendale valida`,
    'user.active': `Attivo`,
    'users.linked': `Collegato`,
    'users.uniquephone': `Il cellulare deve essere univoco`,
    'schedual.addS': `Aggiungi nuova pianificazione`,
    'schedual.m.addDiv': `Aggiungi separatore utente`,
    'locked.txt2': `Non preoccuparti, c'è una soluzione facile, aggiungi altri crediti e in pochi minuti sarà nuovamente abilitato`,
    'setup.comKey': `Codice dell'azienda`,
    'setup.createNewAccount': `Crea un nuovo account utente`,
    'setup.updateKey': `Codice aggiornato`,
    'setup.retry': `Riprova`,
    'createNewAccount.youneedCompanyName': `Devi avere un nome di società`,
    'createNewAccount.theKey': `Il codice che hai ricevuto dal tuo manager`,
    'users.infoText1': `Questo utente è collegato a un membro dello staff, fai clic sul lucchetto per scollegarlo.`,
    'Oct': `Ott`,
    'search': `Ricerca...`,
    'new': `Nuovo`,
    'tuesday': `martedì`,
    'date': `Data`,
    'users': `utenti`,
    'October': `ottobre`,
    'hours': `Ore`,
    'rotateimage': `Ruota l'immagine`,
    'login.lostpass': `Hai dimenticato la password?`,
    'tooltip.Deny': `Rifiuta`,
    'phone': `Telefono`,
    'add': `Inserisci`,
    'monday': `Lunedi`,
    'sick': `Malattia`,
    'name': `Nome`,
    'message.empty': `Non hai richieste.`,
    'cellphone': `Cellulare`,
    'create': `Creare`,
    'picture': `Immagine`,
    'Dec': `Dic`,
    'delete': `Elimina`,
    'message.out.empty': `Non hai richieste in sospeso.`,
    'send': `Invia  `,
    'wednesday': `mercoledì`,
    'Sep': `Set`,
    'Jul': `Lug `,
    'Jun': `Giu `,
    'login.newUser': `Nuovo account del personale`,
    'message.chat': `Chat`,
    'logginout': `Disconnessione ...`,
    'sunday': `Domenica`,
    'wShort': `Set.`,
    'Aug': `Ago`,
    'Mar': `Mar`,
    'yes': `sì`,
    'now': `Adesso`,
    'login.forEmp': `Per il dipendente`,
    'login.newSchedule': `Nuovo account di programmazione`,
    'login.titel': `Accesso`,
    'tooltip.Cancel': `Annulla`,
    'password': `Password`,
    'type': `Tipo`,
    'Feb': `Feb `,
    'off': `Disattivo`,
    'login.titleNewAccount': `Nuovo account`,
    'login.sendpass': `Invia nuova password`,
    'loading': `Caricamento in corso...`,
    'cancel': `Annulla`,
    'thursday': `giovedi`,
    'May': `Mag`,
    'November': `novembre`,
    'April': `aprile`,
    'billing.payDetails': `dettagli di pagamento`,
    'report.h_time': `Orario`,
    'request.titleChange': `Cambia l'orario`,
    'billing.msgHead2': `o il tuo account verrà bloccato automaticamente`,
    'shiftuserinput.title4': `Contrassegna come malato`,
    'billing.infoTxt2': `dopodiché il tuo accesso sarà disabilitato fino a quando non aggiungerai altri crediti`,
    'appdiv.pushmessages': `Messaggi push`,
    'wallet.txtWeb2': `anche se utilizzi solo la versione web di TimeTo.Work, devi effettuare i pagamenti tramite uno degli store. Scarica l'app da uno degli store e vedrai un pulsante Acquista altri crediti all'interno qui nell'app`,
    'request.titleComment': `Commento`,
    'set.settings.btn.openshifts': `Prenota turno`,
    'request.adminRequest': `Richiesta`,
    'userhome.youarefree': `Sei libero di fare quello che vuoi questa settimana`,
    'report.h_workedhours': `Ore lavorative`,
    'editdivider.title': `Divisione di gruppo`,
    'request.titleSwap': `Cambia turno`,
    'shiftuserinput.title5': `Richiedi turno`,
    'wallet.purchasing': ` `,
    'shiftuserinput.btn2': `Chiedi un cambiamento`,
    'report.h_date': `Data`,
    'shiftuserinput.btn3': `Aggiungi un commento`,
    'report.downloadall': `Scarica tutto`,
    'selectuser.title': `Lista degli utenti`,
    'billing.infoTxt3': `Se non vuoi continuare a utilizzare il tuo programma puoi semplicemente saltare l'aggiunta di nuovi crediti, dopo 7 giorni il tuo account verrà bloccato e dopo 360 giorni verrà eliminato`,
    'usernotmapped_txt1': `Non sei collegato a un utente nella pianificazione`,
    'schedual.adminrequest': `Amministratore`,
    'wallet.waiting_for_validation': `In attesa di convalida.`,
    'usernotmapped_title': `Importante!`,
    'report.downloadExcel': `Scarica excel`,
    'appdiv.changepass': `Cambia la password`,
    'shiftuserinput.btn6': `Regola l'ora`,
    'appmenu.user': `Utente`,
    'wallet.txtWeb1': `Al momento supportiamo solo l'acquisto di nuovi crediti tramite Google Play o App Store di iOS`,
    'billing.msgHead1': `Si prega di aggiungere altri crediti prima`,
    'appmenu.changepass': `Cambia la password`,
    'wallet.deferred': `L'acquisto è stato rinviato.`,
    'request.titleUserrequestshift': `Richiesta di turno`,
    'set.settings.btn.sSwp': `Scambia turni`,
    'wallet.success': `Successo.`,
    'wallet.failed': `Acquisto non riuscito.`,
    'schedual.btnAddUser': `Aggiungi personale`,
    'shiftuserinput.swapTitle1': `Seleziona l'utente con cui eseguire lo scambio`,
    'shiftuserinput.usercomment': `Commento dell'utente`,
    'shiftuserinput.btn7': `Assegna il turno a un altro utente`,
    'report.h_break': `Pausa`,
    'billing.infoTxt1': `Ogni 30 giorni controlleremo i crediti utilizzati rispetto ai crediti esistenti, se hai pochi crediti avrai 7 giorni per aggiungerne altri`,
    'billing.msgBodyDays': `giorni`,
    'wallet.faild': `contatta l'assistenza in caso di domande`,
    'shiftuserinput.title2': `Chiedi un cambio`,
    'report.h_sik': `Malattia`,
    'report.h_hours': `Ore`,
    'report.noscheduled': `Nessun orario programmato questo mese`,
    'schedual.btnAddShift': `Aggiungi turno`,
    'wallet.failed1': `contatta l'assistenza in caso di domande`,
    'wallet.pleasewait': `attendere prego`,
    'shiftuserinput.addCommentToUser': `Aggiungi un commento all'utente`,
    'shiftuserinput.btn5': `Annulla turno`,
    'shiftuserinput.title0': `Aggiorna turno`,
    'appmenu.pushmessages': `Notifiche push`,
    'wallet.title1': `Acquista più crediti`,
    'WeCanNotMatchYou': `Non possiamo abbinare la tua mail a un utente, chiedi al tuo manager di aggiungere la tua mail a un utente nella pianificazione`,
    'settings.title': `Preferenze`,
    'and': `e`,
    'setup.yesImAManger': `Sì, sono un manager`,
    'settings.account': `account`,
    'Help': `Aiuto`,
    'dontShowThis': `Non mostrarlo all'avvio`,
    'IncomingRequests': `Richieste in arrivo`,
    'Preferences...': `Preferenze ...`,
    'billing.msgLink': `Vai a TimeTo.Work -> Preferenze -> Fatturazione. Nel menu in alto`,
    'policy': `politica sulla privacy`,
    'joy.welcome': `Benvenuto!`,
    'joy.Last': `Ultimo`,
    'log': `Accesso`,
    'EditChat': `Modifica chat`,
    'joy.startbyaddingusers': `Inizia aggiungendo alcuni membri dello staff`,
    'Toggle menu bar icon': `Attiva / disattiva l'icona della barra dei menu`,
    'AddMoreCredits': `Aggiungi altri crediti`,
    'Paste schedule': `Incolla pianificazione`,
    'Staff': `Personale`,
    'Signout': `Disconnessione`,
    'settings.billing': `Fatturazione`,
    'Hide TimeTo.Work': `Nascondi TimeTo.Work`,
    'Quit': `Esci`,
    'Copy': `copia`,
    'joy.clickHereToAddMultipleUsers': `Qui puoi aggiungere più membri dello staff contemporaneamente`,
    'Reports as XLSX': `Rapporto in XLSX`,
    'Clear all data': `Cancella tutti i dati`,
    'Edit': `modificare`,
    'OrAreYouAManger': `Sei un manager e vuoi creare un nuovo programma per il personale?`,
    'companyNameIsNotValid': `Il nome della società non è valido`,
    'a minute ago': `Un minuto fa`,
    'About TimeTo.Work': `Informazioni su TimeTo.Work`,
    'OutgoingRequests': `Richieste in uscita`,
    'joy.clickHereToAddAShift': `Fare clic qui per aggiungere un turno`,
    'settings.settings': `impostazioni`,
    'AreYouSureYouWantToQuitTitle': `Conferma uscita`,
    'joy.Back': `Indietro`,
    'window': `TimeTo.Work`,
    'How do i ...': `Come posso ...`,
    'Divider': `separatore`,
    'byclicking': `Facendo clic su "Crea" accetti i`,
    'all': `Tutti`,
    'joy.goToSchedule': `vai alla pianificazione per avviare la pianificazione`,
    'Saved': `Salvato`,
    'an hour ago': `un'ora fa`,
    'joy.whenYourDone': `Quando hai finito di aggiungere membri dello staff,`,
    'Shift': `Cambio`,
    'TotalBreakTime': `Tempo di pausa totale`,
    'shortIntro': `Questa è una breve introduzione per apprendere come funziona TimeTo.work`,
    'a day ago': `un giorno fa`,
    'minutes ago': `minuti fa`,
    'Window': `Finestra`,
    'Clear schedule': `Libera il programma`,
    'Copy schedule': `Copia programma`,
    'New schedule': `Nuovo programma`,
    'Hide Others': `Nascondi gli altri`,
    'Cut': `Taglia`,
    'RestorePreviousPurchase': `Ripristina l'acquisto precedente`,
    'currentPrice': `1 utente per 30 giorni = 1 credito`,
    'AreYouSureYouWantToQuitNo': `No`,
    'WelcomeTo': `Benvenuto in TimeTo.Work`,
    'joy.addMultipleRows': `Qui puoi aggiungere più righe contemporaneamente`,
    'joy.Next': `Prossimo`,
    'Prev': `Precedente`,
    'Next': `Prossimo`,
    'Export': `Esportare`,
    'New staff': `Nuovo staff`,
    'AreYouSureYouWantToQuit': `Sei sicuro di voler uscire?
    
    Perderai tutti i dati non pubblicati.`,
    'zoom': `Ingrandisci`,
    'joy.dontForgetToSave': `Quando hai finito, non dimenticare di salvare e pubblicare la tua pianificazione`,
    'users.invalidphone2': `È necessario aggiungere il prefisso internazionale prima del numero`,
    'users.notemail': `Questa non è una email valida`,
    'users.staffmembers': `Membri dello staff`,
    'schedual.addSName': `Nome pianificazione`,
    'schedual.editS': `Modifica pianificazioni`,
    'schedual.m.tools': `Strumenti`,
    'schedual.m.Reports': `Rapporti`,
    'schedual.m.Request': `Richiesta`,
    'schedual.m.Today': `Oggi`,
    'schedual.emptyTxt1': `Utilizza il clic destro per aggiungere personale o turni.`,
    'schedual.emptyTxt2': ` `,
    'createNewAccount.ampmOr24h': `Formato orario`,
    'createNewAccount.termsApprove': `Devi approvare i termini e le condizioni`,
    'createNewAccount.terms': `Accetto i Termini e Condizioni`,
    'createNewAccount.useSameEmail': `Usa la stessa email che hai ricevuto nell'invito. Così possiamo associarti ad un utente nella pianificazione`,
    'set.billing': `Fatturazione`,
    'user.allLinked': `Tutti gli utenti sono collegati`,
    'user.unlockEmail': `Sblocca l'email`,
    'users.uniqueemail': `L'email deve essere univoca`,
    'schedual.m.pasteS': `Incolla pianificazione`,
    'schedual.m.addNewSchedule': `Aggiungi nuova pianificazione`,
    'schedual.m.Staff': `Personale`,
    'schedual.m.Schedule': `Programma`,
    'schedual.last': `Ultimo aggiornamento`,
    'locked.txt1': `Il tuo account è temporaneamente bloccato per mancanza di crediti.`,
    'locked.title': `Il tuo account è bloccato!`,
    'setup.needSetup': `Abbiamo creato il tuo account con successo ma dobbiamo collegarlo a un'azienda`,
    'createNewAccount.youneedkey': `Devi avere un codice aziendale`,
    'createNewAccount.emailAndPass': `Email e password`,
    'set.settings': `impostazioni`,
    'user.bulkTitle': `Inserisci un elenco separato da virgole con gli utenti`,
    'users.delete': `Se elimini l'utente, verrà eliminato definitivamente!
        E tutti i dati storici saranno contrassegnati con "Utente sconosciuto"
    A volte potrebbe essere meglio contrassegnare l'utente come inattivo.`,
    'users.dname': `Nome da visualizzare`,
    'users.newuser': `Nuovo`,
    'schedual.deleteBtn2': `Conferma l'eliminazione definitiva`,
    'schedual.deleteWarning': `Se elimini la pianificazione, verrà eliminata definitivamente e con lei tutti i dati storici in essa contenuti`,
    'schedual.deleteBtn1': `Elimina pianificazione`,
    'schedual.m.addOpenShifts': `Aggiungi "turni aperti"`,
    'schedual.m.print': `Stampa`,
    'schedual.m.Chat': `Chat`,
    'schedual.openshifts': `Turni aperti`,
    'locked.link': `Clicca qui per ottenere più crediti`,
    'schedual.dropdelete': `Rilascia il turno / utente qui per rimuoverlo!`,
    'setup.mappingUser': `Abbinamento dell'utente a una pianificazione ...`,
    'user.inviteUnMaped': `Invita gli utenti non collegati`,
    'set.settings.title': `Impostazioni di sistema`,
    'user.bulkTxt': `Un utente per riga. Separare nome e numero / e-mail con una virgola`,
    'users.deleteBtn1': `Elimina utente`,
    'users.uniqueemail2': `Questa email non può essere utilizzata`,
    'schedual.m.undo': `Annulla tutte le modifiche`,
    'schedual.m.copyS': `Copia programma`,
    'schedual.m.Home': `Home`,
    'schedual.emptyTitle': `La tua programmazione è vuota!`,
    'setup.wrongKey': `Il codice dell'azienda è mancante o sbagliata`,
    'user.bulkimport': `Importazione in blocco`,
    'users.deleteBtn2': `Conferma l'eliminazione definitiva`,
    'schedual.m.clear': `Cancella tutti i turni`,
    'users.invalidphone': `Questo non è un numero di cellulare valido`,
    'user.bulkTxt2': `Visualizza nome, e-mail o telefono
    John Doe, +1123456789 o Jane Doe, jane@timeto.work`,
    'schedual.lbl-openshifts': `Turni aperti`,
    'schedual.addSFirstTitle': `Aggiungi nuova pianificazione`,
    'login.sentResetMail': `Abbiamo inviato un link di ripristino alla tua email.`,
    'message.in': `In`,
    'email': `E-mail`,
    'login.forCom': `Per l'azienda`,
    'message.in.empty': `Non hai richieste.`,
    'credit': `Credito`,
    'Jan': `Gem`,
    'invites': `Invitare`,
    'July': `luglio`,
    'Nov': `Nov`,
    'validphone': `+ prefisso internazionale e numero di cellulare`,
    'friday': `Venerdì`,
    'tooltip.Approve': `Approvare`,
    'no': `No`,
    'calendar.empty': `Non ci sono programmi per questa settimana`,
    'schedual.btnHelp': `Aiuto`,
    'schedual.btnAddDiv': `Aggiungi il separatore
    `,
    'schedual.btnPublish': `Salva e pubblica`,
    'wallet.restored': `L'acquisto di è stato restaurato.`,
    'wallet.success1': `i crediti verranno aggiunti al tuo account a breve`,
    'request.titleSick': `Malattia`,
    'request.titleTime': `Variazione temporale`,
    'request.titleMove': `Spostare il turno`,
    'request.titleCancle': `Annulla turno`,
    'shiftuserinput.cancelation': `Causa di annullamento`,
    'shiftuserinput.swapTitle3': `Seleziona Shift`,
    'shiftuserinput.swapTitle2': `Seleziona la settimana`,
    'shiftuserinput.comment': `Commento`,
    'shiftuserinput.btn8': `Scambia il turno con un altro utente`,
    'shiftuserinput.btn4': `Contrassegna come malato`,
    'shiftuserinput.btn1': `Segnala la variazione dell'orario`,
    'shiftuserinput.title3': `Aggiungi un commento`,
    'shiftuserinput.title1': `Tempo di lavoro effettivo`,
    'joy.Close': `Chiudi`,
    'front': `Porta tutto in primo piano`,
    'minimize': `Rimpicciolisci `,
    'Quick introduction': `Introduzione rapida`,
    'Schedule as PDF': `Calendario in PDF`,
    'New chat': `Nuova chat`,
    'Select All': `Seleziona tutto`,
    'services': `Servizi`,
    'years ago': `anni fa`,
    'months ago': `mesi fa`,
    'days ago': `giorni fa`,
    'hours ago': `ore fa`,
    'a few seconds ago': `qualche secondo fa`,
    'joy.clickonstaff': `Fare clic su "Staff"`,
    'a year ago': `un anno fa`,
    'a month ago': `un mese fa`,
    'seconds ago': `secondi fa`,
    'joy.orClickOnTheSchedule': `Oppure puoi fare doppio clic sulla pianificazione`,
    'joy.clickHerToAddUsers': `Fare clic qui per aggiungere uno o due membri dello staff`,
    'joy.Skip': `Salta il tutorial`,
    'Undo all changes': `Annulla tutte le modifiche`,
    'Add to schedule': `Aggiungi alla pianificazione`,
    'File': `File`,
    'selectuser.warning': `Seleziona almeno un utente da aggiungere alla pianificazione`,
    'usernotmapped_txt2': `Chiedi al tuo amministratore di aggiungere il tuo indirizzo email`,
    'report.h_schedal': `Programma`,
    'report.h_presence': `Presenza`,
    'billing.bHistory': `Cronologia di fatturazione`,
    'billing.msgHead': `Il tuo account non ha abbastanza crediti.`,
    'set.settings.btn.sCha': `Cambia l'orario`,
    'Show All': `Mostra tutto`,
    'Paste': `Incolla`,
    'userCredits': `crediti utente`,
    'ShiftLabel': `Etichetta del turno`,

}