module.exports = {

    'newTaskInfo': `Als je een snelle rondleiding nodig hebt, klik dan op het tabblad 'Help' in het menu linksonder.
    Klik dan op 'Rondleiding' en keer terug naar deze pagina.`,
    'Publish': `Publiceren`,
    'schedual.btnPasteSchedule': `Plak het schema`,
    'schedual.btnCopySchedule': `Kopieer schema`,
    'NewFeatuer': `Dit is een nieuwe functie.`,
    'LinkCopied': `link gekopieerd`,
    'GoToWorkView': `Dit is het gepubliceerde schema
    Ga naar de werkweergave om wijzigingen aan te brengen`,
    'QuickIntro': `Een korte introductie nodig over hoe deze app werkt?`,
    'Workview': `Werkweergave`,
    'AddRow': `Rij toevoegen`,
    'Published': `Gepubliceerd`,
    'thisIsWatStaffSee': `Dit is wat uw personeel ziet`,
    'Unpublish': `Publicatie ongedaan maken`,
    'TaskStartInfo': `Klik op een taak of maak een nieuwe aan door op de plusknop in de rechterbovenhoek te klikken`,
    'ClickHereToStart': `Klik hier om te starten`,
    'joy.viewWeeks': `Aantal weken om te laten zien`,
    'tour.onboardStep_mainMenu': `Begin door op het tabblad te klikken waar u een rondleiding door wilt.`,
    'comefirmUnlinkUser': `Wilt u de gebruiker ontkoppelen?`,
    'draft': `Ontwerp, niet gepubliceerd`,
    'Unpublishing': `Publicatie ongedaan maken ...`,
    'AreYouSurePublish': `Weet u zeker dat u het rooster voor alle personeelsleden wilt publiceren?`,
    'tour.tour_report_1': `Deze maand. Gebruik de pijlen om de maand te wijzigen.`,
    'tour.tour_chats_3': `Selecteer een chatroom en schrijf hier uw bericht.`,
    'AdminCanAddScheduls': `Alleen de beheerder kan nieuwe schema's toevoegen`,
    'PrintNoAbuseMsg': `Alleen een betalende klant kan de printfunctie gebruiken
    (Dit is om misbruik van het systeem te voorkomen.)`,
    'tour.tour_schedule_9': `Dit is uw werkweergave, de medewerkers kunnen deze versie van het rooster niet zien.`,
    'CopyShift': `Kopieer ploeg`,
    'tour.tour_requests_2': `De oorspronkelijke dienst van uw gepubliceerde schema.`,
    'ClearSchedule': `Wilt u dit schema wissen?`,
    'tour.tour_schedule_6': `Klik op een verschuiving om deze te bewerken of sleep om deze te verplaatsen.
    Als u de optie ingedrukt houdt tijdens het slepen, wordt de verschuiving gekloond.`,
    'tour.tour_staff_3': `Klik hier om uitnodigingsmail naar alle ongenode gebruikers tegelijk te sturen.`,
    'PleaseAddCreditsBeforePrinting': `Voeg credits toe voordat u gaat afdrukken`,
    'tour.tour_staff_1': `Klik hier om een nieuw personeelslid toe te voegen`,
    'AddDivider': `Voeg een scheidingslijn toe`,
    'WantToWork': `Willen werken`,
    'tour.tour_requests_5': `De wijzigingen zijn geweigerd en er zijn geen wijzigingen aangebracht.`,
    'tour.tour_report_3': `Dit is de urenstaat
    Hier kunt u de gewerkte uren zien.
    Alle gegevens zijn afkomstig uit het gepubliceerde schema.
    zweef een rij en klik op de pen om de dienst te bewerken.`,
    'CreateShiftRequest': `Maak een dienstverzoek aan`,
    'tour.tour_chats_2': `Als beheerder kunt u nieuwe chatrooms toevoegen.`,
    'tour.tour_schedule_10': `Dit is wat uw gebruikers willen zien in de mobiele app.
    Dit zijn ook de gegevens in rapporten.`,
    'tour.tour_schedule_6_1': `Klik op de gebruiker om een rij te kopiëren of om de gebruiker te bekijken.`,
    'tour.tour_schedule_5': `U kunt ook dubbelklikken op een dag om een dienst toe te voegen
    (Zorg ervoor dat u ten minste één dienst in het schema heeft voordat u naar de volgende stap gaat)`,
    'TakeTour': `Volg een productrondleiding`,
    'tour.tour_report_2': `Selecteer een gebruiker en klik op Volgende.
    Als de gebruikersuren 0:00 uur zijn, moet u uw rooster publiceren.
    Alle gegevens hier zijn van het gepubliceerde schema.`,
    'Mo': `Ma`,
    'Fr': `Vr`,
    'tour.tour_staff_2': `Klik hier om meerdere leden tegelijk toe te voegen`,
    'login.demo': `Demo`,
    'NewVersion': `Er is een nieuwe versie beschikbaar, wil je nu updaten?`,
    'Th': `Th`,
    'Askforthisshift': `Vraag om deze verschuiving`,
    'tour.tour_schedule_8': `Als u het gepubliceerde schema wilt verwijderen, kunt u dit doen door hier te klikken.`,
    'tour.tour_requests_3': `De nieuwe ploeg waar de medewerker naar wil veranderen ...`,
    'tour.tour_schedule_3': `Of gebruik deze weekkiezer om naar een specifieke week te gaan.`,
    'tour.tour_today_2': `Klik hier als u overlappende diensten heeft.`,
    'tour.tour_requests_empty': `Zorg ervoor dat u ten minste één verzoek heeft voordat u aan de tour deelneemt.
    
    Klik bijvoorbeeld op een ploeg in het rooster en klik met de rechtermuisknop op "Aanmaken ploegendienst"
    `,
    'ThereIsNoPubSchedule': `Er is deze maand geen gepubliceerd programma.`,
    'tour.tour_staff_4': `Klik hier om een personeelslid te bekijken of te bewerken`,
    'PasteShift': `Verschuiving plakken`,
    'TaskRestarted': `De taak wordt opnieuw gestart en verplaatst naar het tabblad Actief.`,
    'NewShift': `Nieuwe ploeg`,
    'TaskIsRemoved': `Taak is verwijderd.`,
    'DeleteShift': `Shift verwijderen`,
    'shiftIsCloned': `Shift is gekloond`,
    'TaskIsPublished': `Taak is gepubliceerd.`,
    'tour.tour_schedule_2': `Huidige week, gebruik de pijlen om naar de volgende / vorige week te gaan.`,
    'schedual.m.Tour': `Helpen`,
    'MovetToArchive': `Taak is verplaatst naar Archief.`,
    'tour.tour_schedule_4': `Klik hier om een gebruiker of een ploeg toe te voegen
    (Als u geen gebruikers in uw planning heeft, voeg er dan nu een toe)`,
    'tour.tour_today_1': `Zo ziet de huidige dag eruit.
    De gegevens zijn afkomstig uit het gepubliceerde schema.`,
    'PrivateNotes': `Privé-notities`,
    'minutes': `minuten`,
    'Available': `Beschikbaar`,
    'DemoAccount': `Demo-account, klik hier om uit te loggen en uw eigen account te starten.`,
    'AddASchedule': `Voeg een schema toe om aan de slag te gaan.`,
    'AreYouSureDeleteTask': `Weet u zeker dat u deze taak wilt verwijderen?`,
    'AddStaff': `Voeg personeel toe`,
    'tour.tour_schedule_7': `Als u tevreden bent met uw planning en deze wilt delen met uw personeel.
    Klik hier om te publiceren.`,
    'tour.tour_requests_1': `Dit zijn inbox en outbox voor uw verzoeken.`,
    'Vacation': `Vakantie`,
    'Sa': `Naar`,
    'RequestIsSent': `Verzoek verzonden`,
    'DataComesFromPubSche': `(De gegevens die hier worden weergegeven, zijn afkomstig uit de gepubliceerde schema's)`,
    'MovetToActive': `Taak is verplaatst naar Actief.`,
    'addMoreBeforPrint': `Voeg meer credits toe voordat u gaat afdrukken`,
    'StartByAddSchedule': `Begin met het toevoegen van de naam van uw hoofdschema.`,
    'We': `Wij`,
    'tour.tour_staff_5': `Klik hier om de gebruiker per mail of sms uit te nodigen.`,
    'Tu': `uw`,
    'schedual.m.Tasks': `Taken`,
    'YouCanOnlyPrint4week': `U kunt slechts vier weken vooruit afdrukken`,
    'tour.tour_requests_4': `Keur het gebruikersverzoek goed
    Deze dienst wordt bijgewerkt in uw gepubliceerde schema.`,
    'tour.tour_chats_1': `Dit is je chatlijst.
    Alles is een chatgroep zonder verwijdering voor alle personeelsleden.
    Log is een logboek met uw verzoeken / antwoorden.`,
    'ScheduleIsCopied': `Het schema wordt gekopieerd`,
    'PleaseRestart': `Start uw app opnieuw om de instellingen toe te passen.`,
    'tour.tour_schedule_1': `Het rooster.
    Klik op het tandwiel om een naam te wijzigen of het schema te verwijderen.
    (Zorg ervoor dat u ten minste één planningsgebruiker in het schema heeft voordat u doorgaat)`,
    'Su': `zijn`,
    'TaskIsUnPublished': `Taak is niet gepubliceerd.`,
    'tour.tour_report_4': `Klik hier om een xlsx-rapport te exporteren.
    Als u alle rapporten in één blad wilt downloaden, gebruikt u het hoofdmenu.
    Bestand -> Exporteren -> Exporteren als xlsx.`,
    'CanNotWork': `Kan niet werken`,
    'login.err.2': `Het wachtwoord is ongeldig.`,
    'createNewAccount.info': `Gebruik telefoonnummer als je bent uitgenodigd via sms of e-mail als je een uitnodiging per e-mail hebt ontvangen`,
    'createNewAccount.smscodeTitle': `Code uit het sms-bericht`,
    'login.err.1': `Er is geen gebruiker die overeenkomt met dit e-mailadres. De gebruiker is mogelijk verwijderd.`,
    'login.err.4': `Het account is uitgeschakeld door een beheerder.`,
    'createNewAccount.newaccount': `Nieuw personeelsaccount`,
    'login.btnLogin': `Log in`,
    'login.err.3': `Het e-mailadres is onjuist opgemaakt.`,
    'login.err.6': `Dit is geen geldig telefoonnummer. U moet beginnen met uw landcode.`,
    'createNewAccount.newSchedule': `Nieuw schema`,
    'login.err.5': `Het wachtwoord moet 6 tekens of meer bevatten.`,
    'createNewAccount.teleOrEmail': `Telefoonnummer of e-mail`,
    'createNewAccount.setPassword': `Stel een nieuw wachtwoord in`,
    'createNewAccount.smscode': `Code uit het sms-bericht`,
    'createNewAccount.login': `Log in`,
    'or': `of`,
    'set.settings.btn.dev': `Afwijkingen`,
    'createNewAccount.dontHavePassword': `Heeft u geen paswoord?`,
    'set.settings.time': `Tijd`,
    'set.settings.timeWarning': `Bij wijziging moet u uw planningsgegevens opnieuw opslaan om oude tijdlabels in de cache te vernieuwen`,
    'set.settings.btn.com': `Opmerkingen`,
    'set.settings.publickey': `Bedrijfssleutel`,
    'set.settings.auto': `Automatische goedkeuring van ploegwisselingen van gebruikers`,
    'set.settings.btn.sMov': `Verplaats ploegen`,
    'set.settings.publickeytext': `Dit is de sleutel die uw medewerkers nodig hebben bij het aanmaken van een nieuw account`,
    'set.settings.btn.sCan': `Schakel ploegen uit`,
    'createNewAccount.comName': `Bedrijfsnaam`,
    'SCHEDULES': `SCHEMA'S`,
    'on': `Aan`,
    'createNewAccount.weekstart': `Week begint met`,
    'createNewAccount.secretKey': `Bedrijfssleutel`,
    'import': `Importeren`,
    'signout': `Afmelden`,
    'createNewAccount.comDisplayName': `Weergavenaam van het bedrijf`,
    'createNewAccount.adminAccount': `Beheerdersaccount`,
    'January': `januari-`,
    'June': `juni-`,
    'March': `maart`,
    'time': `Tijd`,
    'uploading_image': `Afbeelding uploaden ...`,
    'December': `december`,
    'shifts': `verschuivingen`,
    'inactive': `Inactief`,
    'week': `Week`,
    'February': `februari`,
    'schedule': `Schema`,
    'phonenumber': `Telefoonnummer`,
    'h': `h`,
    'message.out': `Uit`,
    'oops': `Oeps!`,
    'update': `Bijwerken`,
    'save': `Opslaan`,
    'total': `Totaal`,
    'Apr': `Apr`,
    'users.infoText': `Deze worden gebruikt om uw personeel te verbinden met een gebruiker in het rooster, u hoeft er maar één in te stellen.`,
    'schedual.m.addNewUser': `Nieuwe medewerker toevoegen`,
    'schedual.lbl-unassigned': `Niet toegewezen`,
    'logginin': `Inloggen...`,
    'back': `terug`,
    'upload': `Uploaden`,
    'August': `augustus`,
    'tooltip.AddANewChat': `Voeg een nieuwe chat toe`,
    'active': `Actief`,
    'saturday': `zaterdag`,
    'average': `Gemiddelde`,
    'September': `september`,
    'break': `Breken`,
    'billing.nextInvoice': `Volgende factuur`,
    'billing.msgBody': `Voeg meer credits toe om het systeem te blijven gebruiken`,
    'set.settings.btn.sik': `Ziekteverlof`,
    'AreYouSureYouWantToQuitYes': `Ja`,
    'terms': `Gebruiksvoorwaarden`,
    'setup.creatingCompany': `Zakelijk account aanmaken ...`,
    'setup.notValidKey': `Dit is geen geldige bedrijfssleutel`,
    'user.active': `Actief`,
    'users.linked': `Gekoppeld`,
    'users.uniquephone': `Telefoon moet uniek zijn`,
    'schedual.addS': `Voeg een nieuw schema toe`,
    'schedual.m.addDiv': `Voeg gebruikersverdeler toe`,
    'locked.txt2': `Maak je geen zorgen, het is een gemakkelijke oplossing, voeg meer credits toe en binnen een paar minuten wordt het weer ingeschakeld`,
    'setup.comKey': `Bedrijfssleutel`,
    'setup.createNewAccount': `Maak een nieuw gebruikersaccount aan`,
    'setup.updateKey': `Update sleutel`,
    'setup.retry': `Probeer het opnieuw`,
    'createNewAccount.youneedCompanyName': `U moet een bedrijfsnaam hebben`,
    'createNewAccount.theKey': `De sleutel die u van uw manager heeft gekregen`,
    'users.infoText1': `Deze gebruiker is gekoppeld aan een personeelslid, klik op het slotje om deze te ontkoppelen.`,
    'Oct': `Okt`,
    'search': `Zoeken...`,
    'new': `Nieuw`,
    'tuesday': `dinsdag`,
    'date': `Datum`,
    'users': `gebruikers`,
    'October': `oktober`,
    'hours': `Uren`,
    'rotateimage': `Draai de afbeelding`,
    'login.lostpass': `uw wachtwoord vergeten?`,
    'tooltip.Deny': `Ontkennen`,
    'phone': `Telefoon`,
    'add': `Toevoegen`,
    'monday': `maandag`,
    'sick': `Ziek`,
    'name': `Naam`,
    'message.empty': `U heeft geen verzoeken.`,
    'cellphone': `Mobiele telefoon`,
    'create': `Creëer`,
    'picture': `Afbeelding`,
    'Dec': `Dec`,
    'delete': `Verwijderen`,
    'message.out.empty': `U heeft geen lopende verzoeken.`,
    'send': `Sturen`,
    'wednesday': `woensdag`,
    'Sep': `Sep`,
    'Jul': `Jul`,
    'Jun': `Jun`,
    'login.newUser': `Nieuw personeelsaccount`,
    'message.chat': `Chat`,
    'logginout': `Uitloggen...`,
    'sunday': `zondag`,
    'wShort': `in.`,
    'Aug': `Aug`,
    'Mar': `Mrt`,
    'yes': `Ja`,
    'now': `Nu`,
    'login.forEmp': `Voor de werknemer`,
    'login.newSchedule': `Nieuw roosteraccount`,
    'login.titel': `Log in`,
    'tooltip.Cancel': `annuleren`,
    'password': `Wachtwoord`,
    'type': `Type`,
    'Feb': `Feb`,
    'off': `Uit`,
    'login.titleNewAccount': `Nieuw account`,
    'login.sendpass': `Stuur een nieuw wachtwoord`,
    'loading': `Bezig met laden...`,
    'cancel': `annuleren`,
    'thursday': `donderdag`,
    'May': `Mei`,
    'November': `November`,
    'April': `april`,
    'billing.payDetails': `Betalingsdetails`,
    'report.h_time': `Tijd`,
    'request.titleChange': `Verander tijd`,
    'billing.msgHead2': `of uw account wordt automatisch vergrendeld`,
    'shiftuserinput.title4': `Markeer als ziek`,
    'billing.infoTxt2': `daarna wordt uw toegang uitgeschakeld totdat u meer krediet toevoegt`,
    'appdiv.pushmessages': `Push-berichten`,
    'wallet.txtWeb2': `zelfs als u TimeTo.Work alleen op het web gebruikt, moet u de betalingen doen via een van de winkels. Download de app uit een van de winkels en je ziet hier een knop Meer credits kopen in de app`,
    'request.titleComment': `Commentaar`,
    'set.settings.btn.openshifts': `Boek shift`,
    'request.adminRequest': `Verzoek`,
    'userhome.youarefree': `Je bent deze week vrij om te doen wat je wilt`,
    'report.h_workedhours': `Gewerkte uren`,
    'editdivider.title': `Groepsscheidingslijn`,
    'request.titleSwap': `Wissel van dienst`,
    'shiftuserinput.title5': `Verzoek om ploegendienst`,
    'wallet.purchasing': ` `,
    'shiftuserinput.btn2': `Vraag om verandering`,
    'report.h_date': `Datum`,
    'shiftuserinput.btn3': `Voeg een reactie toe`,
    'report.downloadall': `Download alles`,
    'selectuser.title': `Gebruikers lijst`,
    'billing.infoTxt3': `Als u uw schema niet wilt blijven gebruiken, kunt u het toevoegen van nieuwe credits overslaan, na 7 dagen wordt uw account vergrendeld en na 360 dagen wordt het verwijderd`,
    'usernotmapped_txt1': `U bent niet toegewezen aan een gebruiker in het schema`,
    'schedual.adminrequest': `Beheerder`,
    'wallet.waiting_for_validation': `Wachten op validatie.`,
    'usernotmapped_title': `Belangrijk!`,
    'report.downloadExcel': `Download Excel`,
    'appdiv.changepass': `Wijzig wachtwoord`,
    'shiftuserinput.btn6': `Pas de tijd aan`,
    'appmenu.user': `Gebruiker`,
    'wallet.txtWeb1': `Op dit moment ondersteunen we alleen het kopen van nieuw tegoed via Google Play of iOS App Store`,
    'billing.msgHead1': `Voeg alstublieft eerder krediet toe`,
    'appmenu.changepass': `Wijzig wachtwoord`,
    'wallet.deferred': `De aankoop van is uitgesteld.`,
    'request.titleUserrequestshift': `Shift-verzoek`,
    'set.settings.btn.sSwp': `Wissel ploegen`,
    'wallet.success': `Succes.`,
    'wallet.failed': `Aankoop mislukt.`,
    'schedual.btnAddUser': `Voeg personeel toe`,
    'shiftuserinput.swapTitle1': `Selecteer een gebruiker om mee te wisselen`,
    'shiftuserinput.usercomment': `Gebruiker commentaar`,
    'shiftuserinput.btn7': `Wijs ploeg toe aan een andere gebruiker`,
    'report.h_break': `Breken`,
    'billing.infoTxt1': `Elke 30 dagen zullen we de gebruikte credits vergelijken met je bestaande credits, als je weinig tegoed hebt, heb je 7 dagen om meer credits toe te voegen`,
    'billing.msgBodyDays': `dagen`,
    'wallet.faild': `neem bij vragen contact op met de ondersteuning`,
    'shiftuserinput.title2': `Vraag om verandering`,
    'report.h_sik': `Ziek`,
    'report.h_hours': `Uren`,
    'report.noscheduled': `Geen geplande uren deze maand`,
    'schedual.btnAddShift': `Voeg ploeg toe`,
    'wallet.failed1': `neem bij vragen contact op met de ondersteuning`,
    'wallet.pleasewait': `even geduld aub`,
    'shiftuserinput.addCommentToUser': `Voeg commentaar toe aan de gebruiker`,
    'shiftuserinput.btn5': `Dienst annuleren`,
    'shiftuserinput.title0': `Update ploeg`,
    'appmenu.pushmessages': `Push-notificaties`,
    'wallet.title1': `Koop meer krediet`,
    'WeCanNotMatchYou': `We kunnen uw mail niet koppelen aan een gebruiker. Vraag uw manager om uw mail aan een gebruiker toe te voegen in het schema`,
    'settings.title': `Voorkeuren`,
    'and': `en`,
    'setup.yesImAManger': `Ja, ik ben manager`,
    'settings.account': `Account`,
    'Help': `Helpen`,
    'dontShowThis': `Laat dit niet zien bij het opstarten`,
    'IncomingRequests': `Inkomende verzoeken`,
    'Preferences...': `Voorkeuren ...`,
    'billing.msgLink': `Ga naar TimeTo.Work -> Voorkeuren -> Facturering. In het bovenste menu`,
    'policy': `Privacybeleid`,
    'joy.welcome': `Welkom!`,
    'joy.Last': `Laatste`,
    'log': `Logboek`,
    'EditChat': `Chat bewerken`,
    'joy.startbyaddingusers': `Begin met het toevoegen van enkele personeelsleden`,
    'Toggle menu bar icon': `Toggle menubalk icoon`,
    'AddMoreCredits': `Voeg meer credits toe`,
    'Paste schedule': `Plak het schema`,
    'Staff': `Personeel`,
    'Signout': `Afmelden`,
    'settings.billing': `Facturering`,
    'Hide TimeTo.Work': `Verberg TimeTo.Work`,
    'Quit': `Afsluiten`,
    'Copy': `Kopiëren`,
    'joy.clickHereToAddMultipleUsers': `Hier kunt u meerdere personeelsleden tegelijk toevoegen`,
    'Reports as XLSX': `Rapporteert als XLSX`,
    'Clear all data': `Wis alle gegevens`,
    'Edit': `Bewerk`,
    'OrAreYouAManger': `Of ben je manager en wil je een nieuw personeelsrooster maken?`,
    'companyNameIsNotValid': `Bedrijfsnaam is niet geldig`,
    'a minute ago': `een minuut geleden`,
    'About TimeTo.Work': `Over TimeTo.Work`,
    'OutgoingRequests': `Uitgaande verzoeken`,
    'joy.clickHereToAddAShift': `Klik hier om een dienst toe te voegen`,
    'settings.settings': `Instellingen`,
    'AreYouSureYouWantToQuitTitle': `Bevestig stoppen`,
    'joy.Back': `Terug`,
    'window': `Tijd om te werken`,
    'How do i ...': `Hoe doe ik ...`,
    'Divider': `Verdeler`,
    'byclicking': `Door op 'Aanmaken' te klikken, gaat u akkoord met het`,
    'all': `Alle`,
    'joy.goToSchedule': `ga naar het rooster om te beginnen met plannen`,
    'Saved': `Opgeslagen`,
    'an hour ago': `een uur geleden`,
    'joy.whenYourDone': `Als u klaar bent met het toevoegen van personeel,`,
    'Shift': `Verschuiving`,
    'TotalBreakTime': `Totale pauze`,
    'shortIntro': `Dit is een korte intro om u op de hoogte te brengen van hoe TimeTo.work werkt`,
    'a day ago': `een dag geleden`,
    'minutes ago': `minuten geleden`,
    'Window': `Venster`,
    'Clear schedule': `Overzichtelijk schema`,
    'Copy schedule': `Kopieer schema`,
    'New schedule': `Nieuw schema`,
    'Hide Others': `Verberg anderen`,
    'Cut': `Besnoeiing`,
    'RestorePreviousPurchase': `Herstel vorige aankoop`,
    'currentPrice': `1 gebruiker voor 30 dagen = 1 tegoed`,
    'AreYouSureYouWantToQuitNo': `Nee`,
    'WelcomeTo': `Welkom bij TimeTo.Work`,
    'joy.addMultipleRows': `Hier kun je meerdere rijen tegelijk toevoegen`,
    'joy.Next': `De volgende`,
    'Prev': `Vorige`,
    'Next': `De volgende`,
    'Export': `Exporteren`,
    'New staff': `Nieuw personeel`,
    'AreYouSureYouWantToQuit': `Weet je zeker dat je wilt stoppen?

U verliest alle niet-gepubliceerde gegevens.`,
    'zoom': `zoom`,
    'joy.dontForgetToSave': `Als u klaar bent, vergeet dan niet uw planning op te slaan en te publiceren`,
    'users.invalidphone2': `U moet de landcode voor het nummer plaatsen`,
    'users.notemail': `Dit is geen geldig e-mailadres`,
    'users.staffmembers': `Personeel`,
    'schedual.addSName': `Schema naam`,
    'schedual.editS': `Bewerk planningen`,
    'schedual.m.tools': `Gereedschap`,
    'schedual.m.Reports': `Rapporten`,
    'schedual.m.Request': `Verzoek`,
    'schedual.m.Today': `Vandaag`,
    'schedual.emptyTxt1': `Gebruik een secundaire klik om personeel of ploegen toe te voegen.`,
    'schedual.emptyTxt2': ` `,
    'createNewAccount.ampmOr24h': `Tijd formaat`,
    'createNewAccount.termsApprove': `U moet de algemene voorwaarden goedkeuren`,
    'createNewAccount.terms': `Ik ga akkoord met de algemene voorwaarden`,
    'createNewAccount.useSameEmail': `Gebruik dezelfde e-mail die u in de uitnodiging heeft ontvangen. We kunnen u dus in het schema aan een gebruiker toewijzen`,
    'set.billing': `Facturering`,
    'user.allLinked': `Alle gebruikers zijn gelinkt`,
    'user.unlockEmail': `Ontgrendel e-mail`,
    'users.uniqueemail': `E-mail moet uniek zijn`,
    'schedual.m.pasteS': `Plak het schema`,
    'schedual.m.addNewSchedule': `Voeg een nieuw schema toe`,
    'schedual.m.Staff': `Personeel`,
    'schedual.m.Schedule': `Schema`,
    'schedual.last': `Laatst bijgewerkt`,
    'locked.txt1': `Uw account is tijdelijk geblokkeerd wegens gebrek aan credits.`,
    'locked.title': `Je account is geblokkeerd!`,
    'setup.needSetup': `We hebben uw account met succes aangemaakt, maar we moeten het aan een bedrijf koppelen`,
    'createNewAccount.youneedkey': `U moet een bedrijfssleutel hebben`,
    'createNewAccount.emailAndPass': `E-mailadres en wachtwoord`,
    'set.settings': `Instellingen`,
    'user.bulkTitle': `Voeg een door komma's gescheiden lijst met gebruikers in`,
    'users.delete': `Als u de gebruiker verwijdert, wordt deze permanent verwijderd!
    En alle historische gegevens worden gemarkeerd met 'Onbekende gebruiker'
Soms is het misschien beter om de gebruiker gewoon als inactief te markeren.`,
    'users.dname': `Weergavenaam`,
    'users.newuser': `Nieuw`,
    'schedual.deleteBtn2': `Bevestig definitief verwijderen`,
    'schedual.deleteWarning': `Als u het schema verwijdert, wordt het permanent verwijderd en alle historische gegevens erop`,
    'schedual.deleteBtn1': `Schema verwijderen`,
    'schedual.m.addOpenShifts': `Voeg 'open diensten' toe`,
    'schedual.m.print': `Afdrukken`,
    'schedual.m.Chat': `Chat`,
    'schedual.openshifts': `Open diensten`,
    'locked.link': `Klik hier om meer credits te krijgen`,
    'schedual.dropdelete': `Laat shift / gebruiker hier vallen om het te verwijderen!`,
    'setup.mappingUser': `Gebruiker afstemmen op een schema ...`,
    'user.inviteUnMaped': `Nodig niet-gekoppelde gebruikers uit`,
    'set.settings.title': `Systeem instellingen`,
    'user.bulkTxt': `Eén gebruiker per rij. Scheid naam en nummer / mail met een komma`,
    'users.deleteBtn1': `Verwijder gebruiker`,
    'users.uniqueemail2': `Deze mail kan niet worden gebruikt`,
    'schedual.m.undo': `Maak alle wijzigingen ongedaan`,
    'schedual.m.copyS': `Kopieer schema`,
    'schedual.m.Home': `Huis`,
    'schedual.emptyTitle': `Je schema is leeg!`,
    'setup.wrongKey': `Bedrijfssleutel ontbreekt of is onjuist`,
    'user.bulkimport': `Bulk importeren`,
    'users.deleteBtn2': `Bevestig definitief verwijderen`,
    'schedual.m.clear': `Wis alle diensten`,
    'users.invalidphone': `dit is geen geldig mobiel nummer`,
    'user.bulkTxt2': `Weergavenaam, e-mailadres of telefoonnummer
John Doe, +1123456789 of Jane Doe, jane@timeto.work`,
    'schedual.lbl-openshifts': `Open diensten`,
    'schedual.addSFirstTitle': `Voeg een nieuw schema toe`,
    'login.sentResetMail': `We hebben een resetlink naar uw e-mail gestuurd.`,
    'message.in': `In`,
    'email': `E-mail`,
    'login.forCom': `Voor het bedrijf`,
    'message.in.empty': `U heeft geen verzoeken.`,
    'credit': `Credit`,
    'Jan': `Jan`,
    'invites': `Nodig uit`,
    'July': `juli-`,
    'Nov': `Nov`,
    'validphone': `+ landcode en mobiel nummer`,
    'friday': `vrijdag`,
    'tooltip.Approve': `Goedkeuren`,
    'no': `Nee`,
    'calendar.empty': `Deze week is er geen rooster`,
    'schedual.btnHelp': `Helpen`,
    'schedual.btnAddDiv': `Voeg een scheidingslijn toe`,
    'schedual.btnPublish': `Bewaar en publiceer`,
    'wallet.restored': `De aankoop van is hersteld.`,
    'wallet.success1': `de credits zullen binnenkort op uw account verschijnen`,
    'request.titleSick': `Ziek`,
    'request.titleTime': `Tijdsafwijking`,
    'request.titleMove': `Verplaats de ploeg`,
    'request.titleCancle': `Dienst annuleren`,
    'shiftuserinput.cancelation': `Oorzaak van annulering`,
    'shiftuserinput.swapTitle3': `Selecteer Shift`,
    'shiftuserinput.swapTitle2': `Selecteer week`,
    'shiftuserinput.comment': `Commentaar`,
    'shiftuserinput.btn8': `Wissel van dienst met een andere gebruiker`,
    'shiftuserinput.btn4': `Markeer als ziek`,
    'shiftuserinput.btn1': `Rapporteer tijdsafwijking`,
    'shiftuserinput.title3': `Voeg een reactie toe`,
    'shiftuserinput.title1': `Werkelijke gewerkte tijd`,
    'joy.Close': `Dichtbij`,
    'front': `Breng alles naar voren`,
    'minimize': `minimaliseren`,
    'Quick introduction': `Snelle introductie`,
    'Schedule as PDF': `Plan als pdf`,
    'New chat': `Nieuw gesprek`,
    'Select All': `Selecteer alles`,
    'services': `Diensten`,
    'years ago': `jaren geleden`,
    'months ago': `maanden terug`,
    'days ago': `dagen geleden`,
    'hours ago': `uren geleden`,
    'a few seconds ago': `een paar seconden geleden`,
    'joy.clickonstaff': `Klik op 'Personeel'`,
    'a year ago': `een jaar geleden`,
    'a month ago': `een maand geleden`,
    'seconds ago': `seconden geleden`,
    'joy.orClickOnTheSchedule': `Of u kunt dubbelklikken op het rooster`,
    'joy.clickHerToAddUsers': `Klik hier om een of twee personeelsleden toe te voegen`,
    'joy.Skip': `Handleiding overslaan`,
    'Undo all changes': `Maak alle wijzigingen ongedaan`,
    'Add to schedule': `Toevoegen aan planning`,
    'File': `het dossier`,
    'selectuser.warning': `Selecteer ten minste één gebruiker om toe te voegen aan het schema`,
    'usernotmapped_txt2': `Vraag uw schemabeheerder om uw e-mailadres toe te voegen`,
    'report.h_schedal': `Schema`,
    'report.h_presence': `Aanwezigheid`,
    'billing.bHistory': `Factureringsgeschiedenis`,
    'billing.msgHead': `Je account heeft niet genoeg credits.`,
    'set.settings.btn.sCha': `Verander tijd`,
    'Show All': `Toon alles`,
    'Paste': `Plakken`,
    'userCredits': `gebruikerskredieten`,
    'ShiftLabel': `Label verschuiven`,

}

