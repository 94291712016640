import { getMessaging } from './core/config/firebase'
//this is for web only not cordova 
export function initializePush() {
    console.log('>inside initializePush')
    var messaging = getMessaging()
    messaging
        .requestPermission()
        .then(() => {
            return messaging.getToken();
        })
        .then(token => {
            console.log("FCM Token:", token);
            //send the token to the server to be able to send notifications in the future
            //    console.log('send token to server', token)
            window._webToken = token
            //sendTokenToServer(token);
            window.dispatchEvent(new CustomEvent("getToken", { detail: token }));

        })
        .catch(error => {
            if (error.code === "messaging/permission-blocked") {
                console.log("Please Unblock Notification Request Manually");
            } else {
                console.log("Error Occurred", error);
            }
        });

    messaging.onMessage(payload => {
        console.log('msg in web!', payload)
        window.dispatchEvent(new CustomEvent("onMessagePush", { detail: { ...payload } }));

        // try {
        //     if (payload.notification)
        //         toastr.success(payload.notification.title || '', payload.notification.body || '');
        //     else if (payload.data)
        //         toastr.success(payload.data.title || '', payload.data.body || '');
        // } catch (error) {
        //     console.log('#err msg in>', error)
        // }

        //toastr.info(payload.body, payload.title)
    });

    // messaging.setBackgroundMessageHandler(function (payload) {
    //     console.log('%%%%%%>Handling background message ', payload);
    //     console.log('%%%%%%>window._badeUrl ', window._badeUrl);

    //     return window.self.registration.showNotification(
    //         payload.data.title, {
    //         body: 'OK!' + payload.data.body,
    //         icon: payload.data.icon,
    //         tag: payload.data.tag,
    //         data: payload.data.link
    //     });
    // });

}