import React, { Component } from "react";
import PropTypes from 'prop-types'
import { connect } from "react-redux";
import moment from 'moment'
import FirestoreListnerHelper from '../../../core/util/FirestoreListnerHelper'
import collection from 'lodash/collection'
//import Swipe from 'react-swipe-component'
import ShiftUserInput from '../modal/ShiftUserInput'
import { renderDateAddDays, setRenderNow } from '../../../core/redux/action/viewstatAction'
import Message from 'semantic-ui-react/dist/commonjs/collections/Message'
import Loader from 'semantic-ui-react/dist/commonjs/elements/Loader'
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon'
import { openModal } from "../../../core/redux/action/modalAction";
import { isMobile } from 'react-device-detect';
import { toTop, getLocalDate } from '../../../core/util/helpers'
import { FireHelper } from '../../../core/util/FireHelper'
import { isElectron } from 'react-device-detect';
import { OpenContextMenu } from '../../common/component/ContextMenu'

//import SwipeX from '../../common/component/SwipeX'

const _colorsAvilibility = ['var(--color-font', 'rgba(219, 40, 40, 0.8)', 'rgba(33, 186, 69, 0.8)', 'rgba(251, 189, 8, 0.8)', 'rgba(251, 189, 8, 0.8)', 'rgba(251, 189, 8, 0.8)']
const _colorsAvilibilityBg = ['#ff', 'rgba(219, 40, 40, 0.04)', 'rgba(33, 186, 69, 0.04)', 'rgba(251, 189, 8, 0.04)', 'rgba(251, 189, 8, 0.04)', 'rgba(251, 189, 8, 0.04)']

let ipcRenderer
// let electronMenu
// let electronRemote
if (isElectron) {
  ipcRenderer = window.require('electron').ipcRenderer
  // electronRemote = window.require('electron').remote;
  // electronMenu = electronRemote.Menu;
}

const modalKey = 'shiftuserinput'
class UserHome extends Component {
  doOnce = true
  state = { availabilityData: null, filterdSchedules: null, slideAni: '' }
  //static contextTypes = { store: PropTypes.object.isRequired }

  swipeHandler = (obj) => {
    const swipe = obj.detail
    if (swipe)
      if (swipe.dir === 'left')
        this.onSwipedLeft()
      else
        this.onSwipedRight()

  }

  componentWillUnmount = () => {
    //console.log('removeEventListener->onSwipeX')
    // window.removeEventListener('onSwipeX', this.swipeHandler, false);

  }

  componentDidMount = () => {


    if (ipcRenderer)
      ipcRenderer.send('setMenu', '/home')

    this.doOnce = true
    toTop()

    // console.log('componentDidMount', this.props.currentUser.isLoaded)
    if (this.props.currentUser.isLoaded === true) {
      this.setAviliData()
      if (this.props.currentUser.role >= 100)
        FirestoreListnerHelper.setWeekViewListner(this.context, this.props.renderDate)
    }

    //console.log('componentDidMount->onSwipeX')
    // window.addEventListener('onSwipeX', this.swipeHandler, false);

  }
  componentDidUpdate = async (prevProps, prevState, snapshot) => {
    if (prevProps.renderDateKey !== this.props.renderDateKey) {
      if (this.props.currentUser.role >= 100)
        FirestoreListnerHelper.setWeekViewListner(this.context, this.props.renderDate)
    }
    this.setAviliData()
  }

  openContextMenuOpenShift = (data) => (e) => {

    e.preventDefault();
    this.onOpenModal({ ...data, activeIndex: 5 })
  }

  openContextMenuAvailability = (dayIndex) => (e) => {

    e.preventDefault();


    const config = [
      {
        label: window._getText('Available'),
        click: this.selectAvalibleStatus(0, dayIndex),
      },
      {
        label: window._getText('CanNotWork'),
        click: this.selectAvalibleStatus(1, dayIndex),
      },
      {
        label: window._getText('WantToWork'),
        click: this.selectAvalibleStatus(2, dayIndex),
      },
      {
        label: window._getText('Vacation'),
        click: this.selectAvalibleStatus(3, dayIndex),
      },

    ]

    OpenContextMenu(config)


  }

  openContextMenu = (data) => (e) => {

    e.preventDefault();


    const config = [
      {
        label: window._getText('shiftuserinput.btn1'),
        click: () => this.onOpenModal({ ...data, activeIndex: 1 }),
      },
      {
        label: window._getText('shiftuserinput.btn2'),
        submenu: [
          {
            label: window._getText('shiftuserinput.btn5'),
            click: () => this.onOpenModal({ ...data, activeIndex: 2, subIndex: 1 }),
          },
          {
            label: window._getText('shiftuserinput.btn6'),
            click: () => this.onOpenModal({ ...data, activeIndex: 2, subIndex: 2 }),
          },
          {
            label: window._getText('shiftuserinput.btn7'),
            click: () => this.onOpenModal({ ...data, activeIndex: 2, subIndex: 3 }),
          },
          {
            label: window._getText('shiftuserinput.btn8'),
            click: () => this.onOpenModal({ ...data, activeIndex: 2, subIndex: 4 }),
          },

        ]
      },
      {
        label: window._getText('shiftuserinput.btn3'),
        click: () => this.onOpenModal({ ...data, activeIndex: 3 }),
      },
      {
        label: window._getText('shiftuserinput.btn4'),
        click: () => this.onOpenModal({ ...data, activeIndex: 4 }),
      },
    ]



    OpenContextMenu(config)



    // this.onOpenModal(data)
  }


  getUserData = () => {
    const scheduleWeekData = this.props.scheduleWeekData || {}
    const scheduleWeekDataMeta = this.props.fireData['weekview_' + this.props.renderDateKey + '_meta'] || {}
    const uKey = this.props.currentUser.inSysUserKey
    const filterdSchedules = { count: 0 }





    collection.forEach(scheduleWeekData, (schedule) => {
      if (schedule) {
        var userMetadataForSchedule = scheduleWeekDataMeta[schedule.sKey] || {}
        var userData = collection.find(schedule.rows, ['k', uKey]);
        var openShifts = collection.find(schedule.rows, ['k', 'openshifts'])
        if (userData || openShifts) {
          const count = Object.keys((userData || {}).s || {}).length
          filterdSchedules[schedule.key] = {
            sName: schedule.sName,
            dateKey: this.props.renderDateKey,
            key: schedule.key,
            sKey: schedule.sKey,
            userData: userData || {},
            openShifts: openShifts || {},
            meta: userMetadataForSchedule,
            shiftCount: count
          }
          filterdSchedules.count += count;
        }


      }
    })




    return filterdSchedules;
  }


  setRenderDate = (d) => () => {
    this.props.renderDateAddDays(d)
  }


  onOpenModal = (shift) => {
    this.props.onOpenModal(modalKey, shift)
  }


  renderOpenShift = (index, schedule, date) => {
    if (!schedule.openShifts || !schedule.openShifts.s)
      return null;

    const data = schedule.openShifts.s[index]
    if (!data)
      return null;

    // console.log('data', data)

    return data.map((shift, index) => {
      const meta = schedule.meta[shift.k]
      const sick = shift.sik === true ? true : false

      return (
        <React.Fragment key={shift.k}>

          <div tooltip={`${schedule.sName} - (${shift.l})`} onClick={this.openContextMenuOpenShift({ shift: { ...shift, d: date }, sKey: schedule.sKey, meta, })} key={shift.k}
            style={{
              paddingLeft: 2,
              paddingRight: 2,
            }} className={"opensshiftsLable ui " + shift.c}  >
            <span style={{ color: sick ? 'silver' : 'var(--color-font)' }} >{shift.l ? shift.l : ''}</span>
          </div><span>, </span>
        </React.Fragment>
      )
    })
  }
  renderShift = (index, schedule, date) => {
    if (!schedule.userData || !schedule.userData.s)
      return null;

    const data = schedule.userData.s[index]
    if (!data)
      return null;



    return data.map((shift, index) => {
      const meta = schedule.meta[shift.k]
      const sick = shift.sik === true ? true : false


      return (

        <div onClick={this.openContextMenu({ shift: { ...shift, d: date }, sKey: schedule.sKey, meta, })} key={shift.k} style={{ width: '100%', marginBottom: '5px', padding: '8px', borderRadius: '5px' }} className={"ui y" + (sick ? 'sick' : shift.c)}  >

          <div style={{ float: 'left', fontSize: '13px', color: 'var(--color-font)' }}><Icon name='calendar outline'></Icon><b style={{ paddingRight: '5px' }}>{schedule.sName}</b></div>

          <div style={{ float: 'right', color: 'var(--color-font)' }}>
            {meta && meta['deviation'] && <span><Icon name='tag' size='small' /></span>}
            {meta && meta['comment'] && <span><Icon name='comment' size='small' /></span>}
            {meta && meta['change'] && <span><Icon name='comments' size='small' /></span>}
            {meta && meta['sick'] && <span><Icon name='bed' size='small' /></span>}
            {sick === true && <span><Icon name='bed' size='small' /></span>}
          </div>
          <div style={{ clear: 'both' }}></div>
          <div >
            <span style={{ fontSize: '20px', color: sick ? 'silver' : 'var(--color-font)', fontWeight: 'bold' }} >{shift.l}</span>
            {shift.b && <span style={{ fontSize: '13px', color: sick ? 'silver' : 'var(--color-font)', marginLeft: '4px' }} >(break {shift.b}min)</span>}
          </div>


          {
            shift.co &&
            <div style={{ fontSize: '13px', color: sick ? 'silver' : 'var(--color-font)' }}>
              {shift.co}
            </div>
          }

        </div>
        // </div>
      )
    })
  }


  getDayShift = (dayIndex, filterdSchedules, rDate) => {



    const r = Object.keys(filterdSchedules).map((key, index) => {
      const schedule = filterdSchedules[key]


      // if (schedule.shiftCount === 0)
      //   return null;

      if (!schedule.userData)
        return null;


      // if (!schedule.userData.s[dayIndex])
      //   return null;


      // console.log('filterdSchedules', rDate.clone().add(dayIndex - 1, 'days').format("YYYY-MM-DD ddd"))
      const openshifts = this.renderOpenShift(dayIndex, schedule, rDate.clone().add(dayIndex - 1, 'days').format("YYYY-MM-DD"))
      return (
        <div key={key} style={{ paddingLeft: '6px', display: 'flex', flexDirection: 'column', height: '100%' }}>
          <div style={{ minHeight: '50px' }}>
            {this.renderShift(dayIndex, schedule, rDate.clone().add(dayIndex - 1, 'days').format("YYYY-MM-DD"))}
          </div>
          {openshifts &&
            <div style={{ display: 'flex', lineHeight: 'normal' }}>
              <span tooltip={window._getText('schedual.openshifts')}><Icon name='add circle'  ></Icon></span> {openshifts}
            </div>
          }
        </div>
      )
    })
    return r.filter(obj => obj != null)
  }


  setAvailability = (avilIndex, day) => {

  }


  onSubmitAvalibleStatus = async (availabilityIndex, dayIndex, allWeek) => {
    console.log('onSubmitAvalibleStatus>> dayIndex', dayIndex)
    const { projectKey, inSysUserKey } = this.props


    try {
      if (allWeek === true) {
        await FireHelper.setDayMetaDataAllWeek(projectKey, inSysUserKey, this.props.renderDateKey, availabilityIndex)
      }
      else
        await FireHelper.setDayMetaData(projectKey, inSysUserKey, this.props.renderDateKey, dayIndex, availabilityIndex)

    } catch (error) {
      console.log(error)
    }


    console.log('TODO toast sendt ...')


  };

  selectAvalibleStatus = (availabilityIndex, dayIndex) => () => {
    if (availabilityIndex === 3) {
      if (window.confirm(`Set vacation all week?`)) {
        this.onSubmitAvalibleStatus(availabilityIndex, dayIndex, true)
      } else {
        this.onSubmitAvalibleStatus(availabilityIndex, dayIndex, false)
      }
    } else {
      this.onSubmitAvalibleStatus(availabilityIndex, dayIndex, false)
    }
  }




  setAviliData = () => {


    const inSysUserKey = this.props.inSysUserKey
    const metaDataFull = this.props.fireData['weekview_' + this.props.renderDateKey + '_meta'] || {}
    const metaData = metaDataFull.avil || {}
    let availabilityData = null


    if (metaData && inSysUserKey && metaData[inSysUserKey])
      availabilityData = metaData[inSysUserKey]


    if (availabilityData !== this.state.availabilityData)
      this.setState({ availabilityData })

  }

  renderDays = (filterdSchedules, rDate) => {
    const { firstDayOfWeekNumber } = this.props //sunday 0 , monday 1
    const availabilityData = this.state.availabilityData || {}

    var data = []
    //const colors = ['#fbbd08', '#b5cc18', '#21ba45', '#02c39a', '#2185d0', '#6435c9', '#a333c8']
    const indexes = firstDayOfWeekNumber === 0 ? [0, 7, 1, 2, 3, 4, 5, 6] : [0, 1, 2, 3, 4, 5, 6, 7]
    for (let index = 1; index < 8; index++) {
      var i = indexes[index];
      const d = rDate.clone()
      const innerContent = this.getDayShift(i, filterdSchedules, d.clone())
      //if (innerContent.length > 0) {


      const availabilityIndex = availabilityData[i] || {}
      const avilColor = _colorsAvilibility[availabilityIndex] || '#000'
      const avilColorBg = _colorsAvilibilityBg[availabilityIndex] || '#fff'

      data.push(
        <div key={i} style={{

          height: '100%',
          marginBottom: '15px', borderBottom: 'dotted 1px var(--color-border)', paddingTop: '5px', paddingBottom: '5px', xbackgroundColor: 'rgba(241, 241, 241, 0.5)', xborderRadius: '10px', width: '98%'
        }}>
          <div onClick={this.openContextMenuAvailability(i)} style={{ float: 'left', width: '50px', fontWeight: 'bold', textAlign: 'center', padding: '5px', }} >
            <div style={{ fontSize: '20px', color: avilColor, }}  >{getLocalDate(d.clone().add(index - 1, 'days'), "ddd")}</div>
            <span style={{ fontSize: '11px', color: 'gray' }}>{getLocalDate(d.clone().add(index - 1, 'days'), "DD[\r\n]MMM")}</span>
          </div>
          <div style={{ float: 'left', width: 'calc(100% - 60px)' }}>{innerContent}</div>
          <div style={{ clear: 'both' }}></div>
        </div>
      )
      //}

    }
    return data;
  }
  gotoToday = () => {
    this.props.setRenderNow()
  }



  onSwipedLeft = () => {

    this.setState({ slideAni: 'slideAniLeft' })
    this.props.renderDateAddDays(7)

  }
  onSwipedRight = () => {
    this.setState({ slideAni: 'slideAniRight' })
    this.props.renderDateAddDays(-7)


  }
  animationEnd = (e) => {
    if (e.target.className === 'slideAniLeft') {
      this.setState({ slideAni: '' })
    }
    else if (e.target.className === 'slideAniRight') {
      this.setState({ slideAni: '' })
    }
  }
  render() {

    var rDate = this.props.renderDate.clone()

    var filterdSchedules = this.getUserData(rDate)
    //console.log('render', rDate.format("YYYY-MM-DD ww"))

    if (this.doOnce === true) {
      // console.log('onAppReady dispatch')
      this.doOnce = false;
      // window.dispatchEvent(new CustomEvent("onAppReady", { detail: { } }));
      if (isElectron) {
        ipcRenderer.send('hidesplash', 'userhome')
      } else {
        window._doOnce = false;
        window._hidesplash = true
      }

    }


    return (
      <React.Fragment>
        {/* <SwipeX onSwipedLeft={this.onSwipedLeft} onSwipedRight={this.onSwipedRight}></SwipeX> */}



        {/* <Swipe
          className='swipeDiv'
          onSwipedLeft={this.onSwipedLeft}
          onSwipedRight={this.onSwipedRight}

        > */}

        {!isMobile &&
          <div style={{ position: 'fixed', background: 'var(--color-bg1)', width: '100%', height: '60px' }} className='dragWindow2'>
            <Icon name='angle left' style={{ float: 'left', cursor: 'pointer', paddingTop: '15px' }} size='big' onClick={this.setRenderDate(-7)}></Icon>
            <div style={{ float: 'left', textAlign: 'center', paddingTop: '15px' }}>
              <div style={{ fontSize: '40px', fontWeight: '900' }} >{window._getText('week')} {rDate.format("ww")}</div>
              <div onClick={this.gotoToday} style={{ cursor: 'pointer', color: 'silver', paddingRight: '10px' }}>{getLocalDate(moment(), "LL")}</div>
            </div>
            <Icon name='angle right' style={{ float: 'left', cursor: 'pointer', paddingTop: '15px' }} size='big' onClick={this.setRenderDate(7)}></Icon>
            <div style={{ clear: 'both' }}></div>
          </div>
        }

        {isMobile &&
          <div className='fixed' style={{ position: 'fixed', background: '#fff', width: '100%' }}>
            <div style={{ paddingLeft: '10px', paddingTop: '10px', float: 'left', fontSize: '20px', fontWeight: '900' }} >{window._getText('week')} {rDate.format("ww")}</div>
            <div onClick={this.gotoToday} style={{ fontSize: '12px', color: 'silver', float: 'right', paddingRight: '10px' }}>{getLocalDate(moment(), "LL")}</div>
            <div style={{ clear: 'both' }}></div>
          </div>
        }

        <div className={this.state.slideAni} onAnimationEnd={this.animationEnd} style={{ paddingTop: isMobile ? '35px' : '70px' }} >
          {/* {this.props.currentUser.role >= 100 &&
            <Loader active={!this.props.isDataLoaded} style={{ textAlign: 'center', marginTop: '50px' }} >{window._getText('loading')}</Loader>
          } */}

          {this.props.isUnknown &&
            <Message style={{ margin: '10px', }}>
              <Message.Header>{window._getText('usernotmapped_title')}</Message.Header>
              <p>{window._getText('usernotmapped_txt1')} <br />
                {window._getText('usernotmapped_txt2')}</p>
            </Message>
          }

          {/* {(filterdSchedules.count < 1 && this.props.isDataLoaded) &&
            <div style={{ textAlign: 'center', marginTop: '50px' }}>
              <Icon name='heart outline' size='huge'></Icon><br />
              {window._getText('userhome.youarefree')}
            </div>} */}
          <div style={{ paddingLeft: '8px' }} >
            {this.renderDays(filterdSchedules, rDate)}
          </div>
          {/* <Icon name='angle left' style={{ position: 'absolute', top: '200px', left: '-2px' }}> </Icon> */}
        </div>
        {/* </Swipe> */}
        <ShiftUserInput></ShiftUserInput>
      </React.Fragment>
    )
  }
}


const mapStateToProps = (state, ownProps, ownState) => {

  const inSysUserKey = state.firebase.profile.inSysUserKey
  const firstDayOfWeekNumber = Number(state.firebase.profile.firstDay) //sunday 0 , monday 1


  var isUnknown = true
  if (inSysUserKey)
    isUnknown = false;

  var renderDateKey = state.viewstate.renderDateKey //state.viewstate.renderDateKey
  let scheduleWeekData = {}
  const scheduleWeekDataRaw = state.firestore.data[`weekview_${renderDateKey}`] || {}
  Object.keys(scheduleWeekDataRaw).forEach(key => {
    const item = scheduleWeekDataRaw[key]
    const publish = item.publish !== undefined ? item.publish : false
    const unPublish = item.unPublish !== undefined ? item.unPublish : true

    // item is not broken
    if (item && item.sName) //isAdmin == true
    {
      if (unPublish !== true && publish === true) {
        scheduleWeekData[key] = item
      }
    }
  })



  return ({
    // isDataLoaded: state.firestore.status.requested[`weekview_${state.viewstate.renderDateKey}`],
    isDataLoaded: state.firestore.status.requested[`weekview/${state.viewstate.renderDateKey}`],
    scheduleWeekData,
    //isDataLoadedMeta: state.firestore.status.requested[`projects/${state.firebase.profile.projectKey}/weekview/${state.viewstate.renderDateKey}_meta`],
    renderDate: moment.utc(state.viewstate.renderDate),
    renderDateKey: state.viewstate.renderDateKey,
    projectKey: state.firebase.profile.projectKey,
    fireRequested: state.firestore.status.requested,
    fireData: state.firestore.data,

    isLandscape: state.viewstate.isLandscape,
    currentUser: state.firebase.profile,
    firstDayOfWeekNumber,
    isUnknown,
    inSysUserKey,

  });
}
const mapActionsFromProps = {
  onOpenModal: openModal,
  renderDateAddDays,
  setRenderNow
}
export default connect(
  mapStateToProps,
  mapActionsFromProps
)(UserHome);

