import React, { useMemo } from "react";
import noUserImage from '../../../img/noimg.png'
import { getUserImage } from '../../../core/util/helpers'



const preventDefault = (e) => e.preventDefault()
const imgError = (e) => { e.target.onerror = null; e.target.src = noUserImage }


const Avatar = (props) => useMemo(() => {
    const pkey = window._projectKey
    const {imgUrl,dragStartRow,onDragOverHideDropZone,userKey,name} = props;

    if (imgUrl) return (<img draggable alt='' onDragStart={dragStartRow} onDragEnd={onDragOverHideDropZone} onContextMenu={preventDefault} src={imgUrl ? getUserImage(pkey, userKey, imgUrl) : noUserImage} onError={imgError} />);


    const haveSpace = name.indexOf(' ') > -1
    const nameChars = haveSpace ? (name || ' ').split(' ') : name
    let sum = ''
    for (let ii = 0; ii < userKey.length; ii++) {
        const charNrStr = String(userKey[ii].charCodeAt());
        sum += charNrStr

    }

    const rgb = `${sum.substring(0, 2)}%,${sum.substring(2, 4)}%,${sum.substring(4, 6)}%`
    const alias = []
    for (let index = 0; index < nameChars.length; index++) {

        if (index < 2)
            alias.push(String(nameChars[index] || ' ').substring(0, 1));

    }


    return (<avatar style={{ marginRight: 8, float: 'left', justifyContent: 'center', alignItems: 'center', width: 30, height: 30, borderRadius: 15, background: `rgba(${rgb},0.34)`, border: `solid 4px rgba(${rgb},0.9)`, display: 'flex' }}><label>{alias.join('')}</label></avatar>)

}, [props.userKey, props.imgUrl])
export default Avatar