import React, { Component } from "react";

import { Button, BButton, Icon, Popup } from "../../common/component/Button";
import { Dropdown } from "semantic-ui-react";
import moize from "moize";
import { isMobile, isElectron, isBrowser } from "react-device-detect";
import { getLocalDateStr } from "../../../core/util/helpers";
import WebContextMenu from "../../common/component/ContextMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlusCircle as icoPlus,
  faCalendarPlus as icoAddShift,
  faUserPlus as icoAddUser,
  faHorizontalRule as icoAddDiv,
  faCloudDownload as icoUnpublish,
  faCloudUpload as icoPublish,
  faArrowCircleLeft as icoLeft,
  faArrowCircleRight as icoRight,
  faPaste as icoPasteSchedule,
  faCopy as icoCopySchedule,
} from "@fortawesome/pro-duotone-svg-icons";

import moment from "moment";
import { shallowEqual } from "shouldcomponentupdate-children";

let ipcRenderer;
let shell;

if (isElectron) {
  ipcRenderer = window.require("electron").ipcRenderer;
  shell = window.require("electron").shell;
}

let isMacOs = process.env.REACT_APP_MACOS === "1";
const INITIAL_STATE = {
  isContextMenuOpen: false,
  contextMenu: [],
  openDDCopy: false,
  openDDPlus: false,
  openDDPrint: false,
  openDDRedo: false,
  openDDView: false,
  view: 1,
  showSaveButton: false,
}; // ?? inuse ?
export class ScheduleTopMenu extends Component {
  constructor(props) {
    super(props);
    var newState = { ...INITIAL_STATE };
    newState.showSaveButton = localStorage.getItem("showSaveButton") || false;
    this.state = newState;
  }
  shouldComponentUpdate(nextProps, nextState) {
    const r = shallowEqual(this.props, nextProps, this.state, nextState);
    //console.log('>>shouldComponentUpdate>>SchedualMenu>>', r)
    return r;
  }

  componentDidMount = () => {
    window.addEventListener(
      "onClickContexMenu_topMenuScheduleAdd",
      this.contextMenuClick
    );
  };
  componentWillUnmount = () => {
    window.removeEventListener(
      "onClickContexMenu_topMenuScheduleAdd",
      this.contextMenuClick
    );
  };

  contextMenuClick = ({ detail: { cmd } }) => {
    switch (cmd) {
      case "newShift":
        this.addShift();
        break;
      case "addStaff":
        this.onMenuClick("onClickToolsAddUser")();
        break;
      case "addDivider":
        this.onMenuClick("onClickToolsAddDivider")();
        break;

      default:
        break;
    }
  };

  onClickSchema = (e, { value, text }) => {
    // this.setState({ selectedScheduel: text }) // ?? inuse ?
    this.props.onChangeSchema(e, { value, text });
  };
  onOpenEditScheduleModal = () => {
    this.props.openEditScheduleModal(null, null);
    this.setState({ openDDCopy: false, openDDPlus: false });
  };
  onOpenEditScheduleModalEdit = () => {
    this.props.openEditScheduleModal(
      this.props.scheduleDropdownKey,
      this.props.scheduleDropdownName
    );
    this.setState({ openDDCopy: false, openDDPlus: false });
  };
  openSelectSchedualModal = () => {
    console.log("openSelectSchedualModal");
    this.props.openSelectSchedualModal();
    this.setState({
      openDDCopy: false,
      openDDPlus: false,
      openDDPrint: false,
      openDDRedo: false,
      openDDView: false,
    });
  };
  onMenuClick = (key) => () => {
    this.props[key]();
    this.setState({
      openDDCopy: false,
      openDDPlus: false,
      openDDPrint: false,
      openDDRedo: false,
      openDDView: false,
    });
  };

  setOpen = (name) => () => {
    this.setState({ [name]: true });
  };
  setClose = (name) => () => {
    this.setState({ [name]: false });
  };
  getDropdownData = moize.react((scheduleDropDownList) => {
    //console.log('menu no cahce')
    return (
      <Dropdown.Menu scrolling>
        {scheduleDropDownList.map((option) => {
          if (!option) return null;

          return (
            <Dropdown.Item
              icon="calendar alternate outline"
              onClick={this.onClickSchema}
              key={option.value}
              {...option}
            />
          );
        })}
      </Dropdown.Menu>
    );
  });

  short = (name, n) => {
    if (!name) return "";
    return name.length > n ? name.substr(0, n - 1) + "..." : name;
  };

  //NOT IN USE
  printPdf = () => {
    console.log("printPdf");
    if (isElectron) {
      const credits = this.props.credits || 0;
      if (credits < 0) {
        alert(window._getText("PleaseAddCreditsBeforePrinting"));
      }
      ipcRenderer.send("printToPDF", "");
    }
  };
  addShift = () => {
    window.dispatchEvent(
      new CustomEvent("newshift", {
        detail: { userkey: null, dataedate: null },
      })
    );
  };
  copySchedule = () => {
    window.dispatchEvent(
      new CustomEvent("copyschedule", {
        detail: { userkey: null, dataedate: null },
      })
    );
  };
  pasteSchedule = () => {
    window.dispatchEvent(
      new CustomEvent("pasteschedule", {
        detail: { userkey: null, dataedate: null },
      })
    );
  };
  doSave = () => {
    alert(
      "You dont have to click save anymore.\n\nThe app is autosaving your workview.\n\nThis button will be removed in the next update "
    );
    // this.props.onSaveScheduleNoPublish(false)
    this.setState({ showSaveButton: true });
    localStorage.setItem("showSaveButton", true);
  };
  doUnPublish = () => {
    this.props.onUnpublishSchedule();
  };
  doSaveAndPublish = () => {
    this.props.onSaveSchedule(true, false);
  };
  openHelp = () => {
    if (shell) shell.openExternal("https://www.timeto.work/help/");
  };
  toggleView = (view) => () => {
    this.setState({ view });

    this.props.setScheduleView(view);
  };

  openMenu = () => {
    const contextMenu = [
      {
        label: window._getText("NewShift"),
        click: () => {
          window.dispatchEvent(
            new CustomEvent("onClickContexMenu_topMenuScheduleAdd", {
              detail: { cmd: "newShift" },
            })
          );
        },
      },
      { type: "separator" },
      {
        label: window._getText("AddStaff"),
        click: () => {
          window.dispatchEvent(
            new CustomEvent("onClickContexMenu_topMenuScheduleAdd", {
              detail: { cmd: "addStaff" },
            })
          );
        },
      },
      { type: "separator" },
      {
        label: window._getText("AddDivider"),
        click: () => {
          window.dispatchEvent(
            new CustomEvent("onClickContexMenu_topMenuScheduleAdd", {
              detail: { cmd: "addDivider" },
            })
          );
        },
      },
    ];
    this.setState({ isContextMenuOpen: true, contextMenu }, () => {
      if (isElectron)
        this.setState({ isContextMenuOpen: false, contextMenu: [] });
    });
  };

  getCssMainDiv = () => {
    return {
      paddingTop: "5px",
      background: "var(--color-bg1)",
      paddingRight: isMacOs === true ? "6px" : "70px",
    };
  };
  cssSaveButton = (cssColorPublishButton) => {
    return { fontSize: "22px", color: cssColorPublishButton };
  };
  render() {
    var {
      isPublish,
      isLandscape,
      scheduleDropDownList,
      renderDayDate,
      onClickChangeDate,
      setCloneShift,
      cloneShift,
    } = this.props;

    const cssColorPublishButton = isPublish ? "" : "green"; //'#3e960e'
    const dateEnd = moment
      .utc(renderDayDate)
      .add((this.props.weeksToShow - 1) * 7, "days")
      .add(6, "days");
    const dateStart = moment.utc(renderDayDate);
    return (
      <React.Fragment>
        {/* <Stripe></Stripe> */}
        <WebContextMenu
          open={this.state.isContextMenuOpen}
          config={this.state.contextMenu}
          onClose={() => {
            this.setState({ isContextMenuOpen: false });
          }}
        ></WebContextMenu>
        <div className="divPrintHeader">
          {getLocalDateStr(dateStart)} - {getLocalDateStr(dateEnd)}
        </div>

        <div className="sMenu_s1 dragWindow noPrint" style={styles.s1}>
          <div>
            {/* // {isElectron || isBrowser && */}
            {this.MenuDesktop(
              dateStart,
              dateEnd,
              onClickChangeDate,
              cssColorPublishButton,
              cloneShift,
              setCloneShift
            )}
            {/* // } */}
          </div>
        </div>
      </React.Fragment>
    );
  }

  MenuDesktop(
    dateStart,
    dateEnd,
    onClickChangeDate,
    cssColorPublishButton,
    cloneShift,
    setCloneShift
  ) {
    return (
      <div style={styles.s4}>
        <div style={styles.s5}>
          <b className="topMenuWeekDateMainB">
            <span className="topMenuWeekDateMainWeek">
              {window._getText("week")}{" "}
            </span>

            {dateStart.format("ww")}
            {dateStart.format("ww") !== dateEnd.format("ww") && (
              <React.Fragment>
                &nbsp;-&nbsp;{dateEnd.format("ww")}
              </React.Fragment>
            )}
          </b>
        </div>

        <div style={styles.s6}>
          <b onClick={onClickChangeDate(-7)} style={styles.s7}>
            <FontAwesomeIcon icon={icoLeft} style={styles.s8}></FontAwesomeIcon>
          </b>

          <b className="item" style={styles.s9} className="tour_schedule_2">
            <div className="topMenuWeekDateMainDiv">
              <span className="text">
                <div className="topMenuWeekDateDiv">
                  {getLocalDateStr(dateStart)} - {getLocalDateStr(dateEnd)}
                </div>
              </span>
            </div>
          </b>
          <b onClick={onClickChangeDate(7)} style={styles.s10}>
            <FontAwesomeIcon
              icon={icoRight}
              style={styles.s11}
            ></FontAwesomeIcon>
          </b>
        </div>

        <div style={styles.s12}>
          <BButton
            style={styles.s15}
            disabled={this.props.workview === 0}
            onClick={this.copySchedule}
            tooltip={window._getText("schedual.btnCopySchedule")}
          >
            <FontAwesomeIcon
              icon={icoCopySchedule}
              style={styles.s16}
            ></FontAwesomeIcon>
          </BButton>

          <BButton
            style={styles.s15}
            disabled={this.props.workview === 0}
            onClick={this.pasteSchedule}
            tooltip={window._getText("schedual.btnPasteSchedule")}
          >
            <FontAwesomeIcon
              icon={icoPasteSchedule}
              style={styles.s16}
            ></FontAwesomeIcon>
          </BButton>

          <BButton
            style={styles.s13}
            disabled={this.props.workview === 0}
            onMouseDown={this.openMenu}
            // onMouseOver={this.openMenu}
            tooltip={window._getText("schedual.btnAddShift")}
            className="onboardStep_topmenu_addshift tour_schedule_4 contextMenu"
          >
            <FontAwesomeIcon
              icon={icoPlus}
              style={styles.s14}
            ></FontAwesomeIcon>
          </BButton>

          <BButton
            onClick={this.doUnPublish}
            style={styles.s21}
            compact
            className="tour_schedule_8"
            tooltip_r={window._getText("Unpublish")}
          >
            <FontAwesomeIcon
              icon={icoUnpublish}
              style={styles.s22}
            ></FontAwesomeIcon>
          </BButton>
          <BButton
            onClick={this.doSaveAndPublish}
            style={styles.s23}
            compact
            className={"onboardStep_topmenu_save tour_schedule_7"}
            tooltip_r={window._getText("Publish")}
          >
            <FontAwesomeIcon
              icon={icoPublish}
              style={this.cssSaveButton(cssColorPublishButton)}
            ></FontAwesomeIcon>
          </BButton>
        </div>

        {cloneShift === true && (
          <div onClick={setCloneShift} style={styles.s25}>
            <div
              className="ui icon message black whiteColor"
              style={styles.s26}
            >
              {/* //TODO:LANG */}
              <div className="content whiteColor" style={{ color: "#000" }}>
                <div className="header whiteColor">Shift clone</div>
                <p>
                  <i aria-hidden="true" className="close icon whiteColor"></i>
                  <span className="whiteColor">Click to exit clone mode.</span>
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }

  xxxMenuDesktop(isLandscape, scheduleDropDownList) {
    return (
      <div>
        <Icon name="calendar alternate outline" size="large" />

        <Dropdown
          style={styles.s2}
          text={
            isLandscape
              ? this.short(this.props.scheduleDropdownName, 30)
              : this.short(this.props.scheduleDropdownName, 14)
          }
          floating
          labeled
          className="icon"
          pointing="top left"
        >
          <Dropdown.Menu>
            <Dropdown.Header>
              <div className="sMenu_s2">
                <b className="sMenu_s3">{this.props.scheduleDropdownName} </b>
                <Popup
                  trigger={
                    <Button
                      compact
                      icon="pencil alternate"
                      size="small"
                      className="sMenu_s4"
                      onClick={this.onOpenEditScheduleModalEdit}
                    />
                  }
                  content={window._getText("schedual.editS")}
                />
              </div>
            </Dropdown.Header>

            {this.getDropdownData(scheduleDropDownList || [])}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  }
}

const styles = {
  // s1: { paddingTop: '5px', background: 'var(--color-bg1)', paddingRight: isMacOs === true ? '6px' : '70px' },
  s2: { minWidth: "150px" },
  s3: {
    fontSize: isMobile ? "18px" : undefined,
    padding: isMobile ? "8px" : undefined,
  },
  s4: { display: "flex", width: "100%", height: 50 },
  s5: { display: "flex", padding: 15,  },
  s6: {
    flexGrow: "1",
    verticalAlign: "bottom",
    border: "solid 0px red",
    display: "flex",
    justifyContent: "center",
    paddingTop: 15,
  },
  s7: { fontSize: "20px", paddingTop: "0px" },
  s8: { fontSize: "25px", color: "var(--color--text)" },
  s9: { paddingLeft: "10px", paddingRight: "10px" },
  s10: { fontSize: "20px", paddingTop: "0px" },
  s11: { fontSize: "25px", color: "var(--color--text)" },
  s12: {
    height: "30px",
    marginRight: "10px",
    right: "0px",
  },
  s13: {
    height: "30px",
    paddingRight: "5px",
    paddingLeft: "5px",
    margin: "2px",
    background: "transparent",
    color: "var(--color-text)",
  },
  s14: { fontSize: "22px" },
  s15: {
    height: "30px",
    paddingRight: "5px",
    paddingLeft: "5px",
    margin: "2px",
    background: "transparent",
    color: "var(--color-text)",
  },
  s16: { fontSize: "20px" },
  s17: {
    height: "30px",
    paddingRight: "5px",
    paddingLeft: "5px",
    margin: "2px",
    background: "transparent",
    color: "var(--color-text)",
  },
  s18: { fontSize: "20px" },
  s19: {
    height: "30px",
    paddingRight: "5px",
    paddingLeft: "5px",
    margin: "2px",
    background: "transparent",
    color: "var(--color-text)",
  },
  s20: { fontSize: "20px" },
  s21: {
    height: "30px",
    paddingRight: "5px",
    paddingLeft: "5px",
    margin: "2px",
    background: "transparent",
    color: "var(--color-text)",
  },
  s22: { fontSize: "22px" },
  s23: {
    height: "30px",
    paddingRight: "5px",
    paddingLeft: "5px",
    margin: "2px",
    background: "transparent",
    color: "var(--color-text)",
  },
  //s24: { fontSize: '22px', color: cssColorPublishButton },
  s25: { position: "fixed", top: "38px", right: "8px", zIndex: 2 },
  s26: { maxWidth: "400px" },
};
