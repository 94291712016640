import React, { Component } from "react";
import { isElectron } from 'react-device-detect';

import { Icon, Image, Loader } from "../../../common/component/Button";
import PropTypes from 'prop-types'
import noUserImage from '../../../../img/noimg.png'
import { getLocalFromNow } from '../../../../core/util/helpers'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import FactoryRequests from '../../../../core/util/FactoryRequests'
import {

    faInbox as icoPageHeader,
    faAngleDoubleUp as icoArrowUp,
    faLongArrowRight as icoArrowRight,
    faLongArrowLeft as icoArrowLeft

} from '@fortawesome/pro-duotone-svg-icons';


//import Arr from 'lodash/array'


let ipcRenderer

if (isElectron) {
    ipcRenderer = window.require('electron').ipcRenderer
}

var foo

class RequestList extends Component {
    //static contextTypes = { store: PropTypes.object.isRequired }
    state = { requestClick: {}, view: 0, ignorKeys: [] }

    update = () => {

        if (this.props.update)
            this.props.update()

        this.setState({})
    }
    componentDidMount = () => {
        //toTop() // DO we need this ? 20200505
        // //console.log('componentDidMount')
        foo = FactoryRequests(this.update)
        // const requestList = foo.getRequestList()
        // //console.log('Init !!', requestList)
        // if (requestList && requestList.length > -1) {

        //     const doc = requestList[0]
        //     const { user, userChanged, shiftChanged, shiftOrginal, schedualName, requestIsCurrentUsers } = foo.getListsItem(doc, this.state.view)
        //     // this.onItemClick({ s_schedualName: schedualName, s_user: user, s_userChanged: userChanged, s_shiftChanged: shiftChanged, s_shiftOrginal: shiftOrginal, s_doc: doc }, doc.id)()
        // }
        // else {
        //     this.setState({ selected: undefined })
        // }

    }
    componentDidUpdate = (prevProps, prevState, snapshot) => {


        if (this.props.handledKey)
            if (this.state.ignorKeys.indexOf(this.props.handledKey) === -1) {
                this.state.ignorKeys.push(this.props.handledKey)
                this.setState({ ignorKeys: this.state.ignorKeys })
            }

        if (!this.state.selected || (this.props.handledKey && this.props.handledKey === this.state.selected)) {
            const requestList = foo.getRequestList()

            if (requestList && requestList.length > 0) {
                {
                    const doc = requestList[0]
                    if (doc.id !== this.props.handledKey) {
                        const { user, userChanged, shiftChanged, shiftOrginal, schedualName, requestIsCurrentUsers } = foo.getListsItem(doc, this.state.view)
                        this.onItemClick({ s_schedualName: schedualName, s_user: user, s_userChanged: userChanged, s_shiftChanged: shiftChanged, s_shiftOrginal: shiftOrginal, s_doc: doc }, doc.id)()
                    }
                    //this.setState({ selected: doc.id })
                }
            }
        }





    }

    componentWillUnmount = () => {
        foo.destroy()
    }




    getTitle = (doc) => {
        switch (doc.n) {
            case 'adminrequest':
                return window._getText('request.adminRequest')
            case 'deviation':
                return window._getText('request.titleTime')
            case 'sick':
                return window._getText('request.titleSick')
            case 'change':
                switch (doc.data.ct) {
                    case 1:
                        return window._getText('request.titleCancle')
                    case 2:
                        return window._getText('request.titleChange')
                    case 3:
                        return window._getText('request.titleMove')
                    case 4:
                        return window._getText('request.titleSwap')
                    default:
                        return ''
                }
            case 'userrequestshift':
                return window._getText('request.titleUserrequestshift')
            case 'comment':
                return window._getText('request.titleComment')
            default:
                return ''
        }

    }

    onItemClick = (obj, id) => () => {
        this.setState({ selected: id })
        this.props.onClick(obj)

    }

    render() {

        if (!foo)
            return (<div style={{ textAlign: 'center', marginTop: '50px' }}><Loader inline active>{window._getText('loading')}</Loader></div>)


        const isClicked = false
        const requestList = foo.getRequestList() //get full list from factory ? 
        if (!requestList)
            return null



        let selectedKey = this.state.selected
        //console.log('selectedKey', selectedKey)



        return (

            <div className='tour_requests_1' style={{ overflow: 'auto', height: 'calc(100% - 50px)', paddingBottom: '10px', paddingLeft: 10 }} >

                {requestList.length === 0 &&
                    <div style={{ position: 'absolute', left: 'calc(50% - 70px)', textAlign: 'center', marginTop: '200px', color: 'var(--color--text)' }} className='dragWindow2'>
                        <Icon name='folder open outline' style={{ fontSize: '150px' }}></Icon><br />
                        {window._getText('message.in.empty')}
                    </div>
                }


                {
                    requestList.map((doc) => {
                        //const doc = rData[docKey]
                        const { user, userChanged, shiftChanged, shiftOrginal, schedualName, requestIsCurrentUsers } = foo.getListsItem(doc, this.state.view)


                        // if (this.state.selected === undefined) {

                        //     this.onItemClick({ s_schedualName: schedualName, s_user: user, s_userChanged: userChanged, s_shiftChanged: shiftChanged, s_shiftOrginal: shiftOrginal, s_doc: doc }, doc.id)()
                        //     return
                        // }

                        return (

                            <div key={doc.id} style={{ overflow: 'hidden', transition: 'all 0.25s ease-in-out', height: this.state.ignorKeys.indexOf(doc.id) == -1 ? '50px' : '0px' }} className={(doc.id === selectedKey ? 'listItemSelected' : 'listItem')}

                                onClick={this.onItemClick({ s_schedualName: schedualName, s_user: user, s_userChanged: userChanged, s_shiftChanged: shiftChanged, s_shiftOrginal: shiftOrginal, s_doc: doc }, doc.id)} >

                                {this.state.ignorKeys.indexOf(doc.id) == -1 ?
                                    <div style={{ position: 'relative', display: 'flex', margin: '5px 16px 4px 16px' }}>
                                        <div style={{ padding: '0px', fontWeight: 'normal', fontSize: '11px', color: 'var(--color--text)', position: 'absolute', top: '-6px', right: '-8px' }}>
                                            {doc.cdate && getLocalFromNow(moment(doc.cdate.seconds * 1000))}
                                        </div>
                                        {requestIsCurrentUsers ?
                                            <FontAwesomeIcon icon={icoArrowLeft} style={{ fontSize: '30px', paddingTop: '8px', marginLeft: '-10px', marginRight: '3px', color: 'rgba(33, 186, 69, 0.9)' }}></FontAwesomeIcon>
                                            :
                                            <FontAwesomeIcon icon={icoArrowRight} style={{ fontSize: '30px', paddingTop: '8px', marginLeft: '-10px', marginRight: '3px', color: 'rgba(33, 133, 208, 0.9)' }}></FontAwesomeIcon>
                                        }
                                        <Image circular src={user.img} alt='' style={{ margin: '0px', marginTop: '2px', marginRight: '8px', width: '35px', height: '35px' }} onError={(e) => { e.target.onerror = null; e.target.src = noUserImage }} />

                                        <div style={{ paddingTop: '0px' }}>
                                            <div style={{}}>{user.n}</div>

                                            <div style={{ fontSize: '12px', position: 'absolute', right: '-5px' }}>

                                                {(doc.n === 'adminrequest') && <Icon name='star' size='small' />}
                                                {(doc.n === 'userrequestshift') && <Icon name='add circle' size='small' />}
                                                {(doc.n === 'deviation') && <Icon name='tag' size='small' />}
                                                {(doc.n === 'comment') && <Icon name='comment' size='small' />}
                                                {(doc.n === 'change') && <Icon name='comments' size='small' />}
                                                {(doc.n === 'sick') && <Icon name='bed' size='small' />}

                                            </div>
                                            <span style={{ fontWeight: 'normal', color: 'var(--color--text)' }}> {this.getTitle(doc)}</span>

                                        </div>

                                    </div>

                                    :
                                    <div></div>
                                }

                            </div>
                        )
                    })
                }

            </div>




        )
    }
}


export default RequestList


