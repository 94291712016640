import shortid from 'shortid'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import { FireHelper } from '../util/FireHelper'
import object from 'lodash/object' //TODO REMOVE THIS ; change to Object.keys
const validE164 = (num) => {
    return /^\+?[1-9]\d{1,14}$/.test(num)
}

export const FactoryUser = {



    newUser: (d) => {
        return {

            //  newUser: true,
            isActive: true,
            name: "",
            email: "",
            phone: "",
            role: 50

        };
    },


    validateUser: async (userObj, oldData, userList, calling_code) => {

        return new Promise(async (resolve, reject) => {
            // console.log('oldData', oldData)
            // console.log('userObj', userObj)
            var response = { success: true, msg: '', userObj: { ...userObj } }


            if (!response.userObj.key) // if null its a new user!
                response.userObj.key = shortid.generate()



            if (response.userObj.isActive === undefined)
                response.userObj.isActive = true




            if (response.userObj.email && response.userObj.email.length > 0) {
                response.userObj.email = response.userObj.email.toLowerCase()
                if (response.userObj.email.indexOf('@') === -1) {
                    response.msg = 'notemail'
                    response.success = false
                    resolve(response)
                }

                let dupUserKey = object.findKey(userList, (u) => {
                    if (!u)
                        return false

                    return u.email === response.userObj.email
                })

                if (dupUserKey) {
                    var dupUser = userList[dupUserKey]
                    if (dupUser.key !== response.userObj.key || !response.userObj.key) {
                        response.msg = 'dup'
                        response.success = false
                        resolve(response)
                    }
                }
            }


            if (response.userObj.phone && response.userObj.phone.length > 0) {

                if (response.userObj.phone.startsWith('00'))
                    response.userObj.phone = response.userObj.phone.replace('00', '+')


                if (!response.userObj.phone.startsWith('+'))
                    response.userObj.phone = '+' + (calling_code || '') + response.userObj.phone


                const parsedNumber = parsePhoneNumberFromString(response.userObj.phone)
                if (parsedNumber) {
                    response.userObj.phone = parsedNumber.number
                }
                else {
                    response.msg = 'invalidPhone'
                    response.success = false

                    resolve(response)
                }

            }


            if (response.userObj.phone && response.userObj.phone.length > 0) {
                //console.log('validate phone')
                ////console.log('userList', this.props.userList)
                // //console.log('response.userObj.email', response.userObj.email)
                let dupUserPhoneKey = object.findKey(userList, (u) => {
                    if (!u)
                        return false

                    return u.phone === response.userObj.phone
                })
                // //console.log('userWithSameEmail', dupUserKey)
                // //console.log('response.userObj.key', response.userObj.key)
                if (dupUserPhoneKey) {
                    var dupPhoneUser = userList[dupUserPhoneKey]
                    if (dupPhoneUser.key !== response.userObj.key || !response.userObj.key) {
                        response.msg = 'dupPhone'
                        response.success = false
                        //console.log('dubp phone11')
                        resolve(response)
                    }

                }

            }
            //console.log('03', response.userObj.phone)

            if (response.userObj.phone && validE164(response.userObj.phone) === false) {
                response.msg = 'invalidPhone'
                response.success = false
                resolve(response)
            }
            //console.log('04', response.userObj.phone)

            if (response.userObj.phone && response.userObj.phone.startsWith('+') === false) {
                response.msg = 'invalidPhone2'
                response.success = false

                resolve(response)
            }
            if (response.userObj.phone && response.userObj.phone.length > 0 && oldData.phone !== response.userObj.phone) {
                //console.log('06.1')
                var isPhoneFree = await FireHelper.isLinkFree(response.userObj.phone)
                if (isPhoneFree.success === false) {
                    response.msg = 'dupPhone2'
                    response.success = false

                    resolve(response)
                }
            }

            //console.log('05')

            //console.log('response.userObj.email', response.userObj.email)
            // console.log('oldData.email', oldData.email)
            if (response.userObj.email && response.userObj.email.length > 5 && oldData.email !== response.userObj.email && response.userObj.email.indexOf('@') > 0) {
                //   console.log('05-1')

                var isEmailFree = await FireHelper.isLinkFree(response.userObj.email)
                // console.log('05-1after ', isEmailFree)
                if (isEmailFree.success === false) {
                    response.msg = 'dup2'
                    response.success = false

                    resolve(response)
                }
            }

            //console.log('06')
            //console.log('06 response.userObj.phone', response.userObj.phone)
            //console.log('06 oldData.phone', oldData.phone)




            //console.log('07')
            // if (this.props.userObj.newUser)
            //     response = await this.props.addUser({ ...response.userObj, newUser: false });
            // else
            //     response = await this.props.updateUser(response.userObj);

            ////console.log('response', response)


            // // TODO ONLY ON JOY
            // window.dispatchEvent(new CustomEvent("onJoyStep", { detail: { step: 1 } }));



            // this.props.reset()
            resolve(response)

        })
    },
    cleanUser: (userObj) => {
        //remove data that we dont want in the DB
        var _userObj = { ...userObj }
        return _userObj
    },

    validatePhone: (phone, calling_code) => {
        console.log('calling_code', calling_code)
        var response = { success: true, msg: '', phone }
        return new Promise(async (resolve, reject) => {
            if (phone && phone.length > 0) {

                if (phone.startsWith('00'))
                    phone = phone.replace('00', '+')


                if (phone.startsWith('0'))
                    phone = phone.replace('0', '')

                console.log('phone', phone)
                console.log('calling_code>>>', calling_code)
                if (!phone.startsWith('+')) {
                    console.log('add +++')
                    phone = `+${calling_code}${phone}`
                }


                const parsedNumber = parsePhoneNumberFromString(phone)
                if (parsedNumber) {
                    phone = parsedNumber.number
                }
                else {
                    response.msg = 'invalidPhone'
                    response.success = false
                    response.phone = phone
                    resolve(response)
                }

            }



            if (phone && validE164(phone) === false) {
                response.msg = 'invalidPhone'
                response.success = false
                response.phone = phone
                resolve(response)
            }
            //console.log('04', phone)

            if (phone && phone.startsWith('+') === false) {
                response.msg = 'invalidPhone2'
                response.success = false
                response.phone = phone
                resolve(response)
            }
            if (phone && phone.length > 0) {
                //console.log('06.1')
                var isPhoneFree = await FireHelper.isLinkFree(phone)
                if (isPhoneFree.success === false) {
                    response.msg = 'dupPhone2'
                    response.success = false
                    response.phone = phone
                    resolve(response)
                }
            }
            response.phone = phone
            resolve(response)
        }
        )
    },

    // validateAndClean: (shift) => {
    //     var _shift = { ...shift }
    //     _shift = FactoryUser.cleanShift(_shift)
    //     _shift = FactoryUser.validateShift(_shift)
    //     return _shift
    // },


}

