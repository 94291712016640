import { SET_WEEKS_TO_SHOW, TOGGLE_TEMPLATES, ORIENTATION, RENDER_DATE, RENDER_DATE_ADD_DAYS, RENDER_DATE_NOW, RENDER_INIT, KEYBOARD_OPEN, SET_NEVER, SET_FLATLIST_INDEX, CLONE_SHIFT, SET_LEFT_MENU_OPEN } from '../action/viewstatAction'
import { createReducer } from '../../util/reducerUtil'
import moment from 'moment'
import { getDateKey } from '../../util/helpers'
const initState = {
    weeksToShow: 1,
    template: false,
    isLandscape: true,
    firstDayOfWeek: 0,
    isKeyboardOpen: false,
    renderDate: null,
    renderDateKey: null,
    neverSavedShift: true,
    neverAddedUser: true,
    neverAddedSchedual: true,
    neverSentInvite: true,
    flatListIndex: 52,
    cloneShift: false,
    isLeftMenuOpen: true
}
export const initRenderDate = (state, payload) => {
    //console.log('initRenderDate')
    const firstDayOfWeek = payload
    const utc = new Date();//.toJSON().slice(0, 10).replace(/-/g, '');
    if (firstDayOfWeek === 0)
        moment.locale('en')
    else
        moment.locale('en-gb')


    var renderDate = moment.utc(utc).startOf('week');
    const dateKey = getDateKey(renderDate)
    return { ...state, initRenderDate: renderDate, renderDate: renderDate, renderDateKey: dateKey, firstDayOfWeek }
}

export const setRenderNow = (state, payload) => {
    const firstDayOfWeek = state.firstDayOfWeek// payload
    const utc = new Date();//.toJSON().slice(0, 10).replace(/-/g, '');
    if (firstDayOfWeek === 0)
        moment.locale('en')
    else
        moment.locale('en-gb')


    var renderDate = moment.utc(utc).startOf('week');
    const dateKey = getDateKey(renderDate)
    return { ...state, renderDate: renderDate, renderDateKey: dateKey }
}
export const setRenderDate = (state, payload) => {
    const rDate = moment(payload)
    const dateKey = getDateKey(rDate)
    return { ...state, renderDate: rDate, renderDateKey: dateKey }
}

export const renderDateAddDays = (state, payload) => {
    const rDate = moment.utc(state.renderDate).add(payload, 'days')
    const dateKey = getDateKey(rDate)

    return { ...state, renderDate: rDate, renderDateKey: dateKey }
}
export const toggleTemplates = (state, payload) => {
    return { ...state, template: !state.template }
}

export const setOrientation = (state, payload) => {
    return { ...state, isLandscape: payload }
}
export const setKeyboardOpen = (state, payload) => {
    return { ...state, isKeyboardOpen: payload }
}

export const setNerver = (state, payload) => {

    return { ...state, [payload.action]: payload.value }
}

export const setFlatlistIndex = (state, payload) => {
    return { ...state, flatListIndex: payload }
}

export const cloneShift = (state, payload) => {
    return { ...state, cloneShift: payload }
}
export const setLeftMenuOpen = (state, payload) => {

    return { ...state, isLeftMenuOpen: payload }
}
export const setWeeksToShow = (state, payload) => {

    return { ...state, weeksToShow: payload }
}
export default createReducer(initState, {
    [TOGGLE_TEMPLATES]: toggleTemplates,
    [ORIENTATION]: setOrientation,
    [RENDER_DATE]: setRenderDate,
    [RENDER_DATE_ADD_DAYS]: renderDateAddDays,
    [RENDER_DATE_NOW]: setRenderNow,
    [RENDER_INIT]: initRenderDate,
    [KEYBOARD_OPEN]: setKeyboardOpen,
    [SET_NEVER]: setNerver,
    [SET_FLATLIST_INDEX]: setFlatlistIndex,
    [CLONE_SHIFT]: cloneShift,
    [SET_LEFT_MENU_OPEN]: setLeftMenuOpen,
    [SET_WEEKS_TO_SHOW]: setWeeksToShow

})






