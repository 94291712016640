import React, { Component } from 'react'
import { Checkbox } from "../component/Button";


export default class InputToggle extends Component {
  render() {
    var { input: { value, onChange } } = this.props


    if (value === '')
      value = true

    return (
      <Checkbox toggle checked={value} onClick={() => onChange(!value)}   ></Checkbox>
    )
  }
}
