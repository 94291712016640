import React, { Component } from "react";


import { withRouter } from "react-router-dom";

import { connect } from 'react-redux'
import { osName } from 'react-device-detect';
import { toastr } from 'react-redux-toastr'
import { setOnlinePresence, auth } from './core/config/firebase';


import "./css/App.css";
//import imgLogo from './img/48x48.png'
import { isMobile, isElectron, isBrowser } from 'react-device-detect';
import { version } from '../package.json'
import PropTypes from 'prop-types'
import AppRouter from "./app/pages/router/AppRouter";
import LoginRouter from "./app/pages/router/LoginRouter";
//import { fetchUser } from './app/redux/action/authUserAction';
import { setOrientation, setKeyboardOpen, setLeftMenuOpen } from './core/redux/action/viewstatAction';
import { FireHelper } from './core/util/FireHelper'
import { getUserImage } from './core/util/helpers'
import noUserImage from './img/noimg.png'
//import EditEventModal from './app/pages/modal/EditEventModal';
import { START_NOTIFICATION_SERVICE, NOTIFICATION_SERVICE_STARTED, NOTIFICATION_SERVICE_ERROR, NOTIFICATION_RECEIVED as ON_NOTIFICATION_RECEIVED, TOKEN_UPDATED, } from "./pushConstants.js"
import WebMenu from './electron/WebMenu'






const shortid = require('shortid');




require('moment/min/locales.min') //we need this , locale else we always have EN locale



let ipcRenderer
let shell


if (isElectron) {
  const elApp = window.require('electron')
  ipcRenderer = elApp.ipcRenderer
  shell = elApp.shell
  // dialog = elApp.remote.dialog



  // console.log('window.location.port', window.location.port)
  // console.log(window.location.port !== 3000)
  // console.log(window.location.port !== '3000')
  // console.log(typeof window.location.port)




  if (window.location.port !== '3000' || true) {
    console.log('init sentry')
    require('@sentry/browser').init({
      dsn: 'https://30cf2791642a407b8b09711dfbde57a5@sentry.io/1782761',
      enableInExpoDevelopment: false,
      debug: false,
      // beforeSend(err) {
      //   console.log('BERFOER SDMEMD', err)
      //   return err
      // },
      //enableNative: false
    })


  }






}






class App extends Component {
  static contextTypes = { router: PropTypes.object }
  state = { loggedIn: false, haveUser: false, oldVersion: false, token: null, refresh: 0 }

  constructor(props) {
    super(props)
    //window.onanimationiteration = console.log;


    // document.addEventListener("dragenter", (event) => {
    //   document.body.classList.add("drag");
    // });

    // document.addEventListener("dragleave", (event) => {
    //   document.body.classList.remove("drag");

    // });


    if (ipcRenderer) {
      ipcRenderer.on('appCmd', this.appCmd)


      ipcRenderer.on(NOTIFICATION_SERVICE_STARTED, (_, token) => this._getToken(token));
      // Handle notification errors
      ipcRenderer.on(NOTIFICATION_SERVICE_ERROR, (_, error) => console.log('NOTIFICATION_SERVICE_ERROR', error));
      // Send FCM token to backend
      ipcRenderer.on(TOKEN_UPDATED, (_, token) => this._onTokenRefresh(token));
      // Display notification
      ipcRenderer.on(ON_NOTIFICATION_RECEIVED, (_, notification) => this._onMessagePush(notification));

    }


    auth.onIdTokenChanged(async user => {
      // console.log('>>>>>>>auth.onIdTokenChanged', !!user)
      if (user) {
        // const idTokenResult = await FireHelper.reloadToken(false)
        // console.log('user', user)
        // console.log('idTokenResult.claims.pKey', idTokenResult.claims.pKey)
        // console.log('idTokenResult.claims.role', idTokenResult.claims.role)
        // console.log('this.props.currentUser.isEmpty', this.props.currentUser.isEmpty)
        // console.log('this.props.currentUser ', this.props.currentUser)
        // console.log('this.props.currentUser.isLoaded', this.props.currentUser.isLoaded)
        // console.log('user exists', user)

        user.getIdTokenResult()
          .then((idTokenResult) => {
            // Confirm the user is an Admin.
            if (idTokenResult.claims) {
              // console.log('>>>>>>>idTokenResult.claims.pKey', idTokenResult.claims.pKey)
              // console.log('>>>>>>>idTokenResult.claims.role', idTokenResult.claims.role)
              // We will call the function here
              if (idTokenResult.claims.pKey && idTokenResult.claims.role > 50)
                this.setState({ haveUser: true })
              else {
                // Show something else 
                if (this.props.currentUser && this.props.currentUser.isEmpty === true && this.props.currentUser.isLoaded === false) {

                  // console.log(this.props.authUser)
                  // console.log('Broken')
                  // auth.signOut()
                }
                //  console.log('>>>>>>>WRONG  TOKEN', this.props.currentUser)
              }
            }
            else {
              console.log('>>>>>>> no claims exists')
            }

          })
          .catch((error) => {
            console.log('>>>>>>>', error);
          });

      } else {
        // console.log('>>>>>>>user DOMNT exists')
        this.setState({ haveUser: false })
      }
    });


  }

  openAppStoreUpdate = () => {
    try {
      if (shell)
        shell.openExternal('itms-apps://itunes.apple.com/app/itunes-u/id1457815576')

    } catch (error) {

    }

  }

  openRatingDialog = () => {

    localStorage.setItem('openRatingDialog', 1)


    try {
      if (shell)
        shell.openExternal('itms-apps://itunes.apple.com/app/itunes-u/id1457815576?action=write-review')
    } catch (error) {

    }
  }
  onNotificationOpen = (notification) => {
    //todo check if its in the forground or not!
    console.log('onNotificationOpen')
    this.props.history.push('/chat')
  }

  _onTokenRefresh = (token) => {
    // TODO ADD TO redux
    // TODO REMOVE OLD TOKEN!
    //console.log('onTokenRefresh', token)
    window._webToken = token
    this.setState({ token: token })
  }

  onTokenRefresh = (e) => {
    // TODO ADD TO redux

    this._onTokenRefresh(e.detail)
  }


  _getToken = (token) => {
    //  console.log('_getToken', token)
    window._webToken = token
    this.setState({ token: token })
  }


  getToken = (e) => {
    // TODO ADD TO redux
    //console.log('getToken', e.detail)
    this._getToken(e.detail)
  }

  sendNotification_timer = null
  sendNotification_counter = 0
  sendNotification = (notfy) => {


    if (this.sendNotification_counter === 0) {
      this.sendNotification_counter = 1
      let myNotification = new Notification(notfy.title, notfy)
      myNotification.onclick = () => { ipcRenderer.send('show:requests') }
      this.sendNotification_timer = setTimeout(() => {
        this.sendNotification_counter = 0
        console.log('clear...')
      }, 30000);
    }
    else {
      this.sendNotification_counter++
      console.log('trottle send', this.sendNotification_counter)

    }
  }
  GetTitle = (doc) => {
    switch (doc.n) {
      case 'adminrequest':
        return window._getText('request.adminRequest')
      case 'deviation':
        return window._getText('request.titleTime')
      case 'sick':
        return window._getText('request.titleSick')
      case 'change':
        switch (doc.data.ct) {
          case 1:
            return window._getText('request.titleCancle')
          case 2:
            return window._getText('request.titleChange')
          case 3:
            return window._getText('request.titleMove')
          case 4:
            return window._getText('request.titleSwap')
          default:
            return ''
        }
      case 'userrequestshift':
        return window._getText('request.titleUserrequestshift')
      case 'comment':
        return window._getText('request.titleComment')
      default:
        return ''
    }

  }
  _onMessagePush = (payload) => {
    //  console.log('_onMessagePush')
    const { currentUser } = this.props
    if (currentUser) {
      //  console.log('onMessagePush>not>', payload)
      // console.log('currentUser.push')
      if (currentUser.push !== false) {

        if (payload.data && currentUser.key === payload.data.uk) {
          if (payload.notification.body) {
            // payload has a body, so show it to the user
            toastr.success(payload.notification.title || '', payload.notification.body || '');

            if (document.hasFocus() === false) {
              const doc = JSON.parse((payload.data || {}).doc)

              const { project } = this.props
              const cache = project.cache || {}
              const senderUser = cache[doc.uk]

              var notfy = { ...payload.notification }


              if (senderUser && senderUser.i && senderUser.sk && currentUser.projectKey) {
                if (notfy.body === "You have a new request") {
                  notfy.title = `${senderUser.n}`
                  notfy.body = this.GetTitle(doc)
                  notfy.icon = senderUser.i ? getUserImage(currentUser.projectKey, doc.uk, senderUser.i) : noUserImage
                }
              }
              this.sendNotification(notfy)
            }
          } else {
            // payload has no body, so consider it silent (and just consider the data portion)
            // console.log('do something with the key/value pairs in the data', payload.data)
          }
        }
      }
      else {
        console.log('User dont want push msg')
      }
    }
  }

  onMessagePush = (payload) => {
    console.log('onMessagePush>not>', payload)
    if (payload.detail)
      payload = payload.detail
    this._onMessagePush(payload)

  }
  onGoTo = ({ detail: { page } }) => {

    this.props.history.push(page)
  }

  appCmd = (e, cmd) => {
    if (cmd === 'clear')
      this.props.history.push('/signout')
    else
      window.dispatchEvent(new CustomEvent(cmd.cmd, { detail: { ...cmd.arg } }));
  }

  resizeWindowOldValue
  resizeWindow = () => {
    const newValue = (window.innerWidth > 860)

    //console.log('window.innerWidth', window.innerWidth)

    if (newValue !== this.resizeWindowOldValue) {
      this.resizeWindowOldValue = newValue
      this.props.setLeftMenuOpen(newValue)
    }


  }

  componentDidMount = () => {

    // // console.log('window.FirebasePlugin',window.FirebasePlugin)
    window.addEventListener("onGoTo", this.onGoTo)
    window.addEventListener("onMessagePush", this.onMessagePush)
    window.addEventListener("onNotificationOpen", this.onNotificationOpen)
    window.addEventListener("onTokenRefresh", this.onTokenRefresh)
    window.addEventListener("getToken", this.getToken)
    window.addEventListener("resize", this.resizeWindow);


    if (ipcRenderer) {
      // Start service
      ipcRenderer.send(START_NOTIFICATION_SERVICE, window._messagingSenderId);
    }




    setTimeout(() => {


      var urlVersion = `https://${window._iapUrl}/getCurrentVersion`
      fetch(urlVersion, { method: 'Post', headers: { 'Content-Type': 'application/json' } })
        .then(async r => r.json())
        .then(
          (result) => {
            console.log('cloud result', result)
            console.log('local version', version)
            console.log('result.v > version', result.v > version)
            if (result && result.v)
              if (result.v > version) {
                //   this.setState({ oldVersion: true })
                setTimeout(() => {
                  if (window.confirm(window._getText('NewVersion'))) {
                    this.openAppStoreUpdate()
                  }

                }, 5000);
                console.log('version DONT match')
              }
          },
          (error) => console.log('#err 5jdu7 ', error)
        )

    }, 1000);
    //}

    // setTimeout(() => {
    //   this.openRatingDialog()
    // }, 10000);

  }
  componentWillUnmount = () => {
    //console.log('componentWillUnmount')

    if (ipcRenderer)
      ipcRenderer.removeListener('appCmd', this.appCmd)


    if (this.sendNotification_timer)
      clearTimeout(this.sendNotification_timer)

  }

  calcVH = () => {
    var vH = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
    document.documentElement.style.setProperty('--vh', vH + 'px');
  }

  //isKeyboardOpen = false



  //reFreshTimer




  componentDidUpdate = async (prevProps, prevState, snapshot) => {


  }



  doOnce = false
  onDisconnectRef
  render() {
    const { currentUser, project } = this.props

    if (this.state.haveUser && currentUser && currentUser.projectKey
      && window.location.pathname !== '/newschedule'
      && window.location.pathname !== '/newschedule2'
      && window.location.pathname !== '/newuser'
      && window.location.pathname !== '/login'
      && window.location.pathname !== '/setup') {
      if (this.doOnce === false && project) {

        try {
          setOnlinePresence(currentUser.key, currentUser.projectKey, osName, currentUser).then(connection => { this.onDisconnectRef = connection })
        } catch (error) {

        }
        this.doOnce = true;
      }
      return (
        <React.Fragment>
          {this.state.oldVersion &&
            <div onClick={this.openAppStoreUpdate} style={{ textAlign: 'center', left: '0px', right: '0px', fontSize: '10px', position: 'absolute', top: '-2px', zIndex: '999999999999' }}>
              <span style={{ backgroundColor: '#eff7b2', borderRadius: '5px', paddingLeft: '10px', paddingRight: '10px', paddingTop: '10px', paddingBottom: '3px' }}>
                There is a new version out. Please go to the App Store to update.
              </span>
            </div>
          }
          {isElectron === false && isBrowser &&
            <WebMenu></WebMenu>
          }
          <AppRouter token={this.state.token} currentUser={currentUser} haveUser={this.state.haveUser} />
        </React.Fragment>
      );
    }

    if (this.props.currentUser.isLoaded === false && window.location.pathname !== '/newschedule' && window.location.pathname !== '/newschedule2' && window.location.pathname !== '/newuser' && window.location.pathname !== '/login' && window.location.pathname !== '/setup') {
      this.doOnce = false;
      return ('')

    }


    if (this.onDisconnectRef && this.doOnce === true) {
      //TEMP REMOVED , need to figure out how to fire onDiconnect manualy 
      //this.onDisconnectRef[0].cancel();
      //this.onDisconnectRef[1].remove()
      this.onDisconnectRef = null;
    }
    this.doOnce = false;

    //if we dont have a project go to login router

    return (
      <LoginRouter currentUser={currentUser} haveUser={this.state.haveUser} refresh={this.state.refresh} />
    )

  }
}


const mapStateToProps = (state) => {
  return {
    isLandscape: state.viewstate.isLandscape,
    authUser: state.firebase.auth,
    currentUser: state.firebase.profile,
    project: state.firestore.data.project,


  };
}
const mapActions = { setOrientation, setKeyboardOpen, setLeftMenuOpen }
//
export default withRouter(connect(mapStateToProps, mapActions)(App))


