import React, { Component } from 'react'
import { connect } from 'react-redux'
//import Form from 'semantic-ui-react/dist/commonjs/collections/Form'
import { Button } from '../../../common/component/Button'
import { Field, reduxForm, change } from "redux-form";
import moment from 'moment'
import TimePicker from '../../../common/form/TimePicker'
import { FireHelper } from '../../../../core/util/FireHelper'



const dType = 'deviation'
export class CtrlTimeDeviation extends Component {
    state = { start: moment().format('HH:mm'), stop: moment().format('HH:mm') }
    timerTime = null
    componentDidMount = () => {
        // this.timerTime = setInterval(() => {
        //     console.log('update')
        //     this.setState({ start: moment().format('HH:mm'), stop: moment().format('HH:mm') })
        // }, 60000);
    }
    componentWillUnmount = () => {
        clearInterval(this.timerTime)
    }
    componentDidUpdate = (prevProps, prevState, snapshot) => {
        if (prevProps.doSave === false && this.props.doSave === true) {
            this.props.handleSubmit(this.onSubmit)()
            this.props.resetSave()
        }
    }

    saveData = (values) => {
        //isActive

        // if (values.isActive === undefined)
        //     values.isActive = true


        // if (this.props.userObj.newUser)
        //     this.props.addUser({ ...values, newUser: false });
        // else
        //     this.props.updateUser(values);

        // this.props.reset()
    }
    onSubmit = values => {
        console.log('onSubmit')
        const { projectKey, data, project } = this.props
        const uKey = this.props.currentUser.inSysUserKey
        const settings = (project || {}).s || { dev: false }

        var dataIn = { t1: values.start, t2: values.stop, sh: { ...data.shift } }

        if (dataIn.sh.k)
            delete dataIn.sh.k

        if (values.comment)
            dataIn.com = values.comment







        if (settings.dev === true) {
            //TODO WE ONLY WANT THIS ON ONE PLACE
            var startDate = moment.utc(`${data.shift.d} ${dataIn.t1}`)
            var stopDate = moment.utc(`${data.shift.d} ${dataIn.t2}`)
            var duration = moment.duration(stopDate.diff(startDate));
            var minutes = duration.asMinutes();
            startDate = startDate.utc().unix() * 1000
            stopDate = stopDate.utc().unix() * 1000
            var _break = data.shift.b || 0


            var newData = {
                minutes: minutes - _break,
                startDate,
                stopDate,
                timeStart: dataIn.t1,
                timeEnd: dataIn.t2,
                userCom: dataIn.com || ''
            }
            // console.log('newData', newData)
            FireHelper.setShiftMetaData(projectKey, uKey, data.sKey, this.props.renderDateKey, data.shift.k, dType, dataIn, true, newData)
        }
        else {
            FireHelper.setShiftMetaData(projectKey, uKey, data.sKey, this.props.renderDateKey, data.shift.k, dType, dataIn, true)
        }
        this.props.onClose()

    };
    round5(h, m) {
        var timeM = '00'
        var timeH = h
        var val = Math.round(m / 5) * 5;
        if (val > 59) {
            val = 0
            timeH++
            if (timeH > 23)
                timeH = 0
        }
        if (val < 10)
            timeM = `0${val}`
        else
            timeM = val.toString()

        return `${timeH}:${timeM}`
    }
    setNow1 = (e, props) => {
        const name = 'start'
        const val = this.round5(moment().format('HH'), moment().format('mm'))
        this.props.dispatch(change('ctrlTimeDeviation', name, val));
    }
    setNow2 = (e, props) => {
        const name = 'stop'
        const val = this.round5(moment().format('HH'), moment().format('mm'))
        this.props.dispatch(change('ctrlTimeDeviation', name, val));
    }

    scrolling = (e, c) => {
        e.scrollIntoView();
        if (c < 5) setTimeout(this.scrolling, 300, e, c + 1);
    }
    ensureVisible = (e) => {
        setTimeout(this.scrolling, 300, e, 0);
    };

    render() {
        // console.log('this.props.doSave', this.props.doSave)
        return (
            <React.Fragment>
                <div style={{ display: 'flex', height: '105px' }}>
                    <div >

                        <Field
                            AmPm={this.props.AmPm}
                            onChangeTime={(value) => this.props.dispatch(change('ctrlTimeDeviation', 'start', value))}
                            name="start"
                            component={TimePicker}
                            type="text"
                            placeholder="00:00"
                        />
                    </div>
                    <div >
                        <label>{'\u00A0'}</label>
                        <Button name='start' style={{ padding: '6px', marginTop: '30px', height: '78px' }} onClick={this.setNow1} content={window._getText('now')}>  </Button>
                    </div>


                    <div style={{ marginLeft: '20px', }} >
                        <Field
                            AmPm={this.props.AmPm}
                            onChangeTime={(value) => this.props.dispatch(change('ctrlTimeDeviation', 'stop', value))}
                            name="stop"
                            component={TimePicker}
                            type="text"
                            placeholder="00:00"
                        />
                    </div>
                    <div >
                        <label>{'\u00A0'}</label>
                        <Button name='stop' color='blue' style={{ padding: '6px', marginTop: '30px', height: '78px' }} onClick={this.setNow2} content={window._getText('now')}> </Button>
                    </div>
                </div>
                <div style={{ width: '100%', flexGrow: '1', marginTop: '10px', display: 'flex' }}  >
                    <Field
                        // onFocus={this.ensureVisible}
                        autoComplete="new-password"
                        name="comment"
                        component={'textarea'}
                        type="text"
                        placeholder={window._getText('shiftuserinput.comment')}
                        rows={2}
                        maxLength={200}
                        style={{ width: '100%', flexGrow: '1', }}
                    />

                </div>
            </React.Fragment>


        )
    }
}

const mapStateToProps = state => {
    const data = state.modal.modals['shiftuserinput'].data || {}
    //const label = data.l // TODO we need to ship the dates if they exists 
    let start = data.shift ? data.shift.t1 : '08:00' // TODO if wee have a date get it here 
    let stop = data.shift ? data.shift.t2 : '16:00' // TODO if wee have a date get it here 
    let comment = ''


    if (data.meta && data.meta[dType]) {
        start = data.meta[dType].t1
        stop = data.meta[dType].t2
        comment = data.meta[dType].com || ''
    }

    return {
        data,
        renderDateKey: state.viewstate.renderDateKey,
        projectKey: state.firebase.profile.projectKey,
        project: state.firestore.data.project,
        currentUser: state.firebase.profile,
        enableReinitialize: true,
        initialValues: { start, stop, comment: comment },
        formState: state.form.ctrlTimeDeviation,
        AmPm: state.firestore.data.project.s ? state.firestore.data.project.s.AmPm || false : false

    }
}

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({ form: "ctrlTimeDeviation" })(CtrlTimeDeviation))


