import { createStore, applyMiddleware, compose } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { reactReduxFirebase, getFirebase } from "react-redux-firebase";

import { reduxFirestore, getFirestore } from "redux-firestore";
import thunk from "redux-thunk";
import rootReducer from "../reducers/rootReducer";
import firebase from "../../config/firebase";


const rrfConfig = {
  userProfile: "users",
  logErrors: true,
  attachAuthIsReady: true,
  useFirestoreForProfile: true,
  updateProfileOnLogin: true,
  enableRedirectHandling: false // new do we need this ? 
};

export const configStore = preloadedState => {
  const middlewares = [thunk.withExtraArgument({ getFirebase, getFirestore })];
  const middelwareEnhancer = applyMiddleware(...middlewares);
  const storeEnhancers = [middelwareEnhancer];
  let composeEnhancers;


  if (process.env.NODE_ENV === 'development') {
    console.log('#REDUX>composeWithDevTools')
    composeEnhancers = composeWithDevTools(
      ...storeEnhancers,
      reduxFirestore(firebase, rrfConfig),
      reactReduxFirebase(firebase, rrfConfig),

    )
  } else {
    //console.log('#REDUX>compose')
    composeEnhancers = compose(
      ...storeEnhancers,
      reduxFirestore(firebase, rrfConfig),
      reactReduxFirebase(firebase, rrfConfig),

    )
  }


  const store = createStore(rootReducer, preloadedState, composeEnhancers);

  if (process.env.NODE_ENV !== "production") {
    if (module.hot) {
      module.hot.accept("../reducers/rootReducer.js", () => {
        const newRootReducer = require("../reducers/rootReducer").default;
        store.replaceReducer(newRootReducer);
      });
    }
  }

  return store;
};


export const store = configStore()