import React, { useState, useEffect, useRef } from "react";
import { toastr } from "react-redux-toastr";
import { useDispatch } from "react-redux";
import { FireHelper } from "../../../core/util/FireHelper";
import shortid from "shortid";
import SelectUsers from "../../pages/modal/SelectUsers";
import { getUserImage } from "../../../core/util/helpers";
import noUserImage from "../../../img/noimg.png";
import { store } from "../../../core/redux/store/configStore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Icon } from "../../common/component/Button";
import {
  faCheck as icoCheck,
  faTrashAlt as icoTrash,
  faTrashAlt as icoRemove,
  faPlay as icoPublish,
  faStop as icoUnpublish,
  faUndoAlt as icoRestart,
} from "@fortawesome/pro-duotone-svg-icons";
import s from "./style";

let startSaveTimer;
export default function TaskView({
  task,
  callbackOnRemove,
  callbackOnUpdate,
  callBackOnUnload,
}) {
  if (!task)
    return (
      <div style={{ padding: 100, textAlign: "center" }}>
        <Icon
          name="folder open outline"
          style={{ fontSize: "150px", marginTop: "50px" }}
        ></Icon>
        <br />

        {window._getText("TaskStartInfo")}
      </div>
    );

  const useFocus = () => {
    const htmlElRef = useRef(null);
    const setFocus = () => {
      htmlElRef.current && htmlElRef.current.focus();
    };

    return [htmlElRef, setFocus];
  };
  const dispatch = useDispatch();
  const [prevtask, setPrevtask] = useState({ key: "" });
  const [tbNewTask, setTbNewTask] = useState("");
  const [selectStatus, setSelectStatus] = useState(task.status || 10);
  const [tbTitle, setTbTitle] = useState(task.title || "");
  const [tbDescription, setTbDescription] = useState(task.description || "");
  const [tbFeedback, setTbFeedback] = useState(task.feedback || "");
  const [inputRef, setInputFocus] = useFocus();

  useEffect(() => {
    if (task.status !== selectStatus) onSave();
    else if (task.title !== tbTitle) startSave();
    else if (task.description !== tbDescription) startSave();
  }, [selectStatus, tbDescription, tbTitle]);

  useEffect(() => {
    if (task.key === prevtask.key) {
      return;
    }

    setPrevtask(task);
    // if (task.key !== taskKey) {
    //     //clear all
    //     setSelectStatus(10)
    //     setTbDescription('')
    //     setTbFeedback('')
    //     setTbNewTask('')
    //     setTbTitle('')
    //     setTaskKey(task.key)
    //     return
    // }
    setTbNewTask("");
    //if (task.status !== selectStatus)
    setSelectStatus(task.status || 10);

    //if (task.title !== tbTitle)
    setTbTitle(task.title || "");

    //if (task.description !== tbDescription)
    setTbDescription(task.description || "");

    //if (task.feedback !== tbFeedback)
    setTbFeedback(task.feedback || "");
    setInputFocus();
  }, [task]);

  useEffect(() => {
    if (callBackOnUnload) return callBackOnUnload;
  }, []);

  const startSave = () => {
    //  console.log('oko', startSaveTimer)
    if (startSaveTimer) {
      //    console.log('clear rimere', startSaveTimer)
      clearTimeout(startSaveTimer);
    }
    // console.log('startSaveTimer', startSaveTimer)
    startSaveTimer = setTimeout(() => {
      //   console.log('timerSave...')
      onSave();
    }, 1000);
    // console.log('startSaveTimer', startSaveTimer)
  };

  const onSave = () => {
    let state = store.getState();

    let newTask = {
      ...task,
      description: tbDescription || "",
      feedback: tbFeedback || "",
      title: tbTitle || "",
      status: selectStatus,
      uk: state.firebase.profile.inSysUserKey,
    };
    FireHelper.addOrUpdateTask(newTask, state.firebase.profile).then((x) => {
      if (callbackOnUpdate) callbackOnUpdate(task.key);
    });
  };
  // const onRemove = (e) => {
  //     e.preventDefault()
  //     if (window.confirm('Are you sure you want to delete this task')) {

  //         let state = store.getState()
  //         FireHelper.addOrUpdateTask({ key: task.key, delete: true }, state.firebase.profile).then(x => {
  //             if (callbackOnRemove)
  //                 callbackOnRemove()
  //         })
  //     }
  // }
  const addSubTask = (e) => {
    e.preventDefault();

    if (!tbNewTask) return;

    let state = store.getState();

    let newTask = {
      ...task,
      description: tbDescription || "",
      feedback: tbFeedback || "",
      title: tbTitle || "",
      status: selectStatus,
      uk: state.firebase.profile.inSysUserKey,
    };
    newTask.subTasks = [
      ...(newTask.subTasks || []),
      { text: tbNewTask, status: 0, key: shortid.generate() },
    ];
    setTbNewTask("");

    FireHelper.addOrUpdateTask(newTask, state.firebase.profile).then((x) => {
      if (callbackOnUpdate) callbackOnUpdate(task.key);
    });
  };
  const onRemoveTask = (key) => (e) => {
    if (window.confirm(window._getText("AreYouSureDeleteTask"))) {
      let state = store.getState();
      FireHelper.addOrUpdateTask(
        { key: key, delete: true },
        state.firebase.profile
      ).then((x) => {
        toastr.success(window._getText("TaskIsRemoved"));

        // if (callbackOnRemove)
        //   callbackOnRemove()
      });
    }
  };
  const onRemoveTaskItem = (key) => (e) => {
    let state = store.getState(); // todo fix this... only get it once
    const tempArray = [...task.subTasks] || [];
    const index = tempArray.findIndex((x) => x.key === key);
    delete tempArray[index];
    let newTask = { ...task, subTasks: tempArray.filter((x) => x) };
    FireHelper.addOrUpdateTask(newTask, state.firebase.profile).then((x) => {
      if (callbackOnUpdate) callbackOnUpdate(task.key);
    });
  };
  const restartTask = (e) => {
    let state = store.getState(); // todo fix this... only get it once
    if (!task) return;

    const tempArray = [...(task.subTasks || [])] || [];
    const newArray = [];
    tempArray.forEach((x) => {
      newArray.push({ ...x, status: 0 });
    });

    let newTask = { ...task, subTasks: newArray, status: 10, archive: false };
    FireHelper.addOrUpdateTask(newTask, state.firebase.profile).then((x) => {
      setSelectStatus(10);
      if (callbackOnUpdate) callbackOnUpdate(task.key);
      toastr.success(window._getText("TaskRestarted"));
    });
  };
  const setStatusTaskItem = (key) => (e) => {
    let state = store.getState();
    const tempArray = [...task.subTasks] || [];
    const index = tempArray.findIndex((x) => x.key === key);

    tempArray[index] = {
      ...tempArray[index],
      status: tempArray[index].status === 0 ? 1 : 0,
    };
    const isDone = tempArray.findIndex((x) => x.status === 0) === -1;
    let status = task.status;
    if (status === 20 && isDone)
      // published
      status = 40;

    let newTask = { ...task, subTasks: tempArray.filter((x) => x), status };
    FireHelper.addOrUpdateTask(newTask, state.firebase.profile).then((x) => {
      if (callbackOnUpdate) callbackOnUpdate(task.key);
    });
  };
  const changeUser = (data) => {
    let state = store.getState(); // todo fix this... only get it once
    const assignees = data.map((user) => user.key) || [];
    FireHelper.addOrUpdateTask(
      { ...task, assignees },
      state.firebase.profile
    ).then((x) => {
      if (callbackOnUpdate) callbackOnUpdate(task.key);
    });
  };
  const openUserModla = () => {
    //let state = store.getState() // todo fix this... only get it once
    //state.modal.modals['selectUsers'].open = true

    dispatch({
      type: "modal:open",
      payload: { modalKey: "selectUsers", open: true },
    });
  };
  const onDragStart = (e) => {
    // console.log('e', e)
    const index = e.target.getAttribute("index");
    if (index) e.dataTransfer.setData("text", index);
  };

  const onDragEnd = (e) => {
    //  console.log('e', e.target.style.border = 'solid 1px red')
  };
  const onDrop = (e) => {
    e.preventDefault();

    let index = e.target.getAttribute("index");

    if (!index) index = e.target.parentNode.getAttribute("index");

    if (!index) return;

    var data;
    try {
      data = JSON.parse(e.dataTransfer.getData("text"));
    } catch (error) {
      console.log("JSON.parse error");
      return;
    }

    let state = store.getState(); // todo fix this... only get it once
    FireHelper.addOrUpdateTask(
      { ...task, subTasks: moveArray(task.subTasks, data, index) },
      state.firebase.profile
    ).then((x) => {
      if (callbackOnUpdate) callbackOnUpdate(task.key);
    });
  };
  const onDragOver = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };
  const moveArray = (arr, from, to) => {
    const newArr = [...arr];

    newArr.splice(to, 0, newArr.splice(from, 1)[0]);
    return newArr;
  };
  let state = store.getState(); // todo fix this... only get it once
  let project = state.firestore.data.project || { cache: {}, key: "" };
  let cache = project.cache;
  let projectKey = project.key;
  let subTasks = [...((task || {}).subTasks || [])];

  let subTasksDone = subTasks; //.filter(x => x.status === 1)
  let subTasksNotDone = subTasks; //.filter(x => x.status === 0)

  return (
    <div style={s.taskView}>
      <div style={s.taskViewRowTitle}>
        {/* <FontAwesomeIcon onClick={onArchiveTaskItem(id, archive)} icon={icoArchive} style={s.icoAddTask} /> */}

        {selectStatus === 10 && task.archive === false && (
          <div tooltip="Publish task to staff">
            <button
              onClick={(e) => {
                toastr.success(window._getText("TaskIsPublished"));
                setSelectStatus(20);
              }}
              className="btn btn-default tour_task_6"
              style={{ background: "transparent", border: "none" }}
            >
              <FontAwesomeIcon icon={icoPublish} style={s.icoStartStopStart} />
            </button>
          </div>
        )}
        {(selectStatus === 20 || selectStatus === 40) && (
          <div tooltip="Unpublish task">
            <button
              onClick={(e) => {
                toastr.success(window._getText("TaskIsUnPublished"));

                setSelectStatus(10);
              }}
              className="btn btn-default"
              style={{
                color: "var(--color-text)",
                background: "transparent",
                border: "none",
                color: "var(--color-text)",
              }}
            >
              <FontAwesomeIcon icon={icoUnpublish} style={s.icoStartStopStop} />
            </button>
          </div>
        )}

        {/* <div tooltip='Restart'><button onClick={restartTask} className="btn btn-default" style={{ color: 'var(--color-text)', background: 'transparent', border: 'none' }} ><FontAwesomeIcon icon={icoRestart} style={s.icoStartStopRestart} /></button></div> */}

        <div tooltip="Remove task">
          <button
            onClick={onRemoveTask(task.key)}
            className="btn btn-default"
            style={{
              color: "var(--color-text)",
              background: "transparent",
              border: "none",
            }}
          >
            <FontAwesomeIcon icon={icoRemove} style={s.icoDelete} />
          </button>
        </div>
      </div>
      <div style={s.taskViewRow}>
        <input
          ref={inputRef}
          placeholder="Title"
          className="form-control tour_task_2 blueBorderOnHover"
          value={tbTitle}
          maxLength={40}
          style={s.tbTitle}
          onChange={(e) => {
            setTbTitle(e.target.value);
          }}
        ></input>
      </div>
      <div style={s.taskViewRow}>
        <textarea
          placeholder="Task description"
          maxLength={500}
          value={tbDescription}
          className="form-control tour_task_3 blueBorderOnHover"
          style={s.tbDescription}
          onChange={(e) => setTbDescription(e.target.value)}
        ></textarea>
      </div>

      <div style={{ paddingLeft: 0 }}>
        <b style={{ paddingLeft: 10 }}>Assignies :</b>
        <br />
        <div
          style={{
            ...s.taskViewRow,
            display: "flex",
            height: 60,
            overflow: "auto",
          }}
          className="blueBorderOnHover tour_task_4"
          onClick={openUserModla}
        >
          {task &&
            task.assignees &&
            task.assignees.map((userKey) => {
              const user = cache[userKey];
              if (!user) return "";

              const imgSrc = user.i
                ? getUserImage(projectKey, userKey, user.i)
                : noUserImage;

              return (
                <div
                  tooltip={user.n}
                  key={userKey}
                  style={{
                    minWidth: 30,
                    maxWidth: 35,
                    marginRight: 2,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  <img
                    style={{ width: 25, height: 25, borderRadius: 20 }}
                    src={imgSrc}
                  ></img>
                  {user.n}
                </div>
              );
            })}
          {task && (task.assignees || []).length === 0 && (
            <div style={{ color: "var(--color-text)" }}>
              {" "}
              + Click here to add staff{" "}
            </div>
          )}
        </div>
        <SelectUsers
          name="shift"
          isMultiSelect={false}
          onAddSelectedUsers={changeUser}
        ></SelectUsers>
      </div>

      <div style={{ display: "flex" }}>
        <input
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              addSubTask(e);
            }
          }}
          style={{ padding: 10 }}
          value={tbNewTask}
          onChange={(e) => setTbNewTask(e.target.value)}
          className="form-control tour_task_5 blueBorderOnHover"
          placeholder="Write new task here... then press enter"
        ></input>
        {/* <button className="btn btn-default" onClick={addSubTask}>Add</button> */}
        <br />
      </div>
      <div
        style={{
          height: "calc(100vh - 300px)",
          overflow: "auto",
          margin: 4,
          paddingRight: 5,
        }}
      >
        {subTasksNotDone && subTasksNotDone.length > 0 && (
          <div
            style={{
              marginTop: 5,
              marginBottom: 10,
              color: "silver",
              fontWeight: "bold",
              display: "flex",
              flex: 1,
              flexDirection: "row",
            }}
          >
            <div
              style={{
                color: "var(--color-text)",
                background: "transparent",
                flex: 1,
              }}
            >
              Tasks:{" "}
            </div>
            <div tooltip="Restart">
              <button
                onClick={restartTask}
                className="btn btn-default"
                style={{
                  color: "var(--color-text)",
                  background: "transparent",
                  border: "none",
                }}
              >
                <FontAwesomeIcon
                  icon={icoRestart}
                  style={s.icoStartStopRestart}
                />
              </button>
            </div>
          </div>
        )}
        {subTasksNotDone &&
          subTasksNotDone.map((t, index) => {
            if (t.status == 1) return;

            return (
              <div
                index={index}
                onDrop={onDrop}
                draggable="true"
                onDragStart={onDragStart}
                onDragOver={onDragOver}
                onDragEnd={onDragEnd}
                className="mOverShow"
                style={{
                  display: "flex",
                  borderBottom: "dotted 1px var(--color-border)",
                  paddingBottom: 3,
                }}
                key={t.key}
              >
                <FontAwesomeIcon
                  icon={icoCheck}
                  style={{ ...s.icoTask, color: "silver", margin: 4 }}
                  onClick={setStatusTaskItem(t.key)}
                />
                <div
                  onClick={setStatusTaskItem(t.key)}
                  style={{ marginRight: "auto", fontWeight: "200" }}
                >
                  {/* #{index}  */}
                  {t.text}
                </div>
                <div>
                  <FontAwesomeIcon
                    className="trashIcon"
                    onClick={onRemoveTaskItem(t.key)}
                    icon={icoTrash}
                    style={s.icoTask}
                  />
                </div>

                {/* <textarea style={s.tbTaskItem}></textarea> */}
              </div>
            );
          })}
        {/* {subTasksDone && subTasksDone.length > 0 &&
                    <div style={{ marginTop: 5, marginBottom: 5, color: 'silver', height: 15, fontWeight: 'bold' }}>Done:</div>
                } */}
        {subTasksDone &&
          subTasksDone.map((t, index) => {
            if (t.status == 0) return;

            return (
              <div
                index={index}
                onDrop={onDrop}
                draggable="true"
                onDragStart={onDragStart}
                onDragOver={onDragOver}
                onDragEnd={onDragEnd}
                className="mOverShow"
                style={{
                  display: "flex",
                  marginBottom: 3,
                  borderBottom: "dotted 1px var(--color-border)",
                  paddingBottom: 3,
                }}
                key={t.key}
              >
                <FontAwesomeIcon
                  icon={icoCheck}
                  style={{ ...s.icoTask, color: "green" }}
                  onClick={setStatusTaskItem(t.key)}
                />
                <div
                  onClick={setStatusTaskItem(t.key)}
                  style={{
                    marginRight: "auto",
                    textDecoration: "line-through",
                  }}
                >
                  {t.text}
                </div>
                <div>
                  <FontAwesomeIcon
                    className="trashIcon"
                    onClick={onRemoveTaskItem(t.key)}
                    icon={icoTrash}
                    style={s.icoTask}
                  />
                </div>

                {/* <textarea style={s.tbTaskItem}></textarea> */}
              </div>
            );
          })}
      </div>
      {/* <div style={s.taskViewRow}>
                <textarea placeholder='Staff feedback' value={tbFeedback} className='form-control' style={s.tbFeedback} onChange={e => setTbFeedback(e.target.value)}></textarea>
            </div> */}

      {/* <div style={{ display: 'flex', marginTop: 'auto', height: 45 }}> */}

      {/* <button onClick={onRemove} className="btn btn-default" style={{ height: 20, marginTop: 'auto' }} >Delete task</button> */}
      {/* <button onClick={onSave} className="btn btn-default" style={{ height: 20, marginTop: 'auto', marginLeft: 'auto' }} >Update</button> */}
      {/* </div> */}
    </div>
  );
}
