import React from "react";
import { getUserImage, renderTimeLable } from '../../core/util/helpers'
import noUserImage from '../../img/noimg.png'
import { store } from '../redux/store/configStore';




const FactoryRequests = (callBack) => {
    const init = () => {
        // console.log('>>>>>>>>>>>Init', 'FactoryRequests')
        newDataIn()
    }


    const newDataIn = async () => {
        let state = store.getState()


        let requestData = state.firestore.ordered['requestAllListner']
        let requestMyData = state.firestore.ordered['requestMyListner']
        let requestAdminData = state.firestore.ordered['requestAdminListner']


        if (FactoryRequests.cache.requestData !== requestData || FactoryRequests.cache.requestMyData !== requestMyData || FactoryRequests.cache.requestAdminData !== requestAdminData) {
            let projectKey = state.firebase.profile.projectKey
            let project = state.firestore.data.project
            let currentUserKey = state.firebase.profile.inSysUserKey
            getRequestList2({ requestData, requestMyData, requestAdminData, projectKey, project }).then((requests) => {
                state = store.getState()
                FactoryRequests.cache = { currentUserKey, requestData, requestMyData, projectKey, project, requests }
                if (callBack)
                    callBack()
            })

        }


    }

    const unsubscribe = store.subscribe(newDataIn)

    const getRequestList = () => FactoryRequests.cache.requests

    const getRequestList2 = async (params) => {
        return new Promise((resolve, reject) => {
            var { requestData, requestMyData, requestAdminData } = params
            var requestOut = (requestMyData || [])
            var requestIn = (requestData || []).map(x => {
                if (requestOut.findIndex(re => re.id === x.id) === -1)
                    return x
            }).filter(z => z)

            var rData = [...requestOut, ...requestIn, ...(requestAdminData || [])]


            // eslint-disable-next-line array-callback-return
            var sortedKeys = Object.keys(rData).map(key => {
                var item = rData[key]
                if (item && item.cdate)
                    return { k: key, d: item.cdate }
            })
            sortedKeys = sortedKeys.sort((a, b) => {
                if (!a.d || !b.d)
                    return 0
                if (a.d.seconds > b.d.seconds) return -1
                if (a.d.seconds < b.d.seconds) return 1
                return 0
            })

            const requests = sortedKeys.map(item => item ? rData[item.k] : null).filter(x => x)

            resolve(requests)
        })
    }

    const getListsItem = (doc, view) => {
        //const doc = rData[docKey]
        var { projectKey, project, currentUserKey } = FactoryRequests.cache

        if (!doc || !project)
            return ('')

        //  FactoryRequests.cache.requests.forEach(x => {

        //     console.log('x.id', x.id)
        //     console.log('doc.id', doc.id)
        //     console.log('x.id === doc.id', x.id === doc.id)
        //     return x.id === doc.id
        // })
        //  return ('')


        const schedualName = project.cache[doc.schedualKey] ? project.cache[doc.schedualKey].n : '-'
        var user = project.cache[doc.uk] ? { ...project.cache[doc.uk] } : { n: 'unknown', i: '', sk: null }
        user.img = user.i ? getUserImage(projectKey, doc.uk, user.i) : noUserImage
        var userChanged = { ...user };
        var shiftOrginal = { ...doc.data.sh } || {}
        var shiftChanged = { ...doc.data.sh } || {}
        var newUserKey = ''

        switch (doc.n) {

            case 'userrequestshift':

                //console.log(shiftOrginal)
                user.n = window._getText('schedual.openshifts')
                user.img = noUserImage

                // user.img = noUserImage


                break;

            case 'sick':
                //console.log('sick')
                shiftChanged.sik = true
                break;
            case 'deviation':
                //console.log('deviation')
                // console.log('>>shiftChanged>>', doc)
                shiftChanged.l = renderTimeLable('@start - @end', doc.data.sh.d + ' ' + doc.data.t1, doc.data.sh.d + ' ' + doc.data.t2, window._AmPm)
                shiftChanged.t1 = doc.data.t1
                shiftChanged.t2 = doc.data.t2
                break;

            case 'change':
                // console.log('change')
                if (doc.data.ct === 1) {
                    //  console.log('change 1')
                    shiftChanged.l = <span style={{ textDecoration: 'line-through' }}>{shiftChanged.l}</span>
                }
                else if (doc.data.ct === 3 && shiftChanged.newU) {
                    // console.log('change 3')
                    newUserKey = shiftChanged.newU[0] || ''
                    if (!doc.data.adm) {
                        //this is not an ADmin , so we sett current user here !
                        newUserKey = currentUserKey
                    }
                    userChanged = { ...project.cache[newUserKey] }
                    // console.log('>#shiftChanged', shiftChanged)
                    // console.log('>#shiftChanged.newU', newUserKey)
                    // console.log('>#userChanged', userChanged)
                    // console.log('>#cache', project.cache)
                    if (userChanged)
                        userChanged.img = userChanged.i ? getUserImage(projectKey, newUserKey, userChanged.i) : noUserImage
                    else {
                        userChanged = { n: 'unknown', i: '', sk: null, img: noUserImage }
                    }

                }
                else if (doc.data.ct === 4 && shiftChanged.newU) {
                    //console.log('change 4')
                    newUserKey = shiftChanged.newU[0] || ''
                    userChanged = { ...project.cache[newUserKey] }
                    shiftChanged = { ...doc.data.sh2 } || {}
                    shiftOrginal = { ...doc.data.sh } || {}
                    //userChanged = user
                    if (!doc.data.adm) {
                        //this is not an ADmin , so we sett current user here !
                        newUserKey = currentUserKey
                    }

                    if (userChanged)
                        userChanged.img = userChanged.i ? getUserImage(projectKey, newUserKey, userChanged.i) : noUserImage
                    else {
                        userChanged = { n: 'unknown', i: '', sk: null, img: noUserImage }
                    }
                }
                else {
                    //console.log('change else')
                    shiftChanged.l = renderTimeLable('@start - @end', doc.data.sh.d + ' ' + doc.data.t1, doc.data.sh.d + ' ' + doc.data.t2, window._AmPm)
                }

                shiftChanged.t1 = doc.data.t1
                shiftChanged.t2 = doc.data.t2
                break;

            case 'adminrequest':
                //console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>adminrequest', doc)

                user = { n: window._getText('schedual.adminrequest'), i: '', sk: null }
                user.img = user.i ? getUserImage(projectKey, 'adminrequest', user.i) : noUserImage

                newUserKey = shiftChanged.newU[0] || ''
                // if (!doc.data.adm) {
                //   //this is not an ADmin , so we sett current user here !
                //   newUserKey = currentUserKey
                // }
                userChanged = { ...project.cache[newUserKey] }
                if (shiftChanged.newU && view === 1) {
                    userChanged.participants = []
                    shiftChanged.newU.forEach(userKey => {

                        var u = project.cache[userKey]
                        // console.log('>>', u)
                        if (u) {
                            userChanged.participants.push({ ...u, img: u.i ? getUserImage(projectKey, newUserKey, u.i) : noUserImage })
                        }
                    });

                    //  console.log('>>userChanged.participants', userChanged.participants)
                }


                // console.log('>#shiftChanged', shiftChanged)
                // console.log('>#shiftChanged.newU', newUserKey)
                // console.log('>#userChanged', userChanged)
                // console.log('>#cache', project.cache)
                if (userChanged)
                    userChanged.img = userChanged.i ? getUserImage(projectKey, newUserKey, userChanged.i) : noUserImage
                else {
                    userChanged = { n: 'unknown', i: '', sk: null, img: noUserImage }
                }



                shiftChanged.t1 = doc.data.t1
                shiftChanged.t2 = doc.data.t2
                break;




            default:
                break;
        }

        //const isClicked = this.state.requestClick[doc.id] || false
        //console.log('doc', doc)

        //if (!s_schedualName && !isClicked) {
        // const s_user = user
        // const s_userChanged = userChanged
        // const s_shiftChanged = shiftChanged
        // const s_shiftOrginal = shiftOrginal
        // const s_doc = doc
        // const s_schedualName = schedualName
        //}

        const requestIsCurrentUsers = currentUserKey === doc.uk

        return { user, userChanged, shiftChanged, shiftOrginal, schedualName, requestIsCurrentUsers }
    }

    const destroy = () => {
        //  console.log('>>destroy', 'unsubscribe')
        unsubscribe()
    }
    init()

    return ({ getListsItem, destroy, getRequestList })
}
FactoryRequests.cache = {}
export default FactoryRequests