import React, { Component } from 'react'
//import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon'



class ScheduleEmpty extends Component {
  render() {

    return ('')

    // return (


    //   <div style={{

    //     margin: 'auto ',

    //     position: 'absolute',
    //     top: '150px',
    //     opacity: '0',

    //   }}
    //     className='fadeInSlow'
    //   >
    //     <div style={{
    //       padding: '60px', color: 'gray', fontSize: '15px'
    //     }}
    //     >
    //       <span style={{ color: 'gray', fontSize: '15px' }}>{window._getText('schedual.emptyTitle')}</span><br></br>
    //       {window._getText('schedual.emptyTxt1')}

    //       <img src={require('../../../../img/two-fingers.svg')} style={{ fill: 'green', transform: 'rotate(-10deg)', position: 'absolute', top: '120px', left: '120px', width: '50px' }} alt=''></img>


    //     </div>
    //     {/* <div style={{ animation: 'fadein 1s', padding: '20px', margin: '10px' }}>

    //       <img src={require('../../../../img/shift2.png')} alt='' style={{}} ></img>
    //     </div> */}
    //   </div>

    // )
  }
}


export default (ScheduleEmpty)